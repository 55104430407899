<div class="errors-warnings-floating-wrapper"
  *ngIf="hasAny()  && enableWarningIcon && !showFloatingMessages">
  <div class="errors-warnings-floating-icon minimized" (click)="onWarningIconClick()"
    [ngClass]="{'red-icon' : isRed(),'yellow-icon' : isYellow(),'grey-icon' : isGrey()}">
    <span class="rgifont rgi-exclamation-triangle"></span>
  </div>
</div>

<!-- MESSAGGI FISSI ALL'APERTURA DEL PALLINO -->
<div class="errors-warnings-floating-wrapper">
  <div *ngIf="hasAny()  && enableWarningIcon && showFloatingMessages">
      <rgi-lcc-messages  [validationMessages]="validationMessages" [blockingMsgs]="blockingMsgs"
                                [warningMsgs]="warningMsgs" [authoMsgs]="authoMsgs">
      </rgi-lcc-messages>
      <div class="errors-warnings-floating-icon expanded"
          [ngClass]="{'red-icon-close' : isRed(),'yellow-icon-close' : isYellow(),'grey-icon-close' : isGrey()}"
          (click)="onCloseErrorsClick()">
        <span class="rgifont rgi-close"></span>
      </div>
      <div [ngClass]="{'triangle-red' : isRed(),'triangle-yellow' : isYellow(), 'triangle-grey' : isGrey()}">
      </div>      
  </div>
</div>

<!-- MESSAGGI IN FONDO -->
<div #errorsElement>
      <rgi-lcc-messages  [validationMessages]="validationMessages" [blockingMsgs]="blockingMsgs"
                                [warningMsgs]="warningMsgs" [authoMsgs]="authoMsgs">
      </rgi-lcc-messages>
</div>
