<div [formGroup]="formGroup" class="row">
  <div class="col-md-12">
    <div class="row">
      <lpc-claim-beneficiary-control [editable]="editable" [showPercentage]="showPercentage" formControlName="subject"
                               (delete)="onDelete()"></lpc-claim-beneficiary-control>
    </div>
  </div>
  <div *ngFor="let role of getLinkedRolesOfParentRole(formGroup.get('subject').value.value.role);"
       style="padding-left:40px; padding-top:48px">
    <label class="label">{{role.label}}</label>
    <lpc-required [required]="role.mandatory | translate"></lpc-required>
    <div formArrayName="linkedRoles">
      <div *ngFor="let control of value; let i = index; trackBy: trackFn">
        <lpc-claim-beneficiary-control [showPercentage]="role.percentage" [editable]="editable"
                                 (delete)="deleteSubRole(i.toString(), $event)"
                                 [formControlName]="i.toString()"></lpc-claim-beneficiary-control>
      </div>
    </div>
    <input
        *ngIf="canAddSubBeneficiaries(role)"
        style="margin-top:15px;"
        [value]="'lpc_Seleziona'"
        (click)="openAnagSubjectModal(role.code)"
        class="col-lg-12 drop-container dashedSelect"
        [attr.data-qa]="'dashed-select-beneficiary-' + role.label"
        readonly>
  </div>
</div>
