<div class="grid input-box"   [attr.required]="config?.compulsory">
  <form [formGroup]="group">
    <rgi-rx-form-field>
      <label rgiRxLabel>
        <lpc-help-icon [helpFile]="config.helpFile" [title]="value"></lpc-help-icon>
        <span>{{value}}</span>
        <lpc-required *ngIf="config?.compulsory" required="true"></lpc-required>
      </label>
      <input lpcFormatFactor
          (blur)="onBlur()"
          [factorInput]="config"
          [formControlName]="config.code"
          class="form-control"
          [attr.data-qa]="'factor' + config.code"
          [attr.data-mod] = "'factor' + config.code + mandatory"
          [attr.data-obb] = "'factor' + config.code + modifiable"
          rgiRxInput>
          <!-- <ng-template #tabSiOrTabNo>
            <textarea
            style="overflow: hidden;"
            [innerHTML]="configValue"
            rows="4"
            [formControlName]="config.code"
            lpcFormatFactor
            (blur)="onBlur()"
            [factorInput]="config"
            class="form-control"
            [attr.data-qa]="'factor' + config.code">
            {{configValue}}
            </textarea>
          </ng-template> -->
    </rgi-rx-form-field>
  </form>
</div>
