import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { PipDataResp, PIPConfig, PipType, ContributionPipEvent } from '../models/pip-data.model';

@Injectable({
  providedIn: 'root'
})
export class PipDataServiceService {

  protected baseApiUrl: string;

  protected pipMap = new Map<ContributionPipEvent, PipType>([
    ['pipContributionData', 'contribution'],
    ['pipGeneral', 'general'],
    ['pipTransfers', 'transfer'],
    ['pipAdvancePayments', 'advance-payment'],
    ['pipUndeductedPremiums', 'undeducted-premiums']
  ]);

  constructor(private httpClient: HttpClient,
              @Inject('environment') environment: any) {
    this.baseApiUrl = environment.api.portal.host + environment.api.portal.path;
  }

  getPIPbyType(
    proposalId: string,
    idMovement: number,
    type: string
  ): Observable<PipDataResp> {
    const params = new HttpParams()
    .set('idMovement' ,  idMovement);
    return this.httpClient
    .get<PipDataResp>(this.baseApiUrl + '/v2/life/policies/' + proposalId + '/pipdata/' + type , {params});
  }

  getPIPConfig(
    proposalId: string,
    idMovement: number,
  ): Observable<PIPConfig[]> {
    const params = new HttpParams()
    .set('idMovement' ,  idMovement);
    return this.httpClient
    .get<PIPConfig[]>(this.baseApiUrl + '/v2/life/policies/' + proposalId + '/pipvisibility' , {params});
  }

  getTypeByEvent(event: ContributionPipEvent): PipType {
    return this.pipMap.get(event);
  }
}
