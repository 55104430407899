import { TranslationWrapperService } from '../../../i18n/translation-wrapper.service';
import { EMPTY_STR } from '../../../models/consts/lpc-consts';
import { BeneficiaryLifeDetail, Fund, LifeContractDetail, Profile, SubjectData } from '../../../models/life-detail.model';
import { Movement, MovementDetail } from '../../../models/movement.model';
import { PlcCurrencyUtils } from '../../../utils/plc-currency';
import { PlcObjectUtils } from '../../../utils/plc-object-utils';
import { RgiCtryLayerNumFormatterPipe } from '@rgi/rgi-country-layer';

/**
 * @author dmasone
 * @description Utils for LifeDetailComponent
 */
export class LifeDetailComponentUtils {

    public static haveMovementAppendixNumber(movements: Movement[]): boolean {
        let check = false;
        movements.forEach((mov) => {
          if (!!mov.numApp) {
            check = true;
          }
        });

        return check;
      }

    public static getPremiumByType(premiumArray: any[], type: string): any {
    let filteredElement = null;
    if (!!premiumArray && premiumArray.length > 0) {
        premiumArray.forEach((premium) => {
        if (premium && premium.type === type) {
            filteredElement = premium;
        }
        });
    }
    return filteredElement;
    }

    public static compareHolders(s1, s2) {
    const ORDERS = {
        1: -5000,   // Holders always first
        4: -2500,   // Insured always second
        300015: 10000000 // Referente terzo always last
    };
    const o1 = ORDERS[s1.erole] != null ? ORDERS[s1.erole] : parseInt(ORDERS[s1.erole], 10);
    const o2 = ORDERS[s2.erole] != null ? ORDERS[s2.erole] : parseInt(ORDERS[s2.erole], 10);

    return o1 - o2;
    }

  // Get the order set in FE for the buttons
  public static getOrder(actionOperation) {
    let order = 100;
    switch (actionOperation) {
      case 'ACTIONS':
        order =  10;
        break;
      case 'DETAIL':
        order =  20;
        break;
      case 'CANCEL':
        order =  30;
        break;
      case 'CONFIRM_PROPOSAL':
        order =  40;
        break;
      case 'CONFIRM_POLICY':
        order =  50;
        break;
    }
    return order;
  }

  public static getMessageForPopUp(actionCode: string, translate: TranslationWrapperService) {
    switch (actionCode) {
      case 'CANCEL':
        return translate.getImmediate('lpc_sum_det_confirm_cancel');
        break;
        case 'CANCEL_PREV':
        return translate.getImmediate('lpc_sum_det_confirm_cancel_prev');
        break;
    }
  }

  public static checkMultipleRelatedSubject(relSubj, index, position: 'first'|'last'|'middle') {
    if (!!relSubj) {
      if (position === 'first' && index === 0) {
        return true;
      } else if (position === 'last' && index === relSubj.length - 1) {
        return true;
      } else if (position === 'middle' && relSubj.length > 1 && index !== relSubj.length - 1) {
        return true;
      }
    }
    return false;
  }


  public static getTotalFund(fund: Fund, profile: Profile): string {
    // const totalFunds = (+fund.netAmount) * (+fund.exchangeRate) / (+profile.netAmount);
    return fund.percent.toString(); // RDDL-2891
  }

  public static getFundGrossAmount(fund: Fund): number {
    return PlcObjectUtils.roundToDecimal(+fund.grossAmount, 2);
  }

  public static getProfileGrossAmount(profile: Profile): number {
    return PlcObjectUtils.roundToDecimal(+profile.grossAmount, 2);
  }

  public static setDateVal(date: string) {
    if (date != null) {
      const splittedData = date.split('-');
      return splittedData[2] + '/' + splittedData[1] + '/' + splittedData[0];
    }
    return null;
  }

  public static multiplyPercentage(n: string): string {
    return (+n * 100).toString();
  }

  public static getSymbol(symbol: string) {
    return PlcCurrencyUtils.fixEuroSymbol(symbol);
  }

  public static durationLabel(translate: TranslationWrapperService, lifeContract: LifeContractDetail): string {
    let s = translate.getImmediate('lpc_duration_in') + ' ';
    let size = 0;
    if (parseInt(lifeContract.policyData.yearsDuration, 10) > 0) {
      s += translate.getImmediate('lpc_years');
      size++;
    }
    if (parseInt(lifeContract.policyData.monthsDuration, 10) > 0) {
      s += (size > 0 ? '/' : EMPTY_STR) + translate.getImmediate('lpc_months');
      size++;
    }
    if (parseInt(lifeContract.policyData.daysDuration, 10) > 0) {
      s += (size > 0 ? '/' : EMPTY_STR) + translate.getImmediate('lpc_days');
      size++;
    }
    return s;
  }

  public static durationValue(lifeContract: LifeContractDetail): string {
    let s = EMPTY_STR;
    if (parseInt(lifeContract.policyData.yearsDuration, 10) > 0) {
      s += lifeContract.policyData.yearsDuration;
    }
    if (parseInt(lifeContract.policyData.monthsDuration, 10) > 0) {
      s += (s.length > 0 ? '/' : EMPTY_STR) + lifeContract.policyData.monthsDuration;
    }
    if (parseInt(lifeContract.policyData.daysDuration, 10) > 0) {
      s += (s.length > 0 ? '/' : EMPTY_STR) + lifeContract.policyData.daysDuration;
    }
    return s;
  }

  // eslint-disable-next-line max-len
  public static getBenefToShow(subj: SubjectData, index: number, rgiNumberFormatter: RgiCtryLayerNumFormatterPipe, percentFormatterOptions: Intl.NumberFormatOptions): BeneficiaryLifeDetail {
    let beneficiaryLabel = EMPTY_STR;
    let subjId = null;
    const relatedSubjectVector = [];

    if (!!subj.typeChoice) {
      // benef NOT from anag
      beneficiaryLabel = subj.typeChoice;
    } else if (!!subj.denomination) {
      // anag benef
      beneficiaryLabel = subj.denomination;
      // percentage
      if (!!subj.percBenef && Number(subj.percBenef) > 0) {
        beneficiaryLabel = beneficiaryLabel.concat(
          ' ' + rgiNumberFormatter.transform(Number(subj.percBenef) / 100, '', percentFormatterOptions)
        );
      }
      if (!!subj.id) {
        subjId = subj.id;
      }
      // linkedSubjects
      if (!!subj.linkedSubjects && subj.linkedSubjects.length > 0) {
        let subRoles = EMPTY_STR;

        subj.linkedSubjects.forEach(element => {
          relatedSubjectVector.push({ label: element.denomination, id: element.id });
          if (subRoles !== EMPTY_STR) {
            subRoles = subRoles.concat(', ');
          }
          subRoles = subRoles.concat(element.denomination);
        });
        if (subRoles !== EMPTY_STR) {
          beneficiaryLabel = beneficiaryLabel.concat(' (' + subRoles + ')');
        }
      }
    }
    return {
      label: beneficiaryLabel,
      typeBenef: index === 0 ? subj.typeBenef : EMPTY_STR,
      denomination: subj.denomination,
      percentage: subj.percBenef,
      id: subjId,
      expiryCommunication: subj.expiryCommunication,
      severeDisability: subj.severeDisability,
      relatedSubject: relatedSubjectVector,
      payments: subj.payments,
      positionNumberDescr: subj.positionNumberDescr
    };
  }

  public static evalMovementDetailObj(movementDetail: any, responseDetail: MovementDetail) {
    movementDetail.investments = responseDetail.investments;
    movementDetail.disinvestments = responseDetail.disinvestments;
    movementDetail.operationWithdrawal = responseDetail.operationWithdrawal;
    movementDetail.showAmountFee = responseDetail.showAmountFee;
    movementDetail.movementInfo = responseDetail.movementInfo;
    movementDetail.surveys = responseDetail.surveys;
    movementDetail.settlementBeneficiaryDetails = responseDetail.settlementBeneficiaryDetails;
    movementDetail.paymentFrequencyDetail = responseDetail.paymentFrequencyDetail;
    movementDetail.premiumPaymentSuspension = responseDetail.premiumPaymentSuspension; // RDDL-3705
    movementDetail.operationSyntesis = responseDetail.operationSyntesis;
    movementDetail.movementVariation = responseDetail.movementVariation;
    movementDetail.cancellInstallmentData = responseDetail.cancellInstallmentData;
    movementDetail.note = responseDetail.note;
    movementDetail.premiumPaymentReactivation = responseDetail.premiumPaymentReactivation;
    movementDetail.reserveData = responseDetail.reserveData;
    movementDetail.loanData = responseDetail.loanData;
    movementDetail.capitalData = responseDetail.capitalData;
    movementDetail.warrantiesForInstalmentInfo = responseDetail.warrantiesForInstalmentInfo;
    movementDetail.dueRegisterInfo = responseDetail.dueRegisterInfo;
  }


  /**
   * Returns true if the proposal number is present in lifeContractDetail.generalData.propNum, otherwise false
   *
   * @param lifeContractDetail - lifeContractDetail is type LifeContractDetail
   * @returns boolean
   */
  private static isTherePropNumInLifeContractDetail(lifeContractDetail: LifeContractDetail): boolean {
    return !!lifeContractDetail.generalData && !!lifeContractDetail.generalData.propNum;
  }

  /**
   * Returns true if the policy number is present in lifeContractDetail.generalData.polNum, otherwise false
   *
   * @param lifeContractDetail - lifeContractDetail is type LifeContractDetail
   * @returns boolean
   */
  private static isTherePolNumInLifeContractDetail(lifeContractDetail: LifeContractDetail): boolean {
    return !!lifeContractDetail.generalData && !!lifeContractDetail.generalData.polNum;
  }

  /**
   * Returns true if the proposal number is present in data.lifeContract.proposalNumber, otherwise false
   *
   * @param data - data is type any
   * @returns boolean
   */
  private static isThereProposalNumberInData(data: any): boolean {
    return !!data.lifeContract && !!data.lifeContract.proposalNumber;
  }

  /**
   * Returns true if the policy number is present in data.lifeContract.policyNumber, otherwise false
   *
   * @param data - data is type any
   * @returns boolean
   */
  private static isTherePolicyNumberInData(data: any): boolean {
    return !!data.lifeContract && !!data.lifeContract.policyNumber;
  }

  /**
   * Returns proposal number from lifeContractDetail or data, otherwise null
   *
   * @param lifeContractDetail - lifeContractDetail is type LifeContractDetail
   * @param data - data is type any
   * @returns string
   */
  public static getStoreProposalNumber(lifeContractDetail: LifeContractDetail, data: any): string {
    if (this.isTherePropNumInLifeContractDetail(lifeContractDetail)) {
      return lifeContractDetail.generalData.propNum;
    }
    if (this.isThereProposalNumberInData(data)) {
      return data.lifeContract.proposalNumber;
    }
    return null;
  }

  /**
   * Returns policy number from lifeContractDetail or data, otherwise null
   *
   * @param lifeContractDetail - lifeContractDetail is type LifeContractDetail
   * @param data - data is type any
   * @returns string
   */
  public static getStoredPolicyNumber(lifeContractDetail: LifeContractDetail, data: any): string {
    if (this.isTherePolNumInLifeContractDetail(lifeContractDetail)) {
      return lifeContractDetail.generalData.polNum;
    }
    if (this.isTherePolicyNumberInData(data)) {
      return data.lifeContract.policyNumber;
    }
    return null;
  }


}
