import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {LpcPremiumTableComponent} from './lpc-premium-table/lpc-premium-table.component';
import { RgiCountryLayerModule } from '@rgi/rgi-country-layer';
import { RgiRxI18nModule } from '@rgi/rx/i18n';

@NgModule({
  declarations: [
    LpcPremiumTableComponent
  ],
  imports: [
    CommonModule,
    RgiCountryLayerModule,
    RgiRxI18nModule
  ],
  exports: [
    LpcPremiumTableComponent,
  ]
})
export class LpcPremiumModule { }
