import { NgModule } from "@angular/core";
import { LicInvestmentsExtComponent } from "./component/lic-investments/lic-investments-ext.component";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { PortalNgCoreModule } from "@rgi/portal-ng-core";
import { CustomNumberModule, InvestStandaloneSessionModule, LicCheckboxAccordionModule, LicFundModule, LicInvestmentContractModule, LicKarmaFundModule, LicRequiredModule, LicToolsModule } from "@rgi/life-issue-card";
import { NgxPaginationModule } from "ngx-pagination";
import { RgiRxI18nModule } from "@rgi/rx/i18n";
import { RgiRxModalModule, RgiRxPanelModule } from "@rgi/rx/ui";
import { RgiCountryLayerModule } from "@rgi/rgi-country-layer";

@NgModule({
    declarations: [LicInvestmentsExtComponent], 
    imports: [CommonModule,
        FormsModule,
        ReactiveFormsModule,
        PortalNgCoreModule,
        LicInvestmentContractModule,
        LicCheckboxAccordionModule,
        LicFundModule,
        CustomNumberModule,
        LicToolsModule,
        LicKarmaFundModule,
        NgxPaginationModule,
        RgiCountryLayerModule,
        RgiRxI18nModule,
        LicRequiredModule,
        RgiRxModalModule,
        RgiRxPanelModule,
        InvestStandaloneSessionModule],
    exports: [LicInvestmentsExtComponent],
    providers: [
        { provide: 'investmentsComponent', useValue: LicInvestmentsExtComponent }
      ]
})
export class LicInvestmentsExtModule {}