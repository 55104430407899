import { Directive, ElementRef, HostListener, Input, OnInit } from '@angular/core';
import { PaymentFields } from '../../models/meansofpayment.model';
import { RgiCtryLayerNumFormatterPipe } from '@rgi/rgi-country-layer';
import { PolicyService } from '../../services/policy-service';

@Directive({
    selector: '[licFormatPayment]',
    providers: [RgiCtryLayerNumFormatterPipe]
})
export class FormatPaymentDirective implements OnInit {
  @Input () paymentInput: PaymentFields;
  element: ElementRef;

  constructor(
    el: ElementRef,
    public decPipe: RgiCtryLayerNumFormatterPipe,
    protected policyService: PolicyService) {
    this.element = el;
  }

  @HostListener('blur', ['$event.target.value'])
  onBlur(value) {
    if ((this.element.nativeElement.value !== null && this.element.nativeElement.value !== '-1' &&
    this.element.nativeElement.value !== '') && this.paymentInput.type === 'R') {
      this.formattingInputNumber();
    }
  }

  @HostListener('focus', ['$event.target.value'])
  onFocus(value) {
    if ((this.element.nativeElement.value !== null && this.element.nativeElement.value !== '-1' &&
    this.element.nativeElement.value !== '') && this.paymentInput.type === 'R') {
      this.resetFormat();
    }
  }

  @HostListener('keypress', ['$event.key'])
  onKeyPress(value) {
    if (this.paymentInput.type === 'R' || this.paymentInput.type === 'N') {
      const regexForKeyPress = /[\d\.,]/;
      // const regexForValue = /^\d+([\.,]\d{0,2})?$/;
      if (regexForKeyPress.test(value)) {
          return true;
      }
    } else {
      return true;
    }
    console.log('FORMATTER', 'ONKEYPRESS', 'Regex failed for ', this.element.nativeElement.value + value);
    return false;
  }

  @HostListener('keyup', ['$event.key'])
  onkeyup(value) {
    if (this.paymentInput.type === 'R' || this.paymentInput.type === 'N') {
      const regexForValue = /^\d+([\.,]\d{0,2})?$/;
      if (regexForValue.test(this.element.nativeElement.value)) {
          return true;
      }
    } else {
      return true;
    }
    return false;
  }

  ngOnInit() {
    if (this.paymentInput.type === 'R') {
      if (this.element.nativeElement.value !== null && this.element.nativeElement.value !== '-1' &&
      this.element.nativeElement.value !== '') {
        this.formattingInputNumber();
      }
    }
  }

  resetFormat() {
    const regex = /[.,\s]/g;
    this.element.nativeElement.value = this.element.nativeElement.value.replace(regex, '');

    const decimal = (this.element.nativeElement.value.substring(
      this.element.nativeElement.value.length - (+2),
      this.element.nativeElement.value.length));

    const integer = (this.element.nativeElement.value.substring(
      0,
      this.element.nativeElement.value.length - (+2)));

    if (+decimal === 0) {
      this.element.nativeElement.value = integer;
    } else {
      this.element.nativeElement.value = integer + '.' + decimal;
    }

  }

  formattingInputNumber() {
    this.element.nativeElement.value = this.element.nativeElement.value.replace(',', '.');

    const decimal = +2;
    if (decimal > 0) {
      if (+this.element.nativeElement.value !== null) {
        const options = this.policyService.getFormatterOptionsWithDecimal('decimal', '.' + decimal + '-' + decimal);

        this.element.nativeElement.value = this.decPipe.transform(
          +this.element.nativeElement.value,
          this.policyService.currentLocale,
          options);
      } else {
        this.element.nativeElement.value = this.decPipe.transform(
          +this.element.nativeElement.value,
          this.policyService.currentLocale
        );
      }
    }
  }


}
