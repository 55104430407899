<lpc-stepper [formGroup]="formGroup" (stepSlide)="onSlide($event)" [isPublished]="publishMessage !== null">

  <lpc-step label="{{CLAUSES_STEP.DATE.label}}" fieldId="{{CLAUSES_STEP.DATE.fieldId}}" id="{{CLAUSES_STEP.DATE.id}}" [errors]="errors" (next)="onNext($event)">
    <lpc-dates-step [inputFields]="dateDefinitions" [formControlName]="CLAUSES_STEP.DATE.formName" (dateChange)="onDateChange($event)"
      (reloadRequest)="updateData()" [hide]="activeStep<1">
    </lpc-dates-step>
  </lpc-step>

 <!-- <lpc-step
    id="{{CLAUSES_STEP.QUESTS_FIN.id}}" [hidden]="!validQuestsCode.get('FIN')"
    [jump]="!validQuestsCode.get('FIN')"
    *ngIf="showSectionQuestFinAfterInit && showQuestionnaireByType('FIN')"
    fieldId="{{CLAUSES_STEP.QUESTS_FIN.fieldId}}" label="{{CLAUSES_STEP.QUESTS_FIN.label}}" (next)="onNext($event)">
   <lpc-questionnaire [active]="!isAfterId('questsFin')" [key]="composeKey(key, 'questsFin')"
                      [questionnairesCode]="getQuestMap('FIN')" type="FIN"
                      [factors]="questFactorsArray" [disabledQuestions]="disabledQuestionArray"
                      [date]="effectiveDate" [savedIds]="questionnairesFromAuthorization"
                      [productCode]="productCode"
                      (loaderQuest)="loaderQuestManager($event)"
                      (loaded)="countQuest($event)"
                      #financialQuestionnaire>
    </lpc-questionnaire>
  </lpc-step> -->

  <lpc-step label="{{CLAUSES_STEP.CLAUSES.label}}" fieldId="{{CLAUSES_STEP.CLAUSES.fieldId}}" id="{{CLAUSES_STEP.CLAUSES.id}}" [errors]="errors" (next)="onNext($event, true)"
            (confirmAccept)="onNext($event, true, null, true)" [isConfirmAndAccept]="isConfirmAndAccept">
    <div data-qa="step-clauses-variation-step" formGroupName="{{CLAUSES_STEP.CLAUSES.formName}}">
        <div [hidden]="!isActiveStep(CLAUSES_STEP.CLAUSES.id)" class="example-row" *ngFor="let clauseType of clauses | keyvalue; trackBy: trackByFn">
          <rgi-rx-expansion-panel [expanded]="false" color="default" *ngIf="!!clauses[clauseType.key]?.length" >
            <div rgi-rx-expansion-panel-header>
              <!-- lpc_clausesinsuredClauses or lpc_clausespolicyClauses or lpc_clausesriskClauses -->
              <span class="header-text header-clause">{{('lpc_clauses'+ clauseType.key) | translate}}</span>
            </div>
            <div content>
              <div [formGroupName]="clauseType.key">
                <div class="example-row" *ngFor="let clause of clauses[clauseType.key]; index as indexClause" >
                  <ng-container [formArrayName]="indexClause">
                    <ng-container *ngIf="clause.visible">
                      <rgi-rx-expansion-panel>
                        <div rgi-rx-expansion-panel-header>
                          <input type="checkbox" (click)="onClickCheckbox(clause)" class="rgi-ui-form-control" [formControlName]="CLAUSES_STEP.CLAUSES.formChildren.clauseState">
                          <span class="header-clause">{{clause.descrizioneEstesa}} </span>
                        </div>
                        <div content>
                          <rgi-rx-form-field>
                            <textarea rgiRxInput formControlName="{{CLAUSES_STEP.CLAUSES.formChildren.clauseText}}"></textarea>
                          </rgi-rx-form-field>
                        </div>
                      </rgi-rx-expansion-panel>
                    </ng-container>
                  </ng-container>
                </div>
              </div>
            </div>
          </rgi-rx-expansion-panel>
        </div>
        <!-- Summary section -->
        <div class="example-row" *ngIf="isAfterId(CLAUSES_STEP.CLAUSES.id)">
          <div *ngFor="let clauseType of clauses | keyvalue; trackBy: trackByFn">
            <rgi-rx-expansion-panel [expanded]="false" color="default" *ngIf="!!clauses[clauseType.key]?.length" >
              <div rgi-rx-expansion-panel-header>
                <!-- lpc_clausesinsuredClauses or lpc_clausespolicyClauses or lpc_clausesriskClauses -->
                <span class="header-text header-clause">{{('lpc_clauses'+ clauseType.key) | translate}}</span>
              </div>
              <div content>
                <div class="example-row" *ngFor="let clause of clauses[clauseType.key]">
                  <ng-container *ngIf="formGroupValue?.clauses && (formGroupValue.clauses[clauseType.key] | extractFromArray : CLAUSES_STEP.CLAUSES.formChildren.clauseCode: clause.codice: CLAUSES_STEP.CLAUSES.formChildren.clauseState)">
                    <span class="header-clause">{{clause.descrizioneEstesa}}: </span>
                    <span>{{ (formGroupValue?.clauses[clauseType.key] | extractFromArray : CLAUSES_STEP.CLAUSES.formChildren.clauseCode: clause.codice: CLAUSES_STEP.CLAUSES.formChildren.clauseText) || '-' }}</span>
                  </ng-container>
                </div>
              </div>
            </rgi-rx-expansion-panel>
          </div>
        </div>
    </div>
  </lpc-step>

  <!-- <lpc-step [hidden]="!validQuestsCode.get('ADVER')" [jump]="!validQuestsCode.get('ADVER')" id="{{CLAUSES_STEP.QUESTS.id}}" [errors]="errors" [enableAuth]="isAuth" *ngIf="showSectionQuestAfterInit && showQuestionnaireByType('ADVER')"
            fieldId="{{CLAUSES_STEP.QUESTS.fieldId}}" label="{{CLAUSES_STEP.QUESTS.label}}" (next)="onNext($event)"
            (confirmAccept)="onNext($event, false, null, true)" [isConfirmAndAccept]="isConfirmAndAccept">
    <lpc-questionnaire
                       [active]="!isAfterId(CLAUSES_STEP.QUESTS.id)" [key]="composeKey(key, CLAUSES_STEP.QUESTS.id)"
                       [questionnairesCode]="getQuestMap('ADVER')" type="ADVER"
                       [factors]="questFactorsArray" [disabledQuestions]="disabledQuestionArray"
                       [date]="effectiveDate" [productCode]="productCode" [savedIds]="questionnairesFromAuthorization"
                       (loaderQuest)="loaderQuestManager($event)"
                       (loaded)="countQuest($event)"
                       #avcQuestionnaire>
    </lpc-questionnaire>
  </lpc-step> -->

  <lpc-step
        *ngIf="isAuth || !!errors && !!errors.length"
        id="{{CLAUSES_STEP.WARNING.id}}" label="{{CLAUSES_STEP.WARNING.label}}"
        (next)="publish()"
        [nextLabel]="isAuth ? 'AUTHORIZATION REQUEST' : 'Confirm'"
        (confirmAccept)="publish(isConfirmAndAccept)" [isConfirmAndAccept]="isConfirmAndAccept">

    <lpc-warning-messages *ngIf="!!errors" [errors]="errors"></lpc-warning-messages>
  </lpc-step>

  <lpc-step
    label="{{CLAUSES_STEP.DOCUMENT.label}}"
    [errors]="errors"
    fieldId="{{CLAUSES_STEP.DOCUMENT.fieldId}}"
    id="{{CLAUSES_STEP.DOCUMENT.id}}"
    [nextVisible]=false>
    <lpc-document-wrapper
      *ngIf="!!publishMessage"
      [contractId]="getContractId"
      [operationCode]="getOperationCode"
      [publishMessage]="publishMessage"
      [isAuth]="isAuth"
      [authorizationId]="authorizationId"
      [printMode]="printMode"
      [config]="documentsConfig"
      (documentEvent)="handleDocumentEvent($event)">
    </lpc-document-wrapper>
    <lpc-navigation-buttons
      id="last-step-buttons"
      (back)="closeCardWithoutModal()"
      [backLabel]="'lpc_close_button'"
      [backVisible]="true"
      [nextVisible]="false">
    </lpc-navigation-buttons>
  </lpc-step>
</lpc-stepper>

<div class="btn-group btn-group-justified external" *ngIf="!isActiveStep(CLAUSES_STEP.DOCUMENT.id)">
    <lpc-navigation-buttons (back)="closeCard()" [nextVisible]="false" [qa]="'annulla'"></lpc-navigation-buttons>
</div>
