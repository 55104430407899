import {LpcRolesControlModule} from './../lpc-roles-control/lpc-roles-control.module';
import {LpcErrorMessagesModule} from '../lpc-error-messages/lpc-error-messages.module';
import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {PortalNgCoreModule} from '@rgi/portal-ng-core';
import {LpcNavigationButtonsModule} from '../lpc-navigation-buttons/lpc-navigation-buttons.module';
import {ReactiveFormsModule} from '@angular/forms';
import {LpcDatepickerModule} from '../lpc-datepicker/lpc-datepicker.module';
import {LpcBeneficiaryComponent} from './lpc-beneficiary/lpc-beneficiary.component';
import {LpcBeneficiaryControlComponent} from './lpc-beneficiary-control/lpc-beneficiary-control.component';
import {LpcBeneficiaryTextControlComponent} from './lpc-beneficiary-text-control/lpc-beneficiary-text-control.component';
import {LpcClaimBeneficiaryComponent} from './lpc-claim-beneficiary/lpc-claim-beneficiary.component';
import {LpcPaymentStepModule} from '../lpc-payment-step/lpc-payment-step.module';
import {QuestionnairesManagerModule} from '@rgi/questionnaires-manager';
import {LpcClaimBeneficiariesComponent} from './lpc-claim-beneficiaries/lpc-claim-beneficiaries.component';
import { LpcClaimSubBeneficiaryComponent } from './claim-sub-beneficiary/claim-sub-beneficiary.component';
import { LpcQuestionnaireModule } from '../lpc-questionnaire/lpc-questionnaire.module';
import { LpcSubBeneficiaryControlComponent } from './lpc-sub-beneficiary-control/lpc-sub-beneficiary-control.component';
import { LpcClaimBeneficiaryControlComponent } from './lpc-claim-beneficiary-control/lpc-claim-beneficiary-control.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { LpcDistributionToggleModule } from '../distribution-toggle/distribution-toggle.module';
import { RgiCountryLayerModule } from '@rgi/rgi-country-layer';
import { RgiRxI18nModule } from '@rgi/rx/i18n';
import { LpcRequiredModule } from '../../utils/lpc-required/lpc-required.module';
import { LpcBeneficiaryService } from '../../services/beneficiary.service';
import { RgiRxExpansionModule, RgiRxPanelModule } from '@rgi/rx/ui';

@NgModule({
    declarations: [
        LpcBeneficiaryComponent,
        LpcBeneficiaryControlComponent,
        LpcBeneficiaryTextControlComponent,
        LpcClaimBeneficiaryComponent,
        LpcClaimBeneficiariesComponent,
        LpcClaimSubBeneficiaryComponent,
        LpcClaimBeneficiaryControlComponent,
        LpcSubBeneficiaryControlComponent
    ],
    imports: [
        CommonModule,
        LpcErrorMessagesModule,
        PortalNgCoreModule,
        LpcNavigationButtonsModule,
        ReactiveFormsModule,
        LpcDatepickerModule,
        LpcRolesControlModule,
        LpcPaymentStepModule,
        QuestionnairesManagerModule,
        LpcQuestionnaireModule,
        NgSelectModule,
        LpcDistributionToggleModule,
        RgiCountryLayerModule,
        RgiRxI18nModule,
        LpcRequiredModule,
        RgiRxPanelModule,
        RgiRxExpansionModule
    ],
    exports: [
        LpcBeneficiaryComponent,
        LpcBeneficiaryControlComponent,
        LpcBeneficiaryTextControlComponent,
        LpcClaimBeneficiaryComponent,
        LpcClaimBeneficiariesComponent,
        LpcClaimSubBeneficiaryComponent,
        LpcSubBeneficiaryControlComponent,
        LpcClaimBeneficiaryControlComponent
    ],
    providers: [LpcBeneficiaryService]
})
export class LpcBeneficiaryModule { }
