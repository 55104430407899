<ng-container *ngIf="(dialogueHandlerSummaryState.getState$() | async) as state">

  <div class="container-fluid ">

    <!--  -->
    <div [hidden]="displayedDetails.length == 0" class="accordion-color">
      <rgi-rx-expansion-panel>
        <div rgi-rx-expansion-panel-header>
          <div rgi-rx-expansion-panel-label>{{"_CLAIMS_._DETAILS_CLAIMS_DATA"| translate}}</div>
        </div>
        <div *ngFor="let detailsOrder of displayedDetails;" class="row">
          <hr *ngIf="detailsOrder.length == 1 ">
          <div *ngFor="let detail of detailsOrder" [class]="getColumnClass(detail)">
            <label [class]="detail.tipo == 'T' ? 'mar-bot-20' : 'mar-top-20' " *ngIf="detail.titolo" rgiRxLabel
              class="label-core">{{detail.titolo}}</label>
            <input *ngIf="detail.valore" disabled rgiRxInput [value]="detail.valore">
          </div>
        </div>
      </rgi-rx-expansion-panel>
    </div>

    <ng-container *ngIf="state.dialogueSummaryObject as inputObject">
      <br>
      <div class="accordion-color">
        <rgi-rx-expansion-panel [expanded]="true">
          <div rgi-rx-expansion-panel-header>
            <div rgi-rx-expansion-panel-label>{{"_CLAIMS_._DIALOGUE"| translate }}</div>
          </div>
          <div class="row col-md-12">
            <div class="col-md-2">
              <label rgiRxLabel class="label-core" attr.data-qa="new-dialogue-function-label">
                {{ '_CLAIMS_._FUNCTION' | translate }}</label>
              <input disabled rgiRxInput class="form-control" [value]="inputObject.codFunction">
            </div>

            <div class="col-md-4">
              <label rgiRxLabel class="label-core" attr.data-qa="new-dialogue-object-label">
                {{ '_CLAIMS_._OBJECT' | translate }}</label>
              <input disabled rgiRxInput class="form-control" [value]="inputObject.dialogueSubject?.description"
                id="object" type="text" name="object" attr.data-qa="new-dialogue-object-input">
            </div>

            <div class="col-md-1">
              <label rgiRxLabel class="label-core" attr.data-qa="new-dialogue-insertion-label">
                {{ '_CLAIMS_._INSERTION' | translate }}</label>
              <input disabled rgiRxInput class="form-control" [value]="formatData(inputObject.startDate)" id="insertion"
                type="text" name="insertion" attr.data-qa="new-dialogue-insertion-input">
            </div>

            <div class="col-md-1">
              <label rgiRxLabel class="label-core" attr.data-qa="new-dialogue-last-elaboration-label">
                {{ '_CLAIMS_._LAST_ELABORATION' | translate }}</label>
              <input disabled rgiRxInput class="form-control" [value]="formatData(inputObject.lastMsgDate)"
                id="lastElaboration" type="text" name="lastElaboration"
                attr.data-qa="new-dialogue-last-elaboration-input">
            </div>

            <div class="col-md-1">
              <label rgiRxLabel class="label-core" attr.data-qa="new-dialogue-canceldate-label">
                {{ '_CLAIMS_._CANCELLATION_DATE' | translate }}</label>
              <input disabled rgiRxInput class="form-control" [value]="formatData(inputObject.cancelDate)"
                id="cancelDate" type="text" name="cancelDate"
                attr.data-qa="new-dialogue-canceldate-input">
            </div>

            <div class="col-md-2">
              <label rgiRxLabel class="label-core" attr.data-qa="new-dialogue-role-label">
                {{ '_CLAIMS_._ROLE' | translate }}</label>
              <input disabled rgiRxInput class="form-control" [value]="inputObject.roleCompany.description" id="role"
                type="text" name="role" attr.data-qa="new-dialogue-role-input">
            </div>

            <div class="col-md-1">
              <label rgiRxLabel class="label-core" attr.data-qa="new-dialogue-state-label">
                {{ '_CLAIMS_._STATUS' | translate }}</label>
              <input disabled rgiRxInput class="form-control" [value]="status" id="state" type="text" name="state"
                attr.data-qa="new-dialogue-state-input">
            </div>
          </div>
          <br>
          <div class="row col-md-12">

            <div class="col-md-2">
              <label rgiRxLabel class="label-core" attr.data-qa="new-dialogue-claim-number-label">
                {{ '_CLAIMS_._CLAIM' | translate }}</label>
              <input disabled rgiRxInput class="form-control" [value]="inputObject.numCl" id="claimNumber" type="text"
                name="claimNumber" attr.data-qa="new-dialogue-claim-number-input">
            </div>

            <div class="col-md-2">
              <label rgiRxLabel class="label-core" attr.data-qa="new-dialogue-complaint-number-label">
                {{ '_CLAIMS_._COMPLAINT' | translate }}</label>
              <input disabled rgiRxInput class="form-control" [value]="inputObject.numPotClaim" id="complaintNumber"
                type="text" name="complaintNumber" attr.data-qa="new-dialogue-complaint-number-input">
            </div>

            <div class="col-md-3">
              <label rgiRxLabel class="label-core" attr.data-qa="new-dialogue-company-label">
                {{ '_CLAIMS_._COMPANY' | translate }}</label>
              <input disabled rgiRxInput class="form-control" [value]="inputObject.codCompanyAnia" id="companyUs"
                type="text" name="companyUs" attr.data-qa="new-dialogue-company-input">
            </div>



            <div class="col-md-3">
              <label rgiRxLabel class="label-core" attr.data-qa="new-dialogue-ctp-company-label">
                {{ '_CLAIMS_._CTP_COMPANY' | translate }}</label>
              <input disabled rgiRxInput class="form-control" [value]="inputObject.codCompCtnpAnia" id="ctpCompany"
                type="text" name="ctpCompany" attr.data-qa="new-dialogue-ctp-company-input">
            </div>

            <div class="col-md-1">
              <label rgiRxLabel class="label-core" attr.data-qa="new-dialogue-type-ins-plate-label">
                {{ '_CLAIMS_._US_TYPE_PLATE' | translate }}</label>
              <input disabled rgiRxInput class="form-control" [value]="inputObject.typeInsPlate" id="typeInsPlate"
                type="text" name="typeInsPlate" attr.data-qa="new-dialogue-type-ins-plate-input">
            </div>

            <div class="col-md-1">
              <label rgiRxLabel class="label-core" attr.data-qa="new-dialogue-license-plate-label">
                {{ '_CLAIMS_._US_CAR_LICENSE_PLATE' | translate }}</label>
              <input disabled rgiRxInput class="form-control" [value]="inputObject.insurPlate" id="licensePlateUs"
                type="text" name="licensePlateUs" attr.data-qa="new-dialogue-license-plate-input">
            </div>
          </div>
          <br>
          <div class="row col-md-12">

            <div class="col-md-3">
              <label rgiRxLabel class="label-core" attr.data-qa="new-dialogue-external-key-label">
                {{ '_CLAIMS_._EXTERNAL_KEY' | translate }}</label>
              <input disabled rgiRxInput class="form-control" [value]="inputObject.externalKey" id="externalkey"
                type="text" name="externalkey" attr.data-qa="new-dialogue-external-key-input">
            </div>

            <div class="col-md-3">
              <label rgiRxLabel class="label-core" attr.data-qa="new-dialogue-external-key-damaged-label">
                {{ '_CLAIMS_._EXTERNAL_KEY_DAMAGED' | translate }}</label>
              <input disabled rgiRxInput class="form-control" [value]="inputObject.externalIdentifyDamageParty"
                id="externalKeyDamaged" type="text" name="externalKeyDamaged"
                attr.data-qa="new-dialogue-external-key-damaged-input">
            </div>

            <div class="col-md-3">
              <label rgiRxLabel class="label-core" attr.data-qa="new-dialogue-unique-flux-key-label">
                {{ '_CLAIMS_._IDENTIFIER_UNIQUE_CODE' | translate }}</label>
              <input disabled rgiRxInput class="form-control" [value]="inputObject.identifierUniqueCode"
                id="identifierUniqueCode" type="text" name="identifierUniqueCode"
                attr.data-qa="new-dialogue-unique-flux-key-input">
            </div>

            <div class="col-md-1">
              <label rgiRxLabel class="label-core" attr.data-qa="new-dialogue-date-label">
                {{ '_CLAIMS_._DATE' | translate }}</label>
              <input disabled rgiRxInput class="form-control" [value]="formatData(inputObject.occurrenceDate)" id="date"
                type="text" name="date" attr.data-qa="new-dialogue-date-input">
            </div>

            <div class="col-md-1">
              <label rgiRxLabel class="label-core" attr.data-qa="new-dialogue-type-plate-ctnp-label">
                {{ '_CLAIMS_._CTP_TYPE_PLATE' | translate }}</label>
              <input disabled rgiRxInput class="form-control" [value]="inputObject.typePlateCtnp" id="typePlateCtnp"
                type="text" name="typePlateCtnp" attr.data-qa="new-dialogue-type-plate-ctnp-input">
            </div>

            <div class="col-md-1">
              <label rgiRxLabel class="label-core" attr.data-qa="new-dialogue-license-plate-label">
                {{ '_CLAIMS_._CTP_CAR_LICENSE_PLATE' | translate }}</label>
              <input disabled rgiRxInput class="form-control" [value]="inputObject.plateCtnParty" id="licensePlateUs"
                type="text" name="licensePlateUs" attr.data-qa="new-dialogue-license-plate-input">
            </div>

          </div>
          <div class="row col-md-12">

            <div class="col-md-3">
              <label rgiRxLabel class="label-core">{{'_CLAIMS_._INTERNAL_KEY' | translate}}
                ({{inputObject.roleCompany.description}}) </label>
              <input disabled rgiRxInput class="form-control" [value]="inputObject.internalKey" id="internalkey"
                type="text" name="internalkey" attr.data-qa="new-dialogue-internal-key-input">
            </div>

            <div class="col-md-3">
              <label rgiRxLabel class="label-core">{{'_CLAIMS_._INTERNAL_KEY_DAMAGED' | translate}}
                ({{inputObject.roleCompany.description}})</label>
              <input disabled rgiRxInput class="form-control" [value]="inputObject.internalIdentifyDamageParty"
                id="internalKeyDamaged" type="text" name="internalKeyDamaged"
                attr.data-qa="new-dialogue-internal-key-damaged-input">
            </div>

            <div class="col-md-3">
              <label rgiRxLabel class="label-core" attr.data-qa="new-dialogue-nominative-label">
                {{ '_CLAIMS_._NOMINATIVE' | translate }}</label>
              <input disabled rgiRxInput class="form-control" [value]="state.responseFromSearchList?.nameParty"
                id="nominative" type="text" name="nominative" attr.data-qa="new-dialogue-nominative-input">
            </div>
          </div>
          &nbsp;

        </rgi-rx-expansion-panel>
      </div>
    </ng-container>
    &nbsp;
    <div [hidden]="displayedParam.length == 0" class="accordion-color">
      <rgi-rx-expansion-panel>
        <div rgi-rx-expansion-panel-header>
          <div rgi-rx-expansion-panel-label>{{"_CLAIMS_._PARAMETERIZED_DIALOGUE_DATA"| translate}}</div>
        </div>
        <div class="row">
        <div *ngFor="let param of displayedParam">
          <div class="col-md-3">
            <label rgiRxLabel class="label-core">{{param.parameter}}</label>
            <input disabled rgiRxInput [value]="param.value">
          </div>
        </div>
      </div>
      </rgi-rx-expansion-panel>
    </div>
    <div>
      <rgi-rx-datatable [data]="state.dialogueSummaryObject.messageList" [schema]="schemaTable"
        [pageOptions]="[5,10,20,50]" [expansionModel]="customExpansionModel" [expansionRow]="rowDetail">

        <ng-template rgiRxDataTableCell="greenDot" let-rowValue>
          <div (click)="stampa(rowValue)">
            <!-- rowValue.index == greenDotIndex -->
            <span *ngIf="rowValue.row.greenDot == true" class="rgi-ui-icon-confirmation" style="color: #198739"></span>
          </div>
        </ng-template>

        <ng-template rgiRxDataTableCell="statusElabRequest" let-ctx>
          <span>{{ctx.row.statusElabRequest?.description}}</span>
        </ng-template>
      </rgi-rx-datatable>

      <ng-template #rowDetail let-ctx>
        <div class="row border-round" style="margin: 15px">
          <div class="col-md-2">
            <label rgiRxLabel class="label-core">{{'_CLAIMS_._DATE' | translate}}:</label>
            <span (click)="stampa(ctx)" style="margin-left: 5px">{{ctx.requestDate | date:'dd/MM/yyyy'}}</span>
          </div>
          <div class="col-md-2">
            <label rgiRxLabel class="label-core">{{'_CLAIMS_._NOTIFICATION_DATE' | translate}}:</label>
            <span style="margin-left: 5px">{{ctx.notifyDate | date:'dd/MM/yyyy'}}</span>
          </div>
          <div class="col-md-2">
            <label rgiRxLabel class="label-core">{{'_CLAIMS_._LAST_UPDATE_DATE' | translate}}:</label>
            <span style="margin-left: 5px">{{ctx.update | date:'dd/MM/yyyy'}}</span>
          </div>
          <div class="col-md-2">
            <label rgiRxLabel class="label-core">{{'_CLAIMS_._START_STATUS' | translate}}:</label>
            <span style="margin-left: 5px">{{ctx.statusForward}}</span>
          </div>
          <div class="col-md-1">
            <label rgiRxLabel class="label-core">{{'_CLAIMS_._USER_UPDATE' | translate}}:</label>
            <span style="margin-left: 5px">{{ctx.userRequest}}</span>
          </div>
          <div class="col-md-2">
            <label rgiRxLabel class="label-core">{{'_CLAIMS_._ACTIVITY_TYPE' | translate}}:</label>
            <span style="margin-left: 5px">{{ctx.activity.description}}</span>
          </div>
          <div class="col-md-1">
            <label rgiRxLabel class="label-core">{{'_CLAIMS_._REFERENCE' | translate}}:</label>
            <span style="margin-left: 5px">{{ctx.referenceAnia}}</span>
          </div>
        </div>

        <rgi-rx-tab-group class="tab-color" [selectedIndex]="0">
          <rgi-rx-tab [label]="'_CLAIMS_._PARAMETERS_UPPERCASE' | translate">
            <ng-template rgiRxTabContent>
              <div (click)="stampa(ctx)" class="row col-md-12">
                <div class="col-md-6 dividerDiv">

                  <div (click)="stampa(ctx)" class="col-md-4">
                    <label rgiRxLabel class="label-core" attr.data-qa="new-dialogue-request-label">
                      {{'_CLAIMS_._MESSAGE_REQUEST' | translate}}
                    </label>
                    <input disabled rgiRxInput class="form-control" id="request" type="text" name="request"
                      [value]="ctx.inputRequestCode" attr.data-qa="new-dialogue-request-input">
                  </div>

                  <div class="col-md-8">
                    <label rgiRxLabel class="label-core"></label>
                    <input disabled rgiRxInput class="form-control" [value]="ctx.inputRequestDescription" id="request"
                      type="text" name="request" attr.data-qa="new-dialogue-request-input">
                  </div>

                  <div class="col-md-12">
                    <label rgiRxLabel class="label-core" attr.data-qa="new-dialogue-request-note-label">
                      <!-- {{(state.dialogueSummaryObject.roleCompany.code ==
                      roleCompany.CREDITOR)?('_CLAIMS_._RESPONSE_NOTE' | translate):('_CLAIMS_._REQUEST_NOTE' |
                      translate)}} -->
                      {{ '_CLAIMS_._NOTES' | translate }}
                    </label>
                    <textarea disabled class="mar-bot-20" [value]="ctx.notesThirdParty" rgiRxInput></textarea>
                  </div>


                  <div class="row">
                    <div *ngFor="let parameterGroup of ctx.paramRequest; let i = index ">
                      <div class="col-md-6">
                        <label rgiRxLabel class="label-core" attr.data-qa="req-summary-parameter-label">
                          {{parameterGroup.parameter}}</label>
                        <input disabled rgiRxInput class="form-control mar-bot-10" type="text"
                          [id]="parameterGroup.parameter" [name]="parameterGroup.parameter"
                          [value]="parameterGroup.value" attr.data-qa="req-summary-parameter-parameter-input">
                      </div>
                    </div>
                  </div>

                </div>

                <div class="col-md-6">

                  <div class="col-md-4">
                    <label rgiRxLabel class="label-core" attr.data-qa="new-dialogue-response-label">
                      {{'_CLAIMS_._MESSAGE_RESPONSE' | translate}}</label>
                    <input disabled rgiRxInput class="form-control" [value]="ctx.outputResponseCode" id="response"
                      type="text" name="response" attr.data-qa="new-dialogue-response-input">
                  </div>

                  <div class="col-md-8">
                    <label rgiRxLabel class="label-core"></label>
                    <input disabled rgiRxInput class="form-control" [value]="ctx.outputResponseDescription"
                      id="response" type="text" name="response" attr.data-qa="new-dialogue-response-input">
                  </div>

                  <div class="col-md-12">
                    <label rgiRxLabel class="label-core" attr.data-qa="new-dialogue-request-response-label">
                      <!-- {{(state.dialogueSummaryObject.roleCompany.code == roleCompany.CREDITOR)?('_CLAIMS_._REQUEST_NOTE'
                      | translate):('_CLAIMS_._RESPONSE_NOTE' | translate)}} -->
                      {{ '_CLAIMS_._NOTES' | translate }}
                    </label>
                    <textarea class="mar-bot-20" disabled [value]="ctx.note" rgiRxInput></textarea>
                  </div>

                  <div class="row">
                    <div *ngFor="let parameterGroup of ctx.paramResponse; let i = index ">
                      <div class="col-md-6">
                        <label rgiRxLabel class="label-core" attr.data-qa="req-summary-parameter-label">
                          {{parameterGroup.parameter}}</label>
                        <input disabled rgiRxInput class="form-control mar-bot-10" type="text"
                          [id]="parameterGroup.parameter" [name]="parameterGroup.parameter"
                          [value]="parameterGroup.value" attr.data-qa="req-summary-parameter-parameter-input">
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </ng-template>
          </rgi-rx-tab>


          <rgi-rx-tab [label]="'_CLAIMS_._DOCUMENTS_UPPERCASE' | translate">
            <ng-template rgiRxTabContent>
              <div class="row col-md-12">
                <div class="col-md-6 dividerDiv">

                  <div class="col-md-4">
                    <label rgiRxLabel class="label-core" attr.data-qa="new-dialogue-request-label">
                      {{'_CLAIMS_._MESSAGE_REQUEST' | translate}}</label>
                    <input disabled rgiRxInput class="form-control" id="request" type="text" name="request"
                      [value]="ctx.inputRequestCode" attr.data-qa="new-dialogue-request-input">
                  </div>

                  <div class="col-md-8">
                    <label rgiRxLabel class="label-core"></label>
                    <input disabled rgiRxInput class="form-control" [value]="ctx.inputRequestDescription" id="request"
                      type="text" name="request" attr.data-qa="new-dialogue-request-input">
                  </div>

                  <br>
                  <div class="row">
                    <div style="margin-top: 10px;" class="col-md-12">
                      <rgi-rx-datatable [data]="ctx.docuRequest" [schema]="schemaTableDocuments"
                        class="table-documents">
                      </rgi-rx-datatable>

                    </div>
                  </div>


                </div>

                <div class="col-md-6">

                  <div class="col-md-4">
                    <label rgiRxLabel class="label-core" attr.data-qa="new-dialogue-response-label">
                      {{'_CLAIMS_._MESSAGE_RESPONSE' | translate}}</label>
                    <input disabled rgiRxInput class="form-control" [value]="ctx.outputResponseCode" id="response"
                      type="text" name="response" attr.data-qa="new-dialogue-response-input">
                  </div>

                  <div class="col-md-8">
                    <label rgiRxLabel class="label-core"></label>
                    <input disabled rgiRxInput class="form-control" [value]="ctx.outputResponseDescription"
                      id="response" type="text" name="response" attr.data-qa="new-dialogue-response-input">
                  </div>
                  <br>
                  <div class="row">
                    <div style="margin-top: 10px;" class="col-md-12">
                      <rgi-rx-datatable [data]="ctx.docuResponse" [schema]="schemaTableDocuments"
                        class="table-documents">
                      </rgi-rx-datatable>
                    </div>
                  </div>
                </div>
              </div>
            </ng-template>
          </rgi-rx-tab>


          <rgi-rx-tab [label]="'_CLAIMS_._DISCARDED_UPPERCASE' | translate">
            <ng-template rgiRxTabContent>

              <div class="row">
                <div style="margin-top: 10px;" class="col-md-12">
                  <rgi-rx-datatable [data]="ctx.wasteFieldRecord" [schema]="schemaTableDiscarded"
                    class="table-discarded">
                  </rgi-rx-datatable>

                </div>
              </div>


            </ng-template>
          </rgi-rx-tab>
        </rgi-rx-tab-group>

        <div class="btn-group btn-group-justified">
          <div class="btn-group">
            <button *ngIf="ctx.expCompany != ctx.expRegulation" (click)="resetAdvance(ctx.idHistoryStatus)"
              rgi-rx-button style="margin-top: 10px;" type="button"
              class="btn btn-warning pull-right text-uppercase">{{'_CLAIMS_._BUTTONS_._RESET_ADVANCE' |
              translate}}</button>
          </div>
          <div class="btn-group">
            <!--  -->
            <button *ngIf="ctx.greenDot == true && btnCond.responseCond == true && btnCond.tempRequestCond"
              rgi-rx-button style="margin-top: 10px;" type="submit" (click)="goToResponse(ctx)"
              class="btn btn-warning pull-right text-uppercase">
              {{(state.dialogueSummaryObject.roleCompany.code == roleCompany.CREDITOR)?('_CLAIMS_._BUTTONS_._REQUEST' |
              translate):('_CLAIMS_._BUTTONS_._RESPONSE' | translate)}}
            </button>
          </div>
        </div>
      </ng-template>
    </div>

    <div class="btn-group btn-group-justified">
      <div class="btn-group">
        <button rgi-rx-button style="margin-top: 10px;" type="submit" (click)="goBack()"
          class="btn btn-warning pull-right text-uppercase">
          {{'_CLAIMS_._BUTTONS_._BACK' | translate}}
        </button>
      </div>
      <div style="padding: 20px 0 10px 0;" class="btn-group btn-group-justified ">
        <div class="btn-group">
          <!--  -->
          <button [disabled]="state?.reassociationPossible == false" rgi-rx-button type="button"
            class="btn btn-warning pull-right" (click)="modalClaimOpen()"
            attr.data-qa="dialogue-summary-associate-btn">{{
            '_CLAIMS_._BUTTONS_._REASSOCIATE' | translate }}</button>
        </div>

        <div class="btn-group">
          <button [disabled]="btnCond.cancellationCond == false" (click)="modalCancel.open()" rgi-rx-button type="button"
            class="btn btn-warning pull-right" attr.data-qa="dialogue-summary-cancel-btn">{{
            '_CLAIMS_._BUTTONS_._CANCEL' | translate }}</button>
        </div>
      </div>

    </div>
  </div>


  <rgi-rx-modal #modalClaim>
    <rgi-rx-panel>
      <rgi-rx-panel-header [closeable]="true" (onClose)="modalClaimClose()"></rgi-rx-panel-header>
      <rgi-rx-panel-footer>
        <button rgi-rx-button class="rgi-ui-btn rgi-ui-btn-primary"
          (click)="modalClaimClose()">{{'_CLAIMS_._BUTTONS_._CLOSE' |
          translate}}</button>
        <button rgi-rx-button type="submit" [disabled]="localCausals?.length === 0" (click)="associateClaim()"
          class="rgi-ui-btn rgi-ui-btn-primary">
          {{'_CLAIMS_._BUTTONS_._ASSOCIATE' | translate}}</button>
      </rgi-rx-panel-footer>
      <div class="container-fluid " [formGroup]="associationForm">
        <div class=" row col-md-12">
          <div class="col-md-10">
            <label rgiRxLabel class="label-core" attr.data-qa="modal-claim-dialogue-summary-label">
              {{ '_CLAIMS_._CLAIM' | translate }}</label>
            <input rgiRxInput class="form-control" formControlName="claimNumberPass" id="claim" type="text" name="claim"
              attr.data-qa="modal-claim-dialogue-summary-input">
          </div>

          <div class="col-md-2 mar-x-y-10">
            <button (click)="searchCausal()" rgi-rx-button variant="rounded" style="height: 20px !important;"
              color="primary">
              <span class="rgi-ui-icon-search"></span></button>
          </div>

          <span *ngIf="errorMessage" class="col-sm-12 content-error validate-error mar-x-y-10"
            attr.data-qa="elaboration-request-search-error">
            <span class="rgifont rgi-exclamation-triangle"></span>
            <span>{{errorMessage}}</span>
          </span>

          <!-- <div class="col-md-12" style="margin-top: 10px;">
            <label rgiRxLabel class="label-core" ng-bind="label">{{
              '_CLAIMS_._CAUSAL' | translate }}</label>
            <select rgiRxNativeSelect
              [disabled]="!state.causalResponse.response || state.causalResponse.response.length == 0"
              class="core-select form-control" formControlName="idTypeOfLoss" id="causal" name="causal"
              attr.data-qa="causal-select">
              <option></option>
              <option *ngFor="let claim of state.causalResponse.response; let i = index " [value]="claim.causaleId">
                {{claim.causaleDescr}}</option>
            </select>
            <span class="pull-right arrow-select-native rgifont pnc-select pnc-select-chevron-down"></span>
          </div> -->

          <br>
          <br>
          <div class="row col-md-12 padding-top-25">
            <rgi-rx-datatable *ngIf="state?.causalResponse?.response?.length > 0" [data]="state.causalResponse.response"
              [schema]="schemaActorAs">

              <ng-template rgiRxDataTableCell="selected" let-ctx>
                <div>
                  <rgi-rx-form-field>
                    <input rgiRxInput type="checkbox" (change)="onCheckboxChange(ctx)">
                  </rgi-rx-form-field>
                </div>
              </ng-template>

            </rgi-rx-datatable>
          </div>

        </div>
      </div>
    </rgi-rx-panel>
  </rgi-rx-modal>

  <rgi-rx-modal #modalCancel>
    <rgi-rx-panel>
      <rgi-rx-panel-header [closeable]="true" (onClose)="modalCancel.close()"></rgi-rx-panel-header>
      <rgi-rx-panel-footer>
        <button class="rgi-ui-btn rgi-ui-btn-primary" (click)="modalCancel.close()">{{'_CLAIMS_._BUTTONS_._BACK' | translate}}</button>
        <button class="rgi-ui-btn rgi-ui-btn-primary" (click)="modalCancel.close();cancelDialogue()">{{'_CLAIMS_._BUTTONS_._CONFIRM' | translate}}</button>
      </rgi-rx-panel-footer>
      <p>{{'_CLAIMS_._MESSAGE_._CANCELLATION_DIALOGUE' | translate}}</p>
    </rgi-rx-panel>
  </rgi-rx-modal>

  <rgi-rx-modal #modalOutcome>
    <rgi-rx-panel>
      <rgi-rx-panel-header [closeable]="true" (onClose)="modalClose()"></rgi-rx-panel-header>
      <rgi-rx-panel-footer>
        <button rgi-rx-button class="rgi-ui-btn rgi-ui-btn-primary" (click)="modalClose()">{{'_CLAIMS_._BUTTONS_._CLOSE'
          |
          translate}}</button>
      </rgi-rx-panel-footer>
      <p>{{modalType}}</p>
      <p>{{outcome}}</p>
    </rgi-rx-panel>
  </rgi-rx-modal>

</ng-container>
