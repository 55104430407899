<div [formGroup]="formGroup" id="claim-detail-page" *ngIf="newClaimVisible">
    <div formGroupName="data">
        <div data-qa="claim-insert">

            <div class="row">
                <div class="col-md-12 life-detail-margin-box life-detail-box" id="consulting-detail">

                    <lpc-insured-data [insured]="insured"></lpc-insured-data>

                    <div class="col-md-12 life-detail-first-info">
                        <div class="core-header-icon">
                            <span class="header-icon rgifont rgi-folder"></span>
                            <span class="header-text" translate>lpc_claim_data</span>
                            <span class="pull-right header-text"></span></div>
                    </div>
                    <div class="row">
                        <div class="col-lg-6">
                            <pnc-datepicker [label]="'Data sinistro'" [required]="true" [customFormGroup]="formGroup.get('data')" customFormControlName="claimDate">
                            </pnc-datepicker>
                        </div>
                        <div class="col-lg-6">
                            <pnc-datepicker [required]="true" [label]="'Data Pervenimento Denuncia'" [customFormGroup]="formGroup.get('data')"
                                customFormControlName="notificationDate"></pnc-datepicker>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-lg-6">
                            <pnc-datepicker [label]="'Data Denuncia Sinistro'" [required]="true" [customFormGroup]="formGroup.get('data')"
                                customFormControlName="reportDate"></pnc-datepicker>
                        </div>

                        <div class="col-lg-6">
                            <rgi-rx-form-field>
                              <label rgiRxLabel>
                                <span translate>lpc_Tipo_sinistro</span>
                                <lpc-required required="true"></lpc-required>
                              </label>
                              <select rgiRxNativeSelect [formControlName]="'claimTypeId'"
                                      (change)="onChange($event)"
                                      [attr.data-qa]="'claimTypeId'">
                                <option *ngFor="let claimType of claimTypes" [value]="claimType.type.codice">{{ claimType.type.descrizione }}</option>
                              </select>
                            </rgi-rx-form-field>
                        </div>
                    </div>
                    <div formGroupName="factors" class="row">
                        <lpc-factor-adapter (changeValEmitter)="updateProductFactors($event)" #factorAdapter
                            [form]="this.formGroup.get('data').get('factors')" [factors]="factors" [summary]="false">
                        </lpc-factor-adapter>
                    </div>
                </div>
            </div>
            <pnc-form-message type="error" [messages]="errors" *ngIf="errors?.length >0" detail="validationError"
                data-qa="trend-validation-error-messages">
            </pnc-form-message>
        </div>
    </div>
    <div *ngIf="!updateMode" class="btn-group btn-group-justified">
        <div class="btn-group">
            <button type="button" (click)="back()" class="btn btn-warning btn-secondary" translate>lpc_BACK</button>
        </div>
        <div class="btn-group">
            <button type="button" (click)="onSave()" class="btn btn-warning" translate>lpc_inserisci_denuncia</button>
        </div>
    </div>

    <div *ngIf="updateMode" class="btn-group btn-group-justified">
        <div class="btn-group">
            <button type="button" (click)="backToDetail()" class="btn btn-warning btn-secondary" translate>lpc_BACK</button>
        </div>
        <div class="btn-group">
            <button type="button" (click)="onUpdate()" class="btn btn-warning" translate>lpc_modifica_denuncia</button>
        </div>
    </div>
</div>
