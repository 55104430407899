<div *ngIf="!!data.reinvestDataDetail" data-qa="life-reinvestment-detail">

    <div class="row">
        <div class="col-lg-12">
            <div class="col-md-12 life-detail-margin-box life-detail-box">
                <div class="row life-detail-card-title-container">
                    <div class="row life-detail-title">
                        <div class="card-title">
                            <span class="glyphicon glyphicon-circle-arrow-left arrow-precise-pos"
                                style="cursor: pointer;" (click)="back()" data-qa="back-arrow"></span>
                            <span class="separator"> | </span>
                            <span class="menu-title" translate>lpc_reinvest_data_detail</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div *ngIf="data.reinvestDataDetail" class="col-md-12 life-detail-margin-box life-detail-box">
        <div class="tbld life-detail-margin-top">
            <div class="tbld_row life-detail-tbld_row_header">
                <div class="tbld_col tbld_col_title" translate>lpc_polizza</div>
                <div class="tbld_col tbld_col_title" translate>lpc_tipo_liquidazione</div>
                <div class="tbld_col tbld_col_title" translate>lpc_data_effetto_mov</div>
                <div class="tbld_col tbld_col_title" translate>lpc_netto_liquidazione</div>
                <div class="tbld_col tbld_col_title" translate>lpc_netto_reinvestire</div>
                <div class="tbld_col tbld_col_title" translate>lpc_stato_reinvest</div>
            </div>
            <div *ngFor="let setl of data.reinvestDataDetail?.settlements" class="tbld_row life-detail-tbld_row_header">
                <div class="tbld_col tbld_col_value life-detail-table-border">{{setl.policyNumber}}</div>
                <div class="tbld_col tbld_col_value life-detail-table-border">{{setl.type}}</div>
                <div class="tbld_col tbld_col_value life-detail-table-border">{{setl.movementDate | date:'dd/MM/yyyy'}}</div>
                <div class="tbld_col tbld_col_value life-detail-table-border">{{setl.amount | rgiCtryLNumFormatter :  '' : numberFormatterOptions}}</div>
                <div class="tbld_col tbld_col_value life-detail-table-border">{{setl.reinvest | rgiCtryLNumFormatter :  '' : numberFormatterOptions}}</div>
                <div class="tbld_col tbld_col_value life-detail-table-border">{{setl.state}}</div>
            </div>
        </div>
    </div>

</div>
