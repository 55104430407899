import { PolicyService } from './../../../services/policy-service';
import {Component, forwardRef, Input, OnDestroy, OnInit} from '@angular/core';
import {ControlValueAccessor, UntypedFormControl, UntypedFormGroup, NG_VALUE_ACCESSOR} from '@angular/forms';
import {Subscription} from 'rxjs';
import {PassUtils} from '../../../invest-standalone-session/utils/PassUtils';
import { RgiCtryLayerSymbolFormatterPipe } from '@rgi/rgi-country-layer';

@Component({
  selector: 'lic-fund-button',
  templateUrl: './lic-fund-button.component.html',
  styleUrls: ['./lic-fund-button.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => LicFundButtonComponent),
      multi: true
    },
    RgiCtryLayerSymbolFormatterPipe
  ]
})
export class LicFundButtonComponent implements OnInit, ControlValueAccessor, OnDestroy {

  @Input() public name: string;
  @Input() public description: string;
  @Input() public totalAmount: number;

  currencyCode = '€';
  isPercent: boolean;
  selected: boolean;
  subscriptions: Subscription[] = [];

  formGroup: UntypedFormGroup = new UntypedFormGroup({
    name: new UntypedFormControl(),
    percent: new UntypedFormControl(),
    currency: new UntypedFormControl(),
    id: new UntypedFormControl(),
    profileId: new UntypedFormControl(),
    selected: new UntypedFormControl(),
    funds: new UntypedFormControl(),
    isPercent: new UntypedFormControl(),
    minPercentage: new UntypedFormControl(),
    maxPercentage: new UntypedFormControl(),
    readOnly: new UntypedFormControl()
  });

  constructor(
    protected policyService: PolicyService,
    protected currencySymbolPipe: RgiCtryLayerSymbolFormatterPipe) {
    this.currencyCode = !!this.policyService.currencyCode ?
    currencySymbolPipe.transform(this.policyService.currencyCode) : this.currencyCode;
  }

  ngOnInit() {
    this.subscriptions.push(this.formGroup.valueChanges.subscribe(value => {
      if (this.isPercent) {
        if (isNaN(value.percent)) { value.percent = 0; }
        value.currency = PassUtils.getAmountFromPercent(value.percent, this.totalAmount);
      } else {
        if (isNaN(value.currency)) { value.currency = 0; }
        value.percent = (100 * value.currency) / this.totalAmount;
        value.percent = Math.round(value.percent) === value.percent ? value.percent : value.percent.toFixed(3);
      }
      this.writeValue(value);
      this.onChange(value);
    }));

  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  changeSwitch(e: Event) {
    e.stopPropagation();
    this.isPercent = !this.isPercent;
    this.formGroup.get('isPercent').setValue(this.isPercent);
  }

  onSelectChange() {
    if (!!this.formGroup.get('readOnly').value) { return; }
    this.selected = !this.selected;
    if (!this.selected) {
      this.formGroup.get('percent').setValue(0, {emitEvent: false});
      this.formGroup.get('currency').setValue(0, {emitEvent: false});
    }
    this.formGroup.get('selected').setValue(this.selected);
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
  }

  writeValue(obj: any): void {
    this.formGroup.setValue(obj, {emitEvent: false});
    this.setSelectedAndPercent();
  }

  onChange(obj: any) {

  }

  setSelectedAndPercent() {
    this.isPercent = this.formGroup.get('isPercent').value;
    this.selected = this.formGroup.get('selected').value;
  }
}
