<div *ngIf="(caiOpeningState$ | async) as detail">
  <div class="row mtc-2">
    <rgi-rx-expansion-panel [expanded]="true" #first color="info">
      <!-- Intestazione / Titolo -->
      <div rgi-rx-expansion-panel-header (click)="!expanded ? setAccordion() : null">
        <div class="row rgi-claims-title-section-shared" style="padding:5px;">
          <div class="col-xs-6 col-md-6">
            <label ng-bind="label">
              {{ '_CLAIMS_._OCCURRENCE_DATA' | translate }}
            </label>
          </div>
        </div>
      </div>
      <!-- Form -->
      <div class="row mbc-2">
        <claims-dsc-incident-data [typeOpenComponent]="enumTypeOpenTitle" [isPot]="enumIsClaim"
          [incidentData]="detail.occurrenceData.incidentData"
          [isNewClaim]="detail.reopenFromJs?.isReopenedFromJs ? enumTypeIsNewClaimReopen : enumTypeIsReopened"
          (outputFormIncidentData)="outputFormIncidentData($event)"></claims-dsc-incident-data>
      </div>
      <div class="row mbc-2">
        <claims-dsc-address-detail-component [typeOpenComponent]="enumTypeOpenTitle" [isNewClaim]="true"
          [addressData]="detail.occurrenceData.addressEntity" (outputFormAddress)="outputFormAddress($event)">
        </claims-dsc-address-detail-component>
      </div>
      <div class="row mbc-2">
        <claims-dsc-circumstances-data
            [typeOpenComponent]="enumTypeOpenAccordion"
            [circumstanceIncident]="detail.occurrenceData.circumstanceIncident"
            [generalData]="detail.occurrenceData.generalData"
            [context]="detail.generatedClaim?.claim?.context"
            (outputFormcircumstanceIncident)="outputFormCircumstanceIncident($event)"></claims-dsc-circumstances-data>
      </div>
    </rgi-rx-expansion-panel>
  </div>
  <div class="row mtc-2">
    <claims-dsc-policy-holder [policyHolder]="detail.singleCai.policyHolder"
      [chkInjury]="detail.singleCai.policyHolder.chkInjury"
      [nodoFittSx]="nodoFittSx"
      [listMinorGuardian]="detail.singleCai.listMinorGuardian"
      [roles]="detail.singleCai.roles" [typeOpenComponent]="enumTypeOpenAccordion"
      [selectedNode]="selectedNode" [activeRouteId]="activeRouteId"
      [categorySelected]="detail.detailNewClaimInfo.selectedEventCategory.idCategory"
      [selectedPolicy]="detail.selectedPolicy"
      [categoryList]="detail.allCategories"
      [heirsList]="detail.singleCai.heirsList.policyHolder"
      (outputDataFormHolder)="outputPolicyHolderForm($event)"

      (outputHeir)="outputHolderHeirData($event)">
    </claims-dsc-policy-holder>
  </div>
  <div class="row mtc-2">
    <claims-dsc-vehicle [typeOpenComponent]="enumTypeOpenAccordion" [vehicle]="detail.singleCai.vehicle"
      (outputDataForm)="outputVehicleForm($event)">
    </claims-dsc-vehicle>
  </div>
  <div class="row mtc-2">
    <claims-dsc-insurance-company [typeOpenComponent]="enumTypeOpenAccordion"
      [insuranceCompany]="detail.singleCai.insuranceCompany"
      (outputDataForm)="outputPolicyDataForm($event)">
    </claims-dsc-insurance-company>
  </div>
  <div class="row mtc-2">
    <claims-dsc-owner [typeOpenComponent]="enumTypeOpenAccordion"
      [chkInjury]="detail.singleCai.owner.chkInjury"
      [nodoFittSx]="nodoFittSx"
      [listMinorGuardian]="detail.singleCai.listMinorGuardian" [selectedNode]="selectedNode"
      [owner]="detail.singleCai.owner" [roles]="detail.singleCai.roles"
      [selectedPolicy]="detail.selectedPolicy"
      [categorySelected]="detail.detailNewClaimInfo.selectedEventCategory.idCategory"
      [categoryList]="detail.allCategories"
      [heirsList]="detail.singleCai.heirsList.owner"
      (outputDataForm)="outputOwnerForm($event)"
      (outputHeir)="outputOwnerHeirData($event)">
      >
      <!-- qui -->
    </claims-dsc-owner>
  </div>
  <div class="row mtc-2">
    <claims-dsc-driver [typeOpenComponent]="enumTypeOpenAccordion" [selectedNode]="selectedNode"
    [listMinorGuardian]="detail.singleCai.driver.listMinorGuardianSubject"
      [nodoFittSx]="nodoFittSx"
      [chkInjury]="detail.singleCai.driver.chkInjury"
      [driver]="detail.singleCai.driver" [roles]="detail.singleCai.roles"
      [selectedPolicy]="detail.selectedPolicy"
      [categorySelected]="detail.detailNewClaimInfo.selectedEventCategory.idCategory"
      [categoryList]="detail.allCategories"
      [heirsList]="detail.singleCai.heirsList.driver"
      (outputDataForm)="outputDriverForm($event)"
      (outputHeir)="outputDriverHeirData($event)">
    </claims-dsc-driver>
  </div>
  <div class="row mtc-2" *ngIf="detail.singleCai.damagedParts !== null">
    <claims-dsc-damaged-parts [typeOpenComponent]="enumTypeOpenAccordion"
      [propertyAddress]="detail.singleCai.propertyAddress"
      [vehicle]="detail.singleCai.damagedParts" (sendDamageVehicleData)="outputDamagedPartsForm($event)"
      [claim]="detail.singleCai.claim" [generatedClaim]="detail.generatedClaim.claim"
      (outputAddressData)="outputAddressData($event)"
      >
    </claims-dsc-damaged-parts>
  </div>
  <div class="row mtc-2">
    <claims-dsc-car-bodywork [carBodywork]="detail.singleCai.carBodywork"
      [typeOpenComponent]="enumTypeOpenAccordion" (outputpolicyData)="outputCarbodyworkForm($event)">
    </claims-dsc-car-bodywork>
  </div>
  <div class="row mtc-2">
    <claims-dsc-additional-data [additionalData]="detail.occurrenceData.additionalData"
    [generalData]="detail.occurrenceData.generalData"
    [incidentData]="detail.occurrenceData.incidentData"
    (outputFormAdditionalData)="outputFormAdditionalData($event)"></claims-dsc-additional-data>
  </div>
</div>
