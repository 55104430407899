<div class="col-md-12 life-detail-margin-box life-detail-box" id="consulting-detail" data-qa="subjects-table">
    <div class="row life-detail-card-title-container">
        <div class="row life-detail-title">
          <div class="card-title">
            <span class="header-icon rgifont "></span>
            <span class="menu-title" translate>lpc_life_detail_beneficiaries</span>
          </div>
        </div>
      </div>
    <div class="tbld life-detail-margin-top" data-qa="benefeiciary-table">
      <div class="tbld_row life-detail-tbld_row_header">
        <div class="tbld_col tbld_col_title" translate>lpc_life_detail_beneficiaries</div>
        <div class="tbld_col tbld_col_title"></div>
      </div>
      <div *ngFor="let beneficiary of beneficiaries" class="tbld_row life-detail-subject-box">
        <div class="tbld_col tbld_col_value life-detail-subject-margin" [attr.data-qa]="'beneficiary-type'" translate>{{beneficiary.benefTypeDesc}}</div>
        <div class="tbld_col tbld_col_value life-detail-subject-margin" [attr.data-qa]="'beneficiary-label'" translate>{{beneficiary.description}}
            <span *ngIf="beneficiary.percentage">{{beneficiary.percentage | rgiCtryLNumFormatter: '' : percentFormatterOptions}}</span>
        </div>
      </div>
    </div>
  </div>
