<div [formGroup]="formGroup">
  <div formGroupName="factors">
    <div class="row">
      <div class="col-md-6" [ngClass]="{'consultation': !active, 'active-mode': active }" *ngFor="let factor of getFactorsByGroup()">
        <lpc-tool-conf [definition]="factor"
                       [formControlName]="factor.code" [operationCode]="operationCode"
                       [active]="active"
                       (factorReload)="onFactorReload($event)">
        </lpc-tool-conf>
      </div>
    </div>

    <div class="header-text text-capitalize" *ngIf="!!getFactorsByGroup('beneficiary').length" translate>beneficiary
    </div>
    <div class="row">
      <div class="col-md-6" [ngClass]="{'consultation': !active, 'active-mode': active}" *ngFor="let factor of getFactorsByGroup('beneficiary')">
        <lpc-tool-conf [definition]="factor"
                       [formControlName]="factor.code"
                       [active]="active"
                       (factorReload)="onFactorReload($event)">
        </lpc-tool-conf>
      </div>
    </div>
  </div>

  <div *ngIf="active && !!disinvestmentProfiles.length" formGroupName="disinvestmentProfiles">
    <div class="disinvestmentsfunds-header">
      <span class="header-disinvestmentsfunds" translate>lpc_Disinvestmentsfunds</span>
    </div>
    <div *ngFor="let profile of disinvestmentProfiles" [formGroupName]="profile.id">
      <div formGroupName="funds">
        <lpc-checkbox-accordion [active]="active" *ngFor="let fund of profile.funds"
                                [toolName]="fund.description"
                                (toolCheck)="onToolCheck(profile.id, fund.id, $event)"
                                [initialOpen]="disinvestments.get(profile.id + '_' + fund.id)"
                                [isSelected]="disinvestments.get(profile.id + '_' + fund.id)">
          <div class="row" *ngIf="!isProgressiveSwitch; else showDisinvestmentAmount">
            <div class="col-sm-6 col-md-4 col-lg-3">
              <lpc-percent-form-group
                *ngIf="disinvestments.get(profile.id+'_'+fund.id)"
                [formControlName]="fund.id"
                [label]="'lpc_threshold_percentage' | translate"
                [required]="true">
              </lpc-percent-form-group>
            </div>
          </div>
          <ng-template #showDisinvestmentAmount>
            <div class="form-group">
              <div class="row">
                <div class="col-lg-12">
                  <label class="label-core" translate>lpc_disinvestment_amount</label>
                  <lpc-required required="true | translate"></lpc-required>
                </div>
                <input
                  class="col-lg-6 form-control"
                  type="number"
                  [formControlName]="fund.id"
                  data-qa="disinvestment_amount"
                >
              </div>
            </div>
          </ng-template>
        </lpc-checkbox-accordion>
      </div>
    </div>
  </div>


  <div class="example-row">
    <pnc-checkbox *ngIf="showFundDistribution && !!investmentProfiles.length && active"
      formControlName="defaultFunds">
    <span translate>lpc_same_fund_distribution</span>
    </pnc-checkbox>
  </div>


  <div class="distribution-border" *ngIf="unchangedDistro()" translate>
    lpc_same_fund_distribution
  </div>

  <div class="distribution-border" *ngIf="changedDistro()" translate>
    lpc_not_same_fund_distribution
  </div>

  <div *ngIf="!!investmentProfiles.length && showInvestmentProfiles && active" class="row">
    <ng-container *ngIf="!isOnlyOneFundAvailable">
      <lpc-karma-fund-step
        formControlName="investmentProfiles"
        [totalAmount]="totalInvestAmount"
        [definition]="investmentProfiles"
        [sliderProperty]="sliderProp">
      </lpc-karma-fund-step>
    </ng-container>
    <ng-container *ngIf="isOnlyOneFundAvailable">
      <lpc-karma-fund-table
        [profiles]="investmentProfiles"
        [totalAmount]="totalAmount"
        [showAmountColumnOnViewOnly]="false"
        [enableOpenListDefault]="true">
      </lpc-karma-fund-table>
    </ng-container>
  </div>
</div>
