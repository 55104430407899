import { ClaimsTranslationsKeys } from './claims-translations-keys';
/**
 * MORE READABILITY ADVICE
 * Please maintain an alphabetical order in each section
 */
// tslint:disable:max-line-length
export const CLAIMS_EU_ES: ClaimsTranslationsKeys = {
    _CLAIMS_TITLE_CARD_CAI_: 'Ezbehar berria',
    _CLAIMS_TITLE_DIALOGUE_DASHBOARD_: 'Dialogue dashboard',
    _CLAIMS_TITLE_TIREA_DASHBOARD_: 'Tirea Payment Board',
    _CLAIMS_TITLE_DIALOGUE_HANDLER_LIST: 'Dialogue List',
    _CLAIMS_TITLE_PAYMENT_TABLE_TYREA: 'Payments table Tirea',
    _INVOICE: 'Invoice',
    _NEW_PAYMENT: 'New payment',
    _CLAIMS_CONNECTOR: 'Claims Connector',
    _CHAT: 'CHAT',
    _EDIT_CLAIMS: 'Edit Claims',
    _ACTIVITY: 'Jarduera',
    _CLAIMS_PILLS: 'Claims Pills',
    _AUTOSOFT_DASHBOARD: 'Autosoft Dashboard',
    _DOSSIER_MANAGEMENT: 'Dossier management',
    _CLAIM_DETAIL: 'Claim detail',
    _NEW_FNOL: 'New FNOL',
    _RCA_QUESTIONNAIRE: 'Cuestionario RCA',
    _CLAIM_SUBJECTS: 'Claim subjects',
    _LETTER_TEMPLATES: 'Gutun-ereduak',
    _LIMITS_MANAGEMENT: 'Mugak kudeatzea',
    _CLAIMS_FROM_ELASTIC: 'Claims from elastic',
    _COMPENSATION_LIST: 'Compensation list',
    _EXPERTS: 'Experts',
    _TIMELINE: 'Timeline',
     _INVOICES_LIST: 'Faktura-zerrenda',
     _UPDATE_RESERVE: 'Update reserve',
    _CLAIMS_: {
        _TAX_REGIME: 'Régimen Fiscal',
        _IVA_REGIME: 'Régimen del IVA',
        _AGGREGATE_TAX_REGIME: 'Régimen fiscal agregado',
        _AGGREGATE_IVA_REGIME: 'Régimen de IVA agregado',
        _FISCAL_DATA: 'Datos fiscales',
        _ACCOUNTING_DATE: 'Kontabilitate-data',
        _ACT_ACTIVE: 'Egintza aktiboa',
        _ACT_CLOSED: 'Egintza itxia',
        _ACT_EVASION_DATE: 'Osatutako egintzaren data',
        _ACTION: 'Ekintza',
        _ACTIVE_CHATS: 'Txat aktiboak',
        _ACTUAL_DATE_OF_OCCURRENCE: 'Ezbeharraren benetako data',
        _ACTUAL_TIME_OCCURRENCE: 'Ezbeharraren benetako ordua',
        _ADD_ASSET: 'Gehitu aseguratutako ondasuna',
        _ADDRESS: 'Helbidea',
        _ADMIN_LEVEL_1: 'Administrazio-maila 1',
        _ADMIN_LEVEL_2: 'Administrazio-maila 2',
        _ADMIN_LEVEL_3: 'Administrazio-maila 3',
        _ADMINISTRATIVE_REGULARITY: 'Administrazio-erregulazioa',
        _AGENCY_UNIQUE_KEY: 'Agentziaren gako bakarra',
        _AGENCY: 'Agentzia',
        _ALLEGATION_DESCRIPTION: 'Salaketaren deskribapena',
        _AMOUNT_CLEARED: 'Likidatutako zenbatekoa',
        _AMOUNT_HOLD_TAX: 'Konturako atxikipenaren zenbatekoa',
        _AMOUNT_TO_BE_PAYD: 'Ordaindu beharreko zenbatekoa',
        _ASSET: 'Aseguratutako ondasuna',
        _ASSETS_DATA: 'Aseguratutako ondasunaren datuak',
        _ASSET_DESCRIPTION: 'Aseguratutako ondasunaren deskribapena',
        _ASSIGNED_TO_A_GROUP: 'Talde bati esleitua',
        _ASSIGNMENT_SELECTION: 'Esleipena hautatzea',
        _BODY: 'Gorputza',
        _CANCELED_BY: 'Honek ezeztatu du',
        _CANCEL_REQUEST_DATE: 'Eskaera data bertan behera utzi',
        _CAP: 'Posta-kodea',
        _CASH_PREVIDENTIAL_AMOUNT: 'Gizarte kotizazioen funtsaren zenbatekoa',
        _CERTIFIED_OCCURRENCE_DATE: 'Ezbeharraren data ziurtatu da',
        _CERTIFIED_EVENT_DATE: 'Gertaeraren data ziurtatu da',
        _CHASSIS_NUMBER: 'Txasis-zenbakia',
        _CDL_RECEIPT_DATE: 'CHCren jasotze-data',
        _CHAT_HISTORY: 'Txat-historia',
        _CHATS_WITH_ME: 'Txatak nirekin',
        _CITY: 'Hiria',
        _CIVIC_NUMBER: 'Kalearen zenbakia',
        _CLAIM_NUMBER_RECEIVED: 'Ezbehar-zenbakia jaso da',
        _CLAIM_SELECTION: 'Ezbeharra hautatzea',
        _CLAIMS: 'EZBEHARRAK',
        _CLAIMS_TECHNICAL_STATUS: 'Ezbeharraren egoera teknikoa',
        _COMPLAINT_DATA: 'Salaketaren datuak',
        _CLAIM_NUMBER: 'Ezbehar-zenbakia',
        _CLAIM_POLICY: 'Claim Policy',
        _CLICK_HERE: 'sakatu hemen',
        _CLOSE: 'Close',
        _COHERENT: 'Bat etortzen da',
        _COLLECTION_DATE: 'Kobrantzaren data',
        _COMMENTS: 'Iruzkinak',
        _COMPULSORY_QUEST: 'Compulsory question: ',
        _CONFIRM: 'Baieztatu',
        _CONTRACTOR: 'Kontratista',
        _CORPORATE_LITIGATION: 'Enpresa-auzia',
        _COUNT: 'Zenbatu',
        _COUNTERPARTY_PLATE_NUMBER: 'Claim counteparty plate number',
        _COMPANY_DESCRIPTION: 'Company DEscription',
        _CREATION: 'Sorrera',
        _DAMAGE_CATEGORY: 'Kalteen kategoria',
        _DAMAGE_POSITION: 'Kaltearen posizioa',
        _DAMAGED: 'Kaltetutako alderdia',
        _DATE_RECEIVED_INTERMEDIARY: 'Bitartekaria jasotzeko data',
        _DAMAGE_TO_THINGS: 'Kalteak ondasunei',
        _DAMAGED__ASSET: 'Kaltetutako alderdia / Aseguratutako ondasuna',
        _DAMAGED__INVOLVED_ITEM_DATA: 'Kaltetutako/inplikatutako elementuaren datuak',
        _DAMAGED_ASSETS: 'Aseguratutako ondasun kaltetuak',
        _DAMAGED_PARTS: 'Kaltetutako alderdiak',
        _DEADLINE_DATA: 'Epearen datuak',
        _DELETED: 'Kenduta',
        _DEROGATION_LEVEL: 'Baimen-maila',
        _DESELECT_ALL: 'Desautatu dena',
        _DETAILS: 'Informazioa',
        _LAST_EXECUTION_DETAILS: 'Last execution details',
        _SAVE_NEW_REQUEST: 'Save new request',
        _DEPARTMENT: 'Saila',
        _DOCUMENT_MANAGEMENT: 'Dokumentuak kudeatzea',
        _DOCUMENTS_UPLOAD: 'Dokumentuak kargatzea',
        _DRIVER: 'Gidaria',
        _DRIVER_MANDATORY: 'Gidaria beharrezkoa da',
        _DRIVER_LICENSE_TITLE: 'Gidabaimena',
        _CATEGORY: 'Kategoria',
        _ADD_CATEGORY: 'Gehitu kategoria',
        _RELEASED_BY: 'Honek askatu du',
        _RELEASED_DATE: 'Askapen-data',
        _EXPIRATION_DATE: 'Epemuga',
        _DUTIES_AMOUNT: 'Zergen zenbatekoa',
        _EDIT_LIMIT: 'Editatu muga',
        _EMAIL: 'Helbide elektronikoa',
        _ERROR_CODE: 'Errore kodea',
        _EXCLUDE_FROM_CALCULATION: 'Kendu kalkulutik',
        _EXCLUDED_FROM_CALCULATION: 'Kalkulutik kendu da',
        _EXPERT: 'Aditua',
        _EFFECTIVE_COMPLAINT_DATE: 'Salaketaren benetako data',
        _EXPERT_DATA: 'Perituaren datuak',
        _EXPERT_TYPE: 'Expert type',
        _FAX: 'Faxa',
        _FEES_AMOUNT: 'Ordainsarien zenbatekoa',
        _FIRST_REGISTRATION_DATE: 'Erregistroaren lehen data',
        _FISCAL_CODE__VAT_CODE: 'Kode fiskala/BEZ zenbakia',
        _FNOL_DATA_COLLECTION: 'FNOL datuak jasotzea',
        _GENDER: 'Generoa',
        _GENERAL_DATA: 'Datu orokorrak',
        _GIVEN_FIRST_REGISTRATION: 'EU_ES_Given first registration',
        _GREEN_CARD_NUMBER: 'Karta berdearen zenbakia',
        _GROSS: 'Gordin',
        _GROSS_EXCESS: 'Gordina',
        _INCONSISTENT_ROW_AT_LINE_: 'Errenkada ez da bat etortzen lerroan',
        _METAINFO: 'Metainfo',
        _INDEMNITY_AMOUNT: 'Kalte-ordainaren zenbatekoa',
        _INITIAL_LIMIT_AMOUNT: 'Hasierako mugaren zenbatekoa',
        _INITIAL_LIMIT_VALUE: 'Hasierako mugaren balioa',
        _INJURY: 'Lesioa',
        _INJURY_CODE: 'Lesioen kodea',
        _INCIDENT_DATA: 'Gertakariaren datuak',
        _INJURY_PHYSICAL: 'Lesio fisikoa',
        _INPS_CONTRIB_AMOUNT: 'INPS-eri egindako ekarpenaren zenbatekoa',
        _INSERT: 'Insert',
        _INS_DATE: 'EU_ES_Insertion date',
        _INSERT_NEW_REQUEST: 'Txertatu eskaera berria',
        _INSERTION_DATE: 'Insertion date',
        _INSPECTOR: 'Ikuskatzailea',
        _INSTANCE: 'Instance',
        _INSURANCE_COMPANY: 'Aseguru-etxea',
        _INSURED_ACTIVITY: 'Aseguratutako jarduera',
        _INSUREDS: 'Asegurudunak',
        _INSURED_REPORT_DATE: 'Asegurudunen txostenen datuak',
        _INVALID_FIELDS: 'Eremua ez da baliozkoa',
        _INVOICE: 'Faktura',
        _INVOICE_AMOUNT: 'Fakturaren zenbatekoa',
        _INVOICE_DATA: 'Fakturaren datuak',
        _INVOICE_DETAILS: 'Fakturaren datuak',
        _INVOICE_NUMBER: 'Faktura-zenbakia',
        _INVOICE_TYPE: 'Faktura mota',
        _INVOICES: 'Fakturak',
        _LETTER_DEROGATION_LEVEL: 'Gutunaren baimen-maila:',
        _LETTER_TEMPLATE_SAVED: 'Gutun-eredua gorde da',
        _LEVEL: 'Maila',
        _LEVEL_CODE: 'Mailaren kodea',
        _LIMITS_POLICY_N: 'Hurrengo polizaren mugak',
        _MAIN_SUBSTATUS: 'Azpiegoera nagusia',
        _MANAGE_PHASE: 'Kudeaketa-fasea',
        _MANAGEMENT_NOTE: 'Kudeaketa-oharra',
        _MANDATORY_DOCUMENTS: 'Derrigorrezkoa dokumentuak',
        _MANUAL_EXCESS: 'Gidalerro-soberakina',
        _MANUFACTURER: 'Fabrikatzailea',
        _MATERIAL_DAMAGE: 'Kalte materialak',
        _MERGE_ABSORPTION_DATE: 'Merge absorption date',
        _MESSAGE: 'Mezua',
        _MESSAGE_RESPONSE: 'Mezuaren erantzuna',
        _MESSAGE_REQUEST: 'EU_ES_Request Message',
        _MESSAGES: 'Mezuak',
        _MINOR: 'Adingabea',
        _MINORGUARDIAN: 'ADINGABEAREN TUTOREA',
        _MINISTERIAL_BRANCH: 'Ministerio-klasea',
        _MOBILE_PHONE: 'Telefono mugikorra',
        _MODELS_LIST: 'Txantiloi-zerrenda',
        _MODIFY_FNOL: 'Aldatu FNOL',
        _MOTOR_CARD: 'Motorraren txartela',
        _NAME: 'Izena',
        _NATION_ADDRESS: 'Herriaren helbidea',
        _NATIONALITY: 'Nazionalitatea',
        _NET: 'Garbia',
        _NETGROSS_EXCESS: 'Soberakin garbia/gordina',
        _NEW_COMPANY: 'Enpresa berria',
        _NEW_LETTER_MODEL: 'Gutun-eredu berria',
        _NEW_LIMIT: 'Muga berria',
        _NEW_UPLOAD: 'Karga berria',
        _NORMALIZE: 'Normalizatu',
        _NOT_COHERENT: 'Ez da bat etortzen',
        _NOTIFICATION_DATE: 'Jakinarazpen-data',
        _NOT_TAXABLE_AMOUNT: 'Zerga-oinarri salbuetsia',
        _OBJECT: 'Objektua',
        _OCCURRENCE_TIME: 'Ezbeharraren ordua',
        _OCCURRENCE_DESCRIPTION: 'Ezbeharraren deskribapena',
        _OBSERVATIONS: 'Oharrak',
        _OPENING_DATE_FROM: 'Irekiera-data, noiztik',
        _OPENING_DATE_TO: 'Irekiera-data, noiz arte',
        _OTHER_DOCUMENTS: 'Beste dokumentu atzuk',
        _OTHER: 'Beste batzuk',
        _OWNER: 'Jabea',
        _PARTY_INVOLVED: 'Inplikatutako alderdia',
        _PARKED_VEHICLE: 'Aparkatutako ibilgailua',
        _PRODUCT: 'Produktua',
        _PASSENGER: 'Bidaiaria',
        _PAYMENT_AMOUNT: 'Ordaindu beharreko zenbatekoa',
        _PEC: 'Ehunekoa',
        _PERC_PREV_FUND_1: '1. funtseko aurreikusitako ehunekoa',
        _PERC_PREV_FUND_2: '2. funtseko aurreikusitako ehunekoa',
        _PERC_WITH_HOLD_TAX: 'Konturako atxikipenaren ehunekoa',
        _PERCENTAGES: 'Ehunekoak',
        _PERSON: 'Pertsona',
        _PHONE: 'Telefonoa',
        _PHYSICAL_INJURY: 'Lesio fisikoa',
        _PLATE_NUMBER: 'Matrikula zenbakia',
        _POLICY: 'Poliza',
        _POLICY_DETAIL: 'Polizaren inguruko informazioa',
        _POLICY_HOLDER__INSURED: 'Hartzailea / Aseguruduna',
        _POLICY_LIST: 'Poliza-zerrenda',
        _PROVINCE: 'Probintzia',
        _REAL_NAME: 'Benetako izena',
        _RECEIVED_DATE: 'Jasotze-data',
        _RECEIVED_DATE_FROM: 'Jasotze-data, noiztik',
        _RECEIVED_DATE_TO: 'Jasotze-data, noiz arte',
        _RECIPIENT: 'Hartzailea',
        _REGIME: 'Erregimena',
        _REGISTRY: 'Erregistroa',
        _REJECT_WORKED: 'Baztertu landutakoa',
        _REPORT: 'Txostena',
        _RESERVE: 'Erreserba',
        _RESIDUE_LIMIT_AMOUNT: 'Hondar-mugaren zenbatekoa',
        _RESIDUE_LIMIT_VALUE: 'Hondar-mugaren balioa',
        _RISK_DESCRIPTION: 'Arriskuaren deskribapena',
        _ROUNDING_AMOUNT: 'Zenbateko biribildua',
        _SAP_CODE: 'SAP kodea',
        _SAP_RECORDING_DATE: 'SAP erregistro-data',
        _SDI_CODE: 'SDI kodea',
        _SEARCH_DOCTOR: 'Bilatu medikua',
        _SELECT_ALL: 'Hautatu dena',
        _SELECT_ASSIGNMENT: 'Hautatu esleipena',
        _SELECT_AT_LEAST_ONE_USER: 'Hautatu erabiltzaile bat, gutxienez',
        _SELECT_BY_DEFAULT: 'Hautatu modu lehenetsian',
        _SELECT_CLAIM: 'Hautatu ezbeharra',
        _SELECT_POLICY: 'Hautatu poliza',
        _SELECT_RECIPIENT: 'Hautatu hartzailea',
        _SELECT_THE_CORRESPONDING_ASSIGNMENT_FROM_THE_LIST: 'Hautatu dagokion esleipena zerrendan',
        _SELECT_THE_CORRESPONDING_CLAIM_FROM_THE_LIST: 'Hautatu dagokion ezbeharra zerrendan',
        _SELECT_THE_CORRESPONDING_TYPE_OF_LOSS_FROM_THE_LIST: 'Hautatu dagokion ezbehar mota zerrendan',
        _SELECT_TYPE_OF_LOSS: 'Hautatu ezbehar mota',
        _SELECTECT_SUBJECT: 'Hautatu alderdia',
        _SENDING_DATE: 'Bidalketa data',
        _SIC_OUTCOME: 'SIC emaitza',
        _SIMILAR_COMPLAINTS: 'Antzeko salaketak',
        _SIMILAR_SUBJECT: 'Antzeko alderdia',
        _STAMP_DUTY_AMOUNT: 'Egintza juridiko dokumentatuen gaineko zergaren zenbatekoa',
        _SUBJECT_NAME: 'Alderdiaren izena',
        _SUBJECT_SURNAME_OR_DENOMINATION: 'Alderdiaren abizena(k) edo deitura',
        _SUBTYPOLOGY: 'Azpitipologia',
        _SUBTYPOLOGY_CODE: 'Azpitipologiaren kodea',
        _SUIT_NUMBER: 'Demanda-zenbakia',
        _SURNAME: 'Abizena(k)',
        _SX_SUBJECT: 'SX_Subjektua',
        _TAX_ID: 'Identifikazio fiskala',
        _TECHNICAL_COVERAGE: 'Estaldura teknikoa',
        _TAXABLE_AMOUNT: 'Zerga-oinarria',
        _TOTAL_DISTRUCTION: 'Ezeztapena',
        _THINGS: 'Ondasunak',
        _THIRD_PARTY: 'Bitartekariak',
        _TO_BE_EVALUATED: 'Ebaluazioaren zain',
        _TO_BE_REEVALUATED: 'Ebaluatzeke',
        _TO_SELECT: 'hautatu',
        _TOPONYM: 'Lekuaren izena',
        _TOTAL: 'Guztira',
        _TOT_AMOUNT_UNIT: 'Unitatearen guztizko zenbatekoa',
        _TOTAL_RESERVES_AMOUNT: 'Erreserben zenbatekoa, guztira',
        _TYPE_OF_LOSS_DESCRIPTION: 'Ezbehar mota deskribatzea',
        _TYPE_OF_LOSS_SELECTION: 'Ezbehar mota hautatzea',
        _TYPE_OF_DAMAGE: 'Kalte mota',
        _TYPE_CIVIL__PENAL: 'Mota zibila/penala',
        _UNIQUE: 'Bakarra',
        _UPDATE_REASON: 'Eguneratu arrazoia',
        _USER_ASSOCIATION: 'Erabiltzailea lotzea',
        _USER_BY_WORKLOAD: 'Erabiltzailea (lan-kargaren arabera)',
        _USER_DATA: 'Erabiltzailearen datuak',
        _USER_DEROGATION_LEVEL: 'Erabiltzailearen baimen-maila',
        _VALIDITY_END_DATE: 'Baliozkotasunaren amaiera-data',
        _VALIDITY_START_DATE: 'Baliozkotasunaren hasiera-data',
        _VAT_AMOUNT: 'IVA zenbatekoa',
        _VAT_RATE: 'BEZ mota',
        _VEHICLE: 'Ibilgailua',
        _VISIBLE_DAMAGE_ON_VEHICLE: 'Ageriko kalteak ibilgailuan',
        _NUMBER_CLAIM: 'Ezbehar-zenbakia',
        _OCCURRENCE_DATE: 'Ezbeharraren data',
        _CLAIM_BRANCH: 'Ezbeharraren sukurtsala',
        _CLAIM_TYPE: 'Ezbehar mota',
        _POLICY_NUMBER: 'Poliza-zenbakia',
        _POLICY_NR: 'Poliza zk.',
        _CDL: 'CHC',
        _ADJUSTER: 'Likidatzailea',
        _PROCEDURE_NUMBER: 'Prozedura-zenbakia',
        _COUNTERPART: 'Kontrako alderdia',
        _COUNTERPARTS: 'Counterparts',
        _INSURED: 'Aseguruduna',
        _COMPLAINT_DATE: 'Salaketaren data',
        _COVERTURE_TYPE: 'Estaldura mota',
        _CLAIM_NUMBER_STATUS: 'Ezbeharraren zenbakia eta egoera',
        _COMPLAINT_NUMBER_STATUS: 'Salaketaren zenbakia eta egoera',
        _OCCURRENCE_DATA: 'Gertakariaren datuak',
        _DOCUMENTS: 'Dokumentuak',
        _CANCELLATION_DATE: 'Ezeztapen-data',
        _DESCRIPTION: 'Deskribapena',
        _EFFECT_DATE: 'Indarraldiaren data',
        _INVALID_POLICY: 'Poliza ez da baliozkoa',
        _POLICY_HOLDER: 'Titularra',
        _UNITS: 'Unitatea',
        _VALID_POLICY: 'Poliza baliozkoa',
        _MANAGEMENT_NODE: 'Kudeaketa-nodoa',
        _CONTRACT_STATUS: 'Kontratuaren egoera',
        _FRACTIONING: 'Epekako ordainketa',
        _ISSUE_FRACTIONING: 'Epekako jaulkipena',
        _POLICY_TYPE: 'Poliza mota',
        _ISSUE_DATE: 'jaulkipen-data',
        _COVERAGE_EXPIRATION_DATE: 'Estalduraren iraungitze-data',
        _COINSURANCE_SHARE: 'Koaseguru-kuota',
        _INITIAL_EFFECTIVE_DATE: 'Indarraldiaren hasiera-data',
        _RESCINDABILITY_DATE: 'Hutsaltze-data',
        _COINSURANCE_TYPE: 'Koaseguru mota',
        _PREMIUM_ADJUSTMENT: 'Prima doitzea',
        _INDEXING: 'Indexazioa',
        _LPS: 'LPS',
        _NO: 'Ez',
        _YES: 'Bai',
        _INDIRECT_COINSURANCE: 'Zeharkako koasegurua',
        _MIGRATED: 'Migratuta',
        _REINSURANCE: 'Berrasegurua',
        _BONDED: 'Bermatuta',
        _TACIT_RENEWAL: 'Berritze tazitua',
        _SUBJECTS: 'Alderdiak',
        _ARCHIVE_SUBJECTS: 'Alderdien fitxategia',
        _LOCATIONS: 'Kokalekuak',
        _PRINCIPAL: 'Nagusia',
        _PROPERTY_ADDRESS: 'Propietatearen helbidea',
        _SECONDARY: 'Bigarren mailakoa',
        _ACTIVITIES: 'Jarduerak',
        _VEHICLE_DATA: 'Ibilgailuaren informazioa',
        _CLASS: 'Klasea',
        _EXTENSION: 'Luzapena',
        _SPECIAL_ABBREVIATION: 'Laburdura berezia',
        _PLATE_TYPE: 'Matrikula mota',
        _BRAND: 'Marka',
        _MODEL: 'Modeloa',
        _FACTORS: 'Faktoreak',
        _CLAUSES: 'Klausulak',
        _ADDITIONAL_DATA: 'Datu gehigarriak',
        _TYPE_OF_AUTHORITY: 'Autoritate mota',
        _DESCRIPTION_AUTHORITY: 'Autoritateen deskribapena',
        _CATASTROPHIC_EVENT: 'Hondamendia',
        _DESCRIPTION_EVENT: 'Gertakariaren deskribapena',
        _ANTI_FRAUD_CATHEGORY: 'Iruzurraren aurkako kategoria',
        _LAST_AUDIT_DATE: 'Auditoretzaren azken data',
        _LIQUIDABLE: 'Likidagarria',
        _NATION: 'Herrialdea',
        _ZIP_CODE: 'Posta kodea',
        _BODYWORK: 'Karrozeria',
        _SELECT: 'Hautatu',
        _DENOMINATION: 'Deitura',
        _TELEPHONE: 'Telefonoa',
        _ACCIDENT_CIRCUMSTANCE: 'Istripuaren inguruabarra',
        _BAREMS: 'Eskalak',
        _BLACK_BOX_COHERENCE: 'Bat-etortzea kutxa beltzarekin',
        _ACCIDENTS: 'Istripuak',
        _INSURED_LIABILITY_PERC: 'Aseguratutako erantzukizun-ehunekoa',
        _EVENT_DENIAL: 'Ukatze-kasuak',
        _SIGN_TYPE: 'Seinale mota',
        _INSURED_VEICHLE_POSITION: 'Aseguratutako ibilgailuaren posizioa',
        _THIRD_PART_VEICHLE_POSITION: 'Hirugarrenen ibilgailuaren posizioa',
        _CALCULATED_LIABILITY: 'Kalkulatutako erantzukizuna',
        _LIABILITY_TYPE: 'Erantzukizun motak',
        _COUNTERPART_LIABILITY_PERC: 'Kontrako alderdiaren erantzukizun-ehunekoa',
        _DECLARED_LIABILITY: 'Aitortutako erantzukizuna',
        _ANIA_CLASSIFICATION: 'ANIA sailkapena',
        _APPLY_TECH_LIABILITY_FORCED: 'Aplikatu derrigorrezko erantzukizun teknikoa',
        _NOTE: 'Oharra',
        _APPLY_ANIA_LIABILITY_FORCED: 'Aplikatu derrigorrezko ANIA erantzukizuna',
        _FORCE_REASON: 'Behartu arrazoia',
        _FORCED_LIABILITY: 'Behartu erantzukizuna',
        _EDIT_ACCIDENTS: 'Editatu istripuak',
        _USE: 'Erabilera',
        _BUTTONS_: {
            _ACTIONS: 'EKINTZAK',
            _BACK: 'ATZERA',
            _CLOSE: 'ITXI',
            _NEXT: 'HURRENGOA',
            _NEW: 'BERRIA',
            _CANCEL: 'EZEZTATU',
            _CONFIRM: 'BAIEZTATU',
            _EXIT: 'IRTEN',
            _SAVE: 'GORDE',
            _EMPTY: 'HUTSIK',
            _FIND: 'BILATU',
            _SHOW: 'ERAKUTSI',
            _HIDE: 'EZKUTATU',
            _INSERT: 'SARTU',
            _NEW_DIALOGUE: 'NEW DIALOGUE',
            _ASSOCIATE: 'ASSOCIATE',
            _REQUEST: 'REQUEST',
            _RESET_ADVANCE: 'RESET ADVANCE',
            _SAVE_PES: 'SAVE PES',
            _CORRECT: 'CORRECT',
            _ADD: 'ADD',
            _SEARCH: 'Bilatu',
            _IBD: 'IBD',
            _OK: 'OK',
            _ADD_NOTE: 'ADD NOTE',
            _FAILED_TRANSMISSION: 'FAILED TRANSMISSION',
            _EVENTS: 'EVENTS',
            _SEND: 'Bidali',
            _DOWNLOAD_DOCUMENTI_SELEZIONATI: 'DOWNLOAD SELECTED DOCUMENTS',
            _DOWNLOAD_CLAIM_DOCUMENTS: 'DOWNLOAD CLAIM DOCUMENTS',
            _DOCVIEWER: 'DOCVIEWER',
            _DOWNLOAD_POLICY_DOCUMENTS: 'DOWNLOAD POLICY DOCUMENTS',
            _EDIT: 'Edit',
            _NEW_REQUEST: 'New Request',
            _RERUN: 'Rerun',
            _RESERVATION: 'RESERVATION',
            _ONDEMAND: 'ONDEMAND',
            _NEW_MANUAL_DEADLINE: 'Eskuzko epe berria',
            _UPLOAD: 'UPOLAD',
            _TRANSMISSION: 'TRANSMISSION',
            _MODELS_LIST: 'Models list',
            _NEW_LETTER_MODEL: 'New letter model',
            _DELETE: 'DELETE',
            _ADD_ACTOR: 'ADD ACTOR',
            _RETRIEVE_INFORMATION: 'RETRIEVE INFORMATION',
            _REASSOCIATE: 'Reassociate',
            _MODIFY: 'MODIFY',
            _RESPONSE: 'RESPONSE',
        },
        _MESSAGE_: {
            _NO_RESULT: 'Erantzunik ez',
            _OPENED_CLAIM_DO_ISSUE: 'Ezbeharra zitazio judizial baten ondorioz ireki da',
            _NOT_IDENTIFIED_WITNESS: 'Identifikatu gabeko lekukoa',
            _SELECT_ONLY_THE_BOXES_USEFUL_FOR_DESCRIPTION: 'Hautatu soilik erabilera-koadroak deskribapenerako',
            _SELECT_AT_LEAST_ONE_CAUSAL: 'Hautatu gutxienez arrazoi bat jarraitu ahal izateko',
            _INVALID_DATE_INCIDENT: 'Sartutako data ezin da jakinarazitako dataren ondorengoa izan',
            _INVALID_REPORTED_DATE_INCIDENT: 'Sartutako data ezin da izan istripuaren data baino lehenagokoa',
            _INVALID_RECEIVED_DATE_INCIDENT: 'Sartutako data ezin da izan txostenaren dataren aurrekoa',
            _INVALID_CHC_DATE : 'Sartutako data ezin da izan bitartekariak jasotzeko eguna baino lehenagokoa',
            _INVALID_FUTURE_DATE : 'Ezin da etorkizuneko datak hautatu',
            _AMOUNT_TO_BE_PAID_CANNOT_BE_LESS_THAN_THE_SUM_OF_THE_TAXABLE_AMOUNT_AND_THE_NONTAXABLE_AMOUNT: 'Ordaindu beharreko zenbatekoa ezin da zerga-oinarriaren eta zerga-oinarri salbuetsiaren batura baino txikiagoa izan',
            _AMOUNT_TO_BE_PAID_DIFFERENT_FROM_INVOICE_AMOUNT_MINUS_HOLD_TAX_AMOUNT: 'Ordaindu beharreko zenbatekoa ez da fakturaren zenbatekoa ken konturako atxikipenaren zenbatekoa',
            _AMOUNT_TO_BE_PAID_MUST_BE_GREATER_THAN_ZERO: 'Ordaindu beharreko zenbatekoak zero baino handiagoa izan behar du',
            _ATTENTION_A_CLAIM_IS_BEING_OPENED_ON_A_TECHNICALLY_UNCOVERED_POLICY: 'Kontuz! Ezbehar bat teknikoki estaldu gabeko poliza baten gainean zabaltzen ari da',
            _ATTENTION_OPENING_CLAIM_ON_A_CANCELED_POLICY: 'Ohartarazpena: ezbeharra ezeztatutako poliza baten gainean irekitzen ari da',
            _ATTENTION_THE_PAYMENT_EXCEEDS_THE_RESIDUAL_AMOUNTS_OF_THE_LIMIT: 'Ohartarazpena: ordainketak mugaren hondar-zenbatekoak gainditzen ditu',
            _CANNOT_OPEN_A_CLAIM_ON_A_CANCELED_POLICY: 'Ezin da ezbeharra ireki ezeztatutako poliza baten gainean',
            _CANNOT_OPEN_A_CLAIM_ON_A_POLICY_WITHOUT_ADMINISTRATIVE_VALIDITY: 'Ezin da ezbeharra ireki baliozkotasun administratiborik gabeko poliza baten gainean',
            _CASH_PREVIDENTIAL_AMOUNT_CANNOT_BE_GREATER_THAN_THE_TAXABLE_AMOUNT: 'Gizarte kotizazioen funtsaren zenbatekoa izan da zerga-oinarria baino handiagoa izan',
            _DRAG_A_FILE_TO_UPLOAD: 'Arrastatu fitxategi bat hori kargatzeko',
            _HOLD_TAX_AMOUNT_CANNOT_BE_GREATER_THAN_THE_TAXABLE_AMOUNT: 'Konturako atxikipenaren zenbatekoa ezin da zerga-oinarria baino handiagoa izan',
            _IMPOSSIBLE_TO_EXECUTE_THE_REQUIRED_OPERATION_CLAIM_RECEIPTS_TO_AUTHORIZE: 'Ezin da eskatutako eragiketa burutu: ezbehar-ordainagiriak baimentzeko',
            _IMPOSSIBLE_TO_PROCEED_USER_NOT_AUTHORIZED_TO_OPEN_A_CLAIM_ON_A_TECHNICALLY_UNCOVERED_POLICY: 'Ezin da jardun: Erabiltzaileak ez du baimenik ezbehar bat teknikoki estaldu gabeko poliza baten gainean zabaltzeko',
            _INSERT_A_CSV_FILE: 'Gehitu .csv fitxategia',
            _INSERT_POLICY_NUMBER_OR_PLATE: 'Sartu poliza-zenbakia edo matrikula',
            _INSERT_SUBJECT_SURNAME_OR_DENOMINATION: 'Idatzi alderdiaren abizena(k) edo deitura',
            _INVOICE_AMOUNT_DIFFERENT_FROM_SUM_OF_TAXABLE_AMOUNT_NONTAXABLE_AMOUNT_CASH_PREVIDENTIAL_AMOUNT_STAMP_DUTY_AMOUNT_VAT_AMOUNT: 'Fakturaren zenbatekoa ez dator bat hurrengoen baturarekin: zerga-oinarria, zerga-oinarri salbuetsia, eskudirutan aurreikusitako zenbatekoa, egintza juridiko dokumentatuen gaineko zergaren zenbatekoa eta BEZ zenbatekoa',
            _INVOICE_AMOUNT_MUST_BE_GREATER_THAN_ZERO: 'Fakturaren zenbatekoak zero baino handiagoa izan behar du',
            _IT_IS_NOT_POSSIBLE_TO_DELETE_A_DOCUMENT_ALREADY_UPLOADED_TO_DOCUMENT_MANAGEMENT_SYSTEM: 'Ezin da kendu dokumentuak kudeatzeko sisteman dagoeneko kargatuta dagoen dokumentua',
            _MISSING_CLAIM_NUMBER_AT_LINE: 'Ezbehar-zenbakia falta da lerroan ',
            _MISSING_NOT_TAXABLE_AMOUNT_AT_LINE: 'Zerga-oinarri salbuetsia falta da lerroan ',
            _MISSING_TAXABLE_AMOUNT_AT_LINE: 'Zerga-oinarria falta da lerroan ',
            _MISSING_TOTAL_UNIT_AMOUNT_AT_LINE: 'Unitatearen guztizko zenbatekoa falta da lerroan ',
            _N_NOT_CONSISTENT: 'E Ibilgailuaren kalteak ez datoz bat kutxa beltzaren datuekin',
            _NO_ACTIVITY_TO_SHOW: 'Ez dago erakusteko jarduerarik',
            _NO_CHANGE_MADE: 'Aldaketarik gabe',
            _NO_CHAT: 'Txat gabe',
            _NO_DOCUMENTS_TO_UPLOAD: 'Ez dago kargatu beharreko dokumenturik',
            _NO_NORMALIZED_ADDRESS_FOUND: 'Ez da normalizatutako helbiderik aurkitu',
            _NO_RESULTS: 'Ez dago emaitzarik',
            _POLICY_NOT_COMPLIANT_WITH_THE_SELECTED_DAMAGE_CATEGORY: 'Poliza ez dator bat hautatutako kalteen kategoriarekin',
            _POLICY_NOT_COMPLIANT: 'Poliza ez dator bat hautatutako kalteen kategoriarekin',
            _PRESS_CALCULATE_BUTTON_BEFORE_CONFIRM: 'Sakatu «Kalkulatu» botoia baieztatu aurretik',
            _RESERVE_AMOUNT_NOT_CAPACIOUS_UPDATE_RESERVE_BEFORE: 'Erreserbaren zenbatekoa ez da nahikoa: eguneratu erreserba lehenik',
            _S_CONSISTENT: 'B Ibilgailuaren kalteak bat datoz kutxa beltzaren datuekin',
            _SAVING_ERROR: 'Errorea gordetzean',
            _SEARCH_ERROR: 'Errorea bilatzean',
            _STAMP_DUTY_AMOUNT_CANNOT_BE_GREATER_THAN_THE_TAXABLE_AMOUNT: 'Egintza juridiko dokumentatuen gaineko zergaren zenbatekoa ezin da zerga-oinarria baino handiagoa izan',
            _SUM_OF_TAXABLE_AMOUNT_AND_NONTAXABLE_AMOUNT_MUST_BE_GREATER_THAN_ZERO: 'Zerga-oinarriaren eta zerga-oinarri salbuetsiaren baturak zero baino handiagoa izan behar du',
            _SUM_OF_THE_NONTAXABLE_AMOUNT_OF_THE_DETAILS_OTHER_THAN_THE_NONTAXABLE_AMOUNT_OF_THE_INVOICE: 'Datuen zerga-oinarri salbuetsiaren batura ez dator bat fakturaren zerga-oinarri salbuetsiarekin',
            _SUM_OF_THE_TAXABLE_AMOUNT_OF_THE_DETAILS_DIFFERENT_FROM_THE_TAXABLE_AMOUNT_OF_THE_INVOICE: 'Datuen zerga-oinarriaren batura ez dator bat fakturaren zerga-oinarriarekin',
            _SUM_OF_THE_TOTAL_UNIT_AMOUNT_OF_THE_DETAILS_DIFFERENT_FROM_THE_INVOICE_AMOUNT: 'Datuen unitatearen guztizko zenbatekoa ez dator bat fakturaren zenbatekoarekin',
            _TAXABLE_AMOUNT_MUST_BE_GREATER_THAN_ZERO: 'Zerga-oinarriak zero baino handiagoa izan behar du',
            _THE_DOCUMENT_WILL_BE_PERMANENTLY_DELETED_DO_YOU_WITH_TO_CONTINUE: 'Dokumentua betiko ezabatuko da. Jarraitu nahi duzu?',
            _THE_POLICY_ALSO_COVERS_MATERIAL_DAMAGE_TO_YOUR_VEHICLE: 'Polizak zure ibilgailuaren kalte materialak ere estaltzen ditu',
            _TITLE_AND_DEROGATION_LEVEL_ALREADY_EXIST: 'Titulua eta baimen-maila badaude',
            _TOL_CLAIMS_MADE: 'Badira egindako ezbeharrekin edo hil ondoko ezbeharrekin kudeatutako ezbehar motak: baliozkotze tekniko eta administratiboko kontrolak berregingo dira ezbehar motaren atalean',
            _TOTAL_UNIT_AMOUNT_MUST_BE_GREATER_THAN_ZERO: 'Unitatearen guztizko zenbatekoak zero baino handiagoa izan behar du',
            _UNABLE_TO_MAKE_THE_PAYMENT_THE_RESIDUAL_AMOUNTS_OF_THE_LIMIT_EXCEEDED: 'Ezin da ordaindu: mugaren hondar-zenbatekoa gainditu da',
            _UPDATE_SUCCESSFUL: 'Eguneratu da',
            _VAT_AMOUNT_CANNOT_BE_GREATER_THAN_THE_TAXABLE_AMOUNT: 'BEZ zenbatekoa ezin da zerga-oinarria baino handiagoa izan',
            _WARNING_YOU_ARE_EXCEEDING_THE_CEILING: 'Ohartarazpena: gehienezko muga gainditzen ari zara',
            _X_TO_BE_VERIFIED: 'X Kalteak kutxa beltzeko datuekin bat datozen egiaztatu behar da',
            _YOU_HAVE_TO_SELECT_AT_LEAST_ONE_ASSET: 'Gutxienez aseguratutako ondasun bat hautatu behar duzu',
            _YOU_HAVE_TO_SELECT_AT_LEAST_ONE_PARTY: 'Gutxienez alderdi bat hautatu behar duzu',
            _YOU_NEED_TO_ADD_A_DOCUMENT_IN_ORDER_TO_EXECUTE_THE_UPLOAD: 'Dokumentu bat gehitu behar duzu karga hasteko',
            _TYPE_OF_BODYWORK_IS_MANDATORY: 'Karrozeria mota derrigorrezkoa da',
            _NO_RES_FOUND: 'Ez da emaitzarik aurkitu',
            _CANNOT_OPEN_CLAIM_FOR_UNCOVERED_POLICY: 'It is impossible to open a claim on an uncovered policy',
            _FIELDS_MARKED_IN_RED_ARE_REQUIRED_OR_CONTAIN_INCORRECT_VALUES: 'Fields marked in red are required or contain incorrect values',
            _INCORRECT_OR_MISSING_DATA: 'Incorrect or missing data',
            _THERE_ARE_TYPES_OF_LOSS_MANAGED_WITH_CLAIMS_MADE__POSTUMA: 'There are types of loss managed with claims made / postuma:',
            _TECHNICAL_AND_ADMINISTRATIVE_VALIDATION_CONTROLS_WILL_BE_REDONE_IN_THE_TYPE_OF_LOSS_SECTION: ' technical and administrative validation controls will be redone in the type of loss section',
            _CACHE_ERROR: 'Cache error',
            _ERROR_IN_INITIALIZATION_PROCEED_WITH_MANUAL_SELECTION: 'ERROR in initialization. Proceed with manual selection',
            _UPDATE_ERROR: 'Update error',
            _SEARCH_EXPERTS_ERROR: 'Search Experts Error',
            _SEARCH_REJECT_TYPES_ERROR: 'Search reject types error',
            _EXISTING_PES_ALERT: 'A PES/claim with same date occurred and policy is already present. Continue?',
            _UPDATE_RESERVE_SUCCESSFUL: 'Update reserve successful',
            _UPDATE_RESERVE_ERROR: 'Update reserve error',
            _HEIR_ALREADY_ADDED: 'Heir has already been added',
            _HEIR_DATE_INVALID: 'It is not possible to add fatal date for a subject chosen as an heir',
            _HEIR_IS_EQUAL_SUBJECT: 'The deceased subject cannot be added as a subject',
            _HEIR_INVALID: 'It is not possible to select a person with a fatal date as an heir',
            _HEIR_INVALID_FATAL_DATE: 'Heir removed due to death date, check heirs again',
            _ON_CONFIRMATION: 'Upon confirmation, the regularity of the policy will be checked based on the coverage selected',
            _YOU_CAN_INSERT_1_TO_2_ATTACHMENT: 'You can insert 1 to 2 attachments.',
            _ALLOWED_EXTENSION: 'Allowed extensions: txt, pdf, jpg.',
            _THE_SIZE_OF_AN_ATTACHMENT_CANNOT_EXCEED_1_MB: 'The size of an attachment cannot exceed 1 MB.',
            _COMPILING_LETTER_CONTENT: 'Compiling letter content',
            _OPENED_POST_LITIGATION: 'Opened post litigation',
            _PRESENCE_REQUEST_DAMAGE: 'Presence request damage',
            _WARNING_THE_RESERVE_HAS_BEEN_REDUCED_BY_THE_PAID_AMOUNT: 'Warning, the reserve has been reduced by the paid amount',
            _OF_WHICH_ATTORNEYS_FEES: 'Of which attorneys fees',
            _NO_BANK_ACCOUNT_FOR_THE_SUBJECT: 'No bank account for the subject',
            _NO_IBAN_PRESENT_OF_THE_PAYEE: 'No iban present of the payee',
            _FOREIGN_BANK_ACCOUNT_ON_SUBJECT: 'Foreign bank account on subject',
            _INSERT_DATE: 'Insert date',
            _CLAIM_EXCESS_POLICY_VALUES: 'Claim excess policy values',
            _CLAIM_EXCESS_RESIDUE: 'Claim excess residue',
            _CLAIM_EXCESS_ERODED_AMOUNT: 'Claim excess eroded amount',
            _EXCESS_AGGREGATE_POLICY_VALUES: 'Excess aggregate policy values',
            _EXCESS_AGGREGATE_RESIDUE: 'Excess aggregate residue',
            _EXCESS_AGGREGATE_ERODED_AMOUNT: 'Excess aggregate eroded amount',
            _RECEIPT_ACCEPTED: 'Receipt accepted',
            _RECEIPT_REJECTED: 'Receipt rejected',
            _REASON_REJECTED: 'Reason rejected',
            _ONE_PAYEE_IS_REQUIRED: 'One payee is required',
            _ONE_PAYEE_AND_ONE_BENEFICIARIES_REQUIRED: 'One payee and one beneficiary are required',
            _ONE_PRIMARY_BENEFICIARIES_REQUIRED: 'One primary beneficiary required',
            _ONE_PRIMARY_PAYEE_REQUIRED: 'One primary payee required',
            _TAX_CODE__VAT_NUMBER_MISSING: 'Tax code/Vat number missing',
            _PAYEE_MUST_BE_ADULT: 'Payee must be an adult',
            _PAYEE_NOT_REGISTERED_SAVE_SUBJECT_WITH_SUBJECT_MANAGEMENT_FUNCTION: 'Payee not regstered, save subject with subject managment function.',
            _BEFORE_DELETE_ADD_NEW_BENEFICIARY: 'Before delete add new beneficiary',
            _BEFORE_DELETE_ADD_NEW_PAYEE: 'Before delete add new payee',
            _THE_CANCELLATION_DATE_IS_NOT_VALID: 'The cancellation date is not valid',
            _SELECT_REASON_CANCELLATION: 'Select cancellation reason',
            _METHOD_NOT_IMPLEMENTED: 'Method not implemented',
            _THERE_IS_A_PAYMENT_WITHOUT_ASSOCIATED_DOCUMENTS_CONTINUE: 'There is a payment withou associated documents, continue?',
            _SUBJECT_WITH_INCOMPLETE_TAX_DATA__UNABLE_TO_PROCEED: 'Subject with incomplete tax data, unable to proceed',
            _OPERATION_NOT_AVAILABLE: 'Operation not available',
            _FEE_DATA_MISSING: 'Fee data missing',
            _MANDATORY_DATA_MISSING: 'Mandatory data missing',
            _SELECT_THE_DEFINITION_FOR_THE_SELECTED_CAUSALS: 'Select the definition',
            _YOU_HAVE_TO_SELECT_AT_LEAST_ONE_TYPE_OF_LOSS: 'You have to select at least one type of loss',
            _THE_AMOUNT_FOR_THE_REASON_SELECTED: 'The amount for the reason selected',
            _INCOMPLETE_ADDRESS: 'Incomplete address',
            _INCOMPLETE_DATA: 'Incomplete data',
            _UIC_NOT_VALID__MAX_3_CHARACTER: 'Uic not valid, max 3 character',
            _FOREIGN_BANK_ACCOUNT_INVALID: 'Foreign bank account not valid',
            _IBAN_INVALID: 'Iban not valid',
            _INSERT_A_VALID_ITALIAN_IBAN: 'Insert a valid italian iban',
            _SPECIAL_CHARACTERS_NOT_ALLOWED_IN_IBAN_CODE: 'Special characters are not allowed in iban code',
            _RESERVE_AMOUNT_NOT_CAPACIOUS__UPDATE_RESERVE_BEFORE: 'Reserve amount not capacious, update reserve before',
            _IMPOSSIBLE_TO_EXECUTE_REQUIRED_OPERATION__CLAIM_RECEIPTS_TO_AUTHORIZE: 'Impossible to execute required operation,claim receipts to authorize',
            _ATTENTION_THE_PAYMENT_EXCEEDS_THE_RESIDUAL_AMOUNT_OF_THE_LIMIT: 'Attention,the payment exceeds the residual amount of the limit',
            _SELECT_REASON_REVERSAL: 'Select reason reversal',
            _ENTER_NOTES: 'Enter notes',
            _THE_TOTAL_AMOUNT_CANNOT_BE_GREATER_THAN_OR_EQUAL_TO_0: 'The total amount cannot be greater than or equal to 0 ',
            _THE_CREDIT_NOTE_CANNOT_BE_HIGHER_THAN_THE_ORIGINAL_INVOICE: 'The credit note cannot be higher than the original invoice',
            _INVOICE_TYPE_REQUIRED: 'Invoice type required',
            _FEE_NUMBER_AND_FEE_DATE_ARE_REQUIRED: 'Fee number and fee date are required',
            _THE_REVERSAL_DATE_IS_NOT_VALID: 'The reversal date is not valid',
            _ERROR_CODE: 'Error code',
            _ERROR: 'Error',
            _SPECIFY_EVENT_ASSIGNMENT_NUMBER_DATE_FROM_TO: 'Specify event/assignmentNumber/date from-to',
            _SPECIFY_ONLY_CLAIM_NUMBER: 'Specify only claim number',
            _SPECIFY_CRITERIA: 'Specify a criteria',
            _SPECIFY_EVENT: 'Specify event',
            _SPEFCIFY_CLAIM_NUMBER: 'Specify claim number',
            _ALERT_NEW_DOC: 'Alert new doc',
            _EXISTING_REQUEST_FOR_DAMAGES_TO_MATCH: 'Existing Requests for damages to match',
            _NO_REQUEST_DAMAGES: 'No hay daños',
            _REQUEST_FOR_DAMAGES_RECEIPT_DATE: 'Request for damages receipt date',
            _GENERIC_ERROR_ON_SAVE_QUESTIONNARIE: 'Error genérico al guardar el cuestionario.',
            _QUESTIONNAIRE_WAS_UPDATE_SUCCESSFUL: 'El cuestionario se actualizó correctamente',
            _QUESTIONNAIRE_MODIFICATION_CANCELLED: 'Modificación del cuestionario cancelada',
            _ENTER_A_VALID_IBAN: 'Enter a valid Iban',
            _INSERT_A_VALID_SPANISH_IBAN: 'Enter a valid Spanish Iban',
            _AN_ERROR_OCCURED_WHILE_ADDING_THE_NOTE: 'An error occurred while adding the note',
            _AN_ERROR_OCCURRED_WHILE_DELETING_THE_EXPERT_ASSOCIATION: 'An error occurred while deleting the expert association',
            _AN_ERROR_OCCURRED_WHILE_PROCESSING_THE_REQUEST: 'An error occurred while processing the request',
            _ARE_YOU_SURE_YOU_WANT_TO_DELETE_THE_TRUSTEE_ASSOCIATION: 'Are you sure you want to delete the trustee association?',
            _CHARACTER_LIMIT_EXCEEDED: 'Charcater limit exceeded',
            _EXPERT_ASSOCIATION_DELETED_CORRECTLY: ' Expert association deleted correctly',
            _EXPERT_LINK_ADDED_WITH_SUCCESS: 'Expert link added with success',
            _FISCAL_CODE__VAT_UNCORRECT: 'Fiscal Code/Vat Uncorrect',
            _FISCAL_CODE_UNCORRECT: 'Fiscal code uncorrect',
            _IMPUTE_THE_NAME_OR_THE_FISCAL_CODE: 'Impute the name or the fiscal name',
            _IN_ERROR: 'In error',
            _INCOMPLETE_COMPILATION: 'Incomplete compilation',
            _NO_NOTES_FOR_THIS_COMPENSATION: ' No notes for this compensation',
            _OPERATION_PROPERLY_TERMINATED: ' Operation propely terminated',
            _SELECT_A_CRITERIA: 'Select a criteria',
            _SELECT_A_USER_BEFORE_CONFIRMING: 'Select a user before confirming',
            _SELECT_AT_LEAST_ONE_REASON: 'Select at least one reason',
            _SPECIFY_THE_TYPE_ASSET: 'Specify the type asset',
            _VALUE_AT_LEAST_ONE_FIELD_BETWEEN_NAME_SURNAME_BUSINESS_NAME_CONTRACTOR_FISCAL_CODE_VAT_CODE_CODE_INDEPENDENT_EXPERT: 'Value at least one field between: name, surname, business name, contractor fiscal code, VAT code, code independent expert',
            _VAT_UNCORRECT: 'Vat code uncorrect',
            _FILE_IS_EMPTY: 'File is empty',
            _MAXIMUM_FILE_SIZE_EXCEEDED: 'Maximum file size exceeded',
            _NOT_GENERATED_FOR_CHOICE: 'Not generated for choice',
            _GENERIC_ERROR_SAVE_ACTOR: 'ES_Error encountered while saving new actor',
            _SAVE_NEW_ACTOR: 'ES_Save new actor',
            _SELECT_THE_DOCUMENT_TO_ATTACH: 'Select the documents to attach to the payment',
            _KO_UPLOAD_DOC: 'An error occurred while uploading the document',
            _CANCELLATION_DIALOGUE: 'ES_Are you sure you want to cancel this dialogue?',
        },
        _TITLE_: {
            _QUESTIONNAIRE_TITLE: 'Galdetegia',
            _SELECT_POLICY: 'Hautatu poliza',
            _OCCURRENCE_DATA: 'Gertakariaren datuak',
            _DATA_COLLECTION: 'Datuak jasotzea',
            _SUMMARY: 'Laburpena',
            _DIALOGUE_HANDLER_LIST: 'Dialogue List',
            _DIALOGUE_HANDLER_NEW: 'New Demand',
            _DIALOGUE_HANDLER_SUMMARY: 'Dialogue Summary',
            _ADDRESS_MANAGEMENT: 'Address management'
        },
        _DATA_FORWARDING_CDL: 'Datuak CHC-ra transmititzea',
        _INTERMEDIARY: 'Bitartekaria',
        _MASTER_POLICY_NUMBER: 'Oinarrizko polizaren zenbakia',
        _APPLICATION_NUMBER: 'Eskaera-zenbakia',
        _COMPLAINT_STATE: 'Salaketaren egoera',
        _PLATE__CHASSIS: 'Txapa / Txasisa',
        _ACCOUNTING_COVERAGE: 'Kontabilitate-estaldura',
        _LEGAL_GUARDIAN_ROLE: 'Legezko tutorearen papera',
        _CAR_BODY_DETAIL: 'Karrozeriaren inguruko informazioa',
        _START_DATE_OF_COLLABORATION: 'Lankidetzaren hasiera-data',
        _END_DATE_COLLABORATION: 'Lankidetzaren amaiera-data',
        _HOUSE_NUMBER: 'Etxeko zenbakia',
        _MUNICIPALITY: 'Udalerria',
        _NEW_BODYWORK: 'Karrozeria berria',
        _SUBJECT_TYPE: 'Alderdi mota',
        _CODE: 'Kodea',
        _BUSINESS_NAME: 'Sozietate-izena',
        _FISCAL_CODE: 'Kode fiskala',
        _VAT_CODE: 'IVA kodea',
        _TYPE_OF_BODYWORK: 'Karrozeria mota',
        _INJURY_PHISICAL: 'Lesio fisikoa',
        _CAUSE_OF_INJURY: 'Lesioaren kausa',
        _UNCODABLE: 'Kodifikaezina',
        _MULTIPLE: 'Anizkuna',
        _NATURE_OF_INJURY: 'Lesioaren izaera',
        _INJURY_LOCATION: 'Lesioaren kokapena',
        _FURTHER_INJURY_INDICATOR: 'Lesio osagarrien adierazlea',
        _PROFESSIONAL_QUALIFICATION: 'Lanbide kualifikazioa',
        _PERCENTAGE_OF_PERMANENT_DISABILITY: 'Ezintasun iraunkorraren ehunekoa',
        _TEMPORARY_DISABILITY_DAYS_WITHOUT_SEQUELAE: 'Ondoriorik gabeko aldi baterako ezintasuneko egunak',
        _FATALITY_DATE: 'Heriotza-data',
        _FIRST_AID_COUNTRY: 'Lehen sorospeneko herrialdea',
        _FIRST_AID_PLACE: 'Lehen sorospeneko tokia',
        _HEALTHCARE_PROVIDERS: 'Osasun-laguntzaren hornitzaileak',
        _BIOLOGICAL_DAMAGE: 'Kalte biologikoa',
        _SUBROGATION_SOCIAL_ASS_EMPLOYER: 'Gizarte-laguntzaren subrogazioa/Enpresaburua',
        _DOCTOR: 'Doktorea',
        _SEARCH: 'Buscar',
        _SKULL: 'GAREZURRA',
        _SPINE_CERVICAL_COLUMN: 'BIZKARREZURRA-ZERBIKALA',
        _LEFT_SHOULDER: 'EZKERREKO SORBALDA',
        _RIGHT_SHOULDER: 'ESKUINEKO SORBALDA',
        _SPINE_THORACIC_COLUMN: 'BIZKARREZURRA-TORAZIKOA',
        _SPINE_LUMBAR_COLUMN: 'BIZKARREZURRA-LUNBARRA',
        _SPINE_SACRUS_AND_COCCYX: 'BIZKARREZURRA-SAKROA ETA KOKZIXA',
        _LEFT_ANKLE: 'EZKERREKO ORKATILA',
        _RIGHT_ANKLE: 'ESKUINEKO ORKATILA',
        _RIGHT_EAR: 'ESKUINEKO BELARRIA',
        _RIGHT_EYE: 'ESKUINEKO BEGIA',
        _LEFT_EYE: 'EZKERREKO BEGIA',
        _LEFT_EAR: 'EZKERREKO BELARRIA',
        _FACE: 'AURPEGIA',
        _NECK: 'LEPOA',
        _RIGHT_CHEST_WALL: 'ESKUINEKO PARETA TORAZIKOA',
        _LEFT_CHEST_WALL: 'EZKERREKO PARETA TORAZIKOA',
        _RIGHT_ARM: 'ESKUINEKO BESOA',
        _THORACIC_ORGANS: 'ORGANO TORAZIKOAK',
        _LEFT_ARM: 'EZKERREKO BESOA',
        _RIGHT_ELBOW: 'ESKUINEKO UKONDOA',
        _ABDOMINAL_ORGANS: 'ORGANO ABDOMINALAK',
        _LEFT_ELBOW: 'EZKERREKO UKONDOA',
        _RIGHT_FOREARM: 'ESKUINEKO BESAURREA',
        _ABDOMINAL_WALL: 'PARETA ABDOMINALA',
        _LEFT_FOREARM: 'EZKERREKO BESAURREA',
        _PELVIS___RIGHT_HIP: 'PELBISA - ESKUINEKO ALDAKA',
        _UROGENITAL_ORGANS: 'ORGANO UROGENITALAK',
        _PELVIS___LEFT_HIP: 'PELBISA - EZKERREKO ALDAKA',
        _RIGHT_WRIST: 'ESKUINEKO ESKUMUTURRA',
        _LEFT_WRIST: 'EZKERREKO ESKUMUTURRA',
        _RIGHT_HAND: 'ESKUINEKO ESKUA',
        _RIGHT_THIGH: 'ESKUINEKO IZTERRA',
        _LEFT_THIGH: 'EZKERREKO IZTERRA',
        _LEFT_HAND: 'EZKERREKO ESKUA',
        _RIGHT_KNEE: 'ESKUINEKO BELAUNA',
        _LEFT_KNEE: 'EZKERREKO BELAUNA',
        _RIGHT_LEG: 'ESKUINEKO HANKA',
        _LEFT_LEG: 'EZKERREKO HANKA',
        _RIGHT_FOOT: 'ESKUINEKO OINA',
        _LEFT_FOOT: 'EZKERREKO OINA',
        _REQUIRED: 'Eskatuta',
        _EXTERNAL_CLAIM_NUMBER: 'Kanpoko ezbehar-zenbakia',
        _POTENTIAL_CLAIM_NUMBER: 'Balizko ezbehar-zenbakia',
        _ALIGN_POLICY: 'Lerrokatu poliza',
        _ACTIONS: 'Ekintzak',
        _HEIR: 'Heir',
        _HEIR_ADD: 'Add heir',
        _HEIRS: 'Heirs',
        _DATE_ENTRY_FROM: 'Date entry from',
        _ELABORATION_DATE_FROM: 'Elaboration date from',
        _EXPIRY_DATE_FROM: 'Expiry date from',
        _CLAIM_DATE_FROM: 'Claim date from',
        _FUNCTION: 'Function',
        _DAMAGE_MATCH: 'Damage Match',
        _TAX_ID_CODE: 'Tax Id Code',
        _COMPLAINT: 'Complaint',
        _NOT_ASSOCIATED: 'Not Associated',
        _CTP_COMPANY: 'Ctp Company',
        _US_CAR_LICENSE_PLATE: 'License Plate Us',
        _CTP_CAR_LICENSE_PLATE: 'License Plate Ctp',
        _ACTIVE_TASK: 'Active Task',
        _ERROR_MESSAGE: 'Error Message',
        _SIMILAR_FLAG: 'Similar Flag',
        _FILTER: 'Filter',
        _NOT_ASSOCIATED_DIALOGUES: 'Not Associated Dialogues',
        _DIALOGUE_DATE_START: 'Dialogue Date Start',
        _END_DATE: 'End Date',
        _SX_DATE: 'Sinister Date',
        _DIALOGUE: 'Dialogue',
        _EXTERNAL_KEY_DAMAGED: 'External Key Damaged',
        _INTERNAL_KEY_DEF: 'Internal Key definitive',
        _INTERNAL_KEY_TEMP: 'Internal Key temporary',
        _INTERNAL_KEY_DAMAGED_DEF: 'Internal Key Damaged definitive',
        _INTERNAL_KEY_DAMAGED_TEMP: 'Internal Key Damaged temporary',
        _INTERNAL_KEY_DAMAGED: 'Internal Key Damaged',
        _US_TYPE_PLATE: 'Type Plate Us',
        _CTP_TYPE_PLATE: 'Type Plate Ctp',
        _IDENTIFIER_UNIQUE_CODE: 'Identifier Unique Code',
        _PARAMETERIZED_DIALOGUE_DATA: 'Parameterized Dialogue Data',
        _REGULATORY_EXPIRY_DATE: 'Regulatory Expiry Date',
        _LAST_UPDATE_DATE: 'Last User Date',
        _USER_UPDATE: 'User Update',
        _ACTIVITY_TYPE: ' Activity Type',
        _PARAMETERS_UPPERCASE: 'PARAMETERS',
        _REQUEST_NOTE: 'Request Note',
        _RESPONSE_NOTE: 'Response Note',
        _DOCUMENTS_UPPERCASE: 'DOCUMENTS',
        _DISCARDED_UPPERCASE: 'DISCARDS',
        _CAUSAL: 'Causal',
        _EXPIRATION: 'Expiration',
        _SENDED: 'Sended',
        _START_STATUS: 'Start Status',
        _DOCUMENT_TYPE: 'Document Type',
        _DOCUMENT_TYPE_FLUX: 'Document Type (Flux)',
        _CODE_ERROR: 'Code Error',
        _INPUT: 'Input',
        _OUTPUT: 'Output',
        _RESPONSE_NOTES: 'Response Notes',
        _DOCUMENTS_REQUIRED: 'Documents required for managing the selected answer',
        _SELECTED_DOCUMENTS: 'Select the documents to associate with the response by entering the flow document type for each',
        _ACTOR_INVOLVED: 'Actor Involved',
        _REASON: 'Reason',
        _DOCUMENT_TYPE_FLOW: 'Document Type Flow',
        _MANDATORY: 'Mandatory',
        _MATTER: 'Matter',
        _SUB_CATEGORY: 'Sub-Category',
        _LST_MSG_CODE: 'Last Message Code',
        _LST_MES_REQ_DATA: 'Request Date (LST. MS.)',
        _LST_MES_REG_EXP: 'Expiry Date (LST. MS.)',
        _START_DATE: 'Start Date',
        _INTERNAL_KEY: 'Internal Key',
        _LST_RESP_MES_CODE: 'Response Code (LST. MS.)',
        _LST_MSG_DATE: 'Last Message Date',
        _LST_MSG_INT_EXP: 'Internal Expiry Date (LST. MS.)',
        _EXT_KEY_IDEF_DAM: 'External Key Identify Damage Party',
        _ONGOING: 'ONGOING',
        _EVADE: 'EVADE',
        _INSURANCE_CODE_US: 'Company',
        _ACTOR_NOMINATIVE: 'Actor Nominative',
        _DETAILS_CLAIMS_DATA: 'Claims Data Detail',
        _CONSOLIDATE: 'Consolidate',
        _BALANCE: 'Balance',
        _VALIDATION_AND_CONSOLIDATION: 'Validation&Upload',
        _PAYMENT_MANAGE: 'Payment Manage',
        _FORFAIT_MANAGE: 'Forfait Manage',
        _YEAR: 'Year',
        _MONTH: 'Month',
        _JANUARY: 'January',
        _FEBRUARY: 'February',
        _MARCH: 'March',
        _APRIL: 'April',
        _MAY: 'May',
        _JUNE: 'June',
        _JULY: 'July',
        _AUGUST: 'August',
        _SEPTEMBER: 'September',
        _OCTOBER: 'October',
        _NOVEMBER: 'November',
        _DECEMBER: 'December',
        _INCAPACITATED: 'Incapacitated',
        _TYPES_OF_LOSS: 'Types of loss',
        _RISK_DATA: 'Risk data',
        _COVERTURE_DATA: 'Coverture data',
        _RULES_FOR_UPLOADING_DOCUMENTS: 'Rules for uploading documents:',
        _OCCURRENCE_HOUR: 'Occurence hour',
        _DATE_INTERMEDIARY: 'Date intermediary',
        _TYPE_OF_AUTHORITIES_POSSIBLY_INVOLVED: 'Type of authorities possibly involved',
        _CATASTROPHIC_EVENT_DESCRIPTION: 'Catastrophic event description',
        _OPENING_MODE: 'Claim opening mode',
        _PARTY: 'Party',
        _ASSET_INSURED: 'Asset insurance',
        _NEW_DAMAGED: 'New damaged',
        _TYPE_OF_LOSS: 'Daño causal',
        _NOMINATIVE: 'Nominative',
        _NO_RESULT: 'No results.',
        _NEW_ASSET: 'New asset',
        _ASSET_TYPE: 'Asset type',
        _TYPE: 'Type',
        _FOUND: 'Found',
        _COVERAGES_DETAIL: 'Coverages detail',
        _ADD_A_DAMAGED: 'Add a dameged',
        _PHYSICAL_PERSON: 'Physical person',
        _POLICY_DATA: 'Policy data',
        _ADMINISTRATIVE_VALIDITY: 'Administrative Validity',
        _LIST_OF_RELATED_CLAIMS: 'List of related claims',
        _CF__PIVA: 'CF/PIVA',
        _REFERENCE: 'Reference',
        _DOL: 'DOL',
        _DON: 'DON',
        _MAIN: 'Main',
        _ORIGINAL_EFFECT_DATE: 'Original Effect date',
        _NO_POLICY_TO_SHOW: 'No policy to show',
        _ASSIGNMENT_DATE: 'Assignment Date',
        _STATUS: 'Egoera',
        _FILTERS: 'Filters',
        _CLAIMS_HANDLER: ' Claims Handler',
        _COMPANY: ' Enpresa',
        _TECHNICAL_STATE: 'Technical State',
        _RESERVED: 'Reserved',
        _INVOICE_DATE: 'Invoice Date',
        _AMOUNT: 'Amount',
        _REJECT_TYPE: 'Reject Type',
        _UPLOAD_DETAILS: 'Upload Details',
        _NUMBER: 'Number',
        _ACTOR: 'Actor',
        _UPLOAD: 'Upload',
        _EXPERT_INVOICE: 'Expert Invoice',
        _TAX_CODE__VAT_NUMBER: 'Tax Code/VAT Number',
        _MANUAL_NOTE: 'Manual note',
        _INSERT_A_NEW_MANUAL_NOTE: 'Insert a new manual note',
        _COMMENTS_FORM: 'Comments form',
        _THERE_ARE_NO_COMMENTS: 'There are no comments',
        _NO_PERMISSION: 'No permission',
        _CANCELLATION: 'Cancellation',
        _COMMENT: 'Comment',
        _TOTAL_ASSIGNMENT_EXPENSE_AMOUNT: 'Monto total de gastos de asignación',
        _TOTAL_RESERVE_FOR_LITIGATION: 'Reserva total para litigios',
        _FROM: 'Desde',
        _TO: 'Para',
        _QUICK_FILTERS: 'Quick filters',
        _ASCENDING_ORDER: 'Ascending order',
        _DESCENDING_ORDER: 'Descending order',
        _MOVEMENTS: 'Movimientos',
        _ACCESSES: 'Accesses',
        _ACTIVITY: 'Activity',
        _RESERVES: 'Reserves',
        _TITLES: 'Titles',
        _STATE: 'Estado',
        _ASSIGNEE_GROUP: 'Esleitu taldea',
        _ASSIGNEE_USER: 'Esleitu erabiltzailea',
        _RUN_DATE: 'Exekutatu data',
        _PRIORITY: 'Lehentasuna',
        _SUSPENDED: 'Suspended',
        _COMPLETION_DATE: 'Completion date',
        _ESCAPE_METHODS: 'Escape methods',
        _RUN_ACTIVITY: 'Run Activity',
        _TAKE_CHARGE_ACTIVITY: 'Take charge activity',
        _REASSESS_TASK: 'Reassess task',
        _NO_SUGGESTIONS: 'No suggestions',
        _CRITERIA: 'Criteria',
        _ASSIGNMENT: 'Assignment',
        _EVENTS: 'Events',
        _R_PER: 'Return assignment (R-PER)',
        _A_COM: 'Communication (A-COM)',
        _S_RIF: 'Refuse assignment (S-RIF)',
        _R_FAT: 'Invoice  (R-FAT)',
        _TRANSMISSION_LIST: 'Trasmission list',
        _FAILED_TRANSMISSION_LIST: 'Failed trasmission list',
        _STARTER_EVENT: 'Evento starter',
        _REQUEST: 'Request',
        _ID: 'Id',
        _WS: 'WS',
        _XML: 'Xml',
        _RESPONSE: 'Response',
        _DETAIL: 'Detail',
        _VIEW_DETAIL_BLOW: 'View detail blow',
        _INSERT_DATE: 'Insert date',
        _ELABORATION_DATE: 'Elaboration date',
        _INSUFFICIENT_PERMISSIONS: 'Insufficient Permissions',
        _CLAIM: 'Reclamación',
        _POTENTIAL_CLAIM_INQUIRY: 'Investigación de reclamación potencial',
        _VALIDATE: 'Validate',
        _INVALIDATE: 'Invalidate',
        _MODIFY: 'Modify',
        _SUBCATEGORY: 'Subcategory',
        _RECEPTION_CHANNEL: 'Reception channel',
        _FORMAT: 'Format',
        _SIZE: 'Size',
        _NUMBER_OF_PAGES: 'Number of pages',
        _RECEIIPT_DATE: 'Receipt date',
        _USER: 'User',
        _SUBTITLE: 'SubTitle',
        _DOC_KEY: 'Doc key',
        _POSTALIZATION_RESULT: 'Postalization result',
        _POSTALIZATION_DATE: 'Postalization date',
        _SHIPPING_TYPE: 'Shipping type',
        _VIEW_BY_CATEGORIES: 'View by categories',
        _UPLOAD_DATE: 'Upload date',
        _SUBCATEGORY_DEPRECATED: ' Subcategory deprecated',
        _CATEGORY_DEPRECATED: ' Category deprecated',
        _HISTORY: 'History',
        _OPERATIONS: 'Operations',
        _EDIT_DOCUMENT: 'Edit document data',
        _CHANGE_PARTY: 'Change party',
        _SELECT_CATEGORY: 'Select category',
        _SELECT_SUBCATEGORY: 'Select subcategory',
        _PEOPLE: 'People',
        _EXTERNAL_VISIBILITY: 'External visibility',
        _VEICHLE: 'Veichle',
        _VALIDATED: 'Validate',
        _NO_VALIDATE: 'No validate',
        _TECHNICAL_STATUS: 'Estado técnico',
        _RESERVE_AMOUNT: 'Cantidad de Reserva',
        _TECHNICAL_REACTIVATION_OPENING_DATE: 'Reactivación/fecha de apertura técnica',
        _ACCOUNTING_REACTIVATION_OPENING_DATE: 'Fecha de apertura de reactivación contable',
        _TECHNICAL_CLOSING_DATE: 'Fecha de cierre técnico',
        _NEW_QUESTIONNAIRE_DATE: 'New Questionnaire Date',
        _ROLE: 'Role',
        _CODE_CHAIN: 'Code chain',
        _EXTERNAL_KEY: 'External Key',
        _CODE_ACTION: 'Code action',
        _DATA_ENTRY: 'Data entry',
        _OUTCOME: 'Outcome',
        _INSERTED: 'Inserted',
        _JSON_N: 'Json N°',
        _LAST_ELABORATION: 'Last elaboration',
        _CANCELLATION_REASON: 'Cancellation Reason',
        _ASSOCIATED_ENTITY: 'Associated Entity',
        _EXECUTIONS: 'Executions',
        _JSON_OK_N: 'Json OK n.',
        _JSON_KO_N: 'Json KO n.',
        _RERUN: 'Rerun',
        _RESERVATION_DATE: 'Reservation date',
        _IN_PROGRESS: 'In progress',
        _CLOSED: 'Closed',
        _FORCED_CLOSURE: 'Forced closure',
        _NOT_STOPPED: 'Not stopped',
        _STOPPED_SUCCESSFULLY: 'Stopped successfully',
        _RERUN_RESERVED: 'Rerun reserved',
        _RERUN_FAILURE: 'Rerun failure',
        _RESULTS: 'Results',
        _TITLE: 'Title',
        _INSERTION: 'Insertion',
        _ELABORATION: 'Elaboration',
        _REQUEST_TYPE: 'Request type',
        _ELABORATION_REQUEST_DATE: 'Elaboration request date',
        _INPUT_PARAMETERS: 'Input parameters',
        _JSON: 'Json',
        _JSON_EXTERNAL_KEY: 'Json External key',
        _CREATION_FAILED: 'Creation failed',
        _REQUEST_CREATED: 'Request created',
        _RESERVATION: 'Reservation',
        _CREATION_ERROR: 'Creation error',
        _TOO_MANY_JSON_WERE_UPLOADED: 'Too many json were uploaded',
        _COMPANY_SETTLEMENT_AMOUNT: 'Company settlement amount',
        _PAYEE: 'Payee',
        _PAYMENT_TYPE: ' Payment type',
        _BENEFICIARIES: ' Beneficiaries',
        _ADD_BENEFICIARY: 'Add beneficiary',
        _PAYEES: 'Payees',
        _ADD_PAYEE: 'Add Payee',
        _CALCULATE: 'Calculate',
        _REASON_CANCELLATION: 'Cancellation Reason',
        _C__O: 'C/O',
        _NO_DOCUMENTS: 'No Documents',
        _FEE_DATA: 'Fee Data',
        _FEE_NUMBER: 'Fee Number',
        _FEE_DATE: 'Fee Date',
        _IVA_AMOUNT: 'Iva amount',
        _IRPF_AMOUNT: 'IRPF amount',
        _WITH_HOLDING_AMOUNT: 'With holding amount',
        _NET_AMOUNT: 'Net amount',
        _CASH_AMOUNT_1: 'Cash amount 1',
        _CASH_AMOUNT_2: 'Cash amount 2',
        _SETTLEMENT_BLOCKED__PERCENTAGE_SAME_INVOICE_ALREADY_REIMBURSED_TO_INSURED: 'Settlement blocked: % same invoice already reimbursed to insured',
        _PAID_AMOUNT: 'Paid Amount ',
        _PERCENTAGE_OF_THE_TOTAL: '% of the total ',
        _ALREADY_INSERT: 'Already insert',
        _REMAINING_AMOUNT: ' Remaining amount',
        _EVALUATION: ' Evaluation',
        _PAYMENT_DATA: 'Payment data',
        _CALCULATION_OF_THE_INSURED_FEE: 'Calculation of the insured fee',
        _BENEFICIARIES_AND_PAYEES: 'Beneficiaries and payees',
        _METHODS_OF_PAYMENT: 'Methods of Payment',
        _LETTERS_MANAGEMENT: 'Letters managment',
        _RECEIPT_SUMMARY: 'Receipt summary',
        _SETTLEMENT_SUMMARY: 'Settlement summary',
        _PARTY_ROLE: 'Party role',
        _OPERATION_USER: 'Operation user',
        _QUITTANCE_DATE: 'Quittance date',
        _CURRENCY_DATE: 'Currency date',
        _PAYMENT_TRANSACTION_TYPE: 'Payment transaction type',
        _RECEIPT_TYPE: 'Receipt type',
        _MANAGMENT_PAYMENT: 'Managment payment',
        _SEPARATE_RECEIPT: 'Separate receipt',
        _CURRENCY: 'Currency',
        _DUE_DATE: 'Due date',
        _PROCEDURE_CODE: 'Procedure code',
        _AMOUNT_QUOTA: 'Amount quota',
        _OPEN_DETAIL: 'Open detail',
        _FORFAIT_TYPE: 'Forfait type',
        _AMOUNT_QUOTA_FORFAIT: 'Amount quota forfait',
        _FORFAIT: 'Forfait',
        _IMPORT: 'Import',
        _METHOD_OF_PAYMENT: 'Method of payment',
        _BANK_TRANSFER_DESCRIPTION: 'Bank Transfer Description',
        _INCOMPLETE_ADDRESS: 'Incomplete address',
        _NOT_GENERATED_FOR_MISSING_DATA: 'Not generated for missing data',
        _GENERATED: 'Generated',
        _RETURN_OF_RECEIPT: 'Return of receipt',
        _RECEIPT_STATUS: 'Receipt status',
        _RECEIPT_DATE: 'Receipt date',
        _REASON_CANCEL_RECEIPT: 'Reason cancel receipt',
        _RECEIPT_NOTES: 'Receipt notes',
        _RETURN: 'Return',
        _BENEFICIARY: 'Beneficiary',
        _SENDING_LETTERS: 'Sending letters',
        _MANAGEMENT_PAYMENT: 'Managment payment',
        _PAYABILITY: 'Payability',
        _WAITING: 'Waiting',
        _SUBSTATUS: 'Substatus',
        _PAYMENT_DEFINITION: 'Payment Definition',
        _RESERVED_AMOUNT: 'Reserved Amount',
        _PARTIAL: 'Partial',
        _CHC: 'CHC',
        _LOAD_DATE: 'Load date',
        _OFFER_DATE: 'Offer date',
        _IBAN: 'Iban',
        _IBAN_ON_SUBJECT: 'Iban on subject',
        _ACCOUNT_HOLDER: 'Account holder',
        _BANK_DESCRIPTION: 'Bank description',
        _BANK_ADDRESS: 'Bank address',
        _SWIFT: 'Swift',
        _FOREIGN_BANK_ACCOUNT: 'Foreign Bank Account',
        _FOREIGN_BANK_ACCOUNT_ON_SUBJECT: 'Foreign bank account on subject',
        _BIC: 'BIC',
        _UIC: 'UIC',
        _BANK: 'Bank',
        _LEGAL_OPPONENT: 'Legal opponent ',
        _REGISTER_SUBJECT: 'Register subject',
        _OPEN_REGISTER: 'Open register',
        _SETTLEMENTS_LIST: 'Settlements list',
        _RECEIPTS_LIST: 'Receipts list',
        _COMPENSATIONS_LIST: 'Compensation list',
        _APPOINTMENTS_LIST: 'Appointments list',
        _TYPOLOGIES_SETTLEMENT_LIST: 'Typologies settlement list',
        _ACCESSORY_CHECKS: 'Accessory checks',
        _FORCING_APPLY: 'Forcing apply',
        _ADMINISTRATIVE_VALIDITY_OVERIDDEN_ON_POLICY_NO: 'Administrative validity overidden on policy no',
        _EFFECTIVE_DATE_OF_COLLECTION: 'Effective date of collection',
        _EXPIRY_DATE_OF_COLLECTION: 'Expiry date of collection',
        _DATE_OF_COLLECTION: 'Date of collection',
        _CONFIRM_RESPONSIBILITY: 'Confirm responsibility',
        _PERCENTAGE_INSURED_LIABILITY: 'Percentage insured liability',
        _REASON_TO_FORCE_LIABILITY_PERCENTAGE: 'Reason to force liability percentage',
        _COUNTERPART_VEHICLE: 'Counterpart vehicle',
        _CHASSIS__PLATE_NUMBER: 'Chassis plate number',
        _LIABILITY_PERCENTAGE: 'Liability percentage',
        _HISTORICAL_EROSION: 'Historical erosion',
        _INPUT_AMOUNT: 'Input amount',
        _INSURED_SETTLEMENT_AMOUNT: 'Insured settlement amount',
        _CLAIM_EXCESS: 'Claim excess ',
        _DEDUCTIBLE: 'Deductible',
        _EXCESS_AGGREGATE: 'Excess aggregate',
        _SIR: 'Sir',
        _LIMIT: 'Limit',
        _FIRST_SIR: 'First sir',
        _SECOND_SIR: 'Second sir',
        _ADVANCE: 'Advance',
        _SIR_ADVANCED_BY_COMPANY_SOMETIMES: 'Sir advanced by company sometimes',
        _CAP_INDICATOR: 'Cap indicator',
        _CAP_AMOUNT: 'Cap amount',
        _EXCESS_AMOUNT: 'Excess amount ',
        _FIRST_SIR_POLICY_VALUES: 'First sir policy values ',
        _FIRST_SIR_RESIDUE: 'First sir residue ',
        _FIRST_SIR_ERODED_AMOUNT: 'First sir eroded amount ',
        _SIR_AMOUNT: 'Sir amount',
        _DEDUCTIBLE_POLICY_VALUES: 'Deductible policy values',
        _DEDUCTIBLE_RESIDUE: 'Deductible residue',
        _DEDUCTIBLE_ERODED_AMOUNTS: 'Deductible eroded amounts',
        _PERCENTAGE_AMOUNT: 'Percentage amount',
        _DEDUCTIBLE_AMOUNT_RESIDUE: 'Deductible amount residue',
        _CALCULATED_AMOUNT: 'Calculated amount',
        _MAXIMUM_AMOUNT: 'Maximum amount',
        _MINIMUM_AMOUNT: 'Minimum amount',
        _MAXIMUM_LEVELS_POLICY_VALUES: 'Maximum levels policy values',
        _MAXIMUM_LEVELS_RESIDUE: 'Maximum levels residue',
        _MAXIMUM_LEVELS_ERODED_AMOUNTS: 'Maximum levels eroded amounts',
        _RETURN_DATE: 'Return date ',
        _SETTLEMENT_REVERSAL: 'Settlement reversal ',
        _REVERSAL_DATE: 'Reversal date ',
        _REASON_REVERSAL: 'Reason reversal ',
        _PAYMENT_NUMBER: 'Payment number ',
        _PAYMENT_SAVING_SUMMARY: 'Payment saving summary',
        _ACTIVATED: 'Activated',
        _SETTLEMENT_NUMBER: 'Settlement number ',
        _ISSUED_CORRECTLY: 'Issued correctly ',
        _RISK: 'Risk ',
        _POLICY_ID: 'Policy ID ',
        _CLAIM_EXCESS_POLICY_VALUE: 'Claim excess policy value',
        _CLAIM_CAP_AMOUNT: 'Claim cap amount ',
        _CLAIM_EXCESS_AMOUNT: 'Claim excess amount ',
        _POLICY_ID_END_DATE: 'Policy id end date ',
        _POLICY_ID_START_DATE: 'Policy id start date',
        _CLAIM_CAP_INDICATOR: 'Claim cap indicator ',
        _CLAIM_EXCESS_ADVANCE: 'Claim excess advance ',
        _CLAIM_EXCESS_RESIDUE: 'Claim excess residue ',
        _CLAIM_EXCESS_LIQUIDATOR_ADVANCE: 'Claim excess liquidator advance ',
        _CLAIM_EXCESS_AMOUNT_ADVANCE: 'Claim excess amount advance ',
        _CLAIM_CAP_AMOUNT_RESIDUE: 'Claim cap amount residue ',
        _FIRST_SIR_ADVANCE: 'First sir advance ',
        _AGGREGATE_CAP_INDICATOR: 'Aggregate cap indicator ',
        _EXCESS_AGGREGATE_POLICY_VALUE: 'Excess aggregate policy value ',
        _AGGREGATE_CAP_AMOUNT: 'Aggregate cap amount ',
        _EXCESS_AGGREGATE_AMOUNT: 'Excess aggregate amount ',
        _EXCESS_AGGREGATE_ADVANCE: 'Excess aggregate advance ',
        _EXCESS_AGGREGATE_RESIDUE: 'Excess aggregate residue ',
        _EXCESS_AGGREGATE_LIQUIDATOR_ADVANCE: 'Excess aggregate liquidator advance ',
        _AGGREGATE_CAP_AMOUNT_RESIDUE: 'Aggregate cap amount residue ',
        _EXCESS_AGGREGATE_AMOUNT_RESIDUE: 'Excess aggregate amount residue ',
        _SECOND_SIR_POLICY_VALUES: 'Second sir policy values ',
        _EXCESS_SECOND_SIR: 'Excess second sir ',
        _CLAIM_LIQUIDATOR_ADVANCE_SECOND_SIR: 'Claim liquidator advance second sir',
        _SECOND_SIR_RESIDUE: 'Second sir residue ',
        _CLAIM_DATA: 'Claim data ',
        _NOTIFY_DATE: 'Notify date ',
        _SEVERITY_CODE: 'Severity code ',
        _PHISICAL_INJURY_CODE: 'Phisical injury code ',
        _CASHED: 'Cashed',
        _COLLECTED: 'Collected',
        _FISCAL_TAX_REGIME: 'Fiscal tax regime',
        _GENERATE_PAYMENT: 'Generate payment',
        _INDEMNITY_COST: 'Indemnity cost',
        _LEGAL_CODE: 'Legal code',
        _NEW_INVOICE: 'New invoice',
        _PARTY_CODE: 'Party code',
        _PRESENCE_LAWYERS: 'Presence lawyers',
        _PROTOCOLLO_SAP: 'Sap protocl',
        _PROTOCOLLO_SDI: 'SDI Protocol',
        _RECEIPTS: 'Receipts',
        _RESISTANCE_COST: 'Resistance cost',
        _SETTLEMENT_COST: 'Settlement cost',
        _SETTLEMENTS: 'Settlements',
        _TOTAL_AMOUNT: 'Total amount',
        _PAYMENTS_FIND: 'Payments find',
        _OPENING_DUE_TO_WRIT_OF_SUMMONS: 'Opening due to writ of summons',
        _APPLY_PERCENTAGE_LIABILITY_FORCING: 'Apply percentage liability forcing',
        _DAMAGE_CARD: 'Damage card',
        _SEND_TO: 'Send to',
        _CURRENT_RESERVE: 'Current reserve',
        _NOTES: 'Notes',
        _COMPENSATIONS: 'Compensations',
        _MAGIC_STRING: 'Magic string',
        _INVOLVED_PARTIES__DAMAGE_CAUSE: 'Involved parties/Damage cause',
        _SETTLEMENT_COUNTERS: 'Settlement counters',
        _TYPE_OF_LOSS_COUNTERS: 'Type of loss counters',
        _TRUSTEE_DATA: 'Trustee data',
        _SUBJECT: 'Subject',
        _ROSTER_REGISTRATION_CODE: 'Roster registration code',
        _COURT_REGISTRATION_DATE: 'Court registration date',
        _TAX_OFFICE: 'Tax office',
        _ASSOCIATED_TRUSTEES: 'Associated trustees',
        _SUBJECT_IDENTITY: 'Subject identity',
        _TYPE_PROVIDER: 'Type provider',
        _OFFICE_HEADQUARTERS: 'Office Headquarters',
        _COUNTRY: 'Country',
        _SEARCH_FILTERS: 'Search filters',
        _VAT_NUMBER: 'Vat number',
        _EXPERT_TYPE_PROVIDER: 'Expert Type Provider',
        _DATE_OF_INSERTION: 'Fecha de inserción',
        _CANCELED: 'Canceled',
        _UPDATE_TYPE: 'Actualizar tipo',
        _DATE: 'Fecha',
        _TYPOLOGY: 'Typology',
        _CREATION_DATE: 'Creation date',
        _GROUP: 'Group',
        _BY_WORKLOAD: 'By Workload',
        _USERS_ASSIGNABLE_TASKS: 'Tareas asignables a los usuarios',
        _ORIGIN: 'Origin',
        _LAST_UPDATE: 'Last update',
        _CANCELED_POLICY: 'Canceled policy',
        _EMISSION_DATE: 'Emission date',
        _POLICY_REPLACED_NUMBER: 'Policy replaced number',
        _POLICY_REPLACEMENT_NUMBER: 'Policy replacement number',
        _SUSPENSION_DATE_FROM: 'Suspension date from',
        _SUSPENSION_DATE_TO: 'Suspension date to',
        _PREMIUM_DATE_FROM: 'Premium date from',
        _PREMIUM_DATE_TO: 'Premium date to',
        _OUT_OF_RANGE: 'Out of range',
        _DIARIA_DAYS: 'Diaria days',
        _ARREARS_DAYS: 'Arrears days',
        _PRODUCT_CODE: 'Product code',
        _PRODUCT_DESCRIPTION: 'Product description',
        _DIARIA: 'Diaria',
        _LOCATION: 'Location',
        _TERM_CODE: 'Term code',
        _DAYS: 'Days',
        _SELECTABLE: 'Selectable',
        _POSTAL_CODE: 'Postal code',
        _POLICY_CONDITION: 'Policy condition',
        _POLICY_CEILING: 'Policy ceiling',
        _MINIMUM_UNCOVERED_VALUE: 'Minimum uncovered value',
        _SEARCH_RESULTS: 'Search results',
        _UPDATE_DATE: 'Update date',
        _EXCESS: 'Excess',
        _BRANCH: 'Branch',
        _SURNAME__BUSINESS_NAME: 'Surname/Business name',
        _PERSON_TYPE: 'Person type',
        _LEGAL_PERSON: 'Legal person',
        _EDIT_SUBJECT: 'Edit subject',
        _UPDATE_RESERVE: 'Update reserve',
        _REOPENING_DATA: 'Datos de Reapertura',
        _REOPENING_REASON: 'Motivo de Reapertura',
        _SETTLEMENT_COSTS: 'Settlement costs',
        _VIEW_FOR_ACTORS: 'View for Actors',
        _NEW_MANUAL_DEADLINE: 'Eskuliburuko epe berria',
        _QUOTA_AMOUNT: 'Importe de cuota',
        _AMOUNT_ACTIVE: 'Activo importe',
        _AMOUNT_RESERVE_QUOTA: 'Cuota de reserva activa',
        _OPENING_DATE: 'Fecha de apertura',
        _CLOSING_DATE: 'Fecha de cierre',
        _REFUSED_DATE: 'Fecha rechazada',
        _COMPENSATION_DIARY_N: 'Compensation Diary N.',
        _NEW_NOTE: 'New note',
        _EXTRA_INFO: 'Extra info',
        _NO_DATA: 'No data',
        _CONV_TYPE: 'Convention Type',
        _INVALID_PARAMETER_FORM: 'EU-ES_Invalid parameter form',
        _NO_PARTIES: 'No parties',
        _SELECT_ACTOR_ROLE: 'Select actor role',
        _NEW_ACTOR_LIST: 'Actor list',
        _ASSETS_LIST: 'Assets list',
        _DAMAGED_ASSET: 'Damaged asset',
        _ADD_OTHER_ASSET: 'Add new damage',
        _ACTOR_ROLE: 'Actor role',
        _SOLD: 'Sold',
        _RISK_FACTORS: 'Risk Factors',
        _THING: 'Thing',
        _VEHICLE_ASSET: 'Vehicle Asset',
        _EVENT_RISK: 'Event risk',
        _EVENT: 'Event',
        _NO_REASON_MEETS_THE_SEARCH_CRITERIA: 'No reason meets the search criteria mettila in uppecase',
        _RESIDENCE: 'ES_Residenza',
        _DOMICILE: 'ES_Domicilio',
        _ADD_NEW_ADDRESS: 'ES_Aggiungi nuovo indirizzo',
        _DIALOGUE_DESCRIPTION: 'Dialogue Description',
        _INSURED_PLATE_NUMBER: 'Insured Plate Number',
        _COMPANY_CODE: 'Company Code',
        _INVOLVED_PARTY_IDENTIFIER: 'Involved Party Identifier',
        _INVOLVED_PARTY_KEY: 'Involved Party Key',
        _NUMBER_REQUEST: 'Eskaera zenbakia',
        _RESPONSE_IN: 'Response (In)',
        _REQUEST_OUT: 'Request (Out)',
        _REQUEST_IN: 'Request (In)',
        _RESPONSE_OUT: 'Response (Out)',
        _RESPONSE_INPUT: 'Response (Input)',
        _REQUEST_INPUT: 'Request (Input)',
        _REQUEST_OUTPUT: 'Request (Output)',
        _RESPONSE_OUTPUT: 'Response (Output)',
        _SIMILAR_DIALOGUES: 'Similar Dialogues',
        _CANCEL_MESSAGE: 'Canceled Successfully!',
        _CF_PIVA: 'CF/PIVA',
        _LOSS_DATE: 'Galera data',
        _VERSION: 'Versión',
        _CODE_MESSAGE: 'Kode mezua',
        _PAYMENT_DELETE_CHECK_QUESTION: 'Ziur elementu hau taulatik kendu nahi duzula?',
        _ERROR_DURING_SAVING: 'Error while saving!',
        _NOT_CORRECT_ENTRY_DATA: 'The data entered are incorrect!',
        _WRONG_DATES: 'The dates entered are not valid!',
        _MANDATORY_FUNCTION_CODE: 'Mandatory function code!',
        _INCORRECT_AMOUNT: "The amount entered is not valid!",
        _ALREADY_EXISTS_RECORD: "A record with the same data already exists in the indicated time period",
        _NEW_FORFAIT: 'Forfait berria',
        _PAYMENTS: {
            _IDRECORDFLOW: 'ES_Id record flujo',
            _CODFUNCTION: 'ES_Código de función',
            _CODE_COMPANY: 'ES_Entidad',
            _LOTTO: 'ES_Lote',
            _SEQUENCE: 'ES_Secuencia',
            _REGISTERTYPE: 'ES_Tipo de registro',
            _CODMESSAGE: 'ES_Mensaje',
            _SENDINGFLOWDATE: 'ES_Fecha recepción',
            _YEAR: 'ES_Año',
            _MONTH: 'ES_Mes',
            _OPERATIONCENTRE: 'ES_Centro de Operaciones',
            _OPERATIONCENTRECNT: 'ES_Centro de Operaciones contraparte',
            _COMPANYCODEUS: 'ES_Código de empresa',
            _COMPANYCODECNT: 'ES_Entidad contraria',
            _INTERNALKEY: 'ES_Referencia',
            _EXTERNALKEY: 'ES_Clave externa',
            _LOSSDATE: 'ES_Fecha ocurrencia',
            _ROLE: 'ES_Posición',
            _ERRORCODE: 'ES_Código de error',
            _ERROR: 'ES_Error',
            _INTERNALKEYDLG: 'ES_Clave interna de diálogo',
            _ROLEDLG: 'ES_Posición diálogo',
            _PAVEEDLG: 'ES_Placa con el nombre del actor',
            _CLAIMNUMBERDLG: 'ES_Número de diálogo izquierdo',
            _COMPANYCTRDLG: 'ES_Diálogo empresa contraparte',
            _LOSSDATEDLG: 'ES_Fecha del diálogo de pérdida',
            _PLATECTRDLG: 'ES_Placa homóloga de diálogo',
            _SETTLEMENTNUMBER: 'ES_Número de liquidación',
            _PLATEDLG: 'ES_Placa de diálogo',
            _IMPORTFORFAIT: 'ES_Importe',
            _PAYMENTTYPE: 'ES_Tipo de pago',
            _FORCED: 'ES_Forzar',
            _SKIPPED: 'ES_Saltar',
            _DINS: 'ES_Fecha de inserción',
            _UNIQUECODE: 'ES_Código Tirea',
            _SETTLEMENTDATE: 'ES_Fecha liquidacion',
            _PAYMENTCOLLECTION: 'ES_Pago o recobro',
            _PAY: 'ES_Pago',
            _RECOVER: 'ES_Recobro',
            _TOTALRECORD: 'ES_Total Registros',
            _TOTALRECORDKO: 'ES_Total Registros KO',
            _TOTALRECORDOK: 'ES_Total Registros OK',
            _TOTALAMOUNTPAYMENTS: 'ES_Total Importe - Pagos',
            _TOTALAMOUNTRECOVERED: 'ES_Total Importe - Recobros'
        },
        _TYPE_LICENSE_PLATE_NUMBER_US: 'Type of License plate number us',
        _TYPE_LICENSE_PLATE_NUMBER_CTP: 'Type of License plate number ctp',
        _LST_MES_SEND_DATA: 'Send Date',
        _NO_DELEGATION: 'No Delegation',
        _OUR_DELEGATION: 'Our Delegation',
        _THIRD_PARTY_DELEGATION: 'Third party delegation'
    }
};

