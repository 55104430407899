<div [formGroup]="formGroup">
  <div class="row override-acc" *ngIf="active">
    <pnc-accordion [destroyOnHide]="false" [activeIds]="activePanelList">
      <pnc-panel class='panel-item' [id]="panelName">
        <ng-template let-isOpen="isOpen" class='panel-title' pncPanelTitle>
          <span class="accordion-icon" [class.rgi-plus]="!isOpen" [class.rgi-minus]="isOpen"></span>
          <span data-qa="panel-button" class="rgifont"></span>
          <span class="accordion-text" translate>{{ definition.description }}</span>
          <span class="pull-right header-text">  </span>
        </ng-template>
        <ng-template class="panel-container" pncPanelContent>
          <lic-karma-fund-elements
            formControlName="funds"
            [definition]="definition.funds"
            [totalAmount]="TOTAL_AMOUNT"
            [sliderProperty]="sliderProperty"
            [showSliderInput]="showSliderInput"
            [selectedFundsSummary]="selectedFundsSummary">
          </lic-karma-fund-elements>
        </ng-template>
      </pnc-panel>
    </pnc-accordion>
  </div>
<!--   <div *ngIf="!active" class="profile-recap">
    <div>{{ definition.description }}:</div>
    <ul>
      <li *ngFor="let fund of selectedFunds()">
        <span for="label">{{ fund.name }} </span>
        <span for="label">{{ fund.amount | currency:'EUR':'symbol':'1.2-2':'en-US' }} </span>
        <span for="value">({{ fund.percent | percent: '1.3-3' }})</span>
      </li>
    </ul>
  </div> -->
</div>
