import { Component, Inject, Input, OnInit } from '@angular/core';
import { RoutingService } from '@rgi/portal-ng-core';
import { FormControl, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { RgiRxTabSelectionChange } from '@rgi/rx/ui';
import { DialogueFunction } from '../../models/dialogue-function.model';
import { Months, months } from '../../constants/payments-searchboard.constants';
import { PaymentsSearchboardStateManager } from '../../state-managers/payments-searchboard.state.manager';
import { Searchboard } from '../../states/searchboard.state';
import { SearchForfait } from '../../models/forfait/search-forfait.model';
import { UtilityMethod } from '../../constants/forfait.constants';
import { SearchPayments } from '../../models/payments/search-payments.model';

const currentMonth: number = new Date().getMonth() + 1;
const currentYear: number = new Date().getFullYear();

@Component({
  selector: "claims-payments-searchboard",
  templateUrl: "./payments-searchboard.component.html",
  styleUrls: ["./payments-searchboard.component.scss"],
})
export class PaymentsSearchboardComponent implements OnInit {
  @Input() userHasPermission: boolean = false;

  searchForm: UntypedFormGroup;
  subscriptions: Subscription[] = [];
  searchTableForm: UntypedFormGroup;
  selectedFunctionCode: number;
  errorMessage: string;
  localState: Searchboard;
  selectedFunction: DialogueFunction | null = null;
  tabArray: string[] = ['_CLAIMS_._VALIDATION_AND_CONSOLIDATION', '_CLAIMS_._PAYMENT_MANAGE', '_CLAIMS_._FORFAIT_MANAGE']
  months: Months[] = months;
  years: number[] = [];
  selectedIndex = 0;


  configForm = new UntypedFormGroup({
    selectedIndex: new UntypedFormControl(0)
  });

  constructor(
    @Inject('authService') private authService,
    private formBuilder: UntypedFormBuilder,
    protected routingService: RoutingService,
    public searchboardState: PaymentsSearchboardStateManager
  ) {
    this.configForm.controls.selectedIndex.valueChanges.subscribe(
      (next) => (this.selectedIndex = next)
    );
  }

  ngOnInit() {
    this.checkState();
    this.initForm();
  }

  checkState() {
    this.subscriptions.push(this.searchboardState.getState$().subscribe((state: Searchboard) => {
      if (state && this.localState == null) {
        this.localState = state;
      }

      if (state && state.errorMessage) {
        this.errorMessage = state.errorMessage;
        state.errorMessage = null;
      }
    })
    )
  }

  private initForm(): void {
    this.years = Array.from({length: currentYear - 2024 + 1}, (_, i) => 2024 + i);
    this.months = months.slice(0, currentMonth);

    this.resetForm()
  }

  protected onTabChange(event: any): void {
    this.resetForm()
  }

  protected onYearChange(event: any) {
    const newValue = event.target.value;

    if (newValue == currentYear) {
      this.months = months.slice(0, currentMonth);
    } else if(newValue >= 2024 && newValue < currentYear){
      this.months = months;
    } else {
      this.months = [];
    }
  }

  private goToValidation(): void {
    const searchPaymentsInputDTO: SearchPayments = this.populateSearchPaymentsInputDTO("VC")
    this.searchboardState.searchPayments$(searchPaymentsInputDTO)
  }

  private goToPaymentManage(): void {
    const searchPaymentsInputDTO: SearchPayments = this.populateSearchPaymentsInputDTO("G")
    this.searchboardState.searchPayments$(searchPaymentsInputDTO);
  }

  private populateSearchPaymentsInputDTO(searchType: string): SearchPayments {
    let searchPaymentsInputDTO: SearchPayments = new SearchPayments()
    searchPaymentsInputDTO.searchType = searchType

    if(this.searchForm.valid) {
      if (this.searchForm.get('functionCode').value) searchPaymentsInputDTO.functionCode = this.searchForm.get('functionCode').value
      if (this.searchForm.get('company').value) searchPaymentsInputDTO.company = this.searchForm.get('company').value
      if (this.searchForm.get('companyCtr').value) searchPaymentsInputDTO.companyCtr = this.searchForm.get('companyCtr').value
      if (this.searchForm.get('year').value) searchPaymentsInputDTO.year = this.searchForm.get('year').value
  
      const searchFormMonth: Months = this.searchForm.get('month').value
      if (!!searchFormMonth) {
        searchPaymentsInputDTO.month = String(searchFormMonth.code).padStart(2, '0')
      }
  
      if (this.searchForm.get('msgCode').value) searchPaymentsInputDTO.msgcode = this.searchForm.get('msgCode').value
    }

    return searchPaymentsInputDTO
  }

  private goToForfait(): void {
    const codiceMessaggio: string = this.searchForm.get('msgCode').value;

    const searchElement: SearchForfait = {
      codiceFunzione: this.searchForm.get('functionCode').value,
      codiceMessaggio: codiceMessaggio,
      dataInizioValidita: UtilityMethod.transform(this.searchForm.get('startDate').value),
      dataFineValidita: UtilityMethod.transform(this.searchForm.get('endDate').value),
      codiciMessaggioPossibili: !!codiceMessaggio ? [] : this.selectedFunction?.dialogueMsgData.map(dialogueMsg => dialogueMsg.dialogueMsgCode)
    }

    this.searchboardState.searchForfait$(searchElement);
  }

  protected switchFunctions(selectedIndex: number): void {
    if (this.searchForm.valid) {
      switch (selectedIndex) {
        case 0:
          this.goToValidation();
          break;
        case 1:
          this.goToPaymentManage();
          break;
        case 2:
          this.goToForfait();
          break;
        default:
          this.errorMessage = '_CLAIMS_._ERROR'
          break;
      }
    } else {
      this.errorMessage = '_CLAIMS_._MANDATORY_FUNCTION_CODE'
    }
  }

  private resetForm(): void {
    this.searchForm = this.formBuilder.group({
      functionCode: new FormControl("", Validators.required),
      msgCode: "",
      company: "",
      companyCtr: this.selectedIndex == 1 ? new FormControl("", Validators.required) : "",
      month: "",
      year: "",
      startDate: "",
      endDate: ""
    });

    this.errorMessage = null;
  }

  onSelectionChange(tabSelection: RgiRxTabSelectionChange) {
    this.configForm.controls.selectedIndex.patchValue(tabSelection.index, {
      emitEvent: false,
    });
    this.resetForm();
    this.selectedIndex = this.configForm.controls.selectedIndex.value;
  }

  onChangeFun() {
    let func = this.searchForm.controls.functionCode.value;

    this.selectedFunction = this.searchForm.valid
      ? this.localState.searchWrap.dialogueFunctionResponse.find(elem => elem.functionCode == func)
      : null;

    this.searchForm.controls.msgCode.setValue(null);
  }

  unsubscribeState() {
    this.subscriptions.forEach((sub) => {
      if (sub) {
        sub.unsubscribe();
        sub = null;
      }
    })
  }
}
