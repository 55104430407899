import {
    Component,
    OnDestroy,
    Injector,
    Inject,
    Optional,
    ViewEncapsulation,
    OnInit
} from '@angular/core';
import {
    DatiAmmComponent,
    CardsNavigationService,
    SystemPropertiesService,
    LifeRoleService,
    LifeSessionService,
    ActionsService,
    ValidationSubjectsService,
    NavigationSummaryService,
    BranchesService,
    MasterPolicyService,
    LicCacheService,
    ReinvestementService,
    CustomPropertiesService,
    TranslationWrapperService
} from '@rgi/life-issue-card';
import { QuestionnaireCacheService } from '@rgi/questionnaires-manager';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { switchMap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { PolicyServiceExt } from '../life-card/services/policy-service-ext';

@Component({
    templateUrl: './dati-amm-ext.component.html',
    styleUrls: ['./dati-amm-ext.component.css'],
    encapsulation: ViewEncapsulation.None
})
export class DatiAmmExtComponent extends DatiAmmComponent implements OnInit, OnDestroy {

    constructor(
      protected policyService: PolicyServiceExt,
      protected cardsNavigationService: CardsNavigationService,
      protected paymentInfo: CustomPropertiesService,
      @Inject('$injector') protected injector: Injector,
      protected systemPropertiesService: SystemPropertiesService,
      protected lifeRoleService: LifeRoleService,
      protected lifeSessionService: LifeSessionService,
      protected actionService: ActionsService,
      protected validationService: ValidationSubjectsService,
      protected translateService: TranslationWrapperService,
      protected navigationSummaryService: NavigationSummaryService,
      protected branchesService: BranchesService,
      protected policyMasterService: MasterPolicyService,
      protected cacheInvestments: LicCacheService,
      @Optional() protected cache: QuestionnaireCacheService,
      protected reinvestementService: ReinvestementService,
      protected modalService: NgbModal
    ) {
        super(
            policyService,
            cardsNavigationService,
            paymentInfo,
            injector,
            systemPropertiesService,
            lifeRoleService,
            lifeSessionService,
            actionService,
            validationService,
            translateService,
            navigationSummaryService,
            branchesService,
            policyMasterService,
            cacheInvestments,
            cache,
            reinvestementService,
            modalService
        );
    }

    ngOnInit() {
        super.ngOnInit();
    }

    /** @Override */
    public updateFormValue() {
        super.updateFormValue();
        this.filterGroupedFactors();
    }

    /**  @Override */
    protected initComponentVar(): Observable<any> {
        return super.initComponentVar().pipe(
            switchMap(inited => {
                this.filterGroupedFactors();
                return of(inited);
            })
        );
    }

    /**  @description method used in ext to filter two factors useless to the client  */
    private filterGroupedFactors() {
        this.groupedProductFactors = this.groupedProductFactors.map(g => {
            g.factors = g.factors.filter(f => f.code !== '_DISFO' && f.code !== '_VRPRG');

            return g;
        });
    }
}
