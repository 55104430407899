<div style="margin-bottom: 15px;">
  <span class="button-holder" *ngIf="!volatility && !sustainability">
    <button class="btn btn-primary btn-block"
            [disabled]="disabled"
            (click)="onCalculate()" translate
            data-qa="volatility-button">{{label}}
    </button>
  </span>
  <div class="finantial-param-calculator-box" *ngIf="volatility || sustainability">
    <div [ngClass]="{'volatility-box': hasSustainability, 'borderd-box': !hasSustainability}" *ngIf="volatility">
      <span class="volatility">
        <span translate>lic_volatility</span>: <span data-qa="volatility-value">{{ volatility | rgiCtryLNumFormatter : locale : optionPercents }}</span>
      </span>
    </div>


    <rgi-rx-expansion-panel color="secondary" [expanded]="hasOneSustainability" *ngIf="hasSustainability">
      <div rgi-rx-expansion-panel-header>
        <div class="row sustainability">
          <span translate>lic_Sustainability</span>
        </div>
      </div>
      <ng-container rgiRxExpansionPanelContent *ngFor="let item of sustainability | keyvalue" >
        <div class="tbld sustainability-tbld" data-qa="sustainability-data-table" *ngIf="item.key !== 'errors' && !!item.value" >
          <div class="tbld_row" >
            <div class="tbld_col tbld_col_title sustainability_col" translate>{{'lic_' + item.key}}</div>
            <div class="tbld_col tbld_col_value sustainability_col" [attr.data-qa]="item.key">{{item.value}}
            </div>
          </div>
        </div>
      </ng-container>
    </rgi-rx-expansion-panel>
  </div>

</div>
