import { Inject, Injectable } from '@angular/core';
import { RGI_COUNTRY_LAYER_CURRENCY_CONFIG, RgiCountryLayerCurrencyConfig } from '@rgi/rgi-country-layer';
import { RgiRxTranslationService } from '@rgi/rx/i18n';


export abstract class LpcCurrencyCache {

  abstract set context(con: string);
  abstract get context(): string;

  abstract set currency(curr: string);
  abstract get currency(): string;

  abstract get locale(): string;

  abstract setLocaleOnCtryLayerService(context: string);

  constructor(
    protected config: RgiCountryLayerCurrencyConfig,
    protected rxTranslationsService: RgiRxTranslationService) {}
}

@Injectable()
export class CurrencyCacheService extends LpcCurrencyCache {

  private _context = '';
  private _currency = 'EUR';

  set context(con: string) {
    this._context = con;
  }
  get context(): string {
    return this._context;
  }

  set currency(curr: string) {
    this._currency = curr;
  }
  get currency(): string {
    return this._currency;
  }

  get locale(): string {
    return this.rxTranslationsService.currentLanguage + '-' + this.context;
  }

constructor(
  @Inject(RGI_COUNTRY_LAYER_CURRENCY_CONFIG) public config: RgiCountryLayerCurrencyConfig,
  protected rxTranslationsService: RgiRxTranslationService) {
    super(config, rxTranslationsService);
  }

  setLocaleOnCtryLayerService(context: string) {
    this.config.defaultCurrencyContext = this.rxTranslationsService.currentLanguage + '-' + context;
  }

}
