import { ChangeDetectorRef, Component, Inject, Optional } from '@angular/core';
import { AbsOperationComponent } from '../abs-operation-component/abs-operation.component';
import { EMPTY_STR, PV_TOKEN } from '../../models/consts/lpc-consts';
import { PostsalesOperationsService } from '../../services/postsales-operations.service';
import { TranslationWrapperService } from '../../i18n/translation-wrapper.service';
import { QuestionnaireCacheService } from '@rgi/questionnaires-manager';
import { NotifierService } from '@rgi/portal-ng-core';
import { PlcQuestService } from '../../services/plc-quest.service';
import { AnagService } from '../../services/anag.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from '../../services/auth.service';
import { FormControl, FormGroup, UntypedFormGroup } from '@angular/forms';
import { PENSION_BUYBACK } from './config/pension-buyback.config';
import { RequestFactor } from '../../models/factor.model';
import { PostsalesOperationObject, FinancialData, FactorDefinition, PaymentTypeDefinition, Definition, BeneficiaryDefinition, BeneficiaryRole, Risk, RiskRequest, FormFieldsDefinition, Role } from '../../models/postsales-operations-response.model';
import { FiscalData, FiscalPeriod } from './config/pension-buyback.model';
import { KEY_TO_PERIOD_MAPPING, PAYMENT_METHOD, REVERSIONARY } from './config/pension-buyback.const';
import { LpcBeneficiaryUtils } from '../../modules/lpc-beneficiary/util/lpc-beneficiary-utils';
import { PensionBuyBackUtils } from './config/pension-buyback.utils';

@Component({
  selector: 'lpc-pension-buyback',
  templateUrl: './pension-buyback.component.html',
})

export class PensionBuybackOperationComponent extends AbsOperationComponent {

  protected operationDataKey: string;
  protected requestAssetFactor: RequestFactor[] = [];

  readonly PENSION_BUYBACK_STEP = PENSION_BUYBACK;

  public paymentTypes: PaymentTypeDefinition[] = [];
  public listProductFactor: FactorDefinition[] = [];
  public listRiskFactor: Risk[] = [];

  public annuityDataPaymetType: PaymentTypeDefinition[] = [];

  public performanceData: FiscalData;

  public tableDefinitions: Definition[] = [];
  annuityTableDefinitions: any

  public insuredSettlements: any[] = [];
  public beneficiariesDefinitions: BeneficiaryDefinition[] = [];
  public reversionary: FormFieldsDefinition[];

  protected requestProductFactor: RequestFactor[] = [];
  protected requestRiskFactor: RiskRequest[] = [];
  protected isRiskFactorJustCreated: boolean;

  private _beneficiaryData: any[];


  protected riskFactorCode: string;
  anagSubj: any;

  public percentFormatterOptions: Intl.NumberFormatOptions = {
    style: 'percent',
    maximumFractionDigits: 2,
    minimumFractionDigits: 2
  };

  get hasProductFactor(): boolean {
    return !!this.listProductFactor && !!this.listProductFactor.length;
  }

  get hasRiskFactor(): boolean {
    return !!this.listRiskFactor && !!this.listRiskFactor.length;
  }

  get idAssicurato(): string {
    if (this.beneficiariesDefinitions && this.beneficiariesDefinitions.length) {
      return this.beneficiariesDefinitions[0].idAssicurato;
    } else if (!!this._beneficiaryData && this._beneficiaryData.length) {
      return this._beneficiaryData[0].beneficiary && this._beneficiaryData[0].beneficiary.idAssicurato;
    } else {
      return EMPTY_STR;
    }
  }

  get beneficiaryType(): string {
    if (this.beneficiariesDefinitions && this.beneficiariesDefinitions.length) {
      return this.beneficiariesDefinitions[0].code;
    }
  }

  getReversionaryFormValue() {
    return this.formGroup.get(PENSION_BUYBACK.ANNUITY_DATA.formName)?.get(REVERSIONARY)?.value ?? null;
  }

  getPaymentMethodValue() {
    return this.formGroup.get(PENSION_BUYBACK.ANNUITY_DATA.formName)?.get(PAYMENT_METHOD)?.value ?? null;
  }

  constructor(
    @Inject(PV_TOKEN.POSTSALES_SERVICE) protected operations: PostsalesOperationsService,
    protected cd: ChangeDetectorRef,
    protected translate: TranslationWrapperService,
    @Inject(PV_TOKEN.CORE_INJECTOR) protected injector: any,
    @Optional() protected questCacheService: QuestionnaireCacheService,
    protected modalService: NgbModal,
    protected notifierService: NotifierService,
    protected plcQuestService: PlcQuestService,
    protected authService: AuthService,
    protected anag: AnagService,
  ) {
    super(operations, cd, translate, injector, questCacheService, modalService, notifierService,
      plcQuestService, authService, anag);
  }

  ngOnInit(): void {
    this.initializeSession();
    this.$subscriptions.push(
      this.createDraft().subscribe((result) => {
        this.performanceData = result.definitions.payout as FiscalData;
        if (result.definitions.beneficiaries != null) {
          this.beneficiariesDefinitions = (result.definitions.beneficiaries as BeneficiaryDefinition[]).reverse();
        }
        this._beneficiaryData = result.data.operationData.data.beneficiaries;
        if (!!this._beneficiaryData) {
          this.formGroup.get(PENSION_BUYBACK.BENEFICIARIES.formName).setValue(
            (this._beneficiaryData as any[]).map(beneficiary => {
              return LpcBeneficiaryUtils.toClaimBeneficiary(beneficiary);
            }),
            { emitEvent: false }
          );
        }
      }
      )
    );
  }

  public onNext(step: string = null, publish: boolean = false, context = step, isConfirmAndAccept = false): void {
    this.setFeErrors(step);
    super.onNext(step, publish, context, isConfirmAndAccept);
  }

  onTriggerQuestPreval($event: any) {
    if (!!$event) {
      this.anagSubj = $event;
      this.$subscriptions.push(
        this.plcQuestService.prevalQuest(this.operationDataKey, EMPTY_STR, this).subscribe(result => {
          this.questFactorsArray = result;
          this.disabledQuestionArray = this.plcQuestService.disableQuest(this.operationDataKey, EMPTY_STR, this);
        })
      );
    }
  }


  updateDraftHandleResponse(result: PostsalesOperationObject, step: string, reload?: boolean, opDataType?: string) {
    super.updateDraftHandleResponse(result, step, reload, opDataType);
    this.listProductFactor = result.definitions.productFactors as FactorDefinition[];
    this.paymentTypes = result.definitions.paymentTypes as PaymentTypeDefinition[];
    LpcBeneficiaryUtils.setBeneficiaryRoles(result.definitions.BenefiacyRoles as BeneficiaryRole[]);
    const annuityFormGroup = this.formGroup.get(PENSION_BUYBACK.ANNUITY_DATA.formName) as FormGroup;
    this.listRiskFactor = result.definitions.riskFactors as Risk[];

    if (step === PENSION_BUYBACK.PERFORMANCE_DATA.fieldId) {
      // re-assign new risks factors and add the
      this.listRiskFactor = result.definitions.riskFactors as Risk[];
      this.listRiskFactor.forEach(el => {
        // Create a new FormGroup for each element
        const newGroup = new FormGroup({});

        annuityFormGroup.addControl(el.code, newGroup);
        // Now, add this new FormGroup to the parent formGroup
      });
      annuityFormGroup.addControl(REVERSIONARY, new FormControl())
       const annuity = result.definitions.annuity as FinancialData 
       this.annuityDataPaymetType = annuity.paymentTypes as PaymentTypeDefinition[]
    }
    if (step === PENSION_BUYBACK.ANNUITY_DATA.fieldId) {
      this.insuredSettlements = result.data.operationData.data.insuredSettlements;
      this.tableDefinitions = result.definitions.settlement as Definition[];
      this.annuityTableDefinitions = result.definitions.annuity
      this.reversionary = result.definitions.reversionary as FormFieldsDefinition[];

      // extract all the factors and add them to the requestRiskFactors array but do not reload
      if(reload) {
        this.listRiskFactor.forEach(el => {
          this.updateRiskFactors(PensionBuyBackUtils.convertFormFactors(annuityFormGroup.controls[el.code] as UntypedFormGroup, el.factors as FactorDefinition []), el.code, false)
        })
      }
    }
    this.beneficiariesDefinitions = result.definitions.beneficiaries
    && (result.definitions.beneficiaries as BeneficiaryDefinition[]).reverse();
    this.formGroup.get(PENSION_BUYBACK.BENEFICIARIES.formName).setValue(
      this.getClaimBeneficiaries(result),
      { emitEvent: false }
    );

    this.detectChanges();
  }

  getClaimBeneficiaries(result: any) {
    if (result.data.operationData.data.beneficiaries) {
      return (result.data.operationData.data.beneficiaries as any[]).map(beneficiary => {
        return LpcBeneficiaryUtils.toClaimBeneficiary(beneficiary);
      });
    }
    return [];
  }

  public updateProductFactors(factors: RequestFactor[]) {
    this.requestProductFactor = factors;
     this.$subscriptions.push(this.onReload(PENSION_BUYBACK.POLICY_FACTORS.formName).subscribe());
  }

  public onAddedSubject($event: Role) {
    if ($event === null) {
      this.openModal('lpc_found_duplicate', 'lpc_duplicate_subject_message', true);
    }
  }

  public updateRiskFactors(factors: any[], codeRisk: string, refresh: boolean = true) {
    let changesMade = false; // Variable to track if any changes were made
    this.isRiskFactorJustCreated = false
    // Find the risk group based on riskCode, or create a new group if it doesn't exist
    let riskGroup = this.requestRiskFactor.find(group => group.codeRisk === codeRisk);
    if (!riskGroup) {
        riskGroup = { codeRisk, factors: [] };
        this.requestRiskFactor.push(riskGroup);
        this.isRiskFactorJustCreated = true
    }

    factors.forEach(factor => {
        // Skip this iteration of the loop if the factor value is null
        if (factor.value === null) {
            return;
        }
        // Check if the factor is new or has changed
        const existingFactorIndex = riskGroup.factors.findIndex(el => el.code === factor.code);

        if (existingFactorIndex !== -1) {
            // Check if the existing factor is different from the new one
            if (JSON.stringify(riskGroup.factors[existingFactorIndex]) !== JSON.stringify(factor)) {
                // Update the factor if it has changed
                riskGroup.factors.splice(existingFactorIndex, 1, factor);
                changesMade = true; // Factor updated, so a change was made
            }
        } else {
            // Add the new factor if it does not exist
            riskGroup.factors.push(factor);
            changesMade = true; // Factor updated, so a change was made
        }
    });

    // If refresh is true and any changes were made, then refresh
    if(refresh && changesMade) {
      this.$subscriptions.push(this.onReload(PENSION_BUYBACK.ANNUITY_DATA.formName).subscribe());
  }
}

  protected getFormGroup(): FormGroup {
    return new FormGroup({
      [PENSION_BUYBACK.DATE.formName]: new FormControl(),
      [PENSION_BUYBACK.POLICY_FACTORS.formName]: new FormGroup({}),
      [PENSION_BUYBACK.PERFORMANCE_DATA.formName]: new FormControl({}),
      [PENSION_BUYBACK.ANNUITY_DATA.formName]: new FormGroup({
        [PAYMENT_METHOD]: new FormControl()
      }),
      [PENSION_BUYBACK.QUOTATION.formName]: new FormGroup({}),
      [PENSION_BUYBACK.BENEFICIARIES.formName]: new FormControl([]),
      [PENSION_BUYBACK.PAYMENT.formName]: new FormControl(),
      [PENSION_BUYBACK.RISKS.formName]: new FormControl(),
    });
  }

  private convertToPayoutObject(input: Record<string, number | null | undefined>): FiscalPeriod[] {
    // Guard clause to handle null, undefined, or non-object input
    if (!input || typeof input !== 'object') {
      return [];
    }

    const fiscalPeriods: FiscalPeriod[] = Object.entries(input).map(([key, value]) => {
      // Default value to 0 if it's null or undefined, then convert to string with 2 decimal places
      const payoutValue = (value === null || value === undefined ? 0 : value).toString();
      return {
        period: KEY_TO_PERIOD_MAPPING[key] || 'UNKNOWN_PERIOD',
        payout: payoutValue
      };
    });

    return fiscalPeriods;
  }

  protected getTransformedOperationData(): any {
    return {
      listProductFactor: !!this.requestProductFactor.length ? this.requestProductFactor : [],
      listRisksFactor: !!this.requestRiskFactor.length ? this.requestRiskFactor : [],
      payout: !!this.performanceData?.fiscalPeriods?.length ?
              { fiscalPeriods: this.convertToPayoutObject(this.formGroup.get(PENSION_BUYBACK.PERFORMANCE_DATA.formName).value) } :
              { percentage: this.formGroup.get(PENSION_BUYBACK.PERFORMANCE_DATA.formName).value.percentage / 100 },
      beneficiaries: this.formGroup.getRawValue().beneficiaries
        .map(claimBeneficiary => {
          return LpcBeneficiaryUtils.convertToBeneficiaryRequest(claimBeneficiary);
        }),
      reversionary: !!this.getReversionaryFormValue() ? this.getReversionaryFormValue().reversionary?.value : null,
      payment: !!this.getPaymentMethodValue()?.ciban ? this.getPaymentMethodValue() : null
    };
  }

  onChangePayment(payment) {
    // cambio pagamenti
  }
}
