import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject, Observable, combineLatest, of } from 'rxjs';
import { map, mergeMap, tap } from 'rxjs/operators';
import { UserService } from '@rgi/rx/auth';
import { AlertService } from '@rgi/digital-claims-common-angular';
import { DatePipe } from '@angular/common';
import { CtpDetail, CtpDetailNew, FivaStateModel } from '../fiva-configuration/fiva-model';
import { restEndPoint } from '../fiva-configuration/fiva.endpoint'; 
import {RgiRxTranslationService} from "@rgi/rx/i18n";


@Injectable()
export class FivaService {
  private baseApiUrlV2: string;
  private baseApiUrl: string;
  isReopenCaiModule: BehaviorSubject<boolean> = new BehaviorSubject(false);

  constructor(
    private httpClient: HttpClient,
    protected userService: UserService,
    @Inject('coreResult') private coreResult: any,
    @Inject('sessionService') private sessionService: any,
    @Inject('environment') environment: any,
    @Inject('enumService') private enumService: any,
    public datePipe: DatePipe,
    public translateService: RgiRxTranslationService,
    @Inject("sessionService") private session: any,
    private alertService: AlertService
  ) {
    this.baseApiUrlV2 = environment.api.portal.host + environment.api.portal.path + '/v2';
    this.baseApiUrl = environment.api.portal.host + environment.api.portal.path;
  }

  clearState$(
    stateHomeMotorFlow$: Observable<FivaStateModel>
  ): Observable<FivaStateModel> {
    return stateHomeMotorFlow$.pipe(
      map((st) => {
        st = new FivaStateModel(st.$$id);
        return st;
      })
    );
  }
  closeSession$(activeRouteId: string) {
    this.sessionService.remove(activeRouteId);
  }


  initFivaState$(
    st: Observable<FivaStateModel>,
    claim: any
  ): Observable<FivaStateModel> {
    return st.pipe(
      map((state) => {
        if (claim != null) state.claim = claim;
        return state;
      }), mergeMap((state) => {
          const body = {
            filter: {
              policyNumber: state.claim.policyNumber,
              versionDate: state.claim.reportDate,
            },
          };
          const log = this.baseApiUrl + restEndPoint.getContract;
          const serviceCall = this.httpClient.post<any>(
            this.baseApiUrl + restEndPoint.getContract,
            body
          );
          return combineLatest([serviceCall, of(state)]);
      }), mergeMap(([responseService, state]) => {
        state.claim.contract = {...responseService.contract};
        const claimNumber = state.claim.claimNumber === null ? state.claim.reportNumber : state.claim.claimNumber;
        const queryString = '?companyCode=' + state.claim.internalCompanyCode + '&claimNumber=' + claimNumber;
        const serviceCall = this.httpClient.get<any>(this.baseApiUrlV2 + restEndPoint.getFiva + queryString);
        return combineLatest([serviceCall, of(state)]);
      }), map(([responseList, state]) => {
        const requestArray : CtpDetail[] =[];

        for(let requestObj of responseList){
          const request = requestObj.request;
          const requestEl: CtpDetail= {
            id: request.requestId,
            requestNumber: request.requestNumber,
            dcancel: request.dcancel,
            dins: request.dins,
            cplate: request.cplatecntparty,
            doccurrence: request.doccurrence,
            estatus: request.estatus.descrizione,
            dsend: request.dsend,
            ereport: request.ereport.descrizione
          };

          if(requestObj.responses?.length > 0){
            for(let responses of requestObj.responses){
              let defElement: CtpDetail= {...requestEl,
                ccodcomp: responses.ccodcomp,
                mergeAbsorptionDate: responses.mergeAbsorptionDate,
                compCntPartyNewCod: responses.compCntPartyNewCod,
                cancellationDate: responses.cancellationDate,
                notificationDate: responses.notificationDate,
                message: responses.message
              };
              requestArray.push(defElement);
            }
          }else{
            requestArray.push(requestEl);
          }
        }
        state.ctpList = requestArray;
        return state;

        }
      )
    );
  }

  saveCtpDetail(st: Observable<FivaStateModel>, newCtpDetail: CtpDetailNew) {
    return st.pipe(
      mergeMap((state) => {
        state.newCtpDetail = newCtpDetail;
        const saveCtpDetail =
        this.httpClient.post<any>(this.baseApiUrlV2+ restEndPoint.insertFivaRequest, newCtpDetail);
        return combineLatest([saveCtpDetail, of(state)]);
      }),
      mergeMap(([responseList, state]) => {
        if(responseList && responseList.descr == 'OK'){
          this.generateNewSessionFinder(state);
          return of(state);
        }
        return of(state);
      })
    );
  }
  
  generateNewSessionFinder(state: FivaStateModel) {
      const claimNumber = state.claim.claimNumber !== null ? state.claim.claimNumber
        : state.claim.reportNumber;
      let queryParams = '';
      
      if (state.claim.claimNumber === null) {
        queryParams = 'pes/' + claimNumber
          + '?internalCompanyCode=' + state.claim.internalCompanyCode
          + '&potencialClaimTypeRequest=6';
      } else {
        queryParams = 'claim/' + claimNumber + '?companyCode='
          + state.claim.contract.company.description
          + '&internalCompanyCode=' + state.claim.internalCompanyCode;
      }
      
      this.getDetailClaim(queryParams).subscribe(res => {
        if (res) {
          const claimsFinderSession = this.session.list().find(session => session.isActive).idSession;
          this.session.remove(claimsFinderSession);
          
          const detailResult = res.claim ? res.claim : res.claimInquiry;
          const typeOpen = !res.claim ? 'detail-pes' : 'detail';
          const idResult = this.session.open('claimsFinder', typeOpen, '', true);
          detailResult.idResult = idResult; //appoggio l'id del coreresult per aggiornare l'oggetto in cache
          this.coreResult.setResult(idResult, typeOpen, detailResult);
        }
      }), (error) => {
        this.translateService.translate('_CLAIMS_._MESSAGE_._GET_DETAIL_CLAIM_ERROR').subscribe(res => {
          this.alertService.translatedMessage(res);
        });
      };
    }
    
    getDetailClaim(queryParams: string): Observable<any> {
      return this.httpClient.get<any>(
        this.baseApiUrl + restEndPoint.getSingleClaim + queryParams)
    }

  deleteCtpDetail(st: Observable<FivaStateModel>, ctpDetail: CtpDetail) {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),  body: {
        requestId: ctpDetail.id
      },
    };
    return st.pipe(
      mergeMap((state) => {
        const DeleteCtpDetail =
        this.httpClient.delete<any>(this.baseApiUrlV2+ restEndPoint.deleteFiva, options);
        return combineLatest([DeleteCtpDetail, of(state)]);
      }),
      mergeMap(([responseList, state]) => {
        if(responseList && responseList.descr == 'OK'){
          return this.initFivaState$(st, null);
        }
        return of(state);
      })
    );
  }

  getCurrentSession() {
    return this.sessionService.list().find(el => el.isActive);
  }

  getClaimFromSession(session) {
    return this.coreResult.getResult(session.id, 'detail');
  }

 }
