import { ClaimsTranslationsKeys } from './claims-translations-keys';
/**
 * MORE READABILITY ADVICE
 * Please maintain an alphabetical order in each section
 */
// tslint:disable:max-line-length
export const CLAIMS_ES: ClaimsTranslationsKeys = {
    _CLAIMS_TITLE_CARD_CAI_: 'Nuevo siniestro',
    _CLAIMS_TITLE_DIALOGUE_DASHBOARD_: 'Gestor de diálogos',
    _CLAIMS_TITLE_TIREA_DASHBOARD_: 'TIREA - Compensación Pagos y Recobros',
    _CLAIMS_TITLE_DIALOGUE_HANDLER_LIST: 'Lista de Dialogo',
    _CLAIMS_TITLE_PAYMENT_TABLE_TYREA: 'Tabla de pagos de Tirea',
    _CLAIMS_TITLE_NEW_ACTOR_: 'Nuevo interveniente',
    _CLAIMS_TITLE_HOSPITAL_BILLS_: 'Facturas Hospitalarias',
    _INVOICE: 'Factura',
    _NEW_PAYMENT: 'Nuevo pago',
    _COMPENSATION_LIST: 'Lista de indemnizaciones',
    _CLAIMS_CONNECTOR: 'Conector de Reclamaciones',
    _CHAT: 'CHAT',
    _EDIT_CLAIMS: 'Editar Siniestro',
    _ACTIVITY: 'Actividad',
    _CLAIMS_PILLS: 'Siniestro Píldoras',
    _AUTOSOFT_DASHBOARD: 'Autosoft Dashboard',
    _DOSSIER_MANAGEMENT: 'Documentos de siniestros',
    _CLAIM_DETAIL: 'Detalle del siniestro',
    _NEW_FNOL: 'Nuevo FNOL',
    _RCA_QUESTIONNAIRE: 'Cuestionario RCA',
    _CLAIM_SUBJECTS: 'Sujetos demandantes',
    _CLAIMS_FROM_ELASTIC: 'Siniestro de elásticos',
    _EXPERTS: 'Expertos',
    _LETTER_TEMPLATES: 'Plantillas de cartas',
    _LIMITS_MANAGEMENT: 'Gestión de límites',
    _TIMELINE: 'Cronología',
    _INVOICES_LIST: 'Lista de facturas',
    _UPDATE_RESERVE: 'Reserva de actualización',
    _CLAIMS_: {
        _TAX_REGIME: 'Régimen Fiscal',
        _IVA_REGIME: 'Régimen del IVA',
        _AGGREGATE_TAX_REGIME: 'Régimen fiscal agregado',
        _AGGREGATE_IVA_REGIME: 'Régimen de IVA agregado',
        _FISCAL_DATA: 'Datos fiscales',
        _ACCOUNTING_DATE: 'Fecha de contabilidad',
        _ACT_ACTIVE: 'Acto activo',
        _ACT_CLOSED: 'Acto cerrado',
        _ACT_EVASION_DATE: 'Acto cumplido fecha',
        _ACTION: 'Eliminar Petición',
        _ACTIVE_CHATS: 'Chats activos',
        _ACTUAL_DATE_OF_OCCURRENCE: 'Fecha real del incidente',
        _ACTUAL_TIME_OCCURRENCE: 'Hora real del incidente',
        _ADD_ASSET: 'Añadir bien asegurado',
        _ADDRESS: 'Dirección',
        _ADMIN_LEVEL_1: 'Nivel administrativo 1',
        _ADMIN_LEVEL_2: 'Nivel administrativo 2',
        _ADMIN_LEVEL_3: 'Nivel administrativo 3',
        _ADMINISTRATIVE_REGULARITY: 'Regularidad administrativa',
        _AGENCY_UNIQUE_KEY: 'Clave única de la agencia',
        _AGENCY: 'Agencia',
        _ALLEGATION_DESCRIPTION: 'Descripción de la denuncia',
        _AMOUNT_CLEARED: 'Importe liquidado',
        _AMOUNT_HOLD_TAX: 'Importe de la retención a cuenta',
        _AMOUNT_TO_BE_PAYD: 'Importe a pagar',
        _ASSET: 'Bien asegurado',
        _ASSETS_DATA: 'Datos de bien asegurado',
        _ASSET_DESCRIPTION: 'Descripción del bien asegurado',
        _ASSIGNED_TO_A_GROUP: 'Asignado a un grupo',
        _ASSIGNMENT_SELECTION: 'Selección de asignación',
        _BODY: 'Cuerpo',
        _CANCELED_BY: 'Cancelado por',
        _CANCEL_REQUEST_DATE: 'Fecha Baja',
        _CAP: 'ZIP',
        _CASH_PREVIDENTIAL_AMOUNT: 'Importe del fondo de cotizaciones sociales',
        _CERTIFIED_OCCURRENCE_DATE: 'Fecha del incidente certificada',
        _CERTIFIED_EVENT_DATE: 'Fecha del evento certificada',
        _CHASSIS_NUMBER: 'Número de chasis',
        _CDL_RECEIPT_DATE: 'Fecha de recepción de CHC',
        _CHAT_HISTORY: 'Historial del chat',
        _CHATS_WITH_ME: 'Chats conmigo',
        _CITY: 'Ciudad',
        _CIVIC_NUMBER: 'Número de la calle',
        _CLAIM_NUMBER_RECEIVED: 'Número de siniestro recibido',
        _CLAIM_SELECTION: 'Selección de siniestro',
        _CLAIMS: 'SINIESTROS',
        _CLAIMS_TECHNICAL_STATUS: 'Estado técnico del siniestro',
        _COMPANY_DESCRIPTION: 'Nombre Entidad',
        _COMPLAINT_DATA: 'Datos de la denuncia',
        _CLAIM_NUMBER: 'Número siniestro',
        _CLAIM_POLICY: 'Número de póliza',
        _CLICK_HERE: 'haga clic aquí',
        _CLOSE: 'Close',
        _COHERENT: 'Coherente',
        _COLLECTION_DATE: 'Fecha de cobro',
        _COMMENTS: 'Comentarios',
        _COMPULSORY_QUEST: 'Compulsory question: ',
        _CONFIRM: 'Confirme',
        _CONTRACTOR: 'Contractor',
        _COMPANY: 'Código Entidad',
        _CORPORATE_LITIGATION: 'Corporate Litigation',
        _COUNT: 'Count',
        _COUNTERPARTY_PLATE_NUMBER: 'Matrícula Solicitada',
        _CREATION: 'Creación',
        _DAMAGE_CATEGORY: 'Categoría de daños',
        _DAMAGE_POSITION: 'Posición de daño',
        _DAMAGED: 'Parte perjudicada',
        _DATE_RECEIVED_INTERMEDIARY: 'Fecha de recepción del intermediario',
        _DAMAGE_TO_THINGS: 'Daños a los bienes',
        _DAMAGED__ASSET: 'Parte perjudicada / Bien asegurado',
        _DAMAGED__INVOLVED_ITEM_DATA: 'Datos del elemento dañado/implicado',
        _DAMAGED_ASSETS: 'Bienes asegurados dañados',
        _DAMAGED_PARTS: 'Partes dañadas',
        _DEADLINE_DATA: 'Datos del plazo',
        _DELETED: 'Suprimido',
        _DEROGATION_LEVEL: 'Nivel de autorización',
        _DESELECT_ALL: 'Deseleccionar todo',
        _DETAILS: 'Información',
        _DEPARTMENT: 'Departamento',
        _LAST_EXECUTION_DETAILS: 'Last execution details',
        _SAVE_NEW_REQUEST: 'Save new request',
        _DOCUMENT_MANAGEMENT: 'Gestión de documentos',
        _DOCUMENTS_UPLOAD: 'Carga de documentos',
        _DRIVER: 'Conductor',
        _DRIVER_MANDATORY: 'Conductor obligatorio',
        _DRIVER_LICENSE_TITLE: 'Permiso de conducir',
        _RELEASED_BY: 'Publicado por',
        _RELEASED_DATE: 'Fecha de publicación',
        _EXPIRATION_DATE: 'Fecha de caducidad',
        _CATEGORY: 'Categoría',
        _VALIDITY_START_DATE: 'Fecha de inicio de validez',
        _VALIDITY_END_DATE: 'Fecha final de validez',
        _ADD_CATEGORY: 'Añadir categoría',
        _DUTIES_AMOUNT: 'Importe de los impuestos',
        _EDIT_LIMIT: 'Edición de límite',
        _EMAIL: 'Correo electrónico',
        _ERROR_CODE: 'Código ERROR',
        _EXCLUDE_FROM_CALCULATION: 'Excluir del cálculo',
        _EXCLUDED_FROM_CALCULATION: 'Excluido del cálculo',
        _EXPERT: 'Experto',
        _EFFECTIVE_COMPLAINT_DATE: 'Fecha efectiva de denuncia',
        _EXPERT_DATA: 'Datos del perito',
        _EXPERT_TYPE: 'Tipo experto',
        _FAX: 'Fax',
        _FEES_AMOUNT: 'Importe de los honorarios',
        _FIRST_REGISTRATION_DATE: 'Primera fecha de registro',
        _FISCAL_CODE__VAT_CODE: 'Código fiscal / núm. de IVA',
        _FNOL_DATA_COLLECTION: 'Recogida de datos FNOL',
        _GENDER: 'Género',
        _GENERAL_DATA: 'Datos generales',
        _GIVEN_FIRST_REGISTRATION: 'Dada la primera inscripción',
        _GREEN_CARD_NUMBER: 'Número de carta verde',
        _GROSS: 'En bruto',
        _GROSS_EXCESS: 'Bruto',
        _INCONSISTENT_ROW_AT_LINE_: 'Fila inconsistente en la línea ',
        _METAINFO: 'Metainfo',
        _INDEMNITY_AMOUNT: 'Importe de la indemnización',
        _INITIAL_LIMIT_AMOUNT: 'Importe límite inicial',
        _INITIAL_LIMIT_VALUE: 'Valor límite inicial',
        _INJURY: 'Lesión',
        _INJURY_CODE: 'Código de lesiones',
        _INCIDENT_DATA: 'Datos del incidente',
        _INJURY_PHYSICAL: 'Lesión física',
        _INPS_CONTRIB_AMOUNT: 'Importe de contribución INPS',
        _INSERT: 'Insert',
        _INS_DATE: 'Fecha Recepción',
        _INSERT_NEW_REQUEST: 'Insertar nueva petición',
        _INSERTION_DATE: 'Fecha Petición',
        _INSPECTOR: 'Inspector',
        _INSTANCE: 'Instancia',
        _INSURANCE_COMPANY: 'Compañía de seguros',
        _INSURED_ACTIVITY: 'Actividad asegurada',
        _INSUREDS: 'Asegurados',
        _INSURED_REPORT_DATE: 'Datos de los informes de los asegurados',
        _INVALID_FIELDS: 'Campo no válido',
        _INVOICE: 'Factura',
        _INVOICE_AMOUNT: 'Importe de la factura',
        _INVOICE_DATA: 'Datos de factura',
        _INVOICE_DETAILS: 'Datos de la factura',
        _INVOICE_NUMBER: 'Número de factura',
        _INVOICE_TYPE: 'Tipo de factura',
        _INVOICES: 'Facturas',
        _LETTER_DEROGATION_LEVEL: 'Nivel de autorización de la carta',
        _LETTER_TEMPLATE_SAVED: 'Plantilla de carta guardada',
        _LEVEL: 'Nivel',
        _LEVEL_CODE: 'Código de nivel',
        _LIMITS_POLICY_N: 'Límites póliza n.º',
        _MAIN_SUBSTATUS: 'Subestado principal',
        _MANAGE_PHASE: 'Fase de gestión',
        _MANAGEMENT_NOTE: 'Nota de gestión',
        _MANDATORY_DOCUMENTS: 'Documentos obligatorios',
        _MANUAL_EXCESS: 'Manual de franquicias',
        _MANUFACTURER: 'Fabricante',
        _MATERIAL_DAMAGE: 'Daños materiales',
        _MERGE_ABSORPTION_DATE: 'Fecha fusión-absorción',
        _MESSAGE: 'Mensaje',
        _MESSAGE_RESPONSE: 'Mensaje Respuesta',
        _MESSAGE_REQUEST: 'Mensaje Recibido',
        _MESSAGES: 'Mensajes',
        _MINOR: 'Menor',
        _MINORGUARDIAN: 'TUTOR MENOR',
        _MINISTERIAL_BRANCH: 'Clase ministerial',
        _MOBILE_PHONE: 'Teléfono móvil',
        _MODELS_LIST: 'Lista de plantillas',
        _MODIFY_FNOL: 'Modificar FNOL',
        _MOTOR_CARD: 'Tarjeta del motor',
        _NAME: 'Apellidos',
        _NATION_ADDRESS: 'Dirección del país',
        _NATIONALITY: 'Nacionalidad',
        _NET: 'Neto',
        _NETGROSS_EXCESS: 'Franquicia neta/bruta',
        _NEW_COMPANY: 'Nueva Entidad',
        _NEW_LETTER_MODEL: 'Nuevo modelo de carta',
        _NEW_LIMIT: 'Nuevo límite',
        _NEW_UPLOAD: 'Nueva carga',
        _NORMALIZE: 'Normalizar',
        _NOT_COHERENT: 'No es coherente',
        _NOTIFICATION_DATE: 'Fecha Comunicación Baja',
        _NOT_TAXABLE_AMOUNT: 'Base imponible exenta',
        _OBJECT: 'Concepto',
        _OCCURRENCE_TIME: 'Hora de incidente',
        _OCCURRENCE_DESCRIPTION: 'Descripción del incidente',
        _OBSERVATIONS: 'Observaciones',
        _OPENING_DATE_FROM: 'Fecha de apertura a partir de',
        _OPENING_DATE_TO: 'Fecha de apertura a',
        _OTHER_DOCUMENTS: 'Otros documentos',
        _OTHER: 'Otros',
        _OWNER: 'Propietario',
        _PARTY_INVOLVED: 'Parte implicada',
        _PARKED_VEHICLE: 'Vehículo estacionado',
        _PRODUCT: 'Producto',
        _PASSENGER: 'Pasajero',
        _PAYMENT_AMOUNT: 'Importe de pago',
        _PEC: 'Porcentaje',
        _PERC_PREV_FUND_1: 'Porcentaje prev fondo 1',
        _PERC_PREV_FUND_2: 'Porcentaje prev fondo 2',
        _PERC_WITH_HOLD_TAX: 'Porcentaje de retención a cuenta',
        _PERCENTAGES: 'Porcentajes',
        _PERSON: 'Persona',
        _PHONE: 'Teléfono',
        _PHYSICAL_INJURY: 'Lesión física',
        _PLATE_NUMBER: 'Matrícula asegurado',
        _POLICY: 'Póliza',
        _POLICY_DETAIL: 'Información de la póliza',
        _POLICY_HOLDER__INSURED: 'Tomador / Asegurado',
        _POLICY_LIST: 'Lista de pólizas',
        _PROVINCE: 'Provincia',
        _REAL_NAME: 'Nombre real',
        _RECEIVED_DATE: 'Fecha de recepción',
        _RECEIVED_DATE_FROM: 'Fecha de recepción de',
        _RECEIVED_DATE_TO: 'Fecha de recepción hasta',
        _RECIPIENT: 'Destinatario',
        _REGIME: 'Régimen',
        _REGISTRY: 'Registro',
        _REJECT_WORKED: 'Rechazar trabajado',
        _REPORT: 'Mensaje Envío',
        _RESERVE: 'Reserva',
        _RESIDUE_LIMIT_AMOUNT: 'Importe límite residual',
        _RESIDUE_LIMIT_VALUE: 'Valor límite residual',
        _RISK_DESCRIPTION: 'Descripción del riesgo',
        _ROUNDING_AMOUNT: 'Importe de redondeo',
        _SAP_CODE: 'Código SAP',
        _SAP_RECORDING_DATE: 'Fecha de registro SAP',
        _SDI_CODE: 'Código SDI',
        _SEARCH_DOCTOR: 'Buscar médico',
        _SELECT_ALL: 'Seleccionar todo',
        _SELECT_ASSIGNMENT: 'Seleccionar asignación',
        _SELECT_AT_LEAST_ONE_USER: 'Seleccionar al menos un usuario',
        _SELECT_BY_DEFAULT: 'Seleccionar por defecto',
        _SELECT_CLAIM: 'Seleccionar siniestro',
        _SELECT_POLICY: 'Seleccionar póliza',
        _SELECT_RECIPIENT: 'Seleccionar destinatario',
        _SELECT_THE_CORRESPONDING_ASSIGNMENT_FROM_THE_LIST: 'Seleccione la asignación correspondiente de la lista',
        _SELECT_THE_CORRESPONDING_CLAIM_FROM_THE_LIST: 'Seleccione el siniestro correspondiente de la lista',
        _SELECT_THE_CORRESPONDING_TYPE_OF_LOSS_FROM_THE_LIST: 'Seleccione en la lista el tipo de siniestro correspondiente',
        _SELECT_TYPE_OF_LOSS: 'Seleccione el tipo de siniestro',
        _SELECTECT_SUBJECT: 'Seleccionar parte',
        _SENDING_DATE: 'Fecha Envío',
        _SIC_OUTCOME: 'Resultado SIC',
        _SIMILAR_COMPLAINTS: 'Denuncias similares',
        _SIMILAR_SUBJECT: 'Parte similar',
        _STAMP_DUTY_AMOUNT: 'Importe del impuesto sobre actos jurídicos documentados',
        _SUBJECT_NAME: 'Nombre de la parte',
        _SUBJECT_SURNAME_OR_DENOMINATION: 'Apellido o denominación de la parte',
        _SUBTYPOLOGY: 'Subtipología',
        _SUBTYPOLOGY_CODE: 'Código de subtipología',
        _SUIT_NUMBER: 'Número de demanda',
        _SURNAME: 'Apellido',
        _SX_SUBJECT: 'SX_Sujeto',
        _TAX_ID: 'Identificación fiscal',
        _TECHNICAL_COVERAGE: 'Cobertura técnica',
        _TAXABLE_AMOUNT: 'Base imponible',
        _TOTAL_DISTRUCTION: 'Cancelación',
        _THINGS: 'Bienes',
        _THIRD_PARTY: 'Terceros',
        _TO_BE_EVALUATED: 'Pendiente de evaluación',
        _TO_BE_REEVALUATED: 'Para revaluar',
        _TO_SELECT: 'seleccionar',
        _TOPONYM: 'Nombre del lugar',
        _TOTAL: 'Total',
        _TOT_AMOUNT_UNIT: 'Importe unitario total',
        _TOTAL_RESERVES_AMOUNT: 'Importe total de las reservas',
        _TYPE_OF_LOSS_DESCRIPTION: 'Descripción del tipo de siniestro',
        _TYPE_OF_LOSS_SELECTION: 'Selección del tipo de siniestro',
        _TYPE_OF_DAMAGE: 'Tipo de daño',
        _TYPE_CIVIL__PENAL: 'Tipo Civil/Penal',
        _UNIQUE: 'Único',
        _UPDATE_REASON: 'Actualizar motivo',
        _USER_ASSOCIATION: 'Asociación de usuario',
        _USER_BY_WORKLOAD: 'Usuario (por carga de trabajo)',
        _USER_DATA: 'Datos del usuario',
        _USER_DEROGATION_LEVEL: 'Nivel de autorización del usuario',
        _VAT_AMOUNT: 'Importe IVA',
        _VAT_RATE: 'Tipo de IVA',
        _VEHICLE: 'Vehículo',
        _VISIBLE_DAMAGE_ON_VEHICLE: 'Daños visibles en el vehículo',
        _NUMBER_CLAIM: 'Número de siniestro',
        _OCCURRENCE_DATE: 'Fecha occurencia',
        _CLAIM_BRANCH: 'Sucursal del siniestro',
        _CLAIM_TYPE: 'Tipo de siniestro',
        _POLICY_NUMBER: 'Número de póliza',
        _POLICY_NR: 'Núm. de póliza',
        _CDL: 'CHC',
        _ADJUSTER: 'tramitador',
        _PROCEDURE_NUMBER: 'Número de procedimiento',
        _COUNTERPART: 'Contraparte',
        _COUNTERPARTS: 'Counterparts',
        _INSURED: 'Asegurado',
        _COMPLAINT_DATE: 'Fecha de la denuncia',
        _COVERTURE_TYPE: 'Tipo de cobertura',
        _CLAIM_NUMBER_STATUS: 'Número y estado del siniestro',
        _COMPLAINT_NUMBER_STATUS: 'Número y estado de la denuncia',
        _OCCURRENCE_DATA: 'Datos del incidente',
        _DOCUMENTS: 'Documentos',
        _CANCELLATION_DATE: 'Fecha Cancelación Petición',
        _DESCRIPTION: 'Descripción',
        _EFFECT_DATE: 'Fecha de vigencia',
        _INVALID_POLICY: 'Póliza no válida',
        _POLICY_HOLDER: 'Titular',
        _UNITS: 'Unidad',
        _VALID_POLICY: 'Póliza válida',
        _MANAGEMENT_NODE: 'Nodo de gestión',
        _CONTRACT_STATUS: 'Estado del contrato',
        _FRACTIONING: 'Pago a plazos',
        _ISSUE_FRACTIONING: 'Emisión a plazos',
        _POLICY_TYPE: 'Tipo de póliza',
        _ISSUE_DATE: 'fecha de emisión',
        _COVERAGE_EXPIRATION_DATE: 'Fecha de caducidad de la cobertura',
        _COINSURANCE_SHARE: 'Cuota de coaseguro',
        _INITIAL_EFFECTIVE_DATE: 'Fecha inicial de vigencia',
        _RESCINDABILITY_DATE: 'Fecha de rescisión',
        _COINSURANCE_TYPE: 'Tipo de coaseguro',
        _PREMIUM_ADJUSTMENT: 'Ajuste de la prima',
        _INDEXING: 'Indexación',
        _LPS: 'LPS',
        _NO: 'No',
        _YES: 'Sí',
        _INDIRECT_COINSURANCE: 'Coaseguro indirecto',
        _MIGRATED: 'Migrado',
        _REINSURANCE: 'Reaseguro',
        _BONDED: 'Garantizado',
        _TACIT_RENEWAL: 'Renovación tácita',
        _SUBJECTS: 'Partes',
        _ARCHIVE_SUBJECTS: 'Archivo de partes',
        _LOCATIONS: 'Ubicaciones',
        _PRINCIPAL: 'Principal',
        _PROPERTY_ADDRESS: 'Dirección de la propiedad',
        _SECONDARY: 'Secundario',
        _ACTIVITIES: 'Actividades',
        _VEHICLE_DATA: 'Información del vehículo',
        _CLASS: 'Clase',
        _EXTENSION: 'Extensión',
        _SPECIAL_ABBREVIATION: 'Abreviatura especial',
        _PLATE_TYPE: 'Tipo de Matrícula',
        _BRAND: 'Marca',
        _MODEL: 'Modelo',
        _FACTORS: 'Factores',
        _CLAUSES: 'Cláusulas',
        _ADDITIONAL_DATA: 'Datos adicionales',
        _TYPE_OF_AUTHORITY: 'Tipo de autoridad',
        _DESCRIPTION_AUTHORITY: 'Descripción de la autoridad',
        _CATASTROPHIC_EVENT: 'Catástrofe',
        _DESCRIPTION_EVENT: 'Descripción del suceso',
        _ANTI_FRAUD_CATHEGORY: 'Categoría antifraude',
        _LAST_AUDIT_DATE: 'Última fecha de auditoría',
        _LIQUIDABLE: 'Liquidable',
        _NATION: 'País',
        _ZIP_CODE: 'Código postal',
        _BODYWORK: 'Carrocería',
        _SELECT: 'Seleccionar',
        _DENOMINATION: 'Denominación',
        _TELEPHONE: 'Teléfono',
        _ACCIDENT_CIRCUMSTANCE: 'Circunstancia del accidente',
        _BAREMS: 'Escalas',
        _BLACK_BOX_COHERENCE: 'Coherencia de caja negra',
        _ACCIDENTS: 'Accidentes',
        _INSURED_LIABILITY_PERC: 'Porcentaje de responsabilidad asegurada',
        _EVENT_DENIAL: 'Casos de denegación',
        _SIGN_TYPE: 'Número firmas',
        _INSURED_VEICHLE_POSITION: 'Circustancias vehículo asegurado',
        _THIRD_PART_VEICHLE_POSITION: 'Circustancias vehículo contrario',
        _CALCULATED_LIABILITY: 'Responsabilidad calculada',
        _LIABILITY_TYPE: 'Tipo de responsabilidad',
        _COUNTERPART_LIABILITY_PERC: 'Porcentaje de responsabilidad de la contraparte',
        _DECLARED_LIABILITY: 'Culpa',
        _ANIA_CLASSIFICATION: 'Clasificación ANIA',
        _APPLY_TECH_LIABILITY_FORCED: 'Aplicar porcentaje de responsabilidad (Bonus/Malus)',
        _NOTE: 'Nota',
        _APPLY_ANIA_LIABILITY_FORCED: 'Aplicar la responsabilidad obligada ANIA',
        _FORCE_REASON: 'Forzar motivo',
        _FORCED_LIABILITY: 'Forzar la responsabilidad',
        _EDIT_ACCIDENTS: 'Editar accidentes',
        _USE: 'Uso',
        _BUTTONS_: {
            _ACTIONS: 'ACCIONES',
            _BACK: 'ATRÁS',
            _CLOSE: 'CERRAR',
            _NEXT: 'SIGUIENTE',
            _NEW: 'NUEVO',
            _CANCEL: 'CANCELAR',
            _CONFIRM: 'CONFIRMAR',
            _EXIT: 'SALIR',
            _SAVE: 'GUARDAR',
            _EMPTY: 'LIMPIAR',
            _FIND: 'BUSCAR',
            _SHOW: 'MOSTRAR',
            _HIDE: 'OCULTAR',
            _INSERT: 'INTRODUCIR',
            _NEW_DIALOGUE: 'NUEVO DIÁLOGO',
            _ASSOCIATE: 'ASOCIACIÓN',
            _REQUEST: 'RESPUESTA',
            _RESET_ADVANCE: 'RESET ADVANCE',
            _SAVE_PES: 'SAVE PES',
            _CORRECT: 'CORRECT',
            _ADD: 'AÑADIR',
            _SEARCH: 'SEARCH',
            _IBD: 'IBD',
            _OK: 'OK',
            _ADD_NOTE: 'AÑADIR NOTA',
            _FAILED_TRANSMISSION: 'FAILED TRANSMISSION',
            _EVENTS: 'EVENTS',
            _SEND: 'SEND',
            _DOWNLOAD_DOCUMENTI_SELEZIONATI: 'DOWNLOAD SELECTED DOCUMENTS',
            _DOWNLOAD_CLAIM_DOCUMENTS: 'DOWNLOAD CLAIM DOCUMENTS',
            _DOCVIEWER: 'DOCVIEWER',
            _DOWNLOAD_POLICY_DOCUMENTS: 'DOWNLOAD POLICY DOCUMENTS',
            _EDIT: 'EDITAR',
            _NEW_REQUEST: 'Nueva solicitud',
            _RERUN: 'Rerun',
            _RESERVATION: 'RESERVATION',
            _ONDEMAND: 'ONDEMAND',
            _NEW_MANUAL_DEADLINE: 'Nueva fecha límite',
            _UPLOAD: 'UPOLAD',
            _TRANSMISSION: 'TRANSMISSION',
            _MODELS_LIST: 'Listado de modelos',
            _NEW_LETTER_MODEL: 'Nuevo modelo de carta',
            _DELETE: 'DELETE',
            _ADD_ACTOR: 'ADD ACTOR',
            _RETRIEVE_INFORMATION: 'RETRIEVE INFORMATION',
            _REASSOCIATE: 'REASOCIAR',
            _MODIFY: 'MODIFY',
            _RESPONSE: 'RESPUESTA',
        },
        _MESSAGE_: {
            _NO_RESULT: 'Ningún resultado',
            _OPENED_CLAIM_DO_ISSUE: 'Siniestro abierto por citación judicial',
            _NOT_IDENTIFIED_WITNESS: 'Testigo no identificado',
            _SELECT_ONLY_THE_BOXES_USEFUL_FOR_DESCRIPTION: 'Seleccione solo las casillas de uso para la descripción',
            _SELECT_AT_LEAST_ONE_CAUSAL: 'Seleccione al menos un motivo antes de continuar',
            _INVALID_DATE_INCIDENT: 'La fecha introducida no puede ser diferente de la fecha comunicada',
            _INVALID_REPORTED_DATE_INCIDENT: 'La fecha introducida no puede ser anterior a la fecha del incidente',
            _INVALID_RECEIVED_DATE_INCIDENT: 'La fecha introducida no puede ser anterior a la fecha de la denuncia',
            _INVALID_CHC_DATE : 'La fecha introducida no puede ser anterior a la fecha de recepción del intermediario',
            _INVALID_FUTURE_DATE : 'No es posible seleccionar fechas en el futuro',
            _AMOUNT_TO_BE_PAID_CANNOT_BE_LESS_THAN_THE_SUM_OF_THE_TAXABLE_AMOUNT_AND_THE_NONTAXABLE_AMOUNT: 'El importe a pagar no puede ser inferior a la suma de la base imponible y la base no imponible',
            _AMOUNT_TO_BE_PAID_DIFFERENT_FROM_INVOICE_AMOUNT_MINUS_HOLD_TAX_AMOUNT: 'Importe a pagar distinto del importe de la factura menos el importe de la retención a cuenta',
            _AMOUNT_TO_BE_PAID_MUST_BE_GREATER_THAN_ZERO: 'El importe a pagar debe ser superior a cero',
            _ATTENTION_A_CLAIM_IS_BEING_OPENED_ON_A_TECHNICALLY_UNCOVERED_POLICY: '¡Advertencia! Se está abriendo un siniestro sobre una póliza técnicamente no cubierta',
            _ATTENTION_OPENING_CLAIM_ON_A_CANCELED_POLICY: 'Advertencia: apertura de siniestro sobre una póliza cancelada',
            _ATTENTION_THE_PAYMENT_EXCEEDS_THE_RESIDUAL_AMOUNTS_OF_THE_LIMIT: 'Advertencia: el pago supera los importes residuales del límite',
            _CANNOT_OPEN_A_CLAIM_ON_A_CANCELED_POLICY: 'No se puede abrir un siniestro sobre una póliza cancelada',
            _CANNOT_OPEN_A_CLAIM_ON_A_POLICY_WITHOUT_ADMINISTRATIVE_VALIDITY: 'No se puede abrir un siniestro sobre una póliza sin validez administrativa',
            _CASH_PREVIDENTIAL_AMOUNT_CANNOT_BE_GREATER_THAN_THE_TAXABLE_AMOUNT: 'El importe del fondo de cotizaciones sociales no puede ser superior a la base imponible',
            _DRAG_A_FILE_TO_UPLOAD: 'Arrastre un archivo para cargarlo',
            _HOLD_TAX_AMOUNT_CANNOT_BE_GREATER_THAN_THE_TAXABLE_AMOUNT: 'El importe de la retención a cuenta no puede ser superior a la base imponible',
            _IMPOSSIBLE_TO_EXECUTE_THE_REQUIRED_OPERATION_CLAIM_RECEIPTS_TO_AUTHORIZE: 'Imposible ejecutar la operación requerida: recibos de siniestros para autorizar',
            _IMPOSSIBLE_TO_PROCEED_USER_NOT_AUTHORIZED_TO_OPEN_A_CLAIM_ON_A_TECHNICALLY_UNCOVERED_POLICY: 'No se puede proceder: Usuario no autorizado a abrir un siniestro sobre una póliza técnicamente no cubierta',
            _INSERT_A_CSV_FILE: 'Insertar un archivo .csv',
            _INSERT_POLICY_NUMBER_OR_PLATE: 'Insertar número de póliza o matrícula',
            _INSERT_SUBJECT_SURNAME_OR_DENOMINATION: 'Insertar apellido o denominación de la parte',
            _INVOICE_AMOUNT_DIFFERENT_FROM_SUM_OF_TAXABLE_AMOUNT_NONTAXABLE_AMOUNT_CASH_PREVIDENTIAL_AMOUNT_STAMP_DUTY_AMOUNT_VAT_AMOUNT: 'Importe de la factura distinto de la suma de la base imponible, la base imponible exenta, el importe del fondo de cotizaciones sociales, el importe del impuesto sobre actos jurídicos documentados y el importe IVA',
            _INVOICE_AMOUNT_MUST_BE_GREATER_THAN_ZERO: 'El importe de la factura debe ser superior a cero',
            _IT_IS_NOT_POSSIBLE_TO_DELETE_A_DOCUMENT_ALREADY_UPLOADED_TO_DOCUMENT_MANAGEMENT_SYSTEM: 'Imposible suprimir un documento ya cargado en el sistema de gestión de los documentos',
            _MISSING_CLAIM_NUMBER_AT_LINE: 'Falta el número de siniestro en la línea ',
            _MISSING_NOT_TAXABLE_AMOUNT_AT_LINE: 'Falta la base imponible exenta en la línea ',
            _MISSING_TAXABLE_AMOUNT_AT_LINE: 'Falta la base imponible en la línea ',
            _MISSING_TOTAL_UNIT_AMOUNT_AT_LINE: 'Falta el importe total por unidad en la línea ',
            _N_NOT_CONSISTENT: 'N Los daños del vehículo no concuerdan con los datos de la caja negra',
            _NO_ACTIVITY_TO_SHOW: 'No hay actividad que mostrar',
            _NO_CHANGE_MADE: 'Sin cambios',
            _NO_CHAT: 'Sin chat',
            _NO_DOCUMENTS_TO_UPLOAD: 'No hay documentos para cargar',
            _NO_NORMALIZED_ADDRESS_FOUND: 'No se ha encontrado ninguna dirección normalizada',
            _NO_RESULTS: 'No hay resultados',
            _POLICY_NOT_COMPLIANT_WITH_THE_SELECTED_DAMAGE_CATEGORY: 'Póliza no conforme con la categoría de daños seleccionada',
            _POLICY_NOT_COMPLIANT: 'Póliza no conforme con la categoría de daños seleccionada',
            _PRESS_CALCULATE_BUTTON_BEFORE_CONFIRM: 'Pulse el botón de calcular antes de confirmar',
            _RESERVE_AMOUNT_NOT_CAPACIOUS_UPDATE_RESERVE_BEFORE: 'El importe de la reserva no es elevado: actualice primero la reserva',
            _S_CONSISTENT: 'Y Los daños del vehículo concuerdan con los datos de la caja negra',
            _SAVING_ERROR: 'Error de guardado',
            _SEARCH_ERROR: 'Error de búsqueda',
            _STAMP_DUTY_AMOUNT_CANNOT_BE_GREATER_THAN_THE_TAXABLE_AMOUNT: 'El importe del impuesto sobre actos jurídicos documentados no puede ser superior a la base imponible',
            _SUM_OF_TAXABLE_AMOUNT_AND_NONTAXABLE_AMOUNT_MUST_BE_GREATER_THAN_ZERO: 'La suma de la base imponible y de la base imponible exenta debe ser superior a cero',
            _SUM_OF_THE_NONTAXABLE_AMOUNT_OF_THE_DETAILS_OTHER_THAN_THE_NONTAXABLE_AMOUNT_OF_THE_INVOICE: 'Suma de la base imponible exenta de los datos distinta de la base imponible exenta de la factura',
            _SUM_OF_THE_TAXABLE_AMOUNT_OF_THE_DETAILS_DIFFERENT_FROM_THE_TAXABLE_AMOUNT_OF_THE_INVOICE: 'Suma de la base imponible de los datos distinta de la base imponible de la factura',
            _SUM_OF_THE_TOTAL_UNIT_AMOUNT_OF_THE_DETAILS_DIFFERENT_FROM_THE_INVOICE_AMOUNT: 'Suma del importe unitario total de los datos distinta del importe de la factura',
            _TAXABLE_AMOUNT_MUST_BE_GREATER_THAN_ZERO: 'La base imponible debe ser superior a cero',
            _THE_DOCUMENT_WILL_BE_PERMANENTLY_DELETED_DO_YOU_WITH_TO_CONTINUE: 'El documento se suprimirá de forma permanente. ¿Desea continuar?',
            _THE_POLICY_ALSO_COVERS_MATERIAL_DAMAGE_TO_YOUR_VEHICLE: 'La póliza también cubre los daños materiales de su vehículo',
            _TITLE_AND_DEROGATION_LEVEL_ALREADY_EXIST: 'El título y el nivel de autorización ya existen',
            _TOL_CLAIMS_MADE: 'Existen tipos de siniestros gestionadas con siniestros realizados / póstumos: se reharán los controles de validación técnica y administrativa en el apartado de tipo de siniestro',
            _TOTAL_UNIT_AMOUNT_MUST_BE_GREATER_THAN_ZERO: 'El importe total unitario debe ser superior a cero',
            _UNABLE_TO_MAKE_THE_PAYMENT_THE_RESIDUAL_AMOUNTS_OF_THE_LIMIT_EXCEEDED: 'Imposible efectuar el pago: importe residual del límite superado',
            _UPDATE_SUCCESSFUL: 'Actualización correcta',
            _VAT_AMOUNT_CANNOT_BE_GREATER_THAN_THE_TAXABLE_AMOUNT: 'El importe IVA no puede ser superior a la base imponible',
            _WARNING_YOU_ARE_EXCEEDING_THE_CEILING: 'Advertencia: está sobrepasando el límite máximo',
            _X_TO_BE_VERIFIED: 'X Coherencia de los daños con los datos de la caja negra por verificar',
            _YOU_HAVE_TO_SELECT_AT_LEAST_ONE_ASSET: 'Debe seleccionar al menos un bien asegurado',
            _YOU_HAVE_TO_SELECT_AT_LEAST_ONE_PARTY: 'Debe seleccionar al menos una parte',
            _YOU_NEED_TO_ADD_A_DOCUMENT_IN_ORDER_TO_EXECUTE_THE_UPLOAD: 'Necesita añadir un documento para ejecutar la carga',
            _TYPE_OF_BODYWORK_IS_MANDATORY: 'El tipo de carrocería es obligatorio',
            _NO_RES_FOUND: 'No se ha encontrado ningún resultado',
            _CANNOT_OPEN_CLAIM_FOR_UNCOVERED_POLICY: 'Es imposible abrir una reclamación sobre una póliza no cubierta',
            _FIELDS_MARKED_IN_RED_ARE_REQUIRED_OR_CONTAIN_INCORRECT_VALUES: 'Los campos marcados en rojo son obligatorios o contienen valores incorrectos',
            _INCORRECT_OR_MISSING_DATA: 'Datos incorrectos o ausentes',
            _THERE_ARE_TYPES_OF_LOSS_MANAGED_WITH_CLAIMS_MADE__POSTUMA: 'Existen tipos de siniestro gestionados con siniestros realizados / postuma:',
            _TECHNICAL_AND_ADMINISTRATIVE_VALIDATION_CONTROLS_WILL_BE_REDONE_IN_THE_TYPE_OF_LOSS_SECTION: 'los controles de validación técnica y administrativa se reharán en la sección de tipo de pérdida',
            _CACHE_ERROR: 'Error de la caché',
            _ERROR_IN_INITIALIZATION_PROCEED_WITH_MANUAL_SELECTION: 'ERROR en la inicialización. Proceda con la selección manual',
            _UPDATE_ERROR: 'Error de actualización',
            _SEARCH_EXPERTS_ERROR: 'Error de búsqueda de expertos',
            _SEARCH_REJECT_TYPES_ERROR: 'Buscar tipos de error rechazados',
            _EXISTING_PES_ALERT: 'Ya existe un PSE/siniestro con la misma fecha y póliza. ¿Continúa?',
            _UPDATE_RESERVE_SUCCESSFUL: 'Reserva de actualización correcta',
            _UPDATE_RESERVE_ERROR: 'Actualizar error de reserva',
            _HEIR_ALREADY_ADDED: 'Heredero ya ha sido añadido',
            _HEIR_DATE_INVALID: 'No es posible añadir fecha fatal para un tema elegido como heredero',
            _HEIR_IS_EQUAL_SUBJECT: 'El sujeto fallecido no puede ser añadido como sujeto',
            _HEIR_INVALID: 'No es posible seleccionar como heredero a una persona con fecha fatal',
            _HEIR_INVALID_FATAL_DATE: 'Heredero eliminado debido a la fecha de defunción, vuelva a comprobar los herederos',
            _ON_CONFIRMATION: 'Tras la confirmación, se comprobará la regularidad de la póliza en función de la cobertura seleccionada',
            _YOU_CAN_INSERT_1_TO_2_ATTACHMENT: 'Puede insertar de 1 a 2 archivos adjuntos.',
            _ALLOWED_EXTENSION: 'Extensiones permitidas: txt, pdf, jpg.',
            _THE_SIZE_OF_AN_ATTACHMENT_CANNOT_EXCEED_1_MB: 'El tamaño de un archivo adjunto no puede ser superior a 1 MB.',
            _COMPILING_LETTER_CONTENT: 'Recopilación del contenido de las cartas',
            _OPENED_POST_LITIGATION: 'Abierto tras el litigio',
            _PRESENCE_REQUEST_DAMAGE: 'Presencia solicitud daños',
            _WARNING_THE_RESERVE_HAS_BEEN_REDUCED_BY_THE_PAID_AMOUNT: 'Atención, la reserva se ha reducido en el importe pagado',
            _OF_WHICH_ATTORNEYS_FEES: 'De los cuales, honorarios de abogados',
            _NO_BANK_ACCOUNT_FOR_THE_SUBJECT: 'No hay cuenta bancaria del sujeto',
            _NO_IBAN_PRESENT_OF_THE_PAYEE: 'No hay iban del beneficiario',
            _FOREIGN_BANK_ACCOUNT_ON_SUBJECT: 'Cuenta en un banco extranjero',
            _INSERT_DATE: 'Insertar fecha',
            _CLAIM_EXCESS_POLICY_VALUES: 'Reclamación de exceso de valores de póliza',
            _CLAIM_EXCESS_RESIDUE: 'Exceso de residuo del siniestro',
            _CLAIM_EXCESS_ERODED_AMOUNT: 'Importe erosionado en exceso del siniestro',
            _EXCESS_AGGREGATE_POLICY_VALUES: 'Exceso del valor total de las pólizas',
            _EXCESS_AGGREGATE_RESIDUE: 'Exceso de residuos agregados',
            _EXCESS_AGGREGATE_ERODED_AMOUNT: 'Importe total erosionado en exceso',
            _RECEIPT_ACCEPTED: 'Recibo aceptado',
            _RECEIPT_REJECTED: 'Recepción rechazada',
            _REASON_REJECTED: 'Motivo del rechazo',
            _ONE_PAYEE_IS_REQUIRED: 'Se requiere un beneficiario',
            _ONE_PAYEE_AND_ONE_BENEFICIARIES_REQUIRED: 'Se requiere un beneficiario y un perceptor',
            _ONE_PRIMARY_BENEFICIARIES_REQUIRED: 'Se requiere un beneficiario principal',
            _ONE_PRIMARY_PAYEE_REQUIRED: 'Se requiere un beneficiario principal',
            _TAX_CODE__VAT_NUMBER_MISSING: 'Falta el CIF/NIF',
            _PAYEE_MUST_BE_ADULT: 'El beneficiario debe ser mayor de edad',
            _PAYEE_NOT_REGISTERED_SAVE_SUBJECT_WITH_SUBJECT_MANAGEMENT_FUNCTION: 'El beneficiario no está registrado, guarde el asunto con la función de gestión de asuntos.',
            _BEFORE_DELETE_ADD_NEW_BENEFICIARY: 'Antes de suprimir añadir nuevo beneficiario',
            _BEFORE_DELETE_ADD_NEW_PAYEE: 'Antes de borrar añadir nuevo beneficiario',
            _THE_CANCELLATION_DATE_IS_NOT_VALID: 'La fecha de cancelación no es válida',
            _SELECT_REASON_CANCELLATION: 'Seleccione el motivo de cancelación',
            _METHOD_NOT_IMPLEMENTED: 'Método no aplicado',
            _THERE_IS_A_PAYMENT_WITHOUT_ASSOCIATED_DOCUMENTS_CONTINUE: 'Hay un pago sin documentos asociados, ¿continuar?',
            _SUBJECT_WITH_INCOMPLETE_TAX_DATA__UNABLE_TO_PROCEED: 'Sujeto con datos fiscales incompletos, no puede proceder',
            _OPERATION_NOT_AVAILABLE: 'Operación no disponible',
            _FEE_DATA_MISSING: 'Faltan datos sobre las tasas',
            _MANDATORY_DATA_MISSING: 'Faltan datos obligatorios',
            _SELECT_THE_DEFINITION_FOR_THE_SELECTED_CAUSALS: 'Seleccione la definición',
            _YOU_HAVE_TO_SELECT_AT_LEAST_ONE_TYPE_OF_LOSS: 'Debe seleccionar al menos un tipo de pérdida',
            _THE_AMOUNT_FOR_THE_REASON_SELECTED: 'Importe del motivo seleccionado',
            _INCOMPLETE_ADDRESS: 'Dirección incompleta',
            _INCOMPLETE_DATA: 'Datos incompletos',
            _UIC_NOT_VALID__MAX_3_CHARACTER: 'Uic no válido, máximo 3 caracteres',
            _FOREIGN_BANK_ACCOUNT_INVALID: 'Cuenta bancaria extranjera no válida',
            _IBAN_INVALID: 'Iban no válido',
            _INSERT_A_VALID_ITALIAN_IBAN: 'Inserte un iban italiano válido',
            _SPECIAL_CHARACTERS_NOT_ALLOWED_IN_IBAN_CODE: 'Los caracteres especiales no están permitidos en el código iban',
            _RESERVE_AMOUNT_NOT_CAPACIOUS__UPDATE_RESERVE_BEFORE: 'El importe de la reserva no es suficiente, actualícela antes',
            _IMPOSSIBLE_TO_EXECUTE_REQUIRED_OPERATION__CLAIM_RECEIPTS_TO_AUTHORIZE: 'Imposible ejecutar la operación requerida,reclamar recibos para autorizar',
            _ATTENTION_THE_PAYMENT_EXCEEDS_THE_RESIDUAL_AMOUNT_OF_THE_LIMIT: 'Atención, el pago supera el importe residual del límite',
            _SELECT_REASON_REVERSAL: 'Seleccionar motivo anulación',
            _ENTER_NOTES: 'Enter notes',
            _THE_TOTAL_AMOUNT_CANNOT_BE_GREATER_THAN_OR_EQUAL_TO_0: 'The total amount cannot be greater than or equal to 0 ',
            _THE_CREDIT_NOTE_CANNOT_BE_HIGHER_THAN_THE_ORIGINAL_INVOICE: 'The credit note cannot be higher than the original invoice',
            _INVOICE_TYPE_REQUIRED: 'Invoice type required',
            _FEE_NUMBER_AND_FEE_DATE_ARE_REQUIRED: 'Fee number and fee date are required',
            _THE_REVERSAL_DATE_IS_NOT_VALID: 'The reversal date is not valid',
            _ERROR_CODE: 'Código de error',
            _ERROR: 'Error',
            _SPECIFY_EVENT_ASSIGNMENT_NUMBER_DATE_FROM_TO: 'Specify event/assignmentNumber/date from-to',
            _SPECIFY_ONLY_CLAIM_NUMBER: 'Specify only claim number',
            _SPECIFY_CRITERIA: 'Specify a criteria',
            _SPECIFY_EVENT: 'Specify event',
            _SPEFCIFY_CLAIM_NUMBER: 'Specify claim number',
            _ALERT_NEW_DOC: 'Alert new doc',
            _EXISTING_REQUEST_FOR_DAMAGES_TO_MATCH: 'Existing Requests for damages to match',
            _NO_REQUEST_DAMAGES: 'No hay daños',
            _REQUEST_FOR_DAMAGES_RECEIPT_DATE: 'Request for damages receipt date',
            _GENERIC_ERROR_ON_SAVE_QUESTIONNARIE: 'Error genérico al guardar el cuestionario.',
            _QUESTIONNAIRE_WAS_UPDATE_SUCCESSFUL: 'El cuestionario se actualizó correctamente',
            _QUESTIONNAIRE_MODIFICATION_CANCELLED: 'Modificación del cuestionario cancelada',
            _ENTER_A_VALID_IBAN: 'Introduzca un Iban válido',
            _INSERT_A_VALID_SPANISH_IBAN: 'Introduzca un Iban español válido',
            _NO_NOTES_FOR_THIS_COMPENSATION: 'No hay notas para esta compensación',
            _CHARACTER_LIMIT_EXCEEDED: 'Límite de caracteres superado',
            _AN_ERROR_OCCURED_WHILE_ADDING_THE_NOTE: 'Se ha producido un error al añadir la nota',
            _VALUE_AT_LEAST_ONE_FIELD_BETWEEN_NAME_SURNAME_BUSINESS_NAME_CONTRACTOR_FISCAL_CODE_VAT_CODE_CODE_INDEPENDENT_EXPERT: 'Valore al menos un campo entre: nombre, apellidos, razón social, código fiscal del contratista, código IVA, código experto independiente',
            _ARE_YOU_SURE_YOU_WANT_TO_DELETE_THE_TRUSTEE_ASSOCIATION: '¿Estás seguro de que quieres eliminar la asociación fiduciaria?',
            _EXPERT_LINK_ADDED_WITH_SUCCESS: '¡Enlace experto añadido con éxito!',
            _AN_ERROR_OCCURRED_WHILE_PROCESSING_THE_REQUEST: 'Se ha producido un error al procesar la solicitud.',
            _EXPERT_ASSOCIATION_DELETED_CORRECTLY: 'La asociación de expertos se ha eliminado correctamente.',
            _AN_ERROR_OCCURRED_WHILE_DELETING_THE_EXPERT_ASSOCIATION: 'Se ha producido un error al eliminar la asociación de expertos',
            _IN_ERROR: 'En error',
            _SELECT_A_USER_BEFORE_CONFIRMING: 'Seleccionar un usuario antes de confirmar',
            _OPERATION_PROPERLY_TERMINATED: 'Operación propiamente terminada',
            _INCOMPLETE_COMPILATION: 'Recopilación incompleta',
            _FISCAL_CODE__VAT_UNCORRECT: 'Código fiscal/IVA incorrecto',
            _VAT_UNCORRECT: 'IVA incorrecto',
            _FISCAL_CODE_UNCORRECT: 'Código fiscal incorrecto',
            _SELECT_A_CRITERIA: 'Seleccione un criterio',
            _IMPUTE_THE_NAME_OR_THE_FISCAL_CODE: 'Imputar el nombre o el código fiscal',
            _SPECIFY_THE_TYPE_ASSET: 'Especifique el tipo de activo',
            _SELECT_AT_LEAST_ONE_REASON: 'Seleccione al menos una razón',
            _FILE_IS_EMPTY: 'El archivo está vacío',
            _MAXIMUM_FILE_SIZE_EXCEEDED: 'Se ha superado el tamaño máximo de archivo',
            _NOT_GENERATED_FOR_CHOICE: 'No se genera para elegir',
            _GENERIC_ERROR_SAVE_ACTOR: 'Error al guardar un nuevo actor',
            _SAVE_NEW_ACTOR: 'Guardar nuevo actor',
            _SELECT_THE_DOCUMENT_TO_ATTACH: 'Seleccione los documentos que desea adjuntar al pago',
            _KO_UPLOAD_DOC: 'Se ha producido un error al cargar el documento',
            _CANCELLATION_DIALOGUE: '¿Estás seguro de que quieres cancelar el diálogo?',
        },
        _TITLE_: {
            _QUESTIONNAIRE_TITLE: 'Cuestionario',
            _SELECT_POLICY: 'Seleccionar póliza',
            _OCCURRENCE_DATA: 'Datos del incidente',
            _DATA_COLLECTION: 'Recogida de datos',
            _SUMMARY: 'Resumen',
            _DIALOGUE_HANDLER_LIST: 'Lista de diálogos',
            _DIALOGUE_HANDLER_NEW: 'Nueva demanda',
            _DIALOGUE_HANDLER_SUMMARY: 'Resumen Diálogo',
            _ADDRESS_MANAGEMENT: 'Address management'
        },
        _DATA_FORWARDING_CDL: 'Transmisión de datos a CHC',
        _INTERMEDIARY: 'Intermediario',
        _MASTER_POLICY_NUMBER: 'Número de póliza base',
        _APPLICATION_NUMBER: 'Número de solicitud',
        _COMPLAINT_STATE: 'Estado de la denuncia',
        _PLATE__CHASSIS: 'Chapa / Chasis',
        _ACCOUNTING_COVERAGE: 'Cobertura contable',
        _LEGAL_GUARDIAN_ROLE: 'Papel de tutor legal',
        _CAR_BODY_DETAIL: 'Información de la carrocería',
        _START_DATE_OF_COLLABORATION: 'Fecha de inicio de la colaboración',
        _END_DATE_COLLABORATION: 'Fecha de finalización de la colaboración',
        _HOUSE_NUMBER: 'Número de casa',
        _MUNICIPALITY: 'Municipio',
        _NEW_BODYWORK: 'Carrocería nueva',
        _SUBJECT_TYPE: 'Tipo de parte',
        _CODE: 'Código',
        _BUSINESS_NAME: 'Razón social',
        _FISCAL_CODE: 'Código fiscal',
        _VAT_CODE: 'Código de IVA',
        _TYPE_OF_BODYWORK: 'Tipo de carrocería',
        _INJURY_PHISICAL: 'Lesión física',
        _CAUSE_OF_INJURY: 'Causa de la lesión',
        _UNCODABLE: 'Incodificable',
        _MULTIPLE: 'Múltiple',
        _NATURE_OF_INJURY: 'Naturaleza de la lesión',
        _INJURY_LOCATION: 'Localización de la lesión',
        _FURTHER_INJURY_INDICATOR: 'Indicador de lesión adicional',
        _PROFESSIONAL_QUALIFICATION: 'Cualificación profesional',
        _PERCENTAGE_OF_PERMANENT_DISABILITY: 'Porcentaje de incapacidad permanente',
        _TEMPORARY_DISABILITY_DAYS_WITHOUT_SEQUELAE: 'Días de incapacidad temporal sin secuelas',
        _FATALITY_DATE: 'Fecha de la muerte',
        _FIRST_AID_COUNTRY: 'País de primeros auxilios',
        _FIRST_AID_PLACE: 'Lugar de primeros auxilios',
        _HEALTHCARE_PROVIDERS: 'Proveedores de asistencia sanitaria',
        _BIOLOGICAL_DAMAGE: 'Daño biológico',
        _SUBROGATION_SOCIAL_ASS_EMPLOYER: 'Subrogación asistencia social/Empresario',
        _DOCTOR: 'Doctor',
        _SEARCH: 'Buscar',
        _SKULL: 'CRÁNEO',
        _SPINE_CERVICAL_COLUMN: 'COLUMNA VERTEBRAL-CERVICAL',
        _LEFT_SHOULDER: 'HOMBRO IZQUIERDO',
        _RIGHT_SHOULDER: 'HOMBRO DERECHO',
        _SPINE_THORACIC_COLUMN: 'COLUMNA VERTEBRAL-TORÁCICA',
        _SPINE_LUMBAR_COLUMN: 'COLUMNA VERTEBRAL-LUMBAR',
        _SPINE_SACRUS_AND_COCCYX: 'COLUMNA VERTEBRAL-SACRO Y CÓCCIX',
        _LEFT_ANKLE: 'TOBILLO IZQUIERDO',
        _RIGHT_ANKLE: 'TOBILLO DERECHO',
        _RIGHT_EAR: 'OREJA DERECHA',
        _RIGHT_EYE: 'OJO DERECHO',
        _LEFT_EYE: 'OJO IZQUIERDO',
        _LEFT_EAR: 'OREJA IZQUIERDA',
        _FACE: 'CARA',
        _NECK: 'CUELLO',
        _RIGHT_CHEST_WALL: 'PARED TORÁCICA DERECHA',
        _LEFT_CHEST_WALL: 'PARED TORÁCICA IZQUIERDA',
        _RIGHT_ARM: 'BRAZO DERECHO',
        _THORACIC_ORGANS: 'ÓRGANOS TORÁCICOS',
        _LEFT_ARM: 'BRAZO IZQUIERDO',
        _RIGHT_ELBOW: 'CODO DERECHO',
        _ABDOMINAL_ORGANS: 'ÓRGANOS ABDOMINALES',
        _LEFT_ELBOW: 'CODO IZQUIERDO',
        _RIGHT_FOREARM: 'ANTEBRAZO DERECHO',
        _ABDOMINAL_WALL: 'PARED ABDOMINAL',
        _LEFT_FOREARM: 'ANTEBRAZO IZQUIERDO',
        _PELVIS___RIGHT_HIP: 'PELVIS - CADERA DERECHA',
        _UROGENITAL_ORGANS: 'ÓRGANOS UROGENITALES',
        _PELVIS___LEFT_HIP: 'PELVIS - CADERA IZQUIERDA',
        _RIGHT_WRIST: 'MUÑECA DERECHA',
        _LEFT_WRIST: 'MUÑECA IZQUIERDA',
        _RIGHT_HAND: 'MANO DERECHA',
        _RIGHT_THIGH: 'MUSLO DERECHO',
        _LEFT_THIGH: 'MUSLO IZQUIERDO',
        _LEFT_HAND: 'MANO IZQUIERDA',
        _RIGHT_KNEE: 'RODILLA DERECHA',
        _LEFT_KNEE: 'RODILLA IZQUIERDA',
        _RIGHT_LEG: 'PIERNA DERECHA',
        _LEFT_LEG: 'PIERNA IZQUIERDA',
        _RIGHT_FOOT: 'PIE DERECHO',
        _LEFT_FOOT: 'PIE IZQUIERDO',
        _REQUIRED: 'Requerido',
        _HEIR: 'Heredero',
        _HEIR_ADD: 'Añadir heredero',
        _HEIRS: 'Herederos',
        _EXTERNAL_CLAIM_NUMBER: 'Número de siniestro externo',
        _POTENTIAL_CLAIM_NUMBER: 'Número siniestro Potencial',
        _ALIGN_POLICY: 'Alinear Póliza',
        _ACTIONS: 'Acciones',
        _DATE_ENTRY_FROM: 'Fecha de envío',
        _ELABORATION_DATE_FROM: 'Fecha de recepción sistema',
        _EXPIRY_DATE_FROM: 'Fecha de Caducidad regulatoria',
        _CLAIM_DATE_FROM: 'Fecha de siniestro',
        _FUNCTION: 'Convenio',
        _DAMAGE_MATCH: 'Partido de los Daños',
        _TAX_ID_CODE: 'Código de identificación fiscal',
        _COMPLAINT: 'Número siniestro Potencial',
        _NOT_ASSOCIATED: 'No asociado',
        _CTP_COMPANY: 'Entidad Contraria',
        _US_CAR_LICENSE_PLATE: 'Matrícula',
        _CTP_CAR_LICENSE_PLATE: 'Matrícula Contrario',
        _ACTIVE_TASK: 'Tareas abiertas',
        _ERROR_MESSAGE: 'Mensajes de error',
        _SIMILAR_FLAG: 'Bandera similar',
        _FILTER: 'Filtro',
        _NOT_ASSOCIATED_DIALOGUES: 'Diálogos no asociados',
        _DIALOGUE_DATE_START: 'Fecha de inicio del diálogo',
        _END_DATE: 'Fecha final',
        _SX_DATE: 'Fecha del siniestro',
        _DIALOGUE: 'Datos Dialogo',
        _EXTERNAL_KEY_DAMAGED: 'Identificador lesionado contrario',
        _INTERNAL_KEY_DEF: 'Clave interna definitiva',
        _INTERNAL_KEY_TEMP: 'Clave interna temporal',
        _INTERNAL_KEY_DAMAGED_DEF: 'Llave interna dañada definitiva',
        _INTERNAL_KEY_DAMAGED_TEMP: 'Llave interna dañada temporal',
        _INTERNAL_KEY_DAMAGED: 'Identificador lesionado',
        _US_TYPE_PLATE: 'Tipo Matrícula As.',
        _CTP_TYPE_PLATE: 'Tipo Matrícula Con.',
        _IDENTIFIER_UNIQUE_CODE: 'Código unico (TIREA)',
        _PARAMETERIZED_DIALOGUE_DATA: 'Datos diálogo dinámicos',
        _REGULATORY_EXPIRY_DATE: 'Fecha caducidad',
        _LAST_UPDATE_DATE: 'Última actualización',
        _USER_UPDATE: 'Actualizar usuario',
        _ACTIVITY_TYPE: 'Tipo de acción',
        _PARAMETERS_UPPERCASE: 'PARÁMETROS',
        _REQUEST_NOTE: 'Nota de solicitud ',
        _RESPONSE_NOTE: 'Nota de respuesta',
        _DOCUMENTS_UPPERCASE: 'DOCUMENTOS',
        _DISCARDED_UPPERCASE: 'ERRORES',
        _CAUSAL: 'Causal',
        _EXPIRATION: 'Fecha caducidad sistema',
        _SENDED: 'Fecha envío',
        _START_STATUS: 'Estado de envío',
        _DOCUMENT_TYPE: 'Tipo de documento',
        _DOCUMENT_TYPE_FLUX: 'Tipo de documento (Flux)',
        _CODE_ERROR: 'Código de error',
        _INPUT: 'Input',
        _OUTPUT: 'Output',
        _RESPONSE_NOTES: 'Notas de respuesta',
        _DOCUMENTS_REQUIRED: 'Documentos necesarios para gestionar la respuesta seleccionada',
        _SELECTED_DOCUMENTS: 'Seleccione los documentos que desea asociar a la respuesta introduciendo el tipo de documento de flujo para cada uno de ellos',
        _ACTOR_INVOLVED: 'Actor implicado',
        _REASON: 'Razón',
        _DOCUMENT_TYPE_FLOW: 'Flujo de tipos de documentos',
        _MANDATORY: 'Obligatorio',
        _MATTER: 'Materia',
        _SUB_CATEGORY: 'Subcategoría',
        _LST_MSG_CODE: 'Mensaje',
        _LST_MES_REQ_DATA: 'Fecha envío/ recepción',
        _LST_MES_REG_EXP: 'Fecha de caducidad',
        _START_DATE: 'Fecha de inicio',
        _INTERNAL_KEY: 'Referencia',
        _LST_RESP_MES_CODE: 'Respuesta',
        _LST_MSG_DATE: 'Fecha del último mensaje',
        _LST_MSG_INT_EXP: 'Fecha de caducidad interna (U.M)',
        _EXT_KEY_IDEF_DAM: 'Llave de referencia externa dañada',
        _ONGOING: 'EN CURSO',
        _EVADE: 'EVADE',
        _INSURANCE_CODE_US: 'Entidad',
        _ACTOR_NOMINATIVE: 'Actor asociado',
        _DETAILS_CLAIMS_DATA: 'Detalles Siniestro',
        _CONSOLIDATE: 'Consolidar',
        _BALANCE: 'Saldo',
        _VALIDATION_AND_CONSOLIDATION: 'Validación y Consolidación',
        _PAYMENT_MANAGE: 'Validación Pagos y Recobros',
        _FORFAIT_MANAGE: 'Gestión Forfait',
        _YEAR: 'Año',
        _MONTH: 'Mes',
        _JANUARY: 'Enero',
        _FEBRUARY: 'Febrero',
        _MARCH: 'Marzo',
        _APRIL: 'Abril',
        _MAY: 'Mayo',
        _JUNE: 'Junio',
        _JULY: 'Julio',
        _AUGUST: 'Agosto',
        _SEPTEMBER: 'Septiembre',
        _OCTOBER: 'Octubre',
        _NOVEMBER: 'Noviembre',
        _DECEMBER: 'Diciembre',
        _INCAPACITATED: 'Incapacitados',
        _TYPES_OF_LOSS: 'Tipo de siniestro',
        _RISK_DATA: 'Datos de riesgo',
        _COVERTURE_DATA: 'Datos de cobertura',
        _RULES_FOR_UPLOADING_DOCUMENTS: 'Normas para cargar documentos:',
        _OCCURRENCE_HOUR: 'Hora de ocurrencia',
        _DATE_INTERMEDIARY: 'Fecha intermediario',
        _TYPE_OF_AUTHORITIES_POSSIBLY_INVOLVED: 'Tipo de autoridades eventualmente implicadas',
        _CATASTROPHIC_EVENT_DESCRIPTION: 'Descripción de la catástrofe',
        _OPENING_MODE: 'Modo de apertura de siniestro',
        _PARTY: 'Parte',
        _ASSET_INSURED: 'Seguro de activos',
        _NEW_DAMAGED: 'Nuevo dañado',
        _TYPE_OF_LOSS: 'Daño causal',
        _NOMINATIVE: 'Asunto implicado',
        _NO_RESULT: 'Sin resultados.',
        _NEW_ASSET: 'Nuevo activo',
        _ASSET_TYPE: 'Tipo de activo',
        _TYPE: 'Tipo',
        _FOUND: 'Encontrado',
        _COVERAGES_DETAIL: 'Detalle de las coberturas',
        _ADD_A_DAMAGED: 'Añadir un dameged',
        _PHYSICAL_PERSON: 'Persona física',
        _POLICY_DATA: 'Datos de póliza',
        _ADMINISTRATIVE_VALIDITY: 'Validez administrativa',
        _LIST_OF_RELATED_CLAIMS: 'Lista de reclamaciones relacionadas',
        _CF__PIVA: 'CIF/NIF',
        _REFERENCE: 'Referencia',
        _DOL: 'DOL',
        _DON: 'DON',
        _MAIN: 'Principal',
        _ORIGINAL_EFFECT_DATE: 'Fecha de efecto original',
        _NO_POLICY_TO_SHOW: 'Ninguna políza que mostrar',
        _ASSIGNMENT_DATE: 'Fecha de asignación',
        _STATUS: 'Estado',
        _FILTERS: 'Filtros',
        _CLAIMS_HANDLER: ' Gestor de siniestros',
        _TECHNICAL_STATE: 'Estado técnico',
        _RESERVED: 'Reservado',
        _INVOICE_DATE: 'Fecha de la factura',
        _AMOUNT: 'Monto',
        _REJECT_TYPE: 'Tipo de rechazo',
        _UPLOAD_DETAILS: 'Cargar detalles',
        _NUMBER: 'Número',
        _ACTOR: 'Actor',
        _UPLOAD: 'Cargar',
        _EXPERT_INVOICE: 'Factura pericial',
        _TAX_CODE__VAT_NUMBER: 'Código fiscal/Número de IVA',
        _MANUAL_NOTE: 'Nota manual',
        _INSERT_A_NEW_MANUAL_NOTE: 'Insertar una nueva nota manual',
        _COMMENTS_FORM: 'Formulario de comentarios',
        _THERE_ARE_NO_COMMENTS: 'No hay comentarios',
        _NO_PERMISSION: 'Sin permiso',
        _CANCELLATION: 'Cancelación',
        _COMMENT: 'Comentario',
        _TOTAL_ASSIGNMENT_EXPENSE_AMOUNT: 'Monto total de gastos de asignación',
        _TOTAL_RESERVE_FOR_LITIGATION: 'Reserva total para litigios',
        _FROM: 'Desde',
        _TO: 'Hasta',
        _QUICK_FILTERS: 'Filtros rápidos',
        _ASCENDING_ORDER: 'Orden ascendente',
        _DESCENDING_ORDER: 'Orden descendente',
        _MOVEMENTS: 'Movimientos',
        _ACCESSES: 'Accesos',
        _ACTIVITY: 'Actividad',
        _RESERVES: 'Reservas',
        _TITLES: 'Títulos',
        _STATE: 'Estado',
        _ASSIGNEE_GROUP: 'Asignar grupo',
        _ASSIGNEE_USER: 'Asignar usuario',
        _RUN_DATE: 'fecha de ejecución',
        _PRIORITY: 'Prioridad',
        _SUSPENDED: 'Suspendido',
        _COMPLETION_DATE: 'Fecha de finalización',
        _ESCAPE_METHODS: 'Métodos de escape',
        _RUN_ACTIVITY: 'Ejecutar la actividad',
        _TAKE_CHARGE_ACTIVITY: 'Tome las riendas de la actividad',
        _REASSESS_TASK: 'Reevaluar la tarea',
        _NO_SUGGESTIONS: 'Ninguna sugerencia',
        _CRITERIA: 'Criterios',
        _ASSIGNMENT: 'Asignación',
        _EVENTS: 'Eventos',
        _R_PER: 'Asignación de devolución (R-PER)',
        _A_COM: 'Comunicación (A-COM)',
        _S_RIF: 'Rechazar la asignación (S-RIF)',
        _R_FAT: 'Factura  (R-FAT)',
        _TRANSMISSION_LIST: 'Lista de transmisiones',
        _FAILED_TRANSMISSION_LIST: 'Lista de transmisión fallida',
        _STARTER_EVENT: 'Inicio del evento',
        _REQUEST: 'Mensaje recibido',
        _ID: 'Id',
        _WS: 'WS',
        _XML: 'Xml',
        _RESPONSE: 'Mensaje respuesta',
        _DETAIL: 'Detalle',
        _VIEW_DETAIL_BLOW: 'Ver golpe de detalle',
        _INSERT_DATE: 'Insertar fecha',
        _ELABORATION_DATE: 'Fecha de elaboración',
        _INSUFFICIENT_PERMISSIONS: 'Permisos insuficientes',
        _CLAIM: 'Número siniestro',
        _POTENTIAL_CLAIM_INQUIRY: 'Investigación de reclamación potencial',
        _VALIDATE: 'Validar',
        _INVALIDATE: 'Invalidar',
        _MODIFY: 'Editar',
        _SUBCATEGORY: 'Subcategoría',
        _RECEPTION_CHANNEL: 'Canal de recepción',
        _FORMAT: 'Formato',
        _SIZE: 'Talla',
        _NUMBER_OF_PAGES: 'Número de páginas',
        _RECEIIPT_DATE: 'Fecha de recepción',
        _USER: 'Usuario',
        _SUBTITLE: 'Subtítulo',
        _DOC_KEY: 'Clave Doc',
        _POSTALIZATION_RESULT: 'Resultado de la postalización',
        _POSTALIZATION_DATE: 'Fecha de postalización',
        _SHIPPING_TYPE: 'Tipo de envío',
        _VIEW_BY_CATEGORIES: 'Ver por categorías',
        _UPLOAD_DATE: 'Fecha de carga',
        _SUBCATEGORY_DEPRECATED: 'Subcategoría obsoleta',
        _CATEGORY_DEPRECATED: ' Categoría obsoleta',
        _HISTORY: 'Historia',
        _OPERATIONS: 'Operaciones',
        _EDIT_DOCUMENT: 'Editar los datos del documento',
        _CHANGE_PARTY: 'Cambiar de partido',
        _SELECT_CATEGORY: 'Seleccionar categoría',
        _SELECT_SUBCATEGORY: 'Seleccionar subcategoría',
        _PEOPLE: 'Personas',
        _EXTERNAL_VISIBILITY: 'Visibilidad exterior',
        _VEICHLE: 'Veichle',
        _VALIDATED: 'Validar',
        _NO_VALIDATE: 'No validar',
        _TECHNICAL_STATUS: 'Estado técnico',
        _RESERVE_AMOUNT: 'Cantidad de Reserva',
        _TECHNICAL_REACTIVATION_OPENING_DATE: 'Reactivación/fecha de apertura técnica',
        _ACCOUNTING_REACTIVATION_OPENING_DATE: 'Fecha de apertura de reactivación contable',
        _TECHNICAL_CLOSING_DATE: 'Fecha de cierre técnico',
        _NEW_QUESTIONNAIRE_DATE: 'Fecha del nuevo cuestionario',
        _ROLE: 'Posición',
        _CODE_CHAIN: 'Cadena de códigos',
        _EXTERNAL_KEY: 'Referencia contrario',
        _CODE_ACTION: 'Código de acción',
        _DATA_ENTRY: 'Introducción de datos',
        _OUTCOME: 'Resultado',
        _INSERTED: 'Insertado',
        _JSON_N: 'Json N°',
        _LAST_ELABORATION: 'Última fecha modificación',
        _CANCELLATION_REASON: 'Motivo de la cancelación',
        _ASSOCIATED_ENTITY: 'Entidad asociada',
        _EXECUTIONS: 'Ejecuciones',
        _JSON_OK_N: 'Json OK n.',
        _JSON_KO_N: 'Json KO n.',
        _RERUN: 'Vuelva a ejecutar',
        _RESERVATION_DATE: 'Fecha de reserva',
        _IN_PROGRESS: 'En curso',
        _CLOSED: 'Cerrado',
        _FORCED_CLOSURE: 'Cierre forzoso',
        _NOT_STOPPED: 'No parado',
        _STOPPED_SUCCESSFULLY: 'Detenido con éxito',
        _RERUN_RESERVED: 'Repetición reservada',
        _RERUN_FAILURE: 'Fallo de repetición',
        _RESULTS: 'Resultados',
        _TITLE: 'Título',
        _INSERTION: 'Fecha de envío',
        _ELABORATION: 'Elaboración',
        _REQUEST_TYPE: 'Tipo de solicitud',
        _ELABORATION_REQUEST_DATE: 'Fecha de solicitud de elaboración',
        _INPUT_PARAMETERS: 'Parámetros de entrada',
        _JSON: 'Json',
        _JSON_EXTERNAL_KEY: 'Json Clave externa',
        _CREATION_FAILED: 'Creación fallida',
        _REQUEST_CREATED: 'Solicitud creada',
        _RESERVATION: 'Reserva',
        _CREATION_ERROR: 'Error de creación',
        _TOO_MANY_JSON_WERE_UPLOADED: 'Se han cargado demasiados json',
        _COMPANY_SETTLEMENT_AMOUNT: 'Monto de liquidación de la empresa',
        _PAYEE: 'Beneficiario',
        _PAYMENT_TYPE: 'Tipo de pago',
        _BENEFICIARIES: ' Beneficiarios',
        _ADD_BENEFICIARY: 'Añadir beneficiario',
        _PAYEES: 'Beneficiarios',
        _ADD_PAYEE: 'Añadir beneficiario',
        _CALCULATE: 'Calcular',
        _REASON_CANCELLATION: 'Motivo de la cancelación',
        _C__O: 'C/O',
        _NO_DOCUMENTS: 'Sin documentos',
        _FEE_DATA: 'Datos de tasas',
        _FEE_NUMBER: 'Número de tasa',
        _FEE_DATE: 'Fecha de la tasa',
        _IVA_AMOUNT: 'Importe Iva',
        _IRPF_AMOUNT: 'Importe IRPF',
        _WITH_HOLDING_AMOUNT: 'Con importe de retención',
        _NET_AMOUNT: 'Importe neto',
        _CASH_AMOUNT_1: 'Importe en efectivo 1',
        _CASH_AMOUNT_2: 'Importe en efectivo 2',
        _SETTLEMENT_BLOCKED__PERCENTAGE_SAME_INVOICE_ALREADY_REIMBURSED_TO_INSURED: 'Liquidación bloqueada: % misma factura ya reembolsada al asegurado',
        _PAID_AMOUNT: 'Importe pagado ',
        _PERCENTAGE_OF_THE_TOTAL: '% del total ',
        _ALREADY_INSERT: 'Ya insertar',
        _REMAINING_AMOUNT: ' Importe restante',
        _EVALUATION: ' Evaluación',
        _PAYMENT_DATA: 'Datos de pago',
        _CALCULATION_OF_THE_INSURED_FEE: 'Cálculo de la tarifa asegurada',
        _BENEFICIARIES_AND_PAYEES: 'Beneficiarias y beneficiarias',
        _METHODS_OF_PAYMENT: 'Métodos de pago',
        _LETTERS_MANAGEMENT: 'Gestión de cartas',
        _RECEIPT_SUMMARY: 'Resumen de recibos',
        _SETTLEMENT_SUMMARY: 'Resumen de la liquidación',
        _PARTY_ROLE: 'Papel del partido',
        _OPERATION_USER: 'Usuario de operación',
        _QUITTANCE_DATE: 'Fecha de despido',
        _CURRENCY_DATE: 'Fecha de moneda',
        _PAYMENT_TRANSACTION_TYPE: 'Tipo de transacción del pago',
        _RECEIPT_TYPE: 'Tipo de recibo',
        _MANAGMENT_PAYMENT: 'Pago de la gestión',
        _SEPARATE_RECEIPT: 'Recibo separado',
        _CURRENCY: 'Moneda',
        _DUE_DATE: 'Fecha de vencimiento',
        _PROCEDURE_CODE: 'Código del procedimiento',
        _AMOUNT_QUOTA: 'Cuota del monto',
        _OPEN_DETAIL: 'Abrir detalle',
        _FORFAIT_TYPE: 'Tipo de forfait',
        _AMOUNT_QUOTA_FORFAIT: 'Cuota del monto forfait',
        _FORFAIT: 'Forfait',
        _IMPORT: 'Importe',
        _METHOD_OF_PAYMENT: 'Forma de pago',
        _BANK_TRANSFER_DESCRIPTION: 'Descripción de la transferencia bancaria',
        _INCOMPLETE_ADDRESS: 'Dirección incompleta',
        _NOT_GENERATED_FOR_MISSING_DATA: 'No se genera por falta de datos',
        _GENERATED: 'Generado',
        _RETURN_OF_RECEIPT: 'Devolución de recibo',
        _RECEIPT_STATUS: 'Estado de recepción',
        _RECEIPT_DATE: 'Fecha de recepción',
        _REASON_CANCEL_RECEIPT: 'Motivo cancelación recibo',
        _RECEIPT_NOTES: 'Notas de recibo',
        _RETURN: 'Devolver',
        _BENEFICIARY: 'Beneficiario',
        _SENDING_LETTERS: 'Envío de cartas',
        _MANAGEMENT_PAYMENT: 'Pago de la gestión',
        _PAYABILITY: 'Pagabilidad',
        _WAITING: 'En espera',
        _SUBSTATUS: 'Substatus',
        _PAYMENT_DEFINITION: 'Definición del pago',
        _RESERVED_AMOUNT: 'Importe reservado',
        _PARTIAL: 'Parcial',
        _CHC: 'CHC',
        _LOAD_DATE: 'Fecha de carga',
        _OFFER_DATE: 'Fecha de la oferta',
        _IBAN: 'Iban',
        _IBAN_ON_SUBJECT: 'Iban en el asunto',
        _ACCOUNT_HOLDER: 'Titular de la cuenta',
        _BANK_DESCRIPTION: 'Descripción bancaria',
        _BANK_ADDRESS: 'Dirección bancaria',
        _SWIFT: 'Swift',
        _FOREIGN_BANK_ACCOUNT: 'Cuenta bancaria extranjera',
        _FOREIGN_BANK_ACCOUNT_ON_SUBJECT: 'Foreign bank account on subject',
        _BIC: 'BIC',
        _UIC: 'UIC',
        _BANK: 'Banco',
        _LEGAL_OPPONENT: 'Oponente legal',
        _REGISTER_SUBJECT: 'Asunto del registro',
        _OPEN_REGISTER: 'Registro abierto',
        _SETTLEMENTS_LIST: 'Lista de acuerdos',
        _RECEIPTS_LIST: 'Lista de recibos',
        _COMPENSATIONS_LIST: 'Lista de indemnizaciones',
        _APPOINTMENTS_LIST: 'Lista de citas',
        _TYPOLOGIES_SETTLEMENT_LIST: 'Tipologías de asentamientos',
        _ACCESSORY_CHECKS: 'Controles accesorios',
        _FORCING_APPLY: 'Forzar la aplicación',
        _ADMINISTRATIVE_VALIDITY_OVERIDDEN_ON_POLICY_NO: 'Validez administrativa anulada en la política no',
        _EFFECTIVE_DATE_OF_COLLECTION: 'Fecha efectiva de cobro',
        _EXPIRY_DATE_OF_COLLECTION: 'Fecha de expiración de la recogida',
        _DATE_OF_COLLECTION: 'Fecha de recogida',
        _CONFIRM_RESPONSIBILITY: 'Confirmar la responsabilidad',
        _PERCENTAGE_INSURED_LIABILITY: 'Porcentaje de responsabilidad asegurada',
        _REASON_TO_FORCE_LIABILITY_PERCENTAGE: 'Razón para forzar el porcentaje de responsabilidad',
        _COUNTERPART_VEHICLE: 'Vehículo de contrapartida',
        _CHASSIS__PLATE_NUMBER: 'Número de matrícula del chasis',
        _LIABILITY_PERCENTAGE: 'Porcentaje de responsabilidad',
        _HISTORICAL_EROSION: 'Erosión histórica',
        _INPUT_AMOUNT: 'Cantidad de entrada',
        _INSURED_SETTLEMENT_AMOUNT: 'Monto de liquidación asegurado',
        _CLAIM_EXCESS: 'Exceso de siniestro',
        _DEDUCTIBLE: 'Deducible',
        _EXCESS_AGGREGATE: 'Exceso de agregado',
        _SIR: 'Señor',
        _LIMIT: 'Límite',
        _FIRST_SIR: 'Primer señor',
        _SECOND_SIR: 'Segundo señor',
        _ADVANCE: 'Avanzar',
        _SIR_ADVANCED_BY_COMPANY_SOMETIMES: 'Señor adelantado por empresa a veces',
        _CAP_INDICATOR: 'Cap indicador',
        _CAP_AMOUNT: 'Cap importe',
        _EXCESS_AMOUNT: 'Importe en exceso',
        _FIRST_SIR_POLICY_VALUES: 'Valores de la primera póliza principal ',
        _FIRST_SIR_RESIDUE: 'Primer señor residuo ',
        _FIRST_SIR_ERODED_AMOUNT: 'Primer señor importe erosionado',
        _SIR_AMOUNT: 'Señor importe',
        _DEDUCTIBLE_POLICY_VALUES: 'Valores deducibles de las pólizas',
        _DEDUCTIBLE_RESIDUE: 'Residuo deducible',
        _DEDUCTIBLE_ERODED_AMOUNTS: 'Importes deducibles erosionados',
        _PERCENTAGE_AMOUNT: 'Importe porcentual',
        _DEDUCTIBLE_AMOUNT_RESIDUE: 'Residuo de importe deducible',
        _CALCULATED_AMOUNT: 'Importe calculado',
        _MAXIMUM_AMOUNT: 'Importe máximo',
        _MINIMUM_AMOUNT: 'Importe mínimo',
        _MAXIMUM_LEVELS_POLICY_VALUES: 'Valores máximos de las pólizas',
        _MAXIMUM_LEVELS_RESIDUE: 'Niveles máximos de residuos',
        _MAXIMUM_LEVELS_ERODED_AMOUNTS: 'Niveles máximos cantidades erosionadas',
        _RETURN_DATE: 'Fecha de regreso ',
        _SETTLEMENT_REVERSAL: 'Anulación de la liquidación ',
        _REVERSAL_DATE: 'Fecha de anulación ',
        _REASON_REVERSAL: 'Motivo de la anulación ',
        _PAYMENT_NUMBER: 'Número de pago ',
        _PAYMENT_SAVING_SUMMARY: 'Resumen del ahorro en los pagos',
        _ACTIVATED: 'Activado',
        _SETTLEMENT_NUMBER: 'Número de liquidación ',
        _ISSUED_CORRECTLY: 'Emitido correctamente ',
        _RISK: 'Riesgo ',
        _POLICY_ID: 'Políza ID ',
        _CLAIM_EXCESS_POLICY_VALUE: 'Valor de la póliza de exceso de siniestro',
        _CLAIM_CAP_AMOUNT: 'Importe máximo de la solicitud ',
        _CLAIM_EXCESS_AMOUNT: 'Importe de la franquicia reclamada ',
        _POLICY_ID_END_DATE: 'Fecha de finalización de la póliza ',
        _POLICY_ID_START_DATE: 'Fecha de inicio de la póliza',
        _CLAIM_CAP_INDICATOR: 'Indicador de límite de reclamación ',
        _CLAIM_EXCESS_ADVANCE: 'Anticipo del exceso del siniestro',
        _CLAIM_EXCESS_RESIDUE: 'Residuo del exceso del siniestro ',
        _CLAIM_EXCESS_LIQUIDATOR_ADVANCE: 'Anticipo del liquidador del exceso del siniestro',
        _CLAIM_EXCESS_AMOUNT_ADVANCE: 'Importe del anticipo del exceso del siniestro',
        _CLAIM_CAP_AMOUNT_RESIDUE: 'Importe máximo del residuo ',
        _FIRST_SIR_ADVANCE: 'Primer avance señor ',
        _AGGREGATE_CAP_INDICATOR: 'Indicador de tope agregado ',
        _EXCESS_AGGREGATE_POLICY_VALUE: 'Exceso del valor total de la póliza ',
        _AGGREGATE_CAP_AMOUNT: 'Importe máximo total ',
        _EXCESS_AGGREGATE_AMOUNT: 'Importe global excedente ',
        _EXCESS_AGGREGATE_ADVANCE: 'Exceso de anticipo agregado ',
        _EXCESS_AGGREGATE_RESIDUE: 'Exceso de residuos agregados ',
        _EXCESS_AGGREGATE_LIQUIDATOR_ADVANCE: 'Exceso del anticipo del liquidador agregado ',
        _AGGREGATE_CAP_AMOUNT_RESIDUE: 'Importe total del tope de residuos ',
        _EXCESS_AGGREGATE_AMOUNT_RESIDUE: 'Exceso de importe agregado residuo ',
        _SECOND_SIR_POLICY_VALUES: 'Valores de la póliza del segundo señor ',
        _EXCESS_SECOND_SIR: 'Exceso de segundo señor ',
        _CLAIM_LIQUIDATOR_ADVANCE_SECOND_SIR: 'Reclamación liquidador anticipo segundo señor',
        _SECOND_SIR_RESIDUE: 'Segundo señor residuo ',
        _CLAIM_DATA: 'Datos Siniestro ',
        _NOTIFY_DATE: 'Notificar fecha ',
        _SEVERITY_CODE: 'Código de gravedad ',
        _PHISICAL_INJURY_CODE: 'Código de lesión física',
        _CASHED: 'Cobrada',
        _COLLECTED: 'Recopilado',
        _FISCAL_TAX_REGIME: 'Régimen fiscal',
        _GENERATE_PAYMENT: 'Generar pago',
        _INDEMNITY_COST: 'Coste de indemnización',
        _LEGAL_CODE: 'Código jurídico',
        _NEW_INVOICE: 'Nueva factura',
        _PARTY_CODE: 'Código de empresa',
        _PRESENCE_LAWYERS: 'Presencia legal del oponente',
        _PROTOCOLLO_SAP: 'Protocolo SAP',
        _PROTOCOLLO_SDI: 'Protocolo SDI',
        _RECEIPTS: 'Recibos',
        _RESISTANCE_COST: 'Gastos de resistencia',
        _SETTLEMENT_COST: 'Importe Reserva para gastos de liquidación',
        _SETTLEMENTS: 'Asentamientos',
        _TOTAL_AMOUNT: 'Monto total',
        _PAYMENTS_FIND: 'Buscar pago',
        _OPENING_DUE_TO_WRIT_OF_SUMMONS: 'Apertura por citación judicial',
        _APPLY_PERCENTAGE_LIABILITY_FORCING: 'Aplicar el porcentaje de responsabilidad (Derecho civil)',
        _DAMAGE_CARD: 'Tarjeta de daño',
        _SEND_TO: 'Enviar a',
        _CURRENT_RESERVE: 'Reserva actual',
        _NOTES: 'Notas',
        _COMPENSATIONS: 'Compensaciones',
        _MAGIC_STRING: 'Cadena mágica',
        _INVOLVED_PARTIES__DAMAGE_CAUSE: 'Partes implicadas/Causa del daño',
        _SETTLEMENT_COUNTERS: 'Contadores de liquidación',
        _TYPE_OF_LOSS_COUNTERS: 'Tipo de contador de siniestros',
        _TRUSTEE_DATA: 'Datos fiduciarios',
        _SUBJECT: 'Asunto',
        _ROSTER_REGISTRATION_CODE: 'Código de inscripción en la lista',
        _COURT_REGISTRATION_DATE: 'Fecha de registro en el tribunal',
        _TAX_OFFICE: 'Agencia Tributaria',
        _ASSOCIATED_TRUSTEES: 'Administradores asociados',
        _SUBJECT_IDENTITY: 'Identidad temática',
        _TYPE_PROVIDER: 'Proveedor de tipo',
        _OFFICE_HEADQUARTERS: 'Sede de la Oficina',
        _COUNTRY: 'País',
        _SEARCH_FILTERS: 'Filtros de búsqueda',
        _VAT_NUMBER: 'Número de cuba',
        _EXPERT_TYPE_PROVIDER: 'Proveedor de tipo experto',
        _DATE_OF_INSERTION: 'Fecha de inserción',
        _CANCELED: 'CERRADO',
        _UPDATE_TYPE: 'Actualizar tipo',
        _DATE: 'Fecha de siniestro',
        _TYPOLOGY: 'Tipología',
        _CREATION_DATE: 'Fecha de creación',
        _GROUP: 'Grupo',
        _BY_WORKLOAD: 'Por carga de trabajo',
        _USERS_ASSIGNABLE_TASKS: 'Tareas asignables a los usuarios',
        _ORIGIN: 'Origen',
        _LAST_UPDATE: 'Última actualización',
        _CANCELED_POLICY: 'Póliza cancelada',
        _EMISSION_DATE: 'Fecha de emisión',
        _POLICY_REPLACED_NUMBER: 'Número de póliza sustituida',
        _POLICY_REPLACEMENT_NUMBER: 'Número de sustitución de la póliza',
        _SUSPENSION_DATE_FROM: 'Fecha de suspensión desde',
        _SUSPENSION_DATE_TO: 'Fecha de suspensión hasta',
        _PREMIUM_DATE_FROM: 'Fecha de prima a partir de',
        _PREMIUM_DATE_TO: 'Fecha de prima hasta',
        _OUT_OF_RANGE: 'Fuera de rango',
        _DIARIA_DAYS: 'Días de diario',
        _ARREARS_DAYS: 'Días de retraso',
        _PRODUCT_CODE: 'Código del producto',
        _PRODUCT_DESCRIPTION: 'Descripción del producto',
        _DIARIA: 'Diaria',
        _LOCATION: 'Ubicación',
        _TERM_CODE: 'Código del plazo',
        _DAYS: 'Días',
        _SELECTABLE: 'Seleccionable',
        _POSTAL_CODE: 'Código postal',
        _POLICY_CONDITION: 'Condición de la póliza',
        _POLICY_CEILING: 'Límite máximo de la políza',
        _MINIMUM_UNCOVERED_VALUE: 'Valor mínimo descubierto',
        _SEARCH_RESULTS: 'Resultados de la búsqueda',
        _UPDATE_DATE: 'Fecha de actualización',
        _EXCESS: 'Exceso',
        _BRANCH: 'Sucursal',
        _SURNAME__BUSINESS_NAME: 'Apellidos/Nombre comercial',
        _PERSON_TYPE: 'Tipo de persona',
        _LEGAL_PERSON: 'Persona jurídica',
        _EDIT_SUBJECT: 'Editar tema',
        _UPDATE_RESERVE: 'Reserva de actualización',
        _REOPENING_DATA: 'Datos de Reapertura',
        _REOPENING_REASON: 'Motivo de Reapertura',
        _SETTLEMENT_COSTS: 'Gastos de liquidación',
        _VIEW_FOR_ACTORS: 'Vista para actores',
        _NEW_MANUAL_DEADLINE: 'Nueva actividad manual',
        _QUOTA_AMOUNT: 'Importe de cuota',
        _AMOUNT_ACTIVE: 'Activo importe',
        _AMOUNT_RESERVE_QUOTA: 'Cuota de reserva activa',
        _OPENING_DATE: 'Fecha de apertura',
        _CLOSING_DATE: 'Fecha de cierre',
        _REFUSED_DATE: 'Fecha rechazada',
        _COMPENSATION_DIARY_N: 'Diario de Compensación N.',
        _NEW_NOTE: 'Nueva nota',
        _EXTRA_INFO: 'Información adicional',
        _NO_DATA: 'Ningún dato',
        _CONV_TYPE: 'Tipo de convenio',
        _INVALID_PARAMETER_FORM: 'Forma de parámetro no válida',
        _NO_PARTIES: 'Ningún sujeto presente',
        _SELECT_ACTOR_ROLE: 'Seleccione el papel del actor',
        _NEW_ACTOR_LIST: 'Lista de actores',
        _ASSETS_LIST: 'Lista de activos',
        _DAMAGED_ASSET: 'Activo dañado',
        _ADD_OTHER_ASSET: 'Añadir nuevos daños',
        _ACTOR_ROLE: 'Papel de actor',
        _SOLD: 'Vendido',
        _RISK_FACTORS: 'Factores de riesgo',
        _THING: 'Cosa',
        _VEHICLE_ASSET: 'Activo del vehículo',
        _EVENT_RISK: 'Riesgo de sucesos',
        _EVENT: 'Evento',
        _NO_REASON_MEETS_THE_SEARCH_CRITERIA: 'Ninguna razón cumple los criterios de búsqueda',
        _RESIDENCE: 'Residencia',
        _DOMICILE: 'Domicilio',
        _ADD_NEW_ADDRESS: 'Añadir una nueva dirección',
        _DIALOGUE_DESCRIPTION: 'Concepto',
        _INSURED_PLATE_NUMBER: 'Matrícula asegurado',
        _COMPANY_CODE: 'Código entidad',
        _INVOLVED_PARTY_IDENTIFIER: 'Identificador lesionado',
        _INVOLVED_PARTY_KEY: 'NIF',
        _NUMBER_REQUEST: 'Número Petición',
        _RESPONSE_IN: 'Mensaje recibido',
        _REQUEST_OUT: 'Mensaje enviado',
        _REQUEST_IN: 'Mensaje recibido',
        _RESPONSE_OUT: 'Mensaje enviado',
        _RESPONSE_INPUT: 'Mensaje recibido',
        _REQUEST_INPUT: 'Mensaje recibido',
        _REQUEST_OUTPUT: 'Mensaje enviado',
        _RESPONSE_OUTPUT: 'Mensaje enviado',
        _SIMILAR_DIALOGUES: 'Diálogos similares',
        _CANCEL_MESSAGE: 'Cerrado con éxito!',
        _CF_PIVA: 'CF/PIVA',
        _LOSS_DATE: 'Fecha Ocurrencia',
        _VERSION: 'Versión',
        _CODE_MESSAGE: 'Código del mensaje',
        _PAYMENT_DELETE_CHECK_QUESTION: '¿Está seguro de que desea eliminar el siguiente elemento de la tabla?',
        _ERROR_DURING_SAVING: 'Error al guardar!',
        _NOT_CORRECT_ENTRY_DATA: 'Los datos introducidos no son correctos!',
        _WRONG_DATES: 'Las fechas introducidas no son válidas!',
        _MANDATORY_FUNCTION_CODE: 'Código de función obligatorio!',
        _INCORRECT_AMOUNT: 'El importe introducido no es válido',
        _ALREADY_EXISTS_RECORD: "Existe un Forfait en el mismo período de tiempo indicado",
        _NEW_FORFAIT: 'Nuevo forfait',
        _PAYMENTS: {
            _IDRECORDFLOW: 'Id record flujo',
            _CODFUNCTION: 'Convenio',
            _CODE_COMPANY: 'Entidad',
            _LOTTO: 'Lote',
            _SEQUENCE: 'Secuencia',
            _REGISTERTYPE: 'Tipo de registro',
            _CODMESSAGE: 'Mensaje',
            _SENDINGFLOWDATE: 'Fecha recepción',
            _YEAR: 'Año',
            _MONTH: 'Mes',
            _OPERATIONCENTRE: 'Centro de Operaciones',
            _OPERATIONCENTRECNT: 'Centro de Operaciones contraparte',
            _COMPANYCODEUS: 'Código de empresa',
            _COMPANYCODECNT: 'Entidad contraria',
            _INTERNALKEY: 'Referencia',
            _EXTERNALKEY: 'Referencia contraria',
            _LOSSDATE: 'Fecha ocurrencia',
            _ROLE: 'Posición',
            _ERRORCODE: 'Código de error',
            _ERROR: 'Error',
            _INTERNALKEYDLG: 'Clave interna de diálogo',
            _ROLEDLG: 'Posición diálogo',
            _PAVEEDLG: 'Parte implicada - Tipo de siniestro',
            _CLAIMNUMBERDLG: 'Número de diálogo izquierdo',
            _COMPANYCTRDLG: 'Diálogo empresa contraparte',
            _LOSSDATEDLG: 'Fecha del diálogo de pérdida',
            _PLATECTRDLG: 'Placa homóloga de diálogo',
            _SETTLEMENTNUMBER: 'Número de liquidación',
            _PLATEDLG: 'Placa de diálogo',
            _IMPORTFORFAIT: 'Importe',
            _PAYMENTTYPE: 'Tipo de pago',
            _FORCED: 'Forzar',
            _SKIPPED: 'Saltar',
            _DINS: 'Fecha de inserción',
            _UNIQUECODE: 'Código Tirea',
            _SETTLEMENTDATE: 'Fecha liquidación',
            _PAYMENTCOLLECTION: 'Pago o recobro',
            _PAY: 'Pago',
            _RECOVER: 'Recobro',
            _TOTALRECORD: 'Total Registros',
            _TOTALRECORDKO: 'Total Registros KO',
            _TOTALRECORDOK: 'Total Registros OK',
            _TOTALAMOUNTPAYMENTS: 'Total Importe - Pagos',
            _TOTALAMOUNTRECOVERED: 'Total Importe - Recobros'
        },
        _TYPE_LICENSE_PLATE_NUMBER_US: 'Tipo matrícula asegurado',
        _TYPE_LICENSE_PLATE_NUMBER_CTP: 'Tipo matrícula contrario',
        _LST_MES_SEND_DATA: 'Fecha envío respuesta',
        _NO_DELEGATION: 'Ninguno',
        _OUR_DELEGATION: 'Nuestra delegación',
        _THIRD_PARTY_DELEGATION: 'Delegación de terceros'
    }
};

