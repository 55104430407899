<lpc-stepper [formGroup]="formGroup" (stepSlide)="onSlide($event)" [isPublished]="publishMessage !== null">
  <lpc-step id="dates" label="lpc_date_variation_step" [errors]="errors" fieldId="dates" (next)="onNext($event)">
    <lpc-dates-step [inputFields]="dateDefinitions" [formControlName]="'dates'" (dateChange)="onDateChange($event)"
      (reloadRequest)="updateData()" [hide]="activeStep<1">
    </lpc-dates-step>
  </lpc-step>

  <!-- CONVENZIONI -->
  <lpc-step id="conventions" label="lpc_variation_convention" [errors]="errors" fieldId="conventions" (next)="onNext($event)">
    <div class="row" *ngIf="!!agreements && !!agreements.length">
      <div class="col-lg-6" *ngIf="isActiveStep('conventions')">
        <rgi-rx-form-field>
          <label rgiRxLabel>
            <span>{{'lpc_agreement' | translate}}</span>
            <lpc-required required="true"></lpc-required>
          </label>
          <select rgiRxNativeSelect
                [formControlName]="'agreement'"
                [attr.data-qa]="'agreement-selection'">
          <option *ngFor="let option of agreements" [value]="option.code">{{ option.description }}</option>
        </select>
        </rgi-rx-form-field>
      </div>
      <div class="col-lg-6" *ngIf="isAfterId('conventions')">
        <span for="label" translate>lpc_agreement</span>
        <span for="value">: {{ agreementSelected }}</span>
      </div>
    </div>
  </lpc-step>

  <lpc-step *ngIf="isAuth || !!errors && !!getFilteredErrors().length" id="warnings" label="lpc_warnings"
            (next)="publish()" [nextLabel]="isAuth ? 'AUTHORIZATION REQUEST' : 'Confirm'"
            (confirmAccept)="publish(isConfirmAndAccept)" [isConfirmAndAccept]="isConfirmAndAccept">
    <lpc-warning-messages *ngIf="!!errors" [errors]="errors"></lpc-warning-messages>
  </lpc-step>

  <lpc-step
    label="lpc_confirm_variation_step"
    [errors]="errors"
    fieldId="documents"
    id="documents"
    [nextVisible]=false>

    <lpc-document-wrapper
      *ngIf="!!publishMessage"
      [contractId]="getContractId"
      [operationCode]="getOperationCode"
      [publishMessage]="publishMessage"
      [isAuth]="isAuth"
      [authorizationId]="authorizationId"
      [printMode]="printMode"
      [config]="documentsConfig"
      (documentEvent)="handleDocumentEvent($event)">
    </lpc-document-wrapper>

    <lpc-navigation-buttons
      id="last-step-buttons"
      (back)="closeCardWithoutModal()"
      [backLabel]="'lpc_close_button'"
      [backVisible]="true"
      [nextVisible]="false">
    </lpc-navigation-buttons>

  </lpc-step>
</lpc-stepper>

<div class="btn-group btn-group-justified external" *ngIf="!isActiveStep('documents')">
  <lpc-navigation-buttons (back)="closeCard()" [nextVisible]="false" [qa]="'annulla'"></lpc-navigation-buttons>
</div>
