import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable, combineLatest, forkJoin, of } from 'rxjs';
import { map, mergeMap, switchMap } from 'rxjs/operators';
import { restEndPoint } from '../configurations/payments-dashboard.endpoint';
import { PaymentsDashboardIntegrationService } from '../configurations/payments-dashboard-integration.service';
import { ActiveRoute } from '@rgi/rx/router';
import { WrapperDialogueFunction } from '../models/wrapper-dialogue-function.model';
import { WrapperDialogueCompany } from '../models/wrapper-dialogue-company.model';
import { SearchResponse } from '../models/payments/list-payment-response.model';
import { Searchboard } from '../states/searchboard.state';
import { SearchForfait } from '../models/forfait/search-forfait.model';
import { ListResponseForfait } from '../models/forfait/list-response-forfait.model';
import { NOTHING_FOUND } from '../constants/payments-searchboard.constants';
import { SearchPayments } from '../models/payments/search-payments.model';
import { ValidationConsolidationDataFromRoute } from '../models/validation/validation-consolidation-data-from-route.model';
import { OutputForfait } from '../models/forfait/output-forfait.model';

@Injectable({
  providedIn: "root",
})
export class PaymentsSearchboardService {
  private baseApiUrlV2: string;

  constructor(
    private httpClient: HttpClient,
    @Inject("environment") environment: any,
    private integrationService: PaymentsDashboardIntegrationService
  ) {
    this.baseApiUrlV2 =
      environment.api.portal.host + environment.api.portal.path + "/v2";
  }

  initStateObject(st: Observable<Searchboard>): Observable<Searchboard> {
    return st.pipe(
      switchMap((state) => {
        return forkJoin({
          functionResponse: this.httpClient.get<WrapperDialogueFunction>(this.baseApiUrlV2 + restEndPoint.fillInSearchFunMes),
          companyResponse: this.httpClient.get<WrapperDialogueCompany>(this.baseApiUrlV2 + restEndPoint.fillInSearchCompany),
        }).pipe(
          map((apiResponses) => {
            state.searchWrap.dialogueFunctionResponse = apiResponses.functionResponse.response;
            state.searchWrap.dialogueCompResponse = apiResponses.companyResponse;
            return state;
          })
        );
      })
    );
  }

  searchPayments(st: Observable<Searchboard>, searchPaymentsInputDTO: SearchPayments, activeRouteData: ActiveRoute) {
    return st.pipe(
      mergeMap((state) => {
        const responseList: Observable<SearchResponse> = this.httpClient.post<SearchResponse>(this.baseApiUrlV2 + restEndPoint.searchPayments, searchPaymentsInputDTO);
        return combineLatest([responseList, of(state)]);
      }),
      mergeMap(([responseList, state]) => {
        if (!!responseList && !!responseList.response && responseList.response.length >= 1) {
          const dataFromRoute: ValidationConsolidationDataFromRoute = {
            searchPaymentsInputDTO: searchPaymentsInputDTO,
            searchPaymentsOutputDTO: responseList.response
          }

          this.integrationService.goToCardList(dataFromRoute, activeRouteData)
          return of(state)
        }

        state.errorMessage = NOTHING_FOUND;
      }),
    )
  }

  searchForfait(st: Observable<Searchboard>, searchForfait: SearchForfait, activeRouteData: ActiveRoute) {
    return st.pipe(
      mergeMap((state: Searchboard) => {
        const responseList = this.httpClient.post<ListResponseForfait>(this.baseApiUrlV2 + restEndPoint.searchForfait, searchForfait);
        return combineLatest([responseList, of(state)]);
      }),
      mergeMap(([responseList, state]) => {
        if (!!responseList) {
          const dataFromRoute: OutputForfait[] = responseList.response
          this.integrationService.goToCardForfait(dataFromRoute, activeRouteData);
          return of(state)
        }
        
        state.errorMessage = NOTHING_FOUND;
      }),
    );
  }
}