import { FACTOR_VALUE, FactorCode } from './../../invest-standalone-session/utils/factor-code.enum';
import { AfterViewInit, Component, EventEmitter, forwardRef, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { ControlValueAccessor, UntypedFormArray, UntypedFormControl, UntypedFormGroup, NG_VALUE_ACCESSOR, Validators } from '@angular/forms';
import { TranslationWrapperService } from '../../../i18n/translation-wrapper.service';
import { Subject, Subscription } from 'rxjs';
import { concatMap, distinctUntilChanged, finalize, switchMap, take, tap } from 'rxjs/operators';
import { CardsNavigationService } from '../../cards-navigation/cards-navigation.service';
import { ExtensionProperty, Roles } from '../../enum/life-issue.enum';
import { Contribution, ContributionResp, FormFieldDefinition } from '../../models/pip.model';
import { Factor, Instance, LifeParty, PolicyRole } from '../../models/policy.model';
import { ErrorType } from '../../models/response.model';
import { Subject as sbj } from '../../models/subject.model';
import { ActionsService } from '../../services/actions.service';
import { LifeRoleService } from '../../services/life-role.service';
import { LifeSessionService } from '../../services/life-session-service';
import { PipService } from '../../services/pip.service';
import { PolicyService } from '../../services/policy-service';
import { ValidationSubjectsService } from '../../services/validation-subjects.service';
import { LicDateUtils } from '../../utils/lic-date-utils';
import { LicErrorsUtils } from '../../utils/lic-errors-utils';
import { LicPipUtils } from '../../utils/lic-pip-utils';
import { RgiCtryLayerSymbolFormatterPipe } from '@rgi/rgi-country-layer';
import { RgiCtryLayerNumFormatterPipe } from '@rgi/rgi-country-layer';

@Component({
    selector: 'lic-pip-data',
    templateUrl: './pip-data.component.html',
    styleUrls: ['./pip-data.component.scss'],
    encapsulation: ViewEncapsulation.None,
    providers: [
        {
          provide: NG_VALUE_ACCESSOR,
          useExisting: forwardRef(() => PipDataComponent),
          multi: true
        }, RgiCtryLayerSymbolFormatterPipe, RgiCtryLayerNumFormatterPipe
    ]
})
export class PipDataComponent implements OnInit, ControlValueAccessor, AfterViewInit {

  @Output() navigation = new EventEmitter<string>();
  @Output() eventPropagation = new EventEmitter<any>();

  currencyCode = '€';

  protected readonly EMPLOYER_CONTROL = 'datoreLavoro';
  protected readonly TRANSFER_DATA_CONTROL = 'transferData';
  protected readonly CONTRIBUTION_DATA_CONTROL = 'contributionData';
  protected readonly SWITCH_AMOUNT_CONTROL = 'switchAmount';

  get frequency(): string {
      return this.policyService.mainProposal.proposal.policyVersion.fractionation.code;
  }

  private $subscriptions: Subscription[] = [];
  insuredInstance: Instance;
  policyFactors: Factor[] = [];
  goodsFactors: Factor[] = [];
  pipFactors: Factor[] = [];
  warningMessages: string[] = [];
  messageFromPreviousPage: {type: string, message: string}[] = [];
  transferData: FormFieldDefinition[] = [];
  isPercent = false;
  public showSavedProposalNumber = false;
  public proposalNumber: string;
  messages = [];
  pensionCompany: any;
  showContributions = false;
  private _cachedData: Subject<any>;

  public form: UntypedFormGroup = new UntypedFormGroup({
      switchAmount: new UntypedFormControl(),
      contributionData: new UntypedFormArray([])
  });

  public contributionData: ContributionResp;

  get getValidationMessages(): string[] {
      return this.messages;
    }

  get getWarningMessages(): string[] {
      return this.warningMessages.concat(LicErrorsUtils.getPreviousPageMessages(this.messageFromPreviousPage, ErrorType.WARNING)
      .map(msg => msg.message));
  }

  get getBlockingMessages(): string[] {
      return LicErrorsUtils.getPreviousPageMessages(this.messageFromPreviousPage, ErrorType.ERROR)
      .map(msg => msg.message);
  }

  get getAuthMessages(): string[] {
      return LicErrorsUtils.getPreviousPageMessages(this.messageFromPreviousPage, ErrorType.AUTH)
      .map(msg => msg.message);
  }

  public get isTransferData(): boolean {
      return !!this.policyFactors.find(
        (factor) => factor.code === FactorCode.TRANSFER_FROM_ANOTHER_SUPPLEMENTARY_PENSION_SCHEME &&
          factor.value === FACTOR_VALUE._TPECO.SELF_EMPLOYED
      );
  }

  public get onlyVoluntaryContribution(): boolean  {
      return !!this.goodsFactors.find(
        (factor) => factor.code === FactorCode.ADHERENT_ACTIVITY && this.getTranscoderCode(factor) === FACTOR_VALUE._TPECO.SELF_EMPLOYED
      )
      && !!this.policyFactors.find((factor) => factor.code === FactorCode.PERIODIC_CONTRIBUTION_FROM_THE_EMPLOYER && factor.value === '1');
  }

  constructor(
      protected policyService: PolicyService,
      protected cardsNavigationService: CardsNavigationService,
      protected translateService: TranslationWrapperService,
      protected actionService: ActionsService,
      protected lifeRoleService: LifeRoleService,
      protected lifeSessionService: LifeSessionService,
      protected validationService: ValidationSubjectsService,
      protected pipService: PipService,
      protected currencySymbolPipe: RgiCtryLayerSymbolFormatterPipe) {
        this.currencyCode = !!this.policyService.currencyCode ?
        currencySymbolPipe.transform(this.policyService.currencyCode) : this.currencyCode;
  }

  ngOnInit(): void {
      this.messageFromPreviousPage =  this.cardsNavigationService.getPreviousMessages(this.getErrorStep());
      this.goodsFactors = this.policyService.mainProposal.quote.product.assets[0].instances[0].factors as Factor[];
      this.policyFactors = this.policyService.mainProposal.quote.product.factors as Factor[];
      this.pipFactors = this.getPipFactors(this.goodsFactors).concat(this.getPipFactors(this.policyFactors));
      if (this.policyService.contribJson == null) {
          const mainProposal = this.policyService.mainProposal;
          this.$subscriptions.push(
              this.pipService
              .getContributionData(
                  mainProposal.quote.resourceId,
                  mainProposal.quote.product.code,
                  mainProposal.proposal.policyVersion.effectiveDate,
                  `${this.onlyVoluntaryContribution}`)
              .subscribe(resp => {
                  this.contributionData = resp;
                  this.createContributionDataForm(resp);
                  this.showContributions = true;
              })
          );
      }
  }

  protected getErrorStep(): number {
      return this.cardsNavigationService.STEP.PIP.pos;
  }

  ngAfterViewInit() {
      // preval contribuzioni
      if (!!this.policyService.contribJson) {
          this.contributionData = this.policyService.contribJson;
          this.createContributionDataForm(this.contributionData);
          this.showContributions = true;
      }
      // preval trasferimenti
      if (
        this.form.get(FactorCode.TRANSFER_FROM_ANOTHER_SUPPLEMENTARY_PENSION_SCHEME).value === FACTOR_VALUE._TPECO.SELF_EMPLOYED &&
        !!this.policyService.transferJson) {
          this.transferData = this.policyService.transferJson;
          const companyRole = this.getSavedRole(Roles.COMPANY_PENSION_FORM);
          const companyId = companyRole && companyRole.objectId;
          this.createTransferDataForm();
          if (!!companyId) {
              this.eventPropagation.emit('startLoader');
              this.$subscriptions.push(
                this.lifeRoleService.getSubjectFromAnag(companyId, this.lifeSessionService.idPv).subscribe(resp => {
                    this.pensionCompany = resp.subject;
                    this.setPartyOnPolicyLifeParty(resp.subject, Roles.COMPANY_PENSION_FORM);
                    this.eventPropagation.emit('stopLoader');
                })
              );
          }
      }
      // preval datore di lavoro
      if (this.showDatoreDiLavoro() && this.getSavedEmpoyer() != null ) {
          this.eventPropagation.emit('startLoader');
          this.lifeRoleService.getSubjectFromAnag(this.getSavedRole(Roles.EMPLOYER).objectId,
          this.lifeSessionService.idPv).subscribe(resp => {
              this.form.get(this.EMPLOYER_CONTROL).get('val').setValue(resp.subject);
              this.setPartyOnPolicyLifeParty(resp.subject, Roles.EMPLOYER);
              this.eventPropagation.emit('stopLoader');
          });
      }
  }

  getSavedRole(role: Roles): PolicyRole {
      return this.lifeRoleService.getPolicyByRole(role);
  }

  changeSwitch(e: Event) {
      e.stopPropagation();
      this.isPercent = !this.isPercent;
      this.form.get(this.SWITCH_AMOUNT_CONTROL).setValue(this.isPercent);
  }

  getTranscoderCode(factor: Factor): string {
      if (!!factor.values.find(factorValue => factorValue.value === factor.value)) {
          return factor.values.find(factorValue => factorValue.value === factor.value).transcoderCode1;
      }
  }

  createContributionDataForm(contributionResponse: ContributionResp) {
      this.form.get(this.SWITCH_AMOUNT_CONTROL).setValue(!!+contributionResponse.switchValue.value);
      // remove previous form controls
      while ((this.form.get(this.CONTRIBUTION_DATA_CONTROL) as UntypedFormArray).length !== 0) {
          (this.form.get(this.CONTRIBUTION_DATA_CONTROL) as UntypedFormArray).removeAt(0);
      }
      contributionResponse.contributions.map(el => {
          (this.form.get(this.CONTRIBUTION_DATA_CONTROL) as UntypedFormArray).push(new UntypedFormGroup({
              check: new UntypedFormControl(JSON.parse(el.check.value)),
              frequency: new UntypedFormControl(el.frequency.value),
              amount: new UntypedFormControl(el.value.value === '0' ? null : el.value.value)
          }));
      });
  }

  onChange(obj: any) {
  }

  registerOnChange(fn: any): void {
      this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
  }

  setDisabledState(isDisabled: boolean): void {
  }

  writeValue(obj: any): void {
      if (!!obj) {
          this.form.patchValue(obj, {emitEvent: false});
      }
  }

  updateFactors(event: CustomEvent, doSubscribe?: boolean) {
    this.sendToRequestFactorsVal(this.policyFactors, true);
    this.sendToRequestFactorsVal(this.goodsFactors, false);
    if (doSubscribe) {
        this.eventPropagation.emit('startLoader');
        this.$subscriptions.push(
          this.policyService.saveProposal().subscribe(
              (response: any) => {
                  this.manageSavePropResp(event.detail);
                  this.eventPropagation.emit('stopLoader');
              }, err => {
                  this.eventPropagation.emit('stopLoader');
              }
          )
        );
    } else {
        return this.policyService.saveProposal();
    }
  }

  getSavedEmpoyer() {
      return this.lifeRoleService.getPolicyByRole(Roles.EMPLOYER);
  }

  doDisableFields(j: number, contr?: Contribution, type?: string): boolean {
      const contributionDataControl = this.form.get(this.CONTRIBUTION_DATA_CONTROL).get(j.toString());
      if (!!contr && contr.check.code === 'ContributoVol' && type === 'frequency') {
          if (contributionDataControl.get('check').value) {
              contributionDataControl.get('frequency').setValue(this.frequency);
          } else {
              contributionDataControl.get('frequency').setValue(null);
          }
          return true;
      }
      if (!!this.form.get(this.CONTRIBUTION_DATA_CONTROL) && !!contributionDataControl) {
          return !contributionDataControl.get('check').value;
      }
  }

  doMandatoryFields(j) {
      if (!!this.form.get(this.CONTRIBUTION_DATA_CONTROL) && !!this.form.get(this.CONTRIBUTION_DATA_CONTROL).get(j.toString())) {
          return this.form.get(this.CONTRIBUTION_DATA_CONTROL).get(j.toString()).get('check').value;
      }
  }

  manageSavePropResp(code?: string) {
      this.policyFactors = this.policyService.mainProposal.quote.product.factors;
      this.goodsFactors = this.policyService.mainProposal.quote.product.assets[0].instances[0].factors;

      this.pipFactors = this.getPipFactors(this.goodsFactors).concat(this.getPipFactors(this.policyFactors));

      this.updateFormValue();
      this.onTransferValueChanges();
      if (code === FactorCode.PERIODIC_CONTRIBUTION_FROM_THE_EMPLOYER || code === FactorCode.ADHERENT_ACTIVITY) {
          this.onPeriodicContributionValueChange();
      }
  }

  createTransferDataForm() {
      this.form.addControl(this.TRANSFER_DATA_CONTROL, new UntypedFormGroup({}));
      this.transferData.forEach(factor => {
          const formControl: UntypedFormControl = new UntypedFormControl(factor.value, factor.mandatory ? [Validators.required] : []);
          (this.form.get(this.TRANSFER_DATA_CONTROL) as UntypedFormGroup).addControl(
          factor.code,
          formControl
          );
      });
  }

  resetPensionCompany(event) {
      this.policyService.mainProposal.proposal.lifeParty = this.policyService.mainProposal.proposal.lifeParty
      .filter(obj => obj.party.partyRole !== Roles.COMPANY_PENSION_FORM);
      this.lifeRoleService.setPolicyByRole(null, Roles.COMPANY_PENSION_FORM);
  }

  onPeriodicContributionValueChange() {
      const mainProposal = this.policyService.mainProposal;
      this.pipService.getContributionData(
          mainProposal.quote.resourceId,
          mainProposal.quote.product.code,
          mainProposal.proposal.policyVersion.effectiveDate,
          `${this.onlyVoluntaryContribution}`)
      .subscribe(contributions => {
          console.log(contributions, 'contributions');
          this.contributionData = contributions;
          this.createContributionDataForm(contributions);
      });
  }

  onTransferValueChanges() {
    this.$subscriptions.push(
      this.form.get(FactorCode.TRANSFER_FROM_ANOTHER_SUPPLEMENTARY_PENSION_SCHEME).valueChanges.subscribe(value => {
          if (value === FACTOR_VALUE._TPECO.SELF_EMPLOYED) {
              if (!this._cachedData) {
                  this._cachedData = new Subject();
                  this.pipService.getTransferData(this.policyService.mainProposal.quote.resourceId).subscribe(data => {
                      this._cachedData.next(data.fields);
                      this.transferData = data.fields;
                      this.policyService.saveTransferData(this.transferData);
                      this.createTransferDataForm();
                  });
              } else {
                  this.createTransferDataForm();
              }
          } else {
              this.pensionCompany = null;
              this.resetPensionCompany(null);
              this.form.removeControl(this.TRANSFER_DATA_CONTROL);
          }
      })
    );
  }

  sendToRequestFactorsVal(factors: Factor[], fromPolicy: boolean) {
      factors.forEach(fat => {
          const factorsForm = this.form;
          if (factorsForm != null && factorsForm.get(fat.code) != null) {
              let valFromForm = factorsForm.get(fat.code).value;
              if (valFromForm != null) {
                  if (parseInt(fat.type, 10) === 4 && valFromForm) {
                      valFromForm = LicDateUtils.convertToYMD(valFromForm);
                  } else if (parseInt(fat.type, 10) === 1 && valFromForm.includes(',')) {
                      valFromForm = valFromForm.replace(',', '.');
                  }
              } else if (parseInt(fat.type, 10) === 1) {
                  valFromForm = '-1';
              }
              if (fat.value !== valFromForm) {
                  fat.value = valFromForm;
                  fat.manuallySet = true;
              }
          }
      });
      if (fromPolicy) {
          this.policyService.mainProposal.quote.product.factors = factors;
      } else {
          this.policyService.mainProposal.quote.product.assets[0].instances[0].factors = factors;
      }
  }


  updateFormValue() {
      const codes: string[] = [];
      // Aggiungo i fattori mancanti
      this.pipFactors.map((element => {
          codes.push(element.code);
      }));
      this.removeOldControls(this.form, codes);
  }

  // Rimuove i vecchi controlli
  removeOldControls(form: UntypedFormGroup, codes: string[]) {
      Object.keys(form.controls).forEach((key) => {
          if (!codes.includes(key) &&
          key !== this.EMPLOYER_CONTROL && key !== this.TRANSFER_DATA_CONTROL &&
          key !== this.CONTRIBUTION_DATA_CONTROL && key !== this.SWITCH_AMOUNT_CONTROL) {
              form.removeControl(key);
          }
      });
  }

  getPipFactors(factors: Factor[]): Factor[] {
      const factorsArr: Factor[] = [];
      factors.map(factor => {
          if (LicPipUtils.isPipFactor(factor)) {
              factorsArr.push(factor);
          }
      });
      return factorsArr;
  }

  checkDirty(form: UntypedFormGroup) {
      if (!form.get(this.CONTRIBUTION_DATA_CONTROL).valid) {
          this.messages.push(this.translateService.getImmediate('lic_Selectcontributionsmandatoryfields'));
      }
      Object.keys(form.controls).forEach(field => {
        if (!form.get(field).valid) {
          form.get(field).markAsDirty();
        }
      });
  }

  findEmployer() {
      return this.policyFactors.find(factor => factor.code === FactorCode.PERIODIC_CONTRIBUTION_FROM_THE_EMPLOYER && factor.value === '1' );
  }

  showDatoreDiLavoro(): boolean {
      const empl = this.findEmployer();
      if (!!empl) {
          this.form.addControl(this.EMPLOYER_CONTROL, new UntypedFormGroup({
              val: new UntypedFormControl(null)
            }));
          return !!empl;
      } else {
          this.policyService.mainProposal.proposal.lifeParty = this.policyService.mainProposal.proposal.lifeParty
          .filter(obj => obj.party.partyRole !== Roles.EMPLOYER);
          this.lifeRoleService.setPolicyByRole(null, Roles.EMPLOYER);
          this.form.removeControl(this.EMPLOYER_CONTROL);
      }
  }

  resetEmployer(event) {
      this.form.get(this.EMPLOYER_CONTROL).get('val').setValue(null);
      this.lifeRoleService.setPolicyByRole(null, Roles.EMPLOYER);
      this.policyService.mainProposal.proposal.lifeParty = this.policyService.mainProposal.proposal.lifeParty
      .filter(obj => obj.party.partyRole !== Roles.EMPLOYER);
  }

  openAnag(event: any) {
      this.eventPropagation.emit(event);
      this.$subscriptions.push(
        this.lifeRoleService.getObservable().pipe(distinctUntilChanged(), take(1)).subscribe(value => {
          if (value != null) {
            this.handleSubjectReceved(value);
          }
        })
      );
    }

    onEmit(event: any) {
        this.eventPropagation.emit(event);
    }

    handlePensionCompany(event) {
      this.validationService.removeMessage('company_pension');
      const policyRoleEmployer = new PolicyRole(Roles.COMPANY_PENSION_FORM, event);
      this.$subscriptions.push(
        this.validationService.checkRole(policyRoleEmployer, 0, [], this.policyService.isFromPreventive).subscribe(
            (response) => {
                response.errorMessages.map((message) =>
                  this.messages = this.validationService.addMessage('company_pension', message.description)
                );
                if (response.errorMessages.length === 0) {
                  this.setPartyOnPolicyLifeParty(event, Roles.COMPANY_PENSION_FORM);
                }
            }
        )
      );
    }

    handleSubjectReceved(value: sbj) {
      this.validationService.removeMessage('lic_Employer');
      const policyRoleEmployer = new PolicyRole(Roles.EMPLOYER, value);
      this.validationService.checkRole(policyRoleEmployer, 0, [], this.policyService.isFromPreventive).subscribe(
          (response) => {
              console.log(response);
              response.errorMessages.map((message) =>
                this.messages = this.validationService.addMessage('lic_Employer', message.description)
              );
              if (response.errorMessages.length === 0) {
                  this.form.get(this.EMPLOYER_CONTROL).get('val').setValue(value);
                  this.setPartyOnPolicyLifeParty(value, Roles.EMPLOYER);
              }
          }
        );
    }

protected setPartyOnPolicyLifeParty(value: sbj, role: Roles) {
  const party = this.lifeRoleService.subjectToLifeParty(value, role) as LifeParty;
  this.policyService.mainProposal.proposal.lifeParty = this.policyService.mainProposal.proposal.lifeParty
    .filter(p => p.party.partyRole !== role);
  this.policyService.mainProposal.proposal.lifeParty.push(party);
  this.lifeRoleService.setPolicyByRole(value, role);
}

  // controllo se ci sono fattori di bene da esporre
  checkFattoriBene(): boolean {
      let flag = false;
      for (const inst of this.policyService.mainProposal.quote.product.assets[0].instances) {
          if (inst.factors.length !== 0) {
              flag = true;
              break;
          }
      }
      return flag;
  }

  validateContributions() {
      const contributionFormArray = this.form.get(this.CONTRIBUTION_DATA_CONTROL).value;
      // obbligatorietà di almeno una contribuzione
      if (contributionFormArray.every(contr => !contr.check)) {
          this.messages.push(this.translateService.getImmediate('lic_Selectatleastonecontribution'));
      }
      // le frequency delle contribuzioni devono essere uguali tra loro
      const [, ...rest] = contributionFormArray;
      const frequencies = rest.map(contr => {
          return contr.frequency;
      })
      .filter(freq => freq != null);
      if (frequencies.some( (val, i, arr) => val != null && val !== arr[0])) {
          this.messages.push(this.translateService.getImmediate('lic_contribution_frequency', {
            frequency: this.getSelectedContributions()
          }));
      }
      // percentuali
      if (!!this.form.value && !!this.form.value.contributionData) {
          const selectedContributions = contributionFormArray.filter(contr => contr.check);
          let amount: number;
          let bAmountMessage = false;
          if (!!selectedContributions.length) {
              amount = selectedContributions
              .map(contr => {
                  if (!contr.amount && !bAmountMessage) {
                      bAmountMessage = true;
                      this.messages.push(this.translateService.getImmediate('lic_Everycontributionselectedmustbemajorthan0'));
                  }
                  return Number(contr.amount);
              })
              .reduce((previous, current) => previous + current);
          }
          if (this.form.value.switchAmount && 100 !== amount) {
              this.messages.push(this.translateService.getImmediate('lic_Globalpercentagemustbe100'));
          } else if (!this.form.value.switchAmount && (!amount || 0 === amount)) {
              this.messages.push(this.translateService.getImmediate('lic_Globalamountmustbemajorthan0'));
          }
      }
  }

  onSubmit(event: any ) {
      this.messages = [];
      this.checkDirty(this.form);
      this.validateContributions();
      if (this.findEmployer() != null && this.form.get(this.EMPLOYER_CONTROL).get('val').value == null) {
          this.messages.push(this.translateService.getImmediate('lic_add_employer'));
      }
      if (!this.messages.length) {
          if (!!this.form.valid) {
              const reqSavePipTransf = this.createSavePipTransferReq();
              this.eventPropagation.emit('startLoader');
              this.savePipInfo(reqSavePipTransf);
          } else {
              this.messages.push(this.translateService.getImmediate('lic_error_mandatory_fields'));
          }
      }
  }


  onSave(modal = {}) {
      this.messages = [];
      this.checkDirty(this.form);
      this.validateContributions();
      if (this.findEmployer() != null && this.form.get(this.EMPLOYER_CONTROL).get('val').value == null) {
          this.messages.push(this.translateService.getImmediate('lic_add_employer'));
      }
      if (!this.messages.length) {
        if (!!this.form.valid) {
            const reqSavePipTransf = this.createSavePipTransferReq();
            this.eventPropagation.emit('startLoader');
            this.savePipInfo(reqSavePipTransf, true, modal);
        } else {
            this.messages.push(this.translateService.getImmediate('lic_error_mandatory_fields'));
        }
      }
  }

  public saveUndefinedProposal(modal: any = {}) {

      this.policyService.pushExtensionProperty(ExtensionProperty.INTERMEDIATE_SAVE, 'true');
      this.eventPropagation.emit('startLoader');
      this.$subscriptions.push(
        this.policyService.saveFromQuote(false).pipe(
          tap((response: any) => {
            this.policyService.mainProposal.proposal = response.output.proposal;
            this.eventPropagation.emit('stopLoader');
            modal && modal.open();
            this.proposalNumber = this.policyService.mainProposal.proposal.contractData[0].proposalNumber;
          })
        ).subscribe()
      );
    }

  private savePipInfo(reqSavePipTransf: { fields: any[]; }, saveUndefinedProposal: boolean = false, modal = {}) {
    this.$subscriptions.push(
      this.pipService.savePipTransferData(reqSavePipTransf, this.policyService.mainProposalResourceId).pipe(
          tap(innerResp => {
              if (!!innerResp && !!innerResp.proposal) {
                  this.policyService.mainProposal.proposal.extensionData.properties = innerResp.proposal.extensionData.properties;
              }
          }),
          switchMap(resp => {
              const reqSavePipContrib = this.createSavePipContribReq();
              return this.pipService.savePipContributionData(reqSavePipContrib, this.policyService.mainProposalResourceId).pipe(
                  tap(innerResp => {
                      if (!!innerResp && !!innerResp.proposal) {
                          this.policyService.mainProposal.proposal.extensionData.properties = innerResp.proposal.extensionData.properties;
                      }
                  }));
          }),
          concatMap(resp => {
              return this.updateFactors(null, false);
          }),
          finalize(() => this.eventPropagation.emit('stopLoader'))
      ).subscribe((_resp: any) => {
          this.manageSavePropResp();
          if (!saveUndefinedProposal) {
            if (this.checkFattoriBene()) {
              this.actionService.isGoodsInfo = true;
              this.cardsNavigationService.setCurrentCards(this.cardsNavigationService.STEP.DATI_DI_BENE.route);
              this.navigation.emit(this.cardsNavigationService.STEP.DATI_DI_BENE.route);
            } else {
              this.actionService.isGoodsInfo = false;
              this.policyService.resetQuotation();
              this.cardsNavigationService.setCurrentCards(this.cardsNavigationService.STEP.QUOTAZIONE.route);
              this.navigation.emit(this.cardsNavigationService.STEP.QUOTAZIONE.route);
            }
          } else {
            this.saveUndefinedProposal(modal);
          }
      }, (error => {
            console.error(error);
            this.eventPropagation.emit('stopLoader');
      }))
    );
  }

  getSelectedContributions(): string {
      const contribs = [];
      this.contributionData.contributions.forEach((_, index) => {
          if (this.form.controls.contributionData.value[index].check &&
              this.contributionData.contributions[index].check.code !== 'ContributoVol' ) {
              contribs.push(this.contributionData.contributions[index].check.label);
          }
      });
      return contribs.toString();
  }


  createSavePipContribReq() {
      let _fields: Contribution[] = [];
      if (!!this.contributionData) {
          _fields = this.contributionData.contributions;
          this.contributionData.contributions.forEach((_, index) => {
              _fields[index].check.value = String(this.form.controls.contributionData.value[index].check);
              _fields[index].frequency.value = String(this.form.controls.contributionData.value[index].frequency);
              _fields[index].value.value = String(this.form.controls.contributionData.value[index].amount);
          });
      }
      const switchValue = this.contributionData.switchValue;
      switchValue.value = this.form.controls.switchAmount.value === false ? '0' : '1';
      return {contributions: _fields, switchValue};
  }

  createSavePipTransferReq() {
      let _fields =  [];
      if (!!this.transferData && !!this.form.controls.transferData) {
          _fields = this.transferData;
          this.transferData.map((data, index) => {
              if (!!this.form.controls.transferData.value[data.code]) {
                  if (data.type !== 'PARTY') {
                      _fields[index].value = this.form.controls.transferData.value[data.code];
                  } else {
                      // case of  object like party
                      _fields[index].value = this.form.controls.transferData.value[data.code].val.objectId;
                  }
              }

          });
      }
      return {fields: _fields};
  }

}

