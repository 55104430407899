
<div  class="display-col life-detail-margin-box life-detail-box display-table col-lg-12">
  <div class="tbld_row life-detail-tbld_row_header">
    <div class="tbld_col tbld_col_title first-column"></div>
    <div class="tbld_col tbld_col_title" translate>lpc_category_fund</div>
    <div class="tbld_col tbld_col_title" translate>lpc_Percentage</div>
    <div *ngIf="showAmountColumnOnViewOnly" class="tbld_col tbld_col_title" translate>lpc_equivalent_on_date</div>
  </div>
  <ng-container *ngFor="let profile of selectedProfiles">
    <div class="tbld_row life-detail-investment-profile-row bolder">
      <div class="first-column tbld_col tbld_col_value life-detail-table-border">
          <span (click)="toggleProfile(profile.id)"  class="rgifont" [class.rgi-plus]="!profileToggle[profile.id]" [class.rgi-minus]="profileToggle[profile.id]"></span>
      </div>
      <div class="tbld_col tbld_col_value life-detail-table-border">{{profile.description}}</div>
      <div class="tbld_col tbld_col_value life-detail-table-border">{{ getPercentage(profile) | rgiCtryLNumFormatter:'' : percentFormatterOptions }}</div>
      <div *ngIf="showAmountColumnOnViewOnly" class="tbld_col tbld_col_value life-detail-table-border"><!-- {{ profile.amount ? profile.amount : getPercentage(profile) * totalAmount | rgiCtryLNumFormatter : 'currency'}} --></div>
    </div>
    <ng-container *ngIf="profileToggle[profile.id]">
        <div *ngFor="let fund of selectedFundsFilter(profile.funds)" class="tbld_row regular">
          <div class="tbld_col tbld_col_value life-detail-table-border first-column"></div>
          <div class="tbld_col tbld_col_value life-detail-table-border">{{fund.description}}</div>
          <div class="tbld_col tbld_col_value life-detail-table-border">{{ getPercentage(fund) | rgiCtryLNumFormatter:'' : percentFormatterOptions }}</div>
          <div *ngIf="showAmountColumnOnViewOnly" class="tbld_col tbld_col_value life-detail-table-border">{{ getFundAmount(profile, fund) | rgiCtryLNumFormatter : '': currencyFormatterOptions}}</div>
        </div>
    </ng-container>
  </ng-container>
</div>
