import { AfterViewChecked, Component, EventEmitter, Inject, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { JS_EVENT, PV_TOKEN } from '../../../models/consts/lpc-consts';

@Component({
  selector: 'lpc-fiscal-data-detail',
  templateUrl: './fiscal-data-detail.component.html',
  styleUrls: ['../life-movement-detail/life-movement-detail.component.scss',
  './fiscal-data-detail.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class FiscalDataDetailComponent implements OnInit, AfterViewChecked {
  // formatter Options
  public numberFormatterOptions: Intl.NumberFormatOptions = {
    style: 'decimal'
  };

  @Input() id: string;
  @Input() data: any;
  @Output() eventPropagation = new EventEmitter<any>();

  constructor(
    @Inject(PV_TOKEN.PV_GRAPHIC_FIX) protected plcGraphicFix
  ) { }

  ngOnInit() {
    this.plcGraphicFix.fixCardGraphic(this.id);
    console.log(this.data, 'data');
  }

  back() {
    this.eventPropagation.emit(JS_EVENT.BACK_TO_LIFE_DETAIL);
  }

  ngAfterViewChecked() {
    this.plcGraphicFix.fixCardGraphic(this.id);
  }
}
