import { AfterViewChecked, Component, EventEmitter, Inject, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { JS_EVENT, PV_TOKEN } from '../../../models/consts/lpc-consts';

/**
 * @author dmasone
 * @description Component used for show expiry option detail
 */
@Component({
  selector: 'lpc-expiry-option-detail',
  templateUrl: './expiry-option-detail.component.html',
  styleUrls: ['../life-movement-detail/life-movement-detail.component.scss',
  './expiry-option-detail.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class ExpiryOptionDetailComponent implements OnInit, AfterViewChecked {
  // formatter Options
  public numberFormatterOptions: Intl.NumberFormatOptions = {
    style: 'decimal',
    minimumFractionDigits: 2
  };

  @Input() id: string;
  @Input() data: any;
  @Output() eventPropagation = new EventEmitter<any>();

  constructor(
    @Inject(PV_TOKEN.PV_GRAPHIC_FIX) protected plcGraphicFix,
  ) { }

  ngOnInit(): void {
    this.plcGraphicFix.fixCardGraphic(this.id);
  }

  ngAfterViewChecked() {
    this.plcGraphicFix.fixCardGraphic(this.id);
  }

  public back() {
    this.eventPropagation.emit(JS_EVENT.BACK_TO_LIFE_DETAIL);
  }

}
