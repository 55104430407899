import {Component, Input, OnInit} from '@angular/core';
import {Fund, Profile} from '../../../../models/life-detail.model';

@Component({
  selector: 'lpc-profiles-and-funds-table',
  templateUrl: './lpc-profiles-and-funds-table.component.html',
  styleUrls: ['./lpc-profiles-and-funds-table.component.css']
})
export class LpcProfilesAndFundsTableComponent implements OnInit {

  public percentFormatterOptions: Intl.NumberFormatOptions = {
    style: 'percent',
    minimumIntegerDigits: 1,
    minimumFractionDigits: 2
  };

  @Input() showTable: boolean;
  @Input() showTotal: boolean;
  @Input() profiles: Profile[] = [];

  public percentagesProfiles: Array<number> = [];
  public percentagesProfilesToShow: Array<number> = [];
  public percentagesFundsToShow: Array<Array<number>> = [];

  constructor() {
    // not implemented
  }

  ngOnInit() {
    this.setPercentagesProfiles();
    this.setPercentagesFunds();
  }

  public setPercentagesProfiles() {
    if (this.isThereAtLeastAProfile()) {
      this.profiles.forEach((profile: Profile) => {
        const percentageProfile: number = this.calculatePercentageProfile(profile);
        this.percentagesProfiles.push(percentageProfile);
        this.percentagesProfilesToShow.push(percentageProfile / 100);
      });
    }
  }

  public setPercentagesFunds() {
    if (this.isThereAtLeastAProfile()) {
      this.profiles.forEach((profile: Profile, indexProfile: number) => {
        this.percentagesFundsToShow.push([]);
        if (this.canCalculatePercentageFundsOfProfile(profile, indexProfile)) {
          this.calculatePercentagesFundsOfProfile(profile, indexProfile);
        }
      });
    }
  }

  public totalPercentSummary(profiles: Profile[]): string {
    return profiles.reduce((subtotal, item) => subtotal + Number(item.percent), 0)
      .toString(10);
  }

  private isThereAtLeastAProfile(): boolean {
    return Array.isArray(this.profiles) && this.profiles.length > 0;
  }

  private isThereAPercentageProfile(indexProfile: number): boolean {
    return this.percentagesProfiles[indexProfile] !== undefined && this.percentagesProfiles[indexProfile] !== null;
  }

  private isThereAtLeastAFundOfProfile(profile: Profile): boolean {
    return Array.isArray(profile.funds) && profile.funds.length > 0;
  }

  private canCalculatePercentageFundsOfProfile(profile: Profile, indexProfile: number): boolean {
    return this.isThereAtLeastAFundOfProfile(profile) && this.isThereAPercentageProfile(indexProfile);
  }

  private calculatePercentagesFundsOfProfile(profile: Profile, indexProfile: number) {
    const lastIndexFund: number = profile.funds.length - 1;
    let sumPercentagesFunds = 0;
    profile.funds.forEach((fund: Fund, indexFund: number) => {
      const percentageFund: number = this.calculatePercentageFund(fund, indexProfile, indexFund, lastIndexFund, sumPercentagesFunds);
      if (indexFund < lastIndexFund) {
        sumPercentagesFunds += percentageFund;
      }
      this.percentagesFundsToShow[indexProfile].push(percentageFund);
    });
  }

  private calculatePercentageProfile(profile: Profile): number {
    let percentageProfileCalculated = 0;
    if (Array.isArray(profile.funds) && profile.funds.length > 0) {
      percentageProfileCalculated = profile.funds.reduce((acc: number, fund: Fund) => acc
        + (fund.percent !== undefined && fund.percent !== null ? Number(fund.percent) : 0), 0);
    }
    return percentageProfileCalculated;
  }

  private calculatePercentageFund(fund: Fund, indexProfile: number, indexFund: number, lastIndexFund: number,
                                  sumPercentagesFunds: number): number {
    let percentageFundCalculated = 0;
    if (!!fund && fund.percent !== undefined && fund.percent !== null) {
      if (indexFund < lastIndexFund) {
        const percentageProfile: number = this.percentagesProfiles[indexProfile];
        percentageFundCalculated = Number(fund.percent) / percentageProfile;
      } else if (indexFund === lastIndexFund) {
        percentageFundCalculated = 1 - sumPercentagesFunds;
      }
    }
    return percentageFundCalculated;
  }

}
