import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {LpcKarmaFundButtonComponent} from './component/lpc-karma-fund-button/lpc-karma-fund-button.component';
import {ReactiveFormsModule} from '@angular/forms';
import {LpcKarmaFundButtonsComponent} from './component/lpc-karma-fund-buttons/lpc-karma-fund-buttons.component';
import {LpcKarmaFundStepComponent} from './component/lpc-karma-fund-step/lpc-karma-fund-step.component';
import {PortalNgCoreModule} from '@rgi/portal-ng-core';
import {LpcKarmaFundMessageComponent} from './component/lpc-karma-fund-message/lpc-karma-fund-message.component';
import {RgiCurrencyModule} from '../rgi-currency/rgi-currency.module';

import {LpcFundsTableComponent} from './component/lpc-funds-table/lpc-funds-table.component';
import {KarmaFundService} from './service/karma-fund.service';
import {HttpClientModule} from '@angular/common/http';
import {LpcVolatilityButtonComponent} from './component/lpc-volatility-button/lpc-volatility-button.component';
import { LpcKarmaFundTableComponent } from './component/lpc-karma-fund-table/lpc-karma-fund-table.component';
import { LpcKarmaFundSummaryComponent } from './component/lpc-karma-fund-summary/lpc-karma-fund-summary.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { NgSelectModule } from '@ng-select/ng-select';
import { LpcKarmaInvestmentFundComponent } from './component/lpc-investments/lpc-karma-investment-fund/lpc-karma-investment-fund.component';
import { LpcKarmaInvestmentFundsComponent } from './component/lpc-investments/lpc-karma-investment-funds/lpc-karma-investment-funds.component';
import { LpcKarmaDisinvestmentFundComponent } from './component/lpc-disinvestments/lpc-karma-disinvestment-fund/lpc-karma-disinvestment-fund.component';
import { LpcKarmaDisinvestmentFundsComponent } from './component/lpc-disinvestments/lpc-karma-disinvestment-funds/lpc-karma-disinvestment-funds.component';
import { RevisionaryComponent } from './component/revisionary/revisionary.component';
import { PlcFormatterUtils } from './utils/PlcFormatter-utils';
import { LpcInvestmentStepAdapterComponent } from './component/lpc-investments/lpc-investment-step-adapter/lpc-investment-step-adapter.component';
import { CurrencyCacheService } from '../../services/currency-cache.service';
import { RgiCountryLayerModule } from '@rgi/rgi-country-layer';
import { LpcKarmaDisinvestmentFullFundComponent } from './component/lpc-full-disinvestments/lpc-karma-full-disinvestment-fund/lpc-karma-disinvestment-full-fund.component';
import { LpcKarmaDisinvestmentFullFundsComponent } from './component/lpc-full-disinvestments/lpc-karma-full-disinvestment-funds/lpc-karma-disinvestment-full-funds.component';
import { LpcProfilesAndFundsTableComponent } from './component/lpc-profiles-and-funds-table/lpc-profiles-and-funds-table.component';
import { RgiRxI18nModule } from '@rgi/rx/i18n';
import { RgiRxExpansionModule, RgiRxPanelModule } from '@rgi/rx/ui';

@NgModule({
  declarations: [
    LpcKarmaFundButtonComponent,
    LpcKarmaFundButtonsComponent,
    LpcKarmaFundStepComponent,
    LpcKarmaFundMessageComponent,
    LpcFundsTableComponent,
    LpcVolatilityButtonComponent,
    LpcKarmaFundTableComponent,
    LpcKarmaInvestmentFundsComponent,
    LpcKarmaInvestmentFundComponent,
    LpcKarmaFundSummaryComponent,
    LpcKarmaInvestmentFundComponent,
    LpcKarmaInvestmentFundsComponent,
    LpcKarmaDisinvestmentFundComponent,
    LpcKarmaDisinvestmentFundsComponent,
    LpcKarmaDisinvestmentFullFundsComponent,
    LpcKarmaDisinvestmentFullFundComponent,
    RevisionaryComponent,
    LpcInvestmentStepAdapterComponent,
    LpcProfilesAndFundsTableComponent
  ],
  exports: [
    LpcKarmaFundButtonComponent,
    LpcKarmaFundButtonsComponent,
    LpcKarmaFundStepComponent,
    LpcKarmaFundMessageComponent,
    LpcFundsTableComponent,
    LpcVolatilityButtonComponent,
    LpcKarmaFundTableComponent,
    LpcKarmaInvestmentFundsComponent,
    LpcKarmaInvestmentFundComponent,
    LpcKarmaFundSummaryComponent,
    LpcKarmaInvestmentFundComponent,
    LpcKarmaInvestmentFundsComponent,
    LpcKarmaDisinvestmentFundComponent,
    LpcKarmaDisinvestmentFundsComponent,
    LpcKarmaDisinvestmentFullFundsComponent,
    LpcKarmaDisinvestmentFullFundComponent,
    RevisionaryComponent,
    LpcInvestmentStepAdapterComponent,
    LpcProfilesAndFundsTableComponent
  ],
  imports: [
    CommonModule,
    RgiCountryLayerModule,
    ReactiveFormsModule,
    PortalNgCoreModule,
    RgiCurrencyModule,
    HttpClientModule,
    NgxPaginationModule,
    NgSelectModule,
    RgiRxI18nModule,
    RgiRxPanelModule,
    RgiRxExpansionModule
  ],
  providers: [
    KarmaFundService,
    PlcFormatterUtils,
    CurrencyCacheService
  ]
})
export class LpcKarmaFundModule {
}
