export const codiceFunzione: string = 'codiceFunzione';
export const codiceMessaggio: string = 'codiceMessaggio';
export const dataInizioValidita: string = 'dataInizioValidita';
export const dataFineValidita: string = 'dataFineValidita';
export const importo: string = 'importo';
export const actions: string = 'actions';

export class UtilityMethod {
    static transform(dateString: string): string {
        if(
            dateString != null 
            && dateString != "" 
            && dateString != undefined 
            && dateString.includes("T")
        ){
            const date: Date = new Date(dateString);

            const day: string = date.getDate().toString().padStart(2, '0');
            const month: string = (date.getMonth() + 1).toString().padStart(2, '0');
            const year: string = date.getFullYear().toString();
    
            return `${day}-${month}-${year}`;
        }

        return dateString;
    }
}
