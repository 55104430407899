import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { LpcDataTransferComponent } from './lpc-data-transfer.component';
import { RgiCountryLayerModule } from '@rgi/rgi-country-layer';
import { RgiRxI18nModule } from '@rgi/rx/i18n';

@NgModule({
    declarations: [LpcDataTransferComponent],
    exports: [LpcDataTransferComponent],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        RgiCountryLayerModule,
        RgiRxI18nModule
    ]
})
export class LpcDataTransferModule { }
