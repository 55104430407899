import { LpcFactorAdapterModule } from './../lpc-factor-adapter/lpc-factor-adapter.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LpcUnitTableComponent } from './lpc-unit-table.component';
import { RgiCountryLayerModule } from '@rgi/rgi-country-layer';
import { PortalNgCoreModule } from '@rgi/portal-ng-core';
import { ReactiveFormsModule } from '@angular/forms';
import { LpcHelpModule } from '../lpc-help/lpc-help.module';
import { RgiRxI18nModule } from '@rgi/rx/i18n';

@NgModule({
    declarations: [LpcUnitTableComponent],
    imports: [
        CommonModule,
        RgiCountryLayerModule,
        PortalNgCoreModule,
        ReactiveFormsModule,
        RgiRxI18nModule,
        LpcFactorAdapterModule,
        LpcHelpModule
    ],
    exports: [LpcUnitTableComponent]
})
export class LpcUnitTableModule { }
