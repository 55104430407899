<div [formGroup]="formGroup">
  <div style="margin-top:5px" class="input-group" formGroupName="value">
    <input [ngClass]="{'with-percentage' : showPercentage}" type="text" class="form-control" readonly="" formControlName="name" [attr.data-qa]="getValueForDataQA('name')">
      <!-- PERCENTAGE -->
      <input *ngIf="!items?.length && showPercentage"
        type="text"
        formControlName="percentage"
        [ngClass]="{'with-percentage' : showPercentage, 'disabled' : isBenefCedola }"
        [placeholder]="'lpc_perc' | translate "
        class="form-control"
        min="0.01"
        max="100"
        required
        licPercentageDirective
        [attr.data-qa]="getValueForDataQA('percent')"
      >
      <ng-select required *ngIf="!!items?.length && showPercentage" [placeholder]="'lpc_perc' | translate " [attr.data-qa]="getValueForDataQA('percent')" class="col-lg-6" formControlName="percentage">
        <ng-option *ngFor="let item of items" [value]="item?.id">
          {{item?.description}}
        </ng-option>
      </ng-select>
    <!-- PERCENTAGE -->
    <div class="input-group-btn">
      <!--  <button class="btn btn-outline-secondary" type="button" (click)="onEdit(role)"> -->
      <!--   <span class="rgifont rgi-pencil"></span> -->
      <!-- </button> -->
      <button [disabled]="!isBenEditable" (click)="deleteBen(value)" class="btn btn-outline-secondary" type="button"
        [attr.data-qa]="getValueForDataQA('trash')">
        <span class="rgifont rgi-trash"></span>
      </button>
    </div>
    <div *ngIf="!items?.length" formArrayName="linkedSubjectsRoles" class="table-holders">
      <label class="label label-margin">{{beneficiaryRole?.label}}</label>
      <lpc-required *ngIf="beneficiaryRole?.mandatory" required="true | translate"></lpc-required>
      <div *ngFor="let control of roles; let i = index;trackBy: trackFn">
        <div class="col-lg-12 filled-holders">
          <lpc-sub-beneficiary-control [showPercentage]="beneficiaryRole?.percentage"
            (delete)="deleteSubRole(i.toString(), $event)" [formControlName]="i.toString()">
          </lpc-sub-beneficiary-control>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="canAddEffectiveHolders()" class="row">
    <div class="empty-holders">
      <input [value]="'lpc_Seleziona' | translate" (click)="openAnagSubjectModal(beneficiaryRole.code)"
        class="col-lg-12 drop-container dashedSelect" [attr.data-qa]="'dashed-select-beneficiary'" readonly>
    </div>
  </div>

  <!-- see: https://support.rgigroup.com/browse/RDDL-3504 -->
  <div class="example-row" *ngIf="reg41CheckBoxVisibility" >
    <pnc-checkbox
      [formControlName]="'expiryCommunication'"
      class="check"
      [attr.data-qa]="'checkbox_reg41'"
      [checked]="true">
    </pnc-checkbox>
    <label class="lbl_input" translate>lpc_reg41</label>
  </div>

  <div class="example-row" *ngIf="getBenDgCheckBoxVisibility" >
    <pnc-checkbox
      [formControlName]="'severeDisability'"
      class="check"
      [attr.data-qa]="'checkbox_benDg'"
      [checked]="true">
    </pnc-checkbox>
    <label class="lbl_input" translate>lpc_benDg</label>
  </div>

  <!-- PAYMENTS FOR CEDOLA BENEFICIARY -->
  <div class="example-row" *ngIf="isBenefCedola">
    <lpc-payment-step 
      [required]="true" 
      [editable]="isPaymentTypeEditable"
      [paymentTypeDefinitions]="paymentTypes"                              
      formControlName="creditPayment" 
      (change)="onChangePayment($event)">
    </lpc-payment-step>
  </div>

  <div class="example-row" *ngIf="benefPositionNumberConfig.active">
    <div class="col-lg-6">
      <label for="positionNumber" class="label-core" translate>lpc_position_number</label>
      <lpc-required required="true | translate"></lpc-required><br>
    </div>
    <ng-select
          *ngIf="!!benefPositionNumberConfig.benefPositionNumberList?.length"
          required
          [placeholder]="'lpc_position_number' | translate"
          [attr.data-qa]="getValueForDataQA('position_number')"
          class="col-lg-6"
          formControlName="positionNumberCode">
      <ng-option *ngFor="let item of benefPositionNumberConfig.benefPositionNumberList" [value]="item?.code">
        {{item?.label}}
      </ng-option>
    </ng-select>
  </div>
</div>
