import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { FormControl, FormGroup, UntypedFormGroup } from '@angular/forms';
import { Observable, combineLatest, of, forkJoin } from 'rxjs';
import { filter, map, mergeMap, switchMap } from 'rxjs/operators';
import { ApiDialogueHandlerFilter } from '../api/api-dialogue-handler-filter';
import { DetailObjectToList, DetailObjectToNew, DialogueHandlerSearch, ListObjectToSummary, ObjectToNew, ResponseFromSearchState } from '../dialogue-handler-configuration/dialogue-handler.model';
import { restEndPoint } from '../dialogue-handler-configuration/dialogue-handler.endpoint';
import { ActiveRoute } from '@rgi/rx/router';
import { Etype, FormSearch, WrapperDialogueFunction, WrapperDialogueObject } from '../dialogue-handler-configuration/dialogue-handler-search.model';
import { ActCausOutcome, ActDoc, CausaliBySinistroList, DialogueRoleList, FunParam } from '../dialogue-handler-configuration/new-dialogue.model';
import { DialogueHandlerIntegrationService } from '../dialogue-handler-configuration/dialogue-handler-integration.service';
import { DialogueSummaryObject } from '../dialogue-handler-configuration/dh-summary.model';
import { NewDialogueViewConfiguration } from '../new-dialogue/new-dialogue.component';
import { ListDialogueViewConfiguration } from '../constants/listEnumConstants';
import { DHOutcome } from '../dialogue-handler-configuration/dialogue-handler-list.model';
import { MatrixIndex } from '../dialogue-handler-list/dialogue-handler-list.component';
import { RoleCompany } from '../dialogue-summary/dialogue-summary.component';

@Injectable({
  providedIn: 'root'
})

export class DialogueHandlerListService {

  private urlDialogueHandlerList = '/claims/dialogueHandlerSearch';
  private baseApiUrlV2: string;
  sessionService: any;
  id: string;

  constructor(
    private httpClient: HttpClient,
    @Inject('environment') environment: any,
    @Inject('sessionService') sessionService: any,
    @Inject('coreResult') private coreResult: any,
    private integrationService: DialogueHandlerIntegrationService
  ) {
    this.baseApiUrlV2 = environment.api.portal.host + environment.api.portal.path + '/v2';
    this.sessionService = sessionService;
  }


  getCurrentSession() {
    this.id = this.sessionService.list().find(el => el.isActive)?.id;
  }

  getCurrentSessionId() {
    const idSession = this.sessionService.list().find(el => el.isActive)?.idSession;
    return idSession ? idSession : null;
  }

  initStateList$(st: Observable<ResponseFromSearchState>, filterList: DetailObjectToList): Observable<ResponseFromSearchState> {
    return st.pipe(
      map(state => {
        state.formSearch = filterList.formSearch;
        state.responseFromSearchList = filterList.responseFromSearchList;
        state.searchWrapperObject = filterList.searchWrapperObject;
        state.viewConfig = filterList.viewConfig;
        if (state.viewConfig != ListDialogueViewConfiguration.SEARCH_LIST_VIEW) {
          state.responseFromSearchList = state.responseFromSearchList.filter(elem => elem.lastMsgCode);
        }
        return state;
      })
    )
  }


  initStateListFromClaim$(st: Observable<ResponseFromSearchState>): Observable<ResponseFromSearchState> {
    this.getCurrentSession();
    let queryStringClaim = "";
    let queryString = "";

    return st.pipe(
      switchMap((state) => {
        state.idParentSession = this.getCurrentSessionId();
        state.claimSession = this.coreResult.getResult(this.id, 'detail') ? this.coreResult.getResult(this.id, 'detail') : this.coreResult.getResult(this.id, 'detail-pes');
        let claimFilter = null;
        if (state.claimSession.claimNumber)
          { queryStringClaim += "?claimNumberPass=" + state.claimSession.claimNumber;
            claimFilter = state.claimSession.claimNumber;
          }

        else if (state.claimSession.reportNumber)
          { queryStringClaim += "?potClaimNumberPass=" + state.claimSession.reportNumber
            claimFilter = state.claimSession.reportNumber;
          }

        queryString += "?insuranceCodeUs=" + state.claimSession.aniaCompanyCode + "&licensePlateUS=" + state.claimSession.insuredVehiclePlate;

        let occurrenceDateFrom = new Date(state.claimSession.occurrenceDate);
        occurrenceDateFrom.setDate(occurrenceDateFrom.getDate() - 45);
        let occurrenceDateTo = new Date(state.claimSession.occurrenceDate);
        occurrenceDateTo.setDate(occurrenceDateTo.getDate() + 45);
        
        queryString += "&dateLossFrom=" + occurrenceDateFrom.getTime() + "&dateLossTo=" + occurrenceDateTo.getTime(); 
        return forkJoin({
          firstResponse: this.httpClient.get<DHOutcome>(this.baseApiUrlV2 + restEndPoint.goToSearchResults + queryStringClaim),
          secondResponse: this.httpClient.get<DHOutcome>(this.baseApiUrlV2 + restEndPoint.goToSearchResults + queryString),
        }).pipe(
          map((apiResponses) => {
            // state.listFromClaim[0] = this.processResponse(apiResponses);
            state.listFromClaim[0] = apiResponses.firstResponse.response;
            state.listFromClaim[1] = apiResponses.secondResponse.response;
            state.viewConfig = ListDialogueViewConfiguration.DOUBLE_LIST_VIEW;
            console.log("initStateObject=================> ", state);
            for(let i=0;i<state.listFromClaim.length;i++){
              state.listFromClaim[i] = state.listFromClaim[i].filter(elem => elem.roleCompany.code != RoleCompany.DEBITOR || (elem.lastMsgCode && elem.lastMsgCode!="") )
            }

            state.listFromClaim[1] = state.listFromClaim[1].filter((elem) => elem.claimNumberPass != claimFilter && elem.potClaimNumberPass != claimFilter);
            console.log("initStateObject=================> ", state);

            return state;
          })
        );
      })
    );

  }

  getNewDialogueData(st: Observable<ResponseFromSearchState>, activeRouteData: ActiveRoute, viewCond: string) {
    console.log("STATE AGGIORNATO DOPO CHIAMATE======================>");
    return st.pipe(
      mergeMap((state) => {
        console.log("STATE AGGIORNATO DOPO CHIAMATE======================>", state == undefined);
        console.log(viewCond)
        if (viewCond === NewDialogueViewConfiguration.COMPLETE_VIEW_CONF) {
        console.log(viewCond)
          return forkJoin({
            funcResponse: this.httpClient.get<WrapperDialogueFunction>(this.baseApiUrlV2 + restEndPoint.fillInSearchFunMes),
            responseRoleList : this.httpClient.get<DialogueRoleList>(this.baseApiUrlV2 + restEndPoint.roleListActiveRoleCompany),
            objResponse: this.httpClient.get<WrapperDialogueObject>(this.baseApiUrlV2 + restEndPoint.fillInSearchOggetto),
          })
          .pipe(
              map((apiResponse) => {
                state.newWrapperObject.responseRoleList = this.processFirstResponse(apiResponse.responseRoleList);
                console.log("STATE AGGIORNATO DOPO CHIAMATE======================>", state);
                state.searchWrapperObject.dialogueFunctionResponse = apiResponse.funcResponse.response;
                state.searchWrapperObject.dialogueObjectResponse = apiResponse.objResponse.response;
                const objectToNew: ObjectToNew = {
                  searchWrapperObject: state.searchWrapperObject,
                  newWrapperObject: state.newWrapperObject,
                  viewConfig: viewCond,
                  newDialogueRequest: null,
                  claimNumberPass: state.claimSession.claimNumber,
                  idParentSession: state.idParentSession
                };
                this.integrationService.openNewDialogue(objectToNew, activeRouteData);
                return state;
              })
            );
        } else if (viewCond === NewDialogueViewConfiguration.PARTIAL_VIEW_CONF) {
          // Effettua solo la prima chiamata API se viewCond è 'PARTIAL_VIEW_CONF'
          return this.httpClient.get<DialogueRoleList>(this.baseApiUrlV2 + restEndPoint.roleListActiveRoleCompany).pipe(
            map((responseRoleList) => {
              state.newWrapperObject.responseRoleList = this.processFirstResponse(responseRoleList);
              console.log("STATE AGGIORNATO DOPO CHIAMATE======================>", state);

              const objectToNew: ObjectToNew = {
                searchWrapperObject: state.searchWrapperObject,
                newWrapperObject: state.newWrapperObject,
                viewConfig: viewCond,
                newDialogueRequest: null,
                claimNumberPass: null,
                idParentSession: state.idParentSession
              };
              this.integrationService.openNewDialogue(objectToNew, activeRouteData);

              return state;
            })
          );
        } else {
          // Se viewCond non è né 'COMPLETE_VIEW_CONF' né 'PARTIAL_VIEW_CONF', restituisci semplicemente lo stato corrente
          return of(state);
        }
      })
    );
  }


  private processFirstResponse(apiResponse: any): DialogueRoleList {
    if (!apiResponse || !Array.isArray(apiResponse.data)) {
      console.error("La risposta non è nel formato atteso o è vuota: ", apiResponse);
      return null;
    }
    return {
      requestOutcome: apiResponse.requestOutcome,
      data: apiResponse.data.map((item: any) => ({
        code: item.code,
        description: item.description
      }))
    };
  }

  private processSecondResponse(apiResponse: any): ActCausOutcome {
    if (!apiResponse || !Array.isArray(apiResponse.response)) {
      console.error("La risposta non è nel formato atteso o è vuota: ", apiResponse);
      return null;
    }
    return {
      requestOutcome: apiResponse.requestOutcome,
      response: apiResponse.response.map((item: any) => ({
        causaleId: item.causaleId,
        causaleDescr: item.causaleDescr,
        actorId: item.actorId,
        actorExternalIdentifyDamageParty: item.actorExternalIdentifyDamageParty,
        actorName: item.actorName,
        licensePlateUS: item.licensePlateUS,
        // selected: false
      }))
    };
  }

  private processThirdResponse(apiResponse: any): ActDoc {
    if (!apiResponse || !Array.isArray(apiResponse.response)) {
      console.error("La risposta non è nel formato atteso o è vuota: ", apiResponse);
      return null;
    }
    return {
      requestOutcome: apiResponse.requestOutcome,
      response: apiResponse.response.map((item: any) => ({
        docId: item.docId,
        docName: item.docName,
        docTitle: item.docTitle,
        docCategory: item.docCategory,
        docSubCategory: item.docSubCategory,
        actorName: item.actorName
      }))
    };
  }

  private processFourthResponse(apiResponse: any): FunParam {
    console.log("apiResponseIncriminata===>", apiResponse);
    if (!apiResponse || !Array.isArray(apiResponse.response)) {
      console.error("La risposta non è nel formato atteso o è vuota: ", apiResponse);
      return null;
    }
    return {
      requestOutcome: apiResponse.requestOutcome,
      response: apiResponse.response.map((item: any) => ({
        idParam: item.idParam,
        idFunctionStatus: item.idFunctionStatus,
        paramLabel: item.paramLabel,
        typeValue: item.typeValue,
        defaultValue: item.defaultValue,
        listCode: item.listCode,
        listValue: item.listValue,
        required: item.required,
        maxLength: item.maxLength
      }))
    };
  }


  generateQueryStringForCausaleBySinistro(formSearch: FormSearch): string {
    const queryParams = [];
    if (formSearch.function) { queryParams.push(`functionCode=${formSearch.function}`); }
    if (formSearch.object) { queryParams.push(`dialogueSubjectCode=${formSearch.object}`); }
    queryParams.push(`claimNumberPass=99999`);
    // TODO manca parametro del codice sinistro che dovrebbe arrivare da componente angularJS
    return queryParams.length > 0 ? '?' + queryParams.join('&') : '';
  }

  generateQueryStringForActDoc(): string {
    const queryParams = [];
    // TODO mancano parametri claimNumberPass e actorId che dovrebbero arrivare da componente angularJS
    queryParams.push(`claimNumberPass = testParameter1`);
    queryParams.push(`actorId = testParameter2`);
    return queryParams.length > 0 ? '?' + queryParams.join('&') : '';
  }

  //TODO controllare se è solo un parametro per evitare metodo
  generateQueryStringForFunParam(): string {
    const queryParams = [];
    // TODO manca parametro idFunctionstatus che dovrebbero arrivare da componente angularJS
    queryParams.push(`idFunctionstatus = testParameterStatus`);
    return queryParams.length > 0 ? '?' + queryParams.join('&') : '';
  }


  goToSummaryData(st: Observable<ResponseFromSearchState>, activeRouteData: ActiveRoute, viewCond: string, data, matrixIndex) {
    return st.pipe(
      mergeMap((state) => {
        let queryString = '';
        queryString += "?idClnotifcard=" + data.row.idClnotifcard;
        const responseSummary =
          this.httpClient.get<DialogueSummaryObject>(this.baseApiUrlV2 + restEndPoint.summary + queryString);
        return combineLatest([responseSummary, of(state)]);
      }),
      mergeMap(([responseSummary, state]) => {
        if (responseSummary) {
          if(matrixIndex == MatrixIndex.SX_LIST || matrixIndex == MatrixIndex.NOT_ASSOCIETED_LIST){
            const objectToSummary: ListObjectToSummary = {
              searchWrapperObject: state.searchWrapperObject,
              dialogueSummaryObject: responseSummary,
              responseFromSearchList: state.listFromClaim[matrixIndex][data.index],
              viewConfig: viewCond,
              idParentSession: state.idParentSession,
              idClnotifcard : data.row.idClnotifcard,
              checkList: ListDialogueViewConfiguration.DOUBLE_LIST_VIEW,
            }
          this.integrationService.goToDialogueHandlerSummary(objectToSummary, activeRouteData);
          }
          else {
            const objectToSummary: ListObjectToSummary = {
              searchWrapperObject: state.searchWrapperObject,
              dialogueSummaryObject: responseSummary,
              responseFromSearchList: state.responseFromSearchList[data.index],
              viewConfig: viewCond,
              idParentSession: state.idParentSession,
              idClnotifcard : data.row.idClnotifcard,
              checkList: ListDialogueViewConfiguration.SEARCH_LIST_VIEW,
              dialogueList: state.responseFromSearchList
            }
          this.integrationService.goToDialogueHandlerSummary(objectToSummary, activeRouteData);
          }

        }
        else {
          console.error('Risposta non valida dalla chiamata API o non è un array');
        }

        return of(state)
      })
    )
  }

  closeSession$(activeRouteId: string, state: ResponseFromSearchState) {
    this.sessionService.remove(activeRouteId);
    if (state.idParentSession !== null) {
      this.sessionService.setActive(state.idParentSession);
    }
  }
  // private processResponse(apiResponses: any): any[] {
  //     var listaDialoghi: any[] = [];
  //     if(apiResponses.firstResponse.response.length>0){
  //       for (var i =0;i<apiResponses.firstResponse.response.length;i++){
  //         if(apiResponses.firstResponse.response[i].roleCompany.code == 9 && apiResponses.firstResponse.response[i].fromBatch === false ){

  //         }
  //         else{
  //         listaDialoghi.push(apiResponses.firstResponse.response[i])
  //         }
  //       }
  //     }
  //     return listaDialoghi;

  // }
}
