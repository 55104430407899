import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LpcInstallmentsComponent } from './lpc-installments.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { RgiCountryLayerModule } from '@rgi/rgi-country-layer';
import { RgiRxI18nModule } from '@rgi/rx/i18n';




@NgModule({
    declarations: [LpcInstallmentsComponent],
    exports: [LpcInstallmentsComponent],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        RgiCountryLayerModule,
        RgiRxI18nModule
    ]
})
export class LpcInstallmentsModule { }
