<lpc-stepper [formGroup]="formGroup" (stepSlide)="onSlide($event)" [isPublished]="publishMessage !== null">
  <lpc-step id="dates" label="lpc_date_variation_step" [errors]="errors" fieldId="dates" (next)="onNext($event)">
    <lpc-dates-step [inputFields]="dateDefinitions" [formControlName]="'dates'" (dateChange)="onDateChange($event)"
      (reloadRequest)="updateData()" [hide]="activeStep<1">
    </lpc-dates-step>
  </lpc-step>

  <!--<lpc-step id="quotation" [enableAuth]="isAuth" label="lpc_quotation_step" [errors]="errors" fieldId="quotation" (next)="onNext($event)"
            feFieldId="quotation">
    <div formGroupName="quotation" class="row">
      <div *ngFor="let insuredSettlement of insuredSettlements">
        <p class="insured">
          <label translate>lpc_Assicurato</label>
          <label>: {{ insuredSettlement.insured}}</label>
        </p>
        <lpc-definition-table
          [label]="!!damageData ? 'lpc_life_policy' : null"
          [definitions]="tableDefinitions"
          [rows]="insuredSettlement.settlement">
        </lpc-definition-table>
        <br>
        <lpc-quotation-recess
          [label]="damageData != null ? 'lpc_damages_policy' : null"
          [quotationData]="damageData">
        </lpc-quotation-recess>
      </div>
    </div>
  </lpc-step>-->

  <lpc-step [enableAuth]="isAuth" label="lpc_quotation_step" [errors]="errors" fieldId="quotation" (next)="onNext($event, true)" id="quotation"
            (confirmAccept)="onNext($event, true, null, true)" [isConfirmAndAccept]="isConfirmAndAccept">
    <div formGroupName="quotation" class="overflow">
      <div *ngIf="quotationData">
        <h4 translate>lpc_life_policy</h4>
        <lpc-quotation-reduction [quotationData]="quotationData"></lpc-quotation-reduction>
      </div>
    </div>
  </lpc-step>

  <lpc-step *ngIf="isAuth || !!errors && !!errors.length" id="warnings" label="lpc_warnings" (next)="publish()" [nextLabel]="isAuth ? 'AUTHORIZATION REQUEST' : 'Confirm'"
            (confirmAccept)="publish(isConfirmAndAccept)" [isConfirmAndAccept]="isConfirmAndAccept">
    <lpc-warning-messages *ngIf="!!errors" [errors]="errors"></lpc-warning-messages>
  </lpc-step>

  <lpc-step
    label="lpc_confirm_variation_step"
    [errors]="errors"
    fieldId="documents"
    id="documents"
    [nextVisible]=false>

    <lpc-document-wrapper
      *ngIf="!!publishMessage"
      [contractId]="getContractId"
      [operationCode]="getOperationCode"
      [publishMessage]="publishMessage"
      [isAuth]="isAuth"
      [authorizationId]="authorizationId"
      [printMode]="printMode"
      [config]="documentsConfig"
      (documentEvent)="handleDocumentEvent($event)">
    </lpc-document-wrapper>

    <lpc-navigation-buttons
      id="last-step-buttons"
      (back)="changeRouteCloseSession(false)"
      [backLabel]="'lpc_close_button'"
      [backVisible]="true"
      [nextVisible]="false">
    </lpc-navigation-buttons>

  </lpc-step>
</lpc-stepper>

<div class="btn-group btn-group-justified external" *ngIf="!isActiveStep('documents')">
  <lpc-navigation-buttons (back)="changeRouteCloseSession(true)" [nextVisible]="false" [qa]="'annulla'"></lpc-navigation-buttons>
</div>

