import { Observable } from 'rxjs';
import { InfoType } from './enums/vita.enum';
import { RestErrorMessage, Risk } from './postsales-operations-response.model';



export interface LifeContractDetail {
  generalData: GeneralData;
  policyData: any;
  subjectsData: { subject: SubjectData[]};
  warrantiesData: any;
  premiumData: any;
  factorsData: any;
  movementsData: any;
  accountingData: any;
  messages: LifeContractMessage[];
  actionOperations: string[];
  relatedPoliciesData: RelatedPoliciesData;
  factorsForConsultation: FactorForConsultation;
  expiryOptionData: ExpiryOptionData;
  reinvestmentData: ReinvestmentData;
  loanData: LoanDataDetail[];
  reservations: ReservationConsultationData[];
  clausesType: ClausesType[];
  context: string;
  currencyInfo: CurrencyInfo;
}

export interface CurrencyInfo {
  code: string;
  description: string;
}

export interface LoanDataDetail {
  issueDate: string;
  effectiveDate: string;
  paymentFrequencyType: string;
  loanAmount: string;
  loanRepaymentProcedure: string;
  rateType: string;
  interestAmount: string;
  remainingAmount: string;
}

export interface ReinvestmentData {
  settlements: Settlement[];
}

export interface Settlement {
  id: number;
  policyNumber: string;
  movementDate: Date;
  type: string;
  beneficary: string;
  state: string;
  import: number;
  reinvest: number;
}

export interface ExpiryOptionData {
  extraInfo?: any;
  risksExpiryOption?: RisksExpiryOption[];
  beneficiariesExpiryOption?: any;
}

export interface RisksExpiryOption {
  description: string;
  expiryOption: string;
  status: string;
  annuityPaymentType: string;
  annuityFrequency: string;
  reversibilityPercentage: string;
  reversionary: string;
  certainRevenueYears: string;
  postponementType: string;
  postponementYears: string;
}

export interface FactorForConsultation {
  assetsFactors: AssetsFactors[];
  policyFactors: PolicyFactor[];
}

export interface PolicyFactor {
  description: string;
  value: any;
}

export interface AssetsFactors {
  assetDescription: string;
  assetFactors: Asset[];
  risksFactors: RiskFactor[];
}


export interface Asset {
  description: string;
  value: any;
}

export interface RiskFactor {
  riskDescription: string;
  riskFactors: Risk[];
}


export interface AvailablesInquiryActions {
  label: string;
  action: string;
}

export interface LifeContractMessage {
  msg: string;
  livello: string;
}

export interface GeneralData {
  descrProd: string;
  policyId: string;
  proposalAuthorizationId: string;
  status: string;
  subStatus: string;
  prodType: string;
  productId: string;
  managmentNode: string;
  agreement: string;
  polNum: string;
  propNum: string;
  effDate: string;
  paymentMethod: string;
  paymentFreq: string;
  policyReversed: boolean;
  statusCode: string;
  subAgency?: string;
  estimationExpDate: string;
  filiale: GeneralDataValue;
  correspondenceAddress: string;  // RDDL-3750
  additionalData?: PaymentAdditionalData[]; // RDDL-3947
  productTypeId?: string;
  productCode?: string;
}

export interface GeneralDataValue {
  value: string;
  visible: boolean;
}

export interface PaymentAdditionalData {
  fieldLabel: string;
  position: number;
  value: string;
}

export interface SubjectData {
  beneficiary: boolean;
  denomination: string;
  erole: string;
  expiryCommunication: boolean;
  severeDisability: boolean;
  extraInfo: any;
  id: string;
  linkedSubjects: any;
  percBenef: string;
  roleDescription: string;
  typeBenef: string;
  typeChoice: string;
  payments: SectionInfo[];
  positionNumberDescr: string;
}


export interface ResponseInvestments {
  profiles?: Profile[];
  currency?: Currency;
  netAmount?: string;
  grossAmount?: string;
  policyNumber?: string;
  paidAmount?: string;
  effect?: string;
  investedAmount?: string;
  leftPaidAmount?: string;
  leftInvestedAmount?: string;
  lastOpeEffect?: string;
  totalGrossSurrender?: string;
}

export interface Installment {
  grossAmount: string;
  taxes: string;
  fees: string;
  splitInterests: string;
  defaultInterests: string;
  reactivationInterests: string;
  loanInterests: string;
  netAmount: string;
  extraPremium: string;
  loadings: string;
  deductiblePremium: string ;
}

export interface CancellInstallmentData {
  description: string;
  issueDate: string;
  rateAmount: number;
  dateEffect: string;
  policyNumber: string;
}

export interface ResponseOperationSyntesis {
  profilesOp: ProfileOperation[];
}
export interface ProfileOperation {
  fundsOp: FundOperation[];
  idProfile: string;
  postOperationAllowancesNumber: string;
  postOperationPercentage: string;
  postOperationValue: string;
  previousPercentage: string;
  previusAllowancesNumber: string;
  previusValue: string;
  profileName: string;
}
export interface FundOperation {
  fundName: string;
  idFoud: string;
  postOperationAllowancesNumber: string;
  postOperationPercentage: string;
  postOperationValue: string;
  previousPercentage: string;
  previusAllowancesNumber: string;
  previusValue: string;
}

export interface ResponseSettlementBeneficiaryDetails {
  settlementBeneficiaryDetail?: SettlementBeneficiaryDetail;
}

export class SettlementBeneficiaryDetail {
    description?: string;
    rate?: string;
    netAmount?: string;
    survey: SurveyLifeDetail;
}

export class SurveyLifeDetail {
  uuid?: string;
}

export class Profile {
  netAmount?: string;
  grossAmount?: string;
  funds: Fund[];
  guaranteedCapital?: string;
  percent?: string;
  id?: string;
  description: string;
  type: string;

  constructor() {}
}

export class Fund {
  netAmount?: number;
  grossAmount?: number;
  exchangeRate?: number;
  guaranteedCapital?: string;
  percent?: string;
  leftInvestedAmount?: string;
  grossUnits?: string;
  netUnits?: string;
  marketValue?: string;
  marketValueDate?: string;
  exchangeInvRate?: string;
  avgPrice?: string;
  buyingAvgPrice?: string;
  id: string;
  isinSymbol?: string;
  description: string;
  currency?: Currency;
  date?: string;
  unitLinked?: string;
  usedMarketValueDate?: string;
  fundType?: string;
  typeId: string;
  status?: string;
  value?: string;

  constructor() {}
}

export interface Currency {
  id: string;
  label: string;
  symbol: string;
}

// Karma Funds Response
export interface FundsDetail {
  area: string;
  name?: string;
  currency: string;
  frequencyQuotes: string;
  fundCategory: string;
  fundType: string;
  id: string;
  idCrypt: string;
  isinSymbol: string;
  lastDate: string;
  lastQuoteValue: number;
  marketPlace: any;
  peaEligible: boolean;
  startDate: string;
  typeOfInterest: string;
  volatility: number;
  lastVolatilityDate: string;
  fundCode: string;
}

export interface FundsTrend {
  marketValue: number;
  valueData: string;
}

export interface PoliciesDetail {
  policies: Policy[];
}

export interface Policy {
  extension?: any;
  causative?: Causative;
  policyNumber?: string;
  policyHolder?: string;
  description?: string;
  nodeDescription?: string;
  premium?: any;
  licensePlateNumber?: any;
  contractingSubject?: string;
  subscriptorVatNumber?: any;
  state?: State;
  issueDate?: string;
  objectId?: string;
  subscriptorId?: string;
  subscriptorFiscalCode?: string;
  enumobliviontype?: any;
  expirationDate?: any;
  cancellationDate?: any;
  effectDate?: string;
  product?: string;
  proposalNumber?: string;
  nodeCode?: string;
  productCode?: string;
  policyType?: PolicyType;
  category?: Category;
}

export interface Causative {
  identification: string;
  code: string;
  description: string;
}

export interface State {
  codice: string;
  descrizione: string;
}
export interface  PolicyType {
  codice: string;
  descrizione: string;
}

export interface Category {
  codice: string;
  descrizione: string;
}

export interface GsFundDetail {
  revaluations: FundRevaluation[];
  totWarrantyBenefit: string;
}

export interface BeneficiaryLifeDetail {
  label: string;
  typeBenef?: string;
  denomination: string;
  percentage: any;
  id: string;
  expiryCommunication: any;
  severeDisability: any;
  relatedSubject: BenefRelatedLifeDetail[];
  payments: SectionInfo[];
  positionNumberDescr: string;
}

export interface BenefRelatedLifeDetail {
  id: string;
  label: string;
}

export interface LifeContractWarranty {
  benefitMethod: string;
  extraInfo: string;
  onDateBenefit: string;
  premium: string;
  riskCode: string;
  unitDescription: string;
}

export interface RelatedPoliciesData {
  generalData: GeneralData;
  relatedPolicies: RelatedPolicy[];
}

export interface RelatedPolicy {
  bond: string;
  generalData: GeneralData;
  policyNumber: string;
}
export interface Revaluations {
  revaluationRates: Revaluation[];
}
export interface Revaluation {
  fromDate: string;
  value: string;
}

export interface FundRevaluation {
  codiceRischio: string;
  dataEffetto: string;
  gs: string;
  idPrestazione: string;
  numPolizza: string;
  prestazione: string;
  prestazioneIniziale: string;
  prestazioneRivalutata: string;
  tipoRischio: string;
  ultimaRivalutazione: string;
  usingForSum: boolean;
  versione: string;
  movimentAggiornamento: string;
  effettoAggiornamento: string;
  versioneFine: string;
  tassNetto: string;
  cssClass?: string;
}

export interface MovementVariation {
  title: string;
  sections: Section[];
}

export interface Section {
  title: string;
  infos: SectionInfo[];
  code: string;
}

export interface SectionInfo {
  label: string;
  value: string;
}

export interface ReservationDetail {
  reservationId: string;
  reservationType: string;
  cancellationDate?: string;
  effectDate?: string;
  cause?: string;
  policyFactors: ReservationFactors[];
  assetFactors: ReservationFactors[];
  destinationNode?: string;
  id?: string;
  policyQuestionnaires: ReservationQuestionnaires[];
  assetQuestionnaires: ReservationQuestionnaires[];
  risks: ReservationRisks[];
  beneficiaries: ReservationSubjects[];
}

export interface ReservationSubjects {
    benefTypeCode: string;
    benefTypeDesc: string;
    description: string;
    percentage?;
  }

export interface ReservationFactors {
  description: string;
  value: string;
  type: InfoType;
}
export interface ReservationQuestionnaires {
  questionnarieId: string;
  description: string;
}
export interface ReservationRisks {
  description: string;
  code: string;
  status: string;
  statusDescription: string;
  addPremiumHealth?: string;
  addPremiumProf?: string;
  addPremiumSport?: string;
  addPremiumOther?: string;
  riskFactors: ReservationFactors[];
}

export interface ReservationConsultationData {
  reservationType?: string;
  status?: string;
  date?: string;
  showCancellation?: boolean;
  reservationId?: string;
  id?: string;
}

export interface Clauses {
  description?: string;
  text?: string;
}

export interface ClausesType {
  descClauseType?: string;
  clause?: Clauses[];
}

export interface RenewalReservationResponse {
  errorMessages?: RestErrorMessage[];
}

export interface ConsultinServicesObj {
  callInvestments: Observable<any>;
  callMovements: Observable<any>;
  callTools: Observable<any>;
  callFiscalData: Observable<any>;
  callInvestmentPlan: Observable<Array<Profile>>;
}

export interface PremiumDetailObj {
  installments: PremiumDetailInstallmentsObj[];
}

export interface PremiumDetailInstallmentsObj {
  description: string;
  total: IntallmentTotalObj;
  warranties: InstallmentWarrantyObj[];
}

export interface IntallmentTotalObj {
  extraPremium: string;
  fees: string;
  fixedCharges: string;
  gross: string;
  installmentInterest: string;
  net: string;
  purePremium: string;
  taxes: string;
}

export interface InstallmentWarrantyObj {
  description: string;
  extraData: InstallmentWarrantyExtraDataObj;
  extraPremium: string;
  fees: string;
  fixedCharges: string;
  gross: string;
  installmentInterest: string;
  net: string;
  purePremium: string;
  taxes: string;
}

export interface InstallmentWarrantyExtraDataObj {
  extra: InstWarrantyExtraInfos;
  fee: InstWarrantyFeeInfos;
}
export interface InstWarrantyExtraInfos {
    lpc_catastrophe: string;
    health: string;
    other: string;
    professional: string;
    sports: string;
}

export interface InstWarrantyFeeInfos {
  collectionFee: string;
  lambdaFee: string;
  managementFee: string;
  purchaseFee: string;
}

export interface CoverDetailsResponse {
  labelValues: DetailLabelValues[];
}

export interface WarrantyDetailData {
  labelValues: DetailLabelValues[];
  desc: string;
}


export interface DetailLabelValues {
  label: string;
  value: string;
  type: DetailLabelValuesType;
  currencyCode?: string;
}

export type DetailLabelValuesType = 'STRING' | 'DATE' |  'INT' | 'DOUBLE' | 'AMOUNT';
