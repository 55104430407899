import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  Output,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { AbstractControl, UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslationWrapperService } from '../../../i18n/translation-wrapper.service';
import { QuestionnairesManagerComponent } from '@rgi/questionnaires-manager';
import { QuestionnaireCacheService } from '@rgi/questionnaires-manager';
import { combineLatest, forkJoin, Observable, of, Subscription, EMPTY, throwError } from 'rxjs';
import { catchError, distinctUntilChanged, map, switchMap, take, tap } from 'rxjs/operators';
import {
  BENEFICIARY_CHOICE,
  BeneficiaryCathegory,
  ENUMS_KEYS,
  ExtensionProperty,
  FACTOR_TYPE,
  LicCustomProperties,
  Roles, SaveActions,
  SubjectConstants
} from '../../../life-card/enum/life-issue.enum';
import { CardsNavigationService } from '../../cards-navigation/cards-navigation.service';
import { PassUtils } from '../../invest-standalone-session/utils/PassUtils';
import { ActionServiceErrorMessage, ActionServiceResponse } from '../../models/actions.model';
import { AssignmentHolderData, AssignmentHolderResp } from '../../models/assignmentholder.model';
import { DamageRisk, DamagesInstalments } from '../../models/damage.model';
import { Payments } from '../../models/meansofpayment.model';
import {
  Beneficiary,
  CommissionFormula,
  CommonRiskResponse,
  CommonRiskRow,
  ExtProperties,
  Installment,
  Instance,
  LifeParty,
  LifeRiskInsuredData,
  Party,
  PolicyPremium,
  PolicyRole,
  Proposal,
  Questionnaire,
  QuestionnaireValue,
  QuotationMessage,
  Quote,
  RiskCommission,
  RiskPremium,
  Unit
} from '../../models/policy.model';
import { ErrorType, LifeIssueMessage } from '../../models/response.model';
import { RelatedSubject, Subject } from '../../models/subject.model';
import { RoutableComponent } from '../../routable-component';
import { ActionsService } from '../../services/actions.service';
import { AssignmentHolderService } from '../../services/assignment-holder.service';
import { BeneficiaryService } from '../../services/beneficiary.service';
import { LicCacheService } from '../../services/lic-cache.service';
import { LifeRoleService } from '../../services/life-role.service';
import { LifeSessionService } from '../../services/life-session-service';
import { PolicyService } from '../../services/policy-service';
import { QuotationValidationService } from '../../services/quotation-validation.service';
import { ValidationSubjectsService } from '../../services/validation-subjects.service';
import { LicDateUtils } from '../../utils/lic-date-utils';
import { LicErrorsUtils } from '../../utils/lic-errors-utils';
import { LicRolesUtils } from '../../utils/lic-roles-utils';
import { LicIssueModalErrorComponent } from '../issue/issue-modal-error/issue-modal-error.component';
import { ChoiceBeneficiaryData, CustomProperties, Risks } from './../../models/policy.model';
import { PAYMENT_FIELDS_CODES } from '../../models/adapterPayments.model';
import { RgiCtryLayerNumFormatterPipe } from '@rgi/rgi-country-layer';
import { LayeredRuleService } from '../../services/layered-rule.service';
import {UnitsGroupComponent} from './units/units-group/units-group.component';
import { LicQuotationUtils } from '../../utils/lic-quotation-utils';
import { UnitsComponent } from './units/units.component';
import { PartyCheck } from '../../models/party-service.model';
import { LicObjectUtils } from '../../utils/lic-object-utils';
import {LifeHttpErrorMessageHandlerService} from '../../services/http/handlers/life-http-error-message-handler.service';

@Component({
  selector: 'lic-quotation',
  templateUrl: './lic-quotation.component.html',
  styleUrls: ['./lic-quotation.component.css'],
  encapsulation: ViewEncapsulation.None,
  providers: [RgiCtryLayerNumFormatterPipe]
})
export class LicQuotationComponent implements OnInit, RoutableComponent, OnDestroy, AfterViewInit {
  public locale = 'it-IT';

  @Output() navigation = new EventEmitter<string>();
  @Output() eventPropagation = new EventEmitter<any>();
  @Input() typeU: string;
  @ViewChild(QuestionnairesManagerComponent) qMc: QuestionnairesManagerComponent;
  @ViewChild(UnitsComponent) units: UnitsComponent;
  @ViewChild(UnitsGroupComponent) unitsGrouped: UnitsGroupComponent;

  productCode: string;
  effectiveDate: any;
  ppevoQuestKey: string;
  productStructure: any;
  protected $subscriptions: Subscription[] = [];

  productBeneficiaries: Beneficiary[] = [];
  unitBeneficiaries: Beneficiary[] = [];
  showBeneficiaries = false;
  showBenefSevereDisability = false;
  allCategories: any[];
  beneficiaryData = null;
  insuredValue: PolicyRole;
  messageFromPreviusStep = [];
  quotationActionMessages: QuotationMessage[] = [];
  submitted = false;
  inst: Installment;
  mapSubj = new Map<string, Subject>();
  annualPremiumValue: PolicyPremium;
  showRefTerzo = false;
  showVincolatario = false;
  thirdRefCode = Roles.THIRDREFERENT;
  vincolCode = Roles.VINCOLATARIO;
  haveToCheckRoles = false;
  public operator;

  messageFromPreviousPage: LifeIssueMessage[] = [];

  benMorteNoAssic;
  benMorteNoRef;
  benefPresente;
  refTerzoNoAssic;
  refTerzoNoContr;
  refTerzoPresente;


  /* beneficiari da anagrafe */
  minAnagSugject = new Map<string, number>();
  maxAnagSugject = new Map<string, number>();
  // subjectBen: any;

  /* FORM */
  quotationForm: UntypedFormGroup = new UntypedFormGroup({});
  beneficiaryForm: UntypedFormGroup = new UntypedFormGroup({});
  unitForm: UntypedFormGroup = new UntypedFormGroup({});
  public assignmentHolderForm: UntypedFormGroup = new UntypedFormGroup({
    openDate: new UntypedFormControl(),
    closeDate: new UntypedFormControl(),
    assignmentHolderType: new UntypedFormControl(),
    contractNumber: new UntypedFormControl(),
    assignmentType: new UntypedFormControl()
  });

  /* Units */
  instances: Instance[];
  descriptionUnitMap = new Map();
  lifeRiskInsuredData: LifeRiskInsuredData[];
  lifeRiskInsuredMap = new Map();

  /* QUOTATION */
  quotatedValues = new Map<string, any>();
  allowQuotation: boolean; // pilota l'attivazione del pulsante di quotazione
  unitError: boolean;
  beneficiaryStatusErrorMessages: {
    code: 'ref' | 'benef' | 'vinc' | 'previous_roles';
    message: {
        message: string;
        type: ErrorType;
    };
  }[] = [];

  /* DETAIL MODAL */
  showDetailModal = false;
  primaRata: Installment;
  damagesInstalment: DamagesInstalments[];
  damageRisk: DamageRisk[];

  /* WARRANTIES DETAIL MODAL */
  showGarDetailModal = false;
  riskPremium: RiskPremium[];
  policyPremium: PolicyPremium;
  sumExtraPremium: 0;
  warrantiesDescr = new Map<string, string>();

  /* FORMULE MODAL */
  showFormuleModal = false;
  formuleVector = [];
  vectorInsuredRisk = [];
  formuleModalForm = new UntypedFormGroup({});
  showFormuleRecalculate = false;

  /* PROV MODAL */
  showProvModal = false;
  commissionForRisk: RiskCommission[] = [];
  allCommission: { code: string, description: string, type: string, visibility: boolean }[] = [];

  /* QUESTIONARI */
  questionnairesList: Questionnaire[] = [];
  showSectionQuest = false;
  questViewMap = new Map<string, boolean>();
  questMap = new Map<string, QuestionnaireValue>();
  questForm: UntypedFormGroup;
  questFactorsArray: any[] = [];
  disabledQuestionArray: any[] = [];
  ppevoQuestionnaires: Questionnaire[] = [];
  showQuestPPEVO = false;
  isQuestAccordionOpen = true;
  prevalByQuestion: Array<{ section: string, question: string, value: string }> = [];

  /* COMMON RISK MODAL */
  public showCommonRiskModal = false;
  public commonRisk: Risks[];
  public commonRiskTable: CommonRiskRow[];
  HTTP_STATUS_CODE_BUSINESS_ERROR = 422;

  private _validQuestsCode: Map<string, boolean> = new Map<string, boolean>();
  public proposalNumber: string;
  public assignmentData: AssignmentHolderResp;
  public showAssignmentFields: boolean;
  public regProperty: boolean;

  private readonly THIRD_PARTY_MSG_CODE = 'ref';
  private readonly BENEF_MSG_CODE = 'benef';
  private readonly UNIT_MSG_CODE = 'units';
  private readonly QUOTE_MSG_CODE = 'quote';
  private readonly VINCOLATARIO_MSG_CODE = 'vinc';
  private readonly SUBMIT_MSG_CODE = 'submit';

  get validQuestsCode(): Map<string, boolean> {
    return this._validQuestsCode;
  }

  get disableFormule(): boolean {
    return !this.formuleVector.filter(vector => vector.visible).length;
  }

  get isFromPreventive(): boolean {
    return this.policyService.isFromPreventive || this.policyService.isFromQuoteModification;
  }

  get getWarningMessages(): string[] {
    return this.quotValidation.getAllWarning()
      .concat(this.quotValidation.getAllInfo())
      .concat(this.getWarningMessagesOLD())
      .concat(LicErrorsUtils.getPreviousPageMessages(this.messageFromPreviousPage, ErrorType.WARNING))
      .map((m) => m.message);
  }

  get getBlockingMessages(): string[] {
    return this.quotValidation.getAllBlocking()
      .concat(LicErrorsUtils.getPreviousPageMessages(this.messageFromPreviousPage, ErrorType.ERROR))
      .map((m) => m.message);
  }

  get getAuthMessages(): string[] {
    return this.quotValidation.getAllAuth()
      .concat(this.getAuthoMessages())
      .concat(LicErrorsUtils.getPreviousPageMessages(this.messageFromPreviousPage, ErrorType.AUTH))
      .map((m) => m.message);
  }

  get thereAreBlockingErrorsInPage() {
    return this.quotValidation.getAllBlocking().length > 0;
  }

  constructor(
    public policyService: PolicyService,
    protected layeredRuleService: LayeredRuleService,
    protected assignmentHolderService: AssignmentHolderService,
    protected lifeSessionService: LifeSessionService,
    protected quotValidation: QuotationValidationService,
    protected lifeRoleService: LifeRoleService,
    protected cardsNavigationService: CardsNavigationService,
    protected fb: UntypedFormBuilder,
    protected cRef: ChangeDetectorRef,
    protected validationService: ValidationSubjectsService,
    protected actionService: ActionsService,
    protected translateService: TranslationWrapperService,
    protected beneficiaryService: BeneficiaryService,
    protected cacheInvestments: LicCacheService,
    protected modalService: NgbModal,
    protected lifeHttpErrorMessageHandlerService: LifeHttpErrorMessageHandlerService,
    @Optional() protected cache: QuestionnaireCacheService,
    protected el: ElementRef) {
      this.locale = this.policyService.currentLocale;
  }


  ngAfterViewInit(): void {
    // QUOTO se sto tornando indietro o se accedo in pagina ed è possibile effettuarla senza effettuare modifiche
    if (this.policyService.isQuotationDone() || (this.isQuotationAvailable())) {
      this.quote();
      this.allowQuotation = false;
    }
  }

  get showStepBeneficiaries(): boolean {
    return !this.policyService.isFromPreventive && !this.policyService.isFromQuoteModification
    && this.showBeneficiaries;
  }

  get showStepQuestionnaires(): boolean {
    return !this.policyService.isFromPreventive && !this.policyService.isFromQuoteModification
     && (this.showSectionQuest || this.showQuestPPEVO);
  }

  get showStepThirdRef(): boolean {
    return !this.policyService.isFromPreventive && !this.policyService.isFromQuoteModification
    && this.showRefTerzo;
  }

  get showAssignmentHolder(): boolean {
    return !this.policyService.isFromPreventive && !this.policyService.isFromQuoteModification
    && this.showVincolatario;
  }

  ngOnInit() {
    this.operator = this.lifeSessionService.getOperator();
    this.quotValidation.init();
    this.cardsNavigationService.resetMessages(this.cardsNavigationService.STEP.QUOTAZIONE.errorId);
    this.messageFromPreviousPage =  this.cardsNavigationService.getPreviousMessages(this.getErrorStep());
    this.setQuestFactor();
    this.policyService.quotationvisited = true;
    this.allowQuotation = true;
    this.validationService.clean();
    this.productBeneficiaries = this.policyService.mainProposal.quote.product.beneficiaries;
    this.lifeRiskInsuredData = this.policyService.mainProposal.proposal.lifeRiskInsuredData;

    if (this.policyService.mainProposal.proposal.policyPremium != null && this.getPaymentFrequencyCode()) {
      this.annualPremiumValue = this.policyService.mainProposal.proposal.policyPremium;
    }

    this.beneficiaryData = this.policyService.mainProposal.proposal.beneficiaryData;

    this.instances = this.formatUnits(this.policyService.mainProposal.quote.product.assets[0].instances);

    this.beneficiaryGroupingLogic();

    this.insuredValue = this.lifeRoleService.getPolicyInsured();
    this.getMinMaxSubj();
    this.getBeneficiariesFromUnits(this.getUnitsFromProposal(this.instances));
    this.buildBenefForm();

    this.quotationForm = this.fb.group({
      beneficiaryForm: this.beneficiaryForm,
      unitsForm: this.unitForm
    });


    const mainProposal = this.policyService.mainProposal;
    this.getQuotation(mainProposal);
    this.refTerzoVisibility();
    this.refEnteVincVisibility();
    if (this.showAssignmentHolder) {
      const entVinc = this.lifeRoleService.getAssignmentHolder();
      this.addControllForm(entVinc, 'entVincolatario', this.quotationForm);
    }

    const propVincdata = this.policyService.mainProposal.proposal.extensionData.properties
    .find(prop => prop.chiave === ExtensionProperty.ISSUE_ASSIGMENT_HOLDER);

    let assignmentDefinition = null;

    this.$subscriptions.push(
      this.assignmentHolderService.getAssignmentHolderData(
        mainProposal.quote.resourceId,
        mainProposal.quote.product.code,
        LicDateUtils.isoDateTimeToIsoDate(mainProposal.proposal.policyVersion.effectiveDate),
        'false'
      ).pipe(
        switchMap(resp => {
          assignmentDefinition = resp;
          if (!!propVincdata) {
            this.assignmentData = JSON.parse(propVincdata.valore);
            this.assignmentHolderForm.get('closeDate').setValue(!!this.assignmentData.closeDate ? this.assignmentData.closeDate : '');
            this.assignmentHolderForm.get('assignmentHolderType')
              .setValue(!!this.assignmentData.assignmentHolderType ? this.assignmentData.assignmentHolderType : '');
            this.assignmentHolderForm.get('contractNumber')
              .setValue(!!this.assignmentData.contractNumber ? this.assignmentData.contractNumber : '');
          }

          const entVincObjId = this.getAssignmentHolderObjectID();
          return this.lifeRoleService.getSubjectFromAnag(entVincObjId, this.lifeSessionService.idPv);
        })
      )
        .subscribe(entVinc => {
          if (entVinc) {
            this.handleVincSubjectReceved(entVinc.subject);
            this.showAssignmentFields = true;
          }
          this.createAssignmentHolderDataForm(assignmentDefinition);
        })
    );

    /* QUESTIONNAIRES */
    this.questForm = new UntypedFormGroup({});

    this.policyService.mainProposal.quote.product.assets[0].instances[0].sections.map((s) => {
      s.units.map((u) => {
        u.instances[0].questionnaires.map((q) => {
          this.questViewMap.set(q.code, q.included);
        });
      });
    });

    /* QUESTIONNAIRES PP-EVO */
    this.productCode = this.policyService.mainProposal.quote.product.code;
    this.effectiveDate = this.policyService.mainProposal.quote.effectiveDate;
    this.productStructure = this.policyService.mainProposal.quote.product;
    this.ppevoQuestKey = this.generateQuestKey();
    this.checkCacheQuestionnaire();

    this.setQuestionnaireList(true);

    if (this.cache) {
      const quests = this.cache.get(this.ppevoQuestKey);
      if (quests && quests.size > 0) {
        this.showQuestPPEVO = true;
      }
    }

    this.setShowRegolamento41();
    this.setShowBenefSevereDisability();
  }

  protected beneficiaryGroupingLogic() {
    // eslint-disable-next-line max-len
    this.beneficiaryService.positionNumberProp = this.policyService.getPropertyFromProduct(LicCustomProperties.POSITION_NUMBER_FOR_BENEFICIARIES);
    if (this.beneficiaryService.positionNumberProp && !!this.beneficiaryService.positionNumberProp.values.length) {
      this.beneficiaryService.benefGroupingTypes.activeTypes = this.beneficiaryService.positionNumberProp.values as any;
      this.beneficiaryService.benefGroupingTypes.types = this.lifeSessionService.otherEnums
        .find(e => e.code === ENUMS_KEYS.EPOSITIONNUMBER.EN || e.code === ENUMS_KEYS.EPOSITIONNUMBER.IT) || [] as any;
    }
  }

  protected getErrorStep(): number {
    return this.cardsNavigationService.STEP.QUOTAZIONE.pos;
  }

  getAssignmentHolderObjectID() {
    if (!this.quotationForm.get('entVincolatario')) {
      return null;
    }
    const controlVinc = this.quotationForm.get('entVincolatario').get('val').value;
    if (!controlVinc && !!this.assignmentData && !!this.assignmentData.assignmentHolderSubject) {
      return this.assignmentData.assignmentHolderSubject;
    } else {
      return !!controlVinc ? controlVinc.objectId : null;
    }
  }

  getSavedUUIDs(): string[] {
    return this.policyService.getPassProProSavedQuestionnaireUUIDs(null);
  }

  setValidationMessages() {
    // Error Messages Beneficiary
    this.benMorteNoAssic = this.translateService.getImmediate('lic_benMorteNoAssic');
    this.benMorteNoRef = this.translateService.getImmediate('lic_benMorteNoRef');
    this.benefPresente = this.translateService.getImmediate('lic_benefPresente');
    // Error messages Referent
    this.refTerzoNoAssic = this.translateService.getImmediate('lic_refTerzoNoAssic');
    this.refTerzoNoContr = this.translateService.getImmediate('lic_refTerzoNoContr');
    this.refTerzoPresente = this.translateService.getImmediate('lic_refTerzoPresente');
  }

  // necessario per visualizzare i questionari (PP+PPEVO) solo al termine del caricamento dei quest ppEvo
  countTotalQuest() {
    this.showQuestPPEVO = (this.qMc.getQuest().length + this.ppevoQuestionnaires.length) > 0;
  }

  createAssignmentHolderDataForm(assignmentHolderResp: AssignmentHolderResp) {
    if (this.showAssignmentHolder && !!assignmentHolderResp) {
      this.assignmentData = assignmentHolderResp;
      const openDate = !!assignmentHolderResp.openDate ? assignmentHolderResp.openDate.value.split('/').reverse().join('/') : '';
      this.assignmentHolderForm.get('openDate')
        .setValue(!!assignmentHolderResp.openDate ? PassUtils.getIsoDateFromString(openDate) : '');
      this.assignmentHolderForm.get('assignmentType')
        .setValue(!!assignmentHolderResp.assignmentType ? assignmentHolderResp.assignmentType.value : '');
    }
  }

  onEmit(event: any) {
    this.eventPropagation.emit(event);
  }

  setQuestionnaireList(onInit: boolean) {
    this.policyService.mainProposal.quote.product.assets[0].instances[0].sections.map((s) => {
      s.units.map((u) => {
        u.instances[0].questionnaires
          .map((questionnaire: Questionnaire) => {
            if (questionnaire.codeType !== 'QPPEVO') {
              const questPosition = this.questionnairesList.findIndex(q => q.code === questionnaire.code);
              if (-1 === questPosition && questionnaire.included) {
                this.questionnairesList.push(questionnaire);
              } else {
                if (questPosition >= 0) {
                  if (questionnaire.included) {
                    this.questionnairesList[questPosition] = questionnaire;
                  } else {
                    this.questionnairesList.splice(questPosition, 1);
                  }
                }
              }
            } else {
              const questPPEvoPosition = this.ppevoQuestionnaires.findIndex(q => q.code === questionnaire.code);
              if (-1 === questPPEvoPosition && questionnaire.included) {
                this.ppevoQuestionnaires.push(questionnaire);
              } else {
                if (questPPEvoPosition >= 0) {
                  if (questionnaire.included) {
                    this.ppevoQuestionnaires[questPPEvoPosition] = questionnaire;
                  } else {
                    this.ppevoQuestionnaires.splice(questPPEvoPosition, 1);
                  }
                }
              }
            }
          });
      });
    });
    this.showSectionQuest = this.questionnairesList.length > 0;

    this._validQuestsCode = new Map<string, boolean>();
    this.ppevoQuestionnaires.forEach((passQuest: Questionnaire) => this._validQuestsCode.set(passQuest.code, passQuest.compulsory));
    this.showQuestPPEVO = this.ppevoQuestionnaires.length > 0;
    if (this._validQuestsCode.size > 0 && !onInit) {
      if (this.cache && !this.cache.get(this.ppevoQuestKey)) {
        this.qMc.loadQuestionnairesByCode(this.validQuestsCode);
      } else {
        this.cache.setMandatory(this.validQuestsCode);
      }
    } else {
      if (!onInit) {
        this.cache.delete(this.ppevoQuestKey);
        if (this.qMc && this.qMc.questionnaireManagerService) {
          this.qMc.questionnaireManagerService.listChange.emit(this.qMc.questionnaireManagerService.getQuestionnaires());
        }
      }
    }
  }

  public showSaveButton() {

    return !this.policyService.hideSaveButton();
  }

  checkCacheQuestionnaire(){
    if (this.policyService.mainProposal.quote.product.assets[0].instances.length > 0) {
      this.questionnairesList = this.policyService.mainProposal.quote.product.assets[0].instances[0].questionnaires
        .filter((quest: Questionnaire) => quest.included === true && quest.codeType !== 'QPPEVO');
    }
    this.policyService.mainProposal.quote.product.assets[0].instances.forEach((inst: Instance) => {
      if (this.cache && !this.cache.get(inst.name)) {
        this.ppevoQuestionnaires = this.policyService.mainProposal.quote.product.assets[0].instances[0].questionnaires
          .filter((quest: Questionnaire) => quest.included === true && quest.codeType === 'QPPEVO');
      }
      if (!this.showQuestPPEVO) { // restoring cache to reload the correct status
        this.cache.delete(this.ppevoQuestKey);
      }
    });

    this.showQuestPPEVO = this.ppevoQuestionnaires.length > 0;
    this.showSectionQuest = this.questionnairesList.length > 0;
  }

  setShowRegolamento41() {
    const propertyReg41: CustomProperties = this.policyService.getPropertyFromProduct(LicCustomProperties.REGOLAMENTO_41);
    this.regProperty = propertyReg41 && propertyReg41.value === 'true';
  }

  setShowBenefSevereDisability() {
    // Property Disabilità Grave
    const propertySevereDisability: CustomProperties = this.policyService.getPropertyFromProduct(LicCustomProperties.SEVERE_DISABILITY);
    this.showBenefSevereDisability = propertySevereDisability && propertySevereDisability.value === 'true';
  }


  refTerzoVisibility() {
    const refTerzoCP = this.policyService.getPropertyFromProduct(LicCustomProperties.THIRD_REF_VISIBILITY);
    if (refTerzoCP && refTerzoCP.value === 'true') {
      this.showRefTerzo = true;
    }
  }

  refEnteVincVisibility() {
    const entVincolatarioCP = this.policyService.getPropertyFromProduct(LicCustomProperties.CONTRACTING_ENTITY_VISIBILITY);
    if (!!entVincolatarioCP && entVincolatarioCP.value === 'true') {
      this.showVincolatario = true;
    }
  }

  getUnitsFromProposal(instances) {
    const unitsArray = [];
    instances[0].sections.map((section) => {
      section.units.map((unit) => {
        if (unit.selection === true) {
          unitsArray.push(unit.code);
        }
        if (unit.subUnits != null) {
          unit.subUnits.map((subUnit) => {
            if (subUnit.selection === true) {
              unitsArray.push(subUnit.code);
            }
          });
        }
      });
    });
    return unitsArray;
  }

  fill(subject: any): string {
    if (subject != null) {
      if (subject.name != null) {
        return subject.surname + ' ' + subject.name;
      } else if (subject.denomination != null) {
        return subject.denomination;
      } /* else if (subject.companyName != null) {
        return subject.companyName;
      } */
    }
    return null;
  }


  getMinMaxSubj() {
    this.policyService.mainProposal.quote.product.beneficiaries.map((cat) => {
      if (cat.minSubjectsAnagraphicalNumber != null && cat.code === BENEFICIARY_CHOICE.ANAGRAFICAL_BENEF) {
        this.minAnagSugject.set(String(cat.beneficiaryType), +cat.minSubjectsAnagraphicalNumber);
      }
      if (cat.maxSubjectsAnagraphicalNumber != null && cat.code === BENEFICIARY_CHOICE.ANAGRAFICAL_BENEF) {
        this.maxAnagSugject.set(String(cat.beneficiaryType), +cat.maxSubjectsAnagraphicalNumber);
      }
    });

    this.policyService.mainProposal.quote.product.assets[0].instances[0].sections.map((sec) => {
      sec.units.map((uni) => {
        uni.beneficiaries.map((ben) => {
          if (ben.minSubjectsAnagraphicalNumber != null && ben.code === BENEFICIARY_CHOICE.ANAGRAFICAL_BENEF) {
            this.minAnagSugject.set(String(ben.beneficiaryType), +ben.minSubjectsAnagraphicalNumber);
          }
          if (ben.maxSubjectsAnagraphicalNumber != null && ben.code === BENEFICIARY_CHOICE.ANAGRAFICAL_BENEF) {
            this.maxAnagSugject.set(String(ben.beneficiaryType), +ben.maxSubjectsAnagraphicalNumber);
          }
        });
      });
    });
  }

  /*  getMessages(event: any) {
     this.validationMessages = event;
   } */

  /* BENEF */

  createBenefOptions(beneficiaries: Beneficiary[]) {
    Object.keys(this.beneficiaryForm.controls).forEach((key) => {
      if (key !== 'nuovoRefTerzo') {
        this.beneficiaryForm.removeControl(key);
      }
    });

    this.showBeneficiaries = true;
    const groups = new Set(beneficiaries.map(item => item.beneficiaryType));
    this.allCategories = [];
    groups.forEach(g => {
      // let a viarable to get description TypeBeneficiary by service of Enums
      let dscBN: string;
      this.lifeSessionService.enumTypeBeneficiary.map((t) => {
        if (Number(t.codice) === Number(g)) {
          dscBN = t.descrizione;
          this.beneficiaryService.setBenefDescription(t.codice, t.descrizione);
        }
      });

      // Create object ITEM for any select to populate each select with their beneficiaries (filter by typeBeneficiary)
      const benefObject = this.createBenefFormControl(g, dscBN, beneficiaries);

      const cathegoryHasOneOption = !!benefObject.values && benefObject.values.length === 1;
      if (cathegoryHasOneOption) {
        if (!(benefObject.choiceValue as UntypedFormControl).value) {
          (benefObject.choiceValue as UntypedFormControl).setValue(benefObject.values[0].code);
        }
        (benefObject.choiceValue as UntypedFormControl).disable();
      }

      // VALORIZZO PRENDENDO I VALORI DALLA PROPOSAL
      if (this.beneficiaryData != null && this.beneficiaryData.choiceBeneficiaryData != null) {
        const lastValue = this.beneficiaryData.choiceBeneficiaryData.filter((b) => Number(b.typeBeneficiary.codice) === Number(g));
        // lastValue ora può avere più di 1 elemento
        lastValue.forEach(scelta => {
          this.prevalBenefItem(benefObject, scelta);
        });
        // aggiungo un control vuoto se è possibile inserire un'altro beneficiario
        if (benefObject.fromAnag.length < this.maxAnagSugject.get(g)) {
          benefObject.fromAnag.push(this.createSubjectBenefFormGroup(null, null));
        }
      } else {
        // Gestione default beneficiari per tipologia
        const defaultBenef: Beneficiary = benefObject.values.find(benef => benef.defaultValue === true);
        if (defaultBenef) {
          benefObject.choiceValue.setValue(defaultBenef.code);
        }
      }


      this.allCategories.push(benefObject);

      // Sort beneficiaries's select by beneficiaryType
      this.allCategories = this.allCategories.sort((a, b) => {
        return a.beneficiaryType.codice - b.beneficiaryType.codice;
      });
      // Sort alphabetically each beneficiaries's list inside seletc by description
      benefObject.values.sort((a, b) => {
        if (a.description < b.description) {
          return -1;
        }
        if (a.description > b.description) {
          return 1;
        }
        return 0;
      });
      this.beneficiaryForm.addControl(g, this.fb.group(benefObject));
    });


  }

  createBenefFormControl(codice: string, descrizione: string, beneficiaries: Beneficiary[]) {
    return {
      beneficiaryType: { codice: String(codice), descrizione },
      values: beneficiaries.filter(i => i.beneficiaryType === codice),
      choiceValue: new UntypedFormControl(undefined, Validators.required),
      freeText: new UntypedFormControl(''),
      fromAnag: new UntypedFormArray([])
    };
  }

  createSubjectBenefFormGroup(subj, scelta) {
    // serve per sovrascriverla in EXT
    return LicRolesUtils.createSubjectBenefFormGroup(subj, scelta, this.beneficiaryService.benefGroupingTypes);
  }

  public prevalBenefItem(benefObject, scelta) {
    (benefObject.choiceValue as UntypedFormControl).setValue(scelta.choiceTypeBeneficiary);
    if (!!scelta.beneficiaryLifeParty && !!scelta.beneficiaryLifeParty.party) {
      let subj = null;
      if (scelta.beneficiaryLifeParty.party.objectID != null) {
        if (scelta.beneficiaryLifeParty.physicalLegal === 1) {
          const nomeCognome = scelta.beneficiaryLifeParty.party.extraInformations.split(' ');
          subj = {
            name: nomeCognome[0],
            surname: nomeCognome[1],
            objectId: scelta.beneficiaryLifeParty.party.objectID,
            personType: { codice: scelta.beneficiaryLifeParty.physicalLegal }
          };
        } else {
          subj = {
            denomination: scelta.beneficiaryLifeParty.party.extraInformations,
            objectId: scelta.beneficiaryLifeParty.party.objectID,
            personType: { codice: scelta.beneficiaryLifeParty.physicalLegal }
          };
        }
        // caso beneficiario
        const nuovoVal = this.createSubjectBenefFormGroup(subj, scelta);

        // sottolista beneficiario
        this.mapSubj.set(scelta.typeBeneficiary.codice + '-' + subj.objectId, subj);
        benefObject.fromAnag.push(nuovoVal);
      } else {
        // caso testo libero
        benefObject.freeText.setValue(scelta.beneficiaryLifeParty.party.extraInformations);
      }
    }
  }

  deleteRef(event) {
    this.policyService.mainProposal.proposal.lifeParty = this.policyService.mainProposal.proposal.lifeParty.filter(
      (el) => el.party.partyRole !== this.thirdRefCode);
    this.beneficiaryForm.get('nuovoRefTerzo').get('val').setValue(null);
    this.lifeRoleService.cleanThirdRef();
  }

  deleteVinc(event) {
    this.policyService.mainProposal.proposal.lifeParty = this.policyService.mainProposal.proposal.lifeParty.filter(
      (el) => el.party.partyRole !== this.vincolCode);
    this.quotationForm.get('entVincolatario').get('val').setValue(null);
    this.lifeRoleService.cleanAssignmentHolder();
    this.resetAssignmentFields();
    this.showAssignmentFields = false;
  }

  resetAssignmentFields() {
    this.assignmentHolderForm.reset();
    this.createAssignmentHolderDataForm(this.assignmentData);
  }

  buildBenefForm() {
    if (this.productBeneficiaries.length > 0) {
      this.createBenefOptions(this.productBeneficiaries);
    } else {
      if (this.unitBeneficiaries.length > 0) {
        this.createBenefOptions(this.unitBeneficiaries);
      }
    }
    // REFERENTE TERZO
    const refTerzo = this.lifeRoleService.getPolicyThirdRef();
    this.addControllForm(refTerzo, 'nuovoRefTerzo', this.beneficiaryForm);
  }

  addControllForm(role: PolicyRole , name: string, form: UntypedFormGroup) {
    let ref = null;
    if (!!role) {
      if (role.personType === '1') {
        ref = {
          name: role.name,
          surname: role.surname,
          objectId: role.objectId,
          personType: {codice: role.personType}
        };
      } else {
        ref = {
          denomination: role.companyName,
          objectId: role.objectId,
          personType: {codice: role.personType}
        };
      }
    }
    form.addControl(name, new UntypedFormGroup({
      val: new UntypedFormControl(ref)
    }));
  }


  changeValueBenef(cat) {
    this.quotValidation.clear(this.BENEF_MSG_CODE + '-0-' + cat);
    (this.beneficiaryForm.get(cat).get('fromAnag') as UntypedFormArray).clear();
    this.removeBenefByCategory(cat);
  }

  removeBenefByCategory(categoryToRemove: string): void {
    const keysToRemove = [...this.mapSubj.keys()].filter(key => key.startsWith(`${categoryToRemove}-`));
    keysToRemove.forEach(key => this.mapSubj.delete(key));
}

  checkFormBenef(): boolean {
    this.quotValidation.clear(this.BENEF_MSG_CODE);
    Object.keys(this.beneficiaryForm.controls).forEach((cat) => {
      if (cat !== 'nuovoRefTerzo') {
        const formType = this.beneficiaryForm.get(cat) as UntypedFormGroup;
        const selectionForCategory = formType.get('choiceValue').value;
        if (!selectionForCategory) {
          this.quotValidation.setMessage(this.BENEF_MSG_CODE, {
            message: this.translateService.getImmediate('lic_mandatory_benef', {cathegory: this.beneficiaryService.getBenefDescription(cat)}),
            type: ErrorType.ERROR
          });
        } else if (selectionForCategory === BENEFICIARY_CHOICE.ANAGRAFICAL_BENEF) {
          this.checkBeneficiariesFromAnag(formType, cat);
        } else if (selectionForCategory === BENEFICIARY_CHOICE.FREE_TEXT_BENEF) {
          if (formType.get('freeText').invalid) {
            this.quotValidation.setMessage(this.BENEF_MSG_CODE, {
              message: this.translateService.getImmediate('lic_mandatory_appendix_benef', {cathegory: this.beneficiaryService.getBenefDescription(cat)}),
              type: ErrorType.ERROR
            });
          }
        }
      }
    });

    return this.quotValidation.getBlocking(this.BENEF_MSG_CODE).length === 0;
  }

  // controlla i beneficiari da anagrafica di una categoria specifica
  // i messaggi di errore vengono caricati su quotValidation
  checkBeneficiariesFromAnag(formType: UntypedFormGroup, cat: string) {
    const anagBenef = formType.get('fromAnag') as UntypedFormArray;
    if (anagBenef.length !== 0) {
      let checkBenef = true;
      anagBenef.controls.map((benef: UntypedFormGroup, index) => {
        let subj: Subject = (formType.get('fromAnag')).get(index.toString()).get('value').value;
        if (!LicRolesUtils.isEmptyBenefControl(benef)) {
          // il metodo checkBenefPercentage (dove aggiungiamo messaggi in quote validation) va chiamato per tutti i benef
          // ma se diventa false non deve più tornare a true (per questo c'è && checkBenef come secondo controllo)
          checkBenef = this.checkBenefPercentage(benef, cat) &&
                        checkBenef &&
                        this.checkIfCedolaAndAddValidation(cat, benef) &&
                        this.checkBenefGrouping(benef, cat);
        } else {
          if (index < this.minAnagSugject.get(cat)) {
            if (!subj) {
              this.quotValidation.setMessage(this.BENEF_MSG_CODE, {
                message: this.translateService.getImmediate(
                  'lic_beneficiary_from_party_req',
                  {benefRole: this.translateService.getImmediate('lic_Beneficiary'), benefCat: this.beneficiaryService.getBenefDescription(cat)}
                ), type: ErrorType.ERROR
              });

              checkBenef = false;
            }
          }
        }
        if(!!subj){
          if(this.isBeneficiarySelectedAsInsured(cat,subj)){
            checkBenef = false;
          }
        }
      });

      // se non ci sono errori nelle singole percentuali allora controllo la somma
      if (checkBenef) {
        if (!!this.beneficiaryService.benefGroupingTypes.activeTypes.find(t => t && t.code === cat)) {
          checkBenef = this.beneficiariesPositionValidation(anagBenef, cat, checkBenef);
        } else {
          checkBenef = this.checkBenefPercentageSum(anagBenef, cat);
        }
      }

      // se non ci sono errori continuo a controllare i relatedRoles
      if (!!checkBenef && this.quotValidation.getBlocking(this.BENEF_MSG_CODE).length === 0) {
        const newErrors = this.beneficiaryService.checkPercentage();
        if (!!newErrors && newErrors.length > 0) {
          newErrors.forEach((err) => {
            this.quotValidation.setMessage(this.BENEF_MSG_CODE, {
              message: err, type: ErrorType.ERROR
            });
          });
        }
      }
    }
  }

  protected checkBenefGrouping(benefForm: UntypedFormGroup, cat: string): boolean {
    const benefValue = benefForm.get('value').value;
    if (!!benefValue && !!this.beneficiaryService.benefGroupingTypes.activeTypes.find(t => t && t.code === cat) &&
          !benefForm.getRawValue().positionNumber.positionNumberType
      ) {
        const message = this.translateService.getImmediate(
          'lic_beneficiary_position_mandatory',
          {
            benefName: this.fill(benefValue),
            benefCat: this.beneficiaryService.getBenefDescription(cat)
          }
        );
        this.quotValidation.setMessage(this.BENEF_MSG_CODE, { message, type: ErrorType.ERROR });
        return false;
      }
    return true;
  }

  private beneficiariesPositionValidation(anagBenef: UntypedFormArray, cat: string, checkBenef: boolean) {
    const groupedBeneficiaries = LicRolesUtils.groupByPositionNumberType(anagBenef);
    Object.keys(groupedBeneficiaries).forEach(groupType => {
      if (!!this.beneficiaryService.benefGroupingTypes.activeTypes.find(t => t && t.code === cat) && !JSON.parse(groupType)) {
        // checking if the grouping is active for the current iter cathegory then if 'groupType' is null means it's not Defined yet
        // there is already a check made previously on checkBenefGrouping
        return;
      }
      const totalPerGroup = groupedBeneficiaries[groupType]
      .map((benef: UntypedFormGroup) => {
        let counter = 0;
        if (benef.get('value').value != null && benef.get('percentage').value != null) {
          counter += +(benef.get('percentage').value.toString().replace(',', '.'));
        }
        return counter;
      }).reduce((accumulator: number, currentValue: number) => {
        return +accumulator + +currentValue;
      });
      if (totalPerGroup !== 100) {
        const message = this.translateService.getImmediate('lic_invalid_total_position_percentage', {
          cat: this.beneficiaryService.getBenefDescription(cat),
          groupType: this.beneficiaryService.benefGroupingTypes.types.values.find(e => e && e.codice === groupType).descrizione
        });
        this.quotValidation.setMessage(this.BENEF_MSG_CODE, {
          message,
          type: ErrorType.ERROR
        });
        checkBenef = false;
      }
    });
    return checkBenef;
  }

  private checkIfCedolaAndAddValidation(cat: string, benef: UntypedFormGroup) {
    const checkIfPaymentsValorized = benef.get('paymentInfo').valid;
    if (cat === BeneficiaryCathegory.CEDOLA && !checkIfPaymentsValorized) {
      this.quotValidation.setMessage(this.BENEF_MSG_CODE, {
        message: this.translateService.getImmediate('lic_PleaseprovideapaymentmethodtotheCouponBeneficiary'),
        type: ErrorType.ERROR
      });
      return false;
    }
    return true;
  }

  checkBenefPercentage(benefForm: UntypedFormGroup, cat: string) {
    const benefValue = benefForm.get('value').value;
    const percentage = benefForm.get('percentage').value;
    const convertedPercentage = percentage ? +this.beneficiaryService.getPercentageNormalized(percentage) : null;

    if (!!benefValue) {
      if (convertedPercentage == null) {
        this.quotValidation.setMessage(this.BENEF_MSG_CODE, {
          message: this.translateService.getImmediate(
            'lic_beneficiary_perc_req',
            {
              benefRole: this.translateService.getImmediate('lic_Beneficiary'), benefName: this.fill(benefValue),
              benefCat: this.beneficiaryService.getBenefDescription(cat)
            }
          ), type: ErrorType.ERROR
        });
        return false;
      } else if (!(convertedPercentage > 0 && convertedPercentage <= 100)) {
        this.quotValidation.setMessage(this.BENEF_MSG_CODE, {
          message: this.translateService.getImmediate(
            'lic_beneficiary_perc_req_range',
            {
              benefRole: this.translateService.getImmediate('lic_Beneficiary'), benefName: this.fill(benefValue),
              benefCat: this.beneficiaryService.getBenefDescription(cat)
            }
          ), type: ErrorType.ERROR
        });
        return false;
      }
    } else {
      // form vuoto e percentuale a zero -> non controllo niente
    }
    return true;
  }

  checkBenefPercentageSum(anagBenef: UntypedFormArray, cat: string): boolean {
    let counter = 0;
    anagBenef.controls.forEach((benef) => {
      if (benef.get('value').value != null && benef.get('percentage').value != null) {
        counter += +(benef.get('percentage').value.toString().replace(',', '.'));
      }
    });
    if (counter !== 100) {
      this.quotValidation.setMessage(this.BENEF_MSG_CODE, {
        message: this.translateService.getImmediate('lic_benef_total_perc', {cathegory: this.beneficiaryService.getBenefDescription(cat)}),
        type: ErrorType.ERROR
      });
      return false;
    }
    return true;
  }

  saveBenef() {
    this.beneficiaryData = { choiceBeneficiaryData: [] };

    // ciclo tutti i beneficiari nel form (è presente anche il referente terzo)
    Object.keys(this.beneficiaryForm.controls).forEach((category) => {
      if (category === 'nuovoRefTerzo') {
        this.saveRefTerzo(); // SALVATAGGIO REFERENTE TERZO
      } else {
        // SALVATAGGIO BENEFICIARIO
        const arrayToPush = [];
        const categoryForm = this.beneficiaryForm.get(category) as UntypedFormGroup;
        const choiceValue = categoryForm.get('choiceValue').value;
        const fromAnagArray = categoryForm.get('fromAnag') as UntypedFormArray;
        const beneficiaryType = categoryForm.get('beneficiaryType').value;
        const freeText = categoryForm.get('freeText').value;

        // salvo il flag irrevocabile (se presente)
        if (categoryForm.get('irrevocable') !== null) {
          this.beneficiaryData[categoryForm.get('irrevocabilityFieldName').value] = categoryForm.get('irrevocable').value;
        }

        if (choiceValue === BENEFICIARY_CHOICE.ANAGRAFICAL_BENEF) {
          // CASO BENEFICIARIO DA ANAGRAFE
          this.haveToCheckRoles = true;
          fromAnagArray.controls.forEach(benef => {
            if (benef.get('value').value != null) {
              arrayToPush.push(this.createChoiceBeneficiaryData(choiceValue, beneficiaryType.codice, benef));
            }
          });
        } else if (choiceValue === BENEFICIARY_CHOICE.FREE_TEXT_BENEF) {
          // CASO TESTO LIBERO
          arrayToPush.push({
            choiceTypeBeneficiary: choiceValue,
            typeBeneficiary: { codice: beneficiaryType.codice },
            insuredId: this.productBeneficiaries.length !== 0 ? null : this.insuredValue.objectId,
            beneficiaryLifeParty: {
              party: {
                extraInformations: freeText,
                partyRole: Roles.BENEFICIARY
              }
            }
          });
        } else {
          // TUTTE LE ALTRE SELEZIONI
          arrayToPush.push({
            choiceTypeBeneficiary: choiceValue,
            typeBeneficiary: { codice: beneficiaryType.codice },
            insuredId: this.productBeneficiaries.length !== 0 ? null : this.insuredValue.objectId,
            beneficiaryLifeParty: null
          });
        }

        // inserisco i beneficiari di quel gruppo indicato da CATEGORY al vettore choiceBeneficiaryData
        this.beneficiaryData.choiceBeneficiaryData = this.beneficiaryData.choiceBeneficiaryData.concat(arrayToPush);
      }
    });
    // assegno la struttura creata sulla mainProposal
    this.policyService.mainProposal.proposal.beneficiaryData = this.beneficiaryData;
  }

  saveRefTerzo() {
    const referenteTerzo = this.beneficiaryForm.get('nuovoRefTerzo').get('val') as UntypedFormGroup;
    if (referenteTerzo.value != null) {
      const party = new Party();
      party.setPartyRole('300015');
      party.setObjectId(referenteTerzo.value.objectId);
      if (referenteTerzo.value.personType.codice === '1') {
        party.setExtraInformations(referenteTerzo.value.surname + ' ' + referenteTerzo.value.name);
      } else {
        party.setExtraInformations(referenteTerzo.value.denomination);
      }
      const refTerzo = new LifeParty(party);
      refTerzo.physicalLegal = referenteTerzo.value.personType.codice;
      const refTerzoFromProposal = this.policyService.mainProposal.proposal.lifeParty.find((lifeparty) =>
        lifeparty.party.partyRole === '300015');
      if (refTerzoFromProposal == null) {
        this.policyService.mainProposal.proposal.lifeParty.push(refTerzo);
      }
    }
  }

  createChoiceBeneficiaryData(choiceValue, beneficiaryTypeCode, benef) {
    const choiceBeneficiaryData: any = {
      choiceTypeBeneficiary: choiceValue,
      typeBeneficiary: { codice: beneficiaryTypeCode },
      insuredId: this.productBeneficiaries.length > 0 ? null : this.insuredValue.objectId,
      beneficiaryLifeParty: this.createBenefLifeParty(benef, beneficiaryTypeCode),
      extProperties: this.getBeneficiaryDataExtProperties(choiceValue, beneficiaryTypeCode, benef),
      payments: [this.getBeneficiaryDataPayment(beneficiaryTypeCode, benef)]
    };
    return choiceBeneficiaryData;
  }


  getBeneficiaryDataPayment(beneficiaryTypeCode: any, benefForm: any): Payments {
    if (beneficiaryTypeCode === BeneficiaryCathegory.CEDOLA) {
      const objId = benefForm.get('value').value.objectId;
      if (!this.beneficiaryService.getPaymentCedola(objId)) {
        return null;
      }
      /* eslint-disable max-len */
      this.beneficiaryService.getPaymentCedola(objId).iban = benefForm.get('paymentInfo').get(PAYMENT_FIELDS_CODES.IBAN).value;
      this.beneficiaryService.getPaymentCedola(objId).checkNumber = benefForm.get('paymentInfo').get(PAYMENT_FIELDS_CODES.CHECK_NUMBER).value;
      this.beneficiaryService.getPaymentCedola(objId).bankAccountOwner = benefForm.get('paymentInfo').get(PAYMENT_FIELDS_CODES.HOLDER).value;
      this.beneficiaryService.getPaymentCedola(objId).creditCardNumber = benefForm.get('paymentInfo').get(PAYMENT_FIELDS_CODES.CREDIT_CARD).value;
      this.beneficiaryService.getPaymentCedola(objId).cardExpirationDate = benefForm.get('paymentInfo').get(PAYMENT_FIELDS_CODES.CREDIT_CARD_EXPIRY).value;
      return this.beneficiaryService.getPaymentCedola(objId);
    }
    return null;
  }

  getBeneficiaryDataExtProperties(choice: string, benefType: string, benefForm: any): ExtProperties[] {
    const v = [];
    if (this.regProperty && choice === BENEFICIARY_CHOICE.ANAGRAFICAL_BENEF && benefType === BeneficiaryCathegory.VITA) {
      v.push({ chiave: LicCustomProperties.REGOLAMENTO_41, valore: benefForm.get('_reg41').value });
    } else if (!this.regProperty && choice === BENEFICIARY_CHOICE.ANAGRAFICAL_BENEF && benefType === BeneficiaryCathegory.VITA) {
      v.push({ chiave: LicCustomProperties.REGOLAMENTO_41, valore: false });
    }
    if (benefForm.get('value').value.personType.codice === SubjectConstants.PHYSICAL_SUBJECT && this.showBenefSevereDisability &&
      benefType === BeneficiaryCathegory.MORTE) {
      v.push({ chiave: LicCustomProperties.SEVERE_DISABILITY, valore: benefForm.get('severeDisability').value });
    }
    if (!!this.beneficiaryService.benefGroupingTypes.activeTypes.find(t => t && t.code === benefType)) {
        if (benefForm.get('positionNumber').get('positionNumberType').value !== null  ) {
          v.push({ chiave: LicCustomProperties.POSITION_NUMBER_FOR_BENEFICIARIES, valore: benefForm.get('positionNumber').get('positionNumberType').value });
      }
    }
    return v;
  }

  createBenefLifeParty(benef: AbstractControl, beneficiaryType) {
    return {
      party: {
        extraInformations: this.fill(benef.get('value').value),
        partyRole: Roles.BENEFICIARY,
        objectID: benef.get('value').value.objectId
      },
      physicalLegal: benef.get('value').value.personType.codice,
      percentageParty: !!benef.get('percentage').value ? +(benef.get('percentage').value.toString().replace(',', '.')) : null,
      linkedLifeParties: this.beneficiaryService.getRelatedSubjectRequest(beneficiaryType, benef.get('value').value.objectId)
    };
  }

  showFreeText(beneficiaryType) {
    beneficiaryType.codice = String(beneficiaryType.codice);
    if (this.beneficiaryForm.get(beneficiaryType.codice).value.choiceValue === BENEFICIARY_CHOICE.FREE_TEXT_BENEF) {
      this.beneficiaryForm.get(beneficiaryType.codice).get('freeText')
        .setValidators([Validators.required, Validators.pattern('^(?!\\s*$).+')]);
      this.beneficiaryForm.get(beneficiaryType.codice).get('freeText').updateValueAndValidity();
      return true;
    } else {
      this.beneficiaryForm.get(beneficiaryType.codice).get('freeText').clearValidators();
      this.beneficiaryForm.get(beneficiaryType.codice).get('freeText').updateValueAndValidity();
      return false;
    }
  }

  showSelectBenef(beneficiaryType) {
    return this.beneficiaryForm.get(beneficiaryType.codice).get('choiceValue').value === BENEFICIARY_CHOICE.ANAGRAFICAL_BENEF;
  }

  /* UNIT */

  formatUnits(i) {
    const instancesChanged = JSON.parse(JSON.stringify(i));
    this.descriptionUnitMap.clear();

    instancesChanged.map((inst) => {
      inst.sections.map((sect) => {
        sect.units.map((unit) => {
          this.descriptionUnitMap.set(unit.code, unit.description);
          if (unit.base) {
            unit.subUnits = [];
            sect.units.map((sUnit) => {
              if (sUnit.baseCode === unit.code) {
                unit.subUnits.push(sUnit);
              }
            });
          } else if (!unit.base && !unit.life) { // UNIT DANNI
            unit.subUnits = [];
            // noin dovrebbero avere sotto unit, però meglio controllare e renderla uniforme al vita
            sect.units.map((sUnit) => {
              if (sUnit.baseCode === unit.code) {
                unit.subUnits.push(sUnit);
              }
            });
          }
        });
        sect.units = sect.units.filter(u => u.base || (!u.base && u.baseCode == null));
      });
    });
    return instancesChanged;
  }

  getBeneficiariesFromUnits(unitsSelected: string[]) {
    const unitBeneficiariesMap = new Map();
    this.policyService.mainProposal.quote.product.assets.map((asset) => {
      asset.instances[0].sections.map((section) => {
        section.units.map((unit) => {
          if (this.isUnitSelected(unit.code, unitsSelected)) {
            unit.beneficiaries.map((beneficiary) => {
              const benef = new Beneficiary();
              benef.code = beneficiary.code;
              benef.beneficiaryType = String(beneficiary.beneficiaryType);
              benef.description = beneficiary.description;
              benef.extendedDescription = beneficiary.extendedDescription;
              benef.maxSubjectsAnagraphicalNumber = beneficiary.maxSubjectsAnagraphicalNumber;
              benef.minSubjectsAnagraphicalNumber = beneficiary.minSubjectsAnagraphicalNumber;
              benef.defaultValue = beneficiary.defaultValue;
              unitBeneficiariesMap.set(beneficiary.code + benef.beneficiaryType, benef);
            });
          }
        });
      });
    });
    this.unitBeneficiaries = [...unitBeneficiariesMap.values()];
  }

  isUnitSelected(code: string, unitsSelected: string[]): boolean {
    let present = false;
    unitsSelected.forEach((el) => {
      if (el === code) {
        present = true;
      }
    });
    return present;
  }

  getUnitsChecked(unitChanged?: string): Map<string, boolean> {
    const mapVal = new Map<string, boolean>();
    Object.keys(this.unitForm.controls).forEach((inst) => {
      const formInst = this.unitForm.get(inst) as UntypedFormGroup;
      Object.keys(formInst.controls).forEach((sez) => {
        const formSez = formInst.get(sez) as UntypedFormGroup;
        Object.keys(formSez.controls).forEach((unit) => {
          const formUnit = formSez.get(unit) as UntypedFormGroup;
          if (unitChanged != null && unit === unitChanged) {
            mapVal.set(unit, !formUnit.getRawValue().selection);
          } else {
            mapVal.set(unit, formUnit.getRawValue().selection);
          }
          Object.keys((formUnit.get('subUnits') as UntypedFormGroup).controls).forEach((subUnit) => {
            const formSubUnit = formUnit.get('subUnits').get(subUnit) as UntypedFormGroup;
            if (unitChanged != null && subUnit === unitChanged) {
              mapVal.set(subUnit, !formSubUnit.getRawValue().selection);
            } else {
              mapVal.set(subUnit, formSubUnit.getRawValue().selection);
            }
          });
        });
      });
    });
    return mapVal;
  }

  saveUnit(unitChanged: string) {
    const mapVal = this.getUnitsChecked(unitChanged);
    // ciclo la proposal e aggiorno i valori
    this.policyService.mainProposal.quote.product.assets[0].instances.map((inst) => {
      inst.sections.map((sect) => {
        sect.units.map((unit) => {
          unit.manuallySet = true;
          if (unit.selection !== mapVal.get(unit.code)) {
            unit.selection = mapVal.get(unit.code);
          }

          let factorsForm = null;
          if (unit.baseCode != null) {
            if (this.unitForm.get(inst.name).get(sect.code).get(unit.baseCode)) {
              factorsForm = this.unitForm.get(inst.name).get(sect.code).get(unit.baseCode).get('subUnits').get(unit.code).get('factors');
            }
          } else {
            if (this.unitForm.get(inst.name).get(sect.code).get(unit.code) != null) {
              factorsForm = this.unitForm.get(inst.name).get(sect.code).get(unit.code).get('factors');
            }
          }

          unit.instances[0].factors.map((fat) => {
            if (factorsForm != null && factorsForm.get(fat.code) != null) {
              let valFromForm = factorsForm.get(fat.code).value;
              if (!!valFromForm) {
                if (LicObjectUtils.isFactorTypeEqual(fat.type, FACTOR_TYPE.DATE)) {
                  valFromForm = LicDateUtils.convertToYMD(valFromForm);
                } else if (LicObjectUtils.isFactorTypeEqual(fat.type, FACTOR_TYPE.NUMERIC) && valFromForm.includes(',')) {
                  valFromForm = valFromForm.replace(',', '.');
                }
              } else if (LicObjectUtils.isFactorTypeEqual(fat.type, FACTOR_TYPE.NUMERIC)) {
                valFromForm = '-1';
              }
              if (fat.value !== valFromForm) {
                fat.value = valFromForm;
                fat.manuallySet = true;
              }
            }
          });
        });
      });
    });
    const unitsArray = [];
    mapVal.forEach((value: boolean, key: string) => {
      if (value === true) {
        unitsArray.push(key);
      }
    });
    this.getBeneficiariesFromUnits(unitsArray);
  }

  updateUnit(unitChanged: string) {
    this.resetQuotationAndQuotationData();

    this.allowQuotation = true;

    this.quotValidation.clear(this.UNIT_MSG_CODE);
    this.eventPropagation.emit('startLoader');
    this.saveUnit(unitChanged);
    this.buildBenefForm();
    this.createProposal().subscribe(() => {
      this.handleCreateProposalResponse(false);
    });
  }

  /**
   * Resets the quotation and quotation data.
   * Calls `resetQuotation()` method of `policyService`.
   * Sets `allowQuotation` to `true`.
   * Updates `annualPremiumValue` and `inst` to `null`.
   * Clears the formula of `policyService.mainProposal.proposal`.
   * Clears the `quotatedValues` map.
   * Deletes the session of `policyService.mainProposalResourceId` from `cacheInvestments`.
   */
  resetQuotationAndQuotationData() {
    this.policyService.resetQuotation();
    this.allowQuotation = true;

    // aggiorno bandone -> cancello quotazione
    this.annualPremiumValue = null;
    this.inst = null;
    // cancello le formule
    this.policyService.mainProposal.proposal.formula = null;

    // azzero i valori quotati sulle unit
    this.quotatedValues.clear();

    // ASMC-11575 - azzero gli investimenti al cambio valore di un fattore
    this.cacheInvestments.deleteSession(this.policyService.mainProposalResourceId);
  }

  checkFormUnits(): boolean {
    let valid = true;
    Object.keys(this.unitForm.controls).forEach((inst) => {
      const formInst = this.unitForm.get(inst) as UntypedFormGroup;
      Object.keys(formInst.controls).forEach((sez) => {
        const formSez = formInst.get(sez) as UntypedFormGroup;
        Object.keys(formSez.controls).forEach((unit) => {
          const formUnit = formSez.get(unit) as UntypedFormGroup;
          if (formUnit.invalid) {
            valid = false;
          }
        });
      });
    });

    return valid;
  }

  checkFormUnitsWithMsg(): boolean {
    this.quotValidation.clear(this.UNIT_MSG_CODE);
    Object.keys(this.unitForm.controls).forEach((inst) => {
      const formInst = this.unitForm.get(inst) as UntypedFormGroup;
      Object.keys(formInst.controls).forEach((sez) => {
        const formSez = formInst.get(sez) as UntypedFormGroup;
        Object.keys(formSez.controls).forEach((unit) => {
          const formUnit = formSez.get(unit) as UntypedFormGroup;
          if (formUnit.invalid) {
            this.quotValidation.setMessage(this.UNIT_MSG_CODE,
              {
                message: this.translateService.getImmediate('lic_guarantee_mandatory_data', {unit: this.descriptionUnitMap.get(unit)}),
                type: ErrorType.ERROR
              });
          }
        });
      });
    });

    return this.quotValidation.getBlocking(this.UNIT_MSG_CODE).length === 0;
  }

  /* SUBMIT */

  onSubmit(saveUndefinedProposal: boolean = false, modal = {}) {
    if (!!this.beneficiaryStatusErrorMessages.length) {
      return;
    }
    const resolvedKey = !!this.qMc && !!this.qMc.key ? this.qMc.key : undefined;
    this.submitted = true;
    this.haveToCheckRoles = false;
    this.resetValidationOnSubmit();

    if (this.checkQuotationPage(resolvedKey)) {
      this.onSubmitAfterCheck(saveUndefinedProposal, modal);
    } else {
      this.errorFoundOnSubmitAction();
    }
  }

  resetValidationOnSubmit() {
    this.quotValidation.clear(this.SUBMIT_MSG_CODE);
    this.quotValidation.clear(this.BENEF_MSG_CODE);
    this.quotValidation.clear(this.UNIT_MSG_CODE);
    this.quotValidation.clear(this.QUOTE_MSG_CODE);
    this.quotValidation.clear(this.THIRD_PARTY_MSG_CODE);
    this.quotValidation.clear(this.VINCOLATARIO_MSG_CODE);
  }

  errorFoundOnSubmitAction() {
    // segno tutti i campi come dirty e aggiungo un messaggio di errore se non è già presente
    Object.keys(this.beneficiaryForm.controls).forEach(cat => {
      if (cat !== 'nuovoRefTerzo') {
        const catForm = this.beneficiaryForm.get(cat) as UntypedFormGroup;
        Object.keys(catForm.controls).forEach(type => {
          const typeForm = catForm.get(type) as UntypedFormGroup;
          typeForm.markAsDirty();
          const formAnag = catForm.get('fromAnag') as UntypedFormArray;

          formAnag.controls.map((benef) => {
            benef.markAsDirty();
            benef.get('value').markAsDirty();
            benef.get('percentage').markAsDirty();
          });
        });
      }
    });
    this.checkDirty(this.questForm);
    if (this.thereAreBlockingErrorsInPage && !this.showAssignmentFields) {
      this.quotValidation.setMessage(this.SUBMIT_MSG_CODE,
        { message: this.translateService
          .getImmediate('lic_error_mandatory_fields'), type: ErrorType.ERROR });
    }
    this.eventPropagation.emit('stopLoader');
  }

  onSubmitAfterCheck(saveUndefinedProposal: boolean = false, modal = {}) {
    const resolvedKey = !!this.qMc && !!this.qMc.key ? this.qMc.key : undefined;
    this.saveUnit(null);
    this.saveBenef();

    // haveToCheckRoles viene settato a true nel salvataggio dei beneficiari (this.saveBenef())
    // quando è stato scelta l'opzione _ALTRO ovvero è presente un beneficiario da anagrafe
    this.eventPropagation.emit('startLoader');
    this.beneficiaryService.checkAllRoles(this.haveToCheckRoles, this.policyService.isFromPreventive).subscribe((res) => {
      if (!res.partiescongruent && !!res.errorMessages && res.errorMessages.length > 0) {
        res.errorMessages.forEach((err) => {
          this.quotValidation.setMessage(this.BENEF_MSG_CODE, { message: err.errorDescription, type: ErrorType.ERROR });
        });
        this.eventPropagation.emit('stopLoader');
      } else {
        this.afterCheckSaveProposal(resolvedKey, saveUndefinedProposal, modal);
      }
    });
  }

  afterCheckSaveProposal(resolvedKey, saveUndefinedProposal: boolean = false, modal = {}) {
    this.saveQuestIntoProposal();

    if (this.showAssignmentFields) {
      const assignmentData: {proposalId: string, holderId: string, data: {}} = {
        proposalId: this.policyService.mainProposalResourceId,
        holderId: this.lifeRoleService.getAssignmentHolder().objectId,
        data: this.getPopulateAssignmentData()
      };
      this.$subscriptions.push(
        this.assignmentHolderService.saveAssignmentHolderData(assignmentData).pipe(
          switchMap(res => {
            if (!!res && !!res.proposal) {
              this.policyService.mainProposal.proposal.extensionData.properties = res.proposal.extensionData.properties;
            }
            return this.callCheckSaveProposal(resolvedKey, saveUndefinedProposal, modal);
          })
        ).subscribe()
      );
    } else {
      const exludeAssignmentProp = this.policyService.mainProposal.proposal.extensionData.properties.filter((prop) => {
        return prop.chiave !== ExtensionProperty.ISSUE_ASSIGMENT_HOLDER;
      });
      if (!!exludeAssignmentProp.length) {
        this.policyService.mainProposal.proposal.extensionData.properties = exludeAssignmentProp;
      }
      this.$subscriptions.push(
        this.callCheckSaveProposal(resolvedKey, saveUndefinedProposal, modal).subscribe()
      );
    }
  }

  getPopulateAssignmentData(): AssignmentHolderData {
      return {assignmentHolderSubject: this.quotationForm.get('entVincolatario').get('val').value.objectId,
              openDate: this.assignmentHolderForm.get('openDate').value,
              closeDate: this.assignmentHolderForm.get('closeDate').value,
              assignmentHolderType: this.assignmentHolderForm.get('assignmentHolderType').value,
              contractNumber: this.assignmentHolderForm.get('contractNumber').value,
              assignmentType: this.assignmentHolderForm.get('assignmentType').value};
  }

  protected callCheckSaveProposal(resolvedKey, saveUndefinedProposal, modal = {}): Observable<any> {
    return this.policyService.saveQuestAnd(!!resolvedKey ? this.cache : null)
      .pipe(
        switchMap(result => this.actionService.getMsgByCodLivelloAzioni('AV;Q;G;U')),
        switchMap((response: ActionServiceResponse) => {
          this.checkActions(true, true, response.errorMessages, this.SUBMIT_MSG_CODE);
          return of(response);
        }),
        switchMap(() => {
          if (!this.thereAreBlockingErrorsInPage) {
            return this.createProposal();
          } else {
            this.eventPropagation.emit('stopLoader');
            return of(null);
          }
        }),
        switchMap(res => {
          if (res) {
            this.checkActions(true, true, res.output.proposal.quotationMessages, this.QUOTE_MSG_CODE);
            if (!this.thereAreBlockingErrorsInPage) {
              this.handleCreateProposalResponse(true, saveUndefinedProposal, modal);
            } else {
              this.eventPropagation.emit('stopLoader');
            }
          }
          return of(res);
        })
      );
  }

  onSubmitSavePreventive() {
    const resolvedKey = !!this.qMc && !!this.qMc.key ? this.qMc.key : undefined;
    this.quotValidation.clear(this.SUBMIT_MSG_CODE);

    if (this.checkQuotationPage(resolvedKey)) {

      this.saveUnit(null);

      this.eventPropagation.emit('startLoader');
      // dopo che ho salvato in req i dati della quotazione, posso salvare
      // saveFromQuote -> al suo interno riconosce se deve salvare polizza/proposta o preventivo
      this.policyService.saveFromQuote(false, this.cache).subscribe((response) => {
        this.policyService.mainProposal = response.output;
        this.eventPropagation.emit('stopLoader');
        this.cardsNavigationService.setCurrentCards(this.cardsNavigationService.STEP.ISSUE.route);
        // comprende anche il caso di isFromQuoteModification (modifica preventivo)
        this.policyService.flowTypeOnConfirm = SaveActions.SAVE_PREVENTIVE;
        this.navigation.emit(this.cardsNavigationService.STEP.ISSUE.route);
      }, (err) => console.log(err));
    } else {
      this.errorFoundOnSubmitAction();
    }
  }

  checkQuotationPage(questKey) {
    let allowNext = true;

    if (this.thereAreBlockingErrorsInPage) {
      allowNext = false;
    } else if (!this.policyService.isQuotationDone()) {
      allowNext = false;
      this.quotValidation.setMessage(this.QUOTE_MSG_CODE,
        { message: this.translateService.getImmediate('lic_quotation_not_done'), type: ErrorType.ERROR });
    } else if (this.allowQuotation) {
      allowNext = false;
      this.quotValidation.setMessage(this.SUBMIT_MSG_CODE,
        { message: this.translateService.getImmediate('lic_quotation_modified_value_quote'), type: ErrorType.ERROR });
    } else if (!!this.questForm.invalid) {
      allowNext = false;
    } else if (!!this.assignmentHolderForm.invalid && this.showAssignmentFields) {
      this.quotValidation.setMessage(this.SUBMIT_MSG_CODE, {message: this.translateService
          .getImmediate('lic_error_mandatory_fields'), type: ErrorType.ERROR});
      allowNext = false;
    }

    // check benef & quest
    if (allowNext) {
      allowNext = this.checkFormUnitsWithMsg();
    }
    if (allowNext && this.showStepBeneficiaries) {
      allowNext = this.checkFormBenef();
    }
    if (allowNext && this.showStepQuestionnaires) {
      if (!this.cache || this.cache.checkCompileByKey(questKey)) {
        allowNext = true;
      } else {
        this.quotValidation.setMessage(this.SUBMIT_MSG_CODE, {
          message: this.translateService
            .getImmediate('lic_error_mandatory_fields'), type: ErrorType.ERROR
        });
        allowNext = true;
      }
    }

    return allowNext;
  }

  checkDirty(form: UntypedFormGroup) {
    Object.keys(form.controls).forEach(field => {
      if (!form.get(field).valid) {
        form.get(field).markAsDirty();
      }
    });
  }

  createProposal(): Observable<any> {
    return this.policyService.saveProposal();
  }

  protected handleCreateProposalResponse(submit: boolean, saveUndefinedProposal: boolean = false, modal = {}) {
    this.instances = this.formatUnits(this.policyService.mainProposal.quote.product.assets[0].instances);
    this.setFormuleVector();
    this.eventPropagation.emit('stopLoader');

    if (submit) {
      this.setMessagesBeforeGoNext();
      if (saveUndefinedProposal) {
        this.saveUndefinedProposal(modal);
      } else {
        if (this.policyService.isUnitLinkedRiskType(this.lifeRiskInsuredData)) {
          this.policyService.investmentsData = null;
          this.cardsNavigationService.setCurrentCards(this.cardsNavigationService.STEP.INVESTIMENTI.route);
          this.navigation.emit(this.cardsNavigationService.STEP.INVESTIMENTI.route);
        } else {
          this.cardsNavigationService.setCurrentCards(this.cardsNavigationService.STEP.SOMMARIO.route);
          this.navigation.emit(this.cardsNavigationService.STEP.SOMMARIO.route);
        }
      }

    } else {
      this.checkViewQuest();
    }
  }

  public saveUndefinedProposal(modal: any = {}) {
    this.policyService.pushExtensionProperty(ExtensionProperty.INTERMEDIATE_SAVE, 'true');

    this.eventPropagation.emit('startLoader');
    const quotationStateSaved = this.policyService.quotationDone;

    this.policyService.saveFromQuote(false).pipe(
      tap((response: any) => {
        this.policyService.mainProposal.proposal = response.output.proposal;
        this.eventPropagation.emit('stopLoader');
        modal && modal.open();
        this.proposalNumber = this.policyService.mainProposal.proposal.contractData[0].proposalNumber;
        this.policyService.quotationDone = quotationStateSaved;
      })
    ).subscribe();
  }

  // setto tutti i messaggi che mi serviranno nelle pagine successive
  setMessagesBeforeGoNext() {
    const STEP_ID = this.cardsNavigationService.STEP.QUOTAZIONE.errorId;
    const authMessages = this.actionService.getAuthorizationsByStepId(STEP_ID);

    this.actionService.setAuthorization(STEP_ID, !!authMessages && authMessages.length > 0);

    const nonBlockingMessages = this.actionService.getErrorsMapped(ErrorType.AUTH, STEP_ID)
    .concat(this.actionService.getErrorsMapped(ErrorType.WARNING, STEP_ID));
    this.cardsNavigationService.setMessageForPage(STEP_ID,
      nonBlockingMessages as { type: ErrorType, message: string }[]);
  }

  saveQuestionnaire(quest: QuestionnaireValue, onInit?: boolean) {
    // this.resetBlockingErrors();
    this.questMap.set(quest.questionnaire.code, quest);
  }

  deleteQuestionnaire(questCode: string) {
    this.questMap.delete(questCode);
  }

  checkViewQuest() {
    this.policyService.mainProposal.quote.product.assets[0].instances[0].sections.map((s) => {
      s.units.map((u) => {
        u.instances[0].questionnaires.map((q) => {
          if (this.questViewMap.get(q.code) !== q.included) {
            if (!q.included) { // è diventato non visibile
              this.questMap.delete(q.code);
            }
            this.questViewMap.set(q.code, q.included);
          }
        });
      });
    });

    this.setQuestionnaireList(false);
  }

  saveQuestIntoProposal() {
    // se non è contenuto nella mappa allora arriva da un'altra pagina e va mantenuto
    const questToCopy = [];
    this.policyService.mainProposal.proposal.questionnaires.map((q) => {
      if (!this.questViewMap.has(q.questionnaire.code)) {
        questToCopy.push(q);
      }
    });

    this.policyService.mainProposal.proposal.questionnaires = questToCopy;

    for (const questionnaire of this.questMap.values()) {
      this.policyService.mainProposal.proposal.questionnaires.push(questionnaire);
    }
  }


  printMessage(message) {
    this.quotValidation.clear(this.BENEF_MSG_CODE);
    this.quotValidation.setMessage(this.BENEF_MSG_CODE, { message, type: ErrorType.ERROR });
  }

  undoChanges() {
    this.instances = null;
    const instances2 = this.formatUnits(this.policyService.mainProposal.quote.product.assets[0].instances);
    this.instances = [...instances2];
    this.cRef.detectChanges();
  }

  quote() {
    this.quotValidation.clear(this.QUOTE_MSG_CODE);
    /* this.blockingMsgs = [];
    this.warningMsgs = [];
    this.authoMsgs = []; */
    this.validationService.removeMessage('blockingAction');
    this.quotationActionMessages = [];

    this.eventPropagation.emit('startLoader');
    this.actionService.cleanErrorMessagesForStep(this.cardsNavigationService.STEP.QUOTAZIONE.errorId);
    this.cardsNavigationService.resetMessages(this.cardsNavigationService.STEP.QUOTAZIONE.errorId);

    const executeActionFirst$ = this.actionService.getMsgByCodLivelloAzioni('G;U').pipe(
      catchError(error => {
        console.error(error);
        this.handleCallError('Errore servizio execute actions');
        return of(null);
      })
    );
    const saveProp$ =  this.policyService.saveProposal(true).pipe(
      catchError(error => {
        console.error(error);
        this.handleCallError('Errore leggendo la quotazione');
        return of(null);
      })
    );
    const executeActionSecond$ = this.actionService.getMsgByCodLivelloAzioni('Q;G;U').pipe(
      catchError(error => {
        console.error(error);
        this.handleCallError('Errore servizio execute actions');
        return of(null);
      })
    );

    forkJoin({
      executeActionFirst: executeActionFirst$,
      saveProp: saveProp$
    }).pipe(
      switchMap(resp => {
        if (resp.executeActionFirst && resp.saveProp) {
          this.checkActions(true, true, resp.executeActionFirst.errorMessages, this.QUOTE_MSG_CODE);
          if (!!this.quotValidation.getBlocking(this.QUOTE_MSG_CODE).length) {
            // ci sono errori bloccanti e non si può quotare
            this.eventPropagation.emit('stopLoader');
          } else {
            this.saveUnit(null);
            this.allowQuotation = false;
            this.setCommissionForRisk(this.policyService.mainProposal.proposal.riskCommission);
            this.lifeRiskInsuredData = this.policyService.mainProposal.proposal.lifeRiskInsuredData;
            const showInvestmentStep = this.policyService.isUnitLinkedRiskType(this.lifeRiskInsuredData) && !this.policyService.isFromPreventive && !this.policyService.isAnonymous;
            // ASMC-9375 - investments visibility
            this.cardsNavigationService.checkInvestmentsStepVisibility(showInvestmentStep);
            this.checkActions(true, true, this.policyService.mainProposal.proposal.quotationMessages, this.QUOTE_MSG_CODE);
            this.setFormuleVector();
            this.getQuotation(this.policyService.mainProposal);
            this.instances = this.formatUnits(this.policyService.mainProposal.quote.product.assets[0].instances);

            if (this.isGrcovPropEnabled()) {
              this.unitsGrouped.updateForm();
            } else {
              this.units.updateForm();
            }
          }
          this.setShowCommonRisk();
          return executeActionSecond$;
        } else {
          return of(null);
        }
      })
    ).subscribe(res => {
      if (res) {
        this.checkActions(true, true, res.errorMessages, this.QUOTE_MSG_CODE);
        this.callExtWaterfallService();
      }
    });
  }


  setShowCommonRisk() {
    const prop = this.policyService.getExtensionProperty(ExtensionProperty.SHOW_COMMON_RISK);
    if (prop && prop.valore) {
      this.policyService.showCommonRisk.next(JSON.parse(prop.valore));
    }
  }

  // !Important Method used in ext
  callExtWaterfallService() {
    this.eventPropagation.emit('stopLoader');
  }


  handleCallError(msg: string) {
    console.log(msg);
    this.resetQuotationAndQuotationData();
    this.eventPropagation.emit('stopLoader');
  }

  checkActions(blocking: boolean, nonBlocking: boolean, errorMessages, type: string) {
    const blockingCode = '0';
    errorMessages.forEach((action: ActionServiceErrorMessage) => {
      this.checkAction(blocking, nonBlocking, action, blockingCode, type);
    });
  }

  checkAction(blocking: boolean, nonBlocking: boolean, action: ActionServiceErrorMessage, blockingCode, type: string) {
    if (blocking) {
      if (Number(action.derogationLevel) === Number(blockingCode)) {
        this.quotValidation.setMessage(type, { message: action.messageDescription, type: ErrorType.ERROR });
      }
    }
    if (nonBlocking) {
      if (+action.derogationLevel > +blockingCode) {
        const warningMsg = this.actionService.getWarningErrors(this.operator, action);
        if (!!warningMsg) {
          this.quotValidation.setMessage(type, { message: warningMsg, type: ErrorType.WARNING });
        }
        const authoMsg = this.actionService.getAuthoErrors(this.operator, action);
        if (!!authoMsg) {
          // ASMC-7977 serve a riempire i quotation messages
          this.actionService.setActionErrorMessage(this.cardsNavigationService.STEP.QUOTAZIONE.errorId, action);
          this.quotValidation.setMessage(type, { message: authoMsg, type: ErrorType.AUTH });
        }
        const alertMsg = this.actionService.getAlertErrors(this.operator, action);
        if (!!alertMsg) {
          this.quotValidation.setMessage(type, { message: alertMsg, type: ErrorType.INFO });
        }

      }
    }
  }

  setTaxationMap() {
    this.instances.map((instance) => {
      instance.sections.map((section) => {
        if (section.units.length !== 0) {
          section.units.map((unit) => {
            const lifeRiskInsDataForUnit = this.lifeRiskInsuredData.find(el => el.riskCode === unit.riskCode);
            if (lifeRiskInsDataForUnit != null) {
              this.lifeRiskInsuredMap.set(unit.code,
                lifeRiskInsDataForUnit.insuredRisks.tipoFiscalita.codice);
            }
            // GESTIONE SUB UNITS
            /* unit.subUnits.map((subunit) => {
              const lifeRiskInsDataForSubUnits = this.lifeRiskInsuredData.find(el => el.riskCode === subunit.riskCode);
              if (lifeRiskInsDataForSubUnits != null) {
              this.lifeRiskInsuredMap.set(subunit.code,
                lifeRiskInsDataForSubUnits.insuredRisks.tipoFiscalita.codice);
            }
            }); */
          });
        }
      });
    });

    console.log(this.lifeRiskInsuredMap, 'map insured');
  }

  getQuotation(r) {
    this.quotatedValues.clear();

    const resp: Proposal = r.proposal;

    this.damagesInstalment = resp.damagesInstalments;
    this.damageRisk = resp.damageRisks;

    // proposal.installment
    let inst = null;
    if (resp.installment != null && resp.installment[0] != null) {
      inst = resp.installment[0];
      if (this.getPaymentFrequencyCode()) {
        this.annualPremiumValue = resp.policyPremium;
      }
      this.inst = inst;
    }

    // proposal.benefit
    let ben = null;
    if (resp.benefit != null && resp.benefit.length > 0) {
      ben = resp.benefit;
    }

    // proposal.damageRisk
    let damInst = null;
    if (resp.damagesInstalments != null && resp.damagesInstalments.length > 0) {
      damInst = resp.damagesInstalments;
      this.damagesInstalment = resp.damagesInstalments;
    }


    if (inst != null) {
      inst.riskInstallment.map((risk) => {
        const valPres = this.quotatedValues.get(risk.riskCode) != null ? this.quotatedValues.get(risk.riskCode).prestazione : '- - -';
        this.quotatedValues.set(risk.riskCode, { rataFirma: risk.totalPremium, prestazione: valPres });
      });
    }

    if (ben != null) {
      ben.map((b) => {
        const valRata = this.quotatedValues.get(b.riskCode) != null ? this.quotatedValues.get(b.riskCode).rataFirma : '- - -';
        this.quotatedValues.set(b.riskCode, { rataFirma: valRata, prestazione: b.initialPerformance });
      });
    }

    if (damInst != null) {
      // rataFirma damInst[0]
      damInst[0].dueAmountsByRisk.map((risk) => {
        let riskCode = risk.object != null ? risk.object.code : null;
        const valPres = this.quotatedValues.get(riskCode) != null ? this.quotatedValues.get(riskCode).prestazione : '- - -';
        riskCode = this.checkValOnMapAndGetCode(riskCode, valPres);
        this.quotatedValues.set(riskCode, { rataFirma: risk.premium.gross, prestazione: valPres });
      });
    }

  }

  checkValOnMapAndGetCode(riskCode: string, valPres: string): string {
    if (valPres !== '- - -' && this.quotatedValues.get(riskCode) && !!this.quotatedValues.get(riskCode).rataFirma) {
      return riskCode + '_damage';
    }
    return riskCode;
  }

  isBeneficiarySelectedAsInsured(category, subject: Subject): boolean {
    if (subject.objectId === this.insuredValue.objectId) {
      this.quotValidation.setMessage(this.BENEF_MSG_CODE, {
        message: this.translateService.getImmediate('lic_benMorteNoAssic', {benefRole: this.translateService.getImmediate('lic_Beneficiary'), benefCat: this.beneficiaryService.getBenefDescription(category), benefName: subject.nominative}
        ), type: ErrorType.ERROR
      });
      return true;
    }else{
      return false;
    }
  }

  subjectValidation(category, subject: Subject): boolean {
    let error = false;
    const formGroup = this.beneficiaryForm.get(category) as UntypedFormGroup;
    const refTerzo = this.beneficiaryForm.get('nuovoRefTerzo').get('val').value;

    const isDeathBeneficiary = this.layeredRuleService.isDeathBeneficiary(category, this.policyService.currentContext);
    // controlli per il caso beneficiario morte -> diverso da assicurato e referente terzo
    if (isDeathBeneficiary && (formGroup.get('choiceValue') as UntypedFormControl).value === BENEFICIARY_CHOICE.ANAGRAFICAL_BENEF) {
      if(this.isBeneficiarySelectedAsInsured(category,subject)){
        error = true;
      }
      if (!!refTerzo) {
        if (refTerzo.objectId === subject.objectId) {
          this.quotValidation.setMessage(this.BENEF_MSG_CODE, {
            message: this.translateService.getImmediate('lic_benMorteNoRef', {benefRole: this.translateService.getImmediate('lic_Beneficiary'), benefCat: this.beneficiaryService.getBenefDescription(category), benefName: subject.nominative}
            ), type: ErrorType.ERROR
          });
          error = true;
        }
      }
    }
    // controllo se nella categoria ho già inserito il soggetto
    if (this.mapSubj.has(category + '-' + subject.objectId)) {
      this.quotValidation.setMessage(this.BENEF_MSG_CODE, {
        message: this.translateService.getImmediate('lic_benefPresente', {benefRole: this.translateService.getImmediate('lic_Beneficiary'), benefCat: this.beneficiaryService.getBenefDescription(category), benefName: subject.nominative}
        ), type: ErrorType.ERROR
      });
      error = true;
    }
    return error;
  }

  subjectRelatedValidation(category, subjectBenef, subjectRelated: Subject): boolean {
    if (!category || !subjectBenef || !subjectRelated || !subjectRelated.objectId) {
      return false;
    }

    const relatedSubjects: RelatedSubject[]  = this.beneficiaryService.getSubjectList(category, subjectBenef.objectId);
    if (!!relatedSubjects  && !!relatedSubjects.find(subj => !!subj.subject && subj.subject.objectId  === subjectRelated.objectId)) {
      this.quotValidation.setMessage(this.BENEF_MSG_CODE, {
        message: this.translateService.getImmediate('lic_effective_holder_duplicated',
                  {linkedName: subjectRelated.nominative, benefName: subjectBenef.nominative}
        ), type: ErrorType.ERROR
      });
      return true;
    }

    return false;

  }

  openAnag(event: any) {
    this.quotValidation.clear(this.BENEF_MSG_CODE + '-0-' + event.cat);
    this.eventPropagation.emit(event.subject);
    this.$subscriptions.push(
      this.lifeRoleService.getObservable().pipe(
        distinctUntilChanged(),
        take(1)).pipe(
        switchMap((subj: Subject) => {
          if (subj != null) {
            return this.handleBenefFromAnag(event, subj);
          }
          return of(null);
        })
      ).subscribe()
    );
  }

  editAnag(event: any) {
    this.resetValidationMessagesForBeneficiaries(event.obj.subject.objectId, event.cat);
    this.mapSubj.delete(event.cat + '-' + event.obj.subject.objectId);
    this.saveBenef();
    const roleCode = event.relatedSubj || event.subject.roleCode;
    if (event.relatedSubj) {
      const arrayForm = this.beneficiaryForm.get(event.cat).get('fromAnag') as UntypedFormArray;
      const subject: Subject = arrayForm.at(event.index).get('value').value;
      const relatedSubj = this.beneficiaryService.getSubjectList(event.cat, subject.objectId);
      const removedEditedRelSub = relatedSubj && relatedSubj.filter(relSubj => relSubj.subject.objectId !== event.obj.subject.objectId);
      if (removedEditedRelSub) {
        this.beneficiaryService.setRelatedSubjectList(event.cat, subject.objectId, removedEditedRelSub);
      }
    }
    this.handleBenefFromAnag(event, event.obj.subject)
    .pipe(
      switchMap(() => this.callPartyCompletedForOtherRolesWithSameObjectId(event.obj.subject, roleCode))
    ).subscribe();
  }


  private resetValidationMessagesForBeneficiaries(subjId: string, cathegory: string) {
    const messageKey = this.BENEF_MSG_CODE + '-' + subjId + '-' + cathegory;
    const messagesToDelete = this.quotValidation.getMessagesByKey(messageKey);
    this.quotValidation.resetMessages(messageKey);
    if (!!messagesToDelete.length) {
      this.beneficiaryStatusErrorMessages = this.beneficiaryStatusErrorMessages.filter(msg => {
        return messagesToDelete.every(valMsg => valMsg.message !== msg.message.message);
      });
    }
  }

  // lo richiamo in caso di beneficiario o di ruolo collegato al beneficiario
  // esegue un checkRole sui related roles ed espone messaggi in caso il soggetto non fosse adeguato
  // capisco se sto sostituendo un soggetto oppure inserendolo in uno spazio vuoto
  // nel caso di beneficiario va a controllare i related roles con una chiamata e li setta dal service
  // aggiunge il soggetto:
  // - nel form
  // - nella mappa dei soggetti (mapSubj)
  // - nel beneficiaryService nel caso di relatedRole
  handleBenefFromAnag(event, subj: Subject): Observable<{ partyConfirmed: boolean }> {
    if (event.relatedSubj) { // TODO VALIDAZIONE
      const arrayForm = this.beneficiaryForm.get(event.cat).get('fromAnag') as UntypedFormArray;
      const subject: Subject = arrayForm.at(event.index).get('value').value;
      if (this.subjectRelatedValidation(event.cat, subject, subj)) {
        return of(null);
      }
      return this.checkPartyCompleted(subj, event.relatedSubj, event.cat).pipe(
        switchMap((resp) => {
            this.beneficiaryService.setSubject(event.cat, subject.objectId, event.relatedSubj, subj, event.withPercentage);
            this.beneficiaryService.createPolicyRole(subj, event.relatedSubj);
            this.saveBenef(); // aggiorno i beneficiari
            return of(resp);
        })
      );
    } else if (!this.subjectValidation(event.cat, subj)) {
      return this.checkPartyCompleted(subj, Roles.BENEFICIARY, event.cat).pipe(
        switchMap(res => {
          const arrayForm = this.beneficiaryForm.get(event.cat).get('fromAnag') as UntypedFormArray;

          const haveToInsertHere = arrayForm.at(event.index) ? arrayForm.at(event.index).get('value') : null;
          if (!!haveToInsertHere && !!haveToInsertHere.value) {
            // sto sostituendo un soggetto
            this.mapSubj.delete(event.cat + '-' + haveToInsertHere.value.objectId);
            haveToInsertHere.setValue(subj);
          } else if (!!haveToInsertHere) {
            // aggiungo il soggetto in un control già presente
            haveToInsertHere.setValue(subj);
          } else {
            if (this.maxAnagSugject.get(event.cat) > arrayForm.length) {
              arrayForm.push(this.createSubjectBenefFormGroup(subj, null));
            }
          }
          this.mapSubj.set(event.cat + '-' + subj.objectId, subj);
          this.saveBenef(); // aggiorno i beneficiari

          // aggiungo un altro spazio vuoto se il max lo permette
          // e non arrivo dalla modifica anagrafica
          if (!(event.obj && event.obj.openedEdit) && this.maxAnagSugject.get(event.cat) > arrayForm.length) {
            arrayForm.push(this.createSubjectBenefFormGroup(null, null));
          }

          // creo i relatedRoles chiamando il servizio
          const newBenef = this.beneficiaryService.createPolicyRole(subj, Roles.BENEFICIARY);
          this.beneficiaryService.createEmptyRelatedSubjectList(event.cat, subj.objectId);

          return this.beneficiaryService.getRelatedRoles(newBenef, event.cat, this.policyService.isFromPreventive).pipe(
            switchMap((resp) => {
              if (!!resp) {
                this.beneficiaryService.setRelatedRoles(event.cat, newBenef.objectId, resp.subjectRole.linkedSubjectRoles);
              }
              return of({partyConfirmed: true});
            })
          );
        })
      );
    }
    return of(null);
  }


  referentValidation(value: Subject) {
    let error = false;
    if (value.objectId === this.insuredValue.objectId) {
      this.quotValidation.setMessage(this.THIRD_PARTY_MSG_CODE, {
        message: this.translateService.getImmediate('lic_refTerzoNoAssic'), type: ErrorType.ERROR
      });
      error = true;
    }
    if (value.objectId === this.lifeRoleService.getPolicyHolder().objectId) {
      this.quotValidation.setMessage(this.THIRD_PARTY_MSG_CODE, {
        message: this.translateService.getImmediate('lic_refTerzoNoContr'), type: ErrorType.ERROR
      });
      error = true;
    }
    const categoryForm = this.beneficiaryForm.get('2') as UntypedFormGroup;
    if (categoryForm != null) {
      const fromAnag = categoryForm.get('fromAnag').value;
      if ((categoryForm.get('choiceValue') as UntypedFormControl).value === BENEFICIARY_CHOICE.ANAGRAFICAL_BENEF) {  // categoria morte beneficiario da anagrafe
        if (fromAnag != null) {
          fromAnag.forEach((benef) => {
            if (benef.value != null && benef.value.objectId === value.objectId) {
              this.quotValidation.setMessage(this.THIRD_PARTY_MSG_CODE, {
                message: this.translateService.getImmediate('lic_refTerzoPresente'), type: ErrorType.ERROR
              });

              error = true;
            }
          });
        }
      }
    }
    return error;
  }

  openRefAnag(event: any ) {
    this.eventPropagation.emit(event);
    this.$subscriptions.push(
      this.lifeRoleService.getObservable().pipe(distinctUntilChanged(), take(1)).pipe(
        switchMap(value => {
          if (value != null) {
            return this.checkPartyCompleted(value, Roles.THIRDREFERENT, null).pipe(
              map(resp => {
                return resp.partyConfirmed ? value : null;
              })
            );
          }
        })
      ).subscribe(value => {
        if (value != null) {
          this.handleRefSubjectReceved(value);
        }
      })
    );
  }

  openVincAnag(event: any) {
    this.eventPropagation.emit(event);
    this.$subscriptions.push(
      this.lifeRoleService.getObservable().pipe(distinctUntilChanged(), take(1)).pipe(
        switchMap(value => {
          if (value != null) {
            return this.checkPartyCompleted(value, Roles.VINCOLATARIO, null).pipe(
              map(resp => {
                return resp.partyConfirmed ? value : null;
              })
            );
          }
        })
      ).subscribe(value => {
        if (value != null) {
          this.handleVincSubjectReceved(value);
          this.showAssignmentFields = true;
        }
      })
    );
  }

  handleRefSubjectReceved(value: Subject) {
    if (!this.referentValidation(value)) {
      this.beneficiaryForm.get('nuovoRefTerzo').get('val').setValue(value);
      this.lifeRoleService.setPolicyThirdRef(value);
    }
  }

  handleVincSubjectReceved(value: Subject) {
    if (this.isEntVincChanged(value)) {
      this.quotationForm.get('entVincolatario').get('val').setValue(value);
      this.lifeRoleService.setPolicyAssignmentHolder(value);
    }
  }

  isEntVincChanged(entVinc: Subject) {
    const currentVincHolder = this.quotationForm.get('entVincolatario').get('val').value;
    return (!currentVincHolder || (!!currentVincHolder && currentVincHolder.objectId !== entVinc.objectId));
  }

  // cancella il soggetto dalla mappa
  // nel form è stato già cancellato dal figlio che emette l'evento
  delete(event) {
    this.mapSubj.delete(event.cat + '-' + event.subject.objectId);
    this.resetValidationMessagesForBeneficiaries(event.subject.objectId, event.cat);
    this.saveBenef(); // aggiorno i beneficiari
  }

  deleteRelSubject(event) {
    this.beneficiaryService.deleteRelatedSubject(event.cat, event.benef, event.subjId);
    this.resetValidationMessagesForBeneficiaries(event.subjId, event.cat);
  }

  openDetailModal(detailModal) {
    this.policyPremium = this.policyService.mainProposal.proposal.policyPremium;
    this.primaRata = this.policyService.mainProposal.proposal.installment[0];
    this.damagesInstalment = this.policyService.mainProposal.proposal.damagesInstalments;
    this.damageRisk = this.policyService.mainProposal.proposal.damageRisks;

    this.showDetailModal = true;

    detailModal.open();
  }

  getPaymentFrequencyCode(): boolean {
    const pfc = this.policyService.mainProposal.quote.product.paymentFrequencyCode;

    let result = false;

    this.policyService.mainProposal.quote.product.paymentFrequencies.map((payment) => {
      if (!result) {
        if (payment.code === pfc) {
          if (payment.paymentFrequencyType === '3') {
            result = false;
          } else {
            result = true;
          }
        }
      }
    });
    return result;
  }

  openGarDetailModal(garDetailModal) {
    this.updateWarrantiesDescriptionMap();
    this.riskPremium = this.policyService.mainProposal.proposal.riskPremiumDisplay;
    this.policyPremium = this.policyService.mainProposal.proposal.policyPremium;
    this.damagesInstalment = this.policyService.mainProposal.proposal.damagesInstalments;
    this.damageRisk = this.policyService.mainProposal.proposal.damageRisks;

    this.showGarDetailModal = true;
    garDetailModal.open();
  }

  openProvModal(provModal) {
    this.updateWarrantiesDescriptionMap();
    this.showProvModal = true;

    provModal.open();
  }

  openFormuleModal(formuleModal) {
    this.setFormuleVector();
    this.showFormuleModal = true;
    formuleModal.open();
  }

  updateWarrantiesDescriptionMap() {
    this.policyService.mainProposal.quote.product.assets[0].instances.forEach((inst) => {
      inst.sections.forEach((sect) => {
        sect.units.forEach((unit) => {
          this.warrantiesDescr.set(unit.riskCode, this.getWarrantyDescr(this.policyService.mainProposal.quote, unit.riskCode));
        });
      });
    });
  }

  setFormuleVector() {
    this.vectorInsuredRisk = [];
    this.formuleVector = this.policyService.mainProposal.proposal.formula;

    if (this.formuleVector != null) {
      const insuredVector = [];
      this.formuleVector.map((formula) => {
        if (!insuredVector.includes(formula.assetInstanceName)) {
          insuredVector.push(formula.assetInstanceName);
          if (!this.formuleModalForm.contains(formula.assetInstanceName)) {
            this.formuleModalForm.addControl(formula.assetInstanceName, new UntypedFormGroup({}));
          }
        }
      });

      insuredVector.forEach((insured) => {
        let formuleByInsured = [];
        const riskVector = [];
        formuleByInsured = this.formuleVector.filter((f) => f.assetInstanceName === insured);
        formuleByInsured.forEach((formula) => {
          if (!riskVector.includes(formula.riskCode)) {
            riskVector.push(formula.riskCode);
            if (!(this.formuleModalForm.get(insured) as UntypedFormGroup).contains(formula.riskCode)) {
              (this.formuleModalForm.get(insured) as UntypedFormGroup).addControl(formula.riskCode, new UntypedFormGroup({}));
            }
          }
        });
        const vectorRisk = [];
        riskVector.map((risk) => {
          const filteredVector = formuleByInsured
            .filter((f) => f.riskCode === risk && f.visible)
            .sort((a, b) => a.code < b.code ? 1 : -1);
          if (!!filteredVector && filteredVector.length > 0) {
            vectorRisk.push(filteredVector);
          }
        });
        if (vectorRisk.length > 0) {
          this.vectorInsuredRisk.push(vectorRisk);
        }
      });

      this.showFormuleRecalculate = false;
      this.formuleVector.map((formula) => {
        const formVal = (this.formuleModalForm.get(formula.assetInstanceName).get(formula.riskCode) as UntypedFormGroup);
        if (formula.visible) {
          if (!formVal.contains(formula.code)) {
            formVal.addControl(formula.code, new UntypedFormControl(formula.value));
          } else {
            formVal.get(formula.code).setValue(formula.value);
          }
          if (formula.modifiable) {
            this.showFormuleRecalculate = true;
          }
        }
      });
    }
  }

  getWarrantyDescr(quote: Quote, riskCode: string) {
    let warrantyDescription = null;

    if (quote.product.assets[0].instances[0].sections) {
      const sections = quote.product.assets[0].instances[0].sections;

      loop1:
      for (const i in sections) {
        if (sections[i] && sections[i].units) {
          const units = sections[i].units;

          loop2:
          for (const j in units) {
            if (units[j] && units[j].selection && units[j].riskCode === riskCode) {
              warrantyDescription = units[j].shortDescription;
              break loop1;
            }
          }
        }
      }
    }
    return warrantyDescription;
  }

  saveFormulaVal() {
    this.policyService.mainProposal.proposal.formula.filter(el => el.visible).map((formula) => {
      const value = this.formuleModalForm.getRawValue()[formula.assetInstanceName][formula.riskCode][formula.code];
      formula.value = (typeof value === 'string') ? value.replace(',', '.') : value;
    });

    console.log('formule aggiornate', this.policyService.mainProposal.proposal.formula);

    this.quote();
  }

  closeFormulaModal(formuleModal) {
    this.showFormuleModal = false;
    this.formuleModalForm = new UntypedFormGroup({});
    formuleModal.close();
  }

  isQuotationAvailable() {
    return this.allowQuotation && this.checkFormUnits();
  }

  setQuestFactor() {
    if (!this.policyService.isAnonymous) {
      this.questFactorsArray = [];
      this.disabledQuestionArray = [];
      this.prevalByQuestion = [];

      // DOMANDE DA DISABILITARE
      this.disabledQuestionArray.push({ code: 'AVSOGG' }); // tipo soggetto
      this.disabledQuestionArray.push({ code: 'QADVE1' }); // tipo prod

      /* SCOPO OPERAZIONE (NASCOSTA)
      this.disabledQuestionArray.push({ code: 'QADVE2' }); */

      // TIPO SOGGETTO
      this.questFactorsArray.push({
        code: '_1CTPF',
        value: this.lifeRoleService.holderSubj.personType.codice === '1' ? '1' : '0'
      });

      // sempre ad 1 per l'emissione
      this.questFactorsArray.push({
        code: 'QADVE1',
        value: '1'
      });

      // Tipo prodotto
      let valueQADVE2 = '0';
      switch (this.policyService.mainProposal.quote.product.type.code) {
        case 'TCM':
        case 'CPI':
          valueQADVE2 = '1';
          break;
        case 'PIP':
          valueQADVE2 = '2';
          break;
        case 'UNIT':
          valueQADVE2 = '3';
          break;
        default:
          valueQADVE2 = '4';
          break;
      }

      this.questFactorsArray.push({
        code: 'QADVE2',
        value: valueQADVE2
      });

      // DOMANDA -> DPEP
      // RISPOSTE -> CONT DELE RAPP TITO

      let contraente = 0;
      let delegato = 0;
      let rappresentante = 0;
      let titolari = 1; // FATTORE LISTA -> parte da 1 perchè 0 corrisponde ad idRisposta 1
      let assicurato = 0;
      let benefType = null;
      let terzoPagatore = 0;
      let esecutore = 0;
      let benefAnag = 0;


      this.policyService.mainProposal.proposal.lifeParty.forEach((subj) => {
        switch (subj.party.partyRole) {
          case '1':
            contraente++;
            break;
          case '4':
            assicurato++;
            break;
          case '112':
            delegato++;
            break;
          case '101':
            rappresentante++;
            break;
          case '113':
            titolari++;
            break;
          case '300016':
            terzoPagatore++;
            break;
          case '112':
            esecutore++;
            break;
        }
      });

      if (!!this.policyService.mainProposal.proposal.beneficiaryData) {
        this.policyService.mainProposal.proposal.beneficiaryData.choiceBeneficiaryData.forEach((cat: ChoiceBeneficiaryData) => {
          if (cat.choiceTypeBeneficiary === BENEFICIARY_CHOICE.ANAGRAFICAL_BENEF) {
            if (!!cat.beneficiaryLifeParty) {
              benefType = !benefType ? !!cat.beneficiaryLifeParty.physicalLegal : benefType;
              benefAnag++;
            }
          }
        });
      }

      this.questFactorsArray.push({
        code: '1DELEG',
        value: delegato.toString()
      });

      this.questFactorsArray.push({
        code: '1CONTR',
        value: contraente.toString()
      });

      this.questFactorsArray.push({
        code: '1LEGAL',
        value: rappresentante.toString()
      });

      this.questFactorsArray.push({
        code: '1TITOL',
        value: titolari.toString()
      });

      this.questFactorsArray.push({
        code: '1ASSIC',
        value: assicurato.toString()
      });

      this.questFactorsArray.push({
        code: '1TERPA',
        value: terzoPagatore.toString()
      });

      this.questFactorsArray.push({
        code: '1COESE',
        value: esecutore.toString()
      });

      this.questFactorsArray.push({
        code: '1BENAN',
        value: benefAnag
      });

      if (!!benefType) {
        this.questFactorsArray.push({
          code: '1BENEF',
          value: benefType.toString()
        });
      }
    }
  }

  findValueOfRiskCommission(riskCommissionFormula: CommissionFormula[], commissionCode: string) {
    const com = riskCommissionFormula.find((c) => c.code === commissionCode);
    return com != null ? com.value : null;
  }

  setCommissionForRisk(riskCommission: RiskCommission[]) {
    this.commissionForRisk = riskCommission;

    const vectorOfCommission = [];

    if (this.commissionForRisk != null && this.commissionForRisk.length > 0) {
      const riskType1 = this.commissionForRisk.find((r) => r.typeCommission === '1');
      if (!!riskType1) {
        riskType1.commissionFormula.forEach((com) => {
          vectorOfCommission.push({
            code: com.code,
            description: com.description,
            type: '1',
            visibility: parseInt(com.visibility, 10) !== 0
          });
        });
      }
      const riskType2 = this.commissionForRisk.find((r) => r.typeCommission === '2');
      if (!!riskType2) {
        riskType2.commissionFormula.forEach((com) => {
          vectorOfCommission.push({
            code: com.code,
            description: com.description,
            type: '2',
            visibility: parseInt(com.visibility, 10) !== 0
          });
        });
      }
    }

    this.allCommission = vectorOfCommission;
  }

  /**
   * Filters the commission for risk based on the given type.
   * And then it will check if it's enabled the property LIFE_POLICY_COVERTURE_GROUPING, if that is will sum the values of risks groupped under the same transcoderCode1.
   * @param type - The type of commission to filter by.
   * @returns An array of RiskCommission objects that match the given type.
   */
  filterCommissionForRisk(type: string): RiskCommission[] {
    let risks = this.commissionForRisk.filter((r) => r.typeCommission === type);
    if (this.isGrcovPropEnabled()) {
      const groupUnits = this.getUnitGroupedByTranscoderCode();
      Object.keys(groupUnits).forEach(group => {
        risks = this.sumRiskPremiumsByGroup(risks, group, groupUnits);
      });
    }
    return risks;
  }

  private sumRiskPremiumsByGroup(risks: RiskCommission[], group: string, groupUnits: { [key: string]: Unit[]; }) {
    return LicQuotationUtils.sumRiskPremiumsByGroup(group, groupUnits, risks);
  }

  /**
   * Returns an object with units grouped by transcoder code.
   * @param assets - An array of Asset objects.
   * @returns An object with units grouped by transcoder code.
   */
  protected getUnitGroupedByTranscoderCode() {
    return LicQuotationUtils.getUnitGroupedByTranscoderCode(this.policyService.mainProposal.quote.product.assets);
  }

  filterAllCommission(type: string): { code: string, description: string, type: string }[] {
    return this.allCommission.filter((c) => c.type === type && !!c.visibility);
  }

  areCommissionPresent(): boolean {
    if (!!this.allCommission && this.allCommission.length > 0) {
      const visCom = this.allCommission.find((c) => c.visibility === true);
      return !!visCom;
    }
    return false;
  }

  getAuthoMessages(): LifeIssueMessage[] {
    const messages = [];
    if (!!this.messageFromPreviusStep && this.messageFromPreviusStep.length > 0) {
      this.messageFromPreviusStep.forEach((m) => {
        if (this.actionService.getAuthoErrors(this.operator, m) != null) {
          messages.push({ message: m.messageDescription, type: ErrorType.AUTH });
        }
      });
    }
    return messages;
  }

  getWarningMessagesOLD(): LifeIssueMessage[] {
    const messages = [];
    if (!!this.messageFromPreviusStep && this.messageFromPreviusStep.length > 0) {
      this.messageFromPreviusStep.forEach((m) => {
        if (this.actionService.getWarningErrors(this.operator, m) != null) {
          messages.push({ message: m.messageDescription, type: ErrorType.WARNING });
        }
      });
    }

    return messages;
  }

  private generateQuestKey(): string {
    let key = 'FAKE-QUOT';
    this.ppevoQuestionnaires.forEach(q => {
      key += `-${q.code}`;
    });

    return key.toUpperCase();
  }

  /* private resetBlockingErrors() {
    this.blockingMsgs = [];
  } */

  public loaderQuestManager(event: string) {
    this.eventPropagation.emit(event);
  }

  ngOnDestroy(): void {
    this.$subscriptions.forEach(subscription => {
      subscription.unsubscribe();
    });
  }

  clickOnQuestAccordion() {
    this.isQuestAccordionOpen = !this.isQuestAccordionOpen;
  }

  public openCommonRiskModal(commoRiskModal) {
    this.eventPropagation.emit('startLoader');
    const subject = this.lifeRoleService.getStoredSubject(this.lifeRoleService.getPolicyInsured().objectId);
    this.$subscriptions.push(
      this.policyService.getCommonRisk([subject.objectId]).pipe(
        catchError((err: any) => {
          return this.handlesErrorCall(err);
        })
      ).subscribe((response: CommonRiskResponse) => {
        this.eventPropagation.emit('stopLoader');
        this.commonRisk = response.data && response.data[0] && response.data[0].risks;
        if (this.commonRisk && this.commonRisk.length > 0) {
          this.fillCommonRiskTable();
          this.showCommonRiskModal = true;
          commoRiskModal.open();
        }
      })
    );
  }

  /*TODO: this error handling must be centralized*/
  private handlesErrorCall(err: any): Observable<never> {
    if (this.HTTP_STATUS_CODE_BUSINESS_ERROR === err.status) {
      const dialog = this.modalService.open(LicIssueModalErrorComponent, {
        centered: true,
        size: 'lg',
        windowClass: 'in',
        backdropClass: 'light-blue-backdrop in'
      });
      dialog.componentInstance.title = 'lic_error';
      dialog.componentInstance.message = this.lifeHttpErrorMessageHandlerService.getDescriptionErrorMessageByCatchError(err);
      return EMPTY;
    }
    return throwError(err);
  }

  protected fillCommonRiskTable() {
    this.commonRiskTable = [];
    this.commonRisk.forEach(risk => {
      risk.policies.forEach(policy => {
        let row = {
          policyNumber : policy.numberPolicy,
          proposalNumber : policy.numberProposal,
          productDesc : risk.productDescription,
          riskDesc : risk.riskDescription,
          insuredAmount : policy.value
        }
        this.commonRiskTable.push(row);
      });
    });
    this.sortCommonRiskTable();
  }

  protected sortCommonRiskTable() {
    let proposals = this.commonRiskTable
      .filter(row => !row.policyNumber?.length);

    proposals.sort((a, b) => {
        return Number.parseInt(a.proposalNumber) < Number.parseInt(b.proposalNumber) ? -1 : 1;
    });

    let policies = this.commonRiskTable
      .filter(row => row.policyNumber?.length);

    policies.sort((a, b) => {
        return Number.parseInt(a.proposalNumber) < Number.parseInt(b.proposalNumber) ? -1 : 1;
    });

    this.commonRiskTable = proposals;
    this.commonRiskTable.push(...policies);
  }

  isGrcovPropEnabled(): boolean {
    const grcov = this.policyService.getPropertyFromProduct(LicCustomProperties.LIFE_POLICY_COVERTURE_GROUPING);
    return !!grcov && grcov.value === 'true';
  }

  /**
   * Calls party completed for other roles with the same object ID.
   * @param subj The subject to check.
   * @param roleCode The role code to filter out.
   * @returns An observable that emits the result of the checks.
   */
  protected callPartyCompletedForOtherRolesWithSameObjectId(subj: Subject, roleCode: string): Observable<any> {
    const policyRolesFilled = this.lifeRoleService.getPolicyRolesFilled(this.validationService);
    const allFilledRoles = policyRolesFilled
      .concat(this.beneficiaryService.getAllRelatedSubjects().map(rr => new PolicyRole(rr.roleCode, rr.subject)));

    return combineLatest(
      allFilledRoles
      .filter(policyRole => policyRole.partyRole !== roleCode && policyRole.objectId === subj.objectId)
      .map((policyRole) => {
        const subject = this.lifeRoleService.getStoredSubject(policyRole.objectId);
        if (policyRolesFilled.includes(policyRole)) {

          // calling checkPartyCompleted for the policy roles filled with the same object ID on life-role-page
          return this.recallLifeRoleChecks(subject, policyRole, policyRolesFilled).pipe(
            switchMap(res => {
                return this.addValidationsForBenefAndReturnPartyConfirmed(res, policyRole.partyRole, null, subject);
            })
          );
        }
        // calling checkPartyCompleted for the beneficiary role and related roles
        return this.checkPartyCompleted(subject, policyRole.partyRole, null);
      })
    ).pipe(tap((res) => {
      console.log(res);
    }));
  }

  private recallLifeRoleChecks(subject: Subject, policyRole: PolicyRole, policyRolesFilled: PolicyRole[]): Observable<PartyCheck> {
    return this.lifeRoleService.checkPartyCompleted(
      Number(subject.objectId),
      Number(subject.idLatestPhotos),
      Number(policyRole.partyRole),
      null,
      Number(this.lifeSessionService.idPv)
    ).pipe(
      switchMap(responsePartyComplete => {
        return of({errorMessages: [], partycongruent: null}).pipe(
          map(responseCheck => {
            return this.validationService.mapResponsePartyCompleteToCheck(responsePartyComplete, responseCheck, policyRole);
          })
        );
      })
    );
  }

  protected checkPartyCompleted(subj: Subject, roleCode: Roles, cathegory: string): Observable<{partyConfirmed: boolean}> {
    return this.lifeRoleService.checkPartyCompleted(
      Number(subj.objectId),
      Number(subj.idLatestPhotos),
      Number(roleCode),
      null,
      Number(this.lifeSessionService.idPv)
    ).pipe(
      switchMap(response => {
        return this.beneficiaryService.checkRole(subj,
          roleCode,
          cathegory,
          subj.objectId,
          this.policyService.isFromPreventive
        ).pipe(
          map(responseCheck => {
            const pr = this.lifeRoleService.getRoleDescription(new PolicyRole(roleCode, subj));
            return this.validationService.mapResponsePartyCompleteToCheck(response, responseCheck, pr);
          })
        );
      }),
      switchMap(resp => {
        return this.addValidationsForBenefAndReturnPartyConfirmed(resp, roleCode, cathegory, subj);
      })
    );
  }



  private addValidationsForBenefAndReturnPartyConfirmed(resp: PartyCheck, roleCode: string, cathegory: string, subj: Subject) {
    if (resp.errorMessages && resp.errorMessages.length > 0) {
      this.handleErrorMessagesFromPartyCompleted(resp, roleCode, cathegory, subj);
    } else {
      this.beneficiaryStatusErrorMessages = [];
    }
    return of({ partyConfirmed: !resp.errorMessages || resp.errorMessages.length === 0 });
  }

  /**
   * Handles error messages from the party completed response.
   * adds the error messages to the quotValidation service and to the lastBeneficiaryStatusErrorMessages array.
   * @param resp - The party check response object.
   * @param roleCode - The role code.
   * @param cathegory - The category.
   */
  private handleErrorMessagesFromPartyCompleted(resp: PartyCheck, roleCode: string, cathegory: string, subj: Subject) {
    resp.errorMessages.forEach(element => {
      const validationMsg = this.beneficiaryService.getValidationMsgByRoleCode(roleCode, cathegory);
      const validationServiceMessage = {
        code: validationMsg.code,
        message: {
          message: validationMsg.msg + element.errorDescription,
          type: ErrorType.ERROR
        }
      };
      this.quotValidation.setMessage(
        validationServiceMessage.code + '-' + subj.objectId + '-' + cathegory,
        validationServiceMessage.message
      );
      this.beneficiaryStatusErrorMessages.push(validationServiceMessage);
    });
  }

  getHeader(title: string): string {
    if (title !== 'lic_cover') {
      return this.translateService.getImmediate('lic_' + title.replace(/[^a-zA-Z0-9 ]/g, '').trim().replace(' ', ''));
    }
    return this.translateService.getImmediate(title);
  }
}

