/**
 * MORE READABILITY ADVICE
 * Please maintain an alphabetical order in each section
 */
import {AuthTranslationsKeys} from './auth-translations-keys';


export const AUTH_ES: AuthTranslationsKeys = {
    _AUTH_: {
        /* eslint-disable max-len */
        // _VIEW_FROM_{{_START_}}_TO_{{_END}}_OF_{{AUTHORIZATIONLISTLENGTH}}_ELEMENTS: "View from {{ start }} to {{ end}} of {{authorizationList.length}} elements",
        _ACTION_IS_MANDATORY: 'La acción es obligatoria',
        _ARE_YOU_SURE_TO_APPLY_THIS_ACTION: '¿Está seguro de que quiere solicitar esta acción?',
        _AUTHORIZATION_DEROGATION_LEVEL: 'Nivel de derogación de la autorización:',
        _AUTHORIZATION_LEVEL: 'Nivel de autorización:',
        _AUTHORIZATION_REQUEST_CODE: 'Código de petición de la autorización',
        _BACK: 'Atrás',
        _BLOCK: 'Bloquear',
        _BUY_POLICY: 'suscribir_póliza',
        _BY_USER: 'por el usuario',
        _CANCEL: 'Cancelar',
        _CANCEL_REQUEST: 'Cancelar solicitud',
        _CARD_TITLE_: 'Authorizations',
        _CHARACTERS: 'Caracteres',
        _CODE: 'Código',
        _COMMENT: 'Comentario:',
        _COMPETENCE_LEVEL: 'Nivel de competencia:',
        _COMPETENT_LEVEL: 'Nivel competente:',
        _COMPETENT_USER: 'Usuario competente:',
        _CONFIRM: 'confirmar',
        _CONFIRM_PROP_POL_AUTH: 'Confirm proposal/policy in Authorization',
        _CONFIRM_VARIATION: 'confirmar_variación',
        _CONTRACT_NUMBER: 'Número de contrato',
        _CONTRACT_TYPE: 'Tipo de contrato',
        _DATE: 'Fecha',
        _DEROGATION_LEVEL: 'Nivel de derogación:',
        _DESCRIPTION: 'Descripción',
        _EFFECTIVE_DATE: 'Fecha de vigencia',
        _ELEMENTS: 'elementos',
        _EMPTY: 'vacío',
        _ERROR: 'Error',
        _ERR_AUTH_DEROGATION_LEVEL: 'ERR_AUTH_DEROGATION_LEVEL',
        _ERR_AUTH_EXTRA_CHECK: 'ERR_AUTH_EXTRA_CHECK',
        _ERR_AUTH_NOT_OPERABLE: 'ERR_AUTH_NOT_OPERABLE',
        _ERR_AUTH_NOT_SAVED: 'ERR_AUTH_NOT_SAVED',
        _EVENT: 'Evento',
        _FILL_IN_REQUEST_STATUS_OR_USER: 'Complete el estado de solicitud o usuario',
        _FIND: 'encontrar',
        _HISTORY: 'Historial',
        _IN_ORDER_TO_ACCEPT_THE_REQUEST_ALL_DOCUMENTS_MUST_BE_VALIDATED: 'Para aceptar la solicitud, se tienen que validar todos los documentos',
        _ISSUE: 'emitir',
        _ISSUE_DATE: 'fecha de emisión',
        _ISSUE_POLICY: 'emisión_póliza',
        _ISSUE_PROPOSAL: 'emisión_propuesta',
        _ISSUE_USER: 'emitir usuario',
        _LAST_ACTION: 'Última acción:',
        _LAST_ACTION_DATE: 'Fecha de la última acción:',
        _LAST_EVENT: 'Último evento',
        _LAST_EXECUTED_ACTION: 'Última acción excluida',
        _MANAGEMENT_NODE: 'Nodo de gestión',
        _MODIFY_AUTHORIZATION_LABEL: 'Modificar autorización',
        _MODIFY_PROPOSAL: 'modificar_propuesta',
        _MODIFY_VARIATION: 'modificar_variación',
        _NEXT_STATUS: 'Siguiente estado',
        _NODE: 'Nodo',
        _NODE2: 'Nodo:',
        _COMPANY: 'Compañía:',
        _NODE_CODE: 'Nodo de gestión',
        _NODE_IS_MANDATORY: 'El nodo es obligatorio',
        _NOTES: 'Notas',
        _NOT_AVAILABLE: 'No disponible',
        _NO_ELEMENT_PRESENT: 'No hay ningún elemento presente',
        _OF: 'de',
        _OPERATION: 'Operación',
        _OPERATION_CONFIRMED: 'Se ha confirmado la operación',
        _OPERATION_WAS_ISSUED_ON: 'La operación se emitió el',
        _OPERATOR: 'Operador:',
        _OPERATOR_DEROGATION_LEVEL: 'Nivel de derogación del operador:',
        _OPERATOR_LEVEL: 'Nivel de operador:',
        _ORIGIN_TYPE: 'Tipo de origen:',
        _PM_POLICY_NUMBER: 'Póliza principal n.º',
        _PM_PROPOSAL_NUMBER: 'Propuesta principal n.º',
        _POLICY_NUMBER: 'Número de póliza',
        _POLICY_NUMBER_PM: 'Número de póliza principal',
        _POLICY_TYPE: 'Tipo de póliza',
        _POSSIBLE_ACTIONS: 'Posibles acciones',
        _POSSIBLE_DEROGATION_LEVEL: 'Posible nivel de derogación',
        _POSTSELL_CODE: 'Código posventa',
        _PRIORITY: 'Prioridad',
        _PRODUCT: 'Producto',
        _PRODUCT_TYPE: 'Tipo de producto',
        _PROPOSAL_NUMBER: 'Número de propuesta',
        _PROPOSAL_NUMBER_PM: 'Número de propuesta principal',
        _REFERENCE: 'Referencia',
        _REQUESTS_LIST: 'Lista de solicitudes',
        _REQUEST_DATE: 'Fecha de solicitud',
        _REQUEST_DATE_FROM: 'Fecha de solicitud desde',
        _REQUEST_DATE_FROM_IS_NOT_A_VALID_DATE: 'Fecha de solicitud desde no es una fecha válida',
        _REQUEST_DATE_FROM_MUST_BE_GREATER_THEN_REQUEST_DATE_TO: 'Fecha de solicitud debe ser mayor de la fecha de solicitud a',
        _REQUEST_DATE_TO: 'Fecha de solicitud a',
        _REQUEST_DATE_TO_IS_NOT_A_VALID_DATE: 'Fecha de solicitud no es una fecha válida',
        _REQUEST_DETAIL: 'Detalle de solicitud',
        _REQUEST_NODE: 'Nodo de solicitud',
        _REQUEST_NUMBER: 'Número de petición',
        _REQUEST_OPERATION: 'Solicitar operación',
        _REQUEST_STATUS: 'Estado de solicitud',
        _REQUEST_STATUS_VARIATION: 'Variación del estado de solicitud',
        _REQUEST_TYPE: 'Tipo de solicitud',
        _REQUEST_USER: 'Usuario de la solicitud:',
        _SALE_POINT_GROUP: 'Grupo de punto de venta',
        _SEARCH_UNDER_SUBNET: 'Búsqueda en subred',
        _SELECT_NODE: 'Seleccionar nodo',
        _STATUS: 'Estado:',
        _STATUS_AFTER_EVENT: 'Estado después del evento:',
        _STATUS_CHANGES_ARE_NOT_ADMITTED_FOR_THIS_AUTHORIZATION_REQUEST: 'No se admiten cambios de estado en esta solicitud de autorización',
        _STATUS_VARIATION: 'Variación de Estado',
        _SUBSCRIBER: 'Tomador',
        _SUBSYSTEM: 'Subsistema',
        _SUBSYSTEM_IS_MANDATORY: 'El subsistema es obligatorio',
        _TO: 'a',
        _TYPE: 'Tipo',
        _USER: 'Usuario',
        _USER_TYPE: 'Tipo de usuario',
        _VARIATION_SUMMARY: 'variación_resumen',
        _VIEW_ELEMENT: 'Ver elemento',
        _VIEW_FROM: 'Ver formulario',
        _WARNINGS: 'Advertencias',
        _WORKFLOW_STATUS: 'Estado del flujo de trabajo',
    },
    _AUTHORIZATION_SEARCH: 'Búsqueda de autorizaciones',
    _AUTHORIZATION_SEARCH_DETAIL: 'Detalle de búsqueda de autorizaciones',
};
