import {
  Component,
  EventEmitter,
  HostListener,
  Inject,
  Injector,
  OnDestroy,
  OnInit,
  Optional,
  Output,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { AbstractControl, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslationWrapperService } from '../../../i18n/translation-wrapper.service';
import { QuestionnairesManagerComponent } from '@rgi/questionnaires-manager';
import { QuestionnaireCacheService } from '@rgi/questionnaires-manager';
import { Observable, of, Subscription, throwError, combineLatest } from 'rxjs';
import { catchError, distinctUntilChanged, switchMap, take, tap } from 'rxjs/operators';
import { CardsNavigationService } from '../../cards-navigation/cards-navigation.service';
import {
  ExtensionProperty, Frequency, LicCustomProperties, PaymentFrequencyValue, PaymentTypeCode, PaymentTypeID, PaymentTypeOption,
  Roles, TypeFractionation
} from '../../enum/life-issue.enum';
import { Action, ActionServiceErrorMessage, ActionServiceResponse } from '../../models/actions.model';
import { AdapterPayments, PAYMENT_FIELDS_CODES } from '../../models/adapterPayments.model';
import { Cover, MasterPolicy } from '../../models/masterpolicy.model';
import { PaymentFields, PaymentFrequency } from '../../models/meansofpayment.model';
import { FormFieldDefinition } from '../../models/pip.model';
import { Agreement, Factor, Product } from '../../models/policy.model';
import { ErrorType, LifeIssueMessage } from '../../models/response.model';
import { Settlement } from '../../models/settlement.model';
import { BranchesService } from '../../services/branches.service';
import { LicCacheService } from '../../services/lic-cache.service';
import { LifeRoleService } from '../../services/life-role.service';
import { NavigationSummaryService } from '../../services/navigation-summary.service';
import { MasterPolicyService } from '../../services/policy-master.service';
import { PolicyService } from '../../services/policy-service';
import { ReinvestementService } from '../../services/reinvestment.service';
import { ValidationSubjectsService } from '../../services/validation-subjects.service';
import { LicDateUtils } from '../../utils/lic-date-utils';
import { LicErrorsUtils } from '../../utils/lic-errors-utils';
import { LicPipUtils } from '../../utils/lic-pip-utils';
import { ReinvestmentModalComponent } from '../reinvestment-modal/reinvestment-modal.component';
import { Payments, PaymentType } from './../../models/meansofpayment.model';
import {
  InstalmentType,
  Instance,
  LifeParty,
  PolicyModel,
  Proposal,
  Questionnaire,
  QuestionnaireValue
} from './../../models/policy.model';
import { ActionsService, Code } from './../../services/actions.service';
import { CustomPropertiesService } from './../../services/custom-properties.service';
import { LifeSessionService } from './../../services/life-session-service';
import { SystemPropertiesService } from './../../services/system-properties.service';
import { emptyStringValidator } from './utils/lic-custom-validators';

@Component({
  selector: 'lic-dati-amm',
  templateUrl: './dati-amm.component.html',
  styleUrls: ['./dati-amm.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class DatiAmmComponent implements OnInit, OnDestroy {

  @Output() navigation = new EventEmitter<string>();
  @Output() eventPropagation = new EventEmitter<any>();

  @ViewChild(QuestionnairesManagerComponent) qMc: QuestionnairesManagerComponent;

  public product: Product;
  public proposal: Proposal;

  public form: UntypedFormGroup;
  public formPagamentiRataFirma: UntypedFormGroup;
  public formClauses: UntypedFormGroup;
  public formPagamentiRateSuccessive: UntypedFormGroup;
  public questForm: UntypedFormGroup;

  public ptfContactsManagement = '';
  public lifePolicyContactsManagement = '';
  public effectiveDate: string;
  public ppevoQuestKey: string;
  public operator;


  /* DATI DI PAGAMENTO */
  public paymentFirst: Payments;
  public paymentSecond: Payments;
  public allPayments: Payments[] = [];
  public firstInstPay: any[] = [];
  public nextInstPay: any[] = [];
  public firstEqualNext = false;

  public showFirstInst: boolean;
  public showNextInst: boolean;

  public paymentField1: PaymentFields[];
  public paymentField2: PaymentFields[];
  public showExtrapayForFirst = false;
  public showExtrapayForSecond = false;

  /* ALTRO */
  public message = [];
  public semaphore = false;
  public tipoOwner = 1;
  public propertyType = 1;
  public cosignedRoleCode = Roles.COSIGNED;

  /* FILIALI */
  public branches: FormFieldDefinition;

  /* QUESTIONARI */
  private _validQuestsCode: Map<string, boolean> = new Map<string, boolean>();

  public questionnairesList: Questionnaire[] = [];
  public ppevoQuestionnaires: Questionnaire[] = [];
  public showSectionQuest = false;
  public showQuestPPEVO = false;
  public questViewMap = new Map<string, boolean>();
  public questMap = new Map<string, QuestionnaireValue>();
  public productStructure: any;
  public policyMasterMap: Map<number, object[]> = new Map();
  public rowsPolicyMasterData: number[] = [];
  public policyMasterData: object[] = [];

  public selectedClauseText: string;

  public coversMap: Map<number, object[]>;
  public rowsCovers: number[];
  public rowsDescCovers: string[] = [];
  public coversData: object[] = [];

  public _warningMessages: string[] = [];
  public messageFromPreviousPage: LifeIssueMessage[] = [];

  public reinvestmentFactor: Factor;
  public isReinvestment = false;
  public settlements: Settlement[] = [];
  readonly REINVESTMENT_CODE = '_VREIN';
  public proposalNumber: string;
  public isPrevalorizationSddEnabled: boolean;
  public isCheckSdd: boolean;
  public disablesdd = false;

  private noUpdate = false;

  protected $subscriptions: Subscription[] = [];

  private readonly paymentsSortedByType = this.policyService.mainProposal.proposal.payments.sort((a, b) => {
    if (Number(a.instalmentType.codice) > Number(b.instalmentType.codice)) {
        return 1;
    }
    if (Number(a.instalmentType.codice) < Number(b.instalmentType.codice)) {
        return -1;
    }
  });
  groupedProductFactors: { factors: Factor[], description: string }[] = [];

  get validQuestsCode(): Map<string, boolean> {
    return this._validQuestsCode;
  }

  get showAccordionPolicyAddress(): boolean {
    // TODO: quando si gestiranno (JIRA ASMC-1710) gli altri tipi recapito bisognerà togliere il ptfContactsManagement === '4'
    return !this.policyService.isFromPreventive && !this.policyService.isFromQuoteModification
      && this.lifePolicyContactsManagement !== '1';
  }

  get showAccordionPayments(): boolean {
    return !this.policyService.isFromPreventive && !this.policyService.isFromQuoteModification;
  }

  get showAccordionQuestionnaires(): boolean {
    return !this.policyService.isFromPreventive &&
      !this.policyService.isFromQuoteModification && (this.showSectionQuest || this.showQuestPPEVO);
  }

  get showAddPolicyAddress(): boolean {
    return !this.policyService.isFromPreventive && !this.policyService.isFromQuoteModification && this.lifePolicyContactsManagement === '3';
  }

  get validationMessages(): string[] {
    return this.message;
  }

  get warningMessages(): string[] {
    const warningMessages = LicErrorsUtils.getPreviousPageMessages(this.messageFromPreviousPage, ErrorType.WARNING);
    return this._warningMessages.concat(warningMessages.map(msg => msg.message));
  }

  get blockingMessages(): string[] {
    return LicErrorsUtils.getPreviousPageMessages(this.messageFromPreviousPage, ErrorType.ERROR).map(msg => msg.message);
  }

  get disablecondition(): boolean {
    const viewNext = this.getPropertyBoleanValue(
      this.policyService.mainProposal.quote.product.customProperties,
      LicCustomProperties.RATA_SUCCESSIVA);
    return (viewNext && this.form.get('paymentFrequency').value === '000006' &&
      this.formPagamentiRateSuccessive.get('paymentInfoNext').value === '5' &&
      this.isPrevalorizationSddEnabled);
  }


  get authMessages(): string[] {
    return LicErrorsUtils.getPreviousPageMessages(this.messageFromPreviousPage, ErrorType.AUTH).map(msg => msg.message);
  }

  public getSavedUUIDs() {
    return this.policyService.getPassProProSavedQuestionnaireUUIDs(null);
  }

  get isFromPreventive(): boolean {
    return this.policyService.isFromPreventive || this.policyService.isFromQuoteModification;
  }

  protected getContractID() {
    return this.policyService.mainProposal.proposal.contractData &&
      this.policyService.mainProposal.proposal.contractData.length > 0 ?
      this.policyService.mainProposal.proposal.contractData[0].contractId :
      undefined;
  }

  constructor(
    protected policyService: PolicyService,
    protected cardsNavigationService: CardsNavigationService,
    protected paymentInfo: CustomPropertiesService,
    @Inject('$injector') protected injector: Injector,
    protected systemPropertiesService: SystemPropertiesService,
    protected lifeRoleService: LifeRoleService,
    protected lifeSessionService: LifeSessionService,
    protected actionService: ActionsService,
    protected validationService: ValidationSubjectsService,
    protected translateService: TranslationWrapperService,
    protected navigationSummaryService: NavigationSummaryService,
    protected branchesService: BranchesService,
    protected policyMasterService: MasterPolicyService,
    protected cacheInvestments: LicCacheService,
    @Optional() protected cache: QuestionnaireCacheService,
    protected reinvestementService: ReinvestementService,
    protected modalService: NgbModal) {
    }

  @HostListener('changeVal', ['$event'])
  onChangeVal(event) {
    if (event.detail === this.REINVESTMENT_CODE) {
      const reinvestControl = this.form.get(this.REINVESTMENT_CODE);
      if (reinvestControl && reinvestControl.value === '1') {
        if (this.settlements.length > 0) {
          this.isReinvestment = true;
          this.openReinvestments(reinvestControl);
        }
      } else {
        this.isReinvestment = false;
        const skipReloadOfInvestment = this.policyService.getExtensionProperty(ExtensionProperty.REINVESTMENT_INITIALIZED);
        if (!skipReloadOfInvestment) {
          this.policyService.pushExtensionProperty(ExtensionProperty.REINVESTMENT_INITIALIZED, 'true');
        }

        if (!this.noUpdate) {
          if (this.settlements.length > 0) {
            this.policyService.mainProposal.proposal.extensionData.properties =
              this.policyService.mainProposal.proposal.extensionData.properties
                .filter(p => p.chiave !== ExtensionProperty.REINVEST_LIQ);

            this.updateFactors(false);
          }
        } else {
          this.noUpdate = false;
        }
      }

    }
  }

  ngOnInit(): void {
    this.$subscriptions.push(
      this.initComponentVar().pipe(
        switchMap(() => combineLatest([this.initBranches(), this.initPayments(), this.initReinvestments()]))
      ).subscribe(() => {

        /* QUESTIONNAIRES */
        this.initPPQuestionnaires();

        // Questionari PPEVO
        this.initPPEVOQuestionnaires();

        /* ADDRESS */
        this.ptfContactsManagement = this.systemPropertiesService.getStoredSystemProp('PortfolioContactsManagement');
        this.lifePolicyContactsManagement = this.systemPropertiesService.getStoredSystemProp('LifePolicyContactsManagement');
      })
    );
  }

  protected initComponentVar(): Observable<any> {
    this.operator = this.lifeSessionService.getOperator();
    this.isPrevalorizationSddEnabled = this.getPropertyBoleanValue(
      this.policyService.mainProposal.quote.product.customProperties,
      LicCustomProperties.PREVALORIZZAZIONE_SDD
    );

    this.messageFromPreviousPage = this.cardsNavigationService.getPreviousMessages(this.getErrorStep());
    this.createPolMasterData();
    this.product = this.policyService.mainProposal.quote.product;
    this.allPayments = this.policyService.mainProposal.proposal.payments;

    this.navigationSummaryService.setDescriptions(
      this.lifeRoleService.fill(this.lifeRoleService.getPolicyHolder()),
      this.policyService.mainProposal.quote.product.description
    );
    this.navigationSummaryService.updateNavigationSummary();

    this.initMainForm();

    this.groupByFunctionForFactor();

    return of({});
  }

  private groupByFunctionForFactor() {
    this.groupedProductFactors = [];

    // Ordina i fattori in base alla proprietà "order" (se presente)
    const sortedFactors = [...this.getPolicyFactor()].sort((a, b) => (a.order || 0) - (b.order || 0));

    sortedFactors.forEach((factor) => {
      const property = factor.customProperties.find(p => p.code === LicCustomProperties.LIFE_POLICY_VARIABLE_GROUPING);
      const description = property && property.values && property.values[0].description;

      // Trova l'indice del gruppo corrente nell'array
      const groupIndex = this.groupedProductFactors.findIndex(group => group.description === description);

      if (groupIndex === -1) {
        this.groupedProductFactors.push({
          description,
          factors: [factor],
        });
      } else {
        this.groupedProductFactors[groupIndex].factors.push(factor);
      }
    });
  }


  protected getErrorStep(): number {
    return this.cardsNavigationService.STEP.DATI_AMMINISTRATIVI.pos;
  }

  /** @description inizialized payment from the formControl to the field visibility */
  initPayments(): Observable<any> {
    this.initPymentForm();
    this.paymentVisibility();
    return this.initPaymentValue();
  }

  /** @description chiamata a servizio filiali */
  protected initBranches(): Observable<any> {
    return this.branchesService.getBranches(this.lifeSessionService.idPv).pipe(
      switchMap(resp => {
        this.branches = resp;
        if (!!this.branches && !!this.policyService.mainProposal.proposal.policyVersion) {
          if (!!this.policyService.mainProposal.proposal.policyVersion.branch) {
            this.form.get('branch').setValue(this.policyService.mainProposal.proposal.policyVersion.branch.objectId);
          }
        }
        return of({});
      })
    );
  }

  protected initReinvestments(): Observable<any> {
    this.reinvestmentFactor = this.product.factors.find(f => f.code === this.REINVESTMENT_CODE);
    if (this.reinvestmentFactor) {
      this.form.addControl(this.reinvestmentFactor.code, new UntypedFormControl(this.reinvestmentFactor.value));
      const role = this.policyService.mainProposal.proposal.lifeParty.find(item => item.party.partyRole === '1');
      if (role) {
        // Get the contractId for policies that are in authorization
        const contractId = this.getContractID();
        return this.reinvestementService.getSettlements(1, role.party.objectID, Number(contractId)).pipe(
          switchMap(sets => {
            this.settlements = sets;
            this.isReinvestment = this.reinvestmentFactor.value === '1' && this.settlements.length > 0;
            return of({});
          })
        );
      }
    }
    return of({});
  }

  protected initMainForm(): void {
    if (!!this.product.clauses && !!this.product.clauses.length) {
      this.initClauseForm();
    }
    this.form = new UntypedFormGroup({});
    this.autoCompleteAgreement();
    this.autoCompletePaymentFreq();
    this.form.addControl('branch', new UntypedFormControl());
    this.form.addControl('cosigned', new UntypedFormControl(this.lifeRoleService.getCosigned()));
    this.formPagamentiRataFirma = new UntypedFormGroup({});


    this.formPagamentiRataFirma.addControl('paymentInfoFirst', new UntypedFormControl(null, Validators.required));

    this.formPagamentiRateSuccessive = new UntypedFormGroup({});
    this.formPagamentiRateSuccessive.addControl('paymentInfoNext', new UntypedFormControl(null, Validators.required));

    if (this.handleCoapplicant() && this.isMutuoCointestato() && this.isPiuAssicurati()) {
      this.jointLoanHandling(this.product.factors);
    } else {
      this.policyService.setCoSigner(false);
    }
    if (this.policyService.mainProposal.quote.product.applicationCertificateManagement) {
      this.form.get('paymentFrequency').disable();
      this.form.get('agreement').disable();
    }
  }

  private autoCompletePaymentFreq() {
    const onlyOneFrequency = !!this.product.paymentFrequencies && this.product.paymentFrequencies.length === 1;
    const paymentFrequency = onlyOneFrequency && !this.product.paymentFrequencyCode ? this.product.paymentFrequencies[0].code : this.product.paymentFrequencyCode;
    this.form.addControl('paymentFrequency', new UntypedFormControl(paymentFrequency, Validators.required));
    if (onlyOneFrequency) {
      this.form.get('paymentFrequency').disable();
    }
  }

  private autoCompleteAgreement() {
    let agreement = this.product.agreementCode;
    this.form.addControl('agreement', new UntypedFormControl(agreement))
  }

  private initClauseForm() {
    this.formClauses = new UntypedFormGroup({});

    // Loop through the quote clauses
    for (const data of this.product.clauses) {
        // Check if the clause is in the proposal
        const proposalClauses = this.policyService.mainProposal.proposal.clauses;
        const proposalClause = proposalClauses ? proposalClauses.find(x => x.code === data.code) : null;

        const controlName = data.code;
        const controlValue = data.selected;
        const controlDisabled = !data.enabled;
        const controlTextType = data.textType;

        const controlQuoteText = data.text;
        const controlProposalText = proposalClause ? proposalClause.text : undefined;

        const validators = [];
        if (data.compulsoryText && controlValue) {
          validators.push(emptyStringValidator, Validators.required);
        }

        // Create the nested group
        const selectedValue = new UntypedFormControl({ value: controlValue, disabled: controlDisabled });
        const textValue =  new UntypedFormControl({
          value: controlProposalText ? controlProposalText : controlQuoteText,
          disabled: controlTextType === 0 ? true : false,
        });
        textValue.setValidators(validators);
        textValue.updateValueAndValidity();
        const nestedGroup = new UntypedFormGroup({
          selectedValue,
          textValue
        });

        // Add the nested group to the formClauses form group
        this.formClauses.addControl(controlName, nestedGroup);
    }
  }

  public saveClauseText(clauseText: string) {
    this.selectedClauseText = clauseText;
  }

  protected resetClauseText(clauseCode: any, save?: boolean) {
    this.formClauses.get(clauseCode).get('textValue').setValue(this.selectedClauseText);
  }

  public checkClauseValidation(clauseCode: any, save?: boolean) {
    // if you don't wish to save the clause text, just return
    if (!save) {
      this.resetClauseText(clauseCode, save);
    }

    const clause = this.product.clauses.find((c) => c.code === clauseCode);
    // Custom validator function

    if (clause && clause.compulsoryText) {
      const textValueControl = this.formClauses.get(clauseCode).get('textValue');

      if (textValueControl) {
        textValueControl.setValidators([emptyStringValidator, Validators.required]);
        textValueControl.updateValueAndValidity();
      }
    }
  }

  protected initPPQuestionnaires(): void {
    this.questForm = new UntypedFormGroup({});
    this.policyService.mainProposal.quote.product.questionnaires.forEach(
      (q) => {
        this.questViewMap.set(q.code, q.included);
      });
    this.questionnairesList = this.policyService.mainProposal.quote.product.questionnaires // TODO multi-assicurato
      .filter((quest: Questionnaire) => quest.included === true && quest.codeType !== 'QPPEVO');
    this.showSectionQuest = this.questionnairesList.length > 0;
  }

  protected initPPEVOQuestionnaires() {
    this.effectiveDate = this.policyService.mainProposal.proposal.policyVersion.effectiveDate;
    this.ppevoQuestKey = this.generateQuestKey();
    this.productStructure = this.policyService.mainProposal.quote.product;
    this.ppevoQuestionnaires = this.policyService.mainProposal.quote.product.questionnaires
      .filter((quest: Questionnaire) => quest.included === true && quest.codeType === 'QPPEVO');

    if (this.cache && !this.cache.get(this.ppevoQuestKey)) {
      this.ppevoQuestionnaires.forEach((passQuest: Questionnaire) => this._validQuestsCode.set(passQuest.code, passQuest.compulsory));
      this.showQuestPPEVO = this.ppevoQuestionnaires.length > 0;
    }

    if (this.cache) {
      const quests = this.cache.get(this.ppevoQuestKey);
      if (quests && quests.size > 0) {
        this.showQuestPPEVO = true;
      }
    }
  }

  public createPolMasterData() {
    const idMasterPol = this.policyService.mainProposal.proposal.idMasterPolicy;
    if (idMasterPol != null) {
      this.eventPropagation.emit('startLoader');
      this.$subscriptions.push(
        this.policyMasterService.getMasterPolicyById(idMasterPol).subscribe((pm: MasterPolicy) => {
          this.policyMasterService.setMasterPolicy(pm);
          this.policyService.masterPolicyStatus = pm.status;
          this.putOnTable(this.policyMasterData, this.translateService.getImmediate('lic_Policynumber'), pm.policyNumber);
          this.putOnTable(this.policyMasterData, this.translateService.getImmediate('lic_Policyholder'), pm.subscriber);
          this.policyMasterMap = this.generateDynamicTable(this.policyMasterData);
          this.rowsPolicyMasterData = this.composeArray(Math.ceil(this.policyMasterData.length / 2));
          pm.covers.forEach(cover => {
            this.putOnTable(this.coversData, this.translateService.getImmediate('lic_guarantee'), cover.description);
            this.putOnTable(this.coversData, this.translateService.getImmediate('lic_State'), this.createLabel(cover.selected, cover.enabled));
          });
          this.coversMap = this.generateDynamicTable(this.coversData);
          this.rowsCovers = this.composeArray(Math.ceil(this.coversData.length / 2));
          this.eventPropagation.emit('stopLoader');
        })
      );
    }
  }

  public createLabel(selected: boolean, enabled: boolean): string {
    let label = '';
    if (selected && enabled) {
      label = 'lic_ACTIVE_STATE';
    } else if (selected) {
      label = 'lic_MANDATORY_STATE';
    } else if (enabled) {
      label = 'lic_DEACTIVATE_STATE';
    }

    return this.translateService.getImmediate(label);
  }

  public findCoverDesc(cover: Cover) {
    let desc = '';
    const product: Product = this.policyService.mainProposal.quote.product;
    if (product.assets != null) {
      product.assets.forEach(asset => {
        asset.instances.forEach(inst => {
          inst.sections.forEach(section => {
            const unit = section.units.filter(u => {
              return u.riskCode === cover.code;
            });
            if (unit != null && unit.length > 0) {
              desc = unit[0].shortDescription;
            }
          });
        });
      });
    }
    return desc;
  }

  public putOnTable(table: object[], key: string, value: string) {
    if (value) {
      table.push({ chiave: key, valore: value });
    }
  }

  public generateDynamicTable(objectsArray): Map<number, object[]> {
    let i = 0;
    const numberArray = [];
    objectsArray.map(() => {
      numberArray.push(i);
      i++;
    });
    return this.chunkSize(numberArray, 2, objectsArray);
  }

  public chunkSize(array: number[], size: number, objectsArray): Map<number, object[]> {
    const indexMap = new Map<number, object[]>();
    let counter = 0;
    [].concat.apply([],
      array.map((i) => {
        const objectsForEachRow = [];
        if (i % size === 0) {
          array.slice(i, i + size).map((element) => objectsForEachRow.push(objectsArray[element]));
          indexMap.set(counter, objectsForEachRow);
          counter++;
        }
      })
    );
    return indexMap;
  }

  public composeArray(nRow: number): number[] {
    return Array(nRow).fill(0).map((x, i) => i);
  }

  /** @description Init of the payment form by adding the validations */
  public initPymentForm() {
    if (this.policyService.mainProposal.proposal.payments.length > 0) {
      this.paymentsSortedByType.forEach((p) => {
        if (p.instalmentType.codice === PaymentTypeOption.FIRST_INST) {
          this.formPagamentiRataFirma.get('paymentInfoFirst').setValue(p.paymentType.codice);
          this.formPagamentiRataFirma.get('paymentInfoFirst').setValidators(Validators.required);
          this.paymentFirst = new Payments(p.instalmentType, p.paymentType);
        } else {
          this.formPagamentiRateSuccessive.get('paymentInfoNext').setValue(p.paymentType.codice);
          this.formPagamentiRateSuccessive.get('paymentInfoNext').setValidators(Validators.required);
          this.paymentSecond = new Payments(p.instalmentType, p.paymentType);
        }
      });
    }
  }


  /** @description prints IBAN message: 'IBAN is not formally valid' */
  public printIBANMessage() {
    this.message = [];
    this.message.push(this.translateService.getImmediate('lic_IBAN_formally_incorrect'));
  }

  /** @description chiamato al change dei pagamenti aggiuntivi */
  public modifyAddPayments(): void {
    if (this.paymentField1 !== undefined) {
      this.valAddPaymentValue(this.paymentField1, this.formPagamentiRataFirma);
    }
    /* prevalorizzazione uguale alla rata firma */
    if (this.firstEqualNext) {
      this.paymentField1.map(pay => { // copio i valori del form della rata alla firma
        const control = this.formPagamentiRataFirma.get(pay.name);
        if (this.formPagamentiRateSuccessive.get(pay.name) != null) {
          this.formPagamentiRateSuccessive.get(pay.name).setValue(control.value);
        } else {
          this.formPagamentiRateSuccessive.addControl(pay.name, new UntypedFormControl(control.value));
        }
      });
      this.valAddPaymentValue(this.paymentField2, this.formPagamentiRateSuccessive);
    } else if (this.paymentField2 !== undefined) {
      this.valAddPaymentValue(this.paymentField2, this.formPagamentiRateSuccessive);
    }
  }

  /** @description setta il valore del vettore a partire dal form */
  public valAddPaymentValue(paymentField: any, form: UntypedFormGroup): void {
    if (paymentField !== undefined) {
      paymentField.map(pay => {

        let paymentValue = null;

        if (form.controls[pay.name] != null) {
          if (pay.type === 'D' && form.controls[pay.name].value !== null) {
            paymentValue = LicDateUtils.convertToYMD(form.controls[pay.name].value);
          } else {
            if (pay.name === PAYMENT_FIELDS_CODES.IBAN && (!form.controls[pay.name].invalid)) {
              this.message = [];
            }
            paymentValue = form.controls[pay.name].value;
          }
          pay.value = paymentValue;
          if (pay.type === 'R' && pay.value !== null) {
            pay.value = pay.value.replace(',', '.');
          }
        }
      });
    }
  }

  public valAddFirstEqualNext(paymentField: any, formFirst: UntypedFormGroup, formNext: UntypedFormGroup) {
    if (paymentField !== undefined) {
      paymentField.forEach(pay => {

        let paymentValue = null;

        if (formFirst.get(pay.name) != null) {
          if (pay.type === 'D' && formFirst.controls[pay.name].value !== null) {
            paymentValue = LicDateUtils.convertToYMD(formFirst.controls[pay.name].value);
          } else {
            if (pay.name === PAYMENT_FIELDS_CODES.IBAN && (!formFirst.controls[pay.name].invalid)) {
              this.message = [];
            }
            paymentValue = formFirst.controls[pay.name].value;
          }
          pay.value = paymentValue;
          if (pay.type === 'R' && pay.value !== null) {
            pay.value = pay.value.replace(',', '.');
          }
        }
        formNext.get(pay.name).setValue(pay.value);
      });
    }
  }

  // cambio di valore della select della rata alla firma
  public changeFirstPayments(event) {
    if (this.firstInstPay !== null) {
      this.allPayments = [];
      const controlValue = this.formPagamentiRataFirma.getRawValue().paymentInfoFirst;
      const filteredVal = this.firstInstPay.find(meanOfPaym => meanOfPaym.meanOfPayment.identification === controlValue);

      let valDescription = null;
      if (filteredVal != null) {
        valDescription = Object.assign({}, filteredVal);
      }
      if (valDescription != null) {
        if (this.paymentField1 !== undefined) {
          this.paymentField1.map(p1 => {
            this.formPagamentiRataFirma.removeControl(p1.name);
            this.paymentField1.map((p) => {
              if (p.name === p1.name) {
                p.value = null;
              }
            });
          });
        }
        /* this.cleanPayments(true, false); */
        this.paymentFirst = new Payments(
          new InstalmentType(PaymentTypeOption.FIRST_INST, 'lic_first_instalment'),
          new PaymentType(controlValue, valDescription.meanOfPayment.description));
        this.extraPaymentsFirstPayments(controlValue);
        this.setPayment(this.paymentField1, this.formPagamentiRataFirma);
      } else {
        this.cleanPayments(true, false);
      }
      if (this.firstEqualNext) {
        // il valore nella form non è stato modificato da frontend quindi devo forzarlo
        this.formPagamentiRateSuccessive.get('paymentInfoNext').setValue(controlValue);
        this.changeNextPayments(controlValue); // ripeto il metodo per il next
      } else {
        // effettuo la chiamata solo quando non sono legati, se sono legati la chiamata viene effettuata nel changeNext
        this.checkAllPayments();
        this.updateFactors(false);
      }
    }
  }

  // cambio di valore della select delle rate successive
  public changeNextPayments(event) {
    if (this.nextInstPay !== null) {
      this.allPayments = [];
      const controlValue = this.formPagamentiRateSuccessive.getRawValue().paymentInfoNext;
      const filteredVal = this.nextInstPay.find(meanOfPaym => meanOfPaym.meanOfPayment.identification === controlValue);

      let valDescription = null;
      if (filteredVal != null) {
        valDescription = Object.assign({}, filteredVal);
      }
      if (valDescription != null) {
        if (this.paymentField2 !== undefined) {
          this.paymentField2.map(p2 => {
            this.formPagamentiRateSuccessive.removeControl(p2.name);
            this.paymentField2.map((p) => {
              if (p.name === p2.name) {
                p.value = null;
              }
            });
          });
        }
        /* this.cleanPayments(false, true); */
        this.paymentSecond = new Payments(new InstalmentType(PaymentTypeOption.NEXT_INST, 'lic_next_instalment_premium'),
          new PaymentType(controlValue, valDescription.meanOfPayment.description));
        this.extraPaymentsSecondPayments(controlValue);
        this.setPayment(this.paymentField2, this.formPagamentiRateSuccessive);
      } else {
        this.cleanPayments(false, true);
      }
    }

    this.checkAllPayments();
    this.updateFactors(false);
  }

  public changeConv(event) {
    this.eventPropagation.emit('startLoader');
    this.updateFactors(false); // aggiorno dati perchè i fattori potrebbero cambiare
  }

  public changeFraz(event) {
    this.eventPropagation.emit('startLoader');
    this.paymentVisibility();
    if (!this.showNextInst && !this.firstEqualNext) {
      this.cleanPayments(false, true);
    }
    this.updateFactors(false);
  }


  public getTypePaymentFrequency(codFreq: string) {
    const pFreqType = this.policyService.mainProposal.quote.product.paymentFrequencies.find(meanOfPaym =>
      meanOfPaym.code === codFreq);
    if (pFreqType !== undefined) {
      return pFreqType.paymentFrequencyType;
    }
    return null;
  }

  public paymentVisibility(): void {
    this.firstEqualNext = this.getPropertyBoleanValue(
      this.policyService.mainProposal.quote.product.customProperties,
      LicCustomProperties.RATA_FIRMA_EQUAL_RATA_SUCCESSIVA
    );
    this.showFirstInst = this.getPropertyBoleanValue(
      this.policyService.mainProposal.quote.product.customProperties,
      LicCustomProperties.RATA_FIRMA
    );
    const viewNext = this.getPropertyBoleanValue(
      this.policyService.mainProposal.quote.product.customProperties,
      LicCustomProperties.RATA_SUCCESSIVA
    );

    // prendo valore del frazionamento direttamente dal form control e piloto visualizzazione delle rate successive
    const typeFreq = this.getTypePaymentFrequency(this.form.controls.paymentFrequency.value);
    if (typeFreq !== PaymentFrequencyValue.UNICO && viewNext) {
      this.showNextInst = true;
      this.firstEqualNext ? this.formPagamentiRateSuccessive.disable() : this.formPagamentiRateSuccessive.enable();
    } else {
      this.showNextInst = false;
      this.formPagamentiRateSuccessive.disable();
    }
  }

  public getPropertyBoleanValue(properties, propCode): boolean {
    const prop = properties.find(p => p.code === propCode);
    return !!prop && prop.value === 'true' ? true : false;
  }

  public getPaymentRequest(product: string, func: string, subfunc: string, node: string) {
    const request = {
      paymentConfigInput: {
        product: this.policyService.mainProposal.quote.product.code,
        function: func,
        subfunction: subfunc,
        paymentType: PaymentTypeOption.FIRST_INST,
        nodeId: node
      }
    };

    return request;
  }

  public initPaymentValue(): Observable<any> {
    if (!this.showFirstInst) {
      this.formPagamentiRataFirma.get('paymentInfoFirst').disable();
    }
    if (!this.showNextInst && !this.firstEqualNext) {
      this.formPagamentiRateSuccessive.get('paymentInfoNext').disable();
    }

    const $firstInstPayment = this.paymentInfo.get_meansOfPayment(
      this.getPaymentRequest(this.product.code, 'LIFE_CONTR', 'FIRST_INST', this.lifeSessionService.idPvManagement));

    const $nextInstPayment = this.paymentInfo.get_meansOfPayment(
      this.getPaymentRequest(this.product.code, 'LIFE_CONTR', 'NEXT_INST', this.lifeSessionService.idPvManagement));

    return combineLatest([$firstInstPayment, $nextInstPayment]).pipe(
      switchMap(([firstInstPayment, nextInstPayment]) => {
        if (firstInstPayment) {
          this.firstInstPay = this.sortPaymentsByDescription(firstInstPayment.meansOfPayment.debMeansOfPayment);
        }
        if (nextInstPayment) {
          this.nextInstPay = this.sortPaymentsByDescription(nextInstPayment.meansOfPayment.debMeansOfPayment);
        }
        this.setValueOfExtraPaymentsPostSubmit();

        return of({});
      })
    );
  }

  // prende la lista di dati aggiuntivi per il tipo di pagamento selezionato
  public extraPaymentsFirstPayments(codice: string) {
    if (codice !== null) {
      const valExtraPay = Object.assign({}, this.firstInstPay.find(meanOfPaym => meanOfPaym.meanOfPayment.identification === codice));
      if (valExtraPay != null) {
        if (valExtraPay.paymentFields.length !== 0 && this.showFirstInst) {
          this.showExtrapayForFirst = true;
          this.paymentField1 = valExtraPay.paymentFields;
        } else {
          // il metodo selezionato non ha campi aggiuntivi o non è visibile
          this.showExtrapayForFirst = false;
        }
      }
    }
  }

  // prende la lista di dati aggiuntivi per il tipo di pagamento selezionato
  public extraPaymentsSecondPayments(codice: string) {
    if (codice !== null) {
      const valExtraPay: any = Object.assign({}, this.nextInstPay.find(meanOfPaym => meanOfPaym.meanOfPayment.identification === codice));
      if (valExtraPay != null) {
        if (valExtraPay.paymentFields.length !== 0) {
          if (this.showNextInst) {
            this.showExtrapayForSecond = true;
            this.paymentField2 = valExtraPay.paymentFields;
          } else if (this.firstEqualNext) {
            this.paymentField2 = valExtraPay.paymentFields;
          } else {
            // non visibile
            this.showExtrapayForSecond = false;
          }
        } else {
          // il metodo selezionato non ha campi aggiuntivi
          this.showExtrapayForSecond = false;
        }
      }
    }
  }

  public setPayment(pagamenti, form: UntypedFormGroup) {
    if (pagamenti != null) {
      const dis = form.disabled;
      pagamenti.map((x: PaymentFields) => {
        form.addControl(x.name, new UntypedFormControl(x.value));
        /* if (this.disableBoolean) {
          this.parentForm.controls[x.name].disable();
        } */
        if (dis) {
          form.get(x.name).disable();
        }
      });
    }
  }

  public setValueOfExtraPaymentsPostSubmit() {
    const adapterExtraPaym: AdapterPayments = new AdapterPayments();
    if (this.policyService.mainProposal.proposal.payments.length > 0) {
      this.paymentsSortedByType.forEach((p) => {
        if (p.instalmentType.codice === PaymentTypeOption.FIRST_INST) {
          this.extraPaymentsFirstPayments(p.paymentType.codice);
          this.setPayment(this.paymentField1, this.formPagamentiRataFirma);
          adapterExtraPaym.getMeanOfPaymentFieldName(this.formPagamentiRataFirma, p); // riempie con descrizioni
          this.valAddPaymentValue(this.paymentField1, this.formPagamentiRataFirma);
        } else if (p.instalmentType.codice === PaymentTypeOption.NEXT_INST) {
          this.extraPaymentsSecondPayments(p.paymentType.codice);
          this.setPayment(this.paymentField2, this.formPagamentiRateSuccessive);
          adapterExtraPaym.getMeanOfPaymentFieldName(this.formPagamentiRateSuccessive, p);
          /* this.valAddPaymentValue(this.paymentField2, this.formPagamentiRateSuccessive); */
          if (this.firstEqualNext) {
            this.valAddFirstEqualNext(this.paymentField2, this.formPagamentiRataFirma, this.formPagamentiRateSuccessive);
          } else {
            this.valAddPaymentValue(this.paymentField2, this.formPagamentiRateSuccessive);
          }
        }
      });
    }
  }

  public sortPaymentsByDescription(paymentToSort: any[]) {
    paymentToSort.sort((a, b) => {
      if (a.description < b.description) {
        return -1;
      }
      if (a.description > b.description) {
        return 1;
      }
      return 0;
    });
    return paymentToSort;
  }

  public cleanPayments(first: boolean, next: boolean) {
    if (first || next) {
      this.allPayments = [];
      this.policyService.mainProposal.proposal.payments = [];
    }
    if (first) {
      this.showExtrapayForFirst = false;
      this.removeOldControls(this.formPagamentiRataFirma, ['paymentInfoFirst']);
      this.formPagamentiRataFirma.get('paymentInfoFirst').setValue(null);
      this.paymentFirst = new Payments(new InstalmentType(PaymentTypeOption.FIRST_INST, 'lic_first_instalment'), new PaymentType(null, null));
    }
    if (next) {
      this.showExtrapayForSecond = false;
      this.removeOldControls(this.formPagamentiRateSuccessive, ['paymentInfoNext']);
      this.formPagamentiRateSuccessive.get('paymentInfoNext').setValue(null);
      this.paymentSecond = new Payments(new InstalmentType(PaymentTypeOption.NEXT_INST, 'lic_next_instalment_premium'), new PaymentType(null, null));
    }
  }

  public updateValueFactor(fat: Factor) {
    const factorsForm = this.form;
    if (factorsForm != null && factorsForm.get(fat.code) != null) {
      let valFromForm = factorsForm.get(fat.code).value;
      if (valFromForm != null) {
          if (parseInt(fat.type, 10) === 4 && valFromForm) {
            valFromForm = LicDateUtils.convertToYMD(valFromForm);
          } else if (parseInt(fat.type, 10) === 1 && valFromForm.includes(',')) {
            valFromForm = valFromForm.replace(',', '.');
          }
      } else if (parseInt(fat.type, 10) === 1) {
        valFromForm = '-1';
      }
      if (fat.value !== valFromForm) {
        fat.value = valFromForm;
        fat.manuallySet = true;
      }
    }
  }

  public updateFactors(submit: boolean) {
    this.semaphore = true;
    // ASMC-11575 - azzero gli investimenti al cambio valore di un fattore
    if (!submit) {
      this.cacheInvestments.deleteSession(this.policyService.mainProposalResourceId);
    }

    setTimeout(() => {
      if (this.semaphore) {
        this.eventPropagation.emit('startLoader');
      }
    }, 500);

    // setto i questionari sulla proposal
    this.saveQuestIntoProposal();

    this.product.factors.forEach(fat => { this.updateValueFactor(fat); });

    // AGGIORNO TUTTI I VALORI PRIMA DELLA CHIAMATA
    this.policyService.mainProposal.quote.product.paymentFrequencyCode = this.form.get('paymentFrequency').value;
    this.policyService.mainProposal.quote.product.agreementCode = this.form.get('agreement').value || null;
    this.policyService.mainProposal.proposal.policyVersion.branch = { objectId: this.form.get('branch').value };
    this.policyService.mainProposal.quote.product.factors = this.product.factors;
    if (this.formClauses) {
      this.assignClauseFormValuesToMainProposal();
    }

    if (!!this.lifeRoleService.holderSubj) {
      this.policyService.mainProposal.proposal.postalAddress = this.lifeRoleService.holderSubj.residence;
    }

    // AGGIORNO LA DATA EFFETTO
    const peffFactor = this.product.factors.find(f => f.code === '_1PEFF');
    if (peffFactor && peffFactor.value) {
      this.policyService.mainProposal.proposal.policyVersion.effectiveDate = (new Date(peffFactor.value)).toISOString();
    }

    if (submit && this.handleCoapplicant() && this.isMutuoCointestato() && this.isPiuAssicurati()) {
      this.removeCoapplicant();
      this.policyService.mainProposal.proposal.lifeParty.push(
        this.lifeRoleService.subjectToLifeParty(this.form.controls.cosigned.value, Roles.COSIGNED) as LifeParty);
    } else {
      this.removeCoapplicant();
    }

    if (!submit) { // la chiamata con il submit viene fatta dopo perchè concatenata
      this.$subscriptions.push(
        this.policyService.saveProposal().pipe(
          catchError(error => {
            this.semaphore = false;
            console.log('Errore leggendo la proposta');
            this.eventPropagation.emit('stopLoader');
            return throwError(error);
          })
        )
        .subscribe(
          (response: any) => {
            this.semaphore = false;
            this.product = this.policyService.mainProposal.quote.product;
            this.updateFormValue();
            this.setAfterSaveProposal(response.output);
            if (!!this.product.clauses && !!this.product.clauses.length) {
              this.initClauseForm();
            }
            // RDDL-4652
            this.checkFrequencyTypeAndPreval();

            if (!submit) {
              // controllo la visualizzazione dei questionari
              this.checkViewQuest();
              this.eventPropagation.emit('stopLoader');
            }
          }
        )
      );
    } else {
      this.semaphore = false;
      this.eventPropagation.emit('stopLoader');
    }
  }

  // RDDL-4652
  checkFrequencyTypeAndPreval() {
    const viewNext2 = this.getPropertyBoleanValue(
      this.policyService.mainProposal.quote.product.customProperties,
      LicCustomProperties.RATA_SUCCESSIVA
    );
    const frequency = this.policyService.mainProposal.proposal.policyVersion &&
      this.policyService.mainProposal.proposal.policyVersion.fractionation;
    const sddCode = this.nextInstPay.find(meanOfPaym => meanOfPaym.meanOfPayment.code === PaymentTypeCode.SDD.toString());
    if (viewNext2 && !!frequency) {
      const typefractionation = this.policyService.mainProposal.proposal.policyVersion.fractionation.typeFractionation;
      // eslint-disable-next-line max-len
      const isFreqMonthly = Number(frequency.numRate) === Frequency.MENSILE && Number(typefractionation.codice) === TypeFractionation.RATEIZZATO;
      if (isFreqMonthly && !!sddCode && this.isPrevalorizationSddEnabled) {
        if (this.formPagamentiRateSuccessive.get('paymentInfoNext').value !== PaymentTypeID.SDD.toString() && isFreqMonthly) {
          this.changeNextPayments(PaymentTypeID.SDD.toString());
        }
        this.formPagamentiRateSuccessive.get('paymentInfoNext').setValue(PaymentTypeID.SDD.toString());
        this.formPagamentiRateSuccessive.get('paymentInfoNext').disable();
      }
    }
  }

  public updateFactorsCall(submit: boolean): Observable<any> {
    this.updateFactors(submit); // se passo il submit a true non fa la chiamata

    // la chiamata con il submit viene fatta qua e restituita come observable
    return this.policyService.saveProposal().pipe(
      tap((response: any) => {
        this.semaphore = false;
        this.product = this.policyService.mainProposal.quote.product;
        this.updateFormValue();
        this.setAfterSaveProposal(response.output);
      })
    );
  }

  public updateFormValue() {
    const codes: string[] = [];
    const codesPRataFirma: string[] = [];
    const codesPRateSucc: string[] = [];

    // Aggiungo i fattori mancanti
    this.product.factors.map((element => {
      codes.push(element.code);
    }));

    codes.push('agreement');
    codes.push('paymentFrequency');

    codesPRataFirma.push('paymentInfoFirst');
    codesPRateSucc.push('paymentInfoNext');

    if (this.paymentField1 !== undefined) {
      this.paymentField1.map(pay => {
        codesPRataFirma.push(pay.name);
      });
    }
    if (this.paymentField2 !== undefined) {
      this.paymentField2.map(pay => {
        codesPRateSucc.push(pay.name);
      });
    }

    this.removeOldControls(this.form, codes);
    this.removeOldControls(this.formPagamentiRataFirma, codesPRataFirma);
    this.removeOldControls(this.formPagamentiRateSuccessive, codesPRateSucc);

    this.groupByFunctionForFactor();
  }

  public setAfterSaveProposal(response: PolicyModel) {
    // GESTIONE MUTUO COINTESTATO
    if (this.handleCoapplicant()) {
      this.jointLoanHandling(this.product.factors);
    }
  }

  public removeCoapplicant() {
    const lifeParty = this.policyService.mainProposal.proposal.lifeParty.find(element => element.party.partyRole === '400000');
    if (lifeParty != null) {
      const idx = this.policyService.mainProposal.proposal.lifeParty.indexOf(lifeParty);
      if (idx !== -1) {
        this.policyService.mainProposal.proposal.lifeParty.splice(idx, 1);
      }
    }
  }

  public jointLoanHandling(factors) {
    this.policyService.setCoSigner(false);
    factors.map((factor) => {
      if (factor.code === 'PIUASS') {
        if (factor.value === '1') {
          this.policyService.setCoSigner(true);
        } else {
          this.lifeRoleService.setCosigned(null);
          this.form.controls.cosigned.setValue(null);
        }
      }
    });
  }

  public showCoSigner() {
    return this.policyService.isCoSignerShowed();
  }

  public handleCoapplicant(): boolean {
    const propVal = this.policyService.getPropertyFromProduct(LicCustomProperties.MUTUO_COINTESTATO);
    return (propVal != null) ? propVal.value === 'true' : false;
  }

  public isMutuoCointestato() {
    const isMutcoi = this.product.factors.find((factor: Factor) => factor.code === 'MUTCOI');
    return !!isMutcoi ? isMutcoi.value === '1' : false;
  }

  public isPiuAssicurati() {
    const isPiuAss = this.product.factors.find((factor: Factor) => factor.code === 'PIUASS');
    return !!isPiuAss ? isPiuAss.value === '1' : false;
  }

  public openAnag(event) {
    this.message = this.validationService.removeMessageByCodeList(['no-legal', 'no-policy-holder', 'no-coapp']);
    this.eventPropagation.emit(event);
    this.$subscriptions.push(
      this.lifeRoleService.getObservable().pipe(distinctUntilChanged(), take(1)).subscribe(value => {
        if (value != null) {
          const policyHolder = this.lifeRoleService.getPolicyHolder();
          if (this.validationService.isLegal(value)) {
            this.message = this.validationService.addMessage('no-legal',
              this.translateService.getImmediate('lic_co_applicant_physical'));
          } else if (!!policyHolder && policyHolder.objectId === value.objectId) {
            this.message = this.validationService.addMessage('no-policy-holder',
              this.translateService.getImmediate('lic_co_applicant_not_same_holder'));
          } else {
            this.lifeRoleService.setCosigned(value);
            this.form.controls.cosigned.setValue(value);
          }
        }
      })
    );
  }

  public getPolicyFactor() {
    return this.product.factors.filter((f) => !LicPipUtils.isPipFactor(f) && f.code !== this.REINVESTMENT_CODE);
  }

  public coapplicantValidation() {
    this.message = this.validationService.removeMessageByCodeList(['no-legal', 'no-coapp']);
    this.product.factors.map((factor) => {

      if (factor.code === 'PIUASS') {
        if (factor.value === '1') {
          if (this.form.controls.cosigned.value == null) {
            this.message = this.validationService.addMessage('no-coapp',
              this.translateService.getImmediate('lic_co_applicant_mandatory'));
          }
        }
      }
    });
  }

  /* ON SUBMIT */

  public onSubmit(saveUndefinedProposal: boolean = false, modal: any = {}) {
    const resolvedKey = !!this.qMc && !!this.qMc.key ? this.qMc.key : undefined;
    this.message = [];
    const reinvestControl = this.form.get(this.REINVESTMENT_CODE);
    this.checkClausesOnSubmit();
    if (reinvestControl && reinvestControl.value === '1' && this.settlements.length === 0) {
      this.message.push(this.translateService.getImmediate('lic_Nosettlementstoreinvestexist'));
    }
    if (this.handleCoapplicant() && this.isMutuoCointestato() && this.isPiuAssicurati()) {
      this.coapplicantValidation();
    }
    if (this.checkDatiAmmPage(resolvedKey)) {
      this.$subscriptions.push(
        this.policyService.saveQuestAnd(!!resolvedKey ? this.cache : null).pipe(
          switchMap(() => {
            return this.updateFactorsCall(true);
          }),
          tap(() => {
            this.actionService.cleanErrorMessagesForStep(this.cardsNavigationService.STEP.DATI_AMMINISTRATIVI.route);
            this.eventPropagation.emit('startLoader');
            this.cardsNavigationService.resetMessages(this.cardsNavigationService.STEP.DATI_AMMINISTRATIVI.errorId);
            this.actionService.setAuthorization('dati_amm', false);
          }),
          switchMap(() => {
            return this.actionService.getMsgByCodLivelloAzioni('T');
          }))
          .subscribe((response: ActionServiceResponse) => {
            this.checkPageDataAndPopulateMessages(response);
            if (this.message.length === 0) {
              if (!saveUndefinedProposal) {
                this.navigateToNextPage();
              } else {
                this.saveUndefinedProposal(modal);
              }
            } else {
              // ci sono blocchi da azioni
              this.eventPropagation.emit('stopLoader');
            }
            this.eventPropagation.emit('stopLoader');
          })
      );

    } else {
      this.eventPropagation.emit('stopLoader');
      this.checkDirty(this.form);
      this.checkDirty(this.questForm);
      this.checkDirty(this.formPagamentiRataFirma);
      this.checkDirty(this.formPagamentiRateSuccessive);
      this.checkDirty(this.formClauses);
      if (this.message.length === 0) {
        this.message.push(this.translateService.getImmediate('lic_error_mandatory_fields'));
      } else {
        this.message.values();
      }
    }
  }

  protected assignClauseFormValuesToMainProposal() {
    // make the array always empty
    this.policyService.mainProposal.proposal.clauses = [];

    // assign quote and proposal clauses objects
    const quoteClauses = this.policyService.mainProposal.quote.product.clauses;
    const propsClauses = this.policyService.mainProposal.proposal.clauses;

    Object.keys(this.formClauses.controls).forEach(key => {
      const controlGroup = this.formClauses.get(key) as UntypedFormGroup;
      const clause = quoteClauses.find(c => c.code === key);

      // assign value to quote clauses
      // only the selected value is assigned
      if (clause) {
        clause.selected = controlGroup.controls.selectedValue.value;
      }

      // assign value to proposal clauses
      // creates a whole new array of objects
      if (!!clause && clause.selected) {
        // fill the array with the form value
        propsClauses.push(
          {
            text: controlGroup.controls.textValue.value,
            code: key
          }
        );
      }
    });
  }

  protected checkClausesOnSubmit() {
    if (!!this.product.clauses && !!this.product.clauses.length) {
      if (!this.formClauses.invalid) {
        this.assignClauseFormValuesToMainProposal();
      } else {
        this.message.push(this.translateService.getImmediate('lic_text_required_error'));
      }
    }
  }


  // Fosse Java farei che DatiAmmComponent estende una base class e metterei questo lì dentro, ma non è Java quindi non so
  public saveUndefinedProposal(modal: any = { }) {

    this.policyService.pushExtensionProperty(ExtensionProperty.INTERMEDIATE_SAVE, 'true');

    this.eventPropagation.emit('startLoader');

    this.$subscriptions.push(
      this.policyService.saveFromQuote(false).subscribe(
        (response: any) => {
          this.semaphore = false;
          this.policyService.mainProposal.proposal = response.output.proposal;
          this.setAfterSaveProposal(response.output);
          this.eventPropagation.emit('stopLoader');
          modal && modal.open();
          this.proposalNumber = this.policyService.mainProposal.proposal.contractData[0].proposalNumber;
        })
    );
  }

  private navigateToNextPage() {
    if (this.lifeSessionService.productType === 'PIP') {
      this.navigateToCard(this.cardsNavigationService.STEP.PIP.route);
    } else {
      if (this.checkFattoriBene()) {
        this.actionService.isGoodsInfo = true;
        this.navigateToCard(this.cardsNavigationService.STEP.DATI_DI_BENE.route);
      } else {
        this.actionService.isGoodsInfo = false;
        this.policyService.resetQuotation();
        this.navigateToCard(this.cardsNavigationService.STEP.QUOTAZIONE.route);
      }
    }
  }

  private navigateToCard(cardIdentifier: string) {
    this.cardsNavigationService.setCurrentCards(cardIdentifier);
    this.navigation.emit(cardIdentifier);
  }

  public getBackDatingValue():ActionServiceErrorMessage{
    let backDating = this.policyService.getExtensionProperty(ExtensionProperty.BACKDATING_NOT_ALLOWED);
    if(backDating && backDating.valore){
      return new ActionServiceErrorMessage(backDating.valore,null,null);
    }else{
      return;
    }
  }

  private checkPageDataAndPopulateMessages(response: ActionServiceResponse) {
    const blockingErrors = [];
    const errorsLevels = new Map<Code, Action[]>();
    const nonBlockingMessages = [];
    response.errorMessages.map((action: ActionServiceErrorMessage) => {
      this.actionService.setActionErrorMessage(this.cardsNavigationService.STEP.DATI_AMMINISTRATIVI.route, action);
      // check blocking errors
      if (!!this.actionService.getBlockingErrors(action)) {
        blockingErrors.push(action);
      }
      // check warning errors
      if (!!this.actionService.getWarningErrors(this.operator, action)) {
        nonBlockingMessages.push({
          type: ErrorType.WARNING,
          message: this.actionService.getWarningErrors(this.operator, action)
        });
      }
      // check auth errors
      if (!!this.actionService.getAuthoErrors(this.operator, action)) {
        this.actionService.setAuthorization('dati_amm', true);
        nonBlockingMessages.push({
          type: ErrorType.AUTH,
          message: this.actionService.getAuthoErrors(this.operator, action)
        });
      }
    });
    if(this.getBackDatingValue()){
      blockingErrors.push(this.getBackDatingValue());
    }
    errorsLevels.set(Code.BLOCKING, blockingErrors);
    this.cardsNavigationService.setMessageForPage(this.cardsNavigationService.STEP.DATI_AMMINISTRATIVI.errorId, nonBlockingMessages);
    this.message = this.actionService.getMsgsByErrorCode(Code.BLOCKING, errorsLevels);
  }

  public checkDatiAmmPage(questKey): boolean {
    let allowNext = true;
    if (this.message.length > 0 || !!this.form.invalid) {
      allowNext = false;
    }
    if (allowNext && this.showAccordionPayments) {
      allowNext = this.checkAllPayments();
    }
    if (allowNext && this.showAccordionPolicyAddress) {
      allowNext = true; // TODO: check policyAddress
    }
    if (allowNext && this.showAccordionQuestionnaires) {
      allowNext = (!this.cache || this.cache.checkCompileByKey(questKey)) && (!this.questForm || this.questForm.valid);
    }

    return allowNext;
  }

  // controlla i pagamenti e aggiorna la mainProposal
  public checkAllPayments() {
    this.allPayments = [];
    let errors = true;
    if (this.showFirstInst && this.formPagamentiRataFirma.controls.paymentInfoFirst != null) {
      errors = !this.formPagamentiRataFirma.invalid;
    }
    if (this.showNextInst && this.formPagamentiRateSuccessive.controls.paymentInfoNext != null) {
      errors = !this.formPagamentiRateSuccessive.invalid && errors;
    }
    // rimappatura dei pagamenti per il salvataggio
    const adapterExtraPaym: AdapterPayments = new AdapterPayments();
    if (this.paymentFirst != null && this.showFirstInst) {
      if (this.paymentFirst.paymentType.codice !== '' && this.paymentFirst.paymentType.codice != null) {
        if (this.paymentField1 != null) {
          this.allPayments.push(adapterExtraPaym.getPaymentFieldName(this.formPagamentiRataFirma, this.paymentFirst));
        } else {
          this.allPayments.push(this.paymentFirst);
        }
      }
    }
    if (this.paymentSecond != null && (this.showNextInst || this.firstEqualNext)) {
      if (this.paymentSecond.paymentType.codice !== '' && this.paymentSecond.paymentType.codice != null) {
        if (this.paymentField2 != null) {
          this.allPayments.push(adapterExtraPaym.getPaymentFieldName(this.formPagamentiRateSuccessive, this.paymentSecond));
        } else {
          this.allPayments.push(this.paymentSecond);
        }
      }
    }
    this.policyService.mainProposal.proposal.payments = this.allPayments;
    return errors;
  }

  // controllo se ci sono fattori di bene da esporre
  public checkFattoriBene(): boolean {
    const showAccordionQuestionnaires = !this.policyService.isFromPreventive && !this.policyService.isFromQuoteModification;
    let showSectionQuest = false;
    if (this.policyService.mainProposal.quote.product.assets[0].instances.length > 0) {
      showSectionQuest = this.policyService.mainProposal.quote.product.assets[0].instances[0].questionnaires
        .filter((quest: Questionnaire) => quest.included === true && quest.codeType !== 'QPPEVO').length > 0;
    }
    let showQuestPPEVO = false;
    let flag = false;

    this.policyService.mainProposal.quote.product.assets[0].instances.forEach((inst: Instance) => {
      if (this.cache && !this.cache.get(inst.name)) {
        showQuestPPEVO = this.policyService.mainProposal.quote.product.assets[0].instances[0].questionnaires
          .filter((quest: Questionnaire) => quest.included === true && quest.codeType === 'QPPEVO').length > 0;
      }
      if (inst.factors.length !== 0 || (showAccordionQuestionnaires && (showSectionQuest || showQuestPPEVO))) {
        flag = true;
      }
      if (!showQuestPPEVO) { // restoring cache to reload the correct status
        this.cache.delete(inst.name);
      }
    });

    return flag;
  }

  public checkDirty(form: UntypedFormGroup) {
    if (!!form && !!form.controls) {
      Object.keys(form.controls).forEach((field: string) => {
        if (!form.get(field).valid) {
          form.get(field).markAsDirty();
        }
      });
    }
  }


  public showSaveButton() {

    return !this.policyService.hideSaveButton();
  }


  // Rimuove i vecchi controlli

  public removeOldControls(form: UntypedFormGroup, codes: string[]) {
    Object.keys(form.controls).forEach((key: string) => {
      if (key !== 'cosigned' && key !== 'branch' && !codes.includes(key)) {
        form.removeControl(key);
      }
    });
  }

  public getAddress() {
    return this.lifeRoleService.holderSubj ? this.lifeRoleService.holderSubj.residence.formatAddress : null;
  }

  public saveQuestionnaire(quest: QuestionnaireValue, onInit?: boolean) {
    this.questMap.set(quest.questionnaire.code, quest);

    if (!onInit) {
      this.updateFactors(false);
    }
  }

  public deleteQuestionnaire(questCode: string) {
    this.questMap.delete(questCode);
  }

  public checkViewQuest() {
    this.policyService.mainProposal.quote.product.questionnaires.map((q) => {
      if (this.questViewMap.get(q.code) !== q.included) {
        if (!q.included) { // è diventato non visibile
          this.questMap.delete(q.code);
        }
        this.questViewMap.set(q.code, q.included);
      }
    });

    this.questionnairesList = this.policyService.mainProposal.quote.product.questionnaires // TODO multi-assicurato
      .filter((quest: Questionnaire) => quest.included === true && quest.codeType !== 'QPPEVO');
    this.showSectionQuest = this.questionnairesList.length > 0;
    this.ppevoQuestionnaires = this.policyService.mainProposal.quote.product.questionnaires
      .filter((quest: Questionnaire) => quest.included === true && quest.codeType === 'QPPEVO');

    this._validQuestsCode = new Map<string, boolean>();
    this.ppevoQuestionnaires.forEach((passQuest: Questionnaire) => this._validQuestsCode.set(passQuest.code, passQuest.compulsory));
    if (this._validQuestsCode.size > 0) {
      if (this.cache && !this.cache.get(this.ppevoQuestKey)) {
        this.qMc.loadQuestionnairesByCode(this.validQuestsCode);
      } else {
        this.cache.setMandatory(this.validQuestsCode);
      }
    } else {
      this.cache.delete(this.ppevoQuestKey);
      if (this.qMc && this.qMc.questionnaireManagerService) {
        this.qMc.questionnaireManagerService.listChange.emit(this.qMc.questionnaireManagerService.getQuestionnaires());
      }
    }
  }

  public saveQuestIntoProposal() {
    // se non è contenuto nella mappa allora arriva da un'altra pagina e va mantenuto
    const questToCopy = [];
    this.policyService.mainProposal.proposal.questionnaires.map((q) => {
      if (!this.questViewMap.has(q.questionnaire.code)) {
        questToCopy.push(q);
      }
    });

    this.policyService.mainProposal.proposal.questionnaires = questToCopy;

    for (const questionnaire of this.questMap.values()) {
      this.policyService.mainProposal.proposal.questionnaires.push(questionnaire);
    }
  }

  public openReinvestments(control?: AbstractControl) {
    const dialog = this.modalService.open(ReinvestmentModalComponent, {
      centered: true,
      size: 'lg',
      windowClass: 'in',
      backdropClass: 'light-blue-backdrop in'
    });
    let propSettl = this.policyService.getExtensionProperty(ExtensionProperty.REINVEST_LIQ);
    if (propSettl && propSettl.valore) {
      dialog.componentInstance.selectedSettlements = JSON.parse(propSettl.valore);
    } else {
      propSettl = { chiave: ExtensionProperty.REINVEST_LIQ, valore: null };
      this.policyService.addExtensionProperty(ExtensionProperty.REINVEST_LIQ, propSettl);
    }
    dialog.componentInstance.settlements = this.settlements;
    dialog.result.then(settlements => {
      this.settlements = settlements;
      propSettl.valore = JSON.stringify(this.settlements.filter(liq => liq.select).map(liq => {
        return { id: liq.id, reinvest: liq.reinvest };
      }));
      this.updateFactors(false);
    })
      .catch(reason => {
        if (!reason && control) {
          this.noUpdate = true;
          control.setValue('0');
        }
      });
  }

  get stampPaymentLabel(): string {
    return (!this.showNextInst && this.showFirstInst && this.firstEqualNext) ?
      this.translateService.getImmediate('lic_payment_type') : this.translateService.getImmediate('lic_first_instalment_payment_type');
  }

  public resetCosigned() {
    this.lifeRoleService.setCosigned(null);
    this.form.controls.cosigned.setValue(null);
  }

  public showMasterPolicy(): boolean {
    return this.policyService.mainProposal.proposal.idMasterPolicy != null;
  }

  public putPMDataOnTable(table: object[], key: string, value: string) {
    if (value) {
      table.push({ chiave: key, valore: value });
    }
  }

  public countTotalQuest() {
    this.showQuestPPEVO = (this.qMc.getQuest().length + this.ppevoQuestionnaires.length) > 0;
  }

  public loaderQuestManager(event: string) {
    this.eventPropagation.emit(event);
  }

  private generateQuestKey(): string {
    let key = 'FAKE-DA';
    this.ppevoQuestionnaires.forEach(q => {
      key += `-${q.code}`;
    });

    return key.toUpperCase();
  }

  trackByFn(index: number, item: PaymentFrequency | Agreement) {
    return item.code;
  }

  ngOnDestroy(): void {
    this.$subscriptions.forEach(subscription => subscription.unsubscribe());
  }
}
