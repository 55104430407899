import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LpcFormuleQuotationTableComponent } from './lpc-formule-quotation-table.component';
import { RgiCountryLayerModule } from '@rgi/rgi-country-layer';
import { RgiRxI18nModule } from '@rgi/rx/i18n';

@NgModule({
  imports: [
    CommonModule,
    RgiRxI18nModule,
    RgiCountryLayerModule
  ],
  declarations: [LpcFormuleQuotationTableComponent],
  exports: [
    LpcFormuleQuotationTableComponent
  ],
})
export class LpcFormuleQuotationTableModule { }
