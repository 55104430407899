/**
 * MORE READABILITY ADVICE
 * Please maintain an alphabetical order in each section
 */
import {AuthTranslationsKeys} from './auth-translations-keys';


export const AUTH_EN: AuthTranslationsKeys = {
    _AUTH_: {
        _ACTION_IS_MANDATORY: 'Action is mandatory',
        _ARE_YOU_SURE_TO_APPLY_THIS_ACTION: 'Are you sure you want to apply this action?',
        _AUTHORIZATION_DEROGATION_LEVEL: 'Authorisation clearance level:',
        _AUTHORIZATION_LEVEL: 'Authorisation Level:',
        _AUTHORIZATION_REQUEST_CODE: 'Authorisation request code',
        _BACK: 'Back',
        _BLOCK: 'Block',
        _BUY_POLICY: 'buy policy',
        _BY_USER: 'by user',
        _CANCEL: 'Cancel',
        _CANCEL_REQUEST: 'Cancel Request',
        _CARD_TITLE_: 'Authorisations',
        _CHARACTERS: 'Characters',
        _CODE: 'Code',
        _COMMENT: 'Comment:',
        _COMPETENCE_LEVEL: 'Competence level:',
        _COMPETENT_LEVEL: 'Competence level:',
        _COMPETENT_USER: 'Competent user',
        _CONFIRM: 'confirm',
        _CONFIRM_VARIATION: 'confirm variation',
        _CONTRACT_NUMBER: 'Contract Number',
        _CONTRACT_TYPE: 'Contract Type',
        _DATE: 'Date',
        _DEROGATION_LEVEL: 'Clearance level:',
        _DESCRIPTION: 'Description',
        _EFFECTIVE_DATE: 'Effective date',
        _ELEMENTS: 'elements',
        _EMPTY: 'empty',
        _ERROR: 'Error',
        _ERR_AUTH_DEROGATION_LEVEL: 'Error on user clearance level',
        _ERR_AUTH_EXTRA_CHECK: 'Extracheck not passed',
        _ERR_AUTH_NOT_OPERABLE: 'Event not applicable',
        _ERR_AUTH_NOT_SAVED: 'Event not saved',
        _EVENT: 'Event',
        _FILL_IN_REQUEST_STATUS_OR_USER: 'Fill in request status or user',
        _FIND: 'find',
        _HISTORY: 'Log',
        _IN_ORDER_TO_ACCEPT_THE_REQUEST_ALL_DOCUMENTS_MUST_BE_VALIDATED: 'In order to accept the request, all documents must be validated',
        _ISSUE: 'issue',
        _ISSUE_DATE: 'Issue date',
        _ISSUE_POLICY: 'issue policy',
        _ISSUE_PROPOSAL: 'issue proposal',
        _ISSUE_USER: 'Issue user',
        _LAST_ACTION: 'Last action:',
        _LAST_ACTION_DATE: 'Last action date:',
        _LAST_EVENT: 'Last Event',
        _LAST_EXECUTED_ACTION: 'Last executed action',
        _MANAGEMENT_NODE: 'Management Node',
        _MODIFY_PROPOSAL: 'modify proposal',
        _MODIFY_VARIATION: 'modify variation',
        _NEXT_STATUS: 'Next status',
        _NODE: 'Node',
        _NODE2: 'Node:',
        _COMPANY: 'Company:',
        _NODE_CODE: 'Node Code',
        _NODE_IS_MANDATORY: 'Node is mandatory',
        _NOTES: 'Notes',
        _NOT_AVAILABLE: 'Not available',
        _NO_ELEMENT_PRESENT: 'No element present',
        _OF: 'of',
        _OPERATION: 'Operation',
        _OPERATION_WAS_ISSUED_ON: 'Operation was issued on',
        _OPERATOR: 'Operator:',
        _OPERATOR_DEROGATION_LEVEL: 'Operator clearance level:',
        _OPERATOR_LEVEL: 'Operator level:',
        _ORIGIN_TYPE: 'Origin Type:',
        _PM_POLICY_NUMBER: 'Master Policy No.',
        _PM_PROPOSAL_NUMBER: 'Master Proposal No.',
        _POLICY_NUMBER: 'Policy Number',
        _POLICY_NUMBER_PM: 'Master Policy Number',
        _POLICY_TYPE: 'Contract Type',
        _POSSIBLE_ACTIONS: 'Possible actions',
        _POSSIBLE_DEROGATION_LEVEL: 'Possible clearance level',
        _POSTSELL_CODE: 'Post-sell code',
        _PRIORITY: 'Priority',
        _PRODUCT: 'Product',
        _PRODUCT_TYPE: 'Product Type',
        _PROPOSAL_NUMBER: 'Proposal Number',
        _PROPOSAL_NUMBER_PM: 'Master Proposal Number',
        _REFERENCE: 'Reference',
        _REQUESTS_LIST: 'Requests list',
        _REQUEST_DATE: 'Request Date',
        _REQUEST_DATE_FROM: 'Request date from',
        _REQUEST_DATE_FROM_IS_NOT_A_VALID_DATE: 'Request date from is not a valid date',
        _REQUEST_DATE_FROM_MUST_BE_GREATER_THEN_REQUEST_DATE_TO: 'Request date from must be greater than Request date to',
        _REQUEST_DATE_TO: 'Request date to',
        _REQUEST_DATE_TO_IS_NOT_A_VALID_DATE: 'Request date to is not a valid date',
        _REQUEST_DETAIL: 'Request detail',
        _REQUEST_NODE: 'Request Node',
        _REQUEST_NUMBER: 'Request Number',
        _REQUEST_OPERATION: 'Request operation',
        _REQUEST_STATUS: 'Request Status',
        _REQUEST_STATUS_VARIATION: 'Request Status Variation',
        _REQUEST_TYPE: 'Request Type',
        _REQUEST_USER: 'Request user:',
        _SALE_POINT_GROUP: 'Point of Sale Group',
        _SEARCH_UNDER_SUBNET: 'Search under subnet',
        _SELECT_NODE: 'Select Node',
        _STATUS: 'Status:',
        _STATUS_AFTER_EVENT: 'Status after event:',
        _STATUS_CHANGES_ARE_NOT_ADMITTED_FOR_THIS_AUTHORIZATION_REQUEST: 'Status changes are not admitted for this authorisation request',
        _STATUS_VARIATION: 'Status variation',
        _SUBSCRIBER: 'Subscriber',
        _SUBSYSTEM: 'Subsystem',
        _SUBSYSTEM_IS_MANDATORY: 'Subsystem is mandatory',
        _TO: 'to',
        _TYPE: 'Type',
        _USER: 'User',
        _USER_TYPE: 'User Type',
        _VARIATION_SUMMARY: 'variation summary',
        _VIEW_ELEMENT: 'View Element',
        _VIEW_FROM: 'View from',
        _WARNINGS: 'Warnings',
        _WORKFLOW_STATUS: 'Workflow Status',
        _MODIFY_AUTHORIZATION_LABEL: 'Modify Authorisation',
        _CONFIRM_PROP_POL_AUTH: 'Confirm proposal/policy in Authorisation',
        _OPERATION_CONFIRMED: 'Operation has been confirmed'
    },
    _AUTHORIZATION_SEARCH: 'Authorisation Search',
    _AUTHORIZATION_SEARCH_DETAIL: 'Authorisation Search Detail',
};
