
<label class="label-core">{{inputField.label}}</label>
<lpc-required required="true | translate"></lpc-required>
<div class="input-group col-lg-12">
  <div class="input-group-btn">
    <button type="button" class="btn btn-default" (click)="setBack()" data-qa="buttonPreviuosRecurrence"> << </button>
  </div>
    <div style="width:100%" class="form-control" data-qa="date-stepper">{{ name | date : 'dd/MM/yyyy' }}</div>
    <div class="input-group-btn">
      <button type="button" class="btn btn-default" (click)="setNext()" data-qa="buttonNextRecurrence">>></button>
    </div>
  </div>
