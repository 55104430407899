<table style="width:100%" [formGroup]="dataTransfer" class="settlement-table active" [attr.data-qa]="'data-transfer-table'">
  <thead>
    <tr>
      <th class="life-detail-table-width-5"></th>
      <th class="life-detail-table-width-20" translate>lpc_typePreviousPenFund</th>
      <th class="life-detail-table-width-20" translate>lpc_companyPreviousPenFund</th>
      <th class="life-detail-table-width-20" translate>lpc_amount</th>
      <th class="life-detail-table-width-20" translate>lpc_Previous_Pension_Fund</th>
      <th class="life-detail-table-width-15" translate>lpc_note</th>
    </tr>
  </thead>
  <ng-container *ngFor="let data of dataTransferDefinitions; let i = index;">
    <tbody>
      <tr>
        <td class="type-flex">
          <input formControlName="data" type="radio" name="data" [value]="data.id"/>
        </td>
        <td [attr.data-qa]="'td-liquidation-effectiveDate'" class="life-detail-table-width-20">{{data.descOfPreviousPensionPlan ? data.descOfPreviousPensionPlan : '-'}}</td>
        <td [attr.data-qa]="'td-liquidation-issueDate'" class="life-detail-table-width-20">{{data.providerOfPreviousPensionPlan ? data.providerOfPreviousPensionPlan : '-'}}</td>
        <td [attr.data-qa]="'td-liquidation-movementDescription'" class="life-detail-table-width-35">{{data.amountToBeTransferred | rgiCtryLNumFormatter}}</td>
        <td [attr.data-qa]="'td-liquidation-movementDescription'" class="life-detail-table-width-35">{{data.previousContract ? data.previousContract : '-'}}</td>
        <td [attr.data-qa]="'td-liquidation-grossAmount'" class="life-detail-table-width-20">{{data.notes ? data.notes : '-'}}</td>
      </tr>
    </tbody>
  </ng-container>
</table>

<div class="col-md-12 inactive">
  <div *ngFor="let data of selectedDataTransferDefinition">
    <div *ngIf="data.descOfPreviousPensionPlan" translate>lpc_typePreviousPenFund<span>: {{data.descOfPreviousPensionPlan}}</span></div>
    <div *ngIf="data.providerOfPreviousPensionPlan" translate>lpc_companyPreviousPenFund<span>: {{data.providerOfPreviousPensionPlan}}</span></div>
    <div *ngIf="data.amountToBeTransferred" translate>lpc_amount<span>: {{data.amountToBeTransferred}}</span></div>
    <div *ngIf="data.previousContract" translate>lpc_Previous_Pension_Fund<span>: {{data.previousContract}}</span></div>
    <div *ngIf="data.notes">lpc_note<span>: {{data.notes}}</span></div>
  </div>
</div>

