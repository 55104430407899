import { Component, Inject, OnInit, Optional } from '@angular/core';
import { LpcReactivationQuotationComponent } from '../lpc-reactivation-quotation/lpc-reactivation-quotation.component';
import { LpcCurrencyCache, CurrencyCacheService } from '../../../services/currency-cache.service';
import { RgiCtryLayerNumFormatterPipe } from '@rgi/rgi-country-layer';

@Component({
  selector: 'lpc-reactivation-reduction',
  templateUrl: './lpc-reactivation-reduction.component.html',
  styleUrls: ['./../lpc-reactivation-quotation/lpc-reactivation-quotation.component.scss'],
  providers: [RgiCtryLayerNumFormatterPipe]
})
export class LpcReactivationReductionComponent extends LpcReactivationQuotationComponent implements OnInit {
  // formatter Options
  public currencyFormatterOptions: Intl.NumberFormatOptions = {
    style: 'currency'
  };

  constructor(@Optional() @Inject(LpcCurrencyCache) protected currencyService: CurrencyCacheService) {
    super(currencyService);
    this.currencyFormatterOptions.currency = currencyService.currency;
  }

  ngOnInit(): void { }

}
