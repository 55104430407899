<ng-container *ngIf="active">
  <div class="col-md-12 active">
    <div class="grid-title" *ngIf="!!fiscalData?.totalBenefit">P - <span translate>lpc_assignment_benefit</span><span>: {{ fiscalData.totalBenefit | rgiCtryLNumFormatter: '' : currencyFormatterOptions }}</span></div>
    <div class="grid-title" *ngIf="!!fiscalData?.maxPayout">IMC - <span translate>lpc_max_capital_amount</span><span>: {{ fiscalData.maxPayout | rgiCtryLNumFormatter: '' : currencyFormatterOptions }}</span></div>
    <div class="grid-container" [formGroup]="form">
      <!-- Dynamically Generated M Values and K Inputs -->
      <ng-container *ngIf="!usePercentage">
        <ng-container *ngFor="let period of fiscalData.fiscalPeriods; let i = index">
          <div class="m-value">
            <label translate>{{'lpc_fiscal_period_M' + (i + 1)}}</label>
            <span class="font-weight-bold">{{period.maxPayout | rgiCtryLNumFormatter: '' : currencyFormatterOptions}}</span>
          </div>
          <div class="k-input">
            <label translate for="{{ 'K' + (i + 1) }}">{{'lpc_fiscal_period_capital_request_K' + (i + 1)}}</label>
            <input [appFormatDisplayValue]="currencyFormatterOptions" class="form-control text-align-right" [formControlName]="'K' + (i + 1)">
          </div>
        </ng-container>
      </ng-container>
      <!-- Percentage case  -->
      <ng-container *ngIf="usePercentage">
        <div class="k-input">
          <label translate for="percentage">PC - <span translate>lpc_percentage_in_capital</span></label>
          <input class="form-control text-align-right" formControlName="percentage">
        </div>
      </ng-container>
    </div>
  </div>
</ng-container>
<ng-container *ngIf="!active" >
  <ng-container *ngIf="!usePercentage">
    <div class="col-md-12 inactive" *ngFor="let period of fiscalData.fiscalPeriods; let i = index">
      <div class="col-md-6">
        <span translate>{{'lpc_fiscal_period_M' + (i + 1)}}</span>: {{period.maxPayout | rgiCtryLNumFormatter: '' : currencyFormatterOptions}}
      </div>
      <div class="col-md-6">
        <span translate>{{'lpc_fiscal_period_capital_request_K' + (i + 1)}}</span>: {{form.get('K'+ (i + 1)).value | rgiCtryLNumFormatter: '' : currencyFormatterOptions}}
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="usePercentage">
    <div class="col-md-12 inactive">
      <div class="col-md-6">
        PC - <span translate>lpc_percentage_in_capital</span>: {{form.get('percentage').value / 100 | rgiCtryLNumFormatter: '' : percentFormatterOptions}}
      </div>
    </div>
  </ng-container>
</ng-container>
