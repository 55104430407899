import { ConstitutionAnnuitiesOperationsComponent } from './constitution-annuities-operations/constitution-annuities-operations.component';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';

import { PortalNgCoreModule } from '@rgi/portal-ng-core';
import { QuestionnairesManagerModule } from '@rgi/questionnaires-manager';
import { AngularIbanModule } from 'angular-iban';
import { LpcBeneficiaryModule } from '../modules/lpc-beneficiary/lpc-beneficiary.module';
import { LpcCheckboxAccordionModule } from '../modules/lpc-checkbox-accordion/lpc-checkbox-accordion.module';
import { LpcDatepickerModule } from '../modules/lpc-datepicker/lpc-datepicker.module';
import { LpcDatesStepModule } from '../modules/lpc-dates-step/lpc-dates-step.module';
import { LpcDefinitionTableModule } from '../modules/lpc-definition-table/lpc-definition-table.module';
import { DocumentModule } from '../modules/lpc-document-wrapper/document.module';
import { LpcErrorMessagesModule } from '../modules/lpc-error-messages/lpc-error-messages.module';
import { LpcFactorAdapterModule } from '../modules/lpc-factor-adapter/lpc-factor-adapter.module';
import { LpcFormuleModalModule } from '../modules/lpc-formule-modal/lpc-formule-modal.module';
import { LpcKarmaFundModule } from '../modules/lpc-karma-funds/lpc-karma-fund.module';
import { LpcLiquidationModule } from '../modules/lpc-liquidation/lpc-liquidation.module';
import { MessageModalComponentModule } from '../modules/lpc-message-modal/lpc-message-modal.module';
import { LpcModalModule } from '../modules/lpc-modal/lpc-modal.module';
import { LpcNavigationButtonsModule } from '../modules/lpc-navigation-buttons/lpc-navigation-buttons.module';
import { LpcPaymentStepModule } from '../modules/lpc-payment-step/lpc-payment-step.module';
import { LpcPolicyContactModule } from '../modules/lpc-policy-contact/lpc-policy-contact.module';
import { LpcPremiumModule } from '../modules/lpc-premium/lpc-premium.module';
import { LpcQuestionnaireModule } from '../modules/lpc-questionnaire/lpc-questionnaire.module';
import { LpcQuotationControlModule } from '../modules/lpc-quotation-control/lpc-quotation-control.module';
import { LpcQuotationDetailModalModule } from '../modules/lpc-quotation-detail-modal/lpc-quotation-detail-modal.module';
import { LpcQuotationModule } from '../modules/lpc-quotation/lpc-quotation.module';
import { LpcRolesControlModule } from '../modules/lpc-roles-control/lpc-roles-control.module';
import { LpcStaticLabelModule } from '../modules/lpc-static-label/lpc-static-label.module';
import { LpcStepperModule } from '../modules/lpc-stepper/lpc-stepper.module';
import { LpcSubjectModule } from '../modules/lpc-subject/lpc-subject.module';
import { LpcThirdPartyModule } from '../modules/lpc-third-party/lpc-third-party.module';
import { LpcToolsModule } from '../modules/lpc-tools/lpc-tools.module';
import { LpcUnitTableModule } from '../modules/lpc-unit-table/lpc-unit-table.module';
import { LpcWarningMessagesModule } from '../modules/lpc-warning-messages/lpc-warning-messages.module';
import { LpcWarrantyDetailModalModule } from '../modules/lpc-warranty-detail-modal/lpc-warranty-detail-modal.module';
import { PlcQuestService } from '../services/plc-quest.service';
import { LpcCommissionDetailModalModule } from './../modules/lpc-commission-detail-modal/lpc-commission-detail-modal.module';
import { ActivateToolComponent } from './activate-tool/activate-tool.component';
import { AdditionalPaymentComponent } from './additional-payment/additional-payment.component';
import { CancelAppendixComponent } from './cancel-appendix/cancel-appendix.component';
import { ChangeAppendixComponent } from './change-appendix/change-appendix.component';
import { ChangeBeneficiariesComponent } from './change-beneficiaries/change-beneficiaries.component';
import { ChangeCombinationComponent } from './change-combination/change-combination.component';
import { ChangeContactComponent } from './change-contact/change-contact.component';
import { ChangePaymentFrequencyComponent } from './change-payment-frequency/change-payment-frequency.component';
import { ChangePaymentModeComponent } from './change-payment-mode/change-payment-mode.component';
import { ChangeRolesComponent } from './change-roles/change-roles.component';
import { ChangeSettlementBenefComponent } from './change-settlement-beneficiary/change-settlement-benef.component';
import { CloseAssignmentComponent } from './close-assignment/close-assignment.component';
import { DeathClaimComponent } from './death-claim/death-claim.component';
import { DeleteToolComponent } from './delete-tool/delete-tool.component';
import { DynamicOperationComponent } from './dynamic-operation/dynamic-operation.component';
import { NonCompletionComponent } from './non-completion/non-completion.component';
import { OpenAssignmentComponent } from './open-assignment/open-assignment.component';
import { OpenLienComponent } from './open-lien/open-lien.component';
import { PartialEarlyRepaymentComponent } from './partial-early-repayment/partial-early-repayment.component';
import { PartialSurrenderComponent } from './partial-surrender/partial-surrender.component';
import { PolicyTrasnferComponent } from './policy-transfer/policy-transfer.component';
import { RecessComponent } from './recess/recess.component';
import { RenewalBookingComponent } from './renewal-booking/renewal-booking-component';
import { SwitchFreeComponentComponent } from './switch-free-component/switch-free-component.component';
import { TerminateRescissionComponent } from './terminate-rescission/terminate-rescission.component';
import { TerminationIndividualPolicyComponent } from './termination-individual-policy/termination-individual-policy.component';
import { ToolManagerComponent } from './tool-manager/tool-manager.component';
import { TotalEarlyRepaymentComponent } from './total-early-repayment/total-early-repayment.component';
import { TotalSurrenderComponent } from './total-surrender/total-surrender.component';
import { ExpiryChoiceComponent } from './expiry-choice/expiry-choice.component';
import { LpcRolesStepModule } from '../modules/lpc-roles-step/lpc-roles-step.module';
import { InstallmentCancellationComponent } from './installment-cancellation/installment-cancellation.component';
import { LpcInstallmentsModule } from '../modules/lpc-installments/lpc-installments.module';
import { ReductionComponent } from './reduction/reduction.component';
import { PremiumPaymentSuspensionComponent } from './premium-payment-suspension/premium-payment-suspension.component';
import { CancellationOperationsComponent } from './cancellation-operations-component/cancellation-operations.component';
import { LpcHelpModule } from '../modules/lpc-help/lpc-help.module';
import { PremiumPaymentReactivationComponent } from './premium-payment-reactivation/premium-payment-reactivation.component';
import {PremiumPaymentVariationComponent} from './premium-payment-variation/premium-payment-variation.component';
import { GenericClaimComponent } from './generic-claim/generic-claim.component';
import { LpcFormuleQuotationTableModule } from '../modules/lpc-formule-quotation-table/lpc-formule-quotation-table.module';
import {LoanGrantComponent} from './loan-grant/loan-grant.component';
import {ReductionReactivationComponent} from './reduction-reactivation/reduction-reactivation.component';
import { LoanRefundComponent } from './loan-refund/loan-refund.component';
import { LpcLoanRefundTableModule } from '../modules/lpc-loan-refund-table/lpc-loan-refund-table.module';
import { ReactivationFromTerminationComponent } from './reactivation-from-termination/reactivation-from-termination.component';
import { LpcVprinTableModule } from '../modules/vprin-unit-table/vprin-unit-table.module';
import { ModificationPersonalDataComponent } from './modification-personal-data/modification-personal-data.component';
import { LpcContractListTableModule } from './modification-personal-data/lpc-contract-list-table/lpc-contract-list-table.module';
import { OperationWithFinancialStepComponent } from '../interfaces/impl/operation-with-financial-step.component';
import { ConventionInsertionExclusionComponent } from './convention-insertion-exclusion/convention-insertion-exclusion.component';
import { BenefSummaryModule } from '../utils/pipe/benefsummary/benefsummary.module';
import { EarlyTotalSurrenderComponent } from './early-total-surrender/early-total-surrender.component';
import { TransferReserveComponent } from './transfer-reserve/transfer-reserve.component';
import { LpcFormFieldHandlerComponentModule } from '../modules/lpc-form-field-handler/lpc-form-field-handler.component.module';
import { BackpackTransferComponent } from './backpack-transfer/backpack-transfer.component';
import { LpcDataTransferModule } from '../modules/lpc-data-transfer/lpc-data-transfer.module';
import { TaxRelief2022Component} from './tax-relief-2022/tax-relief-2022.component';
import { RgiRxExpansionModule, RgiRxFormElementsModule } from '@rgi/rx/ui';
import { TransferToOtherFundComponent } from './transfer-to-other-fund/transfer-to-other-fund.component';
import { AdvancePaymentComponent } from './advance-payment/advance-payment.component';
import { ReusableTablesModule } from '../utils/reusable-components/reusable-components.module';
import { RgiCountryLayerModule } from '@rgi/rgi-country-layer';
import { RgiRxI18nModule } from '@rgi/rx/i18n';
import { ChangeClausesComponent } from './change-clauses/change-clauses.component';
import { ExtractFromArrayPipeModule } from '../utils/pipe/extract-from-array/extract-from-array.module';
import { LpcRequiredModule } from '../utils/lpc-required/lpc-required.module';
import { PensionBuybackModule } from './pension-buyback/pension-buyback.module';
import { PensionBuybackOperationComponent } from './pension-buyback/pension-buyback.component';

@NgModule({
    declarations: [
        ChangeContactComponent,
        ChangeAppendixComponent,
        ChangeRolesComponent,
        ChangeBeneficiariesComponent,
        NonCompletionComponent,
        ChangePaymentModeComponent,
        RecessComponent,
        TotalEarlyRepaymentComponent,
        PartialEarlyRepaymentComponent,
        DeathClaimComponent,
        ChangePaymentFrequencyComponent,
        DynamicOperationComponent,
        TerminationIndividualPolicyComponent,
        AdditionalPaymentComponent,
        PartialSurrenderComponent,
        PolicyTrasnferComponent,
        RenewalBookingComponent,
        TerminateRescissionComponent,
        DeleteToolComponent,
        ActivateToolComponent,
        ChangeCombinationComponent,
        CancellationOperationsComponent,
        ChangeSettlementBenefComponent,
        CancelAppendixComponent,
        ToolManagerComponent,
        OpenLienComponent,
        OpenAssignmentComponent,
        TotalSurrenderComponent,
        CloseAssignmentComponent,
        SwitchFreeComponentComponent,
        ExpiryChoiceComponent,
        InstallmentCancellationComponent,
        ReductionComponent,
        PremiumPaymentSuspensionComponent,
        PremiumPaymentReactivationComponent,
        PremiumPaymentVariationComponent,
        ConstitutionAnnuitiesOperationsComponent,
        GenericClaimComponent,
        LoanGrantComponent,
        ReductionReactivationComponent,
        LoanRefundComponent,
        ReactivationFromTerminationComponent,
        ModificationPersonalDataComponent,
        OperationWithFinancialStepComponent,
        ConventionInsertionExclusionComponent,
        EarlyTotalSurrenderComponent,
        TransferReserveComponent,
        BackpackTransferComponent,
        TaxRelief2022Component,
        TransferToOtherFundComponent,
        AdvancePaymentComponent,
        ChangeClausesComponent,
        PensionBuybackOperationComponent,
    ],
    imports: [
        CommonModule,
        FormsModule,
        NgSelectModule,
        LpcStepperModule,
        LpcDatesStepModule,
        LpcRolesStepModule,
        LpcNavigationButtonsModule,
        LpcPolicyContactModule,
        ReactiveFormsModule,
        LpcStaticLabelModule,
        LpcErrorMessagesModule,
        LpcRolesControlModule,
        LpcQuotationModule,
        PortalNgCoreModule,
        MessageModalComponentModule,
        LpcBeneficiaryModule,
        QuestionnairesManagerModule,
        NgSelectModule,
        AngularIbanModule,
        LpcThirdPartyModule,
        LpcDefinitionTableModule,
        LpcFormuleQuotationTableModule,
        LpcFactorAdapterModule,
        LpcPaymentStepModule,
        LpcPremiumModule,
        LpcFormuleModalModule,
        LpcQuotationControlModule,
        LpcUnitTableModule,
        LpcVprinTableModule,
        LpcQuotationDetailModalModule,
        LpcCommissionDetailModalModule,
        LpcWarrantyDetailModalModule,
        LpcModalModule,
        LpcCheckboxAccordionModule,
        LpcToolsModule,
        LpcKarmaFundModule,
        RgiCountryLayerModule,
        DocumentModule,
        LpcQuestionnaireModule,
        LpcLiquidationModule,
        LpcDataTransferModule,
        LpcWarningMessagesModule,
        LpcSubjectModule,
        LpcDatepickerModule,
        LpcInstallmentsModule,
        LpcHelpModule,
        LpcLoanRefundTableModule,
        LpcContractListTableModule,
        BenefSummaryModule,
        LpcFormFieldHandlerComponentModule,
        RgiRxExpansionModule,
        ReusableTablesModule,
        RgiRxI18nModule,
        RgiRxFormElementsModule,
        ExtractFromArrayPipeModule,
        LpcRequiredModule,
        PensionBuybackModule
    ],
    // TODO: check if these exports are needed
    exports: [
        ChangeContactComponent,
        ChangeAppendixComponent,
        ChangeRolesComponent,
        NonCompletionComponent,
        RecessComponent,
        TotalEarlyRepaymentComponent,
        PartialEarlyRepaymentComponent,
        DeathClaimComponent,
        ChangePaymentFrequencyComponent,
        DynamicOperationComponent,
        TerminationIndividualPolicyComponent,
        AdditionalPaymentComponent,
        PartialSurrenderComponent,
        PolicyTrasnferComponent,
        RenewalBookingComponent,
        TerminateRescissionComponent,
        DeleteToolComponent,
        CancellationOperationsComponent,
        ChangeSettlementBenefComponent,
        ToolManagerComponent,
        OpenLienComponent,
        OpenAssignmentComponent,
        TotalSurrenderComponent,
        CloseAssignmentComponent,
        SwitchFreeComponentComponent,
        ExpiryChoiceComponent,
        InstallmentCancellationComponent,
        ReductionComponent,
        PremiumPaymentSuspensionComponent,
        PremiumPaymentReactivationComponent,
        PremiumPaymentVariationComponent,
        ConstitutionAnnuitiesOperationsComponent,
        GenericClaimComponent,
        LoanGrantComponent,
        ReductionReactivationComponent,
        LoanRefundComponent,
        ReactivationFromTerminationComponent,
        ModificationPersonalDataComponent,
        OperationWithFinancialStepComponent,
        ConventionInsertionExclusionComponent,
        EarlyTotalSurrenderComponent,
        TransferReserveComponent,
        BackpackTransferComponent,
        TaxRelief2022Component,
        TransferToOtherFundComponent,
        AdvancePaymentComponent,
        ChangeClausesComponent,
    ],
    providers: [
        PlcQuestService
    ]
})
export class OperationComponentsModule {
}
