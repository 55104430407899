
<div *ngIf="hasInfo()" data-qa="info-error-messages">
  <div class="rgi-ui-snackbar-header rgi-ui-info" *ngFor="let item of infoMsgs">
    <span class="rgi-ui-icon-snackbar rgi-ui-icon-info"></span>
    <span>{{item}}</span>
  </div>
</div>
<div *ngIf="hasWarning()" data-qa="warning-error-messages">
  <div class="rgi-ui-snackbar-header rgi-ui-default rgi-lcc-message" *ngFor="let item of warningMsgs">
    <span class="rgi-ui-icon-snackbar rgi-ui-icon-alert"></span>
    <span>{{item}}</span>
  </div>
</div>
<div *ngIf="hasAuthorizzation()" data-qa="autho-error-messages">
  <div class="rgi-ui-snackbar-header rgi-ui-warning rgi-lcc-message"  *ngFor="let item of authoMsgs">
    <span class="rgi-ui-icon-snackbar rgi-ui-icon-info"></span>
    <span>{{item}}</span>
  </div>
</div>
<div *ngIf="hasValidation()" data-qa="validation-error-messages">
  <div class="rgi-ui-snackbar-header rgi-ui-danger rgi-lcc-message" *ngFor="let item of validationMessages">
      <span class="rgi-ui-icon-snackbar rgi-ui-icon-alert"></span>
      <span>{{item}}</span>
  </div>
</div>
<div *ngIf="hasBlocking()" data-qa="blocking-error-messages">
  <div class="rgi-ui-snackbar-header rgi-ui-danger rgi-lcc-message" *ngFor="let item of blockingMsgs">
      <span class="rgi-ui-icon-snackbar rgi-ui-icon-alert"></span>
      <span>{{item}}</span>
  </div>
</div>

