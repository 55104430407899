import { PolicyService } from './../../../services/policy-service';
import {Component, forwardRef, HostListener, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  UntypedFormControl,
  UntypedFormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator
} from '@angular/forms';
import {KarmaFundDefinition} from '../../model/karma-fund-definition';
import {Subscription} from 'rxjs';
import {LicObjectUtils} from '../../../utils/lic-object-utils';
import {distinctUntilChanged} from 'rxjs/operators';
import { SliderProperty } from '../../model/karma-fund';
import { PROPERTY_VALUE } from '../../../enum/life-issue.enum';
import { RgiCtryLayerSymbolFormatterPipe } from '@rgi/rgi-country-layer';

@Component({
  selector: 'lic-karma-fund-button[totalAmount][definition]',
  templateUrl: './lic-karma-fund-button.component.html',
  styleUrls: ['./lic-karma-fund-button.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => LicKarmaFundButtonComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => LicKarmaFundButtonComponent),
      multi: true
    }
  ]
})
export class LicKarmaFundButtonComponent implements OnInit, OnChanges, OnDestroy, ControlValueAccessor, Validator {

  public get isSelected(): boolean {
    return this.formGroup.get('percent').value > 0 || this.$selected;
  }

  public get isPercent(): boolean {
    return this.formGroup.get('isPercent').value;
  }

  currencyCode = '€';

  public get labelSymbol(): string {
    return this.formGroup.get('isPercent').value ? '%' : this.currencyCode;
  }

  constructor(
    protected policyService: PolicyService,
    protected currencySymbolPipe: RgiCtryLayerSymbolFormatterPipe) {
    this.currencyCode = !!this.policyService.currencyCode ?
    currencySymbolPipe.transform(this.policyService.currencyCode) : this.currencyCode;
  }

  @Input() public definition: KarmaFundDefinition;
  @Input() public totalAmount: number;
  @Input() public isMinimumRequired = true; // whether at least the minimum percent value is required or not
  @Input() public sliderProperty: SliderProperty;

  public formGroup: UntypedFormGroup = new UntypedFormGroup({
    percent: new UntypedFormControl(),
    amount: new UntypedFormControl(),
    isPercent: new UntypedFormControl(true)
  });

  private $subscriptions: Subscription[] = [];

  private $selected = false;

  private $clickedInside: boolean;

  @HostListener('click')
  onClick() {
    this.$clickedInside = true;
    this.$selected = true;
    if (this.sliderProperty === PROPERTY_VALUE._MODIN.PERCENTAGE) {
      this.formGroup.get('isPercent').setValue(true);
      this.formGroup.get('isPercent').disable();
    } else if (this.sliderProperty === PROPERTY_VALUE._MODIN.AMOUNT) {
      this.formGroup.get('isPercent').setValue(false);
      this.formGroup.get('isPercent').disable();
    }
  }

  @HostListener('document:click')
  onClickOut() {
    if (!this.$clickedInside) {
      this.$selected = false;
    }
    this.$clickedInside = false;
  }

  ngOnInit() {
    this.$subscriptions.push(
      this.formGroup.get('percent').valueChanges.pipe(
        distinctUntilChanged()
      ).subscribe(value => {
        this.formGroup.get('amount').setValue(
          !!this.totalAmount ? LicObjectUtils.roundToDecimal(value * this.totalAmount / 100, 2) : 0,
          {emitEvent: false}
        );

        this.onChange(value / 100);
        this.onTouch();
      }),
      this.formGroup.get('amount').valueChanges.pipe(
        distinctUntilChanged()
      ).subscribe(value => {
        this.formGroup.get('percent').setValue(
          !!this.totalAmount ? LicObjectUtils.roundToDecimal(value / this.totalAmount * 100, 2) : 0,
          {emitEvent: false}
        );
        this.onChange(this.formGroup.get('percent').value / 100);
        this.onTouch();
      })
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.totalAmount) {
      if (!changes.totalAmount.currentValue) {
        this.formGroup.get('amount').setValue(
          0, {emitEvent: false}
        );
        this.formGroup.disable({emitEvent: false});
      } else {
        this.formGroup.get('amount').setValue(
          this.formGroup.get('percent').value * changes.totalAmount.currentValue, {emitEvent: false}
        );
        this.formGroup.enable({emitEvent: false});
      }
    }
  }

  ngOnDestroy(): void {
    this.$subscriptions.forEach(s => {
      s.unsubscribe();
    });
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    if (isDisabled) {
      this.formGroup.disable({emitEvent: false});
    } else {
      this.formGroup.enable({emitEvent: false});
    }
  }

  writeValue(percent: number): void {
    if (!!percent) {
      this.formGroup.patchValue({
        percent: percent * 100,
        amount: percent * this.totalAmount,
      }, {emitEvent: false});
    } else {
      this.formGroup.patchValue({
        percent: 0,
        amount: 0,
      }, {emitEvent: false});
    }
  }

  validate(control: AbstractControl): ValidationErrors | null {
    const obj: ValidationErrors = {};
    const percent: number = this.formGroup.get('percent').value / 100;
    if (percent > this.definition.maxPercentAllocation) {
      obj.max = {value: percent, limit: this.definition.maxPercentAllocation};
    } else if (percent < this.definition.minPercentAllocation) {
      if (this.isMinimumRequired) {
        obj.min = {value: this.formGroup.get('percent').value / 100, limit: this.definition.minPercentAllocation};
      } else if (percent > 0 && !this.isMinimumRequired) {
        obj.min = {value: this.formGroup.get('percent').value / 100, limit: this.definition.minPercentAllocation};
      }
    }
    return !!Object.keys(obj).length ? obj : null;
  }

  onChange(obj: number) {
  }

  onTouch() {
  }

  roundValue(field: string) {
    const value: number = this.formGroup.get(field).value;
    this.formGroup.get(field).setValue(LicObjectUtils.roundToDecimal(value, 2));
  }
}
