import { Component, EventEmitter, Inject, OnDestroy, OnInit, Output } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { AlertService, CardComponent, FunctionService } from '@rgi/digital-claims-common-angular';
import { ReserveRequest } from './dto/reserve-request';
import { PartyDamageCard, ReserveResponse, TolMapList } from './dto/reserve-response';
import { ReserveUpdateRequest } from './dto/reserve-update-request';
import { TypeOfLossDamage } from './dto/typeofloss-damage';
import { TypeOfLossDamageSave } from './dto/typeofloss-request-save';
import { UpdateReserveService } from './update-reserve.service';
import { RgiRxTranslationService } from '@rgi/rx/i18n';
import {
  DetailReopeningReactivation, INIT_DETAIL_REOPEN_REACT,
  IReasonReopening, ReasonReopening
} from './dto/reopeningReactivation/reopening-reactivation.interface';
import { DatePipe } from '@angular/common';
import { GlobalModalConfirmComponent } from '../shared/modal/global-modal-confirm/global-modal-confirm.component';
import { ModalService } from '@rgi/rx/ui';
import { Observable } from 'rxjs';



@Component({
  selector: 'claims-update-reserve',
  templateUrl: './update-reserve.component.html',
  styleUrls: ['./update-reserve.component.css']
})

export class UpdateReserveComponent extends CardComponent implements OnInit, OnDestroy {
  @Output() editDone = new EventEmitter<boolean>();
  UPDATE_RESERVE_OPERATION: string = 'modifyReserve';
  claim: any;
  party: any;
  idTol: any;
  companyDescription: string;
  public authSrv: any;
  public companies: any[];
  public coinsurenceTypeDef: string;
  public isLimitsForcing: boolean;
  coassTypeDescr: any;
  actorNominative: any;
  session: any;
  typeoflossDamageFromActor: PartyDamageCard[];
  form: UntypedFormGroup;
  notValidTol: boolean;
  public today: NgbDate;
  tolMap: Map<number, boolean> = new Map<number, boolean>();
  tolMapList: TolMapList[] = [];
  formValid: boolean = false;
  isManageDamageCard: boolean;
  limits: [];
  detailReopenReactivation: DetailReopeningReactivation = INIT_DETAIL_REOPEN_REACT;
  reopenReactivationForm: UntypedFormGroup = this.formBuilder.group({});
  datepickerPlaceholder: string = '';
  reasonReopeningList: ReasonReopening[] = [];
  showFormReopeningReactivation: boolean = false;
  amountValid: boolean = true;
  reserveAmount: number;
  settlementCosts: number;

  constructor(
    @Inject('claimsBaseObjectService') private claimsBaseObjectService: any,
    @Inject('sessionService') session: any,
    @Inject('coreResult') private coreResult: any,
    @Inject('authService') private authService,
    @Inject('eventService') private eventService: any,
    private updateReserveService: UpdateReserveService,
    public formBuilder: UntypedFormBuilder,
    private alertService: AlertService,
    private functionService: FunctionService,
    public translateService: RgiRxTranslationService,
    private datepipe: DatePipe,
    private modalServiceRgi: ModalService
  ) {
    super();
    this.today = this.generateMinDate(new Date().getTime());
    this.isLimitsForcing = false;
    this.companies = this.authService.getOperator().visibleCompanies;
    this.form = formBuilder.group({
      initValToReset: formBuilder.control('', null)
    });
    this.session = session;


  }
  ngOnDestroy(): void {
    if (this.claim && this.claim.claimNumber) {
      this.claimsBaseObjectService.reloadClaim(this.claim.claimNumber, this.claim.aniaCompanyCode, this.card.idSessionParent);
    }
  }
  reflectChangeonForm() {
    const validValues: Map<number, boolean> = new Map<number, boolean>();
    this.tolMap.forEach((value: boolean, key: number) => {
      if (value) {
        if (this.form.controls['updateDate' + key].valid &&
          this.form.controls['amountTolInput' + key].valid &&
          this.form.controls['amountTolInput' + key].value > 0 &&
          this.form.controls['assignmentExpensesAmount' + key].valid) {
          validValues.set(key, true);
        } else {
          validValues.set(key, false);
        }
      }

    });
    let foundError = false;

    validValues.forEach(element => {
      if (!element) {
        foundError = true;
      }

    });
    if (!foundError) {
      this.formValid = true;
    } else {
      this.formValid = false;
    }
  }

  changeReserveAmount(value) {
    this.reserveAmount = parseFloat(value);
    if (this.reserveAmount <= 0 || this.settlementCosts <= 0) {
      this.amountValid = false;
    } else {
      this.amountValid = true;
    }
  }

  changeSettlementCosts(value) {
    this.settlementCosts = parseFloat(value);
    if (this.reserveAmount <= 0 || this.settlementCosts <= 0) {
      this.amountValid = false;
    } else {
      this.amountValid = true;
    }
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.detailReopenReactivation = INIT_DETAIL_REOPEN_REACT;
    this.fetchHeaderData();
    const bodyLoadingUpdateReserve: ReserveRequest = {
      claimNumber: this.claim.claimNumber,
      idParty: this.party ? this.party.identifier : undefined,
      idTol: this.idTol ? this.idTol : undefined,
      idSettlement: null,
      reasonUpdate: this.UPDATE_RESERVE_OPERATION
    };
    this.updateReserveService.loadingUpdateReserve(bodyLoadingUpdateReserve)
      .subscribe((result: ReserveResponse) => {
        this.typeoflossDamageFromActor = result.partyDamageCard;
        this.isManageDamageCard = result.manageDamageCard;
        this.form = this.getNewReserveUpdateForm(this.typeoflossDamageFromActor);
        this.typeoflossDamageFromActor.forEach(actors => {
          let tolMapList: TolMapList;
          actors.sxTypeOfLossDamage.forEach(tol => {
            tolMapList = {
              idParty: actors.idParty,
              idTol: tol.idTol,
              selected: true
            };
            this.tolMapList.push(tolMapList);
            this.tolMap.set(tol.idTol, true);
            this.limits = tol.limits;
          });
        });
        if (this.UPDATE_RESERVE_OPERATION === 'reopeningReactivation') {
          this.initReopenReactivationFrom();
          this.getReasonReopeningList();
        }
        this.reflectChangeonForm();
      });
    this.isLimitsForcing = this.functionService.isAuthorizedForClaimFeature('LIMITS_FORCING');
  }
  getCurrentSession() {
    return this.session.list().find(el => el.isActive);
  }
  onSubmit() {
    this.eventService.broadcastEvent('start-loader');
    const listTol = [];
    this.tolMap.forEach((value: boolean, key: number) => {
      if (value) {
        const reserve = new TypeOfLossDamageSave();
        reserve.idTol = key;
        reserve.amountTolInput = this.form.get('amountTolInput' + key).value;
        reserve.assignmentExpensesAmount = this.form.get('assignmentExpensesAmount' + key).value;
        reserve.updateDate = this.form.get('updateDate' + key).value;
        if (this.UPDATE_RESERVE_OPERATION === 'reopeningReactivation') {
          reserve.updateDate = this.datepipe.transform(new Date(
            this.reopenReactivationForm.get('dataReopenReactivation').value), 'dd/MM/yyyy');
          reserve.tolDescription = this.reopenReactivationForm.get('reasonReopeningNote').value;
          reserve.idMotivazione = this.reopenReactivationForm.get('reasonReopeningCode').value !== '' ?
            this.reopenReactivationForm.get('reasonReopeningCode').value : undefined;
        }
        listTol.push(reserve);
      }
    });
    if (this.UPDATE_RESERVE_OPERATION === 'reopeningReactivation') {

      this.updateReserveService.chekUpdateReactivationMulti(
        new ReserveUpdateRequest(this.claim.claimNumber, listTol, false, false)
      ).subscribe((result: any) => {
        this.eventService.broadcastEvent('stop-loader');
        if (result.code === '1') {
          this.generateNewSessionFinder();
          this.ngOnDestroy();
        } else {
          this.alertService.translatedMessage(result.descr);
        }
      });
    } else {
      this.updateReserveService.saveUpdateReserve(
        new ReserveUpdateRequest(this.claim.claimNumber, listTol, false, false))
        .subscribe((result: any) => {
          this.eventService.broadcastEvent('stop-loader');
          if (result.code === '1') {
           // this.exit('save');
            this.generateNewSessionFinder();
            this.ngOnDestroy();
          } else if (result.code === '2') {
            if (this.isLimitsForcing) {
              this.openGlobalModalConfirm('The reserve entered exceeds the associated ceiling', result, listTol);
            }
            // RDC-864
          } else if (result.code === '3') {
            this.alertService.translatedMessage(result.descr);
            this.exit('save');
            this.ngOnDestroy();
          } else if (result.code === '0') {
            this.alertService.translatedMessage(result.descr);
          }
        });
    }
  }

  generateNewSessionFinder() {
    const queryParams = '/' + this.claim.claimNumber + '?companyCode='
      + this.companyDescription
      + '&internalCompanyCode=' + this.claim.internalCompanyCode;
    this.getDetailClaim(queryParams).subscribe(res => {
      if (res.claim) {
        this.exit('save');
        this.translateService.translate('_CLAIMS_._MESSAGE_._UPDATE_RESERVE_SUCCESSFUL').subscribe(res => {
          this.alertService.translatedMessage(res);
        });
        const detailResult = res.claim;
        const idResult = this.session.open('claimsFinder', 'detail', '', true);
        detailResult.idResult = idResult; // appoggio l'id del coreresult per aggiornare l'oggetto in cache
        this.coreResult.setResult(idResult, 'detail', detailResult);
      }
    }), (error) => {
      this.translateService.translate('_CLAIMS_._MESSAGE_._UPDATE_RESERVE_ERROR').subscribe(res => {
        this.alertService.translatedMessage(res);
      });
    };
  }

  getDetailClaim(queryParams: string): Observable<any> {
    return this.updateReserveService.getDetailClaim(queryParams);
  }


  getFinderSession() {
    const sessioneFinder = this.session.list().filter(el => el.name === 'claimsFinder' && el.currentRoute === 'detail');
    if (sessioneFinder.length > 0) {
      return sessioneFinder[0];
    } else {
      return undefined;
    }
  }

  exit(typeExit: string) {
    const session = this.getCurrentSession();
    const finderSession = this.getFinderSession();
    this.session.remove(session.idSession);
    if (typeExit !== 'back' && finderSession) {
      this.session.remove(finderSession.idSession);
    }
    this.detailReopenReactivation = INIT_DETAIL_REOPEN_REACT;

    // this.session.remove(session.idSession);
  }


  exit_() {
    this.detailReopenReactivation = INIT_DETAIL_REOPEN_REACT;
    const session = this.getCurrentSession();
    this.session.remove(session.idSession);
  }

  openGlobalModalConfirm(messageInput: string, result: any, listTol: any) {
    this.modalServiceRgi.open(GlobalModalConfirmComponent, {
      contentMessage: messageInput,
    }).onClose.subscribe(response => {
      if (response && response !== 'Close') {
        if (result.code === '2') {
          this.updateReserveService.saveUpdateReserve(
            new ReserveUpdateRequest(this.claim.claimNumber, listTol, false, true)).subscribe((result2: any) => {
              this.eventService.broadcastEvent('stop-loader');
              if (result2.code === '1') {
                // this.alertService.translatedMessage('Authorization process started for exceeding reserve limits');
                if (result2.descr) {
                  this.alertService.translatedMessage(result2.descr);
                }
                this.exit('save');
                this.ngOnDestroy();
              } else if (result2.code === '0') {
                this.alertService.translatedMessage(result2.descr);
              } else if (result2.code === '3') {
                this.alertService.translatedMessage(result2.descr);
                this.exit('save');
                this.ngOnDestroy();
              }
            });
        } else if (result.code === '3') {
          this.alertService.translatedMessage(result.descr);
          this.exit('save');
          this.ngOnDestroy();
        }
      }
    });
  }

  validateSelectedTol(array: TolMapList[]): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {

      this.notValidTol = true;
      if (this.UPDATE_RESERVE_OPERATION !== 'reopeningReactivation') { } else { }
      const listTolMap = [];
      for (const loss of array) {

        if (this.form.get('selectedTol' + loss.idTol) && this.form.get('selectedTol' + loss.idTol).value) {
          this.tolMap.set(loss.idTol, true);
          listTolMap.push({ value: true });
          this.form.get('updateDate' + loss.idTol).enable();

        } else {
          this.tolMap.set(loss.idTol, false);
          if (this.form.get('updateDate' + loss.idTol)) {
            this.form.get('updateDate' + loss.idTol).disable();

          }
          listTolMap.push({ value: false });
        }

      }
      this.notValidTol = listTolMap.filter(res => res.value === true).length === 0 ? true : false;


      if (this.notValidTol) {
        return {
          value: {
            valid: this.notValidTol
          }
        };
      } else {
        return null;
      }


    };
  }
  protected getNewReserveUpdateForm(typeOfLossDamage: PartyDamageCard[]) {
    const group: any = {};
    typeOfLossDamage.forEach((actors: PartyDamageCard) => {

      for (const damage of actors.sxTypeOfLossDamage) {
        const id = damage.idTol;
        group['selectedTol' + id] = new UntypedFormControl(true, [Validators.required, this.validateSelectedTol(this.tolMapList)]);
        group['updateDate' + id] = new UntypedFormControl({ value: new Date(), disabled: false }, Validators.required);
        group['amountTolInput' + id] = new UntypedFormControl(damage.amountTolInput || 0, [Validators.required]);
        group['assignmentExpensesAmount' + id] = new UntypedFormControl(damage.assignmentExpensesAmount || 0, [Validators.required]);
        group['enableAssignmentExpensesAmount' + id] = new UntypedFormControl(damage.enableAssignmentExpensesAmount, [Validators.required]);
      }

    });

    return new UntypedFormGroup(group);
  }

  initReopenReactivationFrom() {
    this.reopenReactivationForm.addControl('dataReopenReactivation',
      new UntypedFormControl({ value: new Date(), disabled: true }, Validators.required));
    this.reopenReactivationForm.addControl('reasonReopeningCode',
      new UntypedFormControl({ value: '', disabled: false }, Validators.required));
    this.reopenReactivationForm.addControl('reasonReopeningNote',
      new UntypedFormControl({ value: '', disabled: false }, Validators.required));
    this.showFormReopeningReactivation = true;
  }

  onDateSelect() { }

  private fetchHeaderData() {
    const jumpOperation = this.coreResult.getResult(this.id, 'operationJump');
    if (jumpOperation && jumpOperation.jumpCommand === 'reactivationClaim') {
      this.UPDATE_RESERVE_OPERATION = 'reopeningReactivation';
    }
    this.claim = this.coreResult.getResult(this.id, 'detail');

    this.party = this.coreResult.getResult(this.id, 'party');
    this.idTol = this.coreResult.getResult(this.id, 'idTol');
    this.companyDescription = this.companies.find(value => value.aniaCode === this.claim.aniaCompanyCode).description;
    let nomin = '';
    if (this.party) {
      nomin = (this.party.surname ? this.party.surname : '') + ' ' + (this.party.name ? this.party.name : '');
      this.actorNominative = (nomin.trim() !== '') ? nomin : this.party.subject.designation;
    }


    switch (this.claim.coinsuranceType) {
      case '1': this.coinsurenceTypeDef = '_CLAIMS_._NO_DELEGATION';
                break;
      case '2': this.coinsurenceTypeDef = '_CLAIMS_._OUR_DELEGATION';
                break;
      case '3': this.coinsurenceTypeDef = '_CLAIMS_._THIRD_PARTY_DELEGATION';
                break;
      default:
        break;
    }
  }

  isEnableAssignmentExpensesAmount(key): boolean {
    return this.form.get('enableAssignmentExpensesAmount' + key).value;
  }


  assignmentExpensesAmountEnabled(val): boolean {
    return this.tolMap.get(val) && this.isEnableAssignmentExpensesAmount(val);
  }

  generateMinDate(data: number): NgbDate {
    let date;
    if (data) {
      date = new Date(data);
    } else {
      date = new Date();
    }

    return new NgbDate(date.getFullYear(), date.getMonth() + 1, date.getDate());
  }

  getReasonReopeningList() {
    const body: IReasonReopening = {
      receiptIdMotivoAnnullo: '2',
      receiptDate: this.formatDateTimezone(this.claim.reportDate),
      receiptIdRamoMinisteriale: this.claim.ministerialBranchCodes
    };
    this.updateReserveService.getReasonReopening(body).subscribe((res) => {
      if (res) {
        this.reasonReopeningList.push(...this.initComboReasonRejectedList(res));
      }
      this.checkValidatorsForm();
    }, (error) => {
      this.checkValidatorsForm();
    });
  }
  formatDateTimezone(date: string): string {
    const miaData = new Date(date);
    const timezoneOffset = Math.abs(new Date().getTimezoneOffset());
    miaData.setTime(miaData.getTime() + timezoneOffset * 60000);
    const dataOutput = this.datepipe.transform(miaData, 'dd-MM-yyyy');
    return dataOutput;
  }

  initComboReasonRejectedList(lista: any): ReasonReopening[] {
    const reasonRejectedComboList: ReasonReopening[] = [];
    if (lista && lista[0]) {
      lista[0].forEach((res, index) => {
        const combo: ReasonReopening = {
          code: res.toString(),
          description: lista[1][index]
        };
        reasonRejectedComboList.push(combo);
      });
    }
    return reasonRejectedComboList;
  }

  checkValidatorsForm() {
    if (this.reasonReopeningList.length > 0) {
      if (this.reopenReactivationForm.get('dataReopenReactivation')) { this.reopenReactivationForm.get('dataReopenReactivation').setValidators(Validators.required); }
      if (this.reopenReactivationForm.get('reasonReopeningCode')) { this.reopenReactivationForm.get('reasonReopeningCode').setValidators(Validators.required); }
      if (this.reopenReactivationForm.get('reasonReopeningNote')) { this.reopenReactivationForm.get('reasonReopeningNote').setValidators(Validators.required); }
    } else {
      if (this.reopenReactivationForm.get('dataReopenReactivation')) { this.reopenReactivationForm.get('dataReopenReactivation').setValidators(Validators.required) }
      if (this.reopenReactivationForm.get('reasonReopeningCode')) { this.reopenReactivationForm.get('reasonReopeningCode').clearValidators();}
      if (this.reopenReactivationForm.get('reasonReopeningNote')) { this.reopenReactivationForm.get('reasonReopeningNote').setValidators(Validators.required); }
      //if (this.reopenReactivationForm.get('dateReceipt')) { this.reopenReactivationForm.get('dateReceipt').setValidators(Validators.required); }
    }


    if (this.reopenReactivationForm.get('reasonReopeningCode')) { this.reopenReactivationForm.get('reasonReopeningCode').updateValueAndValidity(); }
    if (this.reopenReactivationForm.get('dataReopenReactivation')) { this.reopenReactivationForm.get('dataReopenReactivation').updateValueAndValidity(); }
    if (this.reopenReactivationForm.get('reasonReopeningNote')) { this.reopenReactivationForm.get('reasonReopeningNote').updateValueAndValidity(); }

    // this "reasonreasonReopeningCodeRejected" go in error and reasonreasonReopeningCodeRejected does not exist
    /*if (this.reopenReactivationForm.get('reasonreasonReopeningCodeRejected').value) {
      this.reopenReactivationForm.get('reasonreasonReopeningCodeRejected').updateValueAndValidity();
    } else {
      this.reopenReactivationForm.controls.reasonreasonReopeningCodeRejected.setValue('');
      this.reopenReactivationForm.get('reasonreasonReopeningCodeRejected').updateValueAndValidity();
    }*/
  }
}
