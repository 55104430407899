import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LpcCommissionDetailModalComponent } from './lpc-commission-detail-modal.component';
import { PortalNgCoreModule } from '@rgi/portal-ng-core';
import { RgiCountryLayerModule } from '@rgi/rgi-country-layer';
import { RgiRxI18nModule } from '@rgi/rx/i18n';


@NgModule({
    declarations: [LpcCommissionDetailModalComponent],
    imports: [
        CommonModule,
        PortalNgCoreModule,
        RgiCountryLayerModule,
        RgiRxI18nModule
    ],
    exports: [LpcCommissionDetailModalComponent]
})
export class LpcCommissionDetailModalModule { }
