import { DISTRIBUTION_AMOUNT, DISTRIBUTION_PERC } from './../../distribution-toggle/distribution-toggle.model';
import { Role } from './../../../models/postsales-operations-response.model';
import { RoleType, Roles } from './../../../models/enum/lpc-subjects.enum';
import {
  Component,
  EventEmitter,
  forwardRef,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  Output,
  QueryList,
  ViewChildren,
  ViewEncapsulation
} from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  UntypedFormArray,
  UntypedFormControl,
  UntypedFormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator
} from '@angular/forms';
import {
  BeneficiaryRole,
  PaymentTypeDefinition,
  QuestionnaireDefinition
} from '../../../models/postsales-operations-response.model';
import { Subscription} from 'rxjs';
import { ClaimBeneficiary} from '../model/claim-beneficiary';
import { ComponentWithAnagModal} from '../../../interfaces/component-with-anag-modal';
import { AnagSubject, PARTY_COMPLETE_KO} from '../../../models/subject.model';
import { AnagService} from '../../../services/anag.service';
import { PlcObjectUtils} from '../../../utils/plc-object-utils';
import { LpcClaimBeneficiaryComponent} from '../lpc-claim-beneficiary/lpc-claim-beneficiary.component';
import { areBeneficiariesChanged} from '../util/lpc-beneficiary-utils';
import { DistributionConfigDefinition, DistributionToggle } from '../../distribution-toggle/distribution-toggle.model';
import { RgiCtryLayerNumFormatterPipe } from '@rgi/rgi-country-layer';
import { CurrencyCacheService, LpcCurrencyCache } from '../../../services/currency-cache.service';
import { TranslationWrapperService } from '../../../i18n/translation-wrapper.service';
import { LpcBeneficiaryService } from '../../../services/beneficiary.service';

/* eslint-disable max-len */
@Component({
  selector: 'lpc-claim-beneficiaries',
  templateUrl: './lpc-claim-beneficiaries.component.html',
  styleUrls: ['./lpc-claim-beneficiaries.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => LpcClaimBeneficiariesComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => LpcClaimBeneficiariesComponent),
      multi: true
    },
    RgiCtryLayerNumFormatterPipe
  ],
  encapsulation: ViewEncapsulation.None
})
export class LpcClaimBeneficiariesComponent implements OnInit, OnDestroy, ControlValueAccessor, Validator, ComponentWithAnagModal {
  // formatter Options
  public currencyFormatterOptions: Intl.NumberFormatOptions = {
    style: 'currency'
  };
  public percentFormatterOptions: Intl.NumberFormatOptions = {
    style: 'percent',
    minimumIntegerDigits: 1,
    minimumFractionDigits: 2
  };

  @Input() paymentTypes: PaymentTypeDefinition[] = [];
  @Input() min = 0;
  @Input() max = Number.MAX_VALUE;
  @Input() effectiveDate: string;
  @Input() validateMandatoryBeneficiary = true;
  @Input() idAssicurato: string;
  @Input() beneficiaryType: string;
  @Input() productCode: string;
  @Input() summary: boolean;
  @Input() questionnairesCode: Map<string, boolean>;
  @Input() public questFactorsArray: any[] = [];
  @Input() public disabledQuestionArray: any[] = [];
  @Input() public defaultAnswersArray: any[] = [];
  @Input() questionnairesDefinitions: QuestionnaireDefinition[];
  @Input() public blockBeneficiaries = false;
  @Input() set distributionConfiguration(config: DistributionConfigDefinition) {
    this.distributionType = !!config ? config.distributionType : null;
    this.distributionConfig = config;
  }

  @Output() addedSubject: EventEmitter<Role> = new EventEmitter<Role>();
  @Output() triggerQuestPreval: EventEmitter<any> = new EventEmitter<any>();
  @Output() loaderQuest = new EventEmitter<string>();
  // eslint-disable-next-line max-len
  @Output() delete: EventEmitter<{benef?: string, linkedRole: ClaimBeneficiary}> = new EventEmitter<{benef?: string, linkedRole: ClaimBeneficiary}>();
  public roleCodeToAdd = Roles.BENEFICIARY;
  public linkedRoles: BeneficiaryRole[] = [];
  public showAddBeneficiary = true;
  private partyCompleteError: {subjIdPlusrole: string, messages: string[]}[] = [];

  @ViewChildren('beneficiary') beneficiary: QueryList<LpcClaimBeneficiaryComponent>;
  distributionType: DistributionToggle = null;
  distributionConfig: DistributionConfigDefinition = null;

  public get value(): ClaimBeneficiary[] {
    return this.formGroup.get('array').value;
  }

  public get messageInfos(): {amount: number | null, percent: number | null} {
    const totalAmount = this.getTotalAmount();
    const totalPercent: number = this.getTotalPercent();
    const amount = !!this.distributionConfig && !!this.distributionConfig.totalAmount
                    ? this.distributionConfig.totalAmount - totalAmount
                    : null;

    const obj: any = {};
    if (this.distributionConfig && this.distributionConfig.distributionType === DISTRIBUTION_PERC) {
      obj.percent = 100 - totalPercent;
    }
    if (this.distributionConfig && this.distributionConfig.distributionType === DISTRIBUTION_AMOUNT) {
      obj.amount = amount;
    }
    return obj;
  }

  private $subscriptions: Subscription[] = [];
  formGroup: UntypedFormGroup = new UntypedFormGroup({
    array: new UntypedFormArray([]),
  });

  private $errors: any[] = [];

  private getTotalPercent(): number {
    return (this.formGroup.getRawValue().array as ClaimBeneficiary[])
      .map(bene => Number((bene.subject.value as Role).percentage))
      .reduce((accumulator, currentValue) => accumulator + currentValue, 0);
  }

  private getTotalAmount() {
    return (this.formGroup.get('array') as UntypedFormArray).value
      .map(value => Number(((value as ClaimBeneficiary).subject.value as Role).amount))
      .reduce((accumulator, currentValue) => accumulator + currentValue, 0);
  }

  public get errors(): any[] {
    return this.$errors;
  }

  public get getBenefSummary(): { name: string, perc: string }[] {
    const vectorOfBenef = [];
    const benefVal = this.formGroup.get('array').value;
    if (!!benefVal && benefVal.length > 0) {
      benefVal.forEach(ben => {
        if (!!ben.subject && !!ben.subject.value) {
          vectorOfBenef.push({ name: ben.subject.value.name, perc: ben.subject.value.percentage});
        }
      });
    }
    return vectorOfBenef;
  }

  constructor(
    protected anagService: AnagService,
    protected rgiNumberFormatter: RgiCtryLayerNumFormatterPipe,
    protected translate: TranslationWrapperService,
    @Optional() @Inject(LpcCurrencyCache) protected currencyService: CurrencyCacheService,
    protected beneficiaryService: LpcBeneficiaryService) {
      this.currencyFormatterOptions.currency = currencyService.currency;
  }

  ngOnInit() {
    this.$subscriptions.push(
      this.formGroup.get('array').valueChanges.subscribe(() => {
        this.onChange(this.formGroup.getRawValue().array);
        this.onTouch();
        this.beneficiaryService.setTarghetBeneficiaries(this);
      })
    );
  }

  ngOnDestroy(): void {
    this.$subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  setDisabledState(isDisabled: boolean): void {
  }

  validate(control: AbstractControl): ValidationErrors | null {

    const errors: ValidationErrors = {};

    const beneficiaryErrors = (this.formGroup.get('array') as UntypedFormArray).controls
      .map(ctrl => ctrl.errors)
      .filter(err => !!err);

    const percentErrors = (this.formGroup.get('array') as UntypedFormArray).value
      .map(value => Number(((value as ClaimBeneficiary).subject.value as Role).percentage))
      .filter(percent => !percent);

    const totalAmount = this.getTotalAmount();

    const totalPercent: number = this.getTotalPercent();

    const totalPercentReadOnly: number = (this.formGroup.getRawValue().array as ClaimBeneficiary[])
      .filter(bene => !bene.editable)
      .map(bene => Number((bene.subject.value as Role).percentage))
      .reduce((accumulator, currentValue) => accumulator + currentValue, 0);

    this.showAddBeneficiary = totalPercentReadOnly < 100;

    const beneficiaries = this.formGroup.getRawValue().array.length;

    if (!beneficiaries && this.validateMandatoryBeneficiary) {
      errors.mandatoryBen = true;
    }

    if (!!beneficiaryErrors.length) {
      errors.beneficiaries = beneficiaryErrors;
    }

    if (!this.distributionConfig || (!!this.distributionConfig && this.distributionConfig.distributionType !== DISTRIBUTION_AMOUNT)) {
      if (!!percentErrors.length) {
        errors.percents = percentErrors;
      }

      if ((totalPercent > 100) && !!this.value.length) {
        errors.totalPercent = true;
      }

      if ((totalPercent < 100) && !!this.value.length && this.validateMandatoryBeneficiary) {
        errors.minTotalPercent = true;
      }

      if (this.value.length < this.min) {
        errors.min = true;
      }

      if (this.value.length > this.max) {
        errors.max = true;
      }
    }

    const isDistributionAmount = !!this.distributionConfig && this.distributionConfig.distributionType === DISTRIBUTION_AMOUNT;
    const isLimitAmountExceeded = !!this.distributionConfig && !!this.distributionConfig.totalAmount && totalAmount > this.distributionConfig.totalAmount;

    if (isDistributionAmount && isLimitAmountExceeded) {
      errors.totalAmount = {amount: this.rgiNumberFormatter.transform(this.distributionConfig.totalAmount, '', this.currencyFormatterOptions)};
    }

    if (!!this.partyCompleteError.length) {
      Object.assign(errors, {partycomplete: this.partyCompleteError});
    }

    this.$errors = Object.keys(errors).map(key => {
      return {key, value: errors[key]};
    });

    if (!!Object.keys(errors).length) {
      return errors;
    } else {
      return null;
    }
  }

  writeValue(objs: ClaimBeneficiary[]): void {
    const formArrayBeneficiaries = this.formGroup.get('array') as UntypedFormArray;
    const currentBeneficiaries = formArrayBeneficiaries.value as ClaimBeneficiary[];
    if (!!objs && areBeneficiariesChanged(currentBeneficiaries, objs)) {
      formArrayBeneficiaries.clear();
      objs.forEach(obj => {
        formArrayBeneficiaries.push(new UntypedFormControl(obj));
      });
    }
  }

  onChange(obj: ClaimBeneficiary[]) {
  }

  onTouch() {
  }

  openAnagSubjectModal() {
    this.anagService.openSubjectModal(this);
  }

  receiveAnagSubjectFromModal(subject: AnagSubject) {
    const role: Role = AnagService.subjectToRole(subject, this.roleCodeToAdd);
    const formArray = this.formGroup.get('array') as UntypedFormArray;
    const foundIndex = formArray.controls.findIndex((control: UntypedFormControl) => control.value.subject.value.id === role.id);

    if (-1 === foundIndex) {
      this.$subscriptions.push(
        this.anagService.checkPartyCompleted(
          Number(subject.objectId),
          Number(subject.idLatestPhotos),
          role.role,
          Number(this.anagService.managementNode)
        ).subscribe(res => {
          if (res.result === PARTY_COMPLETE_KO) {
            const roleDescr = this.translate.getImmediate('lpc_new_beneficiary');
            this.partyCompleteError.push({
              subjIdPlusrole: `${subject.objectId}-${role.role}`,
              messages: res.outcome.map(m => `${roleDescr} ${subject.nominative}: ${m}`)
            });
          } else {
            this.removeMessagesByRole(subject.objectId, role.role);
          }
          this.pushAndEmitNewBenef(role, subject);
          (this.formGroup.get('array') as UntypedFormArray).updateValueAndValidity();
        })
      );
    } else {
      this.addedSubject.emit(null);
    }
  }

  private removeMessagesByRole(subjId: string, role: RoleType) {
    this.partyCompleteError = this.partyCompleteError.filter(m => m.subjIdPlusrole !== `${subjId}-${role}`);
  }

  private pushAndEmitNewBenef(role: Role, subject: AnagSubject) {
    (this.formGroup.get('array') as UntypedFormArray).push(
      new UntypedFormControl({
        subject: {
          value: role,
          type: this.beneficiaryType,
          code: '_ALTRO',
          idAssicurato: this.idAssicurato,
          irrevocable: true,
          idBenefLock: subject.idLatestPhotos
        },
        paymentMethod: null,
        questionnaireData: null,
        editable: true
      })
    );
    this.addedSubject.emit(role);
    this.triggerQuestPreval.emit({
      subject,
      roleCodeToAdd: this.roleCodeToAdd
    });
  }

  trackFn(index: any, item: ClaimBeneficiary) {
    if (item && item.subject && item.subject.value) {
      if ((item.subject.value as Role).id) {
        return (item.subject.value as Role).id;
      } else {
        return index;
      }
    }
    return undefined;
  }

  onDelete(beneficiary: ClaimBeneficiary) {
    this.removeMessagesByRole((beneficiary.subject.value as Role).id, (beneficiary.subject.value as Role).role);
    (this.formGroup.get('array') as UntypedFormArray).removeAt(
      this.formGroup.get('array').value.findIndex(ben => PlcObjectUtils.equal(ben, beneficiary))
    );
    this.delete.emit({linkedRole: beneficiary});
  }

  onDeleteSubRole(beneficiary: {benef?: string, linkedRole: ClaimBeneficiary}) {
    this.delete.emit(beneficiary);
  }

  /* checkAllQuestionnaires(): boolean {
    // QueryList do not have every function...
    return !this.beneficiary.some(x =>
      !x.isQuestionnaireValid()
    );
  } */

  /* persistBeneficiaries(): Observable<any> {
    const $allPersist = [];
    this.beneficiary.forEach(x => {
      // controllo se ho i questionari in quel beneficiario
      if (!!x.avcQuestionnaire && x.avcQuestionnaire.getQuest().length > 0) {
        $allPersist.push(x.persist());
      }
    });

    if ($allPersist.length > 0) {
      return forkJoin(...$allPersist)
        .pipe(
          switchMap((savedQuest: (Map<string, Survey[]>[])) => {
            const fillForms = [];
            if (savedQuest && savedQuest.length > 0) {
              savedQuest.forEach(elem => {
                for (const key of elem.keys()) {
                  const beneficiaryComponent: LpcClaimBeneficiaryComponent = this.beneficiary.find(x => x.questionnaireKey === key);
                  fillForms.push(beneficiaryComponent.fillForm(elem.get(key)));
                }
              });
              return combineLatest(fillForms);
            } else {
              return of(null);
            }
          }),
          switchMap(res => {
            // WA: added the onChange of the current component with the formGroup of the child component
            // to get correctly the values updated after the persist of the questionnaries.
            // FIXME: with a correct implementation of the ControlValueAccessor if there's a solution
            const childBenef: ClaimBeneficiary[] = this.beneficiary
              .filter(val => !!val.formGroup)
              .map(ben => ben.formGroup.getRawValue());
            this.onChange(childBenef);
            return of(res);
          }));
    }

    return of(null);
  } */

 onTriggerQuestPreval($event: any) {
    this.triggerQuestPreval.emit($event);
  }

  public loaderQuestManager(event: string) {
    this.loaderQuest.emit(event);
  }

  changeDistribution(toggle: DistributionToggle) {
    this.distributionConfig.distributionType = toggle;
    this.beneficiary.filter(benef => !!benef.mainBenef).forEach(benef => {
      benef.mainBenef.distributionConfig = this.distributionConfig;
    });
  }
}
