import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RgiCountryLayerModule } from '@rgi/rgi-country-layer';
import { ReactiveFormsModule } from '@angular/forms';
import { RgiRxI18nModule } from '@rgi/rx/i18n';
import { PerformanceDataComponent } from './components/performance-data/performance-data.component';
import { AnnuityTableComponent } from './components/annuity-table/annuity-table.component';
import { FormatDisplayValueDirective } from '../../utils/directives/formattedValue.directive';
import { RgiCountryLayerNumberFormatModule } from '@rgi/country-layer';
import { ReversionarySubjectComponent } from './components/reversionary-subject/reversionary-subject.component';
import { LpcRequiredModule } from '../../utils/lpc-required/lpc-required.module';
import { LpcBeneficiaryModule } from '../../modules/lpc-beneficiary/lpc-beneficiary.module';
import { LpcSubjectModule } from '../../modules/lpc-subject/lpc-subject.module';

@NgModule({
    declarations: [
        PerformanceDataComponent,
        AnnuityTableComponent,
        ReversionarySubjectComponent,
        FormatDisplayValueDirective,
        
    ], 
    imports: [
        CommonModule,
        RgiCountryLayerModule,
        ReactiveFormsModule,
        RgiRxI18nModule,
        RgiCountryLayerNumberFormatModule,
        LpcRequiredModule,
        LpcBeneficiaryModule,
        LpcSubjectModule
    ],
    exports: [
        PerformanceDataComponent,
        AnnuityTableComponent,
        ReversionarySubjectComponent,
        FormatDisplayValueDirective
    ]   
})
export class PensionBuybackModule { }
