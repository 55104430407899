<div *ngIf="active" [formGroup]="formGroup">
  <lic-karma-fund-elements
      [totalAmount]="totalAmount"
      [definition]="definition"
      formControlName="profiles"
      [sliderProperty]="sliderProperty">
  </lic-karma-fund-elements>
</div>
<div *ngIf="!active">
  <span translate>lic_selected_profiles_</span><span>:</span>
  <ul>
    <li *ngFor="let profile of selectedProfiles" id="li-profile-recap">
      <span>{{ profile.name }}</span>&nbsp;
      <span>({{ profile.amount  | rgiCtryLNumFormatter : locale : optionCurrency }})</span>&nbsp;
      <span>({{ profile.percent | rgiCtryLNumFormatter : locale : optionPercents }})</span>
    </li>
  </ul>
</div>
