export class LicObjectUtils {

  public static isObject(item: any): boolean {
    return item && typeof item === 'object' && !Array.isArray(item);
  }

  public static merge(target: any, ...sources: any): any {
    if (!sources.length) {
      return target;
    }
    const source = sources.shift();

    if (LicObjectUtils.isObject(target) && LicObjectUtils.isObject(source)) {
      for (const key in source) {
        if (LicObjectUtils.isObject(source[key])) {
          if (!target[key]) {
            Object.assign(target, { [key]: {} });
          }
          LicObjectUtils.merge(target[key], source[key]);
        } else {
          Object.assign(target, { [key]: source[key] });
        }
      }
    }

    return LicObjectUtils.merge(target, ...sources);
  }

  public static equal(obj1: any, obj2: any): boolean {
    if (obj1 === obj2) {
      return true;
    }

    if (obj1 !== Object(obj1) && obj2 !== Object(obj2)) {
      return obj1 === obj2;
    }

    if ((obj1 !== Object(obj1)) !== (obj2 !== Object(obj2))) {
      return false;
    }

    if (Object.keys(obj1).length !== Object.keys(obj2).length) {
      return false;
    }

    for (const key of Object.keys(obj1)) {
      if (!Object.keys(obj2).includes(key)) {
        return false;
      }
      if (!LicObjectUtils.equal(obj1[key], obj2[key])) {
        return false;
      }
    }

    return true;
  }

  public static roundToDecimal(num: number, decimal: number = 0): number {
    const dec: number = Math.pow(10, decimal);
    return Math.round(num * dec) / dec;
  }

  public static asValidArray<T>(array: T[]): T[] {
    return array ? array : [];
  }

  // ex: decimal 2 => "10,46%"
  public static formatPercentageString(num: number): string {
    const numVector = num.toString().split('.');

    let suffix = ',00%';
    if (!!numVector[1]) {
      if (numVector[1].length === 1) {
        suffix = ',' + numVector[1] + '0%';
      } else {
        suffix = ',' + numVector[1] + '%';
      }
    }

    return numVector[0] + suffix;
  }

  public static cloneObject(obj: any): any {
    return JSON.parse(JSON.stringify(obj));
  }

  public static arrSum(arr) {
    let sum = 0;
    // iterate array using forEach, better to use for loop since it have higher performance
    arr.forEach((v) =>  {
      // checking array element is an array
      if (LicObjectUtils.isObject(v)) {
        // if array then getting sum it's element (recursion)
        sum += LicObjectUtils.arrSum(v);
      } else {
        // else adding the value with sum
        sum += v;
      }
    });
    // returning the result
    return sum;
  }

  public static  getPropertyBoleanValue(properties, propCode): boolean {
    const prop = properties.find(p => p.code === propCode);
    return !!prop && prop.value === 'true' ? true : false;
  }


  public static getFloatConversion(value) {
    const result = LicObjectUtils.getNormalizedForNumericConversion(value);
    if (!result) {
      return null;
    }
    return parseFloat(result);
  }
  public static getNormalizedForNumericConversion(value) {

    if (!!value) {
      value = String(value).replace(',', '.').trim();
    }
    return value;

  }

  public static isFactorTypeEqual(type1: any, type2: any): boolean  {

    if (type1 === null || type1 === undefined || type2 === null || type2 === undefined) {
      return false;
    }

    return type1.toString().trim() === type2.toString().trim();

  }

  /**
   * Converts an object to its string representation.
   * If the input is already a string, it is returned as is.
   * Otherwise, the object is converted to a JSON string, with special characters escaped.
   *
   * @param obj - The object to stringify.
   * @returns The string representation of the object.
   */
  public static strigifyObject(obj: any): string {
    if (typeof obj === 'string') {
      return obj;
    }
    return JSON.stringify(obj).replace(String.fromCharCode(92), String.fromCharCode(92, 92));
  }

  /**
   * Parses a string into an object.
   * If the input is not a string, it returns the input as is.
   * @param str - The string to parse.
   * @returns The parsed object.
   */
  public static parsifyString(str): any {
    if (typeof str !== 'string') {
      return str;
    }
    return JSON.parse(str.replace(String.fromCharCode(92, 92), String.fromCharCode(92)));
  }


}
