<lpc-stepper [formGroup]="formGroup" (stepSlide)="onSlide($event)" [isPublished]="publishMessage !== null">

    <lpc-step
        id="dates"
        label="lpc_date_variation_step"
        [errors]="errors"
        fieldId="dates"
        (next)="onNext($event)"
    >
        <lpc-dates-step
            [inputFields]="dateDefinitions"
            [formControlName]="'dates'"
            (dateChange)="onDateChange($event)"
            (reloadRequest)="updateData()"
            [hide]="activeStep<1">
        </lpc-dates-step>
    </lpc-step>

    <lpc-step
        id="factors"
        label="lpc_variation_step_label"
        [errors]="errors"
        fieldId="factors"
        (next)="onNext($event)"
    >
    <div formGroupName="factors" class="row">
      <lpc-factor-adapter
        #factorAdapter
        (changeValEmitter)="updateProductFactors($event)"
        [form]="formGroup.controls['factors']"
        [factors]="listProductFactor">
      </lpc-factor-adapter>
    </div>
    <!-- Reversionary subject -->
    <div *ngIf="isActiveStep('factors')" class="reversionary">
        <div class="col-lg-6 reversionary-box" *ngIf="!!reversionary">
            <label class="label-core" translate>lpc_reversionary_subject</label>
            <lpc-required required="true | translate"></lpc-required>
            <div *ngIf="!!formGroup.get('reversionary') && !!formGroup.get('reversionary').value; else empty">
                <lpc-beneficiary-control
                    required
                    formControlName="reversionary"
                    (delete)="deleteRevers($event)"
                    [items]="reversionary[1]?.values">
                </lpc-beneficiary-control>
            </div>
            <ng-template #empty>
                <input
                    required
                    (click)="openAnagSubjectModal()"
                    [value]="'lpc_Seleziona'"
                    class="col-lg-12 drop-container dashedSelect"
                    data-qa="dashed-select-thirdParty" readonly
                >
            </ng-template>
          </div>
      </div>

      <div class="m-0" *ngIf="!isActiveStep('factors') && !!formGroup.get('reversionary')?.value">
          <span translate>lpc_reversionary_subject</span>:<span> {{formGroup.get('reversionary')?.value.value.name}} </span>
          <br>
          <span translate>lpc_percentage_reversibility</span>:<span> {{formGroup.get('reversionary')?.value.value.percentage}} % </span>
      </div>
  </lpc-step>

  <!-- <lpc-step
            *ngIf="!!beneficiariesDefinitions.length"
            id="beneficiaries"
            label="Beneficiaries"
            [errors]="errors"
            fieldId="beneficiaries"
            (next)="onNext($event)"
            [feErrors]="feErrors"
            id="beneficiaries"
            (confirmAccept)="onNext($event)"
            [isConfirmAndAccept]="isConfirmAndAccept"
        >
            <lpc-claim-beneficiaries
                #beneficiaries
                formControlName="beneficiaries"
                [paymentTypes]="paymentTypes"
                [idAssicurato]="idAssicurato"
                [summary]="!isActiveStep('beneficiaries')"
                [questionnairesCode]="getUnfilteredQuestMap('IST')"
                (addedSubject)="onAddedSubject($event)"
                (triggerQuestPreval)="onTriggerQuestPreval($event)"
                (loaderQuest)="loaderQuestManager($event)"
                [questFactorsArray]="questFactorsArray"
                [disabledQuestionArray]="disabledQuestionArray"
            >
            </lpc-claim-beneficiaries>
        </lpc-step> -->


    <lpc-step
        *ngIf="isAuth || !!errors && !!errors.length"
        id="warnings" label="lpc_warnings"
        (next)="publish()"
        [nextLabel]="isAuth ? 'AUTHORIZATION REQUEST' : 'Confirm'"
    >
        <lpc-warning-messages
            *ngIf="!!errors"
            [errors]="errors">
        </lpc-warning-messages>
    </lpc-step>

    <lpc-step
        label="lpc_confirm_variation_step"
        [errors]="errors"
        fieldId="documents"
        id="documents"
        [nextVisible]=false>

        <lpc-document-wrapper
            *ngIf="!!publishMessage"
            [contractId]="getContractId"
            [operationCode]="getOperationCode"
            [publishMessage]="publishMessage"
            [isAuth]="isAuth"
            [authorizationId]="authorizationId"
            [printMode]="printMode"
            [config]="documentsConfig"
            (documentEvent)="handleDocumentEvent($event)">
        </lpc-document-wrapper>

        <lpc-navigation-buttons
            id="last-step-buttons"
            (back)="closeCardWithoutModal()"
            [backLabel]="'lpc_close_button'"
            [backVisible]="true"
            [nextVisible]="false">
        </lpc-navigation-buttons>

    </lpc-step>

</lpc-stepper>

<div class="btn-group btn-group-justified external" *ngIf="!isActiveStep('documents')">
    <lpc-navigation-buttons
        (back)="closeCard()"
        [nextVisible]="false"
        [qa]="'annulla'">
    </lpc-navigation-buttons>
</div>
