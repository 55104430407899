import { ClaimsTranslationsKeys } from './claims-translations-keys';
/**
 * MORE READABILITY ADVICE
 * Please maintain an alphabetical order in each section
 */
// tslint:disable:max-line-length
export const CLAIMS_IT: ClaimsTranslationsKeys = {
    _CLAIMS_TITLE_CARD_CAI_: 'Nuovo Sinistro',
    _CLAIMS_TITLE_DIALOGUE_DASHBOARD_: 'Gestore dialoghi',
    _CLAIMS_TITLE_TIREA_DASHBOARD_: 'Scheda di Pagamento Tirea',
    _CLAIMS_TITLE_DIALOGUE_HANDLER_LIST: 'Lista Dialoghi',
    _CLAIMS_TITLE_PAYMENT_TABLE_TYREA: 'Tabella dei pagamenti Tirea',
    _INVOICE: 'Fattura',
    _NEW_PAYMENT: 'Nuova Quietanza',
    _COMPENSATION_LIST: 'Lista rivalse',
    _CLAIMS_CONNECTOR: 'Connettore Sinistri',
    _CHAT: 'CHAT',
    _EDIT_CLAIMS: 'Modifica sinistro',
    _ACTIVITY: 'Attività',
    _CLAIMS_PILLS: 'Claims Pills',
    _AUTOSOFT_DASHBOARD: 'Autosoft Dashboard',
    _DOSSIER_MANAGEMENT: 'Documenti Sinistro',
    _CLAIM_DETAIL: 'Dettagli sinistro',
    _NEW_FNOL: 'Nuova FNOL',
    _RCA_QUESTIONNAIRE: 'Questionario RCA',
    _CLAIM_SUBJECTS: 'Soggetti Sinistro',
    _CLAIMS_FROM_ELASTIC: 'Claims from elastic',
    _EXPERTS: 'Fiduciari',
    _LETTER_TEMPLATES: 'Modelli lettera',
    _LIMITS_MANAGEMENT: 'Gestione Massimali',
    _TIMELINE: 'Sequenza temporale',
    _INVOICES_LIST: 'Lista fatture',
    _UPDATE_RESERVE: 'Modifica riserva',
    _CLAIMS_: {
        _TAX_REGIME: 'Regime Tasse',
        _IVA_REGIME: 'Regime IVA',
        _AGGREGATE_TAX_REGIME: 'Regime tasse aggregato',
        _AGGREGATE_IVA_REGIME: 'Regime IVA aggregato',
        _FISCAL_DATA: 'Dati Fiscali',
        _ACCIDENT_CIRCUMSTANCE: `Circostanze dell'incidente`,
        _ACCIDENTS: 'Circostanze incidente',
        _ACCOUNTING_DATE: 'Data contabile',
        _ACT_ACTIVE: 'Attive',
        _ACT_CLOSED: 'Chiuse',
        _ACT_EVASION_DATE: 'Data evasione',
        _ACTION: 'Azione',
        _ACTIVE_CHATS: 'Conversazioni attive',
        _ACTIVITIES: 'Attività',
        _ACTUAL_DATE_OF_OCCURRENCE: 'Data effettivo accadimento',
        _ACTUAL_TIME_OCCURRENCE: 'Ora effettivo accadimento',
        _ADD_ASSET: 'Aggiungi bene',
        _ADDITIONAL_DATA: 'Dati aggiuntivi',
        _ADDRESS: 'Indirizzo',
        _ADJUSTER: 'Liquidatore',
        _ALLEGATION_DESCRIPTION: 'Descrizione asserzione',
        _ADMIN_LEVEL_1: 'Livello amministrativo 1',
        _ADMIN_LEVEL_2: 'Livello amministrativo 2',
        _ADMIN_LEVEL_3: 'Livello amministrativo 3',
        _ADMINISTRATIVE_REGULARITY: 'Regolarità Amministrativa',
        _AGENCY: 'Agenzia',
        _AGENCY_UNIQUE_KEY: 'Identificativo Agenzia',
        _AMOUNT_CLEARED: 'Importo Liquidato',
        _AMOUNT_HOLD_TAX: 'Importo ritenuta',
        _AMOUNT_TO_BE_PAYD: 'Importo da pagare',
        _ASSET: 'Bene',
        _ANIA_CLASSIFICATION: 'Classificazione Ania',
        _ANTI_FRAUD_CATHEGORY: 'Categoria antifrode',
        _APPLY_ANIA_LIABILITY_FORCED: 'Applica forzatura responsabilità dichiarata ad ANIA',
        _APPLY_TECH_LIABILITY_FORCED: 'Applica forzatura responsabilità tecnica',
        _ARCHIVE_SUBJECTS: 'Archivio soggetti',
        _ASSETS_DATA: 'Dati bene colpito',
        _ASSET_DESCRIPTION: 'Descrizione del bene',
        _ASSIGNED_TO_A_GROUP: 'Assegnato a Gruppo',
        _ASSIGNMENT_SELECTION: 'Selezione incarico',
        _BAREMS: 'Baremes',
        _BLACK_BOX_COHERENCE: 'Coerenza scatola nera',
        _BODY: 'Corpo lettera',
        _BODYWORK: 'Carrozzeria',
        _BONDED: 'Vincolo',
        _BRAND: 'Marca',
        _CALCULATED_LIABILITY: 'Responsabilità calcolata',
        _CANCELED_BY: 'Annullata da',
        _CANCEL_REQUEST_DATE: 'Data richiesta cancellazione',
        _CAP: 'CAP',
        _CANCELLATION_DATE: 'Data annullamento',
        _CASH_PREVIDENTIAL_AMOUNT: 'Importo cassa previdenziale',
        _CATASTROPHIC_EVENT: 'Evento catastrofico',
        _CDL: 'CDL',
        _CERTIFIED_OCCURRENCE_DATE: 'Data accadimento certificata',
        _CERTIFIED_EVENT_DATE: 'Data evento certificata',
        _CHASSIS_NUMBER: 'Numero telaio',
        _CHAT_HISTORY: 'Storico conversazioni',
        _CHATS_WITH_ME: 'Conversazioni con me',
        _CITY: 'Città',
        _CIVIC_NUMBER: 'Numero civico',
        _CLAIM_BRANCH: 'Ramo sinistro',
        _CLAIM_NUMBER: 'Numero Sinistro',
        _CLAIM_NUMBER_RECEIVED: 'Numero sinistro ricevuto',
        _CLAIM_NUMBER_STATUS: 'Numero sinistro e stato',
        _CLAIM_POLICY: 'Numero Polizza',
        _CLAIM_SELECTION: 'Selezione sinistro',
        _CLAIM_TYPE: 'Tipo sinistro',
        _CLAIMS_TECHNICAL_STATUS: 'Stato tecnico sinistro',
        _CDL_RECEIPT_DATE: 'Data ricezione CDL',
        _CLAIMS: 'SINISTRI',
        _CLASS: 'Classe',
        _CLAUSES: 'Clausole',
        _CLICK_HERE: 'clicca qui',
        _CLOSE: 'Chiudi',
        _COHERENT: 'Coerente',
        _COINSURANCE_SHARE: 'Perc. ns quota',
        _COINSURANCE_TYPE: 'Tipo coassicurazione',
        _COLLECTION_DATE: 'Data incasso',
        _COMMENTS: 'Commenti',
        _COMPANY: 'Compagnia',
        _COMPANY_DESCRIPTION:'Descrizione Compagnia',
        _COMPLAINT_DATE: 'Data denuncia',
        _COMPLAINT_NUMBER_STATUS: 'Numero denuncia e stato',
        _CONFIRM: 'Conferma',
        _CONTRACTOR: 'Contraente',
        _CONTRACT_STATUS: 'Stato contratto',
        _COMPLAINT_DATA: 'Dati denuncia',
        _COMPULSORY_QUEST: 'Domanda obbligatoria: ',
        _CORPORATE_LITIGATION: 'Contenzioso Societario',
        _COUNT: 'Contatore',
        _COUNTERPART_LIABILITY_PERC: 'Percentuale responsabilità controparte',
        _COUNTERPART: 'Controparte',
        _COUNTERPARTS: 'Controparti',
        _COUNTERPARTY_PLATE_NUMBER: 'Targa di controparte',
        _COVERAGE_EXPIRATION_DATE: 'Data scadenza copertura',
        _COVERTURE_TYPE: 'Tipo copertura',
        _CREATION: 'Creazione',
        _DAMAGE_CATEGORY: 'Evento',
        _DAMAGE_POSITION: 'Posizione Danno',
        _DAMAGED: 'Danneggiati',
        _DAMAGE_TO_THINGS: 'Danni alle cose',
        _DAMAGED__ASSET: 'Danneggiato / Bene',
        _DAMAGED__INVOLVED_ITEM_DATA: 'Dati danneggiato / Bene colpito',
        _DAMAGED_ASSETS: 'Beni colpiti',
        _ADD_OTHER_ASSET: 'Aggiungi nuovo bene',
        _DEADLINE_DATA: 'Dati Scadenza',
        _DAMAGED_PARTS: 'Parti danneggiate',
        _DATE_RECEIVED_INTERMEDIARY: 'Data ricezione intermediario',
        _DECLARED_LIABILITY: 'Responsabilità dichiarata',
        _DELETED: 'Eliminato',
        _DENOMINATION: 'Denominazione',
        _DEROGATION_LEVEL: 'Livello deroga',
        _DESCRIPTION_AUTHORITY: 'Descrizione autorità',
        _DESCRIPTION_EVENT: 'Descrizione evento',
        _DESCRIPTION: 'Descrizione',
        _DESELECT_ALL: 'Deseleziona tutti',
        _DETAILS: 'Dettagli',
        _LAST_EXECUTION_DETAILS: 'Dettagli ultima esecuzione',
        _SAVE_NEW_REQUEST: 'Salva nuova richiesta',
        _DEPARTMENT: 'Reparto',
        _DOCUMENT_MANAGEMENT: 'Gestione documenti',
        _DOCUMENTS_UPLOAD: 'Upload documenti',
        _DOCUMENTS: 'Documenti',
        _DRIVER: 'Conducente',
        _DRIVER_MANDATORY: 'Conducente obbligatorio',
        _DUTIES_AMOUNT: 'Importo bolli',
        _EDIT_ACCIDENTS: 'Modifica circostanze incidente',
        _EDIT_LIMIT: 'Modifica Massimale',
        _EFFECTIVE_COMPLAINT_DATE: 'Data effettiva insorgenza',
        _EFFECT_DATE: 'Data effetto',
        _EMAIL: 'E-mail',
        _ERROR_CODE: 'Codice errore',
        _EVENT_DENIAL: 'Negazione evento',
        _EXCLUDE_FROM_CALCULATION: 'Escludere dal calcolo',
        _EXCLUDED_FROM_CALCULATION: 'Escluso dal calcolo',
        _EXPERT_DATA: 'Dati professionista',
        _EXPERT_TYPE: 'Tipo fiduciario',
        _EXPERT: 'Professionista',
        _EXPIRATION_DATE: 'Data scadenza',
        _EXTENSION: 'Estensione',
        _FACTORS: 'Fattori',
        _FAX: 'Fax',
        _FEES_AMOUNT: 'Importo spese',
        _FIRST_REGISTRATION_DATE: 'Data prima registrazione',
        _FISCAL_CODE__VAT_CODE: 'Cod. Fiscale / P.IVA',
        _FNOL_DATA_COLLECTION: 'Raccolta dati FNOL',
        _FORCE_REASON: 'Motivo forzatura',
        _FORCED_LIABILITY: 'Responsabilità forzata',
        _FRACTIONING: 'Frazionamento',
        _GENDER: 'Genere',
        _GENERAL_DATA: 'Dati generali',
        _GIVEN_FIRST_REGISTRATION: 'Data prima immatricolazione',
        _GREEN_CARD_NUMBER: 'Numero Green Card',
        _GROSS: 'Lordo',
        _GROSS_EXCESS: 'Al lordo di franchigia',
        _INCONSISTENT_ROW_AT_LINE_: 'Riga inconsistente alla linea ',
        _METAINFO: 'Metainfo',
        _INDEMNITY_AMOUNT: 'Importo indennizzo',
        _INDEXING: 'Indicizzazione',
        _INDIRECT_COINSURANCE: 'Coassicurazione indiretta',
        _INITIAL_EFFECTIVE_DATE: 'Data effetto originale',
        _INITIAL_LIMIT_AMOUNT: 'Importo Massimale Iniziale',
        _INITIAL_LIMIT_VALUE: 'Valore Massimale Iniziale',
        _INJURY: 'Lesioni',
        _INJURY_CODE: 'Codice lesione',
        _INCIDENT_DATA: 'Data incidente',
        _INJURY_PHYSICAL: 'Lesioni fisiche.',
        _INPS_CONTRIB_AMOUNT: 'Importo cassa inps',
        _INSERT: 'Inserire',
        _INS_DATE: 'Data Inserimento',
        _INSERT_NEW_REQUEST: 'Inserire nuova richiesta',
        _INSERTION_DATE: 'Data inserimento',
        _INSPECTOR: 'Ispettore',
        _INSTANCE: 'Istanza',
        _INSURANCE_COMPANY: ' Compagnia Assicurativa',
        _INSURED_ACTIVITY: 'Attività assicurata',
        _INSURED_LIABILITY_PERC: 'Percentuale responsabilità assicurato',
        _INSURED_VEICHLE_POSITION: 'Caso nostro veicolo',
        _INSURED: 'Assicurato',
        _INSUREDS: 'Assicurati',
        _INSURED_REPORT_DATE: 'Data denuncia assicurato',
        _INVALID_FIELDS: 'Campi non validi',
        _INVALID_POLICY: 'Polizza non valida',
        _INVOICE_AMOUNT: 'Importo fattura',
        _INVOICE_DATA: 'Dati fattura',
        _INVOICE_DETAILS: 'Dettagli fattura',
        _INVOICE_NUMBER: 'Numero fattura',
        _INVOICE_TYPE: 'Tipo fattura',
        _INVOICE: 'Fattura',
        _INVOICES: 'Fatture',
        _ISSUE_DATE: 'Data emissione',
        _ISSUE_FRACTIONING: 'Frazionamento emissione',
        _LAST_AUDIT_DATE: 'Data ultimo Audit',
        _LETTER_DEROGATION_LEVEL: 'Livello deroga lettera',
        _LETTER_TEMPLATE_SAVED: 'Template lettera salvato',
        _LEVEL_CODE: 'Codice Livello',
        _LEVEL: 'Livello',
        _LIABILITY_TYPE: 'Tipo responsabilità',
        _LIMITS_POLICY_N: 'Massimali Polizza n.',
        _LIQUIDABLE: 'Liquidabile',
        _LOCATIONS: 'Posizioni',
        _LPS: 'LPS',
        _MAIN_SUBSTATUS: 'Sottostato principale',
        _MANAGE_PHASE: 'Fase gestione',
        _MANAGEMENT_NOTE: 'Note Direzione ',
        _MANAGEMENT_NODE: 'Nodo di gestione',
        _MANDATORY_DOCUMENTS: 'Documenti Obbligatori',
        _MANUAL_EXCESS: 'Franchigia manuale',
        _MANUFACTURER: 'Marca',
        _MATERIAL_DAMAGE: 'Cose generico',
        _MERGE_ABSORPTION_DATE: 'Data di assorbimento',
        _MESSAGE: 'Messaggio',
        _MESSAGE_RESPONSE: 'Messaggio di risposta',
        _MESSAGE_REQUEST: 'Messaggio di richesta',
        _MESSAGES: 'Messaggi',
        _MINOR: 'Minore',
        _MINORGUARDIAN: 'TUTORE',
        _MINISTERIAL_BRANCH: 'Branch Ministeriale',
        _MIGRATED: 'Migrato',
        _MOBILE_PHONE: 'Cellulare',
        _MODEL: 'Modello',
        _MODELS_LIST: 'Elenco modelli',
        _MODIFY_FNOL: 'Modifica FNOL',
        _MOTOR_CARD: 'Autovettura',
        _NAME: 'Nome',
        _NATION_ADDRESS: 'Nazione indirizzo',
        _NATION: 'Nazione',
        _NATIONALITY: 'Nazionalità',
        _NET: 'Netto',
        _NETGROSS_EXCESS: 'Al netto/lordo di franchigia',
        _NEW_COMPANY: 'Nuova azienda',
        _NEW_LETTER_MODEL: 'Nuovo modello lettera',
        _NEW_LIMIT: 'Nuovo Massimale',
        _NEW_UPLOAD: 'Nuovo upload',
        _NO: 'No',
        _NORMALIZE: 'Normalizza',
        _NOT_COHERENT: 'Non coerente',
        _NOTIFICATION_DATE: 'Data notifica',
        _NOT_TAXABLE_AMOUNT: 'Importo non imponibile',
        _NOTE: 'Note',
        _NUMBER_CLAIM: 'Numero Sinistro',
        _OBJECT: 'Oggetto',
        _OBSERVATIONS: 'Osservazioni',
        _OCCURRENCE_DATA: 'Dati accadimento',
        _OCCURRENCE_DATE: 'Data accadimento',
        _OCCURRENCE_TIME: 'Ora accadimento',
        _OCCURRENCE_DESCRIPTION: 'Descrizione Accadimento',
        _OPENING_DATE_FROM: 'Data apertura da',
        _OPENING_DATE_TO: 'Data apertura a',
        _OTHER_DOCUMENTS: 'Altri documenti',
        _OWNER: 'Proprietario',
        _OTHER: 'Altro',
        _PARTY_INVOLVED: 'Attore Coinvolto',
        _PARKED_VEHICLE: 'Veicolo in sosta',
        _PASSENGER: 'Trasportato',
        _PAYMENT_AMOUNT: 'Importo pagamento',
        _PEC: 'Pec',
        _PERC_PREV_FUND_1: 'Aliquota cassa previdenziale',
        _PERC_PREV_FUND_2: 'Aliquota cassa inps',
        _PERC_WITH_HOLD_TAX: 'Aliquota ritenuta',
        _PERCENTAGES: 'Percentuali',
        _PERSON: 'Persona',
        _PHONE: 'Telefono',
        _PHYSICAL_INJURY: 'Danni fisici',
        _PLATE_NUMBER: 'Targa',
        _PLATE_TYPE: 'Tipo targa',
        _POLICY_DETAIL: 'Dettaglio Polizza',
        _POLICY_HOLDER__INSURED: 'Contraente / Assicurato',
        _POLICY_HOLDER: 'Contraente',
        _POLICY_LIST: 'Elenco Polizze',
        _POLICY_NUMBER: 'Nr polizza',
        _POLICY_NR: 'Nr. polizza',
        _POLICY_TYPE: 'Tipo polizza',
        _POLICY: 'Polizza',
        _PRODUCT: 'Prodotto',
        _PREMIUM_ADJUSTMENT: 'Regolazione premio',
        _PRINCIPAL: 'Principale',
        _PROPERTY_ADDRESS: 'Indirizzo del bene',
        _PROCEDURE_NUMBER: 'Numero procedimento',
        _PROVINCE: 'Provincia',
        _REAL_NAME: 'Nome reale',
        _RECEIVED_DATE_FROM: 'Data ricezione da',
        _RECEIVED_DATE_TO: 'Data ricezione a',
        _RECEIVED_DATE: 'Data ricezione',
        _RECIPIENT: 'Destinatario',
        _REGIME: 'Regime',
        _REGISTRY: 'Anagrafica',
        _REINSURANCE: 'Riassicurazione',
        _REJECT_WORKED: 'Scarto lavorato',
        _REPORT: 'Report',
        _RESCINDABILITY_DATE: 'Data rescindibilità',
        _RESERVE: 'Riserva',
        _RESIDUE_LIMIT_AMOUNT: 'Importo Massimale Residuo',
        _RESIDUE_LIMIT_VALUE: 'Valore Massimale Residuo',
        _RISK_DESCRIPTION: 'Descrizione del rischio',
        _ROUNDING_AMOUNT: 'Arrotondamento',
        _SAP_CODE: 'Codice Sap',
        _SAP_RECORDING_DATE: 'Data registrazione sap',
        _SDI_CODE: 'Codice Sdi',
        _SEARCH_DOCTOR: 'Ricerca medico',
        _SECONDARY: 'Secondario',
        _SELECT_ALL: 'Seleziona tutti',
        _SELECT_ASSIGNMENT: 'Selezionare incarico',
        _SELECT_AT_LEAST_ONE_USER: 'Selezionare almeno un utente',
        _SELECT_BY_DEFAULT: 'Scelto dal sistema',
        _SELECT_CLAIM: 'Selezionare sinistro',
        _SELECT_POLICY: 'Selezione polizza',
        _SELECT_RECIPIENT: 'Seleziona destinatario',
        _SELECT_THE_CORRESPONDING_ASSIGNMENT_FROM_THE_LIST: 'Selezione incarico corrispondente dalla lista',
        _SELECT_THE_CORRESPONDING_CLAIM_FROM_THE_LIST: 'Selezione il sinistro corrispondente dalla lista',
        _SELECT_THE_CORRESPONDING_TYPE_OF_LOSS_FROM_THE_LIST: 'Selezionare la corrispondente causale danno dalla lista',
        _SELECT_TYPE_OF_LOSS: 'Selezione causale',
        _SELECT: 'Seleziona',
        _SELECTECT_SUBJECT: 'Soggetto selezionato',
        _SENDING_DATE: 'Data di invio',
        _SIC_OUTCOME: ' Risultato SIC',
        _SIGN_TYPE: 'Tipo firma',
        _SIMILAR_COMPLAINTS: 'Denunce simili',
        _SIMILAR_SUBJECT: 'Soggetto simile',
        _SPECIAL_ABBREVIATION: 'Abbreviazione speciale',
        _STAMP_DUTY_AMOUNT: 'Importo bollo',
        _SUBJECT_NAME: 'Nome Soggetto',
        _SUBJECT_SURNAME_OR_DENOMINATION: 'Cognome o denominazione soggetto',
        _SUBJECTS: 'Soggetti',
        _SUBTYPOLOGY_CODE: 'Codice Sottotipologia',
        _SUBTYPOLOGY: 'Sottotipologia',
        _SUIT_NUMBER: 'Numero procedimento',
        _SURNAME: 'Cognome',
        _SX_SUBJECT: 'Soggetto',
        _TACIT_RENEWAL: 'Tacito rinnovo',
        _TAX_ID: 'Riferimento fiscale',
        _TECHNICAL_COVERAGE: 'Copertura tecnica',
        _TAXABLE_AMOUNT: 'Importo imponibile',
        _TELEPHONE: 'Telefono',
        _THINGS: 'Cose',
        _THIRD_PART_VEICHLE_POSITION: 'Caso veicolo controparte',
        _THIRD_PARTY: 'Terzo danneggiato',
        _TO_BE_EVALUATED: 'Da rivalutare',
        _TO_BE_REEVALUATED: 'Da rivalutare',
        _TO_SELECT: 'Per selezionare',
        _TOPONYM: 'Toponimo',
        _TOT_AMOUNT_UNIT: 'Costo totale unitario',
        _TOTAL: 'Totale',
        _TOTAL_RESERVES_AMOUNT: 'Importo totale riserve',
        _TOTAL_DISTRUCTION: 'Distruzione totale',
        _TYPE_OF_AUTHORITY: 'Tipo eventuale autorità intervenuta',
        _TYPE_OF_LOSS_DESCRIPTION: 'Descrizione causale danno',
        _TYPE_OF_LOSS_SELECTION: 'Selezione causale danno',
        _TYPE_OF_DAMAGE: 'Tipo perdita',
        _TYPE_CIVIL__PENAL: 'Tipologia civile/penale',
        _UNIQUE: 'Unico',
        _UNITS: 'Unit',
        _UPDATE_REASON: 'Motivo Aggiornamento',
        _USE: 'Uso',
        _USER_ASSOCIATION: 'Associazione utenti',
        _USER_BY_WORKLOAD: 'Utente (ordinati per carico di lavoro)',
        _USER_DATA: 'Dati Utente',
        _USER_DEROGATION_LEVEL: 'Livello deroga utente',
        _VALID_POLICY: 'Polizza valida',
        _VALIDITY_END_DATE: 'Data fine validità',
        _VALIDITY_START_DATE: 'Data inizio validità',
        _VAT_AMOUNT: 'Importo iva',
        _VAT_RATE: 'Aliquota iva',
        _VEHICLE: 'Veicolo',
        _VISIBLE_DAMAGE_ON_VEHICLE: ' Danni visibili sul veicolo',
        _VEHICLE_DATA: 'Dati veicolo',
        _YES: 'Si',
        _ZIP_CODE: 'Cap',
        _BUTTONS_: {
            _ACTIONS: 'AZIONI',
            _BACK: 'INDIETRO',
            _CLOSE: 'CHIUDI',
            _NEXT: 'AVANTI',
            _NEW: 'NUOVO',
            _CANCEL: 'ANNULLA',
            _CONFIRM: 'CONFERMA',
            _EXIT: 'ESCI',
            _SAVE: 'SALVA',
            _SAVE_PES: 'SALVA DENUNCIA',
            _FIND: 'TROVA',
            _EMPTY: 'SVUOTA',
            _SHOW: 'MOSTRA',
            _HIDE: 'NASCONDI',
            _INSERT: 'INSERISCI',
            _NEW_DIALOGUE: 'NUOVO DIALOGO',
            _ASSOCIATE: 'ASSOCIA',
            _REQUEST: 'RICHIESTA',
            _RESET_ADVANCE: 'RESETTARE ANTICIPO',
            _CORRECT: 'CORREGGI',
            _ADD: 'AGGIUNGI',
            _SEARCH: 'CERCA',
            _IBD: 'IBD',
            _OK: 'OK',
            _ADD_NOTE: 'AGGIUNGI NOTA',
            _FAILED_TRANSMISSION: 'Trasmissione fallita',
            _EVENTS: 'Eventi',
            _SEND: 'Invia',
            _DOWNLOAD_DOCUMENTI_SELEZIONATI: 'DOWNLOAD DOCUMENTI SELEZIONATI',
            _DOWNLOAD_CLAIM_DOCUMENTS: 'DOWNLOAD DOCUMENTI SINISTRO',
            _DOCVIEWER: 'DOCVIEWER',
            _DOWNLOAD_POLICY_DOCUMENTS: 'DOWNLOAD DOCUMENTI POLIZZA',
            _EDIT: 'Modifica',
            _NEW_REQUEST: 'Nuova richiesta',
            _RERUN: 'Rerun',
            _RESERVATION: 'PRENOTAZIONE',
            _ONDEMAND: 'ONDEMAND',
            _NEW_MANUAL_DEADLINE: 'Nuovo Reminder Manuale',
            _UPLOAD: 'UPOLAD',
            _TRANSMISSION: 'TRASMISSIONE',
            _MODELS_LIST: 'Elenco modelli',
            _NEW_LETTER_MODEL: 'Nuovo modello lettera',
            _DELETE: 'ELIMINA',
            _ADD_ACTOR: 'AGGIUNGI ATTORE',
            _RETRIEVE_INFORMATION: 'RECUPERA INFORMAZIONI',
            _REASSOCIATE: 'Riassocia',
            _MODIFY: 'MODIFICA',
            _RESPONSE: 'RISPOSTA',
        },
        _DRIVER_LICENSE_TITLE: 'Dati patente',
        _RELEASED_BY: 'Rilasciato da',
        _RELEASED_DATE: 'Data rilascio',
        _CATEGORY: 'Categoria',
        _ADD_CATEGORY: 'Aggiungi categoria',
        _MESSAGE_: {
            _NO_RESULT: 'Nessun risultato',
            _OPENED_CLAIM_DO_ISSUE: 'Apertura a seguito di atto citazione',
            _NOT_IDENTIFIED_WITNESS: 'Testimone non identificato',
            _SELECT_ONLY_THE_BOXES_USEFUL_FOR_DESCRIPTION: 'Selezionare solo le caselle utili alla descrizione',
            _SELECT_AT_LEAST_ONE_CAUSAL: 'Selezionare almeno una causale prima di continuare.',
            _INVALID_DATE_INCIDENT: 'La data inserita non può essere differente dalla data accadimento',
            _INVALID_REPORTED_DATE_INCIDENT: 'La data inserita non può essere precedente alla data accadimento',
            _INVALID_RECEIVED_DATE_INCIDENT: 'La data inserita non può essere precedente alla data denuncia',
            _INVALID_CHC_DATE : 'La data inserita non può essere precedente alla data ricezione intermediario',
            _INVALID_FUTURE_DATE : 'Non è possibile selezionare date nel futuro',
            _CANNOT_OPEN_CLAIM_FOR_UNCOVERED_POLICY: 'È impossibile aprire un sinistro su una polizza scoperta',
            _FIELDS_MARKED_IN_RED_ARE_REQUIRED_OR_CONTAIN_INCORRECT_VALUES: 'Fields marked in red are required or contain incorrect values',
            _INCORRECT_OR_MISSING_DATA: 'Dati non corretti o mancanti',
            _THERE_ARE_TYPES_OF_LOSS_MANAGED_WITH_CLAIMS_MADE__POSTUMA: 'Esistono tipologie di sinistro gestito con sinistri avanzati/postumi:',
            _TECHNICAL_AND_ADMINISTRATIVE_VALIDATION_CONTROLS_WILL_BE_REDONE_IN_THE_TYPE_OF_LOSS_SECTION: ' nella sezione tipologia di sinistro verranno rifatti i controlli di validazione tecnico-amministrativa',
            _CACHE_ERROR: 'Errore cache',
            _ERROR_IN_INITIALIZATION_PROCEED_WITH_MANUAL_SELECTION: 'ERRORE nella prevalorizzazione. Procedere con la selezione manuale',
            _UPDATE_ERROR: 'Errore di Update',
            _SEARCH_EXPERTS_ERROR: 'Errore ricerca fiduciario',
            _SEARCH_REJECT_TYPES_ERROR: 'Errore nei tipi di rifiuto della ricerca',
            _AMOUNT_TO_BE_PAID_CANNOT_BE_LESS_THAN_THE_SUM_OF_THE_TAXABLE_AMOUNT_AND_THE_NONTAXABLE_AMOUNT: 'Importo da pagare non puo essere minore della somma di importo imponibile ed importo non imponibile',
            _AMOUNT_TO_BE_PAID_DIFFERENT_FROM_INVOICE_AMOUNT_MINUS_HOLD_TAX_AMOUNT: 'Importo da pagare diverso da importo fattura meno importo ritenuta',
            _AMOUNT_TO_BE_PAID_MUST_BE_GREATER_THAN_ZERO: 'Importo da pagare deve essere maggiore di zero',
            _ATTENTION_A_CLAIM_IS_BEING_OPENED_ON_A_TECHNICALLY_UNCOVERED_POLICY: 'Attenzione! Si sta procedendo all\'apertura di un sinistro su polizza in scopertura tecnica',
            _ATTENTION_OPENING_CLAIM_ON_A_CANCELED_POLICY: 'Attenzione si sta procedendo all\'apertura di un sinistro su polizza annullata',
            _ATTENTION_THE_PAYMENT_EXCEEDS_THE_RESIDUAL_AMOUNTS_OF_THE_LIMIT: 'Attenzione il pagamento supera gli importi residui del massimale!',
            _CANNOT_OPEN_A_CLAIM_ON_A_CANCELED_POLICY: 'Impossibile aprire un sinistro su polizza annullata',
            _CANNOT_OPEN_A_CLAIM_ON_A_POLICY_WITHOUT_ADMINISTRATIVE_VALIDITY: 'Impossibile aprire un sinistro su polizza scoperta amministrativamente',
            _CASH_PREVIDENTIAL_AMOUNT_CANNOT_BE_GREATER_THAN_THE_TAXABLE_AMOUNT: 'Importo cassa previdenziale non puo essere maggiore di importo imponibile',
            _DRAG_A_FILE_TO_UPLOAD: 'Trascina un file da caricare',
            _EXISTING_PES_ALERT: 'È presente a sistema una pes/sinistro con medesima data accadimento e polizza colpita, proseguire?',
            _HOLD_TAX_AMOUNT_CANNOT_BE_GREATER_THAN_THE_TAXABLE_AMOUNT: 'Importo ritenuta non puo essere maggiore di importo imponibile',
            _IMPOSSIBLE_TO_EXECUTE_THE_REQUIRED_OPERATION_CLAIM_RECEIPTS_TO_AUTHORIZE: 'Impossibile effettuare l\'operazione richiesta, quietanza con causali da autorizzare',
            _IMPOSSIBLE_TO_PROCEED_USER_NOT_AUTHORIZED_TO_OPEN_A_CLAIM_ON_A_TECHNICALLY_UNCOVERED_POLICY: 'Impossibile procedere: Utente non abilitato alla apertura di un sinistro su polizza in scopertura tecnica',
            _INSERT_A_CSV_FILE: 'Inserire un file csv',
            _INSERT_POLICY_NUMBER_OR_PLATE: 'È necessario compilare almeno un filtro tra \'targa\' e \'numero polizza\'',
            _INSERT_SUBJECT_SURNAME_OR_DENOMINATION: 'Inserire cognome o denominazione soggetto',
            _INVOICE_AMOUNT_DIFFERENT_FROM_SUM_OF_TAXABLE_AMOUNT_NONTAXABLE_AMOUNT_CASH_PREVIDENTIAL_AMOUNT_STAMP_DUTY_AMOUNT_VAT_AMOUNT: 'Importo fattura diverso da somma di importo imponibile, importo non imponibile, importo cassa previdenziale, importo bolli, importo iva',
            _INVOICE_AMOUNT_MUST_BE_GREATER_THAN_ZERO: 'Importo fattura deve essere maggiore di zero',
            _IT_IS_NOT_POSSIBLE_TO_DELETE_A_DOCUMENT_ALREADY_UPLOADED_TO_DOCUMENT_MANAGEMENT_SYSTEM: 'Non è possibile eliminare un documento caricato sul fascicolo',
            _MISSING_CLAIM_NUMBER_AT_LINE: 'Numero sinistro mancante alla linea ',
            _MISSING_NOT_TAXABLE_AMOUNT_AT_LINE: 'Importo non imponibile mancante alla linea ',
            _MISSING_TAXABLE_AMOUNT_AT_LINE: 'Importo imponibile mancante alla linea ',
            _MISSING_TOTAL_UNIT_AMOUNT_AT_LINE: 'Costo totale unitario mancante alla linea ',
            _N_NOT_CONSISTENT: 'N Danni al veicolo non coerenti con i dati della scatola nera',
            _NO_ACTIVITY_TO_SHOW: 'Nessuna attività da visualizzare',
            _NO_CHANGE_MADE: 'Nessuna modifica apportata',
            _NO_CHAT: 'Nessuna conversazione',
            _NO_DOCUMENTS_TO_UPLOAD: 'Nessun documento da caricare',
            _NO_NORMALIZED_ADDRESS_FOUND: 'Indirizzo normalizzato non trovato',
            _NO_RESULTS: 'Nessun risultato',
            _POLICY_NOT_COMPLIANT_WITH_THE_SELECTED_DAMAGE_CATEGORY: 'Polizza non compatibile con l\'evento selezionato',
            _POLICY_NOT_COMPLIANT: 'Polizza non conforme alla categoria di danno selezionata',
            _PRESS_CALCULATE_BUTTON_BEFORE_CONFIRM: 'Effettuare il calcolo prima di confermare',
            _RESERVE_AMOUNT_NOT_CAPACIOUS_UPDATE_RESERVE_BEFORE: 'Importo di riserva non capiente: aggiornare l\'importo della riserva continua tecnica per poter eseguire la liquidazione',
            _S_CONSISTENT: 'S Danni al veicolo coerenti con i dati della scatola nera',
            _SAVING_ERROR: 'Errore al salvataggio',
            _SEARCH_ERROR: 'Errore durante la ricerca',
            _STAMP_DUTY_AMOUNT_CANNOT_BE_GREATER_THAN_THE_TAXABLE_AMOUNT: 'Importo bolli non puo essere maggiore di importo imponibile',
            _SUM_OF_TAXABLE_AMOUNT_AND_NONTAXABLE_AMOUNT_MUST_BE_GREATER_THAN_ZERO: 'Somma di importo imponibile ed importo non imponibile deve essere maggiore di zero',
            _SUM_OF_THE_NONTAXABLE_AMOUNT_OF_THE_DETAILS_OTHER_THAN_THE_NONTAXABLE_AMOUNT_OF_THE_INVOICE: 'Somma importo non imponibile dei dettagli diverso da importo non imponibile fattura',
            _SUM_OF_THE_TAXABLE_AMOUNT_OF_THE_DETAILS_DIFFERENT_FROM_THE_TAXABLE_AMOUNT_OF_THE_INVOICE: 'Somma importo imponibile dei dettagli diverso da importo imponibile fattura',
            _SUM_OF_THE_TOTAL_UNIT_AMOUNT_OF_THE_DETAILS_DIFFERENT_FROM_THE_INVOICE_AMOUNT: 'Somma importo costo totale unitario dei dettagli diverso da importo fattura',
            _TAXABLE_AMOUNT_MUST_BE_GREATER_THAN_ZERO: 'Importo imponibile deve essere maggiore di zero',
            _THE_POLICY_ALSO_COVERS_MATERIAL_DAMAGE_TO_YOUR_VEHICLE: 'L\' assicurazione copre anche i danni materiali al veicolo',
            _THE_DOCUMENT_WILL_BE_PERMANENTLY_DELETED_DO_YOU_WITH_TO_CONTINUE: 'Il documento verrà eliminato definitivamente, si desidera continuare?',
            _TITLE_AND_DEROGATION_LEVEL_ALREADY_EXIST: 'Titolo e fase gestione già esistente',
            _TOL_CLAIMS_MADE: 'Sono presenti causali di danno in gestione claims made / postuma: i controlli di validità tecnica e amministrativa verranno rieffettuati alla selezione della causale di danno',
            _TOTAL_UNIT_AMOUNT_MUST_BE_GREATER_THAN_ZERO: 'Costo totale unitario deve essere maggiore di zero',
            _UNABLE_TO_MAKE_THE_PAYMENT_THE_RESIDUAL_AMOUNTS_OF_THE_LIMIT_EXCEEDED: 'Impossibile effettuare il pagamento: superati gli importi residui del massimale',
            _UPDATE_SUCCESSFUL: 'Aggiornamento avvenuto correttamente',
            _UPDATE_RESERVE_SUCCESSFUL: 'Aggiornamento avvenuto con successo',
            _UPDATE_RESERVE_ERROR: 'Errore nell\'aggiornamento',
            _VAT_AMOUNT_CANNOT_BE_GREATER_THAN_THE_TAXABLE_AMOUNT: 'Importo iva non puo essere maggiore di importo imponibile',
            _WARNING_YOU_ARE_EXCEEDING_THE_CEILING: 'Attenzione, stai superando il massimale',
            _X_TO_BE_VERIFIED: 'X Coerenza del danno con i dati della scatola nera da verificare',
            _YOU_HAVE_TO_SELECT_AT_LEAST_ONE_ASSET: 'Bisogna selezionare almeno un bene colpito',
            _YOU_HAVE_TO_SELECT_AT_LEAST_ONE_PARTY: 'Bisogna selezionare almeno un attore',
            _YOU_NEED_TO_ADD_A_DOCUMENT_IN_ORDER_TO_EXECUTE_THE_UPLOAD: 'Devi aggiungere un documento per poter effettuare l\'upload.',
            _TYPE_OF_BODYWORK_IS_MANDATORY: 'Il tipo di carrozzeria è obbligatorio',
            _NO_RES_FOUND: 'Nessun risultato trovato',
            _HEIR_ALREADY_ADDED: 'Erede già inserito',
            _HEIR_DATE_INVALID: 'Non è possibile aggiungere la data decesso per un soggetto scelto come erede.',
            _HEIR_IS_EQUAL_SUBJECT: 'Non è possibile aggiungere come erede un soggetto deceduto',
            _HEIR_INVALID: 'Non è possibile selezionare come erede un soggetto che ha la data decesso',
            _HEIR_INVALID_FATAL_DATE: 'Erede rimosso a causa della data decesso, controllare nuovamente gli eredi',
            _ON_CONFIRMATION: 'Al conferma verrà fatta la verifica della regolarità di polizza in base alle coperture selezionate',
            _YOU_CAN_INSERT_1_TO_2_ATTACHMENT: 'Puoi inserire da 1 a 2 allegati.',
            _ALLOWED_EXTENSION: 'Estensioni consentite: txt, pdf, jpg.',
            _THE_SIZE_OF_AN_ATTACHMENT_CANNOT_EXCEED_1_MB: 'La dimensione di un allegato non può superare 1 MB.',
            _ERROR_CODE: 'Codice errore',
            _ERROR: 'Errore',
            _SPECIFY_EVENT_ASSIGNMENT_NUMBER_DATE_FROM_TO: 'Specificare l\'evento/il numero incarico/la data da-a',
            _SPECIFY_ONLY_CLAIM_NUMBER: 'Specificare solo il numero del sinistro',
            _SPECIFY_CRITERIA: 'Specifa criterio',
            _SPECIFY_EVENT: 'Specifa evento',
            _SPEFCIFY_CLAIM_NUMBER: 'Specifare il numero del sinistro',
            _ALERT_NEW_DOC: 'Alert nuovo doc',
            _EXISTING_REQUEST_FOR_DAMAGES_TO_MATCH: 'Richieste Danni esistenti da abbinare al documento',
            _NO_REQUEST_DAMAGES: 'Nessuna richiesta danni',
            _REQUEST_FOR_DAMAGES_RECEIPT_DATE: 'Data pervenimento Richiesta Danni',
            _GENERIC_ERROR_ON_SAVE_QUESTIONNARIE: 'Errore generico nel salvataggio del questionario',
            _QUESTIONNAIRE_WAS_UPDATE_SUCCESSFUL: 'Questionario aggiornato correttamente',
            _QUESTIONNAIRE_MODIFICATION_CANCELLED: 'Modifica questionario annullata',
            _COMPILING_LETTER_CONTENT: 'Compilando il contenuto della lettera',
            _OPENED_POST_LITIGATION: 'Presenza contenzioso societario',
            _PRESENCE_REQUEST_DAMAGE: 'Presenza richiesta danni',
            _WARNING_THE_RESERVE_HAS_BEEN_REDUCED_BY_THE_PAID_AMOUNT: 'Attenzione - La riserva è stata decurtata dell importo liquidato',
            _OF_WHICH_ATTORNEYS_FEES: 'Di cui spese legali',
            _NO_BANK_ACCOUNT_FOR_THE_SUBJECT: 'Nessun conto corrente presente sul soggetto',
            _NO_IBAN_PRESENT_OF_THE_PAYEE: 'Nessun IBAN Presente sul soggetto percipiente',
            _FOREIGN_BANK_ACCOUNT_ON_SUBJECT: 'Conto corrente estero',
            _INSERT_DATE: 'Inserisci la data',
            _CLAIM_EXCESS_POLICY_VALUES: 'Claim excess policy values',
            _CLAIM_EXCESS_RESIDUE: 'Claim excess residue',
            _CLAIM_EXCESS_ERODED_AMOUNT: 'Claim excess eroded amount',
            _EXCESS_AGGREGATE_POLICY_VALUES: 'Franchigia Fissa per Sinistro di Polizza',
            _EXCESS_AGGREGATE_RESIDUE: 'Residui Franchigia Aggregata',
            _EXCESS_AGGREGATE_ERODED_AMOUNT: 'Franchigia aggregata importi erosi',
            _RECEIPT_ACCEPTED: 'Quietanza accettata',
            _RECEIPT_REJECTED: 'Quietanza rifiutata',
            _REASON_REJECTED: 'Motivazione respinta',
            _ONE_PAYEE_IS_REQUIRED: 'Un percipiente è riquiesto',
            _ONE_PAYEE_AND_ONE_BENEFICIARIES_REQUIRED: 'Un percipiente e un benificiario sono richiesti',
            _ONE_PRIMARY_BENEFICIARIES_REQUIRED: 'Un benificiario primario è richiesto',
            _ONE_PRIMARY_PAYEE_REQUIRED: 'Un percipiente primario è richiesto',
            _TAX_CODE__VAT_NUMBER_MISSING: 'Codice fiscale/ Partita IVA mancanti',
            _PAYEE_MUST_BE_ADULT: 'Il percipiente deve essere maggiorenne',
            _PAYEE_NOT_REGISTERED_SAVE_SUBJECT_WITH_SUBJECT_MANAGEMENT_FUNCTION: 'Percipiente non registrato, salvane uno con la funzione gestione soggetti',
            _BEFORE_DELETE_ADD_NEW_BENEFICIARY: 'Aggiungi un nuovo beneficiario prima di eliminare',
            _BEFORE_DELETE_ADD_NEW_PAYEE: 'Aggiungi un nuovo percipiente prima di eliminare',
            _THE_CANCELLATION_DATE_IS_NOT_VALID: 'La data cancellazione non è valida ',
            _SELECT_REASON_CANCELLATION: 'Selezionare motivo annullamento',
            _METHOD_NOT_IMPLEMENTED: 'Metodo non implementato',
            _THERE_IS_A_PAYMENT_WITHOUT_ASSOCIATED_DOCUMENTS_CONTINUE: 'C è presente un pagamento senza documenti associati. Proseguire',
            _SUBJECT_WITH_INCOMPLETE_TAX_DATA__UNABLE_TO_PROCEED: 'Soggetto con dati fiscali incompleti - Impossibile procedere',
            _OPERATION_NOT_AVAILABLE: 'Operazione non disponibile',
            _FEE_DATA_MISSING: 'Dati fattura mancanti',
            _MANDATORY_DATA_MISSING: 'Dati obbligatori mancanti',
            _SELECT_THE_DEFINITION_FOR_THE_SELECTED_CAUSALS: 'Selezionare la definizione per le causali selezionate',
            _YOU_HAVE_TO_SELECT_AT_LEAST_ONE_TYPE_OF_LOSS: 'Bisogna selezionare almeno una causale per proseguire.',
            _THE_AMOUNT_FOR_THE_REASON_SELECTED: 'Censire gli importi per le causali selezionate"',
            _INCOMPLETE_ADDRESS: 'Indirizzo incompleto',
            _INCOMPLETE_DATA: 'Dati incompleti',
            _UIC_NOT_VALID__MAX_3_CHARACTER: 'UIC non valido - massimo 3 caratteri',
            _FOREIGN_BANK_ACCOUNT_INVALID: 'Conto corrente estero non valido',
            _IBAN_INVALID: 'Iban non valido',
            _INSERT_A_VALID_ITALIAN_IBAN: 'Inserire un iban italiano',
            _SPECIAL_CHARACTERS_NOT_ALLOWED_IN_IBAN_CODE: 'I caratteri speciali non sono permessi nel codice IBAN',
            _RESERVE_AMOUNT_NOT_CAPACIOUS__UPDATE_RESERVE_BEFORE: 'Importo di riserva non capiente - aggiorna l importo prima.',
            _IMPOSSIBLE_TO_EXECUTE_REQUIRED_OPERATION__CLAIM_RECEIPTS_TO_AUTHORIZE: 'Impossibile eseguire l operazione richiesta - Quietanze sinistro per autorizzare',
            _ATTENTION_THE_PAYMENT_EXCEEDS_THE_RESIDUAL_AMOUNT_OF_THE_LIMIT: 'AAttenzione, il pagamento supera i limiti',
            _SELECT_REASON_REVERSAL: 'Selezionare motivo dello storno',
            _ENTER_NOTES: 'Inserisci le note',
            _THE_TOTAL_AMOUNT_CANNOT_BE_GREATER_THAN_OR_EQUAL_TO_0: 'L importo totale non può essere superiore o uguale a 0"',
            _THE_CREDIT_NOTE_CANNOT_BE_HIGHER_THAN_THE_ORIGINAL_INVOICE: 'La fattura di storno non può essere superiore alla fattura originale',
            _INVOICE_TYPE_REQUIRED: 'Tipo fattura obbligatorio',
            _FEE_NUMBER_AND_FEE_DATE_ARE_REQUIRED: 'Numero Fattura e Data Fattura sono obbligatori',
            _THE_REVERSAL_DATE_IS_NOT_VALID: 'La data dello storno non è valida',
            _ENTER_A_VALID_IBAN: 'Inserire un iban valido',
            _INSERT_A_VALID_SPANISH_IBAN: 'Inserire un iban spagnolo valido',
            _NO_NOTES_FOR_THIS_COMPENSATION: 'Nessuna nota per questa rivalsa',
            _CHARACTER_LIMIT_EXCEEDED: 'Superato limite caratteri',
            _AN_ERROR_OCCURED_WHILE_ADDING_THE_NOTE: 'Si è verificato un errore all aggiunta della nota',
            _VALUE_AT_LEAST_ONE_FIELD_BETWEEN_NAME_SURNAME_BUSINESS_NAME_CONTRACTOR_FISCAL_CODE_VAT_CODE_CODE_INDEPENDENT_EXPERT: 'Valorizzare almeno un campo tra: nome, cognome, ragione sociale, codice fiscale contraente, partita iva, codice fiduciario',
            _ARE_YOU_SURE_YOU_WANT_TO_DELETE_THE_TRUSTEE_ASSOCIATION: 'Confermi di voler eliminare l\'associazione a fiduciario?',
            _EXPERT_LINK_ADDED_WITH_SUCCESS: 'Collegamento al fiduciario aggiunto con successo!',
            _AN_ERROR_OCCURRED_WHILE_PROCESSING_THE_REQUEST: 'Si è verificato un errore durante l\'elaborazione della richiesta.',
            _EXPERT_ASSOCIATION_DELETED_CORRECTLY: 'Associazione fiduciario eliminata correttamente!',
            _AN_ERROR_OCCURRED_WHILE_DELETING_THE_EXPERT_ASSOCIATION: 'Si è verificato un errore durante l\'eliminazione dell\'associazione del fiduciario',
            _IN_ERROR: 'In errore',
            _SELECT_A_USER_BEFORE_CONFIRMING: 'Selezionare un utente prima di confermare',
            _OPERATION_PROPERLY_TERMINATED: 'Operazione terminata correttamente',
            _INCOMPLETE_COMPILATION: 'Compilazione incompleta',
            _FISCAL_CODE__VAT_UNCORRECT: 'Codice fiscale/ PIVA errato',
            _VAT_UNCORRECT: 'PIVA errata',
            _FISCAL_CODE_UNCORRECT: 'Codice fiscale errato',
            _SELECT_A_CRITERIA: 'Seleziona un criterio',
            _IMPUTE_THE_NAME_OR_THE_FISCAL_CODE: 'Imputare il nome o il codice fiscale',
            _SPECIFY_THE_TYPE_ASSET: 'Specificare il tipo bene',
            _SELECT_AT_LEAST_ONE_REASON: 'Selezionare almeno una causale',
            _FILE_IS_EMPTY: 'File is empty',
            _MAXIMUM_FILE_SIZE_EXCEEDED: 'Maximum file size exceeded',
            _NOT_GENERATED_FOR_CHOICE: 'Non generato per scelta',
            _SAVE_NEW_ACTOR: 'Attore salvato correttamente',
            _GENERIC_ERROR_SAVE_ACTOR: 'Errore riscontrato durante il salvataggio del nuovo attore',
            _SELECT_THE_DOCUMENT_TO_ATTACH: 'Seleziona il documento da allegare.',
            _KO_UPLOAD_DOC: 'Si è verificato un errore durante l\'upload del documento',
            _CANCELLATION_DIALOGUE: 'Sei sicuro di voler annullare il dialogo?',
        },
        _TITLE_: {
            _QUESTIONNAIRE_TITLE: 'Questionario',
            _SELECT_POLICY: 'Seleziona polizza',
            _OCCURRENCE_DATA: 'Dati accedimento',
            _DATA_COLLECTION: 'Raccolta dati',
            _SUMMARY: 'Riepilogo',
            _DIALOGUE_HANDLER_LIST: 'Lista Dialoghi',
            _DIALOGUE_HANDLER_NEW: 'Nuova Richiesta',
            _DIALOGUE_HANDLER_SUMMARY: 'Riepilogo Dialogo',
            _ADDRESS_MANAGEMENT: 'Gestione indirizzo'
        },
        _DATA_FORWARDING_CDL: ' Data inoltro CDL',
        _INTERMEDIARY: 'Intermediario',
        _MASTER_POLICY_NUMBER: 'Numero polizza madre',
        _APPLICATION_NUMBER: 'Nr. Applicazione',
        _COMPLAINT_STATE: 'Stato denuncia',
        _PLATE__CHASSIS: 'Targa / Telaio ',
        _ACCOUNTING_COVERAGE: 'Copertura Amministrativa',
        _LEGAL_GUARDIAN_ROLE: 'Ruolo tutore',
        _CAR_BODY_DETAIL: 'Dettaglio carrozzeria',
        _START_DATE_OF_COLLABORATION: 'Data inizio collaborazione',
        _END_DATE_COLLABORATION: 'Data fine collaborazione',
        _HOUSE_NUMBER: 'Numero Civico',
        _MUNICIPALITY: 'Comune',
        _NEW_BODYWORK: 'Nuova carrozzeria',
        _SUBJECT_TYPE: 'Tipo soggetto',
        _CODE: 'Codice',
        _BUSINESS_NAME: 'Ragione sociale',
        _FISCAL_CODE: 'Codice fiscale',
        _VAT_CODE: 'P. IVA',
        _TYPE_OF_BODYWORK: 'Tipo di carrozzeria',
        _INJURY_PHISICAL: 'Lesioni',
        _CAUSE_OF_INJURY: 'Causa lesione',
        _UNCODABLE: 'Non Codificabile',
        _MULTIPLE: 'Multiple',
        _NATURE_OF_INJURY: 'Natura lesione',
        _INJURY_LOCATION: 'Sede lesione',
        _FURTHER_INJURY_INDICATOR: 'Indicatore ulteriori lesioni',
        _PROFESSIONAL_QUALIFICATION: 'Qualifica professionale',
        _PERCENTAGE_OF_PERMANENT_DISABILITY: 'Percentuale invalidità permanente',
        _TEMPORARY_DISABILITY_DAYS_WITHOUT_SEQUELAE: 'Giorni inabilità temporanea senza postumi',
        _FATALITY_DATE: 'Data decesso',
        _FIRST_AID_COUNTRY: 'Nazione pronto soccorso',
        _FIRST_AID_PLACE: 'Luogo pronto soccorso',
        _HEALTHCARE_PROVIDERS: 'Strutture Sanitarie',
        _BIOLOGICAL_DAMAGE: 'Danno biologico',
        _SUBROGATION_SOCIAL_ASS_EMPLOYER: 'Surroga Ass. sociale/Datore lavoro',
        _DOCTOR: 'Medico',
        _SEARCH: 'Cerca',
        _SKULL: 'CRANIO',
        _SPINE_CERVICAL_COLUMN: 'SPINE-CERVICAL COLUMN',
        _LEFT_SHOULDER: 'COLONNA VERTEBR-CERVICALE',
        _RIGHT_SHOULDER: 'SPALLA DESTRA',
        _SPINE_THORACIC_COLUMN: 'COLONNA VERTEBR-TORACICA',
        _SPINE_LUMBAR_COLUMN: 'COLONNA VERTEBR-LOMBARE',
        _SPINE_SACRUS_AND_COCCYX: 'COLONNA VERTEBR-SACRO E COCCIGE',
        _LEFT_ANKLE: 'CAVIGLIA SINISTRA',
        _RIGHT_ANKLE: 'CAVIGLIA DESTRA',
        _RIGHT_EAR: 'ORECCHIO DESTRO',
        _RIGHT_EYE: 'OCCHIO DESTRO',
        _LEFT_EYE: 'OCCHIO SINISTRO',
        _LEFT_EAR: 'ORECCHIO SINISTRO',
        _FACE: 'FACCIA',
        _NECK: 'COLLO',
        _RIGHT_CHEST_WALL: 'PARETE TORACICA DESTRA',
        _LEFT_CHEST_WALL: 'PARETE TORACICA SINISTRA',
        _RIGHT_ARM: 'RACCIO DESTRO',
        _THORACIC_ORGANS: 'ORGANI TORACICI',
        _LEFT_ARM: 'BRACCIO SINISTRO',
        _RIGHT_ELBOW: 'GOMITO DESTRO',
        _ABDOMINAL_ORGANS: 'ORGANI ADDOMINALI',
        _LEFT_ELBOW: 'GOMITO SINISTRO',
        _RIGHT_FOREARM: 'AVAMBRACCIO DESTRO',
        _ABDOMINAL_WALL: 'PARETE ADDOMINALE',
        _LEFT_FOREARM: 'AVAMBRACCIO SINISTRO',
        _PELVIS___RIGHT_HIP: 'BACINO - ANCA DESTRA',
        _UROGENITAL_ORGANS: 'ORGANI UROGENITALI',
        _PELVIS___LEFT_HIP: 'BACINO - ANCA SINISTRA',
        _RIGHT_WRIST: 'POLSO DESTRO',
        _LEFT_WRIST: 'POLSO SINISTRO',
        _RIGHT_HAND: 'MANO DESTRA',
        _RIGHT_THIGH: 'COSCIA DESTRA',
        _LEFT_THIGH: 'COSCIA SINISTRA',
        _LEFT_HAND: 'MANO SINISTRA',
        _RIGHT_KNEE: 'GINOCCHIO DESTRO',
        _LEFT_KNEE: 'GINOCCHIO SINISTRO',
        _RIGHT_LEG: 'GAMBA DESTRA',
        _LEFT_LEG: 'GAMBA SINISTRA',
        _RIGHT_FOOT: 'PIEDE DESTRO',
        _LEFT_FOOT: 'PIEDE SINISTRO',
        _REQUIRED: 'Obbligatorio',
        _HEIR: 'Erede',
        _HEIR_ADD: 'Aggiungi erede',
        _HEIRS: 'Eredi',
        _EXTERNAL_CLAIM_NUMBER: 'Numero sinistro esterno',
        _POTENTIAL_CLAIM_NUMBER: 'Numero denuncia',
        _ALIGN_POLICY: 'Allinea Polizza',
        _ACTIONS: 'Azioni',
        _DATE_ENTRY_FROM: 'Data Inizio aa',
        _ELABORATION_DATE_FROM: 'Data Elaborazione da',
        _EXPIRY_DATE_FROM: 'Data scadenza da',
        _CLAIM_DATE_FROM: 'Data sinistro da',
        _INCAPACITATED: 'Inabile',
        _FUNCTION: 'Funzione',
        _EXTERNAL_KEY: 'Chiave esterna',
        _DAMAGE_MATCH: 'Partita di danno',
        _TAX_ID_CODE: 'Codice Fiscale',
        _COMPLAINT: 'Denuncia',
        _NOT_ASSOCIATED: 'Non Associato',
        _CTP_COMPANY: 'Compagnia ctp',
        _US_CAR_LICENSE_PLATE: 'Targa Ass.',
        _CTP_CAR_LICENSE_PLATE: 'Targa Ctp',
        _ACTIVE_TASK: 'Task attivo',
        _ERROR_MESSAGE: 'Messaggio di errore',
        _SIMILAR_FLAG: 'Campi Simili',
        _FILTER: 'Filtro',
        _ROLE: 'Ruolo',
        _LAST_ELABORATION: 'Ultima Elaborazione',
        _NOT_ASSOCIATED_DIALOGUES: 'Dialoghi non associati',
        _NOMINATIVE: 'Nominativo',
        _DIALOGUE_DATE_START: 'Data Inizio Dialogo',
        _END_DATE: 'Data Fine',
        _SX_DATE: 'Data Sinistro',
        _DIALOGUE: 'Dialogo',
        _INSERTION: 'Inserito il',
        _DATE: 'Data',
        _EXTERNAL_KEY_DAMAGED: 'Chiave Esterna Danneggiato',
        _INTERNAL_KEY_DEF: 'Chiave Interna Definitiva',
        _INTERNAL_KEY_TEMP: 'Chiave Interna Temporanea',
        _INTERNAL_KEY_DAMAGED_DEF: 'Chiave Interna Danneggiato Definitiva',
        _INTERNAL_KEY_DAMAGED_TEMP: 'Chiave Interna Danneggiato Temporanea',
        _INTERNAL_KEY_DAMAGED: 'Chiave Interna Danneggiato',
        _US_TYPE_PLATE: 'Tipo Targa',
        _CTP_TYPE_PLATE: 'Tipo Targa Ctp',
        _IDENTIFIER_UNIQUE_CODE: 'Codice Univoco',
        _PARAMETERIZED_DIALOGUE_DATA: 'Dati Dialogo Parametrizzati',
        _REGULATORY_EXPIRY_DATE: 'Data Scadenza Regolatoria',
        _LAST_UPDATE_DATE: 'Ultimo Aggiornamento',
        _USER_UPDATE: 'Aggiornamento Utente',
        _ACTIVITY_TYPE: 'Tipo Attività',
        _REFERENCE: 'Riferimento',
        _PARAMETERS_UPPERCASE: 'PARAMETRI',
        _REQUEST: 'Richiesta',
        _REQUEST_NOTE: 'Note Richiesta',
        _RESPONSE: 'Risposta',
        _RESPONSE_NOTE: 'Note Risposta',
        _DOCUMENTS_UPPERCASE: 'DOCUMENTI',
        _DISCARDED_UPPERCASE: 'SCARTATI',
        _CAUSAL: 'Causale',
        _EXPIRATION: 'Scadenza',
        _SENDED: 'Inviato',
        _START_STATUS: 'Stato Invio',
        _DOCUMENT_TYPE: 'Tipo Documento',
        _DOCUMENT_TYPE_FLUX: 'Tipo Documento (flusso)',
        _CODE_ERROR: 'Codice Errore',
        _TYPE: 'Tipo',
        _INPUT: 'Input',
        _OUTPUT: 'Output',
        _RESPONSE_NOTES: 'Note di Risposta',
        _DOCUMENTS_REQUIRED: 'Documenti necessari per la gestione della risposta selezionata',
        _SELECTED_DOCUMENTS: 'Seleziona i documenti da associare alla risposta entrando nel flusso tipo di documento per ciascuno',
        _ACTOR_INVOLVED: 'Attore Coinvolto',
        _REASON: 'Motivo',
        _DOCUMENT_TYPE_FLOW: 'Tipo Documento (flusso)',
        _MANDATORY: 'Obbligatorio',
        _MATTER: 'Importanza',
        _SIZE: 'Dimensione',
        _TITLE: 'Titolo',
        _SUB_CATEGORY: 'Sottocategoria',
        _CLAIM: 'sinistro',
        _LST_MSG_CODE: 'Codice Ultimo Messaggio',
        _LST_MES_REQ_DATA: 'Data Richiesta (U.M.)',
        _LST_MES_REG_EXP: 'Data Scadenza (U.M.)',
        _START_DATE: 'Data Inizio',
        _INTERNAL_KEY: 'Chiave Interna',
        _LST_RESP_MES_CODE: 'Codice Risposta (U.M.)',
        _LST_MSG_DATE: 'Data Ultimo Messaggio',
        _LST_MSG_INT_EXP: 'Data Scadenza Interna (U.M)',
        _EXT_KEY_IDEF_DAM: 'Chiave esterna di riferimento danneggiato',
        _ONGOING: 'IN CORSO',
        _EVADE: 'DA EVADERE',
        _CLOSED: 'CHIUSO',
        _CANCELED: 'CANCELLATO',
        _INSURANCE_CODE_US: 'Compagnia',
        _ACTOR_NOMINATIVE: 'Nominativo Attore Associato',
        _DETAILS_CLAIMS_DATA: 'Dettagli Dati Sinistro',
        _VALIDATE: 'Valida',
        _CONSOLIDATE: 'Consolida',
        _BALANCE: 'Bilancio',
        _VALIDATION_AND_CONSOLIDATION: 'Validazione&Caricamento',
        _PAYMENT_MANAGE: 'Gestione Pagamenti',
        _FORFAIT_MANAGE: 'Gestione Forfait',
        _YEAR: 'Anno',
        _MONTH: 'Mese',
        _JANUARY: 'Gennaio',
        _FEBRUARY: 'Febbraio',
        _MARCH: 'Marzo',
        _APRIL: 'Aprile',
        _MAY: 'Maggio',
        _JUNE: 'Giugno',
        _JULY: 'Luglio',
        _AUGUST: 'Agosto',
        _SEPTEMBER: 'Settembre',
        _OCTOBER: 'Ottobre',
        _NOVEMBER: 'Novembre',
        _DECEMBER: 'Dicembre',
        _TYPES_OF_LOSS: 'Causali',
        _RISK_DATA: 'Dati del rischio',
        _COVERTURE_DATA: 'Dati della causale',
        _RULES_FOR_UPLOADING_DOCUMENTS: 'Regole per il caricamento dei documenti:',
        _OCCURRENCE_HOUR: 'Ora accadimento',
        _DATE_INTERMEDIARY: 'Data Ricezione Intermediario',
        _TYPE_OF_AUTHORITIES_POSSIBLY_INVOLVED: 'Tipo eventuale autorità intervenuta',
        _CATASTROPHIC_EVENT_DESCRIPTION: 'Descrizione evento catastrofico',
        _OPENING_MODE: 'Modalità di apertura sinistro',
        _PARTY: 'Attore',
        _ASSET_INSURED: 'Beni assicurato',
        _NEW_DAMAGED: 'Nuovo danneggiato',
        _TYPE_OF_LOSS: 'Causale danno',
        _NO_RESULT: 'Nessun risultato.',
        _NEW_ASSET: 'Nuovo bene',
        _ASSET_TYPE: 'Tipo bene',
        _FOUND: 'Trovati',
        _COVERAGES_DETAIL: 'Dettaglio coperture',
        _ADD_A_DAMAGED: 'Aggiungi un danneggiato',
        _PHYSICAL_PERSON: 'Persona fisica',
        _POLICY_DATA: 'Dati di polizza',
        _ADMINISTRATIVE_VALIDITY: 'Validità amministrativa',
        _LIST_OF_RELATED_CLAIMS: 'Elenco sinistri correlati',
        _CF__PIVA: 'CF/PIVA',
        _DOL: 'DOL',
        _DON: 'DON',
        _MAIN: 'Principale',
        _ORIGINAL_EFFECT_DATE: 'Data effetto originale',
        _NO_POLICY_TO_SHOW: 'Non ci sono polizze da mostrare',
        _ASSIGNMENT_DATE: 'Assignment Date',
        _STATUS: 'Stato',
        _FILTERS: 'Filtri',
        _CLAIMS_HANDLER: 'Liquidatore',
        _TECHNICAL_STATE: 'Stato Tecnico',
        _RESERVED: 'Riservato',
        _INVOICE_DATE: 'Data fattura',
        _AMOUNT: 'Importo',
        _REJECT_TYPE: 'Tipo Scarto',
        _UPLOAD_DETAILS: 'Upload Dettagli',
        _NUMBER: ' Numero',
        _ACTOR: 'Attore',
        _UPLOAD: 'Upload',
        _EXPERT_INVOICE: 'Professionista',
        _TAX_CODE__VAT_NUMBER: 'Codice Fiscale/ Partita IVA',
        _MANUAL_NOTE: 'Nota manuale',
        _INSERT_A_NEW_MANUAL_NOTE: 'Inserire una nuova nota manuale',
        _COMMENTS_FORM: 'Comments form',
        _THERE_ARE_NO_COMMENTS: 'Non sono presenti commenti',
        _NO_PERMISSION: 'Permessi insufficienti',
        _CANCELLATION: 'Annullo',
        _COMMENT: 'Commento',
        _TOTAL_ASSIGNMENT_EXPENSE_AMOUNT: 'Spese di liquidazione',
        _TOTAL_RESERVE_FOR_LITIGATION: 'Spese di resistenza',
        _FROM: 'Da',
        _TO: 'A',
        _QUICK_FILTERS: 'Filtri rapidi',
        _ASCENDING_ORDER: 'Ordine crescente',
        _DESCENDING_ORDER: 'Ordine decrescente',
        _MOVEMENTS: 'Movimenti',
        _ACCESSES: 'Accessi',
        _ACTIVITY: 'Attività',
        _RESERVES: 'Riserve',
        _TITLES: 'Titoli',
        _STATE: 'Stato',
        _ASSIGNEE_GROUP: 'Assign group',
        _ASSIGNEE_USER: 'Assign user',
        _RUN_DATE: 'Data avvio',
        _SUSPENDED: 'Sospeso',
        _COMPLETION_DATE: 'Data completamento',
        _ESCAPE_METHODS: 'Modalità di evasione',
        _RUN_ACTIVITY: 'Esegui attività',
        _TAKE_CHARGE_ACTIVITY: 'Take charge activity',
        _REASSESS_TASK: 'Riassegna task',
        _NO_SUGGESTIONS: 'Nessun suggerimento',
        _CRITERIA: 'Criteria',
        _ASSIGNMENT: 'Incarico',
        _EVENTS: 'Eventi',
        _R_PER: 'Restituzione incarico (R-PER)',
        _A_COM: 'Comunicazione (A-COM)',
        _S_RIF: 'Rifiuto incarico (S-RIF)',
        _R_FAT: 'Fattura (R-FAT)',
        _TRANSMISSION_LIST: 'Elenco Invii',
        _FAILED_TRANSMISSION_LIST: 'Elenco Mancati Invii',
        _STARTER_EVENT: 'Evento starter',
        _ID: 'Id',
        _WS: 'WS',
        _XML: 'Xml',
        _DETAIL: 'Dettaglio',
        _VIEW_DETAIL_BLOW: 'View detail blow',
        _INSERT_DATE: 'Data inserimento',
        _ELABORATION_DATE: 'Elaboration date',
        _INSUFFICIENT_PERMISSIONS: 'Permessi insufficienti',
        _POTENTIAL_CLAIM_INQUIRY: 'Denuncia',
        _INVALIDATE: 'Invalida',
        _MODIFY: 'Modifica',
        _SUBCATEGORY: 'Sottocategoria',
        _RECEPTION_CHANNEL: 'Canale di ricezione',
        _FORMAT: 'Formato',
        _NUMBER_OF_PAGES: 'Numero di pagine',
        _RECEIIPT_DATE: 'Data ricezione',
        _USER: 'Utente',
        _SUBTITLE: 'Sottotitolo',
        _DOC_KEY: 'Chiave documento',
        _POSTALIZATION_RESULT: 'Risultato della postalizzazione',
        _POSTALIZATION_DATE: 'Data di postalizzazione',
        _SHIPPING_TYPE: 'Tipo di spedizione',
        _VIEW_BY_CATEGORIES: 'Vista per categorie',
        _UPLOAD_DATE: 'Data caricamento',
        _SUBCATEGORY_DEPRECATED: 'Sottocategoria deprecata',
        _CATEGORY_DEPRECATED: 'Categoria deprecata',
        _HISTORY: 'Cronologia',
        _OPERATIONS: 'Operazioni',
        _EDIT_DOCUMENT: 'Modifica dati documento',
        _CHANGE_PARTY: 'Cambia attore',
        _SELECT_CATEGORY: 'Seleziona categoria',
        _SELECT_SUBCATEGORY: 'Seleziona sottocategoria',
        _PEOPLE: 'Persone',
        _EXTERNAL_VISIBILITY: 'Visibilità esterna',
        _VEICHLE: 'Veicolo',
        _VALIDATED: 'Validato',
        _NO_VALIDATE: 'Non validato',
        _TECHNICAL_STATUS: 'Stato tecnico',
        _RESERVE_AMOUNT: 'Importo riserva',
        _TECHNICAL_REACTIVATION_OPENING_DATE: 'Data ultima riapertura/riattivazione tecnica',
        _ACCOUNTING_REACTIVATION_OPENING_DATE: 'Data ultima riapertura/riattivazione contabile',
        _TECHNICAL_CLOSING_DATE: 'Data ultima chiusura tecnica',
        _NEW_QUESTIONNAIRE_DATE: 'Nuova data del questionario',
        _CODE_CHAIN: 'Code chain',
        _CODE_ACTION: 'Code action',
        _DATA_ENTRY: 'Data entry',
        _OUTCOME: 'Outcome',
        _INSERTED: 'Inserted',
        _JSON_N: 'Json N°',
        _CANCELLATION_REASON: 'Motivo della cancellazione',
        _ASSOCIATED_ENTITY: 'Entità associata',
        _EXECUTIONS: 'Esecuzioni',
        _JSON_OK_N: 'Json OK n.',
        _JSON_KO_N: 'Json KO n.',
        _RERUN: 'Rerun',
        _RESERVATION_DATE: 'Data di prenotazione',
        _IN_PROGRESS: 'In corso',
        _FORCED_CLOSURE: 'Chiusura forzata',
        _NOT_STOPPED: 'Non interrotto',
        _STOPPED_SUCCESSFULLY: 'Interrotto con successo',
        _RERUN_RESERVED: 'Rerun reserved',
        _RERUN_FAILURE: 'Riesecuzione fallita',
        _RESULTS: 'Risultati',
        _ELABORATION: 'Elaborazione',
        _REQUEST_TYPE: 'Tipo richiesta',
        _ELABORATION_REQUEST_DATE: 'Data richiesta elaborazione',
        _INPUT_PARAMETERS: 'Parametri di input',
        _JSON: 'Json',
        _JSON_EXTERNAL_KEY: 'Json chiave esterna',
        _CREATION_FAILED: 'Creazione fallita',
        _REQUEST_CREATED: 'Richiesta creata',
        _RESERVATION: 'Prenotazione',
        _CREATION_ERROR: 'Errore di creazione',
        _TOO_MANY_JSON_WERE_UPLOADED: 'Sono stati caricati troppi JSON',
        _COMPANY_SETTLEMENT_AMOUNT: 'Importo a carico della compagnia',
        _PAYEE: 'Percipiente',
        _PAYMENT_TYPE: 'Tipo di Pagamento',
        _BENEFICIARIES: ' Beneficiari',
        _ADD_BENEFICIARY: 'Aggiungi beneficiario',
        _PAYEES: 'Percipienti',
        _ADD_PAYEE: 'Aggiungi percipiente',
        _CALCULATE: 'Calcola',
        _REASON_CANCELLATION: 'Motivo dell annullamento',
        _C__O: 'C/O',
        _NO_DOCUMENTS: 'Nessun documento',
        _FEE_DATA: 'Dati Spese',
        _FEE_NUMBER: 'Numero Spese',
        _FEE_DATE: 'Data Spese',
        _IVA_AMOUNT: 'Importo IVA',
        _IRPF_AMOUNT: 'Importo IRPF',
        _WITH_HOLDING_AMOUNT: 'Ritenuta',
        _NET_AMOUNT: 'Importo netto',
        _CASH_AMOUNT_1: 'Importo cassa 1',
        _CASH_AMOUNT_2: 'Importo cassa 2/INPS',
        _SETTLEMENT_BLOCKED__PERCENTAGE_SAME_INVOICE_ALREADY_REIMBURSED_TO_INSURED: 'Liquidazione bloccata: % medesima fattura già rimborsata ad assicurato',
        _PAID_AMOUNT: 'Importo liquidato',
        _PERCENTAGE_OF_THE_TOTAL: '% sul totale ',
        _ALREADY_INSERT: 'Importo già inserito',
        _REMAINING_AMOUNT: ' Importo rimanente',
        _EVALUATION: ' Valutazione',
        _PAYMENT_DATA: 'Dati pagamento',
        _CALCULATION_OF_THE_INSURED_FEE: 'Calcolo quota a carico',
        _BENEFICIARIES_AND_PAYEES: 'Beneficiari e percipienti',
        _METHODS_OF_PAYMENT: 'Metodi di pagamento',
        _LETTERS_MANAGEMENT: 'Gestione Lettere',
        _RECEIPT_SUMMARY: 'Dati riepilogo quietanza',
        _SETTLEMENT_SUMMARY: 'Dati riepilogo liquidazione',
        _PARTY_ROLE: 'Ruolo attore',
        _OPERATION_USER: 'Utente operazione',
        _QUITTANCE_DATE: 'Data quietanza',
        _CURRENCY_DATE: 'Data valuta',
        _PAYMENT_TRANSACTION_TYPE: 'Tipo pagamento',
        _RECEIPT_TYPE: 'Tipo quietanza',
        _MANAGMENT_PAYMENT: 'Gestione pagamento',
        _SEPARATE_RECEIPT: 'Quietanza separata',
        _CURRENCY: 'Valuta',
        _PRIORITY: 'Priorità',
        _DUE_DATE: 'Data esigibilità',
        _PROCEDURE_CODE: 'Codice procedimento',
        _AMOUNT_QUOTA: 'Importo quota',
        _OPEN_DETAIL: 'Apri dettagli',
        _FORFAIT_TYPE: 'Tipo forfait',
        _AMOUNT_QUOTA_FORFAIT: 'Importo quota forfait',
        _FORFAIT: 'Forfait',
        _IMPORT: 'Importo',
        _METHOD_OF_PAYMENT: 'Metodi di pagamento',
        _BANK_TRANSFER_DESCRIPTION: 'Causale bonifico',
        _INCOMPLETE_ADDRESS: 'Indirizzo incompleto',
        _NOT_GENERATED_FOR_MISSING_DATA: 'Non generato per assenza di dati significativi',
        _GENERATED: 'Generato',
        _RETURN_OF_RECEIPT: 'Restituzione quietanza',
        _RECEIPT_STATUS: 'Stato quietanza',
        _RECEIPT_DATE: 'Data quietanza',
        _REASON_CANCEL_RECEIPT: 'Motivo annullo quietanza',
        _RECEIPT_NOTES: 'Note quietanza',
        _RETURN: 'Rientro',
        _BENEFICIARY: 'Beneficiario',
        _SENDING_LETTERS: 'Invio lettere',
        _MANAGEMENT_PAYMENT: 'Gestione pagamento',
        _PAYABILITY: 'Data Esigibilità',
        _WAITING: 'Attendere',
        _SUBSTATUS: 'Sottostato',
        _PAYMENT_DEFINITION: 'Definizione pagamento',
        _RESERVED_AMOUNT: 'Importo riservato',
        _PARTIAL: 'Parziale',
        _CHC: 'CHC',
        _LOAD_DATE: 'Data carico',
        _OFFER_DATE: 'Data offerta',
        _IBAN: 'Iban',
        _IBAN_ON_SUBJECT: 'Iban su soggetto',
        _ACCOUNT_HOLDER: 'Intestatario conto',
        _BANK_DESCRIPTION: 'Descrizione banca',
        _BANK_ADDRESS: 'Indirizzo banca',
        _SWIFT: 'Swift',
        _FOREIGN_BANK_ACCOUNT: 'Conto corrente estero',
        _FOREIGN_BANK_ACCOUNT_ON_SUBJECT: 'Conto corrente estero sul soggetto',
        _BIC: 'BIC',
        _UIC: 'UIC',
        _BANK: 'Banca',
        _LEGAL_OPPONENT: 'Legale avversario ',
        _REGISTER_SUBJECT: 'Soggetto da Anagrafe',
        _OPEN_REGISTER: 'Apri anagrafe',
        _SETTLEMENTS_LIST: 'Lista liquidazioni',
        _RECEIPTS_LIST: 'Lista quietanze',
        _COMPENSATIONS_LIST: 'Lista rivalse',
        _APPOINTMENTS_LIST: 'Lista appuntamenti',
        _TYPOLOGIES_SETTLEMENT_LIST: 'Elenco tipologie liquidazione',
        _ACCESSORY_CHECKS: 'Verifiche accessorie',
        _FORCING_APPLY: 'Applica forzatura',
        _ADMINISTRATIVE_VALIDITY_OVERIDDEN_ON_POLICY_NO: 'Effettuata forzatura validità amministrativa su polizza colpita n',
        _EFFECTIVE_DATE_OF_COLLECTION: 'Data effetto titolo',
        _EXPIRY_DATE_OF_COLLECTION: 'Data scadenza titolo',
        _DATE_OF_COLLECTION: 'Data incasso titolo',
        _CONFIRM_RESPONSIBILITY: 'Conferma responsabilità',
        _PERCENTAGE_INSURED_LIABILITY: 'Percentuale responsabilità assicurato',
        _REASON_TO_FORCE_LIABILITY_PERCENTAGE: 'Motivo forzatura % responsabilità',
        _COUNTERPART_VEHICLE: 'Veicolo di controparte',
        _CHASSIS__PLATE_NUMBER: 'Numero telaio',
        _LIABILITY_PERCENTAGE: 'Percentuale responsabilità',
        _HISTORICAL_EROSION: 'Storico erosione',
        _INPUT_AMOUNT: 'Importo in input',
        _INSURED_SETTLEMENT_AMOUNT: 'Importo a carico dell assicurato',
        _CLAIM_EXCESS: 'Franchigia sinistro',
        _DEDUCTIBLE: 'Scoperto',
        _EXCESS_AGGREGATE: 'Franchigia aggregata',
        _SIR: 'SIR',
        _LIMIT: 'Limite',
        _FIRST_SIR: 'Prima SIR',
        _SECOND_SIR: 'Seconda SIR',
        _ADVANCE: 'Anticipa',
        _SIR_ADVANCED_BY_COMPANY_SOMETIMES: 'SIR Anticipata da AMT (per sinistri eccedenti la SIR)',
        _CAP_INDICATOR: 'Indicatore CAP Sinistro',
        _CAP_AMOUNT: 'Importo CAP sinistro',
        _EXCESS_AMOUNT: 'Importo franchigia sinistro',
        _FIRST_SIR_POLICY_VALUES: 'Prima SIR valori di polizza ',
        _FIRST_SIR_RESIDUE: 'Prima SIR residui',
        _FIRST_SIR_ERODED_AMOUNT: 'Prima SIR importi erosi ',
        _SIR_AMOUNT: 'Importo SIR',
        _DEDUCTIBLE_POLICY_VALUES: 'Scoperto valori di polizza',
        _DEDUCTIBLE_RESIDUE: 'Scoperto residui',
        _DEDUCTIBLE_ERODED_AMOUNTS: 'Scoperto importi erosi',
        _PERCENTAGE_AMOUNT: 'Importo percentuale',
        _DEDUCTIBLE_AMOUNT_RESIDUE: 'Importo scoperto',
        _CALCULATED_AMOUNT: 'Importo calcolato',
        _MAXIMUM_AMOUNT: 'Importo massimo',
        _MINIMUM_AMOUNT: 'Importo minimo',
        _MAXIMUM_LEVELS_POLICY_VALUES: 'Massimali valori di polizza',
        _MAXIMUM_LEVELS_RESIDUE: 'Massimali residui',
        _MAXIMUM_LEVELS_ERODED_AMOUNTS: 'Massimali importi erosi',
        _RETURN_DATE: 'Data rientro',
        _SETTLEMENT_REVERSAL: 'Storno Liquidazione',
        _REVERSAL_DATE: 'Data dello storno',
        _REASON_REVERSAL: 'Motivo dello storno',
        _PAYMENT_NUMBER: 'Numero pagamento',
        _PAYMENT_SAVING_SUMMARY: 'Riepilogo Salvataggio Pagamento',
        _ACTIVATED: 'Attiva',
        _SETTLEMENT_NUMBER: 'Liquidazione numero',
        _ISSUED_CORRECTLY: 'Emessa correttamente',
        _RISK: 'Rischio',
        _POLICY_ID: 'ID Contraente ',
        _CLAIM_EXCESS_POLICY_VALUE: 'Franchigia Fissa per Sinistro di Polizza',
        _CLAIM_CAP_AMOUNT: 'Importo CAP sinistro ',
        _CLAIM_EXCESS_AMOUNT: 'Importo franchigia sinistro',
        _POLICY_ID_END_DATE: 'Data fine ID Contraente ',
        _POLICY_ID_START_DATE: 'Data inizio ID Contraente',
        _CLAIM_CAP_INDICATOR: 'Indicatore CAP sinistro',
        _CLAIM_EXCESS_ADVANCE: 'Anticipa franchigia sinistro',
        _CLAIM_EXCESS_RESIDUE: 'Residui franchigia fissa per sinistro',
        _CLAIM_EXCESS_LIQUIDATOR_ADVANCE: 'Anticipa franchigia sinistro liquidatore ',
        _CLAIM_EXCESS_AMOUNT_ADVANCE: 'Anticipa Importo Franchigia Sinistro  ',
        _CLAIM_CAP_AMOUNT_RESIDUE: 'Importo CAP Sinistro residuo',
        _FIRST_SIR_ADVANCE: 'Prima SIR Anticipa ',
        _AGGREGATE_CAP_INDICATOR: 'Indicatore CAP Aggregata ',
        _EXCESS_AGGREGATE_POLICY_VALUE: 'Franchigia Aggregata di Polizza',
        _AGGREGATE_CAP_AMOUNT: 'Importo CAP Aggregata ',
        _EXCESS_AGGREGATE_AMOUNT: 'Importo Franchigia Aggregata ',
        _EXCESS_AGGREGATE_ADVANCE: 'Anticipa Franchigia Aggregata ',
        _EXCESS_AGGREGATE_RESIDUE: 'Residui Franchigia Aggregata ',
        _EXCESS_AGGREGATE_LIQUIDATOR_ADVANCE: 'Anticipa Franchigia Aggregata liquidatore ',
        _AGGREGATE_CAP_AMOUNT_RESIDUE: 'Importo CAP Aggregata residuo ',
        _EXCESS_AGGREGATE_AMOUNT_RESIDUE: 'Importo Franchigia Aggregata residuo ',
        _SECOND_SIR_POLICY_VALUES: 'Valori polizza secondo SIR  ',
        _EXCESS_SECOND_SIR: 'Franchigia secondo SIR ',
        _CLAIM_LIQUIDATOR_ADVANCE_SECOND_SIR: 'Anticipa Franchigia Liquidatore secondo SIR',
        _SECOND_SIR_RESIDUE: 'Residuo Secondo SIR ',
        _CLAIM_DATA: 'Dati sinistro ',
        _NOTIFY_DATE: 'Data notifica',
        _SEVERITY_CODE: 'Codice gravità lesione ',
        _PHISICAL_INJURY_CODE: 'Codice lesioni ',
        _CASHED: 'Pagato',
        _COLLECTED: 'Incassato',
        _FISCAL_TAX_REGIME: 'Regime fiscale',
        _GENERATE_PAYMENT: 'Genera pagamento',
        _INDEMNITY_COST: 'Spese di indennizzo',
        _LEGAL_CODE: 'Codice legale',
        _NEW_INVOICE: 'Nuova fattura',
        _PARTY_CODE: 'Codice compagnia',
        _PRESENCE_LAWYERS: 'Presenza Legali Avversari',
        _PROTOCOLLO_SAP: 'Protocollo SAP',
        _PROTOCOLLO_SDI: 'Protocollo SDI',
        _RECEIPTS: 'Quietanze',
        _RESISTANCE_COST: 'Spese di resistenza',
        _SETTLEMENT_COST: 'Importo Riserva Spese di Liquidazione',
        _SETTLEMENTS: 'Liquidazioni',
        _TOTAL_AMOUNT: 'Importo totale',
        _PAYMENTS_FIND: 'Trova pagamento',
        _APPLY_PERCENTAGE_LIABILITY_FORCING: 'Applica forzatura percentuale responsabilità (civilistica)',
        _DAMAGE_CARD: 'Scheda danno',
        _SEND_TO: 'Inviato a',
        _CURRENT_RESERVE: 'Riserva corrente',
        _OPENING_DUE_TO_WRIT_OF_SUMMONS: 'Apertura a seguito di atto di citazione',
        _NOTES: 'Note',
        _COMPENSATIONS: 'Rivalse',
        _MAGIC_STRING: 'Magic string',
        _INVOLVED_PARTIES__DAMAGE_CAUSE: 'Attori coinvolti/ Causa danni',
        _SETTLEMENT_COUNTERS: 'Contatore per liquidatori',
        _TYPE_OF_LOSS_COUNTERS: 'Contatore per causali',
        _TRUSTEE_DATA: 'Dati fiduciario',
        _SUBJECT: 'Soggetto',
        _ROSTER_REGISTRATION_CODE: 'Codice iscrizione a ruolo',
        _COURT_REGISTRATION_DATE: 'Data iscrizione a ruolo',
        _TAX_OFFICE: 'Sede fiscale',
        _ASSOCIATED_TRUSTEES: 'Fiduciari associati',
        _SUBJECT_IDENTITY: 'Identificativo soggetto',
        _TYPE_PROVIDER: 'Fornitore',
        _OFFICE_HEADQUARTERS: 'Sede Ufficio',
        _COUNTRY: 'Nazione',
        _SEARCH_FILTERS: 'Filtri di ricerca',
        _VAT_NUMBER: 'Partita iva',
        _EXPERT_TYPE_PROVIDER: 'Expert Type Provider',
        _DATE_OF_INSERTION: 'Data inserimento',
        _UPDATE_TYPE: 'Tipo aggiornamento',
        _TYPOLOGY: 'Typology',
        _CREATION_DATE: 'Data di creazione',
        _GROUP: 'Gruppo',
        _BY_WORKLOAD: 'Per carico di lavoro',
        _USERS_ASSIGNABLE_TASKS: 'Attività assegnabili agli utenti',
        _ORIGIN: 'Provenienza',
        _LAST_UPDATE: 'Ultimo aggiornamento',
        _CANCELED_POLICY: 'Policy eliminata',
        _EMISSION_DATE: 'Data emissione',
        _POLICY_REPLACED_NUMBER: 'Nr. Polizza sostituita',
        _POLICY_REPLACEMENT_NUMBER: 'Nr. polizza sostituente',
        _SUSPENSION_DATE_FROM: 'Data inizio sospensione',
        _SUSPENSION_DATE_TO: 'Data fine sospensione',
        _PREMIUM_DATE_FROM: 'Premio da',
        _PREMIUM_DATE_TO: 'Premio a',
        _OUT_OF_RANGE: 'Fuori copertura',
        _DIARIA_DAYS: 'Giorni diaria',
        _ARREARS_DAYS: 'Nr. giorni di mora',
        _PRODUCT_CODE: 'Codice prodotto',
        _PRODUCT_DESCRIPTION: 'Descrizione prodotto',
        _DIARIA: 'Diaria',
        _LOCATION: 'Ubicazione',
        _TERM_CODE: 'Codice clausola',
        _DAYS: 'Giorni',
        _SELECTABLE: 'Selezionabile',
        _POSTAL_CODE: 'Codice postale',
        _POLICY_CONDITION: 'Policy condition',
        _POLICY_CEILING: 'Massimale di polizza',
        _MINIMUM_UNCOVERED_VALUE: 'Valore minimo scoperto',
        _SEARCH_RESULTS: 'Cerca risultati',
        _UPDATE_DATE: 'Data aggiornamento',
        _EXCESS: 'Franchigia',
        _BRANCH: 'Ramo',
        _SURNAME__BUSINESS_NAME: 'Cognome/Ragione sociale',
        _PERSON_TYPE: 'Tipo persona',
        _LEGAL_PERSON: 'Persona giuridica',
        _EDIT_SUBJECT: 'Modifica anagrafica',
        _UPDATE_RESERVE: 'Modifica riserva',
        _REOPENING_DATA: 'Dati Riapertura',
        _REOPENING_REASON: 'Motivo Riapertura',
        _SETTLEMENT_COSTS: 'Importo Riserva Spese di Liquidazione',
        _VIEW_FOR_ACTORS: 'Vista per Attori',
        _NEW_MANUAL_DEADLINE: 'Nuovo Reminder Manuale',
        _QUOTA_AMOUNT: 'Quota Importo',
        _AMOUNT_ACTIVE: 'Riserva attiva',
        _AMOUNT_RESERVE_QUOTA: 'Riserva attiva quota',
        _OPENING_DATE: 'Data apertura',
        _CLOSING_DATE: 'Data chiusura',
        _REFUSED_DATE: 'Data annullo',
        _COMPENSATION_DIARY_N: 'Diario Rivalsa N.',
        _NEW_NOTE: 'Nuova nota',
        _EXTRA_INFO: 'Extra info',
        _NO_DATA: 'Nessun dato',
        _CONV_TYPE: 'Tipo Convenzione',
        _INVALID_PARAMETER_FORM: 'Modulo parametro non valido',
        _NO_PARTIES: 'Nessun soggetto presente',
        _SELECT_ACTOR_ROLE: 'Seleziona ruolo attore',
        _NEW_ACTOR_LIST: 'Lista attori',
        _ASSETS_LIST: 'Lista Beni',
        _DAMAGED_ASSET: 'Bene Danneggiato',
        _ACTOR_ROLE: 'Ruolo attore',
        _SOLD: 'Venduto',
        _RISK_FACTORS: 'Fattori di rischio',
        _THING: 'Cose',
        _VEHICLE_ASSET: 'Veicolo',
        _EVENT_RISK: 'Rischio evento',
        _EVENT: 'Evento',
        _NO_REASON_MEETS_THE_SEARCH_CRITERIA: 'Nessuna causale soddisfa i criteri di ricerca',
        _RESIDENCE: 'Residenza',
        _DOMICILE: 'Domicilio',
        _ADD_NEW_ADDRESS: 'Aggiungi nuovo indirizzo',
        _DIALOGUE_DESCRIPTION: 'Descrizione Dialogo',
        _INSURED_PLATE_NUMBER: 'Targa assicurato',
        _COMPANY_CODE: 'Codice Compagnia',
        _INVOLVED_PARTY_IDENTIFIER: 'Involved Party Identifier',
        _INVOLVED_PARTY_KEY: 'Involved Party Key',
        _NUMBER_REQUEST: 'Number Request',
        _RESPONSE_IN: "Risposta (Ricevuto)",
        _REQUEST_OUT: "Richiesta (Inviato)",
        _REQUEST_IN: "Richiesta (Ricevuto)",
        _RESPONSE_OUT: "Risposta (Inviato)",
        _RESPONSE_INPUT: "Risposta (Ricevuto)",
        _REQUEST_INPUT: "Richiesta (Ricevuto)",
        _REQUEST_OUTPUT: "Richiesta (Inviato)",
        _RESPONSE_OUTPUT: "Risposta (Inviato)",
        _SIMILAR_DIALOGUES: "Dialoghi Simili",
        _CANCEL_MESSAGE: 'Cancellato con successo!',
        _CF_PIVA: 'CF/PIVA',
        _LOSS_DATE: 'Data Sinistro',
        _VERSION: 'Versione',
        _CODE_MESSAGE: 'Codice messaggio',
        _PAYMENT_DELETE_CHECK_QUESTION: 'Sei sicuro di voler rimuovere il seguente elemento dalla tabella?',
        _ERROR_DURING_SAVING: 'Errore durante il salvataggio!',
        _NOT_CORRECT_ENTRY_DATA: 'I dati inseriti non sono corretti!',
        _WRONG_DATES: 'Le date inserite non sono valide!',
        _MANDATORY_FUNCTION_CODE: 'Codice funzione obbligatorio!',
        _INCORRECT_AMOUNT: "L'importo inserito non è valido!",
        _ALREADY_EXISTS_RECORD: "Esiste già un record con i medesimi dati nel periodo di tempo indicato",
        _NEW_FORFAIT: 'Nuovo forfait',
        _PAYMENTS: {
            _IDRECORDFLOW: 'Id record flusso',
            _CODFUNCTION: 'Codice Funzione',
            _CODE_COMPANY: 'Compagnia',
            _LOTTO: 'Lotto',
            _SEQUENCE: 'Sequenza',
            _REGISTERTYPE: 'Tipo registro',
            _CODMESSAGE: 'Messaggio',
            _SENDINGFLOWDATE: 'Data ricezione',
            _YEAR: 'Anno',
            _MONTH: 'Mese',
            _OPERATIONCENTRE: 'Centro operativo',
            _OPERATIONCENTRECNT: 'Centro operativo controparte',
            _COMPANYCODEUS: 'Codice compagnia',
            _COMPANYCODECNT: 'Codice compagnia controparte',
            _INTERNALKEY: 'Chiave interna',
            _EXTERNALKEY: 'Chiave esterna',
            _LOSSDATE: 'Data sinistro',
            _ROLE: 'Ruolo',
            _ERRORCODE: 'Codice errore',
            _ERROR: 'Errore',
            _INTERNALKEYDLG: 'Chiave interna dialogo',
            _ROLEDLG: 'Ruolo dialogo',
            _PAVEEDLG: 'Attore - Tipo causale',
            _CLAIMNUMBERDLG: 'Numero sinistro dialogo',
            _COMPANYCTRDLG: 'Compagnia controparte dialogo',
            _LOSSDATEDLG: 'Data sinistro dialogo',
            _PLATECTRDLG: 'Targa controparte dialogo',
            _SETTLEMENTNUMBER: 'Numero liquidazione',
            _SETTLEMENTDATE: 'Data liquidazione',
            _PLATEDLG: 'Targa dialogo',
            _IMPORTFORFAIT: 'Importo forfait',
            _PAYMENTTYPE: 'Tipo pagamento',
            _FORCED: 'Forza',
            _SKIPPED: 'Salta',
            _DINS: 'Data inserimento',
            _UNIQUECODE: 'Codice univoco',
            _PAYMENTCOLLECTION: 'Pago o recupero',
            _PAY: 'Pago',
            _RECOVER: 'Recupero',
            _TOTALRECORD: 'Totale record',
            _TOTALRECORDKO: 'Totale record KO',
            _TOTALRECORDOK: 'Totale record OK',
            _TOTALAMOUNTPAYMENTS: 'Importo totale pagamenti',
            _TOTALAMOUNTRECOVERED: 'Importo totale recuperi'
        },
        _TYPE_LICENSE_PLATE_NUMBER_US: 'Tipo targa assicurato',
        _TYPE_LICENSE_PLATE_NUMBER_CTP: 'Tipo targa controparte',
        _LST_MES_SEND_DATA: 'Data risposta',
        _NO_DELEGATION: 'Nessuna',
        _OUR_DELEGATION: 'Delega Nostra',
        _THIRD_PARTY_DELEGATION: 'Delega Terzi'
    }
};
