<div [formGroup]="formGroup" data-qa="life-trend-detail" *ngIf="lifeTrendVisible">

  <div class="row" id="funds-row">

    <div class="col-md-8">

      <div class="col-md-12 life-detail-margin-box life-detail-box z-1" id="consulting-detail">
        <div class="row life-detail-card-title-container">
          <div class="row life-detail-title">
            <div class="card-title">
              <span class="glyphicon glyphicon-circle-arrow-left arrow-precise-pos" style="cursor: pointer;"
                (click)="back()" data-qa="back-arrow"></span>
              <span class="separetor"> | </span>
              <span class="menu-title" data-qa="policy-trend-title" translate>lpc_policy_trend</span>
            </div>
          </div>
        </div>

        <div class="col-md-12 life-detail-first-info">
          <div class="col-md-5 life-detail-img">
            <div class="hidden-xs hidden-sm anag-user-ico">
              <span class="rgifont life-detail-icon-padding rgi-folder"></span>
            </div>
          </div>
          <div class="col-md-7 life-detail-general-info">

            <div class="container-fluid anag-data">
              <div class="volatility-trend-date">
                <pnc-datepicker   [attr.data-qa]="'trend-volatilita-date'" [customFormGroup]="formGroup" [label]="label"
                  customFormControlName="date" (change)="onChange($event)" [required]="true"
                  (dateSelect)="onChange($event)">
                </pnc-datepicker>
                <div class="recalulate-volatility" data-qa="trend-volatilita-calculate">
                  <button [disabled]="!enablePolicyTrendButton" style="margin-top:25px" class="btn btn-primary" (click)="onClick()" translate>lpc_calculate</button>
                </div>
              </div>
              <div class="col-lg-12">
                <pnc-form-message type="error" [messages]="errors" *ngIf="errors?.length >0" detail="validationError"
                  data-qa="trend-validation-error-messages">
                </pnc-form-message>
              </div>
              <div class="row" *ngIf="showInfo">
                <div class="col-md-12">
                  <span class="tbld_col_title" data-qa="policy-value" translate>lpc_valore_polizza</span>
                  <span class="tbld_col_title">: </span>
                  <span class="tbld_col_value" data-qa="net-amount">{{getGrossAmount().toString() | rgiCtryLNumFormatter :  '' : currencyFormatterOptions}}</span>
                </div>
                <div class="col-md-12">
                  <span class="tbld_col_title" data-qa="paid-amount" translate>lpc_paidAmount</span>
                  <span class="tbld_col_title">: </span>
                  <span class="tbld_col_value"
                    data-qa="paid-amount-value">{{consultation.paidAmount | rgiCtryLNumFormatter :  '' : currencyFormatterOptions}}</span>
                </div>
                <div class="col-md-12">
                  <span class="tbld_col_title" data-qa="total-gross-surrender" translate>lpc_total_gross_surrender</span>
                  <span class="tbld_col_title">: </span>
                  <span class="tbld_col_value" data-qa="total-gross-surrender-value">{{consultation.totalGrossSurrender | rgiCtryLNumFormatter :  '' : currencyFormatterOptions}}</span>
                </div>
                <div class="col-md-12">
                  <span class="tbld_col_title" data-qa="total-gross-surrender" translate>lpc_left_premiums_paid</span>
                  <span class="tbld_col_title">: </span>
                  <span class="tbld_col_value" data-qa="total-gross-surrender-value">{{ consultation.leftPaidAmount | rgiCtryLNumFormatter :  '' : currencyFormatterOptions}}</span>
                </div>
                <div class="col-md-12">
                  <span class="tbld_col_title" data-qa="left-iinvested-amount" translate>lpc_left_premiums_amount</span>
                  <span class="tbld_col_title">: </span>
                  <span class="tbld_col_value" data-qa="left-invested-amount-value">{{getLeftInvestedAmount().toString()  | rgiCtryLNumFormatter :  '' : currencyFormatterOptions}}</span>
                </div>
                <div class="col-md-12" *ngIf="consultation.volatilityCalculated">
                  <span class="tbld_col_title" data-qa="portfolio-volatility-subscription" translate>lpc_portfolio_volatility_on_subscription_date</span>
                  <span class="tbld_col_title">: </span>
                  <span class="tbld_col_value"
                  data-qa="portfolio-volatility-subscription-value" >{{consultation.volatilityAtSubscription | toPercentage}}</span>
                </div>
                <div class="col-md-12" *ngIf="consultation.volatilityCalculated">
                  <span class="tbld_col_title" data-qa="portfolio-volatility-date" translate>lpc_portfolio_volatility</span> <span class="tbld_col_title">
                    {{date}}</span>
                  <span class="tbld_col_title">: </span>
                  <span class="tbld_col_value" data-qa="portfolio-volatility-date-value">
                    {{consultation.volatilityAtDate | toPercentage}}</span>
                </div>
              </div>

            </div>
          </div>
        </div>

        <div class="col-lg-12">
          <pnc-form-message type="error" [messages]="[getEffectDateError()]" *ngIf="!enablePolicyTrendButton && !!getEffectDateError()"
                            detail="validationError" data-qa="policy-trend-validation-error-messages">
          </pnc-form-message>
        </div>
      </div>
      <div class="col-md-12 life-detail-margin-box life-detail-box" id="consulting-detail">
        <div class="row life-detail-card-title-container">
          <div class="row life-detail-title">
            <div class="card-title">
              <span data-qa="chart-detail-title" class="menu-title" translate>lpc_andamento_controvalore</span>
            </div>
          </div>
        </div>

        <div class="col-md-12" style="margin-top: 30px; margin-bottom: 30px" formGroupName="chartForm">
          <div class="col-md-3" data-qa="data-inizio-andamento-grafico">
            <pnc-datepicker [attr.data-qa]="'start-date'" [customFormGroup]="formGroup.get('chartForm')"
              [label]="'lpc_start_date' | translate" customFormControlName="startDate" (change)="onChangeStartDate($event)"
              [required]="true" (dateSelect)="onChangeStartDate($event)">
            </pnc-datepicker>
          </div>
          <div class="col-md-3" data-qa="data-fine-andamento-grafico">
            <pnc-datepicker [attr.data-qa]="'end-date'" [customFormGroup]="formGroup.get('chartForm')"
              [label]="'lpc_end_date' | translate" customFormControlName="endDate" (change)="onChangeEndDate($event)"
              [required]="true" (dateSelect)="onChangeEndDate($event)">
            </pnc-datepicker>
          </div>
          <div class="col-md-3" data-qa="periodicita-andamento-grafico">
            <label class="label-core" translate>lpc_frequency</label>
            <lpc-required required="true | translate"></lpc-required>
            <ng-select [attr.data-qa]="'frequency'" bindLabel="name" formControlName="frequency">
              <ng-option *ngFor="let frequency of frequencies" [value]="frequency.id">
                {{frequency.description}}
              </ng-option>
            </ng-select>
          </div>
          <div class="col-md-3">
            <div class="col-md-6" data-qa="trend-chart-calculate">
              <button style="margin-top:25px" class="btn btn-primary" (click)="updateChart()"
                translate>lpc_calculate</button>
            </div>
          </div>

        </div>

        <lpc-funds-chart *ngIf="showChart()" [lineChartData]="lineChartData"
                         [lineChartLabels]="lineChartLabels"
                         [lineChartLegend]="lineChartLegend"
                         [lineChartType]="lineChartType"
                         [lineChartColors]="lineChartColors"
                         [lineChartPlugins]="lineChartPlugins"
                         [lineChartOptions]="lineChartOptions">
        </lpc-funds-chart>

        <div class="col-lg-12">
          <pnc-form-message type="error" [messages]="chartErrors" *ngIf="chartErrors?.length >0"
            detail="validationError" data-qa="chart-validation-error-messages">
          </pnc-form-message>
        </div>
      </div>
    </div>
  </div>
</div>
