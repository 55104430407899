<div data-qa="mic-navigation-wrapper" class="portal-navigation-wrapper">
    <div>
      <div class="amount-header">
        <div class="header-amount" translate>lic_investment_value</div>
      </div>
      <div class="amount-body" style="margin: 25px 0 10px 0">
        <span translate>lic_investment_value</span>
        <span> {{investAmount.toString()  | rgiCtryLNumFormatter : locale : formatterOption}}</span>
      </div>
    </div>
    <lic-stepper (stepSlide)="onSlide($event)" [formGroup]="formGroup">
      <lic-step label="{{'lic_investment_profile' | translate}}" data-qa="investment-profile" [open]="activeStep>0" [disabled]="activeStep === 3 || skipProfiles">
        <div style="margin: 25px 10px 0 0" *ngIf="ready">
          <!-- <span translate>lic_selected_profiles_:​</span> -->
          <lic-profiles
              [active]="activeStep===0"
              [totalAmount]="investAmount"
              [definition]="karmaProfileDefinitions"
              formControlName="profiles"
              [sliderProperty]="getSliderProperty()">
          </lic-profiles>
        </div>
        <pnc-form-message *ngIf="!!profileWarnings?.length"
                            type="warning"
                            [messages]="profileWarnings"
                            detail="warningMessages"
                            data-qa="warning-error-messages">
        </pnc-form-message>
        <lic-navigation-buttons
            (next)="onNext(INVEST_STEPS.PROFILES.context)"
            [backVisible]="false"
            [qa]="'invest-profile'"
            [nextVisible]="true"
            [context]="INVEST_STEPS.PROFILES.context"
            [errors]="stepErrors"
            *ngIf="activeStep===0 && formGroup.get(INVEST_STEPS.PROFILES.formName).valid && totalProfileAllocation === 1">
        </lic-navigation-buttons>
      </lic-step>
  
      <lic-step label="{{'lic_investment_funds' | translate}}" data-qa="investment-funds" [open]="activeStep>1" [disabled]="activeStep === 3 || skipFunds">
        <div style="margin-top: 25px" formGroupName="funds">
          <lic-funds *ngFor="let profileDefinition of selectedProfileDefinitions"
            [active]="activeStep===1"
            [totalAmount]="getInvestmentAmountOfProfile(profileDefinition, 'investment')"
            [definition]="profileDefinition"
            [formControlName]="profileDefinition.id"
            [sliderProperty]="getSliderProperty()"
            [showSliderInput]="true">
          </lic-funds>
        </div>
        <div *ngIf="activeStep > 1">
          <lic-karma-fund-table
            [profiles]="getProfilesAndFundsForSummary()">
          </lic-karma-fund-table>
        </div>
        <div *ngIf="areThereErrorMessagesFromServices()">
          <div class="service-message">
            <div class="service-message-error" data-qa="message-service-error" *ngIf="errorMessagesFromServices[0].level === ERROR_MESSAGE_FROM_SERVICE_LEVEL.error">
              <span translate>lic_information</span><span>:</span>
              <span> {{ errorMessagesFromServices[0].description }}</span>
            </div>
            <div class="service-message-warning" data-qa="message-service-warning" *ngIf="errorMessagesFromServices[0].level === ERROR_MESSAGE_FROM_SERVICE_LEVEL.warning">
              <span translate>lic_information</span><span>:</span>
              <span> {{ errorMessagesFromServices[0].description }}</span>
            </div>
          </div>
        </div>
        <div *ngIf="(withVolatility || withSustainability) && formGroup.get('funds').valid && !areThereErrorMessagesFromServices()"
             style="clear:both">
          <lic-finantial-param-calculator
                        (calculate)="onCalculateFinantialParam()"
                        [volatility]="assetVolatility && assetVolatility.volatility"
                        [_sustainability]="sustainability"
                        [label]="'Calculate Finantial parameters' | licFinantialParamCalculatorButton : withVolatility : withSustainability">
          </lic-finantial-param-calculator>
  
  
          <pnc-form-message
              *ngIf="!!warningCalculationErrors?.length"
              type="warning"
              [messages]="warningCalculationErrors"
              detail="warningMessages"
              data-qa="warning-error-messages">
          </pnc-form-message>
  
           <pnc-form-message
              type="error"
              [messages]="criticalCalculationErrors"
              *ngIf="!!criticalCalculationErrors?.length"
              detail="validationError"
              data-qa="validation-error-messages">
          </pnc-form-message>
        </div>
        <lic-navigation-buttons
              (next)="onNext(INVEST_STEPS.FUNDS.context)"
              [backVisible]="false"
              [qa]="'invest-funds'"
              *ngIf="activeStep<2"
              [errors]="stepErrors"
              [context]="INVEST_STEPS.FUNDS.context"
              [nextVisible]="isFinantialParamsCorrect && formGroup.get(INVEST_STEPS.FUNDS.formName).valid && !hasCriticalCalculationErrors">
        </lic-navigation-buttons>
      </lic-step>
  
      <lic-step *ngIf="isScheduledPremiumActive()" label="{{'lic_additional_payment' | translate}}"  data-qa="additional-payment" [open]="activeStep>2"  [disabled]="isDistributionSameAsInitial() && activeStep === 3" formGroupName="scheduledPremium">
        <lic-scheduled-premium
          [formGroup]="formGroup.get('scheduledPremium')"
          [active]="activeStep"
          [karmaProfileDefinitions]="karmaProfileDefinitions"
          [selectedScheduledProfileDefinitions]="selectedScheduledProfileDefinitions"
          [mainProposal]="mainProposal"
          [isActive]="this.activeStep === 2"
          [isScheduledPremiumActive]="isScheduledPremiumActive()"
          [isDistributionSameAsInitial]="isDistributionSameAsInitial()"
          [sliderProperty]="getSliderProperty()">
        </lic-scheduled-premium>
        <lic-navigation-buttons
          (next)="onNext(INVEST_STEPS.SCHEDULED_PREMIUM.context)"
          [backVisible]="false"
          [qa]="'scheduled-premium'"
          [nextVisible]="isScheduledPremiumComplete"
          [context]="INVEST_STEPS.SCHEDULED_PREMIUM.context"
          [errors]="stepErrors"
          *ngIf="activeStep<3">
      </lic-navigation-buttons>
      </lic-step>
  
      <lic-step label="{{'lic_contractual_options' | translate}}" data-qa="contractual-options" [open]="activeStep>2" [disabled]="activeStep === 3" formGroupName="tools">
        <!-- checkbox accordion -->
        <ng-container *ngIf="toolOptions && toolOptions.length>0">
          <ng-container *ngFor="let toolOption of toolOptions">
            <lic-tool-with-accordion *ngIf="toolOption.isToolBeingFullyHandled"
              (toolCheck)="onToolClick(toolOption.id, $event)"
              [toolOption]="toolOption"
              [funds]="formGroup.get(INVEST_STEPS.FUNDS.formName).value"
              [investAmount]="getInvestmentAmountByTool(toolOption.id, investAmount)"
              [definition]="toolsDefinitionMap.get(toolOption.id)"
              [formControlName]="toolOption.id"
              [investedProfiles]="getProfilesAndFundsForSummary()">
            </lic-tool-with-accordion>
          </ng-container>
        </ng-container>
        <div *ngIf="!toolOptions.length" style="margin: 25px 0 10px 15px">
          <span translate>lic_contractual_option_not_allowed</span>
        </div>
        <pnc-floating-error-messages  [warningMsgs]="getWarningMessages" [authoMsgs]="getAuthMessages" [blockingMsgs]="getBlockingMessages"></pnc-floating-error-messages>
        <lic-navigation-buttons
            (next)="next()"
            (save)="save(proposalSaveModal)"
            [backVisible]="false"
            [saveVisible]="showSaveButton()"
            [qa]="'invest-confirm'"
            *ngIf="activeStep<4 && formGroup.get(INVEST_STEPS.TOOLS.formName).valid"
            [context]="INVEST_STEPS.TOOLS.context"
            [nextVisible]="true"
            [errors]="stepErrors">
        </lic-navigation-buttons>
  
        <!-- Dialog per il salvataggio intermedio della proposta -->
        <rgi-rx-modal #proposalSaveModal>
          <rgi-rx-panel>
            <rgi-rx-panel-header [closeable]="true" (onClose)="proposalSaveModal.close()">{{'lic_NumeroProposta' | translate}}</rgi-rx-panel-header>
            <rgi-rx-panel-footer>
              <button class="rgi-ui-btn rgi-ui-btn-secondary" (click)="proposalSaveModal.close()" translate>lic_close</button>
            </rgi-rx-panel-footer>
            <span translate>lic_Proposal_Saved</span> {{proposalNumber}}
          </rgi-rx-panel>
        </rgi-rx-modal>
      </lic-step>
    </lic-stepper>
  
  </div>