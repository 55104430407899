import { PolicyService } from './../../../services/policy-service';
import { Component,
  EventEmitter, forwardRef, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { AbstractControl,
  ControlValueAccessor, UntypedFormControl, UntypedFormGroup, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors, Validator } from '@angular/forms';
import { Subscription } from 'rxjs';

import { LicObjectUtils } from '../../../utils/lic-object-utils';
import { KarmaFundDefinition } from '../../model/karma-fund-definition';
import {FormatterUtils} from '../../../invest-standalone-session/utils/FormatterUtils';
import { FundToggleEvent, SliderProperty } from '../../model/karma-fund';
import { PROPERTY_VALUE } from '../../../enum/life-issue.enum';
import { RgiCtryLayerNumFormatterPipe, RgiCtryLayerSymbolFormatterPipe } from '@rgi/rgi-country-layer';
@Component({
  selector: 'lic-karma-fund-element',
  templateUrl: './lic-karma-fund-element.component.html',
  styleUrls: ['./lic-karma-fund-element.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => LicKarmaFundElementComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => LicKarmaFundElementComponent),
      multi: true
    }, RgiCtryLayerNumFormatterPipe, RgiCtryLayerSymbolFormatterPipe
  ]
})
export class LicKarmaFundElementComponent implements OnInit, OnChanges, OnDestroy, ControlValueAccessor, Validator {

  public get isPercent(): boolean {
    return this.formGroup.get('isPercent').value;
  }

  currencyCode = '€';

  @Input() public definition: KarmaFundDefinition;
  @Input() public totalAmount: number;
  @Input() public sliderProperty: SliderProperty;
  @Input() public showSliderInput = true;
  @Output() public fundSelected = new EventEmitter<any>();
  @Output() public toggleChange = new EventEmitter<FundToggleEvent>();

  private $subscriptions: Subscription[] = [];

  public formGroup: UntypedFormGroup = new UntypedFormGroup({
    active: new UntypedFormControl(false),
    percent: new UntypedFormControl(0),
    amount: new UntypedFormControl(0),
    isPercent: new UntypedFormControl(true)
  });

  constructor(
    public formatter: FormatterUtils,
    protected policyService: PolicyService,
    protected rgiFormatter: RgiCtryLayerNumFormatterPipe,
    protected currencySymbolPipe: RgiCtryLayerSymbolFormatterPipe) {
      this.currencyCode = !!this.policyService.currencyCode ?
      currencySymbolPipe.transform(this.policyService.currencyCode) : this.currencyCode;
    }

  ngOnInit() {
    this.initializeInputs();
    this.$subscriptions.push(
      this.formGroup.get('percent').valueChanges.subscribe(value => {
        const amountFormValue = !!this.totalAmount ? LicObjectUtils.roundToDecimal(value * this.totalAmount / 100, 2) : 0;
        this.formGroup.get('amount').setValue( amountFormValue, { emitEvent: false } );

        this.onChange(value / 100);
        this.onTouch();
        this.fundSelected.emit(
          {
            name: this.definition.description,
            selected: true,
            value: this.setValueForSummary(value, amountFormValue)
          }
        );

      }),
      this.formGroup.get('amount').valueChanges.subscribe(value => {
        const percentFormValue =  !!this.totalAmount ? LicObjectUtils.roundToDecimal(value / this.totalAmount * 100, 2) : 0;
        this.formGroup.get('percent').setValue(percentFormValue, { emitEvent: false });
        this.onChange(this.formGroup.get('percent').value / 100);
        this.onTouch();
        this.fundSelected.emit(
          {
            name: this.definition.description,
            selected: true,
            value: this.setValueForSummary(percentFormValue, value)
          }
        );

      }),
      this.formGroup.get('active').valueChanges.subscribe(value => {
        let selected = false;
        if (value) {
          selected = true;
          if (this.definition.minPercentAllocation != null && this.definition.maxPercentAllocation != null) {
            if (this.definition.minPercentAllocation === this.definition.maxPercentAllocation) {
              if (!this.definition.percent) {
                this.formGroup.get('percent').setValue(this.definition.minPercentAllocation * 100);
              }
              this.disableInputs();
            } else {
              this.enableInputs();
            }
          } else {
            this.enableInputs();
          }
          this.addSliderMod();
        } else {
          this.formGroup.get('percent').setValue(0);
          this.formGroup.get('amount').setValue(0);
          this.disableInputs();
          selected = false;
          this.fundSelected.emit({ name: this.definition.description, selected });
        }
      })
    );
  }

  /**
   * @description
   * checks the property value. If it's 'PERC' you'll see the slider disabled at the percentage,
   * otherwise if the property is 'IMP' you'll see the slider disabled at the amount.
   * Default - percentage (when fund selected you can switch it to amount mod)
   */
  protected addSliderMod() {
    if (this.sliderProperty === PROPERTY_VALUE._MODIN.PERCENTAGE) {
      this.formGroup.get('isPercent').setValue(true);
      this.formGroup.get('isPercent').disable();
    } else if (this.sliderProperty === PROPERTY_VALUE._MODIN.AMOUNT) {
      this.formGroup.get('isPercent').setValue(false);
      this.formGroup.get('isPercent').disable();
    }
  }

  setValueForSummary(percent, amount): string {
    const type = this.sliderProperty === PROPERTY_VALUE._MODIN.PERCENTAGE ?
    'percent' :
    this.sliderProperty === PROPERTY_VALUE._MODIN.AMOUNT ?
    'currency' :
    'percent';
    const options: Intl.NumberFormatOptions = this.policyService.getFormatterOptionsWithDecimal(type, '0.2-2');
    const value = type === 'percent' ? percent / 100 : amount;
    return this.rgiFormatter.transform(value, this.policyService.currentLocale, options);
  }

  initializeInputs() {
    this.addSliderMod();
    this.disableInputs();
    if (this.definition.minPercentAllocation === this.definition.maxPercentAllocation) {
      if (!this.definition.percent) {
        this.formGroup.get('percent').setValue(0);
        this.formGroup.get('active').setValue(false, { emitEvent: false });
      } else {
        this.formGroup.get('active').setValue(true, { emitEvent: false });
        this.formGroup.disable({ emitEvent: false });
      }
    }
  }

  disableInputs() {
    this.formGroup.get('isPercent').disable({ emitEvent: false });
    this.formGroup.get('percent').disable({ emitEvent: false });
    this.formGroup.get('amount').disable({ emitEvent: false });
  }

  enableInputs() {
    this.formGroup.get('isPercent').enable({ emitEvent: false });
    this.formGroup.get('percent').enable({ emitEvent: false });
    this.formGroup.get('amount').enable({ emitEvent: false });
  }


  ngOnChanges(changes: SimpleChanges): void {
    if (changes.totalAmount) {
      if (!changes.totalAmount.currentValue) {
        this.formGroup.get('amount').setValue(
          0, { emitEvent: false }
        );
      } else {
        this.formGroup.get('amount').setValue(
          this.formGroup.get('percent').value * changes.totalAmount.currentValue, { emitEvent: false }
        );
      }
    }
  }

  writeValue(percent: number): void {
    if (!!percent) {
      this.formGroup.patchValue({
        percent: this.calculatePercentage(percent, 100),
        amount: this.calculatePercentage(percent, this.totalAmount),
      }, { emitEvent: false });
      this.formGroup.get('active').setValue(true);
      if (!!this.definition.disabled) {
        this.formGroup.get('active').disable();
        this.disableInputs();
      }
    } else {
      this.formGroup.patchValue({
        percent: 0,
        amount: 0,
      }, { emitEvent: false });
    }
  }


  protected calculatePercentage(percent: any, amount: number): number {
    if (!!percent && !!percent.percentage && amount === 100) { // calculate percent
      return percent.percentage;
    } else if (!!percent && !!percent.percentage && amount !== 100) { // calculate amount
      return (percent.percentage / 100) * amount;
    } else {
      return percent * amount;
    }
  }

  ngOnDestroy(): void {
    this.$subscriptions.forEach(s => {
      s.unsubscribe();
    });
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }


  validate(control: AbstractControl): ValidationErrors | null {
    const obj: ValidationErrors = {};
    if (!!this.formGroup.get('active').value) {
      if (this.definition.minPercentAllocation !== this.definition.maxPercentAllocation) {
        if (this.formGroup.get('percent').value / 100 > this.definition.maxPercentAllocation) {
          obj.max = { value: this.formGroup.get('percent').value / 100, limit: this.definition.maxPercentAllocation };
        } else if (this.formGroup.get('percent').value / 100 < this.definition.minPercentAllocation) {
          obj.min = { value: this.formGroup.get('percent').value / 100, limit: this.definition.minPercentAllocation };
        }
      }
    }
    return !!Object.keys(obj).length ? obj : null;
  }

  onChange(obj: number) {
  }

  onTouch() {
  }

  roundValue(field: string) {
    if (!!field && field !== '') {
      const value: number = this.formGroup.get(field).value;
      this.formGroup.get(field).setValue(LicObjectUtils.roundToDecimal(value, 2));
    } else {
      this.formGroup.get(field).setValue(undefined);
    }
  }

  checkChar(event: any, fieldType: string) {

    const validNumber = new RegExp(/^(?:\d*\.\d{0,2}|\d+)$/);

    if (!validNumber.test(event.target.value) ) {
      if (!!event.target.value && event.target.value.length > 1 && event.target.value[event.target.value.length - 1] !== '.') {
        /*
          Condition to check that the last char is not a dot(avoid to delete the dot when the user is adding the decimal part)
          This allow to write a dot like a first char, after will be parsed to float with a 0 before
        */
        let valSliced;
        for (let i = event.target.value.length - 1; i >= 0; i--) {
          /*
            This iteration start from the string's bottom and delete every position of the string that cause a NaN result
            to cover the case in which are pressed quickly the letters on the keyboard
          */
          valSliced = i > 0 ? parseFloat(event.target.value.slice(0, i)) : parseFloat(event.target.value.slice(0, i + 1));
          if (!Number.isNaN(valSliced)) {
            event.target.value = valSliced;
            break;
          } else if (i === 0) {
            event.target.value = '';
          }
        }
      } else {
         event.target.value = '';
      }
    }
  }

  onToggleClick() {
    if (this.sliderProperty === PROPERTY_VALUE._MODIN.ALLSAME) {
      const fund: FundToggleEvent = this.formGroup.getRawValue();
      fund.id = this.definition.id;
      this.toggleChange.emit(fund);
    }
  }

}
