<div data-qa="life-pip-detail-card-title life-pip-page">
    <div class="row life-detail-card-title-container life-detail-margin-box life-detail-box">
        <div class="row life-detail-title">
            <div class="card-title">
                <span class="glyphicon glyphicon-circle-arrow-left arrow-precise-pos" style="cursor: pointer;"
                    (click)="back()" data-qa="back-arrow"></span>
                <span class="menu-title" translate>{{ pipDataCardTitle }}</span>
            </div>
        </div>
    </div>

    <!--PIP General Card-->
    <ng-container *ngIf="pipDataCardTitle === 'lpc_PIP_General'">
        <div class="col-md-12 life-detail-margin-box life-detail-box" id="consulting-detail" data-qa="life-pip-general-data-table">
            <div class="tbld">
                <div class="tbld_row life-detail-tbld_row_header">
                    <div class="tbld_col tbld_col_title">
                        <span style="padding-left: 10px;" translate>lpc_PIP_General</span>
                    </div>
                    <div class="tbld_col tbld_col_title" *ngFor="let divs of [0,1,2]"></div>
                </div>
                <div class="tbld_row" *ngFor="let fraction of getPipDataRows(pipGeneralDetails)">
                    <ng-container *ngFor="let item of getFractionalizedPipData(pipGeneralDetails).get(fraction)">
                        <ng-container [ngSwitch]="item.description">
                                <ng-container *ngSwitchCase="'ageRetirement'">
                                    <div data-qa="pip-life-general-description-cell-table"
                                     class="tbld_col tbld_col_title life-detail-table-border" translate>{{ !!item.description ? "lpc_" + item.description : "" }}</div>
                                    <div data-qa="pip-life-value-description-cell-table"
                                     class="tbld_col tbld_col_value life-detail-table-border">{{ item.value }}</div>
                                </ng-container>
                                <ng-container *ngSwitchDefault>
                                    <div data-qa="pip-life-general-description-cell-table"
                                     class="tbld_col tbld_col_title life-detail-table-border" translate>{{ !!item.description ? "lpc_" + item.description : "" }}</div>
                                    <div data-qa="pip-life-general-value-cell-table"
                                     class="tbld_col tbld_col_value life-detail-table-border">{{ item.value | dataValueFormatterPipe }}</div>
                                </ng-container>
                        </ng-container>
                    </ng-container>
                </div>
            </div>
        </div>

        <div class="col-md-12 life-detail-margin-box life-detail-box" id="consulting-detail" data-qa="life-pip-fiscal-data-table">
                <div class="tbld">
                    <div class="tbld_row life-detail-tbld_row_header">
                        <div class="tbld_col tbld_col_title">
                            <span style="padding-left: 10px;" translate>lpc_fiscal_data</span>
                        </div>
                        <div class="tbld_col tbld_col_title" *ngFor="let divs of [0,1,2]"></div>
                    </div>
                    <div class="tbld_row" *ngFor="let fraction of getPipDataRows(pipFiscalDetails)" >
                        <ng-container *ngFor="let item of getFractionalizedPipData(pipFiscalDetails).get(fraction)">
                                <div data-qa="pip-life-fiscal-description-cell-table" class="tbld_col tbld_col_title life-detail-table-border" translate>{{ !!item.description ? "lpc_" + item.description : "" }}</div>
                                <div data-qa="pip-life-fiscal-value-cell-table" class="tbld_col tbld_col_value life-detail-table-border">{{ item.value | dataValueFormatterPipe }}</div>
                        </ng-container>
                    </div>
                </div>
        </div>
    </ng-container>
    <!--PIP General Card End-->

    <!--PIP Contribution Card -->
    <div *ngIf="pipDataCardTitle === 'lpc_PIP_Contribution'" class="col-md-12 life-detail-margin-box life-detail-box" id="consulting-detail"
        data-qa="life-pip-contrib-data-table">
        <table class="table-responsive table coreDT life-detail-margin-top">
            <thead>
              <tr class="head-result thDataTable life-detail-tbld_row_header">
                <th *ngFor="let header of contributionsHeaders"  translate>{{ header }}</th>
              </tr>
            </thead>
            <tbody>
              <ng-container *ngFor="let contrib of pipContribs">
                <tr class="table-row table-background">
                    <ng-container *ngFor="let item of contrib | keyvalue: originalOrder">
                            <td class="table-border" data-qa="pip-life-contrib-value-cell-table">
                                <ng-container  [ngSwitch]="item.key">
                                    <ng-container *ngSwitchCase="'percentage'">{{ item.value | rgiCtryLNumFormatter: '' : percentFormatterOptions}}</ng-container>
                                    <ng-container *ngSwitchCase="'amount'">{{ item.value | rgiCtryLNumFormatter: '' : currencyFormatterOptions}}</ng-container>
                                    <ng-container *ngSwitchDefault>{{ item.value ? item.value : '-'}}</ng-container>
                                </ng-container>
                            </td>
                    </ng-container>
                </tr>
              </ng-container>
            </tbody>
        </table>
    </div>
    <!--PIP Contribution Card End-->

    <!--PIP Transfer Card -->
    <div *ngIf="pipDataCardTitle === 'lpc_PIP_Transfer'" class="col-md-12 life-detail-margin-box life-detail-box" id="consulting-detail"
        data-qa="life-pip-contrib-data-table">
        <table class="table-responsive table coreDT life-detail-margin-top">
          <thead>
            <tr class="head-result thDataTable life-detail-tbld_row_header">
              <th *ngFor="let header of transfersHeaders"  [ngClass]="{'life-detail-table-width-22' : header === 'lpc_Note' || header === 'lpc_Type_Previous_Pension_Fund'}" translate>{{ header }}</th>
            </tr>
          </thead>
          <tbody>
            <ng-container *ngFor="let transfer of pipTransfer; let i = index">
              <tr class="table-row table-background">
                  <ng-container *ngFor="let item of transfer | keyvalue: originalOrder">
                          <td class="table-border" data-qa="pip-life-contrib-value-cell-table">
                              <ng-container  [ngSwitch]="item.key">
                                  <ng-container *ngSwitchCase="'pipFiscalDataTransfer'">
                                    <div class="flex-align-centre">
                                        <button [attr.data-qa]="'open-pip-contrib-data-button-'+i" [id]="'id'+i" (click)="toggleData(i,'id'+i)" class="btn dt-btn" [ngClass]="{'disable-button': !hasNonEmptyInfos(transfer.pipFiscalDataTransfer)}">
                                            <span [ngClass]="{'rgi-ui-icon-close-content': !isOpen[i], 'rgi-ui-icon-open-content': isOpen[i]}"></span>
                                        </button>
                                    </div>
                                  </ng-container>
                                  <ng-container *ngSwitchCase="'requestDate'">{{ item.value | dataValueFormatterPipe}}</ng-container>
                                  <ng-container *ngSwitchCase="'importTransfer'">{{ item.value | rgiCtryLNumFormatter: '' : currencyFormatterOptions}}</ng-container>
                                  <ng-container *ngSwitchDefault>{{ item.value ? item.value : '-'}}</ng-container>
                              </ng-container>
                          </td>
                  </ng-container>
              </tr>
              <tr [@fadeAnimation] class="table-row" *ngIf="openedRows.includes(i)">
                <td [attr.colspan]="transfersHeaders.length+1">
                  <ng-container *ngFor="let item of transfer.pipFiscalDataTransfer; let ix = index">
                    <rgi-rx-expansion-panel color="default" *ngIf="!!item.infos.length"  [attr.data-qa]="'open-pip-contrib-accordion-data-'+ix">
                        <div rgi-rx-expansion-panel-header>
                            <div class="row">
                              <span translate>{{item.title}}</span>
                            </div>
                        </div>
                        <ng-container rgiRxExpansionPanelContent >
                            <div class="tbld table-container">
                                <div class="tbld_row transparent-border" *ngFor="let info of getPipDataRows(item.infos)">
                                  <ng-container *ngFor="let infoItem of getFractionalizedPipData(item.infos).get(info)">
                                      <div class="tbld_col tbld_col_title life-detail-table-border" [attr.data-qa]="'pip-life-fiscal-details-description-cell-table-'+infoItem.label" translate>{{infoItem.label}}</div>
                                      <div class="tbld_col tbld_col_value life-detail-table-border" [attr.data-qa]="'pip-life-fiscal-details-value-cell-table-'+infoItem.label">{{infoItem.value}}</div>
                                  </ng-container>
                                </div>
                            </div>
                        </ng-container>
                    </rgi-rx-expansion-panel>
                  </ng-container>
                </td>
                </tr>
            </ng-container>
          </tbody>
        </table>
    </div>
    <!--PIP Transfer Card End-->

    <!-- PIP Advance Payment -->
    <div *ngIf="pipDataCardTitle === 'lpc_PIP_AdvancedPayment'" class="col-md-12 life-detail-margin-box life-detail-box" id="consulting-detail" data-qa="life-pip-advance-payment">
      <table class="table-responsive table coreDT life-detail-margin-top">
        <thead>
          <tr class="head-result thDataTable life-detail-tbld_row_header">
            <th *ngFor="let header of adavancePaymentHeaders">{{ 'lpc_' + header | translate}}</th>
          </tr>
        </thead>
        <tbody>
          <tr class="table-row table-background" *ngFor="let singleObj of pipAdvancedPayments">
            <td *ngFor="let header of adavancePaymentHeaders" class="table-border" [attr.data-qa]="header">{{ singleObj[header] | dataValueFormatterPipe }}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <!-- PIP Advance Payment End -->

  <!-- PIP Undeducted Premiums -->
  <div *ngIf="pipDataCardTitle === 'lpc_PIP_UndeductedPremiums'" class="col-md-12 life-detail-margin-box life-detail-box" id="consulting-detail" data-qa="life-pip-undeducted-premiums">
    <table class="table-responsive table coreDT life-detail-margin-top">
      <thead>
      <tr class="head-result thDataTable life-detail-tbld_row_header">
        <th *ngFor="let header of undeductedPremiumsHeaders">{{ 'lpc_' + header | translate}}</th>
      </tr>
      </thead>
      <tbody>
      <tr class="table-row table-background" *ngFor="let singleObj of pipUndeductedPremiums">
        <td *ngFor="let header of undeductedPremiumsHeaders" class="table-border" [attr.data-qa]="header">{{ singleObj[header] }}</td>
      </tr>
      </tbody>
    </table>
  </div>
  <!-- PIP Undeducted Premiums End -->
</div>
