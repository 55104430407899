import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { Fund, FundsDetail, Revaluation } from '../../../../models/life-detail.model';
import { RgiCtryLayerNumFormatterPipe } from '@rgi/rgi-country-layer';

@Component({
  selector: 'lpc-single-fund-detail',
  templateUrl: './single-fund-detail.component.html',
  styleUrls: ['./single-fund-detail.component.css'],
  providers: [RgiCtryLayerNumFormatterPipe]
})
export class SingleFundDetailComponent implements OnInit {
  // formatter Options
  public numberFormatterOptions: Intl.NumberFormatOptions = {
    style: 'decimal'
  };
  public percentFormatterOptions: Intl.NumberFormatOptions = {
    style: 'percent',
    maximumFractionDigits: 2,
    minimumFractionDigits: 2
  };

  @Input() fundsDetail: FundsDetail;
  @Input() fund: Fund;
  @Input() isFromConsultation: boolean;
  @Input() revaluationRates: Revaluation[];
  public lastRatesProfit;

  constructor(
    protected rgiNumberFormatter: RgiCtryLayerNumFormatterPipe,
    protected datePipe: DatePipe
    ) { }

  ngOnInit() {
    if (!!this.revaluationRates && !!this.revaluationRates.length) {
      this.lastRatesProfit = this.rgiNumberFormatter.transform(
          (this.revaluationRates[this.revaluationRates.length - 1].value).toString(), '', this.percentFormatterOptions);
    }
  }


  public getVolatilityValue(fundsDetail: FundsDetail): string {
    if (!!fundsDetail && !!fundsDetail.lastVolatilityDate) {
      const volatilityValue = !!fundsDetail.volatility ? fundsDetail.volatility : null;

      if (null != volatilityValue) {
        return this.rgiNumberFormatter.transform(volatilityValue.toString(), '', this.percentFormatterOptions);
      }
    }
    return '-';
  }

  public getLastVolatilityDate(fundsDetail: FundsDetail): string {
    if (!!fundsDetail && !!fundsDetail.lastVolatilityDate) {
      return this.datePipe.transform(fundsDetail.lastVolatilityDate, 'dd/MM/yyyy');
    }

    return '-';
  }

}
