<lpc-stepper [formGroup]="formGroup" (stepSlide)="onSlide($event)" [isPublished]="publishMessage !== null">
    <lpc-step id="dates" label="lpc_date_variation_step" [errors]="errors" fieldId="dates" (next)="onNext($event)">
        <lpc-dates-step
          [inputFields]="dateDefinitions"
          [formControlName]="'dates'"
          (dateChange)="onDateChange($event)"
          (reloadRequest)="updateData()"
          [hide]="activeStep<1">
        </lpc-dates-step>
      </lpc-step>

      <lpc-step *ngIf="showSectionQuestFinAfterInit && showQuestionnaireByType('FIN')" id="questsFin" [hidden]="!validQuestsCode.get('FIN')" [jump]="!validQuestsCode.get('FIN')" fieldId="questsFin" label="lpc_financial_questionnaire_step" (next)="onNext($event)">
        <lpc-questionnaire
          #financialQuestionnaire
          [active]="!isAfterId('questsFin')"
          [key]="composeKey(key, 'questsFin')"
          [questionnairesCode]="getQuestMap('FIN')"
          type="FIN"
          [factors]="questFactorsArray"
          [disabledQuestions]="disabledQuestionArray"
          [date]="effectiveDate"
          [savedIds]="questionnairesFromAuthorization"
          [productCode]="productCode"
          (loaderQuest)="loaderQuestManager($event)"
          (loaded)="countQuest($event)">
        </lpc-questionnaire>
      </lpc-step>

      <lpc-step id="factors" *ngIf="hasProductFactors" label="lpc_variation_step_label" [errors]="errors" fieldId="factors" (next)="onNext($event)">
        <div formGroupName="factors" class="row">
          <lpc-factor-adapter
            #factorAdapter
            (changeValEmitter)="updateFactors($event)"
            [form]="formGroup.controls['factors']"
            [factors]="listProductFactor">
          </lpc-factor-adapter>
        </div>
      </lpc-step>

      <lpc-step *ngIf="showDisinvestment" [errors]="errors"  id="disinvestmentProfiles" label="lpc_disinvestment_profile_step" [jump]="skipDisinvestment" [nextVisible]="formGroup.get('disinvestmentProfiles').valid" fieldId="disinvestmentProfiles" (next)="onNext($event)">
        <lpc-karma-disinvestment-full-funds *ngIf="isFreeManagement && isActiveStep('disinvestmentProfiles')"
                [investmentMode]="getDisinvestmentMode()"
                formControlName="disinvestmentProfiles"
                [definition]="disinvestmentProfiles"
                [investedProfiles]="investedProfiles"
                [isFullPreval]="true"
                [isFreeManagement]="isFreeManagement"
                [totalAmount]="totalAmountToInvest"
                [checksOnOverallAlloc]="true">
        </lpc-karma-disinvestment-full-funds>
        <lpc-karma-disinvestment-funds *ngIf="!isFreeManagement && isActiveStep('disinvestmentProfiles')"
                [investmentMode]="getDisinvestmentMode()"
                formControlName="disinvestmentProfiles"
                [definition]="disinvestmentProfiles"
                [isFreeManagement]="isFreeManagement"
                [investedProfiles]="investedProfiles"
                [totalAmount]="totalAmountToInvest"
                [checksOnOverallAlloc]="true">
        </lpc-karma-disinvestment-funds>
        <div *ngIf="isAfterId('disinvestmentProfiles')">
          <ul>
            <li [attr.data-qa]="profile.id + 'info_disinvestment_profile'" *ngFor="let profile of selectedDisinvestmentProfiles">
              <span for="label">{{ profile.description }} </span>
              <span
                for="label">{{ getProfileAmount(profile.id.toString()).toString() | rgiCtryLNumFormatter :  '' : currencyFormatterOptions}}
              </span>
              <span for="value">({{ getProfilePercent(profile.id.toString()).toString() | rgiCtryLNumFormatter: '' : percentFormatterOptions}})</span>
            </li>
          </ul>
        </div>
      </lpc-step>

      <lpc-step *ngIf="showDisinvestment" id="disinvestmentFunds" [errors]="errors"  [jump]="skipDisinvestment" label="lpc_disinvestment_funds" [nextVisible]="formGroup.get('disinvestmentFunds').valid" fieldId="disinvestmentFunds" (next)="onNext($event)">
        <div *ngIf="isActiveStep('disinvestmentFunds');else inactiveStep" formGroupName="disinvestmentFunds">
          <!-- <div *ngIf="!skipDisinvestment"> -->
            
        <div rgi-rx-accordion [multi]="true">
          <rgi-rx-expansion-panel [expanded]="true" color="default" *ngFor="let profile of selectedDisinvestmentProfiles">
            <div rgi-rx-expansion-panel-header>
              <span class="accordion-text" translate>{{ profile.description }}</span>
            </div>
            <div content data-qa="panel-button">
              <lpc-karma-disinvestment-full-funds
                  *ngIf="isFreeManagement"
                  [investmentMode]="getDisinvestmentMode()"
                  [formControlName]="profile.id.toString()"
                  [definition]="profile.funds"
                  [isFreeManagement]="isFreeManagement"
                  [investedProfiles]="getInvestedFundsByProfileId(profile.id)"
                  [totalAmount]="getDisinvestmentAmountWithProfileId(profile.id)"
                  [checksOnOverallAlloc]="true">
              </lpc-karma-disinvestment-full-funds>
              <lpc-karma-disinvestment-funds
                  *ngIf="!isFreeManagement"
                  [investmentMode]="getDisinvestmentMode()"
                  [formControlName]="profile.id.toString()"
                  [definition]="profile.funds"
                  [isFreeManagement]="isFreeManagement"
                  [investedProfiles]="getInvestedFundsByProfileId(profile.id)"
                  [totalAmount]="getDisinvestmentAmountWithProfileId(profile.id)"
                  [checksOnOverallAlloc]="false">
              </lpc-karma-disinvestment-funds>          
            </div>
          </rgi-rx-expansion-panel>
        </div>
          <!-- </div> -->
        </div>
        <ng-template #inactiveStep>
          <!-- This is only to stop the destruction of the component and make sure that we dont lose the form data  -->
        </ng-template>
        <div *ngIf="isAfterId('disinvestmentFunds')">
          <lpc-karma-fund-table
            [isFreeManagement]="isFreeManagement"
            [profiles]="disinvestmentProfiles"
            [totalAmount]="totalInvestedAmount"
            [enableOpenListDefault]="true"
            [investedProfiles]="investedProfiles">
          </lpc-karma-fund-table>
        </div>
      </lpc-step>

      <lpc-step *ngIf="showInvestment"
        [errors]="errors" [jump]="skipInvestmentProfiles"
        id="investmentProfiles" label="lpc_Investment_profiles" [nextVisible]="!formGroup.get('investmentProfiles').invalid"
        fieldId="investmentProfiles" (next)="onNext($event)">
        <lpc-investment-step-adapter *ngIf="isActiveStep('investmentProfiles') || isAfterId('investmentProfiles')"
          [currentStep]="isActiveStep('investmentProfiles')"
          [activeStep]="isActiveStep('investmentProfiles') || isAfterId('investmentProfiles')"
          [customFormGroup]="formGroup"
          [definition]="investmentProfiles"
          [viewOnly]="skipInvestmentProfiles"
          [totalAmount]="isFreeManagement ? totalInvestedAmount : totalAmountToInvest"
          (volatilityEvent)="handleVolatilityEvent($event)"
          [config]="getInvestmentProfileConfigurations">
        </lpc-investment-step-adapter>
      </lpc-step>

      <lpc-step *ngIf="showInvestment"
        [errors]="errors" id="investmentFunds"
        label="lpc_investment_funds" [nextVisible]="!formGroup.get('investmentFunds').invalid" fieldId="investmentFunds"
        (next)="onNext($event)" [isConfirmAndAccept]="formGroup.get('investmentFunds').valid && isConfirmAndAccept"
        (confirmAccept)="onNext($event, true, null, true)">

        <lpc-investment-step-adapter *ngIf="isActiveStep('investmentFunds') || isAfterId('investmentFunds')"
          [currentStep]="isActiveStep('investmentFunds')"
          [activeStep]="isActiveStep('investmentFunds') || isAfterId('investmentFunds')"
          [customFormGroup]="formGroup"
          [definition]="investmentProfiles"
          [viewOnly]="skipInvestmentFunds"
          [totalAmount]="isFreeManagement ? totalInvestedAmount : totalAmountToInvest"
          [(volatility)]="volatility"
          (volatilityEvent)="handleVolatilityEvent($event)"
          [config]="getInvestmentFundConfigurations"
          [investedProfiles]="investedProfiles">
        </lpc-investment-step-adapter>
      </lpc-step>

      <lpc-step *ngIf="isAuth || !!errors && !!errors.length"
                id="warnings"
                label="lpc_warnings"
                [isConfirmAndAccept]="isConfirmAndAccept"
                (confirmAccept)="publish(true)"
                (next)="publish()"
                [disableNext]="operationCantBePublish"
                [nextLabel]="isAuth ? 'AUTHORIZATION REQUEST' : 'Confirm'">
        <lpc-warning-messages *ngIf="!!errors" [errors]="errors"></lpc-warning-messages>
        <lpc-error-messages [errors]="errors" [fieldType]="'tools'"></lpc-error-messages>
      </lpc-step>

      <lpc-step id="documents" label="lpc_confirm_variation_step" [errors]="errors" fieldId="documents" [nextVisible]=false>

        <lpc-document-wrapper
          *ngIf="!!publishMessage"
          [contractId]="getContractId"
          [operationCode]="getOperationCode"
          [publishMessage]="publishMessage"
          [isAuth]="isAuth"
          [authorizationId]="authorizationId"
          [printMode]="printMode"
          [config]="documentsConfig"
          (documentEvent)="handleDocumentEvent($event)">
        </lpc-document-wrapper>

        <lpc-navigation-buttons
          id="last-step-buttons"
          (back)="closeCardWithoutModal()"
          [backLabel]="'lpc_close_button'"
          [backVisible]="true"
          [nextVisible]="false">
        </lpc-navigation-buttons>
      </lpc-step>

</lpc-stepper>

<div class="btn-group btn-group-justified external" *ngIf="!isActiveStep('documents')">
  <lpc-navigation-buttons (back)="closeCard()" [nextVisible]="false" [qa]="'annulla'"></lpc-navigation-buttons>
</div>
