import { PolicyService } from './../services/policy-service';
import { distinctUntilChanged, take } from 'rxjs/operators';
import { Component, EventEmitter, forwardRef, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  UntypedFormControl, UntypedFormGroup, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors, Validator, ValidatorFn, Validators
} from '@angular/forms';
import { TranslationWrapperService } from './../../i18n/translation-wrapper.service';
import { ValidatorService } from 'angular-iban';
import { Subscription } from 'rxjs';
import { ToolConfDefinition } from '../lic-investment-contract/model/tool-conf-definition';
import { FormFieldDefinition } from '../models/pip.model';
import { Subject as sbj } from '../models/subject.model';
import { LifeRoleService } from '../services/life-role.service';
import { RgiCtryLayerNumFormatterPipe } from '@rgi/rgi-country-layer';
import { FACTOR_TOOL_CODE } from '../enum/life-issue.enum';

@Component({
  selector: 'lic-generator',
  templateUrl: './lic-generator.component.html',
  styleUrls: ['./lic-generator.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => LicGeneratorComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => LicGeneratorComponent),
      multi: true
    }
  ]
})
export class LicGeneratorComponent implements OnInit, OnDestroy, ControlValueAccessor, OnChanges, Validator {

  private $subscriptions: Subscription[] = [];
  @Output() eventPropagation = new EventEmitter<any>();
  @Output() deleteFactor = new EventEmitter<any>();
  @Output() handleSubject = new EventEmitter<any>();
  @Output() resetSubject = new EventEmitter<any>();
  @Input() submitted = false;
  @Input() prevalSubject: sbj;
  @Input() disabled = false;
  @Input() occurrenceMinValue = 1;
  @Input() occurrenceMaxValue;

  private _mandatory = null;

  // This setter is called when the parent component provides a value for 'mandatory'.
  // If you want to override the default 'mandatory' value, pass a value to this input
  // in the parent component like so: <your-component [mandatory]="someValue"></your-component>
  @Input() set mandatory(state) {
    this.mandatoryState = state;
  }

  @Input() definition: FormFieldDefinition | ToolConfDefinition;

  public formGroup: UntypedFormGroup = new UntypedFormGroup({
    factor: new UntypedFormControl(null),
    subject: new UntypedFormGroup({
      val: new UntypedFormControl(null)
    })
  });

  public get inputType(): 'DATA' | 'DATE' | 'ENUM' | 'DOUBLE'| 'BENEFICIARY'| 'IBAN' | 'STRING' | 'PARTY'| 'INT' | null {
    return this.definition ? this.definition.type : null;
  }

  public set mandatoryState(state) {
    this._mandatory = state;
  }

  public get mandatoryState(): boolean {
    return this._mandatory === null ? this.definition.mandatory : this._mandatory;
  }

  constructor(
    protected lifeRoleService: LifeRoleService,
    protected translateService: TranslationWrapperService,
    public policyService: PolicyService,
    protected rgiCtryLNumFormatter: RgiCtryLayerNumFormatterPipe
    ) { }

  ngOnInit(): void {
    this.setValidators();
    const onlyOneValue = !!this.definition.values && this.definition.values.length === 1;
    if (onlyOneValue) {
      this.formGroup.get('factor').disable();
    }

    if (this.disabled) {
      (this.formGroup.get('factor') as UntypedFormControl).disable();
    } else {
      (this.formGroup.get('factor') as UntypedFormControl).enable();
    }
    this.$subscriptions.push(
      this.formGroup.get('factor').valueChanges.subscribe(value => {
        this.onChange(value);
      }),
      this.formGroup.get('subject').valueChanges.subscribe(value => {
        if (value.val == null) {
          this.onChange(null);
        } else {
          this.onChange(value);
        }
      }),
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!!changes.prevalSubject && this.definition.type === 'PARTY') {
      this.formGroup.get('subject').get('val').setValue(changes.prevalSubject.currentValue);
    }
    if (!!changes.disabled) {
      if (changes.disabled.currentValue) {
        this.formGroup.get('factor').setValue(null);
        this.formGroup.get('factor').disable();
      } else {
        this.formGroup.get('factor').enable();
      }
    }
  }

  validate(control: AbstractControl): ValidationErrors | null {
    const errors: any = {};
    if (this.mandatoryState && control.value == null ) {
      Object.assign(errors, {codeMissing: true});
    }
    if (this.definition.code === FACTOR_TOOL_CODE.OCCURRENCE_NUMBER_CODE &&
    (this.formGroup.get('factor').hasError('min') || this.formGroup.get('factor').hasError('max'))) {
      const errorMessage = this.translateService.getImmediate('lic_TheoccurenceNumbermustbeavalue', {
        min: this.occurrenceMinValue,
        max: this.occurrenceMaxValue
      });
      Object.assign(errors, {errorMessage});
    }
    return errors;
  }

  // Main method to set validators for various form controls based on specific conditions.
  setValidators() {
    const isMandatory = this.mandatoryState;

    // Apply validators conditionally based on the definition type and other conditions
    if (this.definition.type === 'PARTY' && isMandatory) {
      this.setControlValidators('subject.val', [Validators.required]);
    }

    if (this.definition.type !== 'PARTY' && isMandatory) {
      this.setControlValidators('factor', [Validators.required]);
    }

    if (this.definition.type === 'INT' && this.definition.code === FACTOR_TOOL_CODE.OCCURRENCE_NUMBER_CODE) {
      this.setControlValidators('factor', this.occurrenceValidators([Validators.required]));
    }

    if (this.definition.type === 'IBAN') {
      this.setControlValidators('factor', [Validators.required, ValidatorService.validateIban]);
    }

    if (this.definition.type === 'ENUM' && isMandatory) {
      this.setControlValidators('factor', [Validators.required]);
    }
  }

  // Helper method to set validators on a form control.
  // - controlName: The name of the form control to which validators are to be applied.
  // - validators: An array of validator functions to apply.
  private setControlValidators(controlName: string, validators: ValidatorFn[]) {
    const control = this.formGroup.get(controlName) as UntypedFormControl;
    control.setValidators(validators);
    control.updateValueAndValidity(); // Ensure the new validators take effect immediately
  }

  // eslint-disable-next-line max-len
  private occurrenceValidators(validators: ((control: AbstractControl) => ValidationErrors)[]) {
    validators.push(Validators.min(this.occurrenceMinValue));
    if (!!this.occurrenceMaxValue) {
      validators.push(Validators.max(this.occurrenceMaxValue));
    }
    return validators;
  }

  ngOnDestroy() {
    this.$subscriptions.forEach(subscription => {
      subscription.unsubscribe();
    });
  }

  mandatoryCheck(): boolean {
    return this.mandatoryState;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
  }

  setDisabledState(isDisabled: boolean): void {
    if (isDisabled) {
      this.formGroup.disable({ emitEvent: false });
    } else {
      this.formGroup.enable({ emitEvent: false });
    }
  }

  writeValue(obj: any): void {
    this.formGroup.get('factor').setValue(obj, { emitEvent: false });
  }

  onChange(obj: any) {
  }

  currentValue() {
    return this.formGroup.get('factor').value;
  }

  openAnag(event) {
    this.eventPropagation.emit(event);
    this.$subscriptions.push(
      this.lifeRoleService.getObservable().pipe(distinctUntilChanged(), take(1)).subscribe(value => {
        if (value != null) {
          this.handleSubjectReceved(value);
        }
      })
    );

  }

  handleSubjectReceved(value: sbj) {
    this.formGroup.get('subject').get('val').setValue(value);
    this.handleSubject.emit(value);
  }

  resetMethod(event) {
    this.formGroup.get('subject').get('val').setValue(null);
    this.resetSubject.emit(null);
  }

  formatonValueChange($event) {
    $event.target.value = this.rgiCtryLNumFormatter.transform(
      $event.target.value,
      this.policyService.currentLocale, {
        style: 'decimal',
        minimumFractionDigits: 4
      }
    );
  }

}
