import { PlcObjectUtils } from './../../../utils/plc-object-utils';
import { customDistributionValidator } from './../../../utils/custom-validators/custom-validators-utils';
import { DistributionValidatorConfig, DISTRIBUTION_PERC, DistributionConfigDefinition } from './../../distribution-toggle/distribution-toggle.model';
import { Subscription } from 'rxjs/internal/Subscription';
import {
  Component, EventEmitter,
  forwardRef, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges
} from '@angular/core';
import { AbstractControl, ControlValueAccessor, UntypedFormArray, UntypedFormControl, UntypedFormGroup, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors } from '@angular/forms';
import { Role } from '../../../models/postsales-operations-response.model';
import { AnagService } from '../../../services/anag.service';
import { DistributionToggle, DISTRIBUTION_AMOUNT } from '../../distribution-toggle/distribution-toggle.model';
import { Beneficiary } from '../model/beneficiary';
import { Roles } from '../../../models/enum/lpc-subjects.enum';
import { Subject } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';
import { TranslationWrapperService } from '../../../i18n/translation-wrapper.service';
import { RgiCtryLayerNumFormatterPipe } from '@rgi/rgi-country-layer';


@Component({
  selector: 'lpc-claim-beneficiary-control',
  templateUrl: './lpc-claim-beneficiary-control.component.html',
  styleUrls: ['./lpc-claim-beneficiary-control.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => LpcClaimBeneficiaryControlComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => LpcClaimBeneficiaryControlComponent),
      multi: true
    },
    RgiCtryLayerNumFormatterPipe
  ]
})
export class LpcClaimBeneficiaryControlComponent implements ControlValueAccessor, OnChanges, OnInit, OnDestroy {

  @Input() public value: Beneficiary;
  @Input() public editable = true;
  @Input() public showPercentage = true;
  @Input() public blockBeneficiaries = false;
  @Input() public set distributionConfig(dis: DistributionConfigDefinition) {
    this.totalAmount = dis && dis.totalAmount;
    this._distributionType = !!dis ? dis.distributionType : null;
    this.distributionTypeChanged.next(!!dis ? dis.distributionType : null);
  }
  @Output() public delete: EventEmitter<Beneficiary> = new EventEmitter<Beneficiary>();

  $subscriptions: Subscription[] = [];
  _distributionType: DistributionToggle = null;
  distributionTypeChanged: Subject<DistributionToggle> = new Subject<null>();
  _AMOUNT = DISTRIBUTION_AMOUNT;
  totalAmount: number;

  public formGroup: UntypedFormGroup = new UntypedFormGroup({
    value: new UntypedFormGroup({
      id: new UntypedFormControl(),
      role: new UntypedFormControl(),
      name:  new UntypedFormControl(),
      percentage: new UntypedFormControl(),
      amount: new UntypedFormControl(),
      personType:  new UntypedFormControl(),
      adult: new UntypedFormControl(),
      linkedSubjectsRoles: new UntypedFormArray([])
    }),
    type: new UntypedFormControl(),
    code: new UntypedFormControl(),
    idAssicurato: new UntypedFormControl(),
    irrevocable: new UntypedFormControl(),
    idBenefLock: new UntypedFormControl()
  });

  constructor(
    protected anagService: AnagService,
    protected translate: TranslationWrapperService,
    protected rgiNumberFormatter: RgiCtryLayerNumFormatterPipe) {
  }

  ngOnInit(): void {
    const isBeneficiaryBlocked = !this.editable || this.blockBeneficiaries;
    if (isBeneficiaryBlocked) {
      this.formGroup.get('value').get('percentage').disable();
      this.formGroup.get('value').get('amount').disable();
    }
    this.$subscriptions.push(
      this.formGroup.valueChanges.subscribe((value) => {
        // value.value = this.valorizeDistribution(value);
        this.writeValue(value);
        this.onChange(value);
      }),
      this.distributionTypeChanged.asObservable().pipe(distinctUntilChanged()).subscribe(val => {
        if (val === DISTRIBUTION_AMOUNT) {
          const config = new DistributionValidatorConfig(this.totalAmount, val, this.value.value as Role, 0, this.totalAmount);
          this.addDistributionValidations('percentage', config, false);
          this.addDistributionValidations('amount', config);
        }
        if (val === DISTRIBUTION_PERC) {
          const config = new DistributionValidatorConfig(this.totalAmount, val, this.value.value as Role, 0, 100);
          this.addDistributionValidations('amount', config, false);
          this.addDistributionValidations('percentage', config);
        }
      })
    );
  }

   // private valorizeDistribution(value: {value: Role}): any {
   //   if (this._distributionType === DISTRIBUTION_PERC) {
   /* eslint-disable */
   //     value.value.amount  = (!!this.totalAmount ?
   //                          PlcObjectUtils.roundToDecimal(+value.value.percentage * this.totalAmount / 100, 2) : 0).toString();
   //   } else if (this._distributionType === DISTRIBUTION_AMOUNT) {
   //     value.value.percentage = (!!this.totalAmount ?
   //                          PlcObjectUtils.roundToDecimal(+value.value.amount / this.totalAmount * 100, 2) : 0).toString();
   //   }
   //   return value.value;
   // }

  validate(control: AbstractControl): ValidationErrors | null {
    let errors: ValidationErrors = Object.assign({}, this.formGroup.errors);
    const childControls = (this.formGroup && this.formGroup.controls && this.formGroup.controls.value as UntypedFormGroup).controls;
    if (!!childControls) {
      // extracting errors from the inner FormGroup to propagate the validation errors to the parent
      Object.keys(childControls).filter(c => childControls[c].errors).forEach(c => {
        if (!errors) { errors = {}; }
        PlcObjectUtils.merge(errors, {[c]: childControls[c].errors});
    });
    }
    return !!Object.keys(errors).length ? errors : null;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.value) {
      const value: Beneficiary = changes.value.currentValue;
      this.formGroup.setValue(value, {emitEvent: false});
    }
  }

  trackFn(index: any, item: Role) {
    if ((item as Role).id) {
      return (item as Role).id;
    } else {
      return index;
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
  }

  setDisabledState(isDisabled: boolean): void {
    if (isDisabled) {
      this.formGroup.disable({emitEvent: false});
    } else {
      this.formGroup.enable({emitEvent: false});
    }
  }

  writeValue(obj: Beneficiary): void {
    if (!!obj) {
      this.value = obj;
      this.formGroup.patchValue(obj, {emitEvent: false});
    }
  }

  private addDistributionValidations(control: string, config: DistributionValidatorConfig, add = true) {
    const isMainBenef = this.formGroup.get('value').value.role === Roles.BENEFICIARY;
    if (isMainBenef && !!this.totalAmount && this.editable && !this.blockBeneficiaries) {
      if (add) {
        this.formGroup.get('value').get(control).reset(null);
        this.formGroup.get('value').get(control).setValidators(
          customDistributionValidator(
            config.min,
            config.max,
            config.distributionType,
            config.beneficiary,
            config.totalAmount,
            this.translate, this.rgiNumberFormatter
            )
        );
      } else {
        this.formGroup.get('value').get(control).reset(null);
        this.formGroup.get('value').get(control).clearValidators();
      }
      this.formGroup.get('value').updateValueAndValidity();
    }
  }

  onChange(obj: Beneficiary) {
  }

  deleteBen(value: Beneficiary) {
    this.delete.emit(value);
  }

  getValueForDataQA(element: string): string {
    /**
     * 21/01/22 Il 'type' ritorna un underfined, si è scelto di sostituirlo con il valore del 'role'
     * return element + '-benef-' + this.formGroup.get('type').value + this.formGroup.get('value').get('name').value;
     */
    return element + '-benef-' + this.formGroup.get('value').get('role').value + this.formGroup.get('value').get('name').value;
  }

  ngOnDestroy(): void {
    this.$subscriptions.forEach(sub => sub.unsubscribe());
  }


}
