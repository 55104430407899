<div>
    <div [ngClass]="classMsg" *ngIf="message" role="alert">
        {{ message | translate }}
    </div>

    <div class="card shadow table-white" *ngFor="let finPay of finPays; ">

        <hr>
        <div class="row colorBlu3">
            <div class="col-xs-3 col-md-3" style="font-size: 20px;">
                &nbsp;&nbsp;
                <label ng-bind="label" style="color: white;font-weight: bold; font-size: 20px;"
                    *ngIf="movementReceipt(finPay)">{{ '_CLAIMS_._RECEIPT_SUMMARY' | translate }}: {{finPay.number}}</label>
                <label ng-bind="label" style="color: white;font-weight: bold; font-size: 20px;"
                    *ngIf="!movementReceipt(finPay)">{{ '_CLAIMS_._SETTLEMENT_SUMMARY' | translate }}: {{finPay.number}}</label>
            </div>
            <div *ngIf="finPay.currencyCode=='EUR'" class="col-xs-3 col-md-3" style="font-size: 20px;">
                &nbsp;
            </div>
            <div class="col-xs-3 col-md-3" style="font-size: 20px;">
                <label ng-bind="label" style="margin-right: 1%; font-weight: bold;color:  white;">{{ '_CLAIMS_._STATUS' |
                    translate }}:</label>
                <label ng-bind="label" style="font-weight: bold;color: white;">{{finPay.status}}</label>
            </div>
            <div class="col-xs-3 col-md-3" style="font-size: 20px;">
                <label ng-bind="label" style="margin-right: 1%; font-weight: bold;color:  white;">{{ '_CLAIMS_._AMOUNT' |
                    translate }}: €</label>
                <label ng-bind="label" style="font-weight: bold;color: white;">{{finPay.totalAmountNet |
                    number:'1.2-2'}}</label>
            </div>
            <div *ngIf=" finPay.currencyCode!==null && finPay.currencyCode!=='EUR'" class="col-xs-2 col-md-2"
                style="font-size: 20px;">
                <label ng-bind="label" style="margin-right: 1%; font-weight: bold;color:  white;">{{ '_CLAIMS_._AMOUNT' |
                    translate }}
                    {{finPay.currencyCode}}: </label>
                <label ng-bind="label" style="font-weight: bold;color: white;">{{finPay.currencyAmount |
                    number:'1.2-2'}}</label>
            </div>

        </div>
        <div class="row">
            <div class="col-xs-3 col-md-3" style="font-size: 14px;">
                <label ng-bind="label" style="margin-right: 1%; font-weight: bold;">{{ '_CLAIMS_._PARTY' | translate }}:</label>
                <label ng-bind="label">{{finPay.involvedParty}}</label>
            </div>
            <div class="col-xs-3 col-md-3" style="font-size: 14px;">
                <label ng-bind="label" style="margin-right: 1%; font-weight: bold;">{{ '_CLAIMS_._PARTY_ROLE' | translate
                    }}:</label>
                <label ng-bind="label">{{finPay.partyRole}}</label>
            </div>
            <div [ngClass]="finPay.currencyCode!='EUR' ? 'col-xs-2 col-md-2' : 'col-xs-3 col-md-3'"
                style="font-size: 14px;">
                <label ng-bind="label" style="margin-right: 1%; font-weight: bold;">{{ '_CLAIMS_._OPERATION_USER' | translate
                    }}:</label>
                <label ng-bind="label">{{finPay.userLiq}}</label>
            </div>
            <div [ngClass]="finPay.currencyCode!='EUR' ? 'col-xs-2 col-md-2' : 'col-xs-3 col-md-3'"
                style="font-size: 14px;">
                <label ng-bind="label" style="margin-right: 1%; font-weight: bold;">{{ '_CLAIMS_._QUITTANCE_DATE' | translate
                    }}:</label>
                <label ng-bind="label">{{finPay.receiptDate | date:'dd/MM/yyyy'}}</label>
            </div>
            <div *ngIf="finPay.currencyCode!==null && finPay.currencyCode!=='EUR'" class="col-xs-2 col-md-2"
                style="font-size: 14px;">
                <label ng-bind="label" style="margin-right: 1%; font-weight: bold;">{{ '_CLAIMS_._CURRENCY_DATE' | translate
                    }}:</label>
                <label ng-bind="label">{{finPay.currencyCodeDate | date:'dd/MM/yyyy'}}</label>
            </div>
        </div>
        <div class="row">
            <div *ngIf="finPay.paymentType"
                [ngClass]="finPay.currencyCode!='EUR' ? 'col-xs-3 col-md-3' : 'col-xs-3 col-md-3'"
                style="font-size: 14px;">
                <label ng-bind="label" style="margin-right: 1%; font-weight: bold;">{{ '_CLAIMS_._PAYMENT_TRANSACTION_TYPE' |
                    translate }}:</label>
                <label ng-bind="label">{{defPaymentType(finPay.paymentType)}}</label>
            </div>
            <div [ngClass]="finPay.currencyCode!='EUR' ? 'col-xs-3 col-md-3' : 'col-xs-3 col-md-3'"
                style="font-size: 14px;">
                <label ng-bind="label" style="margin-right: 1%; font-weight: bold;">{{ '_CLAIMS_._RECEIPT_TYPE' | translate
                    }}:</label>
                <label ng-bind="label">{{defReciptType(finPay.receiptType)}}</label>
            </div>
            <div [ngClass]="finPay.currencyCode!='EUR' ? 'col-xs-2 col-md-2' : 'col-xs-3 col-md-3'"
                style="font-size: 14px;">
                <label ng-bind="label" style="margin-right: 1%; font-weight: bold;">{{ '_CLAIMS_._MANAGMENT_PAYMENT' | translate
                    }}:</label>
                <label ng-bind="label">{{defManagementPay(finPay.paymentManagement)}}</label>
            </div>
            <div [ngClass]="finPay.currencyCode!='EUR' ? 'col-xs-2 col-md-2' : 'col-xs-3 col-md-3'"
                style="font-size: 14px;">
                <label ng-bind="label" style="margin-right: 1%; font-weight: bold;">{{ '_CLAIMS_._SEPARATE_RECEIPT' | translate
                    }}:</label>
                <label ng-bind="label">{{defBoolean(finPay.separateReceipt) | translate}}</label>
            </div>
            <div *ngIf="finPay.currencyCode!==null && finPay.currencyCode!=='EUR'" class="col-xs-1 col-md-1"
                style="font-size: 14px;">
                <label ng-bind="label" style="margin-right: 1%; font-weight: bold;">{{ '_CLAIMS_._CURRENCY' | translate }}:</label>
                <label ng-bind="label">{{finPay.currencyCode}}</label>
            </div>
        </div>
        <div class="row" *ngIf="isAbilitatoClaimsPaymentUrgency">
            <div *ngIf="finPay.priority"
                [ngClass]="finPay.currencyCode!='EUR' ? 'col-xs-3 col-md-3' : 'col-xs-2 col-md-2'"
                style="font-size: 14px;">
                <label ng-bind="label" style="margin-right: 1%; font-weight: bold;">{{ '_CLAIMS_._PRIORITY' | translate
                    }}:</label>
                <label ng-bind="label">{{defPriority(finPay.priority)}}</label>
            </div>
            <div *ngIf="finPay.dueDate"
                [ngClass]="finPay.currencyCode!='EUR' ? 'col-xs-3 col-md-3' : 'col-xs-2 col-md-2'"
                style="font-size: 14px;">
                <label ng-bind="label" style="margin-right: 1%; font-weight: bold;">{{ '_CLAIMS_._DUE_DATE' | translate
                    }}:</label>
                <label ng-bind="label">{{finPay.dueDate | date:'dd/MM/yyyy'}}</label>
            </div>
        </div>
        <div class="row" *ngIf="finPay.note">
            <div class="col-xs-3 col-md-3" style="font-size: 14px;">
                <label ng-bind="label" style="margin-right: 1%; font-weight: bold;">{{ '_CLAIMS_._NOTE' | translate }}:</label>
                <label ng-bind="label" style="background-color: #FFD700;">{{finPay.note}}</label>
            </div>
        </div>
        <div class="row" *ngIf="finPay.procedureCode">
            <div class="col-xs-3 col-md-3" style="font-size: 14px;">
                <label ng-bind="label" style="margin-right: 1%; font-weight: bold;">{{ '_CLAIMS_._PROCEDURE_CODE' | translate
                    }}:</label>
                <label ng-bind="label">{{finPay.procedureCode}}</label>
            </div>
        </div>
        <div class="colorBlu11">
            &nbsp;&nbsp;<span class="rgifont rgi-dettagli_tecnici font-size: 16px;"></span>&nbsp;
            <label ng-bind="label" class="colorBlu2" style=" font-size: 16px;">{{ '_CLAIMS_._TYPES_OF_LOSS' | translate }}</label>
        </div>
        <div class="row" *ngIf="!consult && !movementReceipt(finPay)">
            &nbsp;&nbsp;<span class="rgifont rgi-dettagli_tecnici font-size: 16px;"></span>&nbsp;
            <label ng-bind="label" style="color: #f68020; font-size: 16px;">{{ '_CLAIMS_._MESSAGE_._WARNING_THE_RESERVE_HAS_BEEN_REDUCED_BY_THE_PAID_AMOUNT' | translate }}</label>
        </div>
        <div *ngFor="let exp of createBranchMap(finPay); let even = even; let odd = odd; let expIndex = index;">
            <div class="row">
                <div class="col-xs-3 col-md-3" style="font-size: 14px;font-weight: bold;">
                    <label ng-bind="label" *ngIf="exp.branchTitle" class="colorBlu2" style="font-size: 16px; ">{{'_CLAIMS_._CLAIM_BRANCH'
                        | translate}}: {{exp.branchTitle}}</label>
                    <br>
                    <table style="width: 100%;">
                        <tr>
                            <td style="width: 5%;">
                            </td>
                            <td style="width: 95%; text-align: left;">
                                <label ng-bind="label" *ngIf="exp.managementType" style="padding-top: 3px;">
                                    {{exp.description}}&nbsp;
                                    {{exp.moreInfo}}&nbsp;
                                    ({{exp.managementType}})</label>
                                <label ng-bind="label" *ngIf="!exp.managementType" style="padding-top: 3px;">
                                    {{exp.description}}&nbsp;
                                    {{exp.moreInfo}}</label>
                            </td>
                            <td style="width: 10%;">

                            </td>
                        </tr>
                    </table>
                </div>

                <div class="col-xs-2 col-md-2" style="font-size: 14px;"><br>
                    <label ng-bind="label" style="margin-right: 1%; font-weight: bold; padding-top: 5px">{{ '_CLAIMS_._PAYMENT_DEFINITION' | translate }}:</label>
                    <label ng-bind="label">{{defDescription(exp.definition)}}</label>&nbsp;&nbsp;
                    <div class="btn-group" *ngIf="exp.feeData">
                        <button rgi-rx-button (click)="dataFee(finPay.number, exp.idCampo)"
                            class="btn btn-warning btn-secondary pull-right">
                            {{ '_CLAIMS_._FEE_DATA' | translate }}</button>
                    </div>
                </div>
                <div class="col-xs-2 col-md-2" style="font-size: 14px;"><br>
                    <span style="float: right;" *ngIf="!consult">
                        <label ng-bind="label" style="font-weight: bold; padding-top: 5px">{{ '_CLAIMS_._CURRENT_RESERVE' |
                            translate
                            }}:
                            &euro;{{exp.reserveAmount | number:'1.2-2'}}</label>
                    </span>
                    <span style="float: right;" *ngIf="consult">
                        <label ng-bind="label" style="font-weight: bold; padding-top: 5px">{{ '_CLAIMS_._AMOUNT_QUOTA' | translate
                            }}:
                            &euro;{{exp.quotaAmount | number:'1.2-2'}}</label>
                    </span>
                </div>
                <div class="col-xs-2 col-md-2" style="font-size: 14px;"><br>
                    <span style="float: right;">
                        <div class="col-xs-8 col-md-8">
                            <label ng-bind="label" style="font-weight: bold; padding-top: 5px">{{ '_CLAIMS_._CASHED' | translate
                                }}:
                            </label>
                        </div>
                        <div class="col-xs-4 col-md-4">
                            <label ng-bind="label" style="font-weight: bold; padding-top: 5px">
                                &euro;{{exp.amountNet | number:'1.2-2'}}</label>
                        </div>
                        <div class="col-xs-8 col-md-8" *ngIf="exp.amountInsured != 0">
                            <label ng-bind="label" style="font-style: italic;">{{ '_CLAIMS_._INSURED_SETTLEMENT_AMOUNT' |
                                translate }}:
                            </label>
                        </div>
                        <div class="col-xs-4 col-md-4" *ngIf="exp.amountInsured != 0">
                            <label ng-bind="label">
                                &euro;{{exp.amountInsured | number:'1.2-2'}}</label>
                        </div>
                    </span>
                </div>
                <div class="col-xs-2 col-md-2" style="font-size: 14px;" *ngIf="exp.legalFeeAmount"><br>
                    <span style="float: right;">
                        <label ng-bind="label" style="font-weight: bold; padding-top: 5px">{{ '_CLAIMS_._OF_WHICH_ATTORNEYS_FEES'
                            |
                            translate }}:
                            &euro;<span (click)="openDetail(exp)" class="colorBlu2"
                                style="cursor: pointer; text-decoration: underline;"
                                title="{{ '_CLAIMS_._OPEN_DETAIL' | translate }}">{{exp.legalFeeAmount |
                                number:'1.2-2'}}</span></label>
                    </span>
                </div>

                <div *ngIf="exp.currencyCode!==null && exp.currencyCode!=='EUR'" class="col-xs-1 col-md-1"
                    style="font-size: 14px; margin-left: 110px;"><br>
                    <span style="float: right;">
                        <label ng-bind="label" style="font-weight: bold; padding-top: 5px">{{ '_CLAIMS_._AMOUNT' | translate }}
                            {{exp.currencyCode}}:
                            {{exp.currencyAmount | number:'1.2-2'}}</label>
                    </span>

                </div>

            </div>

            <!--inizio marco-->
            <div class="row" *ngIf="exp.branchCode=='000010'" style="border-top: 1px solid #d4d4d4;">
                <div class="col-xs-3 col-md-3" style="font-size: 14px;font-weight: bold;">
                    
                </div>
    
                <div class="col-xs-2 col-md-2" style="font-size: 14px;"><br>
                    <label ng-bind="label" style="margin-right: 1%; font-weight: bold;">{{ '_CLAIMS_._FORFAIT_TYPE' | translate }}:</label>
                    <label ng-bind="label">{{exp.valuedRecognized}}</label>&nbsp;&nbsp;
                    <div class="btn-group" *ngIf="exp.feeData">
                        
                    </div>
                </div>
                <div class="col-xs-2 col-md-2" style="font-size: 14px;"><br>
                    <span style="float: right;" *ngIf="!consult">
                        <label ng-bind="label" style="font-weight: bold;"></label>
                    </span>
                    <span style="float: right;" *ngIf="consult">
                            <label ng-bind="label"
                                style="font-weight: bold;">{{ '_CLAIMS_._AMOUNT_QUOTA_FORFAIT' | translate }}:
                                &euro;{{exp.importoQuotaForfait | number:'1.2-2'}}</label>
                    </span>
                </div>
                <div class="col-xs-2 col-md-2" style="font-size: 14px;"><br>
                    <span style="float: right;">
                    <div class="col-xs-8 col-md-8">
                        <label ng-bind="label" style="font-weight: bold;">{{ '_CLAIMS_._FORFAIT' | translate }}:
                        </label>
                    </div>
                    <div class="col-xs-4 col-md-4">
                        <label ng-bind="label" style="font-weight: bold;">
                                &euro;{{exp.importoForfait | number:'1.2-2'}}</label>
                    </div>
                    <div class="col-xs-8 col-md-8" *ngIf="exp.amountInsured != 0">
                        <label ng-bind="label" style="font-style: italic;">
                        </label>
                    </div>
                    <div class="col-xs-4 col-md-4" *ngIf="exp.amountInsured != 0">
                        <label ng-bind="label" >
                                    </label>
                    </div>
                    </span>
                </div>
                    <div class="col-xs-2 col-md-2" style="font-size: 14px;" *ngIf="exp.legalFeeAmount"><br>
                    <span style="float: right;">
                        <label ng-bind="label" style="font-weight: bold;"></label>
                    </span>
                </div>
    
                    <div *ngIf="exp.currencyCode!==null && exp.currencyCode!=='EUR'" class="col-xs-1 col-md-1"
                    style="font-size: 14px; margin-left: 110px;"><br>
                    <span style="float: right;">
                        <label ng-bind="label" style="font-weight: bold;">{{ '_CLAIMS_._IMPORT' | translate }}
                            </label>
                    </span>
    
                </div>

    
            </div>
            <!--fine marco-->

        </div>
        <div *ngIf="showQuoteEvaluation">
            <div class="row colorBlu11">
                &nbsp;&nbsp;<span class="rgifont rgi-dettagli_tecnici font-size: 16px;"></span>&nbsp;
                <label ng-bind="label" class="colorBlu2" style=" font-size: 16px;">{{ '_CLAIMS_._CALCULATION_OF_THE_INSURED_FEE' |
                    translate
                    }}</label>
            </div>
            <div class="row">
                <claims-quote-evaluation panelId="quote-evaluation" [card]="card" nextPanelId="beneficiary-payee"
                    acceptSignals="true" [potPayFE]="potPayFE" [isDetail]="true" [payment]="finPay" (blockMsgEmitter)="blockMsgQuote($event)">
                </claims-quote-evaluation>
            </div>
        </div>

        <div class="colorBlu11">
            &nbsp;&nbsp;<span class="rgifont rgi-dettagli_tecnici font-size: 16px;"></span>&nbsp;
            <label ng-bind="label" class="colorBlu2" style=" font-size: 16px;">{{ '_CLAIMS_._BENEFICIARIES' | translate }}</label>
        </div>
        <div class="row"
            *ngFor="let aBen of finPay.listBeneficiary; let even = even; let odd = odd; let benIndex = index;">
            <div class="row">
                <div class="col-xs-3 col-md-3" style="font-size: 14px;font-weight: bold;">
                    <label ng-bind="label">{{defPrimary(aBen.primary) | translate}}</label>
                </div>
                <div class="col-xs-3 col-md-3" style="font-size: 14px;font-weight: bold;">
                    <label ng-bind="label">{{aBen.lastName}} {{aBen.name}}</label>
                </div>
                <div class="col-xs-3 col-md-3" style="font-size: 14px;font-weight: bold;">
                    <label ng-bind="label">{{defBenefType(aBen.typeBeneficiary)}} </label>
                </div>
                <div class="col-xs-3 col-md-3" style="font-size: 14px;font-weight: bold;"
                    *ngIf="!aBen.toUpgrade || !this.enableUpgrade">
                    <label ng-bind="label">{{valCFVAT(aBen)}}</label>
                </div>
                <div class="col-xs-3 col-md-3" style="font-size: 14px;font-weight: bold;"
                    *ngIf="aBen.toUpgrade && this.enableUpgrade">
                    <button rgi-rx-button type="button" class="btn btn-warning pull-right"
                        (click)="upgrade(aBen, finPay)">{{ '_CLAIMS_._BUTTONS_._UPDATE' | translate }}</button>
                </div>
            </div>
        </div>
        <div *ngIf="finPay.listPayee.length > 0">
            <div class="row colorBlu11">
                &nbsp;&nbsp;<span class="rgifont rgi-dettagli_tecnici font-size: 16px;"></span>&nbsp;
                <label ng-bind="label" class="colorBlu2" style=" font-size: 16px;">{{ '_CLAIMS_._PAYEES' | translate }}</label>
            </div>

            <div *ngFor="let aPerc of finPay.listPayee; let even = even; let odd = odd; let percIndex = index;">
                <div class="row">
                    <div class="col-xs-3 col-md-3" style="font-size: 14px;font-weight: bold;">
                        <label ng-bind="label">{{defPrimary(aPerc.primary) | translate}}</label>
                    </div>
                    <div class="col-xs-3 col-md-3" style="font-size: 14px;font-weight: bold;">
                        <label ng-bind="label">{{aPerc.lastName}} {{aPerc.name}}</label>
                    </div>
                    <div class="col-xs-3 col-md-3" style="font-size: 14px;font-weight: bold;">
                        <label ng-bind="label">{{defBenefType(aPerc.typeBeneficiary)}} </label>
                    </div>
                    <div class="col-xs-3 col-md-3" style="font-size: 14px;font-weight: bold;"
                        *ngIf="!aPerc.toUpgrade || !this.enableUpgrade">
                        <label ng-bind="label">{{valCFVAT(aPerc)}}</label>
                    </div>
                    <div class="col-xs-3 col-md-3" style="font-size: 14px;font-weight: bold;"
                        *ngIf="aPerc.toUpgrade && this.enableUpgrade">
                        <button rgi-rx-button type="button" class="btn btn-warning pull-right"
                            (click)="upgrade(aPerc, finPay)">
                           {{ '_CLAIMS_._BUTTONS_._UPDATE' | translate }}</button>
                    </div>
                </div>
                <div class="row" *ngIf="aPerc.primary">
                    <div class="colorBlu11">
                        &nbsp;&nbsp;<span class="rgifont rgi-dettagli_tecnici font-size: 16px;"></span>&nbsp;
                        <label ng-bind="label" class="colorBlu2" style=" font-size: 16px;">{{ '_CLAIMS_._METHOD_OF_PAYMENT' |
                            translate }}</label>
                    </div>
                    <div class="row">
                        <div class="col-xs-3 col-md-3" style="font-size: 14px;font-weight: bold;">
                            <label ng-bind="label" style="margin-right: 1%; font-weight: bold;">{{ '_CLAIMS_._TYPE' | translate
                                }}:
                            </label>
                            <label ng-bind="label">{{defPaymentMethod(finPay.methodPayment.methodId)}}</label>
                        </div>
                        <div class="col-xs-3 col-md-3" style="font-size: 14px;font-weight: bold;"
                            *ngIf="isBankTransfer(finPay.methodPayment.methodId)">
                            <label ng-bind="label">{{finPay.methodPayment.iban}} </label>
                        </div>
                        <div class="col-xs-3 col-md-3" style="font-size: 14px;font-weight: bold;"
                            *ngIf="isForeignBankTransfer(finPay.methodPayment.methodId)">
                            <label ng-bind="label">{{finPay.methodPayment.iban}}
                                {{finPay.methodPayment.foreignBankAccount}} </label>
                        </div>

                        <div class="col-xs-3 col-md-3" style="font-size: 14px;font-weight: bold;"
                            *ngIf="isForeignBankTransfer(finPay.methodPayment.methodId) || isBankTransfer(finPay.methodPayment.methodId)">
                            <label ng-bind="label" style="margin-right: 1%; font-weight: bold;">{{ '_CLAIMS_._BANK_TRANSFER_DESCRIPTION' | translate
                                }}:
                            </label>
                            <label ng-bind="label">{{finPay.methodPayment.transferDescription}} </label>
                        </div>
                    </div>

                    <div class="row"
                        *ngIf="finPay.methodPayment.receiver && (finPay.methodPayment.methodId == '4' || finPay.methodPayment.methodId == '1')">
                        <div class="col-xs-3 col-md-3" style="font-size: 14px;font-weight: bold;">
                        {{ '_CLAIMS_._ADDRESS' | translate }}
                        </div>
                        <div class="col-xs-3 col-md-3" style="font-size: 14px;font-weight: bold;">
                            {{finPay.methodPayment.receiver.lastName}} {{finPay.methodPayment.receiver.name}}
                        </div>
                        <div class="col-xs-3 col-md-3" style="font-size: 14px;font-weight: bold;">
                            <label ng-bind="label">
                                {{finPay.methodPayment.receiver.address}} {{finPay.methodPayment.receiver.number}}
                                {{finPay.methodPayment.receiver.postalCode}}
                                {{finPay.methodPayment.receiver.municipality}}
                                ({{finPay.methodPayment.receiver.state}})
                            </label>
                            <label ng-bind="label" *ngIf="!(finPay.methodPayment.receiver.address &&
                                                        finPay.methodPayment.receiver.postalCode &&
                                                        finPay.methodPayment.receiver.state)">
                                {{ '_CLAIMS_._INCOMPLETE_ADDRESS' | translate }}
                            </label>
                        </div>
                    </div>

                </div>
            </div>
        </div>
        <div class="colorBlu11">
            &nbsp;&nbsp;<span class="rgifont rgi-dettagli_tecnici font-size: 16px;"></span>&nbsp;
            <label ng-bind="label" class="colorBlu2" style=" font-size: 16px;">{{ '_CLAIMS_._DOCUMENTS' | translate }}</label>
        </div>

        <div class="row" *ngIf="!docPresent(finPay)">
            <h5 class="text-info bold">{{ '_CLAIMS_._NO_DOCUMENTS' | translate }}</h5>
        </div>


        <div class="row"
            *ngFor="let aDoc of finPay.documentList; let even = even; let odd = odd; let docIndex = index;">
            <div class="col-md-6 col-sm-6 col-xs-6">
                <label class="checkbox-label" style="font-weight: bold;">
                    {{aDoc.documentName | titlecase}}
                </label>
            </div>
            <div class="col-md-6 col-sm-6 col-xs-6" style="float: right;">

                <label class="checkbox-label text-uppercase" *ngIf="!aDoc.selected">
                {{ '_CLAIMS_._MESSAGE_._NOT_GENERATED_FOR_CHOICE' | translate }}
                </label>

            </div>
            <div class="row">&nbsp;</div>
            <div *ngIf="aDoc.selected">
                <div class="row" *ngFor="let aLiqDoc of aDoc.listLiqGenerations;">
                    <div class="col-md-3 col-sm-3 col-xs-3" style="margin-left: 10%">
                        <label ng-bind="label">
                            {{defShippingChannel(aLiqDoc.eShippingChannel) | titlecase}}
                        </label>
                    </div>
                    <div class="col-md-3 col-sm-3 col-xs-3">
                        <label ng-bind="label">
                        {{ '_CLAIMS_._SEND_TO' | translate }} {{defAddresseType(aLiqDoc.addresseType) | titlecase}}
                        </label>
                    </div>
                    <div class="col-md-3 col-sm-3 col-xs-3">
                        <label ng-bind="label" *ngIf="!aLiqDoc.validData">
                        {{ '_CLAIMS_._NOT_GENERATED_FOR_MISSING_DATA' | translate }}
                        </label>
                        <label class="checkbox-label text-uppercase" *ngIf="aDoc.selected && aLiqDoc.idDocInfo">
                        {{ '_CLAIMS_._GENERATED' | translate }} &nbsp;&nbsp;&nbsp;<span
                                class="rgifont rgi-dettagli_tecnici font-size: 40px;"
                                (click)="getPreviewDocument(aLiqDoc.idDocInfo)" style="cursor:pointer"></span>
                        </label>
                    </div>
                </div>
            </div>
        </div>
  
        <!-- Restituzione Quietanza RDC-381 -->
        <div class="colorBlu11">
            &nbsp;&nbsp;<span class="rgifont rgi-dettagli_tecnici font-size: 16px;"></span>&nbsp;
            <label ng-bind="label" class="colorBlu2" style=" font-size: 16px;">{{ '_CLAIMS_._RETURN_OF_RECEIPT' | translate }}</label>
        </div>

        <div class="row" *ngIf="receiptReturnDate == null">
            <h5 class="text-info bold">{{ '_CLAIMS_._NO_DATA' | translate }}</h5>
        </div>

        <div class="row" *ngIf="receiptReturnDate != null">
            <div class="col-xs-3 col-md-3" style="font-size: 14px;">
                <label ng-bind="label" style="margin-right: 1%; font-weight: bold;">{{ '_CLAIMS_._RECEIPT_STATUS' | translate
                    }}:</label>
                <label ng-bind="label">{{receiptStatus | translate}}</label>
            </div>
            <div class="col-xs-3 col-md-3" style="font-size: 14px;">
                <label ng-bind="label" style="margin-right: 1%; font-weight: bold;">{{ '_CLAIMS_._RECEIPT_DATE' | translate }}:</label>
                <label ng-bind="label">{{receiptReturnDate | date:'dd/MM/yyyy'}}</label>
            </div>
            <div [ngClass]="finPay.currencyCode!='EUR' ? 'col-xs-2 col-md-2' : 'col-xs-3 col-md-3'"
                style="font-size: 14px;" *ngIf="receiptMotivoAnnullo && receiptMotivoAnnullo !== null">
                <label ng-bind="label" style="margin-right: 1%; font-weight: bold;">{{ '_CLAIMS_._REASON_CANCEL_RECEIPT' | translate
                    }}:</label>
                <label ng-bind="label">{{receiptMotivoAnnullo}}</label>
            </div>
            <div [ngClass]="finPay.currencyCode!='EUR' ? 'col-xs-2 col-md-2' : 'col-xs-3 col-md-3'"
                style="font-size: 14px;" *ngIf="receiptNote && receiptNote !== null">
                <label ng-bind="label" style="margin-right: 1%; font-weight: bold;">{{ '_CLAIMS_._RECEIPT_NOTES' | translate
                    }}:</label>
                <label ng-bind="label">{{receiptNote}}</label>
            </div>
        </div>

        <!-- se NON arriva da un salva liquidazione e feeData è valorizzato -->
        <div *ngIf="!isFromSaveNewPayment && feeData">
            <div class="row colorBlu11">
                &nbsp;&nbsp;<span class="rgifont rgi-dettagli_tecnici font-size: 16px;"></span>&nbsp;
                <label ng-bind="label" class="colorBlu2" style=" font-size: 16px;">{{ '_CLAIMS_._FEE_DATA' | translate }}</label>
            </div>
            <div class="row">
                <div class="col-xs-3 col-md-3" style="font-size: 14px;">
                    <label ng-bind="label" style="margin-right: 1%; font-weight: bold;">{{ '_CLAIMS_._FEE_NUMBER' | translate
                        }}:</label>
                    <label ng-bind="label">{{feeData.feeNumber}}</label>
                </div>
                <div class="col-xs-3 col-md-3" style="font-size: 14px;">
                    <label ng-bind="label" style="margin-right: 1%; font-weight: bold;">{{ '_CLAIMS_._FEE_DATE' | translate
                        }}:</label>
                    <label ng-bind="label">{{feeData.feeDate | date:'dd/MM/yyyy'}}</label>
                </div>
                <div class="col-xs-3 col-md-3" style="font-size: 14px;">
                    <label ng-bind="label" style="margin-right: 1%; font-weight: bold;">{{ '_CLAIMS_._INVOICE_TYPE' | translate
                        }}:</label>
                    <label ng-bind="label">{{feeData.feeType}}</label>
                </div>
                <!-- se devo visualizzare SDI e SDI è valorizzato -->
                <div *ngIf="showSDIProtocolNumber && feeData.SDIProtocolCod" class="col-xs-3 col-md-3"
                    style="font-size: 14px;">
                    <label ng-bind="label" style="margin-right: 1%; font-weight: bold;">{{ '_CLAIMS_._PROTOCOLLO_SDI' | translate
                        }}:</label>
                    <label ng-bind="label">{{feeData.SDIProtocolCod}}</label>
                </div>
                <!-- se devo visualizzare SAP e se SDI non è valorizzato -->
                <div *ngIf="claimsManagementSapSRV && feeData.SAPProtocolKey" class="col-xs-3 col-md-3"
                    style="font-size: 14px;">
                    <label ng-bind="label" style="margin-right: 1%; font-weight: bold;">{{ '_CLAIMS_._PROTOCOLLO_SAP' | translate
                        }}:</label>
                    <label ng-bind="label">{{feeData.SAPProtocolKey}}</label>
                </div>

            </div>
        </div>
    </div>

    <div class="form-group">
        <div class="alert alert-danger" *ngIf="noVatOrFiscalCode" role="alert">{{ '_CLAIMS_._FISCAL_CODE__VAT_CODE_INCORRECT' |translate }} </div>
    </div>

    <div class="btn-group btn-group-justified">
        <div class="btn-group" *ngIf="consult && finPays && movementReceipt(finPays[0]) &&  !disableManage">
            <button rgi-rx-button type="button" class="btn btn-warning pull-right" (click)="settlement()"
            [disabled]="disableLiquidationSettlement" [rgiRxTooltip]="customTooltipLiquidation" [position]="'top'"
            [trigger]="'mouseenter'" [disableTooltip]="!showLiquidationSettlementTooltip">{{ '_CLAIMS_._SETTLEMENTS' | translate
                }}</button>
        </div>
        <div class="btn-group"
            *ngIf="consult && finPays && movementReceipt(finPays[0]) &&  !disableManage && showReturnReceipt">
            <button rgi-rx-button type="button" class="btn btn-warning pull-right" (click)="return()">{{ '_CLAIMS_._RETURN' |
                translate
                }}</button>
        </div>
        <div class="btn-group" *ngIf="consult && finPays && movementReceipt(finPays[0]) &&  !disableManage">
            <button rgi-rx-button type="button" class="btn btn-warning pull-right" (click)="cancellation()"
                [disabled]="!showCancellationReceipt" [rgiRxTooltip]="customTooltipLiquidation" [position]="'top'"
                [trigger]="'mouseenter'" [disableTooltip]="!showLiquidationSettlementTooltip">{{ '_CLAIMS_._CANCELLATION' | translate
                }}</button>
        </div>
        <div class="btn-group" *ngIf="consult && finPays && !movementReceipt(finPays[0]) &&  !disableManage">
            <button rgi-rx-button type="button" class="btn btn-warning pull-right" (click)="storno()"
            [disabled]="!showCancellationSettlement" [rgiRxTooltip]="customTooltipLiquidationTransferDelete" [position]="'top'"
            [trigger]="'mouseenter'" [disableTooltip]="!showLiquidationTransferDeleteTooltip">{{ liquidationButtonType | translate }}</button>
        </div>
        <div class="btn-group" *ngIf="consult && finPays && !movementReceipt(finPays[0]) &&  !disableManage">
            <button rgi-rx-button type="button" class="btn btn-warning pull-right" (click)="gestPrestatoriOpera()">{{
                'Professional Services' | translate }}</button>
        </div>
        <div class="btn-group">
            <button rgi-rx-button type="button" class="btn btn-warning pull-right" (click)="exitToClaim()">
                <div *ngIf="!consult">{{ '_CLAIMS_._BUTTONS_._EXIT' | translate }}</div>
                <div *ngIf="consult">{{ '_CLAIMS_._BUTTONS_._BACK' | translate }}</div>
            </button>
        </div>
    </div>
</div>

<ng-template #customTooltipLiquidation>
    {{ descErrOrTooltipLiquidation }}
</ng-template>

<ng-template #customTooltipLiquidationTransferDelete>
    {{ descErrOrTooltipLiquidationTransferDelete }}
</ng-template>