<div class="col-md-12 life-detail-first-info">
  <div class="col-md-5 life-detail-img">
    <div class="hidden-xs hidden-sm anag-user-ico">
      <span class="rgifont life-detail-icon-padding rgi-fogliocassa"></span>
    </div>
  </div>
  <div class="col-md-7 life-detail-general-info">
    <h3 class="col-md-12 card-color-blue" style="margin-top: 0px !important;">
      <span data-qa="fundDescription">{{fundsDetail.name}}</span>
    </h3>
    <div class="container-fluid anag-data">
      <div class="col-md-12">
        <span class="tbld_col_title" translate>lpc_macro_category</span>
        <span class="tbld_col_title">: </span>
        <span class="tbld_col_value" data-qa="macroCategory">
          {{fundsDetail.fundCategory}}
        </span>
      </div>
      <div class="col-md-12" *ngIf="!isFromConsultation">
        <span class="tbld_col_title" translate>lpc_quotation_frequency</span>
        <span class="tbld_col_title">: </span>
        <span class="tbld_col_value" data-qa="quotationFrequency">
          {{fundsDetail?.frequencyQuotes}}
        </span>
      </div>

      <div class="col-md-12">
        <span class="tbld_col_title" translate>lpc_fund_type</span>
        <span class="tbld_col_title">: </span>
        <span class="tbld_col_value" data-qa="fund_type">
          {{fundsDetail.fundType}}
        </span>
      </div>
      <div class="col-md-12" *ngIf="revaluationRates?.length > 0">
        <span class="tbld_col_title" translate>lpc_start_date_profit</span>
        <span class="tbld_col_title">: </span>
        <span class="tbld_col_value" data-qa="lpc_start_date_profit">
          {{revaluationRates[0]?.fromDate | date: 'dd/MM/yyyy'}}
        </span>
      </div>
      <div class="col-md-12" *ngIf="revaluationRates?.length > 0">
        <span class="tbld_col_title" translate>lpc_end_date_profit</span>
        <span class="tbld_col_title">: </span>
        <span class="tbld_col_value" data-qa="lpc_end_date_profit">
          {{revaluationRates[revaluationRates?.length - 1].fromDate | date: 'dd/MM/yyyy'}}
        </span>
      </div>
      <div class="col-md-12">
        <span class="tbld_col_title" translate>lpc_last_rate_profit</span>
        <span class="tbld_col_title">: </span>
        <span class="tbld_col_value" data-qa="lpc_last_rate_profit">
          {{lastRatesProfit}}
        </span>
      </div>
      <div class="col-md-12" *ngIf="!isFromConsultation">
        <span class="tbld_col_title" translate>lpc_volatility</span>
        <span class="tbld_col_title">: </span>
        <span class="tbld_col_value" data-qa="fund_type">
          {{getVolatilityValue(fundsDetail)}}
        </span>
      </div>
      <div class="col-md-12" *ngIf="!isFromConsultation">
        <span class="tbld_col_title" translate>lpc_volatility_date</span>
        <span class="tbld_col_title">: </span>
        <span class="tbld_col_value" data-qa="fund_type">
          {{getLastVolatilityDate(fundsDetail)}}
        </span>
      </div>
<!--       <div class="col-md-12">
        <span class="tbld_col_title" translate>Geographic area</span>
        <span class="tbld_col_title">: </span>
        <span class="tbld_col_value" data-qa="georgraphic_area">
          {{fundsDetail?.area}}
        </span>
      </div> -->
      <div class="col-md-12" *ngIf="!isFromConsultation">
        <span class="tbld_col_title" translate>lpc_start_quotation</span>
        <span class="tbld_col_title">: </span>
        <span class="tbld_col_value" data-qa="first_quotation_start">
          {{fundsDetail.startDate | date: 'dd/MM/yyyy'}}
        </span>
      </div>
      <div class="col-md-12" *ngIf="!isFromConsultation">
        <span class="tbld_col_title" translate>lpc_ISIN_code</span>
        <span class="tbld_col_title">: </span>
        <span class="tbld_col_value" data-qa="last_quotation_end">
          {{fundsDetail.isinSymbol}}
        </span>
      </div>
      <div class="col-md-12" *ngIf="!isFromConsultation">
        <span class="tbld_col_title" translate>Fund Code</span>
        <span class="tbld_col_title">: </span>
        <span class="tbld_col_value" data-qa="fund_code">
          {{(!!fundsDetail.fundCode) ? fundsDetail.fundCode : '-'}}
        </span>
      </div>
    </div>
  </div>
</div>

<!-- INIZIO BANDONE DATI GENERALI FONDO -->
<div *ngIf="!isFromConsultation" class="col-md-12 padding-card">
  <div class="col-md-6 life-detail-infobox">
    <div class="col-md-12">
      <span class="life-increase-font-size" *ngIf="fundsDetail.lastDate" data-qa="isinCode">
        {{fundsDetail.lastDate | date: 'dd/MM/yyyy'}}
      </span>
      <span class="life-increase-font-size" *ngIf="!fundsDetail.lastDate" data-qa="isinCode">
        -
      </span>
    </div>
    <div class="col-md-12">
      <span class="tbld_col_title" style="color: white; font-size: 18px;" translate>lpc_last_quotation</span>
    </div>
  </div>
  <div class="col-md-6 life-detail-infobox">
    <div class="col-md-12">
      <span class="life-increase-font-size" data-qa="lastQuoteValue">
        {{fundsDetail.lastQuoteValue.toString() | rgiCtryLNumFormatter : '' : numberFormatterOptions}}
      </span>
      <span class="life-increase-font-size" data-qa="currency">
        {{fundsDetail.currency}}
      </span>
    </div>
    <div class="col-md-12">
      <span class="tbld_col_title" style="color: white; font-size: 18px;" translate>lpc_last_odds_value</span>
    </div>
  </div>
</div>
<!-- FINE BANDONE DATI GENERALI FONDO -->
