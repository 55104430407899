import { Component, Input } from '@angular/core';
import { ReservationSubjects } from '../../../../models/life-detail.model';

@Component({
  selector: 'lpc-lb-subjects',
  templateUrl: './lb-subjects.component.html',
})
export class LbSubjectsComponent {
  // formatter options
  public percentFormatterOptions: Intl.NumberFormatOptions = {
    style: 'percent',
    maximumFractionDigits: 2,
    minimumFractionDigits: 2
  };

  @Input() beneficiaries: ReservationSubjects[];
}
