<div class="col-md-12 life-detail-margin-box life-detail-box" data-qa="funds-table">
  <div class="row life-detail-card-title-container">
    <div class="row life-detail-title">
      <div class="card-title">
        <span class="header-icon rgifont"></span>
        <span data-qa="funds-table-title" class="menu-title" translate>lic_details</span>
      </div>
    </div>
  </div>

  <ng-container *ngFor="let disinvestmentFund of toolData">
    <ng-container *ngIf="!!disinvestmentFund.fund">
      <div class="fund-content-detail row">
        <div class="col-md-12">
          <span data-qa="starting-fund-title" class="tbld_col_title" translate>lic_starting_fund</span>
          <span class="tbld_col_title">: </span>
          <span data-qa="starting-fund-description"
                class="tbld_col_value"> {{disinvestmentFund.fund.description}} </span>
        </div>
        <div class="col-md-12">
          <span data-qa="threshold-title" class="tbld_col_title" translate>Threshold</span>
          <span class="tbld_col_title">: </span>
          <span data-qa="threshold"
                class="tbld_col_value"> {{disinvestmentFund.fund.percent / 100 | rgiCtryLNumFormatter : locale : optionPercents }} </span>
        </div>
      </div>
    </ng-container>

    <div class="tbld life-detail-margin-top">
      <div class="tbld_row life-detail-tbld_row_header">
        <div data-qa="categoria-fondo" class="tbld_col tbld_col_title" translate>lic_fund_category</div>
        <div data-qa="percentuale" class="tbld_col tbld_col_title" translate>lic_Percentage</div>
      </div>
      <ng-container *ngFor="let profile of disinvestmentFund.profiles">
        <div class="tbld_row life-detail-investment-profile-row bolder">
          <div [attr.data-qa]="'profile_description' + profile.id"
               class="tbld_col tbld_col_value life-detail-table-border">{{profile.description}}</div>
          <div class="tbld_col tbld_col_value life-detail-table-border" [attr.data-qa]="'-profilePercent' + profile.id">
            {{profile.percent / 100 | rgiCtryLNumFormatter : locale : optionPercents }}</div>
        </div>
        <div *ngFor="let fund of profile.funds" class="tbld_row regular">
          <div [attr.data-qa]="'fund_description' + profile.id + fund.id"
               class="tbld_col tbld_col_value life-detail-table-border"> {{fund.description}}</div>
          <div class="tbld_col tbld_col_value life-detail-table-border"
               [attr.data-qa]=" '-fundPercent' + profile.id + fund.id">
            {{fund.percent / 100 | rgiCtryLNumFormatter : locale : optionPercents }}</div>

        </div>
      </ng-container>
      <div class="tbld_row life-detail-tbld_row_header">
        <div data-qa="total" class="tbld_col tbld_col_title" translate>Total</div>
        <div class="tbld_col tbld_col_title"
             translate>{{+totalPercentSummary(disinvestmentFund.profiles) / 100 | rgiCtryLNumFormatter : locale : optionPercents }}</div>
      </div>
    </div>
  </ng-container>
</div>
