<form #windowScope [formGroup]="customFormGroup">

        <div class="panel-body col-lg-6" *ngFor="let quest of questionnairesList">

          <div *ngIf="customFormGroup.get(quest.code).valid && customFormGroup.get(quest.code).value === 'COMPILED'" class="questionnaire-block-white col-xs-1">
            <div class="col-xs-12">
              <span class="rgifont font-survey top-margin-ico rgi-survey_ok font-survey-ok"></span>
            </div>
          </div>
          <div *ngIf="customFormGroup.get(quest.code).invalid" class="questionnaire-block-white col-xs-1">
            <div class="col-xs-12">
              <span class="rgifont font-survey top-margin-ico rgi-survey_ko font-survey-ko"></span>
            </div>
          </div>
          <div *ngIf="customFormGroup.get(quest.code).valid && customFormGroup.get(quest.code).value !== 'COMPILED'" class="questionnaire-block-white col-xs-1">
            <div class="col-xs-12">
              <span class="rgifont font-survey top-margin-ico rgi-survey_ok font-survey-orange"></span>
            </div>
          </div>
          <!-- <div class="questionarie-box"> -->
            <div class="col-xs-11">
              <div class="col-xs-12">
                <div class="col-xs-1 custom-arrow-left"></div>
                <div class="col-xs-11 large questionnaire-block">
                  <div class="center-questionarie-block">
                    <div class="col-xs-9">
                      <span>{{quest.description}}</span>
                    </div>
                    <div class="col-xs-3">
                      <div class="to-right">
                        <button type="button" class="btn btn-default btn-address btn-xs" id="questionarieIcon" (click)="openQuest(quest.code)" data-qa="mic-button-file-holder">
                                <span class="rgifont rgi-pencil-square-o"></span>
                        </button>

                        <button *ngIf="customFormGroup.get(quest.code).valid && customFormGroup.get(quest.code).value === 'COMPILED'" id="questionarieIcon" type="button" class="btn btn-default btn-address btn-xs" (click)="deleteQuest(quest.code)" data-qa="mic-button-trash-holder">
                                <span class="rgifont rgi-trash"></span>
                        </button>
                          <!-- <input type="image" class="button unitIcon" alt="" (click)="openModal(unit.code)"
                          *ngIf="!unitsForm.get(instance.name).get(sect.code).get(unit.code).invalid" src="" /> -->

                        <!-- <button type="button" class="btn btn-default">
                          <span class="rgifont rgi-file-text"></span>
                        </button>
                        <button  type="button" class="btn btn-default" >
                          <span class="rgifont rgi-trash"></span>
                        </button> -->
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        <!-- </div> -->

</form>

<div *ngIf="viewQ" class="questionnaire-page">
  <lic-modal-basic modalBodyId="modalBody" dialogClass="quest-modal">
    <div class="app-modal-header">
      <div class="modal-title" translate>
        Questionnaire
      </div>
    </div>
    <div class="app-modal-body app-modal-quest">
      <ppevo-questionnaire [questionnaireFlat]="questionnaireFlat"></ppevo-questionnaire>
      <!-- MESSAGGI di ERRORE -->
      <rgi-lcc-messages-floating 
      containerId="modalBody" 
      *ngIf="validationMessages && validationMessages.length > 0" 
      [validationMessages]="validationMessages"> 
    </rgi-lcc-messages-floating>
    </div>
    <div class="app-modal-footer">
      <div class="btn-group btn-group-justified"
        style="margin-top: 0px !important; border: 0px !important; border-right: 0px !important;">
        <div class="btn-group">
          <button class="btn btn-warning btn-secondary" (click)="closeQuest()" translate>CANCEL</button>
        </div>
        <div class="btn-group" >
          <button class="btn btn-warning" (click)="saveQuest(questionnaireFlat.code)" translate>CONFIRM</button>
        </div>
      </div>
    </div>
  </lic-modal-basic>
</div>
