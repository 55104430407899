import { Directive, Input, HostListener, ElementRef } from '@angular/core';
import { RgiCountryLayerNumberFormatPipe } from '@rgi/country-layer';

@Directive({
  selector: '[appFormatDisplayValue]'
})
export class FormatDisplayValueDirective {
  @Input('appFormatDisplayValue') formatOptions: Intl.NumberFormatOptions;

  constructor(private el: ElementRef, protected rgiNumberFormatter: RgiCountryLayerNumberFormatPipe) {}

  @HostListener('blur', ['$event.target.value'])
  onInput(value: string) {
    const formattedValue = this.formatDisplayValue(value, this.formatOptions);
    this.el.nativeElement.value = formattedValue;
  }

  formatDisplayValue(value: string, options: any): string {
    const formattedValue = this.rgiNumberFormatter.transform(value, '', options);
    return formattedValue; 
  }

}