import { UntypedFormGroup } from "@angular/forms";
import { RequestFactor } from "../../../models/factor.model";
import { FactorDefinition } from "../../../models/postsales-operations-response.model";
import { PlcDateUtils } from "../../../utils/plc-date-utils";

export class PensionBuyBackUtils {
    public static convertFormFactors(
        form: UntypedFormGroup,
        factorDefinitionList: FactorDefinition[],
      ): RequestFactor[] {
        const mapTypeCode = new Map([
            ['0', 'list'],
            ['1', 'number'],
            ['2', 'list'],
            ['3', 'boolean'],
            ['4', 'date'],
            ['5', 'string']
          ]);
        const converted: RequestFactor[] = [];
        if (form != null && form.controls != null) {
          Object.keys(form.controls).forEach(key => {
            converted.push({
              code: key,
              label: null,
              value: null,
              userInput: false,
              typeCode: null
            });
          });
        }
      
        converted.map(factor => {
          const definitionFactor: FactorDefinition = factorDefinitionList.find(f => f.code === factor.code);
          const actualValue = form.get(factor.code).value;
          if (definitionFactor != null) {
            if (mapTypeCode.get(definitionFactor.typeCode) === 'date') {
              factor.value = PlcDateUtils.localDateToString(PlcDateUtils.dateToLocaleDate(actualValue));
            } else {
              factor.value = actualValue;
            }
            if (definitionFactor.defaultValue !== factor.value) {
              factor.userInput = true;
            }
            factor.label = definitionFactor.label;
            factor.typeCode = definitionFactor.typeCode;
          } else {
            console.error('Fattore ' + factor.code + ' non trovato nelle definitions');
          }
        });
      
        return converted;
      }
}
