import { LpcClaimBeneficiaryComponent } from './../modules/lpc-beneficiary/lpc-claim-beneficiary/lpc-claim-beneficiary.component';
import { Injectable } from '@angular/core';
import { Observable, combineLatest, forkJoin, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { ClaimBeneficiary } from '../modules/lpc-beneficiary/model/claim-beneficiary';
import { LpcClaimBeneficiariesComponent } from '../modules/lpc-beneficiary/lpc-claim-beneficiaries/lpc-claim-beneficiaries.component';
import { Survey } from '@rgi/questionnaires-manager';
import { STEP } from '../models/consts/lpc-consts';

@Injectable()
export class LpcBeneficiaryService {
  private targhetBeneficiaries: LpcClaimBeneficiariesComponent;

  constructor() { }

  resetTargetBeneficiaries() {
    this.targhetBeneficiaries = null;
  }

  setTarghetBeneficiaries(beneficiaries: LpcClaimBeneficiariesComponent) {
    this.targhetBeneficiaries = beneficiaries;
  }

  checkAllQuestionnaires(step, onError): boolean {
    const questsValid = !!this.targhetBeneficiaries && this.targhetBeneficiaries.beneficiary.some(benef => {
      return (benef.isQuestionnaireValid());
    });
    if (step === STEP.BENEFICIARIES.id && !questsValid) {
      onError(step);
      return false;
    }
    return true;
  }

  persistQuestionnaires(step): Observable<any> {
    if (!!this.targhetBeneficiaries && step === STEP.BENEFICIARIES.id) {
      const $allPersist = [];
      this.targhetBeneficiaries.beneficiary.forEach(x => {
        // controllo se ho i questionari in quel beneficiario
        if (!!x.avcQuestionnaire && x.avcQuestionnaire.getQuest().length > 0) {
          $allPersist.push(x.persist());
        }
      });

      if ($allPersist.length > 0) {
        return forkJoin(...$allPersist)
          .pipe(
            switchMap((savedQuest: (Map<string, Survey[]>[])) => {
              const fillForms = [];
              if (savedQuest && savedQuest.length > 0) {
                savedQuest.forEach(elem => {
                  for (const key of elem.keys()) {
                    const beneficiaryComponent: LpcClaimBeneficiaryComponent = this.targhetBeneficiaries.beneficiary.find(x => x.questionnaireKey === key);
                    fillForms.push(beneficiaryComponent.fillForm(elem.get(key)));
                  }
                });
                return combineLatest(fillForms);
              } else {
                return of(null);
              }
            }),
            switchMap(res => {
              // WA: added the onChange of the current component with the formGroup of the child component
              // to get correctly the values updated after the persist of the questionnaries.
              // FIXME: with a correct implementation of the ControlValueAccessor if there's a solution
              const childBenef: ClaimBeneficiary[] = this.targhetBeneficiaries.beneficiary
                .filter(val => !!val.formGroup)
                .map(ben => ben.formGroup.getRawValue());
                this.targhetBeneficiaries.onChange(childBenef);
              return of(res);
            }));
      }
    }

    return of(null);
  }

}
