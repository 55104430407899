import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { FormControl, FormGroup, UntypedFormGroup } from '@angular/forms';
import { Observable, combineLatest, of } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';
import { ApiDialogueHandlerFilter } from '../api/api-dialogue-handler-filter';
// import { DialogueHandlerList } from '../dto/dialogue-handler-list';
// import { DialogueHandler } from '../dto/dialogue-handler';
import { DetailObjectToList, DialogueHandlerSearch, ListObjectToSummary, NewDialogueState, ObjectToNew, ObjectToResp, SummaryState } from '../dialogue-handler-configuration/dialogue-handler.model';
import { DialogueHandlerIntegrationService } from '../dialogue-handler-configuration/dialogue-handler-integration.service';
import { ActiveRoute } from '@rgi/rx/router';
import { DialogueRoleList, ResponseObject } from '../dialogue-handler-configuration/new-dialogue.model';
import { restEndPoint } from '../dialogue-handler-configuration/dialogue-handler.endpoint';
import { CausalData, CausalResponse, DettaglioSinistroDenuncia, DialogueSummaryObject, MessageList, OutcomeService, SummaryForm } from '../dialogue-handler-configuration/dh-summary.model';
import { state } from '@angular/animations';
import { DialogueStatus } from '../dialogue-summary/dialogue-summary.component';
import { RgiRxTranslationService } from '@rgi/rx/i18n';
import { ListDialogueViewConfiguration } from '../constants/listEnumConstants';
//import { DialogueHandlerSummaryStateManager } from '@rgi/digital-claims-angular/lib/gedi/dialogue-handler/dialogue-handler-service/dialogue-handler-summary.state.manager';
// import { DialogueHandlerListResponse } from '@rgi/digital-claims-angular/lib/gedi/dialogue-handler/dialogue-handler-configuration/dialogue-handler-list.model';

@Injectable({
  providedIn: 'root'
})

export class DialogueHandlerSummaryService {

  private filterElaborationRequest: ApiDialogueHandlerFilter[];
  private urlDialogueHandlerList = '/claims/dialogueHandlerSearch';
  private baseApiUrlV2: string;
  private filterDialogueHandler: ApiDialogueHandlerFilter[];

  constructor(
    private httpClient: HttpClient,
    private integrationService: DialogueHandlerIntegrationService,
    public translateService: RgiRxTranslationService,
    @Inject('environment') environment: any,
    @Inject('sessionService') private sessionService: any,
  ) {
    this.baseApiUrlV2 = environment.api.portal.host + environment.api.portal.path + '/v2';
  }

  getCurrentSessionId() {
    const idSession = this.sessionService.list().find(el => el.isActive)?.idSession;
    return idSession ? idSession : null;
  }

  initStateObjectbyId(objectFromSx: any, st: Observable<SummaryState>): Observable<SummaryState> {
    return st.pipe(
      mergeMap((state) => {
        let queryString = '';
        queryString += "?idClnotifcard=" + objectFromSx.idClnotifcard;
        const responseSummary =
          this.httpClient.get<DialogueSummaryObject>(this.baseApiUrlV2 + restEndPoint.summary + queryString);
        return combineLatest([responseSummary, of(state)]);
      }),
      mergeMap(([responseSummary, state]) => {
        if (responseSummary) {
          // if (responseSummary.messageList.length > 1) {
          //   this.sortListByDate(responseSummary);
          // }
          state.dialogueSummaryObject = responseSummary;
          state.searchWrapperObject = null;
          state.viewConfig = null;
          state.responseFromSearchList = objectFromSx;
          state.idClnotifcard = objectFromSx.idClnotifcard;
          state.checkList = ListDialogueViewConfiguration.DOUBLE_LIST_VIEW;
        }
        else {
          console.error('Risposta non valida dalla chiamata API o non è un array');
        }
        return of(state)
      })
    )
  }

  // sortListByDate(dialogueSummaryObject: DialogueSummaryObject) {
  //   dialogueSummaryObject.messageList.sort((obj1, obj2) => {
  //     let dIns1 = new Date(obj1.dIns);
  //     let dIns2 = new Date(obj2.dIns);
  //     return dIns1.getTime() - dIns2.getTime();
  //   }
  //   );
  // }

  initStateObject(routerData: ListObjectToSummary, st: Observable<SummaryState>): Observable<SummaryState> {
    return st.pipe(
      map(state => {
        // this.sortListByDate(routerData.dialogueSummaryObject);
        state.dialogueSummaryObject = routerData.dialogueSummaryObject;
        state.searchWrapperObject = routerData.searchWrapperObject;
        state.viewConfig = routerData.viewConfig;
        state.responseFromSearchList = routerData.responseFromSearchList;
        state.idParentSession = routerData.idParentSession;
        state.outcomeService = routerData.outcomeService;
        state.checkList = routerData.checkList;
        state.dialogueList = routerData.dialogueList;
        state.formSearch = routerData.formSearch;
        if(routerData.idClnotifcard){
          state.idClnotifcard = routerData.idClnotifcard;
        } else {
          state.idClnotifcard = routerData.responseFromSearchList?.idClnotifcard;
        }
        return state;
      })
    );
  }

  getDettaglioSinistroDenuncia(st: Observable<SummaryState>) {
    return st.pipe(
      mergeMap((state) => {
        let queryString = '';
        if (state.idClnotifcard) { queryString += "&idDialogo=" + state.idClnotifcard };
        if (state.dialogueSummaryObject.idCl) { queryString += "&idSinistro=" + state.dialogueSummaryObject.idCl };
        if (!state.dialogueSummaryObject.idCl && state.dialogueSummaryObject.idPotClaim) { queryString += "&idDenuncia=" + state.dialogueSummaryObject.idPotClaim }

        if (queryString.length > 1) { queryString = "?" + queryString.slice(1); }
        const dettaglioSinistroDenuncia =
          this.httpClient.get<DettaglioSinistroDenuncia>(this.baseApiUrlV2 + restEndPoint.dettaglioSinistroDenuncia + queryString);
        return combineLatest([dettaglioSinistroDenuncia, of(state)]);
      }),
      mergeMap(([dettaglioSinistroDenuncia, state]) => {
        state.dettaglioSinistroDenuncia = dettaglioSinistroDenuncia;
        return of(state)
      })
    )
  }

  getNewDialogueData(st: Observable<SummaryState>, activeRouteData: ActiveRoute, viewCond: string, ctx: MessageList) {
    return st.pipe(
      mergeMap((state) => {
        let queryString = '';
        // console.log(state.dialogueSummaryObject.codFunction)
        queryString += "?idFunStatusCurrent=" + ctx.inputIdMessage;
        const responseRoleList =
          this.httpClient.get<ResponseObject>(this.baseApiUrlV2 + restEndPoint.response + queryString);
        return combineLatest([responseRoleList, of(state)]);
      }),
      mergeMap(([responseRoleList, state]) => {
        if (responseRoleList) {
          const objectToResp: ObjectToResp = {
            searchWrapperObject: state.searchWrapperObject,
            viewConfig: viewCond,
            list: state.responseFromSearchList,
            dialogueSummaryObject: state.dialogueSummaryObject,
            responseObject: responseRoleList,
            messageList: ctx,
            idParentSession:  this.getCurrentSessionId()
          }
          this.integrationService.openNewDialogue(objectToResp, activeRouteData);
        }
        else {
          console.error('Risposta non valida dalla chiamata API o non è un array');
        }

        return of(state)
      })
    )
  }

  cancelDialogue(st: Observable<SummaryState>, activeRouteData: ActiveRoute) {
    return st.pipe(
      mergeMap((state) => {
        const responseSummary =
        this.httpClient.put<OutcomeService>(this.baseApiUrlV2 + restEndPoint.cancelService + state.idClnotifcard, {});
        return combineLatest([responseSummary, of(state)]);
      }),
      mergeMap(([responseSummary, state]) => {
        state.outcomeService = responseSummary;
        let queryString = '';
        queryString += "?idClnotifcard=" + state.idClnotifcard;
        const response = this.httpClient.get<DialogueSummaryObject>(this.baseApiUrlV2 + restEndPoint.summary + queryString);
        return combineLatest([response, of(state)]);
      }),
      mergeMap(([response, state]) => {
        let objectToSummary: ListObjectToSummary = {
          searchWrapperObject: state.searchWrapperObject,
          dialogueSummaryObject: response,
          responseFromSearchList: state.responseFromSearchList,
          viewConfig: state.viewConfig,
          idParentSession: state.idParentSession,
          outcomeService: state.outcomeService,
          idClnotifcard: state.idClnotifcard,
          formSearch: state.formSearch,
          dialogueList: state.dialogueList,
          checkList: state.checkList
        }
        let translateStatus_CANCEL_MESSAGE = '';
        this.translateService.translate(DialogueStatus.CANCEL_MESSAGE).subscribe(res => {
          translateStatus_CANCEL_MESSAGE = res;
        });

        objectToSummary.outcomeService.descr = translateStatus_CANCEL_MESSAGE;

        this.integrationService.goToDialogueHandlerSummary(objectToSummary, activeRouteData);
        return of(state)
      }),
    )
  }

  resetAdvance(st: Observable<SummaryState>, idHistoryStatus: number) {
    return st.pipe(
      mergeMap((state) => {
        const responseSummary =
          this.httpClient.put<OutcomeService>(this.baseApiUrlV2 + restEndPoint.resetAdvanceService + idHistoryStatus, {});
        return combineLatest([responseSummary, of(state)]);
      }),
      mergeMap(([responseSummary, state]) => {
        state.outcomeService = responseSummary;
        return of(state)
      })
    )
  }

  searchCausal(st: Observable<SummaryState>) {
    return st.pipe(
      mergeMap((state) => {
        let queryString = '';
        if (state.dialogueSummaryObject.codFunction) { queryString += "&functionCode=" + state.dialogueSummaryObject.codFunction; }
        if (state.dialogueSummaryObject.dialogueSubject) { queryString += "&dialogueSubjectCode=" + state.dialogueSummaryObject.dialogueSubject.code; }
        if (state.summaryForm.claimNumberPass) { queryString += "&claimNumberPass=" + state.summaryForm.claimNumberPass; }
        if (state.dialogueSummaryObject.roleCompany) { queryString += "&ruolo=" + state.dialogueSummaryObject.roleCompany.code; }


        if (queryString.length > 1) { queryString = "?" + queryString.slice(1); }
        const responseSummary =
          this.httpClient.get<CausalResponse>(this.baseApiUrlV2 + restEndPoint.attoreCausaleBySinistroList + queryString);
        return combineLatest([responseSummary, of(state)]);
      }),
      mergeMap(([responseSummary, state]) => {
        state.causalResponse =
        {
          requestOutcome: responseSummary.requestOutcome,
          response: responseSummary.response.map((item: any) => ({
            ...item,
            selected: false
          }))
        }
        return of(state)
      })
    )
  }

  updateSummaryForm(summaryForm: SummaryForm, st: Observable<SummaryState>): Observable<SummaryState> {
    return st.pipe(
      map((state) => {

        state.summaryForm = summaryForm;
        return state;
      })
    );
  }

  associateClaim(st: Observable<SummaryState>, localCausals: CausalData[]): Observable<SummaryState> {
    return st.pipe(
      mergeMap((state) => {
        state.causalResponse = null;
        const associatedDialogueRequest = {
          idClnotifcard: state.responseFromSearchList.idClnotifcard,
          accNumber: state.summaryForm.claimNumberPass,
          lstIdTypeOfLoss: localCausals.map(elem => elem.causaleId),
        }
        console.log(localCausals)
        console.error(associatedDialogueRequest)
        const responseSummary =
          this.httpClient.post<OutcomeService>(this.baseApiUrlV2 + restEndPoint.associatedDialogue, associatedDialogueRequest);
        return combineLatest([responseSummary, of(state)]);
      }),
      mergeMap(([responseSummary, state]) => {
        state.outcomeService = responseSummary;
        console.error(state.dialogueSummaryObject.numCl);
        console.warn(state.summaryForm.claimNumberPass);
        if(state.outcomeService.code != '0'){
          state.dialogueSummaryObject.numCl = state.summaryForm.claimNumberPass
        }
        return of(state)
      })
    )
  }

  verifyReassociation(st: Observable<SummaryState>) {
    console.log("test 2")
    return st.pipe(
      mergeMap((state) => {
        console.log("test 3")
        let queryString = "?idDialogo=" + state.idClnotifcard;
        return this.httpClient.get<any>(this.baseApiUrlV2 + restEndPoint.isReassociationPossible + queryString).pipe(
          map((reassociation) => {
            state.reassociationPossible = reassociation.reassociationPossible;
            return state;
          })
        )
      })
    )

  }

  closeSession$(activeRouteId: string, state: SummaryState) {
    this.sessionService.remove(activeRouteId);
    if (state.idParentSession !== null) {
      this.sessionService.setActive(state.idParentSession);
    }
  }

  goToDialogueList(st: Observable<SummaryState>, activeRouteData: ActiveRoute, viewCond: string){
    return st.pipe(
      mergeMap((state) => {
            const objectToList: DetailObjectToList = {
              formSearch: state.formSearch,
              responseFromSearchList: state.dialogueList,
              searchWrapperObject: state.searchWrapperObject,

              viewConfig: viewCond
            }
            state = null;
            this.integrationService.goToDialogueHandlerList(objectToList, activeRouteData);
       
        return of(state)
      }),
    )
  }
}
