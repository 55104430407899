<table style="width:100%" [formGroup]="formGroup" class="settlement-table active" [attr.data-qa]="'liquidation-table'">
  <thead>
    <tr>
      <th class="life-detail-table-width-5"></th>
      <th class="life-detail-table-width-35" translate>lpc_movement_description</th>
      <th class="life-detail-table-width-20" translate>lpc_EffectiveDate</th>
      <th class="life-detail-table-width-20" translate>lpc_issueDate</th>
      <th class="life-detail-table-width-20" translate>lpc_settlement_amount</th>
    </tr>
  </thead>
  <ng-container *ngFor="let settl of liquidationDefinition; let i = index;">
    <tbody>
      <tr>
        <td class="type-flex" >
          <input formControlName="selectsettlement" type="radio" name="selectsettlement" [value]="settl.settlementKey"/>
        </td>
        <td [attr.data-qa]="'td-liquidation-movementDescription'" class="life-detail-table-width-35">{{settl.movementDescription}}</td>
        <td [attr.data-qa]="'td-liquidation-effectiveDate'" class="life-detail-table-width-20">{{settl.effectiveDate}}</td>
        <td [attr.data-qa]="'td-liquidation-issueDate'" class="life-detail-table-width-20">{{settl.issueDate}}</td>
        <td [attr.data-qa]="'td-liquidation-grossAmount'" class="life-detail-table-width-20">{{settl.grossAmount | rgiCtryLNumFormatter : '': currencyFormatterOptions}}</td>
      </tr>
    </tbody>
  </ng-container>
</table>

<div class="col-md-12 inactive">
  <div>
    <span for="label">{{'lpc_selected_settlement' | translate}}: </span>
    <span for="value" [attr.data-qa]="'selected-settlement-value'">{{getSelectedSettlementSummary()}}</span>
  </div>
</div>



