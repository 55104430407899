import {LicCheckboxAccordionModule} from './../invest-standalone-session/component/lic-checkbox-accordion/lic-checkbox-accordion.module';
import {NgSelectModule} from '@ng-select/ng-select';
import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {LicGeneratorComponent} from '../lic-generator/lic-generator.component';
import {PortalNgCoreModule} from '@rgi/portal-ng-core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {LicToolComponent} from './component/lic-tool/lic-tool.component';
import {LicFundModule} from '../lic-fund/lic-fund.module';
import {AngularIbanModule} from 'angular-iban';
import {LicKarmaFundModule} from '../lic-karma-funds/lic-karma-fund.module';
import {LicToolWithAccordionComponent} from './component/lic-tool-with-accordion/lic-tool-with-accordion.component';
import { SelectSubjectModule } from '../life-issue-card/life-role/select-subject/select-subject.module';
import { LicDatepickerModule } from '../lic-datepicker/lic-datepicker/lic-datepicker.module';
import { RgiCountryLayerModule } from '@rgi/rgi-country-layer';
// eslint-disable-next-line max-len
import { ContractualOptionNumberFormatterDirective } from '../lic-generator/contractual-option-number-formatter/contractual-option-number-formatter.directive';
import { RgiRxI18nModule } from '@rgi/rx/i18n';
import { RgiRxFormElementsModule } from '@rgi/rx/ui';
import { LicRequiredModule } from '../utils/lic-required/lic-required.module';

@NgModule({
    declarations: [LicGeneratorComponent, LicToolComponent, LicToolWithAccordionComponent, ContractualOptionNumberFormatterDirective],
    exports: [
        LicGeneratorComponent,
        LicToolComponent,
        LicToolWithAccordionComponent,
        ContractualOptionNumberFormatterDirective
    ],
    imports: [
        CommonModule,
        PortalNgCoreModule,
        AngularIbanModule,
        ReactiveFormsModule,
        NgSelectModule,
        LicDatepickerModule,
        LicFundModule,
        LicCheckboxAccordionModule,
        LicKarmaFundModule,
        FormsModule,
        SelectSubjectModule,
        RgiCountryLayerModule,
        RgiRxI18nModule,
        RgiRxFormElementsModule,
        LicRequiredModule
    ]
})
export class LicInvestmentContractModule {
}
