
<div class="rgi-ui-panel-container">
  <div class="rgi-ui-panel-header rgi-anag-p-1">
    <span class="rgi-ui-panel-title rgi-ui-text-1" translate>{{ 'Consensus and Contacts' | translate}}</span>
    <span class="rgi-ui-btn-close" data-qa="close-modal" (click)="actionClose()"></span>
  </div>
  <ng-container [formGroup]="privacyForm">
    <div class="rgi-ui-panel-content" >
      <div class="rgi-ui-grid-3" *ngFor="let question of privacyQuestions">
        <div class="rgi-ui-col">
          <rgi-rx-form-field>
            <label rgiRxLabel>{{ question.descrizione }}</label>
            <select rgiRxNativeSelect class="rgi-ui-form-control" [formControlName]="question.codice"
              [attr.data-qa]="'question-'+question.codice">
              <option selected> </option>
              <option *ngFor="let opt of questionOptions" [value]="opt.codice">{{opt.descrizione}}</option>
            </select>
          </rgi-rx-form-field>
        </div>
        <div class="rgi-ui-col">
          <rgi-rx-form-field style="display: contents">
            <div style="display: block">
              <label rgiRxLabel><span>{{ '_ANAG_._LABEL_._PRIVACY_CONSENSUS_DATE_' | translate}}</span></label>
              <div style="display:flex;">
                <input [rgiRxDateTimeInput]="privacyConsensusDatePicker" style="flex: 1"
                  [attr.data-qa]="'date-'+question.codice" [formControlName]="'date-'+question.codice" [max]="maxDate"
                  selectMode="single" rangeSeparator="-" placeholder="{{datepickerPlaceholder}}"
                  #inputToTriggerPrivacyConsensusDate="rgiRxDateTimeInput">
                <button style="flex: 0; order: 1" rgi-rx-button variant="icon" class="rgi-ui-icon-calendar2"
                  [rgiRxDateTimeTrigger]="inputToTriggerPrivacyConsensusDate"></button>
              </div>
            </div>
            <rgi-rx-date-time #privacyConsensusDatePicker pickerType="calendar" [hour12Timer]="true"></rgi-rx-date-time>
          </rgi-rx-form-field>
        </div>
        <div class="rgi-ui-col">
          <rgi-rx-form-field>
            <label rgiRxLabel>{{ '_ANAG_._LABEL_._ORIGIN_' | translate}}</label>
            <select rgiRxNativeSelect [formControlName]="'origin-'+question.codice"
              [attr.data-qa]="'origin-'+question.codice">
              <option value=""></option>
              <option *ngFor="let originOpt of originOptions" [value]="originOpt.codice">{{originOpt.descrizione |
                translate}}</option>
            </select>
          </rgi-rx-form-field>
        </div>
      </div>
    </div>
    <!--
    <div class="rgi-ui-grid-3 rgi-ui-col">
      <div class="rgi-ui-col">
        <rgi-rx-form-field>
          <label rgiRxLabel translate>Mobile Phone Number</label>
          <select rgiRxNativeSelect [formControlName]="'phoneNumberIntPrefix'" data-qa="phoneNumberIntPrefix">
            <option selected value="">{{'Int. Prifix' | translate}}</option>
            <option *ngFor="let prefix of internationalPrefixes"
                    [value]="prefix.code">{{prefix.code + ' - ' + prefix.description}}</option>
          </select>
        </rgi-rx-form-field>
      </div>
      <div class="rgi-ui-col">
        <div class="rgi-ui-form-group">
          <label rgiRxLabel>&nbsp;</label>
          <input rgiRxInput [formControlName]="'phoneNumberPrefix'"
                 data-qa="phoneNumberPrefix"
                 placeholder="{{'Prefix'| translate}}">
        </div>
      </div>
      <div class="rgi-ui-col">
        <div class="rgi-ui-form-group">
          <label rgiRxLabel>&nbsp;</label>
          <input rgiRxInput [formControlName]="'phoneNumber'"
                 data-qa="phoneNumber"
                 placeholder="{{'Number'| translate}}">
        </div>
      </div>
    </div>

    <div class="rgi-ui-col">
      <rgi-rx-form-field>
        <label rgiRxLabel translate>Email Address</label>
        <input rgiRxInput [formControlName]="'email'" data-qa="email">
      </rgi-rx-form-field>
    </div>

    <rgi-rx-snackbar tag="error-tag"></rgi-rx-snackbar>
    -->
    <!--
    <rgi-rx-snackbar tag="privacy-tag"></rgi-rx-snackbar>
    <div class="rgi-ui-panel-footer">
      <button class="rgi-ui-btn rgi-ui-btn-primary" type="button" (click)="actionClose()" data-qa="anag-btn-cancel"
        translate>CANCEL
      </button>
      <button class="rgi-ui-btn rgi-ui-btn-primary" type="button" (click)="confirmChanges()" data-qa="anag-btn-confirm"
        translate>CONFIRM
      </button>
    </div>
    -->
  </ng-container>

  <ng-container [formGroup]="contactsForm">
    <div class="rgi-ui-panel-content">
      <div class="rgi-ui-grid">
        <div class="rgi-ui-grid-1">
          <div class="rgi-ui-grid-3 rgi-ui-col">
            <div class="rgi-ui-col">
              <rgi-rx-form-field *ngIf="internationalPrefixes">
                <label rgiRxLabel translate>_IIAB_._OTP_._MOBILE_PHONE_</label>
                <select rgiRxNativeSelect [formControlName]="'phoneNumberIntPrefix'" data-qa="phoneNumberIntPrefix">
                  <option selected value="">{{'Int. Prifix' | translate}}</option>
                  <option *ngFor="let prefix of internationalPrefixes"
                          [value]="prefix.code">{{prefix.code + ' - ' + prefix.description}}</option>
                </select>
              </rgi-rx-form-field>
              <div class="rgi-ui-form-group" *ngIf="!internationalPrefixes">
                <label rgiRxLabel>&nbsp;</label>
                <input rgiRxInput [formControlName]="'phoneNumberIntPrefix'"
                       data-qa="phoneNumberIntPrefix"
                       placeholder="{{'Int. Prifix'| translate}}">
              </div>
            </div>
            <div class="rgi-ui-col" *ngIf="!isPrefixHidden">
              <rgi-rx-form-field>
                <label rgiRxLabel>&nbsp;</label>
                <input rgiRxInput [formControlName]="'phoneNumberPrefix'"
                       data-qa="phoneNumberPrefix"
                       placeholder="{{'Prefix'| translate}}">
              </rgi-rx-form-field>
            </div>
            <div class="rgi-ui-col">
              <rgi-rx-form-field>
                <label rgiRxLabel>&nbsp;</label>
                <input rgiRxInput [formControlName]="'phoneNumber'"
                       data-qa="phoneNumber"
                       placeholder="{{'Number'| translate}}"
                       (blur)="applyFormat('phoneNumber', contactsForm.controls.phoneNumber.value, 'phoneNumberIntPrefix')" (focusin)="removeFormat('phoneNumber', contactsForm.controls.phoneNumber.value)">
              </rgi-rx-form-field>
            </div>
          </div>

          <div class="rgi-ui-col">
            <rgi-rx-form-field>
              <label rgiRxLabel translate>_ANAG_._LABEL_._EMAIL_</label>
              <input rgiRxInput [formControlName]="'email'" data-qa="email">
            </rgi-rx-form-field>
          </div>
        </div>
      </div>
    </div>
  </ng-container>

  <rgi-rx-snackbar tag="privacy-tag"></rgi-rx-snackbar>
  <ng-container [formGroup]="documentForm">
    <div class="rgi-ui-panel-content" >
      <div class="rgi-ui-grid-2">
        <div class="rgi-ui-col" >
          <rgi-rx-form-field>
            <label rgiRxLabel>{{ '_ANAG_._LABEL_._DOCUMENT_TYPE_' | translate }}</label>
            <select rgiRxNativeSelect [formControlName]="'documentType'" data-qa="rgi-anag-document-type">
              <option selected></option>
              <option *ngFor="let type of documentTypes" [value]="type.codice">{{type.descrizione}}</option>
            </select>
          </rgi-rx-form-field>
        </div>
        <div class="rgi-ui-col" >
          <rgi-rx-form-field>
            <label rgiRxLabel>{{ '_ANAG_._LABEL_._DOCUMENT_NO_' | translate }}</label>
            <input rgiRxInput [formControlName]="'documentNumber'"
                   data-qa="rgi-anag-document-number" placeholder="{{'_ANAG_._LABEL_._DOCUMENT_NO_'| translate}}">
          </rgi-rx-form-field>
        </div>

        <div class="rgi-ui-col">
          <rgi-rx-form-field style="display: contents">
            <div style="display: block">
              <label rgiRxLabel>{{ '_IIAB_._LABEL_._RELEASE_DATE_' | translate }}</label>
              <div style="display:flex;">
                <input [rgiRxDateTimeInput]="releaseDatePicker" style="flex: 1"
                  [attr.data-qa]="'rgi-anag-release-date'"
                  formControlName="releaseDate"
                  selectMode="single"
                  rangeSeparator="-"
                  placeholder="{{datepickerPlaceholder}}"
                  #inputToTriggerReleaseDate="rgiRxDateTimeInput">
                <button style="flex: 0; order: 1" rgi-rx-button variant="icon" class="rgi-ui-icon-calendar2"
                  [rgiRxDateTimeTrigger]="inputToTriggerReleaseDate"></button>
              </div>
            </div>
            <rgi-rx-date-time #releaseDatePicker pickerType="calendar" [hour12Timer]="true"></rgi-rx-date-time>
          </rgi-rx-form-field>
        </div>

        <div class="rgi-ui-col">
          <rgi-rx-form-field style="display: contents">
            <div style="display: block">
              <label rgiRxLabel>{{ '_IIAB_._LABEL_._EXPIRATION_DATE_' | translate }}</label>
              <div style="display:flex;">
                <input [rgiRxDateTimeInput]="expirationDatePicker" style="flex: 1"
                  [attr.data-qa]="'rgi-anag-expiration-date'"
                  formControlName="expirationDate"
                  selectMode="single"
                  rangeSeparator="-"
                  placeholder="{{datepickerPlaceholder}}"
                  #inputToTriggerExpirationDate="rgiRxDateTimeInput">
                <button style="flex: 0; order: 1" rgi-rx-button variant="icon" class="rgi-ui-icon-calendar2"
                  [rgiRxDateTimeTrigger]="inputToTriggerExpirationDate"></button>
              </div>
            </div>
            <rgi-rx-date-time #expirationDatePicker pickerType="calendar" [hour12Timer]="true"></rgi-rx-date-time>
          </rgi-rx-form-field>
        </div>

        <div class="rgi-ui-col" >
          <rgi-rx-form-field>
            <label rgirxlabel>{{ '_ANAG_._LABEL_._RELEASE_LOCATION_' | translate }}</label>
            <input rgiRxInput [formControlName]="'releaseLocation'"
                   data-qa="rgi-anag-release-location" placeholder="{{'_ANAG_._LABEL_._RELEASE_LOCATION_'| translate}}">
          </rgi-rx-form-field>
        </div>
      </div>
    </div>
  </ng-container>
  <div class="rgi-ui-panel-footer">
    <button class="rgi-ui-btn rgi-ui-btn-primary" type="button" (click)="actionClose()" data-qa="anag-btn-cancel"
            translate>CANCEL
    </button>
    <button class="rgi-ui-btn rgi-ui-btn-primary" type="button" (click)="confirmChanges()" data-qa="anag-btn-confirm"
            translate>CONFIRM
    </button>
  </div>
</div>

