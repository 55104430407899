import { Component, Input} from '@angular/core';

@Component({
  selector: 'rgi-lcc-messages',
  styleUrls: ['rgi-lcc-messages.component.css'],
  templateUrl: 'rgi-lcc-messages.component.html',
})
export class LccMessagesComponent  {

  @Input() infoMsgs: any[] | undefined;
  @Input() warningMsgs: any[] | undefined;
  @Input() authoMsgs: any[] | undefined;
  @Input() validationMessages: any[] | undefined;
  @Input() blockingMsgs: any[] | undefined;


  isRed(): boolean {
    return this.hasValidation() || this.hasBlocking()

  }
  isYellow(): boolean {
    return !this.hasValidation() && !this.hasBlocking() && this.hasAuthorizzation()
  }
  isGrey(): boolean {
    return !this.hasValidation() && !this.hasBlocking() && !this.hasAuthorizzation()
  }

  hasInfo(): boolean {
    return !!this.infoMsgs && !!this.infoMsgs.length;
  }
  hasWarning(): boolean {
    return !!this.warningMsgs && !!this.warningMsgs.length;
  }
  hasAuthorizzation(): boolean {
    return !!this.authoMsgs && !!this.authoMsgs.length;
  }
  hasValidation(): boolean {
    return !!this.validationMessages && !!this.validationMessages.length;
  }
  hasBlocking(): boolean {
    return !!this.blockingMsgs && !!this.blockingMsgs.length;
  }

  hasAny(): boolean {
    return this.hasBlocking() || this.hasValidation() || 
           this.hasAuthorizzation() || this.hasWarning() || this.hasInfo();
  }

}
