<form [formGroup]="formGroup">
  <table style="width:100%" formArrayName="loans" class="loan-table active" data-qa="loans-table">
    <thead>
      <tr>
        <th class="life-detail-table-width-10"></th>
        <th class="life-detail-table-width-20" translate>lpc_grant_date</th>
        <th class="life-detail-table-width-20" translate>lpc_EffectiveDate</th>
        <th class="life-detail-table-width-20" translate>lpc_loan_amount</th>
        <th class="life-detail-table-width-20" translate>lpc_return_way</th>
        <th class="life-detail-table-width-20" translate>lpc_interest_rate_type</th>
        <th class="life-detail-table-width-20" translate>lpc_residual_amount</th>
        <th class="life-detail-table-width-35" translate>lpc_refund_type</th>
        <th class="life-detail-table-width-20" translate>lpc_refund_amount</th>
      </tr>
    </thead>
    <ng-container *ngIf="!!loanDefinitions.length">
      <tbody *ngFor="let loan of loanDefinitions; let i = index;">
        <tr [formGroupName]="i">
          <td [attr.data-qa]="'td-selectedLoanId'" class="life-detail-table-width-10">
            <input
              formControlName="selectedLoanId"
              type="radio"
              [attr.data-qa]="'select-loan-'+ i"
              (click)="selectedLoan(i.toString(), loan)"
              [value]="loan.id"/>
          </td>
          <td [attr.data-qa]="'td-grantDate'" class="life-detail-table-width-20">{{loan.grantDate | date: 'dd/MM/yyyy'}}</td>
          <td [attr.data-qa]="'td-effectiveDate'" class="life-detail-table-width-20">{{loan.effectiveDate | date: 'dd/MM/yyyy'}}</td>
          <td [attr.data-qa]="'td-amountLoan'" class="life-detail-table-width-20">{{loan.amountLoan | rgiCtryLNumFormatter :  '' : currencyFormatterOptions}}</td>
          <td [attr.data-qa]="'td-loanRefundProcedure'" class="life-detail-table-width-20">{{loan.loanRefundProcedure}}</td>
          <td [attr.data-qa]="'td-typeOfInterestRate'" class="life-detail-table-width-20">{{loan.typeOfInterestRate}}</td>
          <td [attr.data-qa]="'td-residualAmount'" class="life-detail-table-width-20">{{loan.residualAmount | rgiCtryLNumFormatter :  '' : currencyFormatterOptions}}</td>
          <td [attr.data-qa]="'td-typeOfRefund'" class="life-detail-table-width-35">
            <!-- select per il tipo di rimborso -->
            <ng-select
              class="ng-select"
              [items]="loan.typeOfRefund.values"
              formControlName="selectedTypeOfRefund"
              bindLabel="description"
              bindValue="id"
              [attr.data-qa]="'selected-type-of-refund'"
              [required]="loan.typeOfRefund.mandatory"
              (change)="changeTypeOfRefund($event, i.toString(), loan)"
              [clearable]="false">
            </ng-select>
          </td>
          <td class="life-detail-table-width-20">
            <ng-container *ngIf="!!formGroup && !!formGroup.get('loans').get(i.toString()) && !formGroup.get('loans').get(i.toString()).get('refundAmount'); else showInput" >
              {{loan.amountRefund | rgiCtryLNumFormatter :  '' : currencyFormatterOptions}}
            </ng-container>
            <ng-template #showInput>
              <input
                class="refund-amount"
                formControlName="refundAmount"
                data-qa="refund-amount"
                placeholder="{{loan.amountRefund | rgiCtryLNumFormatter :  '' : currencyFormatterOptions}}"
                [value]="formGroup.get('loans').get(i.toString()).get('refundAmount').value"
                lpcCustomNumberFormatDirective/>
            </ng-template>
          </td>
        </tr>
      </tbody>
    </ng-container>
  </table>

  <!-- Starts the summary section -->
  <div class="col-md-12 inactive" data-qa="summary-table-selected-loan">
    <table style="width:100%" class="loan-table" *ngIf="!!getSelectedLoanSummary()" >
      <thead>
        <tr>
          <th class="life-detail-table-width-10"></th>
          <th class="life-detail-table-width-20" translate>lpc_grant_date</th>
          <th class="life-detail-table-width-20" translate>lpc_EffectiveDate</th>
          <th class="life-detail-table-width-20" translate>lpc_loan_amount</th>
          <th class="life-detail-table-width-20" translate>lpc_return_way</th>
          <th class="life-detail-table-width-20" translate>lpc_interest_rate_type</th>
          <th class="life-detail-table-width-20" translate>lpc_residual_amount</th>
          <th class="life-detail-table-width-35" translate>lpc_refund_type</th>
          <th class="life-detail-table-width-20" translate>lpc_refund_amount</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td class="life-detail-table-width-10">
            <input type="radio" name="selectedLoanId" disabled checked/>
          </td>
          <td [attr.data-qa]="'summary-grantDate'" class="life-detail-table-width-20">{{getSelectedLoanSummary().grantDate | date: 'dd/MM/yyyy'}}</td>
          <td [attr.data-qa]="'summary-effectiveDate'" class="life-detail-table-width-20">{{getSelectedLoanSummary().effectiveDate | date: 'dd/MM/yyyy'}}</td>
          <td [attr.data-qa]="'summary-amountLoan'" class="life-detail-table-width-20">{{getSelectedLoanSummary().amountLoan | rgiCtryLNumFormatter :  '' : currencyFormatterOptions}}</td>
          <td [attr.data-qa]="'summary-loanRefundProcedure'" class="life-detail-table-width-20">{{getSelectedLoanSummary().loanRefundProcedure}}</td>
          <td [attr.data-qa]="'summary-typeOfInterestRate'" class="life-detail-table-width-20">{{getSelectedLoanSummary().typeOfInterestRate}}</td>
          <td [attr.data-qa]="'summary-residualAmount'" class="life-detail-table-width-20">{{getSelectedLoanSummary().residualAmount | rgiCtryLNumFormatter :  '' : currencyFormatterOptions}}</td>
          <td [attr.data-qa]="'summary-typeOfRefund'" class="life-detail-table-width-35">{{getSelectedLoanSummary().typeOfRefund.value}}</td>
          <td [attr.data-qa]="'summary-amountRefund'" class="life-detail-table-width-20">{{getSelectedLoanSummary().amountRefund | rgiCtryLNumFormatter :  '' : currencyFormatterOptions}}</td>
        </tr>
      </tbody>
    </table>
  </div>
</form>




