<rgi-rx-panel [@fadeAnimation]>
  <rgi-rx-panel-header [closeable]="true" (onClose)="closeModal()">
    <!--{{ data?.instName }} - {{data?.mainTitle}}-->
    {{title}}
  </rgi-rx-panel-header>

  <div *ngIf="!!grossDetails">
    <div class="row header-unit-modal">
      <b>{{tableTitle}}</b>
    </div>
    <table class="table-responsive table coreDT life-detail-margin-top">
      <tbody>
      <ng-container *ngFor="let item of grossDetails">
        <tr class="table-row table-background" *ngIf="item.warrantyTypeDescription !== null">
          <td class="table-border" [attr.data-qa]="item.warrantyTypeDescription">{{ item.warrantyTypeDescription }}</td>
          <td class="table-border" [attr.data-qa]="item.amount">{{item.amount | rgiCtryLNumFormatter}}</td>
        </tr>
      </ng-container>
      </tbody>
    </table>
  </div>

  <rgi-rx-panel-footer>
    <button class="rgi-ui-btn rgi-ui-btn-primary" (click)="closeModal()" translate>lpc_close_button</button>
  </rgi-rx-panel-footer>
</rgi-rx-panel>
