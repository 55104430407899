import { DatePipe } from '@angular/common';
import {
  ChangeDetectorRef,
  Component,
  Inject,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { RgiCtryLayerNumFormatterPipe } from '@rgi/rgi-country-layer';
import { RgiRxTranslationService } from '@rgi/rx/i18n';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { RoutableComponent } from '@rgi/portal-ng-core';
import {
  LicInvestmentsComponent,
  CardsNavigationService,
  FundService,
  ActionsService,
  LicCacheService,
  LifeSessionService,
  FormatterUtils,
  LifeRoleService,
  InvestmentPlanCacheService,
  LifeHttpErrorMessageHandlerService
 } from '@rgi/life-issue-card';
import { PolicyServiceExt } from '../../../services/policy-service-ext';

@Component({
  selector: 'lic-invest-standalone-session',
  templateUrl: './lic-investments-ext.component.html',
  styleUrls: ['./lic-investments-ext.component.css'],
  providers: [RgiCtryLayerNumFormatterPipe]
})
export class LicInvestmentsExtComponent extends LicInvestmentsComponent implements OnInit, RoutableComponent, OnDestroy {

  constructor(
    @Inject('$injector') protected injector: any,
    protected cardsNavigationService: CardsNavigationService,
    protected policyService: PolicyServiceExt,
    public formatter: FormatterUtils,
    protected datePipe: DatePipe,
    protected fund: FundService,
    public translate: RgiRxTranslationService,
    protected actionsService: ActionsService,
    protected lifeSessionService: LifeSessionService,
    protected cache: LicCacheService,
    protected scheduledPremiumCache: InvestmentPlanCacheService,
    protected cd: ChangeDetectorRef,
    protected numberPipe: RgiCtryLayerNumFormatterPipe,
    protected lifeRoleService: LifeRoleService,
    protected lifeHttpErrorMessageHandlerService: LifeHttpErrorMessageHandlerService,
    protected modalService: NgbModal
  ) {
    super(injector, cardsNavigationService, policyService, formatter, datePipe, fund, translate,
      actionsService, lifeSessionService, cache, scheduledPremiumCache, cd, numberPipe, lifeRoleService,
      lifeHttpErrorMessageHandlerService, modalService);
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

}
