<div class="col-lg-12 blank">
  <h3 translate *ngIf="!!label">{{label}}</h3>
  <div class="tbld postsales-table-style hidden-xs">
    <ng-container *ngFor="let row of definitions | keyvalue;">
      <div class="tbld_row postsales-row-content" *ngIf="row.value">
        <div class="tbld_col psvalue text-left" [attr.data-qa]="row.value.label+'-label'">
          {{ row.value.label }}
        </div>
        <div class="tbld_col psvalue" [attr.data-qa]="row.value.value+'-value'">
          {{ row.value.value | rgiCtryLNumFormatter: '' : currencyFormatterOptions }}
        </div>
    </div>
    </ng-container>
  </div>
</div>

