<div  *ngIf="!(isOpen && !isActive)" class="active form-field-wrapper">
    <form [formGroup]="formFieldData">
        <ng-container formArrayName="definitionsFields">
            <ng-container *ngIf="!!definitionsFields.length">
                <div *ngFor="let field of definitions; let i=index" class="col-lg-6 factor-box">
                    <div [formGroupName]="i" class="example-row">
                        <ng-container *ngIf="!(field.type === 'DATE')">
                            <label class="label-core">{{field.label}}</label>
                            <lpc-required *ngIf="field.mandatory" required="true | translate"></lpc-required>
                        </ng-container>
                        <ng-container *ngIf="field.type === 'STRING'">
                            <input type="text" [attr.data-qa]="'form-field-' + field.type + '-' + field.code"  [formControlName]="field.code" class="rgi-ui-form-control">
                        </ng-container>
                        <ng-container *ngIf="field.type === 'DOUBLE'">
                            <input type="number" [attr.data-qa]="'form-field-' + field.type + '-' + field.code" [formControlName]="field.code" class="rgi-ui-form-control">
                        </ng-container>
                        <ng-container *ngIf="field.type === 'INT'">
                            <input (paste)="intPasteController($event)" type="number" [attr.data-qa]="'form-field-' + field.type + '-' + field.code"  (keydown)="allowOnlyNumbers($event)" [formControlName]="field.code" class="rgi-ui-form-control">
                        </ng-container>
                        <ng-container *ngIf="field.type === 'DATE'">
                            <lpc-datepicker
                                [disabled]="field.readOnly"
                                [formControlName]="field.code"
                                [required]="field.mandatory"
                                [label]="field.label"
                                [value]="field.value"
                                [id]="'form-field-' + field.type + '-' + field.code">
                            </lpc-datepicker>
                        </ng-container>
                        <ng-container *ngIf="field.type === 'ENUM'">
                            <select [formControlName]="field.code" class="rgi-ui-form-control" [attr.data-qa]="'form-field-' + field.type + '-' + field.code">
                                <option class="rgi-ui-form-control" *ngFor="let option of field.values" [value]="option.id">{{option.description}}</option>
                            </select>
                        </ng-container>
                        <ng-container *ngIf="field.type === 'PARTY'">
                            <ng-container *ngIf="formFieldData && definitionsFields && definitionsFields.get(i.toString()) && definitionsFields.get(i.toString()).get(field.code).value; else empty">
                                <lpc-subject
                                  [customClass]="'form-field-height'"
                                  (delete)="deleteSbj({name: field.code, index: i.toString()})"
                                  [formControlName]="field.code"
                                  [data-qa]="'form-field-' + field.type + '-' + field.code" readonly>
                                </lpc-subject>
                            </ng-container>
                            <ng-template #empty>
                                <input (click)="openAnagSubjectModal({name: field.code, index: i.toString()})"
                                    [value]="'Select'" class="btn-block drop-container dashedSelect"
                                    [attr.data-qa]="'form-field-' + field.type + '-' + field.code" readonly>
                            </ng-template>
                        </ng-container>
                    </div>
                </div>
            </ng-container>
        </ng-container>
    </form>
</div>
<div class="inactive" *ngIf="isOpen && !isActive">
    <ng-container *ngFor="let field of definitions; let i=index">
        <ng-container [ngSwitch]="field.type">
            <ng-container *ngIf="definitionsFields.value[i.toString()][field.code]">
                <div *ngSwitchCase="'ENUM'" [attr.data-qa]="'form-field-' + field.type + '-' + field.code">{{field.label}}: {{(field.values | extractFromArray: 'id': definitionsFields.value[i.toString()][field.code]).description}}</div>
                <div *ngSwitchCase="'PARTY'" [attr.data-qa]="'form-field-' + field.type + '-' + field.code">{{field.label}}: {{definitionsFields.value[i.toString()][field.code].name}}</div>
                <div *ngSwitchCase="'DATE'" [attr.data-qa]="'form-field-' + field.type + '-' + field.code">{{field.label}}: {{definitionsFields.value[i.toString()][field.code] | date: 'dd/MM/yyyy'}}</div>
                <div *ngSwitchDefault [attr.data-qa]="'form-field-' + field.type + '-' + field.code">{{field.label}}: {{definitionsFields.value[i.toString()][field.code]}}</div>
            </ng-container>
        </ng-container>
    </ng-container>
</div>
