<lpc-stepper [formGroup]="formGroup" (stepSlide)="onSlide($event)" [isPublished]="publishMessage !== null">

  <lpc-step
    id="{{STEP.DATE.id}}" label="{{STEP.DATE.label}}"
    [errors]="errors" fieldId="{{STEP.DATE.fieldId}}"
    (next)="onNext($event)"
  >
    <lpc-dates-step
      [inputFields]="dateDefinitions"
      [formControlName]="STEP.DATE.formName"
      (dateChange)="onDateChange($event)"
      (reloadRequest)="updateData()"
      [hide]="activeStep<1">
    </lpc-dates-step>
  </lpc-step>

  <lpc-step *ngIf="isOperationRoleStepPresent"
    id="{{STEP.ROLES.id}}"
    label="{{STEP.ROLES.label}}"
    [errors]="errors"
    fieldId="{{STEP.ROLES.fieldId}}"
    (next)="onNext($event)"
  >
    <lpc-roles-step-wrapper
      [active]="isActiveStep(STEP.ROLES.id)"
      [roles]="operationRoles"
      [operationCode]="session.operation"
      [roleDefinition]="operationRolesAdmitted"
      (roleEvent)="handleRolesEvent($event)">
    </lpc-roles-step-wrapper>
  </lpc-step>

  <lpc-step id="{{STEP.POLICY_FACTORS.id}}" [hidden]="!hasListProductFactor"
    [jump]="!hasListProductFactor"
    [feErrors]="errors"
    label="{{STEP.POLICY_FACTORS.label}}" [errors]="errors"
    fieldId="{{STEP.POLICY_FACTORS.fieldId}}"
    (next)="onNextFactors(STEP.POLICY_FACTORS.id, $event)"
  >
    <div formGroupName="{{STEP.POLICY_FACTORS.formName}}" class="row">
      <lpc-factor-adapter #factorAdapter
        (changeValEmitter)="updateFactors($event)"
        [form]="formGroup.controls[STEP.POLICY_FACTORS.formName]"
        [factors]="listProductFactor"
        [summary]="atSummaryProductFactor">
      </lpc-factor-adapter>
    </div>
  </lpc-step>

  <lpc-step id="{{STEP.ASSET_FACTORS.id}}"
    [hidden]="!hasListGoodsFactor"
    [jump]="!hasListGoodsFactor"
    label="{{STEP.ASSET_FACTORS.label}}"
    [feErrors]="errors"
    [errors]="errors"
    feFieldId="{{STEP.ASSET_FACTORS.fieldId}}"
    fieldId="{{STEP.ASSET_FACTORS.fieldId}}"
    (next)="onNextFactors(STEP.ASSET_FACTORS.id, $event)"
  >
    <div formGroupName="{{STEP.ASSET_FACTORS.formName}}" class="row">
      <lpc-factor-adapter
        #goodsFactorAdapter
        (changeValEmitter)="updateGoodsFactors($event)"
        [form]="formGroup.controls[STEP.ASSET_FACTORS.formName]"
        [factors]="listGoodsFactor"
        [summary]="atSummaryGoodsFactor">
      </lpc-factor-adapter>
    </div>
  </lpc-step>

  <lpc-step id="{{STEP.QUESTS_HLT.id}}"
    [hidden]="!validQuestsCode.get(HEALT_QUEST_CODE)"
    [jump]="!validQuestsCode.get(HEALT_QUEST_CODE)"
    [feErrors]="errors"
    *ngIf="showSectionQuestHltAfterInit && showQuestionnaireByType(HEALT_QUEST_CODE)"
    feFieldId="{{STEP.QUESTS_HLT.fieldId}}" fieldId="{{STEP.QUESTS_HLT.fieldId}}"
    label="{{STEP.QUESTS_HLT.label}}"
    (next)="nextOnQuestionary(STEP.QUESTS_HLT.id, false, STEP.QUESTS_HLT.id, false, 'DYNAMC')"
  >
    <lpc-questionnaire [active]="!isAfterId(STEP.QUESTS_HLT.id)" [key]="composeKey(key, STEP.QUESTS_HLT.id)"
                       [questionnairesCode]="getQuestMap(HEALT_QUEST_CODE)" [type]="HEALT_QUEST_CODE"
                       (loaded)="countQuest($event)"
                       [date]="effectiveDate" [savedIds]="questionnairesFromAuthorization"
                       [productCode]="productCode"
                       (loaderQuest)="loaderQuestManager($event)"
                       #healthQuestionnaire>
    </lpc-questionnaire>
  </lpc-step>

  <lpc-step
      id="{{STEP.QUOTATION.id}}"
      [enableAuth]="isAuth"
      label="{{STEP.QUOTATION.label}}"
      [errors]="errors" fieldId="{{STEP.QUOTATION.fieldId}}" (next)="onNextQuotation(STEP.RISKS.id, $event)"
      [disableNext]="disableNext"
      (confirmAccept)="onNextQuotation(STEP.RISKS.id, $event, false, true)"
      [isConfirmAndAccept]="isConfirmAndAccept"
  >
    <lpc-quotation-control
      [disableQuote]="disableQuote"
      [disableDetails]="disableDetails"
      (quote)="recalculate(false)"
      [quotationDefinition]="quotationDefinition"
      (openQuotationModal)="openQuotationModal($event)"
      (openFormuleModal)="openFormuleModal($event)"
      (openProvModal)="openProvModal($event)"
      (openWarrantiesModal)="openWarrantiesModal($event)"
      [enableProv]="enableProvModal">
    </lpc-quotation-control>
    <div class="row">
      <lpc-unit-table
        *ngIf="!!risksForUnits?.length"
        formControlName="{{STEP.RISKS.formName}}"
        [risks]="risksForUnits"
        [showInsuredAmount]="(session.operation === OPERATION_CODES.CAPITALVARIATION) || (session.operation === OPERATION_CODES.CHANGEWARRANTYOPERATION) || (session.operation === OPERATION_CODES.CHANGEWARRANTY)"
        [openSelectedUnits]="false"
        (saveSelection)="recalculate(true)"
        (updateUnitTable)="recalculate(true)"
        [productTypeCode]="productType">
      </lpc-unit-table>
    </div>
    <div *ngIf="showFormuleModal">
      <lpc-formule-modal formControlName="{{STEP.QUOTATION.formName}}" (save)="saveFormulas($event)"
        (closeModal)="closeFormuleModal($event)" *ngIf="!!risks" [riskFormulasObject]="risks">
      </lpc-formule-modal>
    </div>
  </lpc-step>

  <lpc-step *ngIf="hasPaymentTypes"
      [enableAuth]="isAuth" label="{{STEP.PAYMENT.label}}"
      [errors]="errors" [fieldId]="STEP.PAYMENT.id"
      (next)="onNext($event)" id="{{STEP.PAYMENT.id}}"
      (confirmAccept)="onNext($event, false, null, true)"
      [isConfirmAndAccept]="isConfirmAndAccept"
  >
    <lpc-payment-step
      [required]="true"
      [paymentTypeDefinitions]="paymentTypes"
      formControlName="{{STEP.PAYMENT.formName}}"
      [summary]="isAfterId(STEP.PAYMENT.id)">
    </lpc-payment-step>
  </lpc-step>

  <lpc-step
    [hidden]="!validQuestsCode.get(DUE_DILIGENCE_QUEST_CODE)"
    [jump]="!validQuestsCode.get(DUE_DILIGENCE_QUEST_CODE)"
    [enableAuth]="isAuth" id="{{STEP.QUESTS.id}}"
    *ngIf="showSectionQuestAfterInit && showQuestionnaireByType(DUE_DILIGENCE_QUEST_CODE)"
    fieldId="{{STEP.QUESTS.fieldId}}" [errors]="errors"
    label="{{STEP.QUESTS.label}}" (next)="onNext($event)"
    (confirmAccept)="onNext($event, false, null, true)"
    [isConfirmAndAccept]="isConfirmAndAccept"
  >
      <lpc-questionnaire
          [active]="!isAfterId(STEP.QUESTS.id)" [key]="composeKey(key, STEP.QUESTS.id)"
          [questionnairesCode]="getQuestMap(DUE_DILIGENCE_QUEST_CODE)" type="{{DUE_DILIGENCE_QUEST_CODE}}"
          [factors]="questFactorsArray" [disabledQuestions]="disabledQuestionArray"
          [date]="effectiveDate" [productCode]="productCode" [savedIds]="questionnairesFromAuthorization"
          (loaderQuest)="loaderQuestManager($event)"
          (loaded)="countQuest($event)"
          #avcQuestionnaire>
      </lpc-questionnaire>
  </lpc-step>

 <lpc-step id="{{DYNAMIC_STEP.BENEFICIARIES.id}}" [enableAuth]="isAuth" *ngIf="hasBeneficiaryDefinitions"
           label="{{DYNAMIC_STEP.BENEFICIARIES.label}}" [errors]="errors"
           feFieldId="beneficiary" [feErrors]="feErrors"
           fieldId="{{DYNAMIC_STEP.BENEFICIARIES.fieldId}}" (next)="onNext($event)"
           (confirmAccept)="onNext($event, false, null, true)" [isConfirmAndAccept]="isConfirmAndAccept">
    <div *ngIf="isActiveStep(DYNAMIC_STEP.BENEFICIARIES.id)">
      <div formGroupName="{{DYNAMIC_STEP.BENEFICIARIES.formName}}">
        <div class="row display-flex">
            <div *ngFor="let beneficiaryDefinition of beneficiaryDefinitions" class="col-lg-6">
              <div>
                  <lpc-beneficiary
                    [formControlName]="SINGLE_BENEF_CONTROL + beneficiaryDefinition.code"
                    [beneficiaryDefinition]="beneficiaryDefinition">
                  </lpc-beneficiary>
              </div>
            </div>
        </div>
      </div>
    </div>
      <div *ngIf="!isActiveStep(DYNAMIC_STEP.BENEFICIARIES.id) || !!modalMessage" class="row">
        <div class="col-lg-12">
          <div class="col-lg-6" *ngFor="let benef of selectedBeneficiariesForSummary">
            <span>{{benef.benefType}}: </span>
            <span>{{benef.benefLabel}}</span>
          </div>
        </div>
      </div>
  </lpc-step>

  <lpc-step *ngIf="isWarningStepVisible()"
    id="{{STEP.WARNING.id}}"
    label="{{STEP.WARNING.label}}"
    (next)="publish()"
    [nextLabel]="isAuth ? 'AUTHORIZATION REQUEST' : 'Confirm'"
    (confirmAccept)="publish(isConfirmAndAccept)"
    [isConfirmAndAccept]="isConfirmAndAccept"
  >
    <lpc-warning-messages *ngIf="!!errors"
      [errors]="errors">
    </lpc-warning-messages>
  </lpc-step>

  <lpc-step
    label="{{STEP.DOCUMENT.label}}"
    [errors]="errors"
    fieldId="{{STEP.DOCUMENT.fieldId}}"
    id="{{STEP.DOCUMENT.id}}"
    [nextVisible]=false>

    <lpc-document-wrapper
      *ngIf="!!publishMessage"
      [contractId]="getContractId"
      [operationCode]="getOperationCode"
      [publishMessage]="publishMessage"
      [isAuth]="isAuth"
      [authorizationId]="authorizationId"
      [printMode]="printMode"
      [config]="documentsConfig"
      (documentEvent)="handleDocumentEvent($event)">
    </lpc-document-wrapper>

    <lpc-navigation-buttons
      id="last-step-buttons"
      (back)="closeCardWithoutModal()"
      [backLabel]="'lpc_close_button'"
      [backVisible]="true"
      [nextVisible]="false">
    </lpc-navigation-buttons>

  </lpc-step>
</lpc-stepper>

<div class="btn-group btn-group-justified external" *ngIf="!isActiveStep(STEP.DOCUMENT.id)">
  <lpc-navigation-buttons (back)="closeCard()" [nextVisible]="false" [qa]="'annulla'"></lpc-navigation-buttons>
</div>

