import { TableSchema } from "@rgi/rx/ui";

export const schemaTableIn: TableSchema = {
  rows: [
    {
      name: 'greenDot',
      title: '',
    },
    {
      name: 'dIns',
      title: '_CLAIMS_._INS_DATE',
      format: {
        pipe: 'date',
        args: ['shortDate']
      }
    },
    {
      name: 'expirationLeg',
      title: '_CLAIMS_._REGULATORY_EXPIRY_DATE',
      format: {
        pipe: 'date',
        args: ['shortDate']
      }
    },
    {
      name: 'inputRequestCode',
      title: '_CLAIMS_._RESPONSE_IN',
    },
    {
      name: 'sendRequest',
      title: '_CLAIMS_._SENDED',
      format: {
        pipe: 'date',
        args: ['shortDate']
      }
    },
    {
      name: 'expiration',
      title: '_CLAIMS_._EXPIRATION',
      format: {
        pipe: 'date',
        args: ['shortDate']
      }
    },
    {
      name: 'outputResponseCode',
      title: '_CLAIMS_._REQUEST_OUT',
    },
    {
      name: 'statusElabRequest',
      title: '_CLAIMS_._STATUS',
    },
  ],
  header: ['greenDot', 'dIns', 'expirationLeg', 'inputRequestCode', 'sendRequest', 'expiration', 'outputResponseCode', 'statusElabRequest']
};

export const schemaTableDocuments: TableSchema = {
  rows: [
    {
      name: 'nominativo',
      title: '_CLAIMS_._NOMINATIVE',
    },
    {
      name: 'typeDoc',
      title: '_CLAIMS_._DOCUMENT_TYPE',
    },
    {
      name: 'subtitle',
      title: '_CLAIMS_._DOCUMENT_TYPE_FLUX',
    },
    {
      name: 'idDocInfo', // cambiare il nome nel scss ogni volta che si cambia questo 
      title: '',
    },
  ],
  header: ['nominativo', 'typeDoc', 'subtitle', 'idDocInfo']
}

export const schemaTableDiscarded: TableSchema = {
  rows: [
    {
      name: 'codError',
      title: '_CLAIMS_._CODE_ERROR',
    },
    {
      name: 'descError',
      title: '_CLAIMS_._DESCRIPTION',
    },
    {
      name: 'typeError',
      title: '_CLAIMS_._TYPE',
    },
    {
      name: 'typeFlow',
      title: '_CLAIMS_._REFERENCE',
    },
  ],
  header: ['codError', 'descError', 'typeError', 'typeFlow']
}

export const schemaActorAs: TableSchema = {
  rows: [
    {
      name: 'selected',
      title: '',
    },
    {
      name: 'actorName',
      title: '_CLAIMS_._ACTOR_INVOLVED',
    },
    {
      name: 'causaleDescr',
      title: '_CLAIMS_._REASON',
    },
    {
      name: 'licensePlateUS',
      title: '_CLAIMS_._US_CAR_LICENSE_PLATE',
    },
    {
      name: 'agreementType',
      title: '_CLAIMS_._CONV_TYPE'
    }
  ],
  header: ['selected', 'actorName', 'causaleDescr', 'licensePlateUS', 'agreementType']
};

export const schemaTableOut: TableSchema = {
  rows: [
    {
      name: 'greenDot',
      title: '',
    },
    {
      name: 'requestDate',
      title: '_CLAIMS_._INS_DATE',
      format: {
        pipe: 'date',
        args: ['shortDate']
      }
    },
    {
      name: 'expirationLeg',
      title: '_CLAIMS_._REGULATORY_EXPIRY_DATE',
      format: {
        pipe: 'date',
        args: ['shortDate']
      }
    },
    {
      name: 'inputRequestCode',
      title: '_CLAIMS_._REQUEST_IN',
    },
    {
      name: 'sendRequest',
      title: '_CLAIMS_._SENDED',
      format: {
        pipe: 'date',
        args: ['shortDate']
      }
    },
    {
      name: 'expiration',
      title: '_CLAIMS_._EXPIRATION',
      format: {
        pipe: 'date',
        args: ['shortDate']
      }
    },
    {
      name: 'outputResponseCode',
      title: '_CLAIMS_._RESPONSE_OUT',
    },
    {
      name: 'statusElabRequest',
      title: '_CLAIMS_._STATUS',
    },
  ],
  header: ['greenDot', 'dIns', 'expirationLeg', 'inputRequestCode', 'sendRequest', 'expiration', 'outputResponseCode', 'statusElabRequest']
};
