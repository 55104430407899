import { ActivateToolComponent } from '../operation-components/activate-tool/activate-tool.component';
import { AdditionalPaymentComponent } from '../operation-components/additional-payment/additional-payment.component';
import { BackpackTransferComponent } from '../operation-components/backpack-transfer/backpack-transfer.component';
import { CancelAppendixComponent } from '../operation-components/cancel-appendix/cancel-appendix.component';
import { CancellationOperationsComponent } from '../operation-components/cancellation-operations-component/cancellation-operations.component';
import { ChangeAppendixComponent } from '../operation-components/change-appendix/change-appendix.component';
import { ChangeBeneficiariesComponent } from '../operation-components/change-beneficiaries/change-beneficiaries.component';
import { ChangeCombinationComponent } from '../operation-components/change-combination/change-combination.component';
import { ChangeContactComponent } from '../operation-components/change-contact/change-contact.component';
import { ChangePaymentFrequencyComponent } from '../operation-components/change-payment-frequency/change-payment-frequency.component';
import { ChangePaymentModeComponent } from '../operation-components/change-payment-mode/change-payment-mode.component';
import { ChangeRolesComponent } from '../operation-components/change-roles/change-roles.component';
import { ChangeSettlementBenefComponent } from '../operation-components/change-settlement-beneficiary/change-settlement-benef.component';
import { CloseAssignmentComponent } from '../operation-components/close-assignment/close-assignment.component';
import { ConstitutionAnnuitiesOperationsComponent } from '../operation-components/constitution-annuities-operations/constitution-annuities-operations.component';
import { ConventionInsertionExclusionComponent } from '../operation-components/convention-insertion-exclusion/convention-insertion-exclusion.component';
import { DeathClaimComponent } from '../operation-components/death-claim/death-claim.component';
import { DeleteToolComponent } from '../operation-components/delete-tool/delete-tool.component';
import { DynamicOperationComponent } from '../operation-components/dynamic-operation/dynamic-operation.component';
import { EarlyTotalSurrenderComponent } from '../operation-components/early-total-surrender/early-total-surrender.component';
import { ExpiryChoiceComponent } from '../operation-components/expiry-choice/expiry-choice.component';
import { GenericClaimComponent } from '../operation-components/generic-claim/generic-claim.component';
import { InstallmentCancellationComponent } from '../operation-components/installment-cancellation/installment-cancellation.component';
import { LoanGrantComponent } from '../operation-components/loan-grant/loan-grant.component';
import { LoanRefundComponent } from '../operation-components/loan-refund/loan-refund.component';
import { ModificationPersonalDataComponent } from '../operation-components/modification-personal-data/modification-personal-data.component';
import { NonCompletionComponent } from '../operation-components/non-completion/non-completion.component';
import { OpenAssignmentComponent } from '../operation-components/open-assignment/open-assignment.component';
import { OpenLienComponent } from '../operation-components/open-lien/open-lien.component';
import { PartialEarlyRepaymentComponent } from '../operation-components/partial-early-repayment/partial-early-repayment.component';
import { PartialSurrenderComponent } from '../operation-components/partial-surrender/partial-surrender.component';
import { PolicyTrasnferComponent } from '../operation-components/policy-transfer/policy-transfer.component';
import { PremiumPaymentReactivationComponent } from '../operation-components/premium-payment-reactivation/premium-payment-reactivation.component';
import { PremiumPaymentSuspensionComponent } from '../operation-components/premium-payment-suspension/premium-payment-suspension.component';
import { PremiumPaymentVariationComponent } from '../operation-components/premium-payment-variation/premium-payment-variation.component';
import { ReactivationFromTerminationComponent } from '../operation-components/reactivation-from-termination/reactivation-from-termination.component';
import { RecessComponent } from '../operation-components/recess/recess.component';
import { ReductionReactivationComponent } from '../operation-components/reduction-reactivation/reduction-reactivation.component';
import { ReductionComponent } from '../operation-components/reduction/reduction.component';
import { RenewalBookingComponent } from '../operation-components/renewal-booking/renewal-booking-component';
import { SwitchFreeComponentComponent } from '../operation-components/switch-free-component/switch-free-component.component';
import { TerminationIndividualPolicyComponent } from '../operation-components/termination-individual-policy/termination-individual-policy.component';
import { TotalEarlyRepaymentComponent } from '../operation-components/total-early-repayment/total-early-repayment.component';
import { TotalSurrenderComponent } from '../operation-components/total-surrender/total-surrender.component';
import { TransferReserveComponent } from '../operation-components/transfer-reserve/transfer-reserve.component';
import {TaxRelief2022Component} from '../operation-components/tax-relief-2022/tax-relief-2022.component';
import { TransferToOtherFundComponent } from '../operation-components/transfer-to-other-fund/transfer-to-other-fund.component';
import { AdvancePaymentComponent } from '../operation-components/advance-payment/advance-payment.component';
import { ChangeClausesComponent } from '../operation-components/change-clauses/change-clauses.component';
import { PensionBuybackOperationComponent } from '../operation-components/pension-buyback/pension-buyback.component';

export class PlcOperationComponentsUtils {
    public static getOperationComponents() {
      return {
        _VRECP: ChangeContactComponent,
        _VTEXT: ChangeAppendixComponent,
        _VCONT: ChangeRolesComponent,
        _ANPSE: NonCompletionComponent,
        _VBENE: ChangeBeneficiariesComponent,
        _VAGG: AdditionalPaymentComponent,
        _RISPA: PartialSurrenderComponent,
        _VMODP: ChangePaymentModeComponent,
        _ANREC: RecessComponent,
        _ESANT: TotalEarlyRepaymentComponent,
        _ESANP: PartialEarlyRepaymentComponent,
        _SIMOR: DeathClaimComponent,
        _VARFR: ChangePaymentFrequencyComponent,
        _VGARI: DynamicOperationComponent,
        _SOVRP: DynamicOperationComponent,
        _VRESI: TerminationIndividualPolicyComponent,
        _VPRIR: TerminationIndividualPolicyComponent,
        _VGARD: DynamicOperationComponent,
        _VVARC: DynamicOperationComponent,
        _VPRIN: RenewalBookingComponent,
        _ARESI: CancellationOperationsComponent,
        _DTOOL: DeleteToolComponent,
        _ATOOL: ActivateToolComponent,
        _MTOOL: ActivateToolComponent,
        _CCOMB: ChangeCombinationComponent,
        _AANPS: CancellationOperationsComponent,
        _VBLIQ: ChangeSettlementBenefComponent,
        _ANAPP: CancelAppendixComponent,
        _APEGN: OpenLienComponent,
        _AVINC: OpenAssignmentComponent,
        _RISTO: TotalSurrenderComponent,
        _CPEGN: CloseAssignmentComponent,
        _CVINC: CloseAssignmentComponent,
        _SWITC: SwitchFreeComponentComponent,
        _TRFNO: PolicyTrasnferComponent,
        _VFIGU: ChangeRolesComponent,
        _SCOPZ: ExpiryChoiceComponent,
        _ARATA: InstallmentCancellationComponent,
        _RIDUZ: ReductionComponent,
        _INPAG: PremiumPaymentSuspensionComponent,
        _AANRE: CancellationOperationsComponent,
        _RIAPU: PremiumPaymentReactivationComponent,
        _VPRFR: PremiumPaymentVariationComponent,
        _ASIMO: CancellationOperationsComponent,
        _RECOS: ConstitutionAnnuitiesOperationsComponent,
        _ASCAD: CancellationOperationsComponent,
        _SINVA: GenericClaimComponent,
        _SIMAG: GenericClaimComponent,
        _SINAB: GenericClaimComponent,
        _CPRES: LoanGrantComponent,
        _ARIST: CancellationOperationsComponent,
        _ARIDU: CancellationOperationsComponent,
        _RIARI: ReductionReactivationComponent,
        _RPRES: LoanRefundComponent,
        _ARISP: CancellationOperationsComponent,
        _CCACC: ChangeBeneficiariesComponent,
        _AVGAD: CancellationOperationsComponent,
        _AVGAI: CancellationOperationsComponent,
        _RIARE: ReactivationFromTerminationComponent,
        _ASINV: CancellationOperationsComponent,
        _ADIFF: CancellationOperationsComponent,
        _MDATI: ModificationPersonalDataComponent,
        _ASINA: CancellationOperationsComponent,
        _ASIMA: CancellationOperationsComponent,
        _INESC: ConventionInsertionExclusionComponent,
        _RTANT: EarlyTotalSurrenderComponent,
        _TRRIS: TransferReserveComponent,
        _TRAZA: BackpackTransferComponent,
        _ARINV: CancellationOperationsComponent,
        _AFV22: TaxRelief2022Component,
        _TROUT: TransferToOtherFundComponent,
        _ANTIC: AdvancePaymentComponent,
        _VVCLA: ChangeClausesComponent,
        _ARTAN: CancellationOperationsComponent,
        _RTQUI: PensionBuybackOperationComponent,
        _AANTI: CancellationOperationsComponent,
        _ATROU: CancellationOperationsComponent,
        _ATRRI: CancellationOperationsComponent,
        _ARTQU: CancellationOperationsComponent,
      };
    }

}
