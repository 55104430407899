<lpc-stepper [formGroup]="formGroup" (stepSlide)="onSlide($event)" [isPublished]="publishMessage !== null">
    <lpc-step id="dates" label="lpc_date_variation_step" [errors]="errors" fieldId="dates" (next)="onNext($event)">
        <lpc-dates-step [inputFields]="dateDefinitions" [formControlName]="'dates'" (dateChange)="onDateChange($event)"
            (reloadRequest)="updateData()" [hide]="activeStep<1">
        </lpc-dates-step>
    </lpc-step>

    <lpc-step *ngIf="isOperationRoleStepPresent" id="operationRoles" label="lpc_roles_variation" [errors]="errors" fieldId="operationRoles" (next)="onNext($event)">
        <lpc-roles-step-wrapper
          [active]="isActiveStep('operationRoles')"
          [roles]="operationRoles"
          [operationCode]="session.operation"
          [roleDefinition]="operationRolesAdmitted"
          (roleEvent)="handleRolesEvent($event)">
        </lpc-roles-step-wrapper>
      </lpc-step>

    <lpc-step id="openLien" label="lpc_Open_lien" [errors]="errors" fieldId="openLien" (next)="onNext($event)"
              (confirmAccept)="onNext($event, true, null, true)" [isConfirmAndAccept]="isConfirmAndAccept">
        <div class="row" formGroupName="openLien">
            <div *ngFor="let lienDate of lienDates; let i = index;" class="col-lg-6">
                <lpc-datepicker
                [formControlName]="lienDate.code"
                [label]="lienDate.label"
                [required]="lienDate.required"
                [id]="lienDate.code"
                [hidden]="!isActiveStep('openLien')"
                [helpFile]="lienDate.helpFile">
                </lpc-datepicker>
                <div *ngIf="showSummaryValue(lienDate.code)">
                    <span translate>{{lienDate.label}}</span> : {{formGroup.get('openLien').get(lienDate.code).value | date : 'dd/MM/yyyy'}}
                </div>
            </div>

            <div *ngIf="isActiveStep('openLien')" class="col-md-6" data-qa="lien_type_creditor">
                <label class="label-core" translate>lpc_lien_credit</label>
                <lpc-required required="true | translate"></lpc-required>
                <ng-select [attr.data-qa]="'lienTypeCreditor'" formControlName="lienTypeCreditor">
                    <ng-option *ngFor="let type of lienTypesCreditor" [value]="type.codice">{{type.descrizione}}
                    </ng-option>
                </ng-select>
            </div>
            <div *ngIf="showSummaryValue('lienTypeCreditor')" class="col-lg-6">
                <span translate>lpc_lien_credit</span> : {{lienTypeDescriptor}}
            </div>

            <div *ngIf="isActiveStep('openLien')" class="col-md-6" data-qa="lien_creditor">
                <label for="subject" translate class="label-core">lpc_creditor</label>
                <lpc-required required="true | translate"></lpc-required><br>
                <div *ngIf="!!formGroup.get('openLien').get('creditor').value; else empty">
                    <lpc-subject (delete)="deleteSbj($event)" formControlName="creditor"></lpc-subject>
                </div>
                <ng-template #empty>
                    <input (click)="openAnagSubjectModal()" [value]="'lpc_Seleziona' | translate"
                        class="col-lg-12 drop-container dashedSelect" data-qa="dashed-select-subject" readonly>
                </ng-template>
            </div>
            <div *ngIf="showSummaryValue('creditor')" class="col-lg-6">
                <span translate>lpc_lien_credit</span> : {{formGroup.get('openLien').get('creditor')?.value?.name}}
            </div>
        </div>
    </lpc-step>
    <lpc-step *ngIf="isAuth || !!errors && !!errors.length" id="warnings" label="lpc_warnings" (next)="publish()" [nextLabel]="isAuth ? 'AUTHORIZATION REQUEST' : 'Confirm'"
              (confirmAccept)="publish(true)" [isConfirmAndAccept]="isConfirmAndAccept">
        <lpc-warning-messages *ngIf="!!errors" [errors]="errors"></lpc-warning-messages>
    </lpc-step>

    <lpc-step
        label="lpc_confirm_variation_step"
        [errors]="errors"
        fieldId="documents"
        id="documents"
        [nextVisible]=false>

        <lpc-document-wrapper
            *ngIf="!!publishMessage"
            [contractId]="getContractId"
            [operationCode]="getOperationCode"
            [publishMessage]="publishMessage"
            [isAuth]="isAuth"
            [authorizationId]="authorizationId"
            [printMode]="printMode"
            [config]="documentsConfig"
            (documentEvent)="handleDocumentEvent($event)">
        </lpc-document-wrapper>

        <lpc-navigation-buttons
            id="last-step-buttons"
            (back)="closeCardWithoutModal()"
            [backLabel]="'lpc_close_button'"
            [backVisible]="true"
            [nextVisible]="false">
        </lpc-navigation-buttons>

    </lpc-step>

</lpc-stepper>

<div class="btn-group btn-group-justified external" *ngIf="!isActiveStep('documents')">
    <lpc-navigation-buttons (back)="closeCard()" [nextVisible]="false" [qa]="'annulla'"></lpc-navigation-buttons>
</div>
