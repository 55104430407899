<table class="table premium-table">
  <thead>
    <tr>
      <th translate>{{premium.typeDescription}}</th>
      <th [attr.data-qa]="'th-premium-totalAmount'">{{premium.totalAmount | rgiCtryLNumFormatter :  '' : currencyFormatterOptions}}</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngIf="!!premium.taxesAmount">
      <td translate>lpc_Taxes</td>
      <td [attr.data-qa]="'td-taxesAmount-value'">{{ premium.taxesAmount | rgiCtryLNumFormatter :  '' : currencyFormatterOptions}}</td>
    </tr>
    <tr *ngIf="!!premium.taxableAmount">
      <td translate>lpc_imponibile_imp</td>
      <td [attr.data-qa]="'td-taxableAmount-value'">{{ premium.taxableAmount | rgiCtryLNumFormatter :  '' : currencyFormatterOptions}}</td>
    </tr>
    <tr *ngIf="!!premium.rightsAmount">
      <td translate>lpc_Loading</td>
      <td [attr.data-qa]="'td-rightsAmount-value'">{{ premium.rightsAmount | rgiCtryLNumFormatter :  '' : currencyFormatterOptions}}</td>
    </tr>
    <tr *ngIf="!!premium.installmentInterests">
      <td translate>lpc_paymentFrequencyInterest</td>
      <td [attr.data-qa]="'td-installmentInterests-value'">{{ premium.installmentInterests | rgiCtryLNumFormatter :  '' : currencyFormatterOptions}}</td>
    </tr>
    <tr *ngIf="!!premium.accessorsAmount">
      <td translate>Accessori</td>
      <td [attr.data-qa]="'td-accessorsAmount-value'">{{ premium.accessorsAmount | rgiCtryLNumFormatter :  '' : currencyFormatterOptions}}</td>
    </tr>
    <tr *ngIf="!!premium.netAmount">
      <td translate>lpc_net</td>
      <td [attr.data-qa]="'td-netAmount-value'">{{ premium.netAmount | rgiCtryLNumFormatter :  '' : currencyFormatterOptions}}</td>
    </tr>
  </tbody>
</table>
