<div class="lic-preventive-card">
  <label for="node" class="label-core" translate>lic_quote_type</label>
  <rgi-rx-tab-group (onTabSelectionChange)="onSelectionChange($event)" [selectedIndex]="selectedIndex">
    <rgi-rx-tab [label]="'lic_quote_type_registered' | translate">
      <ng-template rgiRxTabContent>
        <ng-container *ngTemplateOutlet="commonTemplate"></ng-container>
      </ng-template>
    </rgi-rx-tab>
    <rgi-rx-tab [label]="'lic_quote_type_anonymous' | translate">
      <ng-template rgiRxTabContent>
        <ng-container *ngTemplateOutlet="commonTemplate"></ng-container>
      </ng-template>
    </rgi-rx-tab>
  </rgi-rx-tab-group>
  <pnc-floating-error-messages 
    [validationMessages]="validationMessages">
  </pnc-floating-error-messages>
</div>


<ng-template #commonTemplate>
  <form #container [formGroup]="formGroup">
  <div class="col-lg-12 form-group row">
    <label class="label-core">
      <span>{{'lic_node' | translate}}</span>
      <lic-required required="true"></lic-required>
    </label>
    <select formControlName="node"
            (change)="onChange($event)"
            data-qa="preventive-node"
            class="drop-container" style="width: 100%;">
      <option *ngFor="let node of listOfNode" [value]="node.displayValue">{{node.description}}</option>
    </select>

    <div class="drop-item-delete">
        <button *ngIf="withBasket" type="button" class="drag-del-item" (click)="cleanNode()">
          <span class="glyphicon glyphicon-trash"></span>
        </button>
    </div>
  </div>

  <div class="col-lg-12 form-group row">
    <rgi-rx-form-field>
      <label rgiRxLabel>
        <span>{{'lic_product' | translate}}</span>
        <lic-required required="true"></lic-required>
      </label>
      <select rgiRxNativeSelect data-qa="preventive-product" name="product" formControlName="product" class="drop-container" required>
        <option value=""></option>
        <option *ngFor="let product of listOfProduct" [value]="product?.productCode">
            {{product?.productDescription}}
        </option>
      </select>
    </rgi-rx-form-field>
  </div>

  <div class="col-lg-12 form-group row" *ngIf="!isAnonymous">
    <label for="subject" class="label-core" translate>lic_Policyholder</label>
    <lic-required required="true"></lic-required><br>
    <div *ngIf="isSubjectSelected; else empty">
      <div class="input-group" >
        <input type="text" class="form-control" readonly value="{{selectedSubj.nominative ? selectedSubj.nominative : selectedSubj.surname + '' + selectedSubj.name}}">
        <div class="input-group-btn">
          <button [disabled]="formGroup.disabled" (click)="deleteSbj()" class="btn btn-outline-secondary" type="button">
            <span class="rgifont rgi-trash"></span>
          </button>
        </div>
      </div>
    </div>
    <ng-template #empty>
      <input [ngClass]="{ 'is-invalid': submitted && formGroup.get('subject').errors }" (click)="wrapSubjectEvent($event)" [value]="'lic_simple_select' | translate" class="col-lg-12 drop-container dashedSelect"
        data-qa="dashed-select-subject">
    </ng-template>
  </div>
</form>
</ng-template>

<div class="btn-group btn-group-justified">
  <!-- <div class="btn-group">
    <button type="button" (click)="clean()" class="btn btn-warning" translate>empty</button>
  </div> -->
  <div class="btn-group">
    <button (click)="openSession()" type="submit" data-qa='claim-report-start' class="btn btn-warning">
      {{'lic_prev_continue' | translate}}
    </button>
  </div>
</div>
