import {
  AfterViewInit,
  Component,
  forwardRef,
  Inject,
  Input,
  OnInit,
  Optional,
} from "@angular/core";
import {
  AbstractControl,
  ControlValueAccessor,
  FormControl,
  FormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from "@angular/forms";
import {
  allocationValidator,
  capitalRequestValidator,
} from "../../config/pension-buyback.validatiors";
import { fiscalPeriods } from "../../config/pension-buyback.const";
import { FiscalData } from "../../config/pension-buyback.model";
import {
  CurrencyCacheService,
  LpcCurrencyCache,
} from "../../../../services/currency-cache.service";
import { RgiCountryLayerNumberFormatPipe } from "@rgi/country-layer";

@Component({
  selector: "lpc-performance-data",
  templateUrl: "./performance-data.component.html",
  styleUrls: ["./performance-data.component.scss"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PerformanceDataComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => PerformanceDataComponent),
      multi: true,
    },
  ],
})
export class PerformanceDataComponent
  implements OnInit, AfterViewInit, ControlValueAccessor
{
  constructor(
    @Optional() @Inject(LpcCurrencyCache) protected currencyService: CurrencyCacheService,
    protected rgiNumberFormatter: RgiCountryLayerNumberFormatPipe,

  ) {
    this.currencyFormatterOptions.currency = currencyService.currency;
  }

  @Input() usePercentage: boolean;
  @Input() fiscalData: FiscalData;
  @Input() active: boolean;

  form: FormGroup;

  public currencyFormatterOptions: Intl.NumberFormatOptions = {
    style: "currency",
  };

  public percentFormatterOptions: Intl.NumberFormatOptions = {
    style: 'percent',
    maximumFractionDigits: 2,
    minimumFractionDigits: 2
  };

  ngOnInit(): void {
    this.initializeForm();
  }

  ngAfterViewInit(): void {
    this.onChange(this.form.value);
  }

  // This method initializes the form based on the input usePercentage.
  // If usePercentage is true, the form is initialized with a single 'percentage' control.
  // This control is intended for scenarios where fiscal data is based on a total percentage benefit rather than individual payouts.
  // If usePercentage is false, the form is initialized with controls for each fiscal period,
  // allowing for detailed input on payouts for each period.
  initializeForm(): void {
    const controls = this.usePercentage
      ? { percentage: new FormControl(0) }
      : this.initFiscalPeriodsControls();
    const validators = this.usePercentage ? [] : this.getValidators();

    this.form = new FormGroup(controls, { validators });
    this.setupFormValueChanges();
  }

  // Initializes form controls for each fiscal period when not using a percentage-based approach.
  // Each control is named based on its index in the fiscal periods array and validated against its maxPayout value.
  private initFiscalPeriodsControls(): { [key: string]: AbstractControl } {
    const controls = {};
    this.fiscalData.fiscalPeriods.forEach((period, index) => {
      const controlName = `K${index + 1}`;
      controls[controlName] = new FormControl(0, [
        Validators.max(Math.floor(Number(period.maxPayout))),
      ]);
    });
    return controls;
  }

  // Retrieves validators for the form based on fiscal data,
  // applying a capital request validator and an allocation validator
  // that depends on predefined fiscal periods.
  private getValidators(): ValidatorFn[] {
    const IMC = Math.floor(Number(this.fiscalData.maxPayout));
    return [
      capitalRequestValidator(IMC),
      allocationValidator(
        this.findMValue(fiscalPeriods.BEFORE_2001),
        this.findMValue(fiscalPeriods.AFTER_2001)
      ),
    ];
  }

  // Setup subscription to form value changes
  private setupFormValueChanges(): void {
    this.form.valueChanges.subscribe(() => {
      this.onChange(this.form.value);
      this.onValidationChange(this.form.value);
    });
  }

  // Finds the maxPayout value for a specific fiscal period.
  findMValue(period: string): number {
    const periodData = this.fiscalData.fiscalPeriods.find(
      (p) => p.period === period
    );
    return periodData ? Math.floor(Number(periodData.maxPayout)) : 0;
  }

  writeValue(val: any): void {
    if (val) {
      // If there's a value, set each control to the corresponding value or to 0 if the value is not provided
      Object.keys(this.form.controls).forEach((key) => {
        const controlValue = val.hasOwnProperty(key) ? val[key] : 0; // Default to 0 if val does not have the key
        this.form.controls[key].setValue(controlValue, { emitEvent: false });
      });
    } else {
      // If no value is provided, reset all controls to 0
      Object.keys(this.form.controls).forEach((key) => {
        this.form.controls[key].setValue(0, { emitEvent: false }); // Set control to 0
      });
    }
  }

  validate(): ValidationErrors {
    return this.form.errors;
  }

  registerOnTouched(fn: any): void {}

  setDisabledState?(isDisabled: boolean): void {}

  onChange(value: any) {}

  onValidationChange(value: any) {}

  ngOnChanges(changes: any): void {}

  onFormChanges() {}

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnValidatorChange?(fn: () => void): void {
    this.onValidationChange = fn;
  }
}
