import {Component, OnInit} from '@angular/core';
import {RoutableComponent} from '@rgi/rx/router';
import {RgiRxDatatableRowAction, TableSchema} from '@rgi/rx/ui';
import {AnagStateManagerResult, AnagStateResult} from '../../anag-states/result/anag-state-manager-result';
import {PARTY_LIST_MODAL_TABLE_SCHEMA} from '../../anag-constants/anag-party-list-modal-tableschema';
import {AnagApiParty} from '../../anag-model/anag-domain/anag-api-party';
import {AnagStateManagerKeyData} from '../../anag-states/key-data/anag-state-manager-key-data';
import {RgiRxUserAuthorizationService} from '@rgi/rx/auth';
import {ANAG_FUNCTIONS} from '../../anag-constants/anag-constants';

@Component({
  selector: 'rgi-anag-search',
  templateUrl: './anag-result.component.html',
  host: {
    class: 'rgi-anag-style'
  }
})
export class AnagResultComponent extends RoutableComponent implements OnInit {

  /**
   * @internal set the Type when including this or will fail to link @rgi/rx 1.x in view Engine libraries
   * since the compiler fail to reference the import correctly ang generates:
   * XXXSCHEMA: import("@rgi/rx/ui/rgi-rx-ui").TableSchema;
   * Hence the ngcc linker fails.
   * Specify the type : TableSchema to prevent this issue.
   * Also prevent using the same property name to reference a const from an outside module, this is a code smell.
   * Eventually transform those constants in factories or the clone the reference because this is another code smell,
   * since modifying the reference will modify the original object and can produce nasty bugs when a component modifies
   * the schema!
   */
  PARTY_LIST_MODAL_TABLE_SCHEMA: TableSchema = PARTY_LIST_MODAL_TABLE_SCHEMA;

  state: AnagStateResult;

  partiesTableData: any[] = [];

  constructor(
    public stateMgr: AnagStateManagerResult,
    public stateMgrKeyData: AnagStateManagerKeyData,
    public authorizationService: RgiRxUserAuthorizationService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.stateMgr.getState$().subscribe((state) => {
      this.state = state;
      if (this.state.parties) {
        this.fillPartiesTableData(this.state.parties);
      }
      if (!this.state.isModal) {
        this.stateMgr.setTitle();
      }
      if (this.state.isBackFromHomonymy) {
        this.stateMgr.actionCreateParty(this.stateMgrKeyData);
      }
    });

  }

  fillPartiesTableData(parties: Array<AnagApiParty>) {
    this.partiesTableData = parties.map(party => {
      return {
        nodeCode: party.node.code,
        nodeId: party.node.identification,
        nominative: party.nominative,
        personType: party.subjectType.descrizione,
        fiscalCode: party.fiscalCode,
        dateOfBirth: party.dateOfBirth,
        objectId: party.objectId,
        residence: party.residence,
        isModal: this.state.isModal,
        canEditParty: this.state.canEditParty && this.authorizationService.isAuthorizedFor(ANAG_FUNCTIONS.edit),
        isTerrorist : party.counterTerrorismStatus ? party.counterTerrorismStatus.codice === '2' ||
                      party.counterTerrorismStatus.codice === '3' : false,
        terrorismInfo : {
          date : party.counterTerrorismDate,
          msg : party.counterTerrorismStatus ? party.counterTerrorismStatus.descrizione : '-'
        },
        birthName: party.birthName
      };
    });

  }

  onActionClick($event: RgiRxDatatableRowAction<any>) {
    switch ($event.name) {
      case 'SELECT':
        this.stateMgr.datatableActionSelectSubject($event.row, 'DETAIL', this.stateMgrKeyData);
        break;
      case 'EDIT':
        if (this.state.isUmaPartyHomonymyEnable) {
          this.stateMgr.editOrSelectSubject($event.row, 'EDIT_HOMONYMY', this.stateMgrKeyData);
        } else {
          this.stateMgr.editOrSelectSubject($event.row, $event.name, this.stateMgrKeyData);
        }
        break;
    }
  }

  actionGoBack() {
      this.stateMgr.goToHomePage();
  }

  createNewParty() {
    this.stateMgr.actionCreateParty(this.stateMgrKeyData);
  }

  isNewButtonVisible() {
    return this.state.canCreateParty && this.authorizationService.isAuthorizedFor(ANAG_FUNCTIONS.insert);
  }

  get showRefineSearch() {
    // tslint:disable-next-line:radix
    return this.state.totalCounterParties && parseInt(this.state.totalCounterParties) > 65;
  }

  isVisibleTerroristInfo(row) {
    if (this.stateMgr.isCounterTerrorismVisible()) {
      return row.isTerrorist;
    }
    return false;
  }

  isTerrorismVisibile():boolean {
    return this.stateMgr.isCounterTerrorismVisible();
  }

  returnTerroristInfoMsg(row) {
    return row.terrorismInfo.msg;
  }

}
