import { Directive, ElementRef, HostListener, Input, OnInit } from '@angular/core';
import { Factor } from './../../models/policy.model';
import { PolicyService } from '../../services/policy-service';
import { RgiCtryLayerNumFormatterPipe } from '@rgi/rgi-country-layer';
import { FACTOR_TYPE } from '../../enum/life-issue.enum';

@Directive({
  selector: '[licFormatFactor]',
  providers: [RgiCtryLayerNumFormatterPipe]
})
export class FormatFactorDirective implements OnInit {
  @Input() factorInput: Factor;
  element: ElementRef;

  constructor(
    el: ElementRef,
    public decPipe: RgiCtryLayerNumFormatterPipe,
    protected policyService: PolicyService) {
    this.element = el;
  }

  @HostListener('blur', ['$event.target.value'])
  onBlur(value) {
    if ((this.element.nativeElement.value !== null && this.element.nativeElement.value !== '-1' &&
      this.element.nativeElement.value !== '') && this.factorInput.formatter !== null && this.factorInput.type === '1') {
      this.formattingInputNumber();
    }
  }

  @HostListener('focus', ['$event.target.value'])
  onFocus(value) {
    if ((this.element.nativeElement.value !== null && this.element.nativeElement.value !== '-1' &&
      this.element.nativeElement.value !== '') && this.factorInput.formatter !== null && this.factorInput.type === '1') {
      this.resetFormat();
    }
  }

  @HostListener('keypress', ['$event'])
  onkeydown(event) {
    const value = event.key;
    const fieldVal = this.getNewString(event);
    if (this.factorInput.type && this.factorInput.type.toString()  === FACTOR_TYPE.NUMERIC) {
      const regexForKeyPress = /[\d\.,]/;
      if (regexForKeyPress.test(value)) {
        const regexForValue = /^\d+([\.,]\d{0,2})?$/;
        if (regexForValue.test(fieldVal)) {
          return true;
        }
      }
    } else {
      return true;
    }
    return false;
  }

  getNewString(event) {
    const valoreAttualeInput = event.srcElement.value;
    const key = event.key;
    const splitValoreAttualeInput = valoreAttualeInput.split('');
    splitValoreAttualeInput.splice(event.target.selectionStart, (event.target.selectionEnd - event.target.selectionStart), key);
    const nuovoValore = splitValoreAttualeInput.join('');
    return nuovoValore;
  }

  @HostListener('keyup', ['$event.key'])
  onkeyup(value) {
    if (this.factorInput.type !== '5') {
      this.element.nativeElement.value = this.element.nativeElement.value.replace('.', ',');
    }
    return true;
  }

  ngOnInit() {
    if (this.factorInput.formatter !== null && this.factorInput.type === '1') {
      if (this.factorInput.formatter.maxLen !== '-1' && this.factorInput.formatter.maxLen !== '0') {
        this.element.nativeElement.maxLength = this.factorInput.formatter.maxLen;
      }
      if (this.element.nativeElement.value !== null && this.element.nativeElement.value !== '-1' &&
        this.element.nativeElement.value !== '') {
        this.formattingInputNumber();
      }
    }
  }

  resetFormat() {
    const regex = /[.,\s]/g;
    this.element.nativeElement.value = this.element.nativeElement.value.replace(regex, '');

    const decimal = (this.element.nativeElement.value.substring(
      this.element.nativeElement.value.length - (+this.factorInput.formatter.precision),
      this.element.nativeElement.value.length));

    const integer = (this.element.nativeElement.value.substring(
      0,
      this.element.nativeElement.value.length - (+this.factorInput.formatter.precision)));

    if (+decimal === 0) {
      this.element.nativeElement.value = integer;
    } else {
      this.element.nativeElement.value = integer + '.' + decimal;
    }

  }

  formattingInputNumber() {
    this.element.nativeElement.value = this.element.nativeElement.value.replace(',', '.');

    const decimal = +this.factorInput.formatter.precision;
    if (decimal > 0) {
      if (this.factorInput.formatter.grouping && +this.element.nativeElement.value !== null) {
        const options = this.policyService.getFormatterOptionsWithDecimal('decimal', '.' + decimal + '-' + decimal);

        this.element.nativeElement.value = this.decPipe.transform(
          +this.element.nativeElement.value,
          this.policyService.currentLocale,
          options
        );
      } else {
        this.element.nativeElement.value = this.decPipe.transform(+this.element.nativeElement.value, this.policyService.currentLocale);
      }
    }
  }

}
