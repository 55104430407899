<div *ngIf="!hide && !!qd" class="blank">
    <h3 translate>{{label}}</h3>
  <div class="tbld postsales-table-style hidden-xs">
    <!-- INTESTAZIONE -->
    <div class="tbld_row postsales-col-title">
      <div class="tbld_col" translate>lpc_descrizione_rischio</div>
      <div class="tbld_col" translate>lpc_prestazione_ridotta_iniz</div>
      <div class="tbld_col" translate>lpc_prestazione_rivalutata</div>
      <div class="tbld_col" translate>lpc_provvigioni_da_recuperare</div>
    </div>
    <!-- GARANZIE -->
    <div *ngFor="let risk of qd.coveragesPolicyReduction" class="tbld_row postsales-row-content">
      <div class="tbld_col postsales-row-title" data-qa="cover-description">{{risk.coverDescription}}</div>
      <div class="tbld_col psvalue" data-qa="reduction-original-benefit">{{risk.reductionOriginalBenefit | rgiCtryLNumFormatter :  '' : currencyFormatterOptions}}</div>
      <div class="tbld_col psvalue" data-qa="reduction-revalued-benefit" *ngIf="repaidVisible">{{risk.reductionRevaluedBenefit | rgiCtryLNumFormatter :  '' : currencyFormatterOptions}}</div>
      <div class="tbld_col psvalue" data-qa="commission-to-retrivie">{{risk.commissionToRetrivie | rgiCtryLNumFormatter :  '' : currencyFormatterOptions}}</div>
    </div>
    <div class="tbld_row postsales-row-total">
      <div class="tbld_col" translate data-qa="reduction-total">lpc_total</div>
      <div class="tbld_col"></div>
      <div class="tbld_col"></div>
      <div class="tbld_col psvalue" data-qa="total-recovered-commission">{{qd.totalRecoveredCommission | rgiCtryLNumFormatter :  '' : currencyFormatterOptions}}</div>
    </div>
  </div>
</div>
