import { State } from '@rgi/rx/state';
import {
  QuestionnaireDetail,
  QuestionnaireDetailJS,
} from './cai-opening-questionnaire.model';
import {
  GeneratedClaimDetail,
} from './cai-opening-claims.module';
import { OutputOccurrenceData } from '../../occurrence-data-modify/state-service/occurrence-config.model';
import { ClaimDetail } from '../../shared/dsc-shared-model/dsc-model-claims';
import { InsurancePolicy, PolicyData } from '../../shared/dsc-shared-model/dsc-model-policy';
import { CategoryDetail, CodiceDescrizione, SelectedEventCategory } from '../../shared/dsc-shared-model/dsc-model-utility';
import { VehicleData } from '../../shared/dsc-shared-model/dsc-model-vehicle';
import { CarBodywork } from '../../shared/dsc-shared-model/dsc-model-bodywork';
import { AddressEntityData } from '../../shared/dsc-shared-model/dsc-model-address';
import { Guardian, Heir, HeirsListContainer, SubjectReduced } from '../../shared/dsc-shared-model/dsc-model-subject';
import { InjuryAsset } from '../../shared/dsc-shared-model/dsc-model-injury';
import { FilterApplied } from './cai-opening-policy.module';

export class OutputSaveDraft {
  result: {
    claim: ClaimDetail;
    error: any[];
    result: {
      resultDescription: string;
      resultOutcome: string;
    };
    warning: any[];
  };
  serviceInfo: any;
}

export enum CodeMsg {
  CLAIM = 'SI1151',
  COMPLAINT = 'SI0236',
}

export class ISimilarClaims {
  branchesCodes: string[];
  occurrenceDate: string;
  policyNumber: string;
  typePtf: string;
}

export class IGenerateClaim {
  claim: {
    policyNumber: string;
    coverTypeCode: string;
    claimBranch: string;
    claimBranchCodes: string;
    draft: boolean;
    flowCreation: boolean;
    debtorFoundOnPolicy: boolean;
    intercompany: boolean;
    reopened: boolean;
    mediation: boolean;
    openingActFollowedQuote: boolean;
    openingFollowedRequestDamage: boolean;
    unknownVehicleColl: boolean;
    witnessesFor: boolean;
  };
}
export class Stato {
  codice: string;
  descrizione: string;
}

export class Controparte{
  compagnia: string;
  targa: string;
}

export class PotentialClaim {
  objectId: any;
  franchigia: any;
  dataDenuncia: any;
  dataInoltroCdl: any;
  idDenuncia: string;
  numberClaim: string;
  numeroPolizza: string;
  nominativoAssicurato: string;
  numeroDenuncia: string;
  companyCode: string;
  descrTipoCopertura: string;
  claimType: string;
  nominativoControparte: string;
  prodotto: string;
  descrizioneAccadimento: string;
  targaTelaio: string;
  claimBranch: any;
  numeroProcedimento: string;
  contraente: any;
  dataAccadimento: any;
  dataEffettoOriginalePolizza: any;
  responsabilitaDichiarata: any;
  stato: Stato;
  ispettorato: string;
  lawsuitInfo: any;
  liquidatore: string;
  pesconSinistro: any;
  expanded?: boolean;
  nodoPolizza: string;
  numeroPolizzaMadre: any;
  numeroApplicazione: string;
  controparti: Controparte[];
}

export class OutputSimilarClaims {
  listPotentialClaims: PotentialClaim[] = [];
  existClMadePostuma: boolean;
}

export class InfoContractSearch {
  extension: null | string;
  causative: {
    code: string;
    description: string;
    identification: string;
  };
  policyNumber: string;
  premium: string;
  licensePlateNumber: null | string;
  subscriptorVatNumber: null | string;
  issueDate: string;
  subscriptorFiscalCode: string;
  enumobliviontype: null | string;
  expirationDate: string;
  cancellationDate: null | string;
  currencyDescription: string;
  proposalNumber: string;
  currencySymbol: string;
  validityExpirationDate: string;
  originalEffectDate: string;
  policyType: {
    codice: string;
    descrizione: string;
  };
  currencyNumberOfDecimals: string;
  currencyCode: string;
  description: string;
  nodeDescription: string;
  contractingSubject: string;
  state: {
    codice: string;
    descrizione: string;
  };
  currencyId: string;
  objectId: string;
  subscriptorId: string;
  effectDate: string;
  product: string;
  nodeCode: string;
  productCode: string;
  category: {
    codice: string;
    descrizione: string;
  };
  policiesList: InsurancePolicy[];
}

export class InputDetailClaimsNewInfo {
  contract: InfoContractSearch = new InfoContractSearch();
  listExternalPolicyFound: null | any;
  companyDetails: null | any;
  companySel: null | any;
  validPolicy: boolean;
  occurrenceDate: string;
  occurrenceHour: string;
  companyCode: string;
  isDummyPolicy: boolean;
  policiesList: [];
}

export class DamageCategory {
  idCategory: string;
  cssClass: string;
  description: string;
  branchesCodes: string[];
  ministerialBranchCodes: string[];
  certifiedOccurrence: boolean;
}

export class SimilarclaimsEntity {
  public policyNumber: string;
  public occurrenceDate: Date;
  public companyCode: string;
  public typePtf: string;
  public branchesCodes: Array<string>;
  public externalPolicy: InsurancePolicy;
}
class ObjectId {
  objectId: string = '';
}

export class Roles {
  holder: ObjectId = new ObjectId();
  owner: ObjectId = new ObjectId();
  driver: ObjectId = new ObjectId();
  partyRole: CodiceDescrizione;
}


export class CaiOpeningSingle {
  policyHolder: SubjectReduced = new SubjectReduced();
  vehicle: VehicleData = new VehicleData();
  insuranceCompany: PolicyData = new PolicyData();
  owner: SubjectReduced = new SubjectReduced();
  driver: SubjectReduced = new SubjectReduced();
  damagedParts: VehicleData = null;
  carBodywork: CarBodywork = new CarBodywork();
  roles: Roles = new Roles();
  dataInjury: any;
  claim: any;
  propertyAddress: AddressEntityData = new AddressEntityData();
  heirsList: HeirsListContainer = new HeirsListContainer()
}

export class ReopenFromJs {
  isReopenedFromJs: false;
  returnClaim: ClaimDetail = new ClaimDetail();
  selectedPolicy: InsurancePolicy = new InsurancePolicy();
  stepToShow: string = '';
}

export class DetailNewClaimInfo {
  contract: InsurancePolicy = new InsurancePolicy();
  listExternalPolicyFound: any;
  companyDetails: any;
  companySel: any;
  validPolicy: boolean = false;
  occurrenceDate: Date;
  filterApplied: FilterApplied = new FilterApplied();
  selectedEventCategory: SelectedEventCategory = new SelectedEventCategory();
  occurrenceHour: Date;
  companyCode: string = '';
  isDummyPolicy: boolean = false;
  policiesList: InsurancePolicy[] = [];
}
export class CovertureBranch {
  claimBranchCode: string = '';
  ministerialBranchDescription: string = '';
  covertureTypes: CovertureType[] = [];
  claimBranchDescription: string = '';
  ministerialBranchCode: string = '';
}

export class StepperDetail {
  activeItem: number = 0;
  selectedIndex: number = 0;
}


export class CaiOpeningState extends State {
  detailNewClaimInfo: DetailNewClaimInfo = new DetailNewClaimInfo();
  similarClaims: OutputSimilarClaims = new OutputSimilarClaims();
  generatedClaim: GeneratedClaimDetail = new GeneratedClaimDetail();
  claimToService: GeneratedClaimDetail = new GeneratedClaimDetail();
  contract: any;
  questionnaire: QuestionnaireDetail = new QuestionnaireDetail();
  defaultQuestionnaire: QuestionnaireDetailJS = new QuestionnaireDetailJS();
  selectedNode: string = '';
  selectedPolicy: InsurancePolicy = new InsurancePolicy();
  singleCai: CaiOpeningSingle =
    new CaiOpeningSingle();
  allCategories: CategoryDetail[] = [];
  occurrenceData: OutputOccurrenceData;
  reloadCircumstance: boolean = false;
  listCovertureBranches: CovertureBranch = new CovertureBranch();
  selectedCovertureBranch: CovertureType = new CovertureType();
  claimBranchDescription: string = '';
  reopenFromJs: ReopenFromJs = new ReopenFromJs();
  stepper: StepperDetail = new StepperDetail();
  additionalInfoCoverture: any = null;
  listFunz: any = null;
  nodoFittSx: NodeManagement = null;
}

export class NodeManagement {
  code: string;
  description: string;
  identification: string;
  constructor(code, description, identification) {
    this.code = code;
    this.description = description;
    this.identification = identification;
  }
}

export class DetailMotorFlow {
  detailNewClaimInfo: DetailNewClaimInfo = new DetailNewClaimInfo();
  similarClaims: OutputSimilarClaims;
  generatedClaim: GeneratedClaimDetail = new GeneratedClaimDetail();
  questionnaire: QuestionnaireDetail = new QuestionnaireDetail();
  selectedNode: string = '';
  singleCai: CaiOpeningSingle =
    new CaiOpeningSingle();
  reopenFromJs: ReopenFromJs = new ReopenFromJs();
}

export interface ClaimsOperatorLight {
  username: string;
  salePoint: {
    objectId: string;
    code: string;
    description: string;
    salePointType: {
      codice: string;
      descrizione: string;
    };
  };
}

export interface OutputGetClaimPes {
  executionId: string;
  logs: string;
  codiceErroreOccorso: string;
  tipoErroreOccorso: string;
  descrizioneErroreOccorso: string;
  dettagliSullErroreOccorso: string;
  eccezioneOccorsaSerializzata: string;
  claimInquiry: any;
  potencialClaimTypeRequest: any;
}

export class CovertureType {
  covertureTypeDescription: string = '';
  sold: boolean = false;
  covertureTypeCode: string = '';
}

class ServiceInfo {
  executionId: string;
  logs: any; // You might want to replace 'any' with the appropriate type if logs have a specific structure
  codiceErroreOccorso: string;
  tipoErroreOccorso: string;
  descrizioneErroreOccorso: any; // Replace 'any' with the appropriate type
  dettagliSullErroreOccorso: any; // Replace 'any' with the appropriate type
  eccezioneOccorsaSerializzata: any; // Replace 'any' with the appropriate type
}

export class OutputResultCovertureBranches {
  serviceInfo: ServiceInfo = new ServiceInfo();
  covertureBranches: CovertureBranch[] = [];
  resultComplete: any | null;
}

export class DataFormSubject {
  isOwner: boolean;
  isDriver: boolean;
  isPassenger: boolean;
  chkMaterial: boolean;
  chkInjury: boolean;
  materialDescription: string;
  enumGuardianRole: string;
  ListMinorGuardianSubject: [];
}

export class RolesSubject {
  holder: {
    objectId: string;
  };
  owner: {
    objectId: string;
  };
  driver: {
    objectId: string;
  };
  partyRole: {
    descrizione: string;
    codice: string;
  };
}

export class OutputDataFormSubjectHolder {
  subject: SubjectReduced = new SubjectReduced();
  dataForm: InjuryAsset = new InjuryAsset();
  /* roles: RolesSubject = new RolesSubject(); */
}
