<lpc-stepper [formGroup]="formGroup" (stepSlide)="onSlide($event)" [isPublished]="publishMessage !== null">

  <lpc-step label="lpc_date_variation_step" [errors]="errors" fieldId="dates" (next)="onNext($event)" id="dates">
    <lpc-dates-step
      [inputFields]="dateDefinitions"
      [formControlName]="'dates'"
      (dateChange)="onDateChange($event)"
      (reloadRequest)="updateData()"
      [hide]="activeStep<1">
    </lpc-dates-step>
  </lpc-step>

  <lpc-step *ngIf="isOperationRoleStepPresent" id="operationRoles" label="lpc_roles_variation" [errors]="errors" fieldId="operationRoles" (next)="onNext($event)">
    <lpc-roles-step-wrapper
      [active]="isActiveStep('operationRoles')"
      [roles]="operationRoles"
      [operationCode]="session.operation"
      [roleDefinition]="operationRolesAdmitted"
      [enableDelegateCheckbox]="enableRoleCheckbox('delegate')"
      [enableLegalGuardianCheckbox]="enableRoleCheckbox('legalGuardian')"
      [enableThirdPayerCheckbox]="enableRoleCheckbox('thirdPayer')"
      (roleEvent)="handleRolesEvent($event)">
    </lpc-roles-step-wrapper>
  </lpc-step>

  <lpc-step [enableAuth]="isAuth" label="lpc_quotation_step" [errors]="errors" fieldId="quotation" (next)="onNext($event)" id="quotation">
    <ng-container *ngIf="tableDefinitions">
      <div *ngFor="let insuredSettlement of insuredSettlements">
        <p *ngIf="!!insuredSettlement.insured && insuredSettlement.insured.length > 1;else singleInsured" class="insured">
          <label translate>lpc_Assicurati</label>
          <label>: {{ insuredSettlement.insured[0] + ' - ' + insuredSettlement.insured[1] }}</label>
        </p>
        <ng-template #singleInsured>
          <p class="insured">
            <label translate>lpc_Assicurato</label>
            <label>: {{ insuredSettlement.insured[0] }}</label>
          </p>
        </ng-template>
        <lpc-definition-table
          [label]="!!damageQuotation ? 'lpc_life_policy' : null"
          [definitions]="tableDefinitions"
          [rows]="insuredSettlement.settlement">
        </lpc-definition-table>
        <br>
        <lpc-quotation-recess
          [label]="!!damageQuotation ? 'lpc_damages_policy' : null"
          [quotationData]="damageQuotation">
        </lpc-quotation-recess>

        <div class="row">
          <div class="col-md-8 col-lg-6 col-md-offset-2 col-lg-offset-3">
            <lpc-quotation-recess *ngIf="lifeQuotation && lifeQuotation.totalRecoveredCommission > 0"
                                  [label]="null" [repaidVisible]="false"
                                  [quotationData]="lifeQuotation">
            </lpc-quotation-recess>
          </div>
        </div>

      </div>
    </ng-container>
    <lpc-quotation-recess *ngIf="!tableDefinitions && !!lifeQuotation"
      [quotationData]="lifeQuotation"
      [hide]="modalMessage"
      [label]="!!lifeDamageProduct ? 'lpc_life_policy' : null">
    </lpc-quotation-recess>
    <lpc-quotation-recess
      *ngIf="!!damageQuotation"
      [quotationData]="damageQuotation"
      [hide]="modalMessage"
      [label]="'lpc_damages_policy'">
    </lpc-quotation-recess>
  </lpc-step>

  <lpc-step id="beneficiaries" *ngIf="isWithSettlements" label="lpc_life_detail_beneficiaries" [errors]="errors" fieldId="beneficiaries"
            (next)="onNext($event, !showCreditPayment)" [feErrors]="feErrors" feFieldId="beneficiaries" id="beneficiaries"
            (confirmAccept)="onNext($event, !showCreditPayment, null, true)" [isConfirmAndAccept]="isConfirmAndAccept">
    <lpc-claim-beneficiaries
      formControlName="beneficiaries" [paymentTypes]="paymentTypes"
      [idAssicurato]="idAssicurato" [summary]="!isActiveStep('beneficiaries')"
      [questionnairesCode]="getUnfilteredQuestMap('IST')"
      [questionnairesDefinitions]="questionnaireDefinitions"
      [beneficiaryType]="beneficiaryType"
      [blockBeneficiaries]="blockBeneficiaries"
      (addedSubject)="onAddedSubject($event)"
      (triggerQuestPreval)="onTriggerQuestPreval($event)"
      (delete)="onDelete($event)"
      (loaderQuest)="loaderQuestManager($event)"
      [questFactorsArray]="questFactorsArray" [disabledQuestionArray]="disabledQuestionArray"
      #beneficiaries >
    </lpc-claim-beneficiaries>
  </lpc-step>

  <lpc-step *ngIf="!isWithSettlements && showCreditPayment" [enableAuth]="isAuth" label="lpc_payment_data" [errors]="errors" fieldId="payment"
            (next)="onNext($event)" id="payment">
    <lpc-payment-step [required]="true" [paymentTypeDefinitions]="paymentTypes" formControlName="payment" [summary]="isAfterId('payment')"></lpc-payment-step>
  </lpc-step>

  <lpc-step *ngIf="isAuth || !!errors && !!errors.length" id="warnings" label="lpc_warnings" (next)="publish()" [nextLabel]="isAuth ? 'AUTHORIZATION REQUEST' : 'Confirm'"
            (confirmAccept)="publish(isConfirmAndAccept)" [isConfirmAndAccept]="isConfirmAndAccept">
    <lpc-warning-messages *ngIf="!!errors" [errors]="errors"></lpc-warning-messages>
  </lpc-step>

  <lpc-step
    label="lpc_confirm_variation_step"
    [errors]="errors"
    fieldId="documents"
    id="documents"
    [nextVisible]=false>

    <lpc-document-wrapper
      *ngIf="!!publishMessage"
      [contractId]="getContractId"
      [operationCode]="getOperationCode"
      [publishMessage]="publishMessage"
      [isAuth]="isAuth"
      [authorizationId]="authorizationId"
      [printMode]="printMode"
      [config]="documentsConfig"
      (documentEvent)="handleDocumentEvent($event)">
    </lpc-document-wrapper>

    <lpc-navigation-buttons
      id="last-step-buttons"
      (back)="closeCardWithoutModal()"
      [backLabel]="'lpc_close_button'"
      [backVisible]="true"
      [nextVisible]="false">
    </lpc-navigation-buttons>

  </lpc-step>
</lpc-stepper>

<div class="btn-group btn-group-justified external" *ngIf="!isActiveStep('documents')">
  <lpc-navigation-buttons
    (back)="closeCard()"
    [nextVisible]="false"
    [qa]="'annulla'">
  </lpc-navigation-buttons>
</div>
