import {
  AfterViewChecked,
  Component,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Optional,
  Output,
  ViewEncapsulation
} from '@angular/core';
import {AbstractControl, UntypedFormControl, UntypedFormGroup, ValidatorFn, Validators} from '@angular/forms';
import { TranslationWrapperService } from '../../../i18n/translation-wrapper.service';
import {FinancialService} from '../../../services/financial.service';
import {NgbDate} from '@ng-bootstrap/ng-bootstrap';
import {ChartDataSets, ChartOptions, ChartType} from 'chart.js';
import {Color, Label} from 'ng2-charts';
import {PlcDateUtils} from '../../../utils/plc-date-utils';
import moment, {Moment} from 'moment';
import {switchMap, tap} from 'rxjs/operators';
import {PlcObjectUtils} from '../../../utils/plc-object-utils';
import { EMPTY_STR, JS_EVENT, PV_TOKEN } from '../../../models/consts/lpc-consts';
import { CurrencyCacheService, LpcCurrencyCache } from '../../../services/currency-cache.service';
import { of } from 'rxjs';
import { RgiCtryLayerNumFormatterPipe } from '@rgi/rgi-country-layer';


export interface Frequency {
  id: number;
  description: string;
}
@Component({
  selector: 'lpc-life-trend-detail',
  templateUrl: './life-trend-detail.component.html',
  styleUrls: ['./life-trend-detail.component.css'],
  encapsulation: ViewEncapsulation.None,
  providers: [RgiCtryLayerNumFormatterPipe]
})

export class LifeTrendDetailComponent implements OnInit, AfterViewChecked {
  // formatter Options
  public currencyFormatterOptions: Intl.NumberFormatOptions = {
    style: 'currency'
  };
  protected lineChartNumberFormat: Intl.NumberFormatOptions = {
    style: 'currency',
    minimumIntegerDigits: 1,
    minimumFractionDigits: 2
  };
  formatterPipe: RgiCtryLayerNumFormatterPipe = null;


  @Output() eventPropagation = new EventEmitter<any>();
  @Input() data;
  @Input() id: string;

  public label: string;
  public startDate: string;
  public endDate: string;
  public frequencies: Frequency[] = [];
  public consultation: any;
  public showInfo = false;
  public date: string;
  public errors = [];
  public chartErrors = [];
  public lifeTrendVisible = false;
  public lineChartLegend = true;
  public enablePolicyTrendButton: boolean;
  public lineChartType: ChartType = 'line';
  public lineChartData: ChartDataSets[] = [];
  public lineChartPlugins = [];
  public lineChartLabels: Label[] = [];
  public lineChartColors: Color[] = [
    {
      borderColor: '#6290b6',
      backgroundColor: 'rgba(204, 215, 224, 0.44)',
    },
  ];
  public lineChartOptions: (ChartOptions & { annotation: any }) = {
    responsive: true,
    annotation: true,
    legend: {position: 'bottom'},
    scales: {
      yAxes: [{
        ticks: {
          callback: (value) => {
            return this.formatterPipe.transform(value, '', this.lineChartNumberFormat);
          }
        }
      }]
    },
    tooltips: {
      callbacks: {
        label: (tooltipItem, data) => {
          let label = data.datasets[tooltipItem.datasetIndex].label || EMPTY_STR;

          if (label) {
            label += ': ';
          }
          label += this.formatterPipe.transform(tooltipItem.yLabel, '', this.lineChartNumberFormat);
          return label;
        }
      }
    }
  };

  public formGroup = new UntypedFormGroup({
    date: new UntypedFormControl(null, [Validators.required]),
    chartForm: new UntypedFormGroup({
      startDate: new UntypedFormControl(null, [Validators.required]),
      endDate: new UntypedFormControl(null, [Validators.required]),
      frequency: new UntypedFormControl(null, [Validators.required])
    })
  });

  constructor(
    protected  translateService: TranslationWrapperService,
    protected financialService: FinancialService,
    @Inject(PV_TOKEN.PV_GRAPHIC_FIX) protected plcGraphicFix,
    @Optional() @Inject(LpcCurrencyCache) protected currencyService: CurrencyCacheService,
    protected rgiNumberFormatter: RgiCtryLayerNumFormatterPipe) {
      this.formatterPipe = rgiNumberFormatter;
      this.currencyFormatterOptions.currency = currencyService.currency;
      this.lineChartNumberFormat.currency = currencyService.currency;
      this.formGroup.get('date').setValidators(this.validatePolicyTrendDate.bind(this));
      this.formGroup.get('chartForm').setValidators(this.dateRangeValidator());
  }

  ngOnInit() {
    this.initFrequencies();
    this.label = this.translateService.getImmediate('lpc_andamento_polizza_alla_data');
    this.startDate = this.translateService.getImmediate('lpc_startDate');
    this.endDate = this.translateService.getImmediate('lpc_endDate');
    const startDateForm = this.formGroup.get('chartForm').get('startDate');
    const endDateForm = this.formGroup.get('chartForm').get('endDate');
    const frequency = this.formGroup.get('chartForm').get('frequency');
    if (!!this.data.lifeContract) {
      this.initDatesChart(startDateForm, endDateForm);
      if (!!this.data.lifeContract) {
        this.lifeTrendVisible = true;
        this.formGroup.get('date').setValue(this.getDefaultPolicyTrendDate());
        // date to show on template
        this.date = moment(this.formGroup.get('date').value).format('DD/MM/YYYY');
        // date to send in request
        const date = moment(this.formGroup.get('date').value).format('YYYY-MM-DD');
        this.eventPropagation.emit(JS_EVENT.LOADER.START);
        this.financialService
          .getFundsVolatility(this.data.lifeContract.policyNumber, date)
          .pipe(
            tap(el => {
              this.consultation = el;
              this.showInfo = true;
            }),
            switchMap(_ => {
              if (this.formGroup.get('chartForm').get('startDate').valid) {
                this.handlingFrequencies();
                return this.financialService.getPolicyTrend(
                  this.data.lifeContract.policyNumber,
                  moment(startDateForm.value).format('YYYY-MM-DD'),
                  moment(endDateForm.value).format('YYYY-MM-DD'),
                  frequency.value
                );
              } else {
                this.setFeErrors();
                this.eventPropagation.emit(JS_EVENT.LOADER.STOP);
                return of(null);
              }
            })
          )
          .subscribe(response => {
            if (!!response) {
              this.fillChart(response);
            }
            this.eventPropagation.emit(JS_EVENT.LOADER.STOP);
          });
      }
    }
  }

  handlingFrequencies() {
    this.chartErrors = [];
    const chartGroup = this.formGroup.get('chartForm');
    const startDate = moment(chartGroup.get('startDate').value);
    const endDate = moment(chartGroup.get('endDate').value);
    this.initFrequencies();
    const occurrenciesMap = this.occurrenciesForInterval(moment(endDate), moment(startDate), this.frequencies );
    this.setFrequencies(occurrenciesMap);
    this.setGranularFrequency(occurrenciesMap);
  }

  initDatesChart(startDate: AbstractControl, endDate: AbstractControl) {
    startDate.setValue(new Date(this.data.lifeContract.effectDate));
    endDate.setValue(new Date());
  }

  ngAfterViewChecked(): void {
    this.plcGraphicFix.fixCardGraphic(this.id);
  }

  showChart() {
    return this.lineChartData.length !== 0 && this.frequencies.length !== 0;
  }

  initFrequencies() {
    this.frequencies = [
      { id: 6, description: this.translateService.getImmediate('lpc_six_monthly') },
      { id: 7, description: this.translateService.getImmediate('lpc_quarterly') },
      { id: 8, description: this.translateService.getImmediate('lpc_monthly') },
      { id: 11, description: this.translateService.getImmediate('lpc_daily') },
      { id: 10, description: this.translateService.getImmediate('lpc_weekly') },
      { id: 12, description: this.translateService.getImmediate('lpc_annual') }
    ];
  }

  onChange(event) {
    this.showInfo = false;
    this.errors = [];
    if (event instanceof NgbDate) {
      this.date = event
        ? ('0' + event.day).slice(-2) +
          '/' +
          ('0' + event.month).slice(-2) +
          '/' +
          event.year
        : null;
    } else {
      this.date = event.target.value;
    }
  }

  onChangeStartDate(event) {
    this.handlingFrequencies();
  }

  onChangeEndDate(event) {
    this.handlingFrequencies();
  }

  public getGrossAmount(): number {
    return PlcObjectUtils.roundToDecimal(+this.consultation.grossAmount, 2);
  }

  public dateRangeValidator(): ValidatorFn {
    return (group: UntypedFormGroup): any => {
      const startDateInput = group.controls.startDate;
      const endDateInput = group.controls.endDate;
      const startDate = moment(startDateInput.value);
      const endDate = moment(endDateInput.value);
      const setError = (startError, endError) => {
        startDateInput.setErrors(startError);
        endDateInput.setErrors(endError);
      };

      this.validateRange(setError, startDate, endDate);
    };
  }

  public validatePolicyTrendDate(dateForm: UntypedFormControl): any {
    const dateValue: Moment = moment(dateForm.value);
    const policyEffectDate: any = this.data.lifeContract.effectDate;
    if (dateValue.isBefore(policyEffectDate)) {
      dateForm.setErrors({effectDate: true});
      this.enablePolicyTrendButton = false;
    } else {
      this.enablePolicyTrendButton = true;
    }
  }

  countOccurrencies(startDate: Moment, endDate: Moment, interval: number): number {
    switch (interval) {
      case 11:  return (Math.abs(endDate.diff(startDate, 'days'))) + 1 ;         // giornaliero
      case 10:  return (Math.abs(endDate.diff(startDate, 'days')) / 7) + 1;      // settimanale
      case 8:  return Math.abs(endDate.diff(startDate, 'months')) + 1;           // mensile
      case 7:  return (Math.abs(endDate.diff(startDate, 'months')) / 3) + 1 ;     // trimestrale
      case 6:  return (Math.abs(endDate.diff(startDate, 'months')) / 6) + 1;    // semestrale
      case 12:  return Math.abs(endDate.diff(startDate, 'years')) + 1;          // annuale
    }
  }

  occurrenciesForInterval(startDate: Moment, endDate: Moment, interval: Frequency[]): any {
    const occurrenciesMap: any = {};
    interval.map((el: Frequency) => {
      const occurrencies = this.countOccurrencies(startDate, endDate, el.id);
      if (Math.trunc(occurrencies) > 1 && occurrencies < 19) {
        occurrenciesMap[el.id] = occurrencies;
      }
    });
    return occurrenciesMap;
  }

  setGranularFrequency(occurenciesMap: any) {
    let max = occurenciesMap[Object.keys(occurenciesMap)[0]];
    Object.keys(occurenciesMap).forEach((value) => {
      const temp = occurenciesMap[value];
      max = temp > max ? temp : max;
    });
    this.formGroup.get('chartForm').get('frequency').setValue(+Object.keys(occurenciesMap).find(el => occurenciesMap[el] === max));
  }

  setFrequencies(occurenciesMap: any) {
    const frequencyArray = [];
    Object.keys(occurenciesMap).forEach((value, key) => {
      frequencyArray.push({id : +value, description: this.frequencies.find(el => el.id === +value ).description});
    });
    this.frequencies = frequencyArray;
  }

  onClick() {
    this.errors = [];
    if (this.formGroup.get('date').valid) {
      this.eventPropagation.emit(JS_EVENT.LOADER.START);
      this.financialService
        .getFundsVolatility(this.data.lifeContract.policyNumber, moment(this.formGroup.get('date').value).format('YYYY-MM-DD'))
        .subscribe(el => {
          this.consultation = el;
          this.showInfo = true;
          this.eventPropagation.emit(JS_EVENT.LOADER.STOP);
        });
    } else {
      this.errors.push(
        this.translateService.getImmediate('lpc_error_mandatory_fields')
      );
    }
  }

  getDataChart(startDate, endDate, frequency) {
    this.financialService
      .getPolicyTrend(
        this.data.lifeContract.policyNumber,
        moment(startDate).format('YYYY-MM-DD'),
        moment(endDate).format('YYYY-MM-DD'),
        frequency
      )
      .subscribe(response => {
        this.fillChart(response);
        this.eventPropagation.emit(JS_EVENT.LOADER.STOP);
      });
  }

  fillChart(response: any) {
    this.lineChartLabels = response.map(el => moment(el.effectDate).format('DD/MM/YYYY'));
    const firstDataset = response.map(el => (+el.grossAmount).toFixed(2));
    const secondDataset = response.map(el => (+el.leftPaidAmount).toFixed(2));
    this.lineChartData.push(
      { label: this.translateService.getImmediate('lpc_contract_value'), fill: false, data: firstDataset },
      { label: this.translateService.getImmediate('lpc_invested_amount'), fill: false, data: secondDataset }
    );
  }

  updateChart() {
    this.lineChartData = [];
    this.chartErrors = [];
    const chartForm = this.formGroup.get('chartForm');
    if (chartForm.valid) {
      const startDate = moment(
        this.formGroup.get('chartForm').get('startDate').value
      ).format('YYYY-MM-DD');
      const endDate = moment(
        this.formGroup.get('chartForm').get('endDate').value
      ).format('YYYY-MM-DD');
      const frequency = this.formGroup.get('chartForm').get('frequency').value;
      this.eventPropagation.emit(JS_EVENT.LOADER.START);
      this.getDataChart(startDate, endDate, frequency);
    } else {
      this.setFeErrors();
    }
  }

  setFeErrors() {
    const startDateControl = this.formGroup.get('chartForm').get('startDate');
    const endDateControl = this.formGroup.get('chartForm').get('endDate');
    const frequencyControl = this.formGroup.get('chartForm').get('frequency');
    if (startDateControl.hasError('effectDate')) {
      this.chartErrors.push(this.getEffectDateError());
    }
    if (startDateControl.hasError('dateRange')) {
      this.chartErrors.push(
        this.translateService.getImmediate(
          'lpc_start_date_must_be_earlier_than_the_end_date'
        )
      );
    }
    if (
      startDateControl.hasError('required') ||
      endDateControl.hasError('required') ||
      frequencyControl.hasError('required')
    ) {
      this.chartErrors.push(
        this.translateService.getImmediate(
          'lpc_error_mandatory_fields'
        )
      );
    }
  }

  back() {
    this.eventPropagation.emit(JS_EVENT.BACK_TO_LIFE_DETAIL);
  }

  getLeftInvestedAmount(): number {
    const leftInvestmentAmount = (+this.consultation.grossAmount) - (+this.consultation.leftPaidAmount);
    return PlcObjectUtils.roundToDecimal(leftInvestmentAmount, 2);
  }

  private validateRange(setError: (startError, endError) => void, startDate: moment.Moment, endDate: moment.Moment) {
    if (startDate.isSame(endDate, 'date')) {
      setError({ dateRange: true }, { dateRange: true });
    } else if (startDate.isAfter(endDate)) {
      setError({ dateRange: true }, null);
    } else if (startDate.isBefore(this.data.lifeContract.effectDate)) {
      setError({ effectDate: true }, null);
    } else {
      setError(null, null);
    }
  }

  public getEffectDateError(): string {
    return this.translateService.getImmediate(
      'lpc_start_date_cannot_be_earlier_than_the_effective_date',
      {
        value: PlcDateUtils.isoToFormattedDate(
          this.data.lifeContract.effectDate
        )
      }
    );
  }

  private getDefaultPolicyTrendDate(): Date {
    const systemDate: Moment = moment();
    const effectDate: Moment = moment(this.data.lifeContract.effectDate);
    const defaultDate = systemDate.isBefore(effectDate) ? effectDate : systemDate;
    return defaultDate.toDate();
  }
}
