/* <<<<<<<<<< POST SALES FACTOR MODEL <<<<<<<<<< */

export interface SummaryFactor {
  value: string;
  description: string;
}

/* <<<<<<<<<< PP FACTOR MODEL <<<<<<<<<< */

export interface Factor {
  code: string;
  visible?: boolean;
  visibleOnConsultation?: boolean;
  values?: FactorValue[];
  description?: string;
  type?: string;
  extraValue?: string;
  formatter?: any;
  derogationLevelValuesList?: string;
  compulsory?: boolean;
  customProperties?: any;
  derogationValueList?: boolean;
  children?: string;
  visibleOnQuote?: boolean;
  factorClass?: string;
  manuallySet?: boolean;
  transcoderCode1?: string;
  transcoderCode2?: string;
  helpFile?: string;
  modifiable?: boolean;
  dependent?: boolean;
  value?: string;
  order?: string;
  applicationFactor?: boolean;
}

export interface FactorValue {
  code?: any;
  transcoderCode1?: string;
  transcoderCode2?: string;
  description: string;
  value: string;
  order: string;
}

/* <<<<<<<<<< REQUEST FACTOR MODEL <<<<<<<<<< */

export interface RequestFactor {
  code: string;
  label: string;
  value: any;
  userInput: boolean;
  typeCode: string;
}
