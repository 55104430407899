<div data-qa="life-movement-detail">

    <div class="row">
        <!-- PRIMA COLONNA COL-8 -->
        <div class="col-lg-12">
            <!-- INIZIO DATI MOVIMENTO DINAMICO -->
            <div class="col-md-12 life-detail-margin-box life-detail-box" id="consulting-detail">
                <div class="row life-detail-card-title-container">
                    <div class="row life-detail-title">
                        <div class="card-title">
                            <span class="glyphicon glyphicon-circle-arrow-left arrow-precise-pos"
                                style="cursor: pointer;" (click)="back()" data-qa="back-arrow"></span>
                            <span class="separator"> | </span>
                            <span class="menu-title" translate>lpc_fiscal_data_detail</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>


    <div *ngIf="!!data.fiscalDataDetail?.stampDutyData" class="col-md-12 life-detail-margin-box life-detail-box"
        data-qa="imposta_bollo_table" id="consulting-detail">
        <div class="row life-detail-card-title-container">
            <div class="row life-detail-title">
                <div class="card-title">
                    <span class="header-icon rgifont "></span>
                    <span class="menu-title" translate>lpc_imposta_bollo</span>
                </div>
            </div>
        </div>
        <div class="tbld life-detail-margin-top">
            <div class="tbld_row life-detail-tbld_row_header">
                <div class="tbld_col tbld_col_title" translate>lpc_data_calc</div>
                <div class="tbld_col tbld_col_title" translate>lpc_tipo_liquidazione</div>
                <div class="tbld_col tbld_col_title" translate>lpc_valore_polizza</div>
                <div class="tbld_col tbld_col_title" translate>lpc_imponibile_imp</div>
                <div class="tbld_col tbld_col_title" translate>lpc_imposta_base</div>
                <div class="tbld_col tbld_col_title" translate>lpc_imposta_calcol</div>
                <div class="tbld_col tbld_col_title" translate>lpc_imposta_da_add</div>
                <div class="tbld_col tbld_col_title" translate>lpc_imposta_add</div>
            </div>
            <div *ngFor="let duty of data.fiscalDataDetail?.stampDutyData?.stampDutyQuotes"
                class="tbld_row life-detail-tbld_row_header">
                <div class="tbld_col tbld_col_value life-detail-table-border">{{duty.date | date: 'dd/MM/yyyy'}}</div>
                <div class="tbld_col tbld_col_value life-detail-table-border">{{duty.operationType}}</div>
                <div class="tbld_col tbld_col_value life-detail-table-border">{{duty.value | rgiCtryLNumFormatter: '' : numberFormatterOptions}}</div>
                <div class="tbld_col tbld_col_value life-detail-table-border">{{duty.taxableTax | rgiCtryLNumFormatter: '' : numberFormatterOptions}}</div>
                <div class="tbld_col tbld_col_value life-detail-table-border">{{duty.baseTax | rgiCtryLNumFormatter: '' : numberFormatterOptions}}</div>
                <div class="tbld_col tbld_col_value life-detail-table-border">{{duty.calculatedTax | rgiCtryLNumFormatter: '' : numberFormatterOptions}}</div>
                <div class="tbld_col tbld_col_value life-detail-table-border">{{duty.taxToBeCharged | rgiCtryLNumFormatter: '' : numberFormatterOptions}}</div>
                <div class="tbld_col tbld_col_value life-detail-table-border">{{duty.taxCharged | rgiCtryLNumFormatter: '' : numberFormatterOptions}}</div>
            </div>
        </div>
    </div>

    <div *ngIf="!!data.fiscalDataDetail?.whiteListData" class="col-md-12 life-detail-margin-box life-detail-box"
        data-qa="imposta_bollo_table" id="consulting-detail">
        <div class="row life-detail-card-title-container">
            <div class="row life-detail-title">
                <div class="card-title">
                    <span class="header-icon rgifont "></span>
                    <span class="menu-title" translate>lpc_quote_white_list</span>
                </div>
            </div>
        </div>
        <div class="tbld life-detail-margin-top">
            <div class="tbld_row life-detail-tbld_row_header">
                <div class="tbld_col tbld_col_title" translate>lpc_data_rif</div>
                <div class="tbld_col tbld_col_title" translate>lpc_tipo_perc</div>
            </div>
            <div *ngFor="let wl of data.fiscalDataDetail?.whiteListData?.whiteListQuotes"
                class="tbld_row life-detail-tbld_row_header">
                <div class="tbld_col tbld_col_value life-detail-table-border">{{wl.date | date: 'dd/MM/yyyy'}}</div>
                <div class="tbld_col tbld_col_value life-detail-table-border">{{wl.percentage | rgiCtryLNumFormatter: '' : numberFormatterOptions}}</div>
            </div>
        </div>
    </div>

    <div *ngIf="!!data.fiscalDataDetail?.substituteTaxData" class="col-md-12 life-detail-margin-box life-detail-box"
        data-qa="imposta_bollo_table" id="consulting-detail">
        <div class="row life-detail-card-title-container">
            <div class="row life-detail-title">
                <div class="card-title">
                    <span class="header-icon rgifont "></span>
                    <span class="menu-title" translate>lpc_imposta_sostitutiva</span>
                </div>
            </div>
        </div>
        <div class="tbld life-detail-margin-top" *ngFor="let st of data.fiscalDataDetail?.substituteTaxData?.substituteQuotes">
            <div class="tbld_row life-detail-tbld_row_header">
                <div class="tbld_col tbld_col_title" translate>{{st.description}}</div>
                <div class="tbld_col tbld_col_title"></div>
                <div class="tbld_col tbld_col_title"></div>
                <div class="tbld_col tbld_col_title"></div>
            </div>
            <div class="tbld_row life-detail-tbld_row_header">
                <div class="tbld_col tbld_col_value life-detail-table-border" translate>lpc_acc_premium_initial_2011</div>
                <div class="tbld_col tbld_col_value life-detail-table-border" translate>{{st.accPremiumInitial2011 | rgiCtryLNumFormatter: '' : numberFormatterOptions}}</div>
                <div class="tbld_col tbld_col_value life-detail-table-border" translate>lpc_amount_intital_2011</div>
                <div class="tbld_col tbld_col_value life-detail-table-border" translate>{{st.amountInitial2011 | rgiCtryLNumFormatter: '' : numberFormatterOptions}}</div>
            </div>
            <div class="tbld_row life-detail-tbld_row_header">
                <div class="tbld_col tbld_col_value life-detail-table-border" translate>lpc_acc_premium_2011</div>
                <div class="tbld_col tbld_col_value life-detail-table-border" translate>{{st.accPremium2011 | rgiCtryLNumFormatter: '' : numberFormatterOptions}}</div>
                <div class="tbld_col tbld_col_value life-detail-table-border" translate>lpc_amount_2011</div>
                <div class="tbld_col tbld_col_value life-detail-table-border" translate>{{st.amount2011 | rgiCtryLNumFormatter: '' : numberFormatterOptions}}</div>
            </div>
            <div class="tbld_row life-detail-tbld_row_header">
                <div class="tbld_col tbld_col_value life-detail-table-border" translate>lpc_acc_premium_initial_2014</div>
                <div class="tbld_col tbld_col_value life-detail-table-border" translate>{{st.accPremiumInitial2014 | rgiCtryLNumFormatter: '' : numberFormatterOptions}}</div>
                <div class="tbld_col tbld_col_value life-detail-table-border" translate>lpc_amount_intital_2014</div>
                <div class="tbld_col tbld_col_value life-detail-table-border" translate>{{st.amountInitial2014 | rgiCtryLNumFormatter: '' : numberFormatterOptions}}</div>
            </div>
            <div class="tbld_row life-detail-tbld_row_header">
                <div class="tbld_col tbld_col_value life-detail-table-border" translate>lpc_acc_premium_2014</div>
                <div class="tbld_col tbld_col_value life-detail-table-border" translate>{{st.accPremium2014 | rgiCtryLNumFormatter: '' : numberFormatterOptions}}</div>
                <div class="tbld_col tbld_col_value life-detail-table-border" translate>lpc_amount_2014</div>
                <div class="tbld_col tbld_col_value life-detail-table-border" translate>{{st.amount2014 | rgiCtryLNumFormatter: '' : numberFormatterOptions}}</div>
            </div>
            <div class="tbld_row life-detail-tbld_row_header">
                <div class="tbld_col tbld_col_value life-detail-table-border" translate>lpc_acc_premium_initial_2022</div>
                <div class="tbld_col tbld_col_value life-detail-table-border" translate>{{st.accPremiumInitial2022 | rgiCtryLNumFormatter: '' : numberFormatterOptions}}</div>
                <div class="tbld_col tbld_col_value life-detail-table-border" translate>lpc_amount_intital_2022</div>
                <div class="tbld_col tbld_col_value life-detail-table-border" translate>{{st.amountInitial2022 | rgiCtryLNumFormatter: '' : numberFormatterOptions}}</div>

            </div>
            <div class="tbld_row life-detail-tbld_row_header">
                <div class="tbld_col tbld_col_value life-detail-table-border" translate>lpc_acc_premium_2022</div>
                <div class="tbld_col tbld_col_value life-detail-table-border" translate>{{st.accPremium2022 | rgiCtryLNumFormatter: '' : numberFormatterOptions}}</div>
                <div class="tbld_col tbld_col_value life-detail-table-border" translate>lpc_amount_2022</div>
                <div class="tbld_col tbld_col_value life-detail-table-border" translate>{{st.amount2022 | rgiCtryLNumFormatter: '' : numberFormatterOptions}}</div>
            </div>
        </div>
    </div>

    <div *ngIf="!!data.fiscalDataDetail?.contractualNovationData" class="col-md-12 life-detail-margin-box life-detail-box"
        data-qa="imposta_bollo_table" id="consulting-detail">
        <div class="row life-detail-card-title-container">
            <div class="row life-detail-title">
                <div class="card-title">
                    <span class="header-icon rgifont "></span>
                    <span class="menu-title" translate>lpc_novazione_contr</span>
                </div>
            </div>
        </div>
        <div class="tbld life-detail-margin-top">
            <div class="tbld_row tbld_row_single_field">
                <div class="tbld_col tbld_col_value" translate>lpc_regime_change_date</div>
                <div class="tbld_col tbld_col_value" translate>
                    {{data.fiscalDataDetail?.contractualNovationData?.substituteQuotes[0].regimeChangeDate | date: 'dd/MM/yyyy'}}
                </div>
                <div class="tbld_col tbld_col_value"></div>
                <div class="tbld_col tbld_col_value"></div>
            </div>
        </div>
        <div class="tbld life-detail-margin-top" *ngFor="let st of data.fiscalDataDetail?.contractualNovationData?.substituteQuotes">
            <div class="tbld_row life-detail-tbld_row_header">
                <div class="tbld_col tbld_col_title" translate>{{st.description}}</div>
                <div class="tbld_col tbld_col_title"></div>
                <div class="tbld_col tbld_col_title"></div>
                <div class="tbld_col tbld_col_title"></div>
            </div>
            <div class="tbld_row life-detail-tbld_row_header">
                <div class="tbld_col tbld_col_value life-detail-table-border" translate>lpc_acc_premium_intial_tax_change</div>
                <div class="tbld_col tbld_col_value life-detail-table-border" translate>{{st.accPremiumInitialTaxChange | rgiCtryLNumFormatter: '' : numberFormatterOptions}}</div>
                <div class="tbld_col tbld_col_value life-detail-table-border" translate>lpc_amount_initial_tax_change</div>
                <div class="tbld_col tbld_col_value life-detail-table-border" translate>{{st.amountInitialTaxChange | rgiCtryLNumFormatter: '' : numberFormatterOptions}}</div>
            </div>
            <div class="tbld_row life-detail-tbld_row_header">
                <div class="tbld_col tbld_col_value life-detail-table-border" translate>lpc_acc_premium_tax_change</div>
                <div class="tbld_col tbld_col_value life-detail-table-border" translate>{{st.accPremiumTaxChange | rgiCtryLNumFormatter: '' : numberFormatterOptions}}</div>
                <div class="tbld_col tbld_col_value life-detail-table-border" translate>lpc_amount_tax_change</div>
                <div class="tbld_col tbld_col_value life-detail-table-border" translate>{{st.amountTaxChange | rgiCtryLNumFormatter: '' : numberFormatterOptions}}</div>
            </div>
        </div>
    </div>


</div>
