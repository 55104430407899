import {
  Component, EventEmitter,
  Inject,
  Input, OnInit,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { TranslationWrapperService } from '../i18n/translation-wrapper.service';
import { concatMap, distinctUntilChanged, map, switchMap, take } from 'rxjs/operators';
import { LifeRoleService } from '../life-card/services/life-role.service';
import { LifeSessionService } from '../life-card/services/life-session-service';
import { PassProLifeService } from '../life-card/services/passpro-life.service';
import { AnagFlowData, AnagSearchOrCreateResolver } from '@rgi/anag';
import { Roles } from '../life-card/enum/life-issue.enum';
import { RgiRxTabSelectionChange } from '@rgi/rx/ui';
import { Observable, of } from 'rxjs';
import { Subject } from '../life-card/models/subject.model';
import { ValidationSubjectsService } from '../life-card/services/validation-subjects.service';
import { PolicyRole } from '../life-card/models/policy.model';

@Component({
  selector: 'lic-preventive-card',
  templateUrl: './lic-preventive-card.component.html',
  styleUrls: ['./lic-preventive-card.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class LicPreventiveCardComponent implements OnInit {

  @Output() eventPropagation = new EventEmitter<any>();

  @Input() set selectedSubject(subject: any) {
    if (!!subject) {
      if (subject === 'noSubj') {
        this.selectedSubj = null;
        this.formGroup.get('subject').setValue(null);
      } else if (!!subject.objectId) {
        this.selectedSubj = subject;
        this.formGroup.get('subject').setValue(subject);
      }
    }
  }
  @Input() idSession: string;

  messages: {code: string, msg: string} [] = [];
  get validationMessages() {
    return this.messages.map(m => m.msg);
  }

  selectedIndex = 0;
  submitted = false;
  isAnonymous = false;

  public formGroup: UntypedFormGroup;
  public roleTypeToAdd = '4';

  // ASSIMOCO NODE
  defaultAgency: any;
  selectedAgency: any;
  listOfNode: any[];
  listOfProduct: any[];

  selectedSubj: any;
  onlyQuickQuotation = true;
  withBasket = false;

  get isSubjectSelected(): boolean {
    return !!this.formGroup.get('subject') && !!this.formGroup.get('subject').value;
  }

  constructor(
    protected lifeSessionService: LifeSessionService,
    protected lifeRoleService: LifeRoleService,
    protected passProService: PassProLifeService,
    protected translateService: TranslationWrapperService,
    @Inject('authService') protected authService: any,
    @Inject('$injector') protected injector: any,
    @Inject('RGI_ANAG_PORTAL_AJS_FLOW') protected anagInJs: boolean,
    protected anagSearchorCreateResolver: AnagSearchOrCreateResolver,
    protected validationService: ValidationSubjectsService
  ) {}

  setDefaultNode() {
    this.eventPropagation.emit('startLoader');
    this.changeNodeInLifeSessionService(this.authService.getOperator().salePoint.code, this.authService.getOperator().salePoint.objectId);
    this.lifeSessionService.getAvailableNodes().pipe(
      concatMap(respNodes => {
        this.listOfNode = respNodes.agencies.map((agency) => {
          return {
            displayValue: agency.idSp,
            description: agency.description,
            code: agency.code
          };
        });
        // First research is done with the login node pre-valued in the select to have the product list
        return this.passProService.getProductsFromService(this.onlyQuickQuotation);
      })
    ).subscribe((prodlist) => {
      if (!!prodlist && !!prodlist.products && prodlist.products.length >= 0) {
        this.listOfProduct = prodlist.products;
      }
      this.eventPropagation.emit('stopLoader');
    }, error => {
      console.log(error);
      this.eventPropagation.emit('stopLoader');
    });
  }

  ngOnInit() {
    this.initForm();
    this.formGroup.get('node').patchValue(this.authService.getOperator().salePoint.objectId);
    this.withBasket = true;
    this.setDefaultNode();
  }

  public translate(key: string): string {
    return this.translateService.getImmediate(key);
  }

  private refreshCard() {
    const angularGridInstance = this.injector.get('angularGridInstance');
    if (angularGridInstance !== 'undefined') {
      angularGridInstance.cards.refresh();
    }
  }

  onSelectionChange(tabSelection: RgiRxTabSelectionChange) {
    if (tabSelection.index === 1 )  {
      this.isAnonymous = true;
      this.formGroup.removeControl('subject');
      this.messages = [];
    } else {
      this.isAnonymous = false;
      if (!this.formGroup.get('subject')) {
        this.formGroup.addControl('subject', new UntypedFormControl(null, {validators: [Validators.required]}));
      }
    }
    this.refreshCard();
  }

  initForm() {
    this.formGroup = new UntypedFormGroup({
      subject: new UntypedFormControl(null, {
        validators: [Validators.required]
      }),
      node: new UntypedFormControl(null, {
        validators: [Validators.required]
      }),
      product: new UntypedFormControl(null, {
        validators: [Validators.required]
      })
    });
  }

  wrapSubjectEvent(event) {
    this.messages = [];
    this.refreshCard();
    if (this.anagInJs) {
      this.emitEventForAJSAnagFlow();
    } else {
      this.handleAnagEventInNewFlow();
    }
  }

  protected emitEventForAJSAnagFlow() {
    this.eventPropagation.emit({
      eventName: 'requireSubject',
      sessionParent: 'anagFinder',
      sessionRoute: 'home',
      sessionTitle: 'Seleziona soggetto',
      navigationDisabled: true,
      roleCode: Roles.CONTRACTOR
    });
    this.lifeRoleService.getObservable().pipe(distinctUntilChanged(), take(1)).pipe(
      switchMap(subject => {
        return this.checkPartyCompleted(subject).pipe(
          map(res => {
            if (res.partyKO) {
              this.refreshCard();
              return null;
            } else {
              return subject;
            }
          })
        );
      })
    ).subscribe(value => {
      if (!!value) {
        this.handleSelectedSubject(value);
      }
    });
  }

  protected handleAnagEventInNewFlow() {
    const anagFlowData = new AnagFlowData();
    anagFlowData.partyRole = Roles.CONTRACTOR;
    anagFlowData.nodeCode = this.lifeSessionService.codNodeManagement;
    // anagFlowData.partyType = ;
    // anagFlowData.operation = ;
    // anagFlowData.idParentSession = this.data.idParentSession;
    this.anagSearchorCreateResolver.searchOrCreateSubject(anagFlowData).pipe(
      switchMap(subject => {
        return this.checkPartyCompleted(subject).pipe(
          map(res => {
            if (res.partyKO) {
              this.refreshCard();
              return null;
            } else {
              return subject;
            }
          })
        );
      })
    ).subscribe(subject => {
      if (!!subject && !!subject.objectId) {
        this.handleSelectedSubject(subject);
      }
    });
  }

  handleSelectedSubject(inputSubject) {
    this.selectedSubject = inputSubject;
    this.lifeRoleService.storeSubject(inputSubject);
    this.lifeRoleService.setSubjectFromAnag(inputSubject);
    this.formGroup.get('subject').setValue(inputSubject);
  }

  openSession() {
    if (!!this.messages.length) {
      return ;
    }
    this.submitted = true;
    const idpv = this.formGroup.get('node').value;
    const nodeCode = this.getNodeCodeFromId(idpv);
    const productType = this.listOfProduct.find(p => p.productCode === this.formGroup.get('product').value).productTypeCode;
    if (this.formGroup.valid) {
      const sessionService = this.injector.get('sessionService');
      if (!!sessionService) {
        const id = sessionService.open(
          'ptflifeProposal',
          'anag',
          this.translateService.getImmediate('lic_PreventiveIssue'),
          true,
          false,
          false,
          this.idSession);
        this.eventPropagation.emit({
          eventName: 'setSubjectOnCoreResult',
          isAnonymous: this.isAnonymous,
          subject: this.isAnonymous ? null : this.formGroup.get('subject').value,
          id,
          product: this.formGroup.get('product').value,
          data: {
            idpv,
            nodeCode,
            productType
          }
        });
      }
    }
  }

  deleteSbj() {
    this.formGroup.get('subject').setValue(null);
    this.selectedSubj = null;
    this.messages = [];
    this.refreshCard();
  }


  onChange(nodeValue) {
    this.withBasket = true;
    this.eventPropagation.emit('startLoader');
    const nodeCodeSelected = this.getNodeCodeFromId(nodeValue);
    this.changeNodeInLifeSessionService(nodeCodeSelected, nodeValue);
    this.passProService.getProductsFromService(this.onlyQuickQuotation).subscribe(prodlist => {
      if (!!prodlist && !!prodlist.products && prodlist.products.length >= 0) {
        this.listOfProduct = prodlist.products;
        this.formGroup.get('product').reset();
      }
      this.eventPropagation.emit('stopLoader');
    }, err => {
      console.log(err);
      this.eventPropagation.emit('stopLoader');
    });
  }

  // bottone sul campo nodo (cestino)
  cleanNode() {
    this.formGroup.get('node').patchValue(null);
    this.withBasket = false;
  }

  changeNodeInLifeSessionService(nodeCode, nodeId) {
    if (!!nodeCode) {
      this.lifeSessionService.codNodeManagement = nodeCode;
    }
    if (!!nodeId) {
      this.lifeSessionService.idPvManagement = nodeId;
    }
  }

  getNodeCodeFromId(nodeId) {
    let nodeCode;
    if (!!this.listOfNode.length) {
      this.listOfNode.map((node) => {
        if (!nodeCode && node.displayValue === nodeId) {
          nodeCode = node.code;
        }
      });
    }
    return nodeCode;
  }

  protected checkPartyCompleted(value: Subject): Observable<{partyKO: boolean}> {
    const pr = new PolicyRole(Roles.CONTRACTOR, value);
    return this.lifeRoleService.checkPartyCompleted(
      Number(value.objectId),
      Number(value.idLatestPhotos),
      Number(pr.partyRole),
      null,
      Number(this.formGroup.getRawValue().node)
    ).pipe(
      switchMap(responsePartyComplete => {
        return this.validationService.checkRole(
          pr,
          0,
          [],
          true
        ).pipe(
          map(responseCheck => {
            return this.validationService.mapResponsePartyCompleteToCheck(responsePartyComplete, responseCheck, pr);
          })
        );
      }),
      switchMap((responseMessages) => {
        this.messages = this.messages.concat(
          responseMessages.errorMessages.map(m => {
            return {msg: m.errorDescription || m.description, code: 'anag_SUBJ'};
          })
        );
        return of({partyKO: !!this.messages.length});
      })
    );
  }

}
