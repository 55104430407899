import { FormGroup, ValidatorFn } from '@angular/forms';

export function capitalRequestValidator(IMC: number): ValidatorFn {
  return (group: FormGroup): {[key: string]: any} | null => {
    const K1 = Math.floor(Number(group.get('K1').value));
    const K2 = Math.floor(Number(group.get('K2').value));
    const K3 = Math.floor(Number(group.get('K3').value));
    if (K1 + K2 + K3 > IMC) {
      return { error: 'lpc_max_amount_exceeded' };
    }
    return null;
  };
}

export function allocationValidator(M1: number, M2: number): ValidatorFn {
  return (group: FormGroup): {[key: string]: any} | null => {
    const K1 = Math.floor(Number(group.get('K1').value));
    const K2 = Math.floor(Number(group.get('K2').value));
    const K3 = Math.floor(Number(group.get('K3').value));
    if (M1 + M2 >= K1 + K2 + K3 && K3 !== 0) {
      return { error: 'lpc_max_amount_error' };
    }
    return null;
  };
}

export function revisionaryValidator(): ValidatorFn {
  return (group: FormGroup): {[key: string]: any} | null => {
    if (!group) {
      // If reversionaryForm doesn't exist in the group
      return null;
    }
    if (!!group.value && group.value.value == null) {
      return { error: 'lpc_revisionary_err' };
    }
    if (!!group.value && group.value.value && group.value.value.percentage == null) {
      return { error: 'lpc_revisionary_perc_err' };
    }

    return null; // If no errors
  };
}
