import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import { RgiCountryLayerModule } from '@rgi/rgi-country-layer';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import { LpcRolesStepComponent } from './lpc-roles-step.component';
import { LpcRolesControlModule } from '../lpc-roles-control/lpc-roles-control.module';
import { LpcRolesStepWrapperComponent } from './lpc-roles-wrapper/lpc-roles-step-wrapper.component';
import { PV_TOKEN } from '../../models/consts/lpc-consts';
import { RgiRxI18nModule } from '@rgi/rx/i18n';
import { LpcRequiredModule } from '../../utils/lpc-required/lpc-required.module';

@NgModule({
    declarations: [LpcRolesStepComponent, LpcRolesStepWrapperComponent],
    imports: [
        CommonModule,
        RgiCountryLayerModule,
        NgbModule,
        LpcRolesControlModule,
        RgiRxI18nModule,
        LpcRequiredModule
    ],
    exports: [LpcRolesStepComponent, LpcRolesStepWrapperComponent],
    providers: [
        { provide: PV_TOKEN.ROLES_STEP_COMPONENT, useValue: LpcRolesStepComponent },
    ]
})
export class LpcRolesStepModule {
}
