<div  class="example-row pip-page" >
    <form #ngForm="ngForm" [formGroup]="form" (ngSubmit)="onSubmit(ngForm)">

      <div rgi-rx-accordion [multi]="true">
        <rgi-rx-expansion-panel [expanded]="true" color="default">
          <div rgi-rx-expansion-panel-header>
            <span class="header-icon rgifont rgi-dettagli_tecnici"></span>
            <span class="header-text" translate>Specific PIP data</span>
          </div>
          <div content>
            <div class="panel-body"  style="height: auto;">
              <lic-factors
                (changeValEmitter)="updateFactors($event, true)"
                [parentForm]="form"
                [fattori]="pipFactors">
              </lic-factors>
              <div class="col-lg-12" *ngIf="showDatoreDiLavoro()">
                  <div><span class="header-text" translate>lic_Employer</span></div>
                  <div style="padding-left: 10px; padding-top: 10px;">
                    <label class="label-core" for="lic_Employer">
                        <span>{{'lic_Employer' | translate}}</span>
                        <lic-required required="true"></lic-required>
                    </label>
                    <lic-select-subject
                        [val]="form.get('datoreLavoro').get('val').value"
                        [parentForm]="form.get('datoreLavoro')"
                        [role]="'300002'"
                        (eventPropagation)="openAnag($event)"
                        (delete)="resetEmployer($event)">
                    </lic-select-subject>
                  </div>
              </div>

            </div>
          </div>
        </rgi-rx-expansion-panel>
        <br><br>

        <ng-container *ngIf="isTransferData && !!transferData?.length">
          <rgi-rx-expansion-panel [expanded]="true" color="default">
            <div rgi-rx-expansion-panel-header>
              <span class="header-icon rgifont rgi-dettagli_tecnici"></span>
              <span class="header-text" translate>lic_Transferdata</span>
            </div>
            <div content>
              <div class="panel-body"  style="height: auto;">
                <div formGroupName="transferData">
                    <div class="row">
                      <div class="col-md-6" style="height: 77px" *ngFor="let factor of transferData">
                        <lic-generator [definition]="factor"
                                       [formControlName]="factor.code"
                                       (eventPropagation)="onEmit($event)"
                                       [submitted]="ngForm.submitted"
                                       (handleSubject)="handlePensionCompany($event)"
                                       [prevalSubject]="pensionCompany"
                                       (resetSubject)="resetPensionCompany($event)"
                                      >
                        </lic-generator>
                      </div>
                    </div>
                </div>
            </div>
            </div>
          </rgi-rx-expansion-panel>
          <br><br>
        </ng-container>

        <ng-container *ngIf="showContributions">
          <rgi-rx-expansion-panel [expanded]="true" color="default">
            <div rgi-rx-expansion-panel-header>
              <span class="header-icon rgifont rgi-dettagli_tecnici"></span>
              <span class="header-text" translate>lic_Contributiondata</span>
            </div>
            <div content>
              <div class="panel-body"  style="height: auto;">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="col-lg-9 col-md-8 col-sm-8"></div>
                        <div class="col-lg-2 text-center">
                            <label class="switch">
                                <input formControlName="switchAmount" style="position: fixed;" type="checkbox" class="form-control" (click)="changeSwitch($event)">
                                <span class="slider" true="%" [attr.false]="currencyCode"></span>
                            </label>
                        </div>
                    </div>
                    <div formArrayName="contributionData">
                        <div class="col-md-12"  *ngFor="let contr of contributionData.contributions;  let j = index">
                            <div [formGroupName]="j.toString()">
                                <div class="margin-check col-lg-4 col-md-4 col-sm-5">
                                    <lic-generator [definition]="contr?.check"
                                    [formControlName]="'check'"
                                    (eventPropagation)="onEmit($event)"
                                    [submitted]="ngForm.submitted"
                                    >
                                    </lic-generator>
                                </div>
                                <div class="col-lg-3 col-md-4 col-sm-4">
                                    <lic-generator [definition]="contr?.frequency"
                                    [formControlName]="'frequency'"
                                    (eventPropagation)="onEmit($event)"
                                    [submitted]="ngForm.submitted"
                                    [disabled]="doDisableFields(j, contr ,'frequency')"
                                    [mandatory]="doMandatoryFields(j)"
                                    >
                                    </lic-generator>
                                </div>
                                <div class="col-lg-2 hidden-md hidden-sm"></div>
                                <div class="col-lg-2 col-md-4 col-sm-3">
                                    <lic-generator [definition]="contr?.value"
                                    [formControlName]="'amount'"
                                    (eventPropagation)="onEmit($event)"
                                    [submitted]="ngForm.submitted"
                                    [disabled]="doDisableFields(j)"
                                    [mandatory]="doMandatoryFields(j)"
                                    >
                                    </lic-generator>
                                </div>
                                <div class="hr-row col-lg-12">
                                    <hr>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
              </div>
            </div>
          </rgi-rx-expansion-panel>
          <br><br>
        </ng-container>

      </div>
      <!-- fine multi accordion -->


        <rgi-rx-modal #proposalSaveModal>
          <rgi-rx-panel>
            <rgi-rx-panel-header [closeable]="true" (onClose)="proposalSaveModal.close()">{{'lic_NumeroProposta' | translate}}</rgi-rx-panel-header>
            <rgi-rx-panel-footer>
              <button class="rgi-ui-btn rgi-ui-btn-primary" (click)="proposalSaveModal.close()" translate>lic_close</button>
            </rgi-rx-panel-footer>
            <span translate>lic_Proposal_Saved</span> {{proposalNumber}}
          </rgi-rx-panel>
        </rgi-rx-modal>



        <!-- MESSAGGI di ERRORE -->
        <rgi-lcc-messages-floating [validationMessages]="getValidationMessages" [blockingMsgs]="getBlockingMessages"
            [warningMsgs]="getWarningMessages" [authoMsgs]="getAuthMessages">
        </rgi-lcc-messages-floating>

        <div class="btn-group btn-group-justified">
            <div class="btn-group">
                <button type="button" class="btn btn-warning pull-left btn-secondary text-uppercase" data-qa="lic-role-save"
                  (click)="onSave(proposalSaveModal)" translate>lic_save</button>
              </div>
            <div class="btn-group">
                <button type="submit" data-qa="lic-factors-submit"
                    class="btn btn-warning pull-right text-uppercase" translate>lic_NEXT
                </button>
            </div>
        </div>
    </form>

</div>
