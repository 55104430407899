<div class="grid no-date">
  <form [formGroup]="group">
    <rgi-rx-form-field>
      <label rgiRxLabel>
        <span>{{label}}</span>
        <lic-required *ngIf="config.mandatory" required="true"></lic-required>
      </label>
      <input licFormatPayment
             (blur)="onBlur()"
             [paymentInput]="config"
             [formControlName]="config.name"
             class="form-control"
             [attr.data-qa]="'mic-input-payment-'+config.name"
             [attr.data-obb]="'mic-input-payment-' + config.mandatory"
             rgiRxInput>
             <br><br>
    </rgi-rx-form-field>
  </form>
</div>
