import { LpcTranslationsKeys } from './lpc-translations-keys';

/**
 * !! IMPORTANT !!
 * Add a new key on the bottom of the list and start declaring from the LpcTranslationsKeys
 *
 * @description
 * This is the list of all the translations keys used in the application for the life-postsales-card library.
 */
export const LPC_ES: LpcTranslationsKeys = {
  lpc_Investment_profiles: 'Perfiles de inversión',
  lpc_payment_method: 'Método de pago',
  lpc_payment_type: 'Tipo de pago',
  lpc_investment_funds: 'Fondos de inversión',
  lpc_fund_type: 'Tipo de fondo',
  lpc_profile_type: 'Tipo de perfil',
  lpc_elements: 'elementos',
  lpc_fund_name: 'Nombre del fondo',
  lpc_profile_name: 'Nombre del perfil',
  lpc_profiles: 'Perfiles',
  lpc_funds: 'Fondos',
  lpc_division_type: 'Tipo de división',
  lpc_division_description: 'Descripción de la asignación',
  lpc_start_validity_date: 'Fecha del inicio de la validez',
  lpc_fund_description: 'Descripción del fondo',
  lpc_ISIN_code: 'Código ISIN',
  lpc_Allocation: 'Asignación',
  lpc_profile_details: 'Detalles del perfil',
  lpc_fields_generic_error: '',
  lpc_portfolio_fund_display: 'Visualización de cartera de fondos de inversión',
  lpc_payment_data: 'Datos de pago',
  lpc_Warning_you_are_deleting_the_contractor: 'Aviso, estás eliminando la parte contratante. ¿Quiere seguir?',
  lpc_Investment_amount: 'Cantidad de inversión:',
  lpc_mov_status: 'Título de estado',
  lpc_mov_Amount: 'Importe del título',
  lpc_ticket_status_label: 'Valoración pendiente de las cuotas',
  lpc_occurenceNumber: '',
  lpc_status_investment_label: 'Estado de inversión',
  lpc_order_status_label: 'Estado Orden',
  lpc_selected_funds: 'Fondos seleccionados',
  lpc_Premium: 'Prima',
  lpc_InvestmentByFund: 'Inversión por fondo',
  lpc_Loading: 'Tasas',
  lpc_NetPremium: 'Prima neta',
  lpc_IntFract: 'Fraccionamiento de intereses',
  lpc_Fees: 'Cargos fijos',
  lpc_Taxes: 'Impuestos',
  lpc_GrossPremium: 'Prima bruta',
  lpc_FirstInstallment: 'Primera cuota',
  lpc_AnnualPremium: 'Prima anual',
  lpc_NextInstallment: 'Siguiente cuota',
  lpc_Guarantee: 'Garantía',
  lpc_BenefitOnDate: 'Beneficio en fecha',
  lpc_Benefit: 'Beneficio',
  lpc_AnnualSinglePremium: 'Prima anual/única',
  lpc_ActiveGuarantees: 'Garantías activas',
  lpc_Enhancement: 'Datos financieros',
  lpc_CalculationData: 'Datos de cálculos',
  lpc_ControvaloreAllaData: 'Valor bursátil en fecha',
  lpc_AccumulationOfPrizes: 'Suma de las primas',
  lpc_TotalSurrender: 'Total del rescate',
  lpc_AccumulationOfReducedPremiums: 'Suma de las primas reducidas',
  lpc_TotalBalance: 'Balance total',
  lpc_EffectiveDate: 'Fecha de vigencia',
  lpc_Movement: 'Movimiento',
  lpc_Movements: 'Movimientos',
  lpc_booking_number: 'Números de la reserva',
  lpc_booking_policyData: 'Datos de la póliza',
  lpc_booking_assetData: 'Datos del bien asegurado',
  lpc_PolicyLinked: 'Pólizas vinculadas',
  lpc_Motivo_Legame: 'Motivación de los bonos',
  lpc_ProposalIssueDate: 'Fecha de emisión de la propuesta',
  lpc_PolicyIssueDate: 'Fecha de emisión de la póliza',
  lpc_ExpirationDate: 'Fecha de caducidad',
  lpc_NextInstalmentDate: 'Fecha de la siguiente cuota',
  lpc_found_duplicate: 'Aviso',
  lpc_duplicate_subject_message: 'El sujeto seleccionado ya está incluido entre los beneficiarios',
  lpc_SelectSettlement: 'Elija la liquidación',
  lpc_You_must_select_the_settlement_to_perform_the_variation: 'Debe seleccionar la liquidación para ejecutar la variación',
  lpc_beneficiaryPercentage: 'Porcentaje para el sujeto',
  lpc_beneficiaryPercentage_greater: 'Debe ser mayor que',
  lpc_beneficiaryPercentage_lower: 'Debe ser menor que',
  lpc_beneficiaryDuplicate: 'Duplicar el sujeto',
  lpc_NoOperations: 'No hay acciones disponibles',
  lpc_confirm: 'Confirmar',
  lpc_description: 'Descripción',
  lpc_issueDate: 'Fecha de emisión',
  lpc_effectiveDate: 'Fecha de vigencia',
  lpc_MovementData: 'Cancelar apéndice',
  lpc_Are_you_sure_you_want_to_confirm_this_claim: '¿Está seguro que quiere confirmar este siniestro',
  lpc_Are_you_sure_you_want_to_cancel_this_claim: '¿Está seguro que quiere cancelar este siniestro?',
  lpc_warnings: 'Avisos',
  lpc_warning: 'Aviso',
  lpc_warning_message: 'Seleccione "PETICIÓN DE AUTORIZACIÓN" para enviar una solicitud de aprobación para hacer la operación',
  lpc_ClaimCanceled: 'El siniestro se ha cancelado con éxito',
  lpc_ClaimConfirmed: 'El siniestro se ha confirmado con éxito',
  lpc_reserve: 'guardar',
  lpc_questionnaire: 'Cuestionario',
  lpc_bonus_amount: 'Importe de la bonificación',
  lpc_life_detail_beneficiary: 'Beneficiario',
  lpc_life_detail_percentage: 'Porcentaje',
  lpc_life_detail_net_amount: 'Cantidad neta',
  lpc_life_detail_questionnaire: 'Cuestionario',
  lpc_life_detail_beneficiaries: 'Beneficiarios',
  lpc_AppendixNumber: 'Número del apéndice',
  lpc_nessuna_variazione: 'Sin variación',
  lpc_appendixLabel: 'Apéndice del beneficiario',
  lpc_operation_rescission_reason: 'Razón de la rescisión',
  lpc_Lien_type_creditor: 'Tipo de acreedor',
  lpc_creditor: 'Acreedor',
  lpc_Open_lien: 'Derecho de retención abierto',
  lpc_assignment_holder_type: 'Tipo de titular de la asignación',
  lpc_assignment_holder: 'Titular de la asignación',
  lpc_assignment_amount: 'Importe',
  lpc_assignment_policy_number: 'Número del contrato',
  lpc_assignment_company: 'Sociedad',
  lpc_assignment_type: 'Tipo de asignación',
  lpc_assignment_benefit: 'Beneficio',
  lpc_amount_less_of_benefit: 'El importe debe ser menor que el beneficio',
  lpc_open_assignment: 'Asignación abierta',
  lpc_sum_det_cancel_proposal: 'Cancelar la propuesta',
  lpc_sum_det_success_cancel: 'La propuesta se ha cancelado con éxito',
  lpc_sum_det_success_cancel_prev: 'El presupuesto se ha cancelado con éxito',
  lpc_sum_det_confirm_cancel: '¿Quiere confirmar la cancelación de la propuesta?',
  lpc_sum_det_confirm_cancel_prev: '¿Quiere confirmar la cancelación de este presupuesto?',
  lpc_sum_det_confirm_prop_issue: 'El presupuesto se emitirá. ¿Quiere confirmar?',
  lpc_sum_det_confirm_pol_issue: 'La póliza se emitirá. ¿Quiere confirmar?',
  lpc_health_questionnaire: 'Cuestionario de salud',
  lpc_quotation_show_details: 'Mostrar información',
  lpc_quotation_premium_details: 'Información de la prima',
  lpc_quotation_warranties_details: 'Información de las garantías',
  lpc_quotation_commissions_details: 'Comisiones',
  lpc_Reference_Third_Party: 'Terceros de referencia',
  lpc_policyHolder: '',
  lpc_DelegateEqualToPolicyHolder: '¿Delegado igual al Asegurado?',
  lpc_LegalGuardianEqualToPolicyHolder: '¿Representante legal igual al Asegurado?',
  lpc_ThirdPayerEqualToPolicyHolder: '¿Tercer pagador iguales que el Asegurado?',
  lpc_DelegateEqualToPolicyHolderWarning: 'Sujeto con el rol de Delegado igual al del Asegurado',
  lpc_LegalGuardianEqualToPolicyHolderWarning: 'Sujeto con el rol de Representante legal igual al del Asegurado',
  lpc_ThirdPayerEqualToPolicyHolderWarning: 'Sujeto del rol de Tercer pagador igual al del Asegurado',
  lpc_but_confirm_accept: 'Conformar y aceptar',
  lpc_Date_Start_Variation: 'Fecha del inicio de la variación',
  lpc_New_Frequent_Payment: 'Nuevo pago frecuente',
  lpc_start_date_profit: 'Fecha de inicio de los beneficios',
  lpc_end_date_profit: 'Fecha de fin de los beneficios',
  lpc_last_rate_profit: 'Última tasa de beneficios',
  lpc_last_profit: 'Últimos beneficios',
  lpc_Fund_Code: 'Código del fondo',
  lpc_OperationSynstesis: 'Síntesis de la operaciones',
  lpc_PreviusPerceent: 'Porcentaje previo',
  lpc_PreviusValue: 'Valor previo',
  lpc_NumberOfUnit: 'Número previo de unidad',
  lpc_PostPErcent: 'Porcentaje del puesto',
  lpc_PostValue: 'Valor del puesto',
  lpc_PostNumberOfUnit: 'Número del puesto del presupuesto',
  lpc_Dati: 'Datos',
  lpc_PIP_Data: 'Datos Pip',
  lpc_PIP_General: 'Datos generales',
  lpc_PIP_Contribution: 'Contribución Pip',
  lpc_HolderData: 'Datos asegurados',
  lpc_RiskData: 'Datos de riesgo',
  lpc_IBAN_error: 'El Iban no es correcto',
  lpc_BACK: 'Atrás',
  lpc_ACTIONS: 'Acciones',
  lpc_DETAIL: 'Información',
  lpc_CANCEL: 'Cancelar la propuesta',
  lpc_CONFIRM_PROPOSAL: 'Confirmar la propuesta',
  lpc_CONFIRM_POLICY: 'Confirmar la póliza',
  lpc_not_linked_detail: 'Información de {{name}}',
  lpc_quote_number: 'Número de presupuesto',
  lpc_valid_quote: 'Presupuesto válido',
  lpc_BUY_PREV: 'Comprar',
  lpc_EDIT_PREV: 'Editar',
  lpc_EDIT: 'Editar',
  lpc_CANCEL_PREV: 'Suprimir',
  lpc_modify_quote: 'Modificar presupuesto',
  lpc_modify_proposal: 'Modificar propuesta',
  lpc_buy_quote: 'Presupuesto de compras',
  lpc_Close_assignment: 'Asignación cerrada',
  lpc_Close_lien: 'Derecho de retención cerrado',
  lpc_disinvestment_funds: 'Fondos de desinversión',
  lpc_error_mandatory_fields: 'Los campos en rojo son obligatorios o contienen valores incorrectos',
  lpc_disinvestment_msg: '{{amount}} para desinvertir o {{percent}}',
  lpc_disinvestment_allocation_msg: 'La asignación de inversión supera el importe disponible',
  lpc_maximum_percentage: 'Porcentaje máximo aceptado',
  lpc_maximum_amount: 'Cantidad máxima aceptada',
  lpc_minimum_percentage: 'Porcentaje mínimo aceptado',
  lpc_minimum_amount: 'Cantidad mínima aceptada',
  lpc_investment_msg: '{{amount}} para invertir o {{percent}}',
  lpc_Fields_marked_in_red_are_required: 'Los campos en rojo son obligatorios',
  lpc_amount_format_validation: 'El importe no es válido, el separador decimal es incorrecto. Utilice "." para corregirlo',
  lpc_select_at_least_one_beneficiary: 'Seleccione al menos un beneficiario',
  lpc_quote_due_date: 'Fecha de la emisión del presupuesto',
  lpc_roles_variation: 'Roles',
  lpc_fiscal_data: 'Datos fiscales',
  lpc_data_calc: 'Fecha de cálculo',
  lpc_tipo_liquidazione: 'Tipo de liquidación',
  lpc_valore_polizza: 'Valor de la póliza',
  lpc_imponibile_imp: 'Impuesto imponible',
  lpc_imposta_base: 'Base imponible',
  lpc_imposta_calcol: 'Impuesto calculado',
  lpc_imposta_da_add: 'Impuesto que debe abonarse',
  lpc_imposta_add: 'Impuesto devengado',
  lpc_imposta_bollo: 'Timbre fiscal',
  lpc_fiscal_data_detail: 'Información de datos fiscales',
  lpc_quote_white_list: 'Lista blanca de presupuesto',
  lpc_data_rif: 'Fecha de referencia',
  lpc_tipo_perc: 'Porcentaje de la lista blanca',
  lpc_CancellInstallment: 'Resumen de la solicitud de la anulación de la cuota',
  lpc_DescOperazione: 'Descripción de la operación',
  lpc_RateAmount: 'Importe total de la cuota',
  lpc_OPEN_LATEST_VERSION: 'Última versión abierta',
  lpc_TOTAL_INVESTED: 'Total invertido',
  lpc_REMAINING_AMOUNT: 'Importe remanente',
  lpc_descrizione_rischio: 'Descripción de los riesgos',
  lpc_prestazione_ridotta_iniz: 'Reducción de la prestación original',
  lpc_prestazione_rivalutata: 'Reducción de la revalorización del beneficio',
  lpc_provvigioni_da_recuperare: 'Comisión que debe recuperarse',
  lpc_reg41: 'Nominativo con el que comunicarse',
  lpc_disinvestment_amount: 'Importe de la desinversión',
  lpc_occurrenceNumber: 'Número de incidencia',
  lpc_frequecy: 'Frecuencia',
  lpc_the_occurenceNumber_must_be_a_value: 'El número de incidencia debe ser mayor que {{min}}',
  lpc_disinvestment_range: 'Introduzca un importe a desinvertir superior a {{max}} e inferior o igual a {{min}}',
  lpc_state: 'Estado',
  lpc_substate: 'Subestado',
  lpc_product_type: 'Tipo de producto',
  lpc_managementnode: 'Nodo de gestión',
  lpc_subagency: 'Subagencia',
  lpc_branch_name: 'Marca',
  lpc_agreement: 'Acuerdo',
  lpc_payment_frequency: 'Frecuencia de pago',
  lpc_mean_of_payment: 'Medio de pago',
  lpc_Subcause: 'Subcausa',
  lpc_premium_payment_suspension_date: 'Fecha de suspensión del pago de la prima',
  lpc_imposta_sostitutiva: 'Impuesto sustitutivo',
  lpc_novazione_contr: 'Novación contractual',
  lpc_acc_premium_initial_2011: 'Acumulación de la prima 31/12/2011 inicial',
  lpc_amount_intital_2011: 'Importe 31/12/2011 inicial',
  lpc_acc_premium_2011: 'Acumulación de la prima 31/12/2011',
  lpc_amount_2011: 'Importe 31/12/2011',
  lpc_acc_premium_initial_2014: 'Acumulación de la prima 30/06/2014 inicial',
  lpc_acc_premium_2014: 'Acumulación de la prima 30/06/2014',
  lpc_amount_intital_2014: 'Importe 30/06/2014 inicial',
  lpc_amount_2014: 'Importe 30/06/2014',
  lpc_acc_premium_initial_2022: 'Suma inicial de las primas el 31/12/2022',
  lpc_acc_premium_2022: 'Suma de las primas el 31/12/2022',
  lpc_amount_intital_2022: 'Capital inicial e intereses el 31/12/2022',
  lpc_amount_2022: 'Capital e intereses el 31/12/2022',
  lpc_acc_premium_intial_tax_change: 'Cambio inicial en la acumulación del impuesto sobre las primas',
  lpc_amount_initial_tax_change: 'Importe inicial del cambio fiscal',
  lpc_acc_premium_tax_change: 'Cambio fiscal de la acumulación de la prima',
  lpc_amount_tax_change: 'Importe del cambio fiscal',
  lpc_regime_change_date: 'Fecha del cambio fiscal',
  lpc_please_enter_a_disinvestment_amount: 'Introduzca un importe de desinversión del fondo: {{fondo}}',
  lpc_please_select_at_least_a_disinvestment: 'Seleccione al menos una desinversión',
  lpc_reg41Msg: 'Receptor de las comunicaciones',
  lpc_cost: 'Coste',
  lpc_paymentFrequencyInterest: 'Frec. Inter.',
  lpc_netpremium: 'Prima neta',
  lpc_grosspremium: 'Prima bruta',
  lpc_information: 'Información:',
  lpc_reinvest_data_detail: 'Información sobre las reinversiones',
  lpc_polizza: 'Póliza',
  lpc_data_effetto_mov: 'Fecha de vigencia',
  lpc_netto_liquidazione: 'Importe neto de la liquidación',
  lpc_netto_reinvestire: 'Importe neto de la reinversión',
  lpc_stato_reinvest: 'Estado de la liquidación de la reinversión',
  lpc_reinvested_settlements: 'Liquidaciones reinvertidas',
  lpc_loans: 'Préstamos',
  lpc_elaboration_date: 'Fecha de elaboración',
  lpc_calculation_date: 'Fecha de cálculo',
  lpc_annuity_data: 'Información de la renta vitalicia',
  lpc_extra_premium: 'Prima extra',
  lpc_loan_interest: 'Intereses del préstamo',
  lpc_fractionation_interest: 'Interés de fraccionamiento',
  lpc_default_interest: 'Interés por falta de pago',
  lpc_reactivation_interest: 'Interés de reactivación',
  lpc_installment_detail: 'Información de la cuota',
  lpc_effectiveAnnuityDate: 'Fecha de vigencia de la anualidad',
  lpc_firstInstallmentDate: 'Fecha de la primera cuota',
  lpc_expiryAnnuityDate: 'Fecha de vencimiento de la anualidad',
  lpc_amountFirstInstallment: 'Importe de la primera cuota',
  lpc_initialAnnuityAmount: 'Importe inicial de la anualidad',
  lpc_periodicityInstallmentAnnuity: 'Periodicidad de la anualidad de la cuota',
  lpc_typePaymentAnnuity: 'Tipo de pago de la anualidad',
  lpc_performanceType: 'Tipo de rendimiento',
  lpc_annuityType: 'Tipo de anualidad',
  lpc_annuity_detail: 'Información de la anualidad',
  lpc_canceled_liquidation_detail: 'Información sobre la cancelación de la liquidación',
  lpc_Percentage: 'Porcentaje',
  lpc_loan_data: 'Información sobre el préstamo',
  lpc_maxLoanAmount: 'Importe máximo pagadero',
  lpc_loanRepaymentProcedure: 'Procedimiento de reembolso del préstamo',
  lpc_loanAmount: 'Importe del préstamo',
  lpc_rateType: 'Tipo de interés del préstamo',
  lpc_maxLoanAmountKO: '0 € (error en el cálculo del importe máximo pagadero)',
  lpc_grant_date: 'Fecha de la concesión',
  lpc_loan_amount: 'Importe del préstamo',
  lpc_return_way: 'Método de la devolución',
  lpc_interest_rate_type: 'Tipo de interés',
  lpc_residual_amount: 'Importe residual',
  lpc_refund_type: 'Tipo de reembolso',
  lpc_refund_amount: 'Importe del reembolso',
  lpc_select_lpc_refund_type: 'Seleccione un tipo de reembolso',
  lpc_leftInsuredAmount: 'Capital asegurado residual',
  lpc_insuredAmount: 'Importe invertido',
  lpc_reactivatedCapital: 'Capital reactivado',
  lpc_premium_reactivation: 'reactivación_prima',
  lpc_negative_amount: 'Importe residual negativo',
  lpc_zero_amount: 'No se encuentra el importe residual. Introduzca un importe superior a 0',
  lpc_error_mandatory_selected_type_of_refund: 'Seleccione un tipo de reembolso',
  lpc_select_refund: 'Seleccione al menos un préstamo',
  lpc_selected_profiles: 'Perfiles seleccionados:',
  lpc_coupon_beneficiary: 'Beneficiario del cupón',
  lpc_bookings: 'Reservas',
  lpc_booking_type: 'Tipo de reservas',
  lpc_booking_state: 'Estados',
  lpc_booking_effective_date: 'Fecha de vigencia de la reserva',
  lpc_booking_extrapremiumHealth: 'Prima extra de salud',
  lpc_booking_extrapremiumProf: 'Prima extra profesional',
  lpc_booking_extrapremiumSport: 'Prima extra deportiva',
  lpc_booking_extrapremiumOther: 'Otra prima extra',
  lpc_booking_effectiveDate: 'Fecha de vigencia',
  lpc_booking_detail_label: '',
  lpc_Rischi: 'Coberturas',
  lpc_booking_destinationNode: 'Nodo de destino',
  lpc_booking_cause: 'Causa',
  lpc_PolicyData: 'Datos de la póliza',
  lpc_booking_cancellationDate: 'Fecha de cancelación',
  lpc_extra_premiums: 'Primas extra',
  lpc_error_reservation_cancel: 'Error en la cancelación del {{descr}} número {{num}}',
  lpc_success_reservation_cancel: 'La cancelación del {{descr}} número {{num}} ha sido confirmada',
  lpc_incomplete_disinvestment_for_the_funds: 'Desinversión incompleta de los fondos',
  lpc_Sinistro_morte: 'Siniestro por fallecimiento',
  lpc_Sinistro_invalidita: 'Siniestro de invalidez',
  lpc_Sinistro_inabilità: 'Siniestro de incapacidad',
  lpc_Malattia_grave: 'Siniestro por enfermedad grave',
  lpc_insured_form: 'Formulario para el asegurado',
  lpc_other_contract_list: 'Otra lista de contratos',
  lpc_modified_personal_data_for_the_subject: 'Datos personales modificados del sujeto',
  lpc_subject_roles: 'Roles del sujeto',
  lpc_no_variation_modpd: 'No se han realizado cambios, modificar el sujeto',
  lpc_DeductiblePremium: 'Prima deducible',
  lpc_firstterm: 'Primer trimestre',
  lpc__1TERM_ATOOL: 'Fecha de activación de la herramienta',
  lpc__1TERM_MTOOL: 'Modificar la herramienta de modificación',
  lpc__1TERM_DTOOL: 'Fecha de eliminación de la herramienta',
  lpc_management_fees: 'Comisiones de gestión',
  lpc_investment_not_found: 'No hay elementos para seleccionar',
  lpc_No_policies: 'No hay pólizas para el sujeto modificado',
  lpc_variation_convention: 'Variación de la convención',
  lpc_andamento_polizza_alla_data: 'Evolución de la política hasta la fecha',
  lpc_appendice_dichiarativa: 'Apéndice de la declaración',
  lpc_policy_number_dot: 'N. de la póliza',
  lpc_investment_sum_must_be100: 'La suma de las inversiones debe ser igual al 100 %',
  lpc_Operation_detail: 'Información de las operaciones',
  lpc_Notwithstanding_any_ongoing_operations: 'Los valores pueden estar sujetos a cambios debido a las operaciones en curso',
  lpc_percentage_max_zero: 'Inserte un porcentaje superior a 0',
  lpc_Mostra_tutti: 'Visualizar todo',
  lpc_Visualizza_dettaglio: 'Ver información',
  lpc_consultazione_storica: 'Consulta histórica',
  lpc_dettaglio_movimento: 'Información del movimiento',
  lpc_mensile: 'mensual',
  lpc_annuale: 'anual',
  lpc_sixmonthly: 'semestral',
  lpc_trimestrale: 'cuatrimestral',
  lpc_Titolo_non_incassato: 'Existencias sin recoger',
  lpc_Rid: 'DDP',
  lpc_In_attesa_valorizzazione_quote: 'Valoración pendiente de las participaciones invertidas',
  lpc_switch_di_perequazione: 'Cambio programado',
  lpc_SELEZIONA: '--SELECCIONE--',
  lpc_settimanale: 'semanalmente',
  lpc_Valido: 'Válido',
  lpc_quotidiano: 'diario',
  lpc_Assegno: 'Verificar',
  lpc_Bonifico: 'Transferencia bancaria',
  lpc_Sdd: 'Orden de domiciliación bancaria',
  lpc_severeDisabilityMsg: 'Beneficiario con discapacidad grave',
  lpc_benDg: 'Beneficiario con discapacidad',
  lpc_distribution: 'Asignación',
  lpc_Distribution_amount_exceeds: 'El importe de la distribución supera el importe total de la liquidación disponible',
  lpc_distribution_invalid_greater: 'El {{tipo}} no es válido, debe ser superior a {{min.}}',
  lpc_distribution_invalid_greater_equal: 'El {{tipo}} no es válido: debe ser inferior o igual a {{máx.}}',
  lpc_perc: 'Porcentaje',
  lpc_amount: 'Importe',
  lpc_Please_insert_a_distribution: 'Inserte una distribución',
  lpc_For_the_Beneficiary: 'Para el Beneficiario',
  lpc_global_distribution: 'El importe total para liquidar debe ser inferior o igual a {{totalAmount}}',
  lpc_invalid_char: 'El formato de {{tipo}} contiene caracteres incorrectos',
  lpc_toDistribute: 'para distribuir',
  lpc_noClaims: 'No se han encontrado siniestros',
  lpc_Data_sinistro: 'Fecha del siniestro',
  lpc_Data_Pervenimento_Denuncia: 'Fecha en la que se recibió la notificación del siniestro',
  lpc_Data_Denuncia_Sinistro: 'Fecha de la notificación del siniestro',
  lpc_Tipo_sinistro: 'Tipo de siniestro',
  lpc_inserisci_denuncia: 'Inserte siniestro',
  lpc_Conferma_inserimento_denuncia: 'Confirmación de la inserción del siniestro',
  lpc_Denuncia_inserita_correttamente: 'El siniestro se ha insertado con éxito',
  lpc_Stato_denuncia: 'Estado del siniestro',
  lpc_marital_status: 'Estado civil',
  lpc_fiscal_code: 'Código fiscal',
  lpc_birthdate: 'Fecha de nacimiento',
  lpc_address: 'Dirección',
  lpc_sex: 'Sexo',
  lpc_annulla_denuncia: 'Cancelar el siniestro',
  lpc_modifica_denuncia: 'Modificar el siniestro',
  lpc_conferma_denuncia: 'Confirmar el siniestro',
  lpc_new_beneficiary: 'Nuevo beneficiario',
  lpc_Seleziona: 'Seleccionar',
  lpc_insert_phisical_sbj_for: 'Insertar un sujeto físico para el rol {{role}}',
  lpc_insert_legal_sbj_for: 'Inserte un sujeto legal para el rol {{role}}',
  lpc_payment_incomplete: 'Información de incompleta de pago',
  lpc_add_mandatory_linkedroles: 'Introduzca roles obligatorios para el sujeto introducido',
  lpc_beneficiary_owner_recipient_must_be_physical: 'El beneficiario efectivo debe ser una persona física',
  lpc_enter_the_beneficial_owners_of_the_recipient: 'Introduzca los beneficiarios efectivos del destinatario',
  lpc_global_percentage_must_be_a_maximum_100: 'El porcentaje global debe ser 100 como máximo',
  lpc_overall_percentage_must_equal100: 'El porcentaje total debe ser igual a 100',
  lpc_Questionario_deve_essere_compilato: 'Se debe rellenar el cuestionario',
  lpc_enter_mandatory_data: 'Introduzca la información obligatoria',
  lpc_enter_an_adult: 'Introduzca un adulto',
  lpc_enter_a_beneficial_owner: 'Introduzca un beneficiario efectivo',
  lpc_enter_a_physical_beneficial_owner: 'Introduzca un beneficiario efectivo físico',
  lpc_global_percentage_of_beneficial_owners_must_not_exceed_100: 'El porcentaje global de los beneficiarios efectivos no deben superar los 100',
  lpc_beneficial_owners_percentages_must_be_greater_than0: 'El porcentaje del beneficiario efectivo debe superar los 0,0',
  lpc_effective_holder_perc: '',
  lpc_effective_holder_duplicated: 'Titular efectivo duplicado',
  lpc_enter_the_minimum_number_of_subjects: 'Introducir el número mínimo de los sujetos',
  lpc_percentage_must_be_greater_than_0: 'El porcentaje debe superar el 0.0',
  lpc_enter_free_text: 'Introducir un texto libre',
  lpc_beneficiary_executor: 'Albacea beneficiario',
  lpc_effective_recipient: 'Receptor efectivo',
  lpc_recipient_owner: 'Propietario receptor',
  lpc_beneficial_owner_of_beneficiary: 'Propietario beneficiario',
  lpc_confirmed_claim_modify: 'Modificación del siniestro confirmada',
  lpc_claim_modified_correctly: 'Siniestro modificado correctamente',
  lpc_Assicurato: 'Asegurado',
  lpc_Assicurati: 'Asegurados',
  lpc_holderType: 'Tipo de empleo del solicitante',
  lpc_ageRetirement: 'Edad en el momento de la jubilación',
  lpc_accessTransfer: 'Solicitante de traslado',
  lpc_emptyContribution: 'Contribución del empleador',
  lpc_firstEmployment: 'Fecha de inicio del empleo',
  lpc_oldUnderWritt: 'Antiguo reclutado',
  lpc_underWitt1993: 'Reclutado el 29/4/1993',
  lpc_maturato2001: 'Devengado a 31/12/2000',
  lpc_maturato2006: 'Devengado a 31/12/2006',
  lpc_riduzImponibiTFR: 'Reducción sobre la TGF imponible',
  lpc_aliquotaTFR: 'Tipo TFR(fiscalidad anterior a 2001)',
  lpc_PIP_Contribution_Type: 'Tipo de la contribución',
  lpc_YES: 'Sí',
  lpc_PIP: 'Plan de pensiones individual',
  lpc_PIP_Contribution_Percentage: 'Porcentaje',
  lpc_PIP_Contribution_Amount: 'Importe',
  lpc_transfer_data: 'Información de la transferencia',
  lpc_unit_questionnaire: 'Cuestionario de la unidad',
  lpc_amount_reimbured: 'Importe para reembolsar',
  lpc_Descr_not_handled: 'Descripción no gestionada para este código de operación',
  lpc_select_transfer: 'Selección de la transferencia',
  lpc_fiscalFolderDataPost2006: 'Sistema fiscal posterior a 2006',
  lpc_fiscalFolderData20012006: 'Sistema fiscal del 2001-2006',
  lpc_fiscalFolderDataBefore2001: 'Sistema fiscal anterior a 2001',
  lpc_filledOut: 'Rellenado',
  lpc_blank: 'Blanco',
  lpc_desc_previousPenFund: 'Fondo de pensiones anterior',
  lpc_companyPreviousPenFund: 'Nombre de la compañía anterior al fondo de pensiones',
  lpc_typePreviousPenFund: 'Tipo del fondo de pensiones anterior',
  lpc_note: 'Notas',
  lpc_Benef_irrevocabile: 'Beneficiario irrevocable',
  lpc_PIP_Transfer: 'Transferencias de otro fondo',
  lpc_requestDate: 'Fecha de la solicitud',
  lpc_Type_Previous_Pension_Fund: 'Tipo del fondo de pensiones anterior',
  lpc_Previous_Pension_Fund: 'Fondo de pensiones anterior',
  lpc_Import_Transfert: 'Importe de la transferencia',
  lpc_Note: 'Notas',
  lpc_relief: 'Desgravaciones fiscales de 2022',
  lpc_selected_settlement: 'Liquidación seleccionada',
  lpc_EDIT_ANONYMOUS_PREV: 'Editar',
  lpc_invalid_quote: 'Presupuesto no válido',
  lpc_reasonForRequest: 'Motivo de la solicitud',
  lpc_advancePaymentAmount: 'Importe del anticipo del pago',
  lpc_repaidAdvance: 'Anticipo reembolsado',
  lpc_PIP_AdvancedPayment: 'Anticipo de los pagos',
  lpc_requestReason: 'Motivo de la solicitud',
  lpc_notRepaidAmount: 'El importe no se ha reembolsado todavía',
  lpc_fiscalDate: 'Fecha de vigencia',
  lpc_previousPension: 'Plan de pensiones anterior',
  lpc_fiscalCompetence: 'Competencia fiscal',
  lpc_settlementStatus: 'Estado del anticipo del pago',
  lpc_repaidAmount: 'Importe reembolsado',
  lpc_advancePaymentTransfer: 'Anticipos de las transferencias',
  lpc_policy_factors: 'Factores de las pólizas',
  lpc_asset_factors: 'Factores de los bienes asegurados',
  lpc_Date_Variation: 'Línea temporal de la variación',
  lpc_penalties_amount: 'Importe de las penalizaciones',
  lpc_tax_amount: 'Importe de los impuestos',
  lpc_warranties_detail: 'Información de las garantías',
  lpc_fixedCharges: 'Cargos fijos',
  lpc_catastrophe: 'Prima extra de catástrofes',
  lpc_health: 'Prima extra de salud',
  lpc_other: 'Otra prima extra',
  lpc_professional: 'Prima extra profesional',
  lpc_sports: 'Prima extra deportiva',
  lpc_collectionFee: 'Tasa de cobro',
  lpc_lambdaFee: 'Tasa de Lambda',
  lpc_managementFee: 'Tasa de gestión',
  lpc_purchaseFee: 'Tasa de compra',
  lpc_continue_button: 'Continuar',
  lpc_confirm_button: 'Confirmar',
  lpc_questionSTR_invalid: 'La respuesta a la pregunta: {{question}}, no es correcta',
  lpc_questionNUM_invalid: 'La cantidad ingresada para la pregunta: {{question}} no es correcta',
  lpc_Beneficiaryowner: 'Beneficiario propietario',
  lpc_confirm_operation: 'Confirmar operación',
  lpc_financial_questionnaire: 'Cuestionario financiero',
  lpc_avc_questionnaire: 'Cuestionario AVC',
  lpc_confirm_variation: 'Confirmar variación',
  lpc_Declarative_Appendix: 'Apéndice declarativo',
  lpc_investment_funds_step: 'Fondos de inversión',
  lpc_disinvestment_profile_step: 'Perfiles de desinversión',
  lpc_startDate: 'Fecha de inicio',
  lpc_end_date: 'Fecha de finalización',
  lpc_six_monthly: 'Semestral',
  lpc_quarterly: 'Trimestral',
  lpc_weekly: 'Semanal',
  lpc_monthly: 'Mensual',
  lpc_annual: 'Anual',
  lpc_daily: 'Diario',
  lpc_start_date_cannot_be_earlier_than_the_effective_date: 'La fecha de inicio no puede ser anterior a la fecha efectiva {{value}}',
  lpc_start_date_must_be_earlier_than_the_end_date: 'La fecha de inicio debe ser anterior a la fecha de finalización',
  lpc_contract_value: 'Valor del contrato',
  lpc_invested_amount: 'Monto invertido',
  lpc_funds_details_and_summary: 'Detalles y resumen de los fondos',
  lpc_separate_management_detail: 'Detalle de gestión separada',
  lpc_denuncia_sinistro: 'Notificación de reclamo',
  lpc_duration_in: 'Duración en',
  lpc_years: 'años',
  lpc_months: 'meses',
  lpc_days: 'días',
  lpc_policy_number: 'Número de póliza',
  lpc_proposal_number: 'Número de propuesta',
  lpc_LifeBeneficiary: 'Beneficiario en vida',
  lpc_DeathBeneficiary: 'Beneficiario en caso de fallecimiento',
  lpc_NO: 'No',
  lpc_claim_data: '',
  lpc_node: 'Nodo',
  lpc_sbj: 'Asunto',
  lpc_empty: 'vacío',
  lpc_search_result: 'Resultados de búsqueda',
  lpc_show_elemets: 'Mostrar elementos',
  lpc_insured: 'Asegurado',
  lpc_notification_status: 'Estado de notificación',
  lpc_nuova_denuncia: 'nueva notificación',
  lpc_insured_policy: 'Pólizas aseguradas',
  lpc_product: 'Producto',
  lpc_find: 'Buscar',
  lpc_status: 'Estado',
  lpc_fund_trend: 'Tendencia del fondo',
  lpc_category_fund: 'Perfil/Fondo',
  lpc_equivalent_on_date: 'Equivalente en la fecha',
  lpc_quotes_number: 'Número de unidades',
  lpc_damages_policy: 'Póliza de daños',
  lpc_life_policy: 'Póliza de vida',
  lpc_next_installment_premium: 'Prima de la próxima cuota',
  lpc_no_result: 'Sin resultados',
  lpc_data_value: 'Fecha Valor Participación',
  lpc_paidAmount: 'Importe pagado',
  lpc_total_gross_surrender: 'Total de la devolución bruta',
  lpc_left_premiums_paid: 'Primas pagadas restantes',
  lpc_left_premiums_amount: 'Importe de las primas pagadas restantes',
  lpc_portfolio_volatility_on_subscription_date: 'Volatilidad de la cartera en la fecha de suscripción',
  lpc_portfolio_volatility: 'Volatilidad de la cartera',
  lpc_andamento_controvalore: 'Evolución del valor nominal',
  lpc_policy_trend: 'Evolución de la póliza',
  lpc_calculate: 'Calcular',
  lpc_frequency: 'Frecuencia',
  lpc_date_variation_step: 'Fecha de la variación',
  lpc_quotation_step: 'Quotización',
  lpc_confirm_variation_step: 'Confirmar la variación',
  lpc_financial_questionnaire_step: 'Cuestionario financiero',
  lpc_close_button: 'Cerrar',
  lpc_Operation_of_executed_correctly: 'Operación {{operation}} realizada correctamente',
  lpc_AuthorizatioPolicyForwarded: 'La solicitud de autorización para el número de póliza {{policyNumber}} ha sido enviada al Usuario correspondiente',
  lpc_are_you_sure_you_want_to_cancel_this_operation: '¿Está seguro de que quiere cancelar esta operación?',
  lpc_cancel_button: 'Cancelar',
  lpc_cancel_reservation: 'Cancelar la reserva',
  lpc_system_error: 'Error del sistema',
  lpc_generic_error_title: 'Error',
  lpc_select_label: 'Seleccionar',
  lpc_Warning_you_are_deleting_the_policyholder_Do_you_want_to_proceed: 'Atención, está eliminando el titular de la póliza. ¿Quiere continuar?',
  lpc_factor_must_be_equal: 'El {{factor}} debe ser igual a {{value}}',
  lpc_factor_must_be_greater_than: 'El {{factor}} debe ser superior a {{value}}',
  lpc_factor_must_be_less_than: 'El {{factor}} debe ser inferior a {{value}}',
  lpc_Please_enter_threshold_percentage_value: 'Fondo: {{value}} - Por favor ingrese el porcentaje de umbral',
  lpc_please_enter_a_value_between: 'Por favor ingrese un valor entre {{min}} y {{max}}',
  lpc_variation_step_label: 'Variación',
  lpc_contractual_option: 'Opción contractual',
  lpc_costs_step_label: 'Costes',
  lpc_variation_detail_step_label: 'Detalle de la variación',
  lpc_ongoing_operations: 'Los valores podrían estar sujetos a cambios debido a operaciones en curso',
  lpc_exchange_rate: 'Tipo de cambio',
  lpc_last_share_value: 'Último valor de la acción',
  lpc_movement_description: 'Descripción del movimiento',
  lpc_settlement_amount: 'Monto del acuerdo',
  lpc_Annual_SinglePremium: 'Prima anual/única',
  lpc_policy_address: 'Dirección de la póliza',
  lpc_holders: 'Titulares',
  lpc_last_revaluation: 'Última revalorización',
  lpc_revalueted_benef: 'Beneficio revalorizado',
  lpc_investment: 'Inversiones',
  lpc_position_number: 'Número de posición',
  lpc_invested_premium: 'Prima invertida',
  lpc_operation_details: 'Detalles de la operación',
  lpc_contract: 'Contrato',
  lpc_dis_date: 'Fecha de desinversión',
  lpc_gross_amount: 'Monto bruto',
  lpc_net_amount: 'Monto neto',
  lpc_net: 'Neto',
  lpc_quote: 'Recalcular',
  lpc_quote_and_save: 'Recalcular y guardar',
  lpc_info_investment_complete: 'Información: Inversión completa',
  lpc_effective_holders: 'Titulares efectivos',
  lpc_effective_holder: 'Titular efectivo',
  lpc_third_party_role: 'Tercero pagador',
  lpc_lien_credit: 'Motivo de Rescisión',
  lpc_end_validity_date: 'Fecha de fin de validez',
  lpc_start_date: 'Fecha de inicio',
  lpc_unit_linked_value: 'Valor de Unidad Vinculada',
  lpc_allocation_profile: 'Perfil de Asignación',
  lpc_search_filter: 'Filtro de Búsqueda',
  lpc_expiry_option_detail: 'Detalle de Opción de Vencimiento',
  lpc_annuity_frequency: 'Frecuencia de Anualidad',
  lpc_annuity_payment_type: 'Tipo de Pago de Anualidad',
  lpc_percentage_reversibility: 'Porcentaje de Reversibilidad',
  lpc_reversionary: 'Reversionario',
  lpc_certain_revenue_years: 'Años Ciertos de Ingresos',
  lpc_postponement_type: 'Tipo de Aplazamiento',
  lpc_postponement_years: 'Años de Aplazamiento',
  lpc_clauses: 'Cláusulas',
  lpc_active_contractual_option: 'Opciones Contractuales Activas',
  lpc_expiry_option: 'Opción de Expiración',
  lpc_revaluation_history: 'Historial de Revalorización',
  lpc_monetary_fund: 'Fondo Monetario',
  lpc_benefit_effect_date: 'Fecha de Efecto del Beneficio',
  lpc_initial_benefit: 'Beneficio Inicial',
  lpc_movement_update: 'Actualización de Movimiento',
  lpc_update_effect_date: 'Fecha de Efecto de Actualización',
  lpc_net_rate: 'Tasa Neta',
  lpc_macro_category: 'Macro Categoría',
  lpc_quotation_frequency: 'Frecuencia de Cotización',
  lpc_volatility: 'Volatilidad',
  lpc_volatility_date: 'Fecha de Volatilidad',
  lpc_start_quotation: 'Inicio de Cotización',
  lpc_last_quotation: 'Última Cotización',
  lpc_valuation: 'Valoración',
  lpc_percentage_total: 'Porcentaje Total',
  lpc_euro_valuation_not_fund_currency: 'La valoración se expresa en € y no en la moneda del fondo',
  lpc_disinvstements: 'Desinversiones',
  lpc_payment_amount: 'Monto del Pago',
  lpc_surrender_distribution: 'Distribución de Rendición',
  lpc_formula: 'Fórmula',
  lpc_roles_connected_benef: 'Roles Vinculados al Beneficiario',
  lpc_quotation_details: 'Detalle de Cotización',
  lpc_Disinvestmentsfunds: 'Disinvestments funds',
  lpc_same_fund_distribution: 'Distribución de fondos idéntica al depósito inicial',
  lpc_not_same_fund_distribution: 'Distribución de fondos modificada respecto al depósito inicial',
  lpc_guarantees_oveview: 'Resumen de Garantías',
  lpc_calculate_volatility: 'Calcular la Volatilidad del Activo',
  lpc_benef_death_not_same: 'El beneficiario por fallecimiento no puede ser el sujeto asegurado',
  lpc_global_percentage_equal_hundred: 'El porcentaje global debe ser igual al 100',
  lpc_effective_holder_natural_person_req: 'El sujeto {{linkedName}} en el papel de titular real de un {{benefRole}} {{benefCat}} debe ser una persona física',
  lpc_benefPresente_default_msg: 'El sujeto ya ha sido seleccionado como beneficiario en la misma categoría.',
  lpc_effective_holder_duplicate: 'El sujeto {{linkedName}} ya ha sido seleccionado en el papel de titular efectivo de {{benefName}}.',
  lpc_effective_holder_adult_req: 'El sujeto {{linkedName}} en el papel de titular efectivo de un {{benefRole}} {{benefCat}} debe ser un adulto.',
  lpc_investment_plan: 'Plan de inversión',
  lpc_contract_detail: 'Detalle del contrato',
  lpc_variation_reason: 'Motivo de la variación',
  lpc_investment_plan_detail: 'Detalle del plan de inversión',
  lpc_revaluation_rate: 'Tasa de Revalorización',
  lpc_benefit_commencement_date: 'Fecha de Inicio de la Prestación',
  lpc_update_effective_date: 'Fecha de Efecto de la Actualización',
  lpc_last_odds_value: 'Ultimo valore quota',
  lpc_total: 'Total',
  lpc__EFFOP: 'Fecha Efectiva',
  lpc__OPZFR: 'Fraccionamiento',
  lpc__OPZPM: 'Método de Pago',
  lpc__OPBEN: 'Beneficiario',
  lpc__MINTS: 'Porcentaje Umbral Mínimo',
  lpc__MAXTS: 'Porcentaje Umbral Máximo',
  lpc__NUMOC: 'Número de Ocurrencias',
  lpc__AMTOP: 'Importe del Rescate',
  lpc__MALMT: 'Importe Mínimo',
  lpc__CIBAN: 'IBAN',
  lpc__PCOUP: 'Porcentaje de Cupón Periódico',
  lpc_threshold_percentage: 'Porcentaje Umbral',
  lpc_pure: 'Premio Puro',
  lpc_starting_fund: 'Fondo Inicial',
  lpc_details: 'Detalles',
  lpc_treshold: 'Umbral',
  lpc_inv_date: 'Fecha de Inversión',
  lpc_beneficiary_perc_req: '{{benefCat}} {{benefRole}} {{benefName}}: Ingrese un porcentaje',
  lpc_beneficiary_perc_req_range: ' {{benefRole}} {{benefCat}} {{benefName}}: El porcentaje debe estar comprendido entre 0.01 y 100',
  lpc_invalid_total_position_percent: 'El porcentaje total para el agrupamiento "{{groupType}}" en el Beneficiario {{cat}} debe ser del 100%',
  lpc_beneficiary_from_party_req: ' {{benefCat}} {{benefRole}}: Ingrese un beneficiario del repositorio de partes',
  lpc_benefPresente: '{{benefName}} ya ha sido ingresado como {{benefCat}} {{benefRole}}',
  lpc_benMorteNoAssic: '{{benefName}} selezionato come {{benefRole}} {{benefCat}} è già stato censito come assicurato.',
  lpc_life_beneficiary: 'Beneficiario de vida',
  lpc_death_beneficiary: 'Beneficiario de Muerte',
  lpc_beneficiary_position_mandatory: 'Beneficiario {{benefCat}} {{benefName}}, seleccione un "Número de Posición"',
  lpc_beneficiary_qualification: ' {{benefCat}} {{benefRole}} ',
  lpc_reversionary_subject: 'Sujeto reversionario',
  lpc_gross_detail: '',
  lpc_type_of_covers: '',
  lpc_clausesinsuredClauses: 'Clausulas del Asegurado',
  lpc_clausespolicyClauses: 'Clausulas de la Póliza',
  lpc_clausesriskClauses: 'Clausulas del Riesgo',
  lpc_max_capital_amount: 'IMC – Importe Máximo de Capital',
  lpc_fiscal_period_M1: 'M1 – Monto acumulado al 31/12/2000',
  lpc_fiscal_period_M2: 'M2 - Monto acumulado desde el 1/1/2001 hasta el 31/12/2006',
  lpc_fiscal_period_M3: 'M3 - Monto acumulado desde el 1/1/2007',
  lpc_fiscal_period_capital_request_K1: 'Parte de M1 solicitada en capital',
  lpc_fiscal_period_capital_request_K2: 'Parte de M2 solicitada en capital',
  lpc_fiscal_period_capital_request_K3: 'Parte de M3 solicitada en capital',
  lpc_max_amount_exceeded: 'El importe solicitado en capital supera el máximo posible.',
  lpc_max_amount_error: 'El importe en capital solicitado debe ser totalmente asignado a los montos M1 y M2.',
  lpc_performance_data: 'Datos de rendimient',
  lpc_role_is_mandatory: '{{role}} es obligatorio',
  lpc_issue_authorized_proposal: '',
  lpc_confirm_prop_pol_auth: 'Confirmar propuesta/política en Autorización',
  lpc_settlement: 'Liquidación',
  lpc_annuity: 'Renta vitalicia',
  lpc_percentage_in_capital: '% en capital',
  lpc_insured_amount: 'Importe invertido',
  lpc_custom_field_mandatory_error: 'El campo {{field}} es obligatorio',
  lpc_print_docs: 'Imprimir documentos',
  lpc_revisionary_err: 'Ingresar sujeto reversionario',
  lpc_revisionary_perc_err: 'Ingresar porcentaje del sujeto reversionario',
  lpc_percentage_must_be_between: 'El porcentaje debe estar entre 0.01 y 100',
  lpc_PIP_UndeductedPremiums: '',
  lpc_contributionYear: '',
  lpc_annualPremium: '',
  lpc_undeductedPremium : '',
  lpc_reversionario: "Reversionary",
  lpc_firstEnrollmentComplementaryPension: ''
};
export default LPC_ES;
