<div [attr.data-qa]="'life-detail-'+id" class="life-consulting-page">
  <div class="row" *ngIf="isContractPresent && initializeView">
    <!-- PRIMA COLONNA COL-8 -->
    <div class="col-sm-12 col-lg-8">
      <!-- DATI GENERALI CONTRATTO -->
      <lpc-summary-detail-card
        [lastMovementId]="movements[0]?.idMov"
        [header]="'lpc_contract_detail'"
        [generalData]="lifeContract.generalData"
        [additionalData]="lifeContract.generalData.additionalData"
        [buttons]="true"
        [isPolicy]="isPolicy"
        [movDetail]="movDetail"
        [messages]="lifeContract.messages"
        [avalActionOperations]="availablesInquiryActions"
        (eventPropagation)="propagate($event)"
        (actionPropagation)="actionHandler($event)">
      </lpc-summary-detail-card>
      <!-- FINE DATI GENERALI CONTRATTO -->

      <!-- TABELLA PREMI -->
      <div *ngIf="!loader && !!lifeContractPremium" class="col-md-12 life-detail-margin-box life-detail-box" id="consulting-detail" data-qa="premium-table">
        <div class="row life-detail-card-title-container">
          <div class="row life-detail-title">
            <div class="card-title">
              <span class="menu-title" translate>lpc_Premium</span>
              <div class="content-btn-header-right" style="bottom: 10px; right: 10px">
                <button data-qa="open-premium-detail" class="btn dt-btn" (click)="openPremiumDetail()">
                  <span class="rgifont rgi-chevron-right"></span>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="tbld life-detail-margin-top">
          <div class="tbld_row life-detail-tbld_row_header">
            <div class="tbld_col tbld_col_title life-detail-table-width-22" translate>lpc_Premium</div>
            <div class="tbld_col tbld_col_title life-detail-table-width-13" translate>lpc_Loading</div>
            <div class="tbld_col tbld_col_title life-detail-table-width-13" translate>lpc_NetPremium</div>
            <div class="tbld_col tbld_col_title life-detail-table-width-13" translate>lpc_IntFract</div>
            <div class="tbld_col tbld_col_title life-detail-table-width-13" translate>lpc_Fees</div>
            <div class="tbld_col tbld_col_title life-detail-table-width-13" translate>lpc_Taxes</div>
            <div class="tbld_col tbld_col_title life-detail-table-width-13" translate>lpc_GrossPremium</div>
          </div>
          <div *ngIf="!!lifeContractPremium.firstInstallment" class="tbld_row">
            <div data-qa="premium-firstInstallment" class="tbld_col tbld_col_value life-detail-table-border life-detail-table-width-22" translate>lpc_FirstInstallment</div>
            <div data-qa="premium-firstInstallment-caricamenti" class="tbld_col tbld_col_value life-detail-table-border life-detail-table-width-13">{{lifeContractPremium.firstInstallment.loadings | rgiCtryLNumFormatter: '' : currencyFormatterOptions}}</div>
            <div data-qa="premium-firstInstallment-premioNetto" class="tbld_col tbld_col_value life-detail-table-border life-detail-table-width-13">{{lifeContractPremium.firstInstallment.netPremium | rgiCtryLNumFormatter: '' : currencyFormatterOptions}}</div>
            <div data-qa="premium-firstInstallment-intFraz" class="tbld_col tbld_col_value life-detail-table-border life-detail-table-width-13">{{lifeContractPremium.firstInstallment.splitInterests | rgiCtryLNumFormatter: '' : currencyFormatterOptions}}</div>
            <div data-qa="premium-firstInstallment-diritti" class="tbld_col tbld_col_value life-detail-table-border life-detail-table-width-13">{{lifeContractPremium.firstInstallment.adminFees | rgiCtryLNumFormatter: '' : currencyFormatterOptions}}</div>
            <div data-qa="premium-firstInstallment-imposte" class="tbld_col tbld_col_value life-detail-table-border life-detail-table-width-13">{{lifeContractPremium.firstInstallment.taxes | rgiCtryLNumFormatter: '' : currencyFormatterOptions}}</div>
            <div data-qa="premium-firstInstallment-premioLordo" class="tbld_col tbld_col_value life-detail-table-border life-detail-table-width-13">{{lifeContractPremium.firstInstallment.grossPremium| rgiCtryLNumFormatter: '' : currencyFormatterOptions}}</div>
          </div>
          <div *ngIf="!!lifeContractPremium.annualPremium" class="tbld_row">
            <div data-qa="premium-annualPremium" class="tbld_col tbld_col_value life-detail-table-border life-detail-table-width-22" translate>lpc_AnnualPremium</div>
            <div data-qa="premium-annualPremium-caricamenti" class="tbld_col tbld_col_value life-detail-table-border life-detail-table-width-13">{{lifeContractPremium.annualPremium.loadings | rgiCtryLNumFormatter: '' : currencyFormatterOptions}}</div>
            <div data-qa="premium-annualPremium-premioNetto" class="tbld_col tbld_col_value life-detail-table-border life-detail-table-width-13">{{lifeContractPremium.annualPremium.netPremium | rgiCtryLNumFormatter: '' : currencyFormatterOptions}}</div>
            <div data-qa="premium-annualPremium-intFraz" class="tbld_col tbld_col_value life-detail-table-border life-detail-table-width-13">{{lifeContractPremium.annualPremium.splitInterests | rgiCtryLNumFormatter: '' : currencyFormatterOptions}}</div>
            <div data-qa="premium-annualPremium-diritti" class="tbld_col tbld_col_value life-detail-table-border life-detail-table-width-13">{{lifeContractPremium.annualPremium.adminFees | rgiCtryLNumFormatter: '' : currencyFormatterOptions}}</div>
            <div data-qa="premium-annualPremium-imposte" class="tbld_col tbld_col_value life-detail-table-border life-detail-table-width-13">{{lifeContractPremium.annualPremium.taxes | rgiCtryLNumFormatter: '' : currencyFormatterOptions}}</div>
            <div data-qa="premium-annualPremium-premioLordo" class="tbld_col tbld_col_value life-detail-table-border life-detail-table-width-13">{{lifeContractPremium.annualPremium.grossPremium | rgiCtryLNumFormatter: '' : currencyFormatterOptions}}</div>
          </div>
          <div *ngIf="!!lifeContractPremium.nextInstallment" class="tbld_row">
            <div data-qa="premium-nextInstallment" class="tbld_col tbld_col_value life-detail-table-border life-detail-table-width-22" translate>lpc_NextInstallment</div>
            <div data-qa="premium-nextInstallment-caricamenti" class="tbld_col tbld_col_value life-detail-table-border life-detail-table-width-13">{{lifeContractPremium.nextInstallment.loadings | rgiCtryLNumFormatter: '' : currencyFormatterOptions}}</div>
            <div data-qa="premium-nextInstallment-premioNetto" class="tbld_col tbld_col_value life-detail-table-border life-detail-table-width-13">{{lifeContractPremium.nextInstallment.netPremium | rgiCtryLNumFormatter: '' : currencyFormatterOptions}}</div>
            <div data-qa="premium-nextInstallment-intFraz" class="tbld_col tbld_col_value life-detail-table-border life-detail-table-width-13">{{lifeContractPremium.nextInstallment.splitInterests | rgiCtryLNumFormatter: '' : currencyFormatterOptions}}</div>
            <div data-qa="premium-nextInstallment-diritti" class="tbld_col tbld_col_value life-detail-table-border life-detail-table-width-13">{{lifeContractPremium.nextInstallment.adminFees | rgiCtryLNumFormatter: '' : currencyFormatterOptions}}</div>
            <div data-qa="premium-nextInstallment-imposte" class="tbld_col tbld_col_value life-detail-table-border life-detail-table-width-13">{{lifeContractPremium.nextInstallment.taxes | rgiCtryLNumFormatter: '' : currencyFormatterOptions}}</div>
            <div data-qa="premium-nextInstallment-premioLordo" class="tbld_col tbld_col_value life-detail-table-border life-detail-table-width-13">{{lifeContractPremium.nextInstallment.grossPremium | rgiCtryLNumFormatter: '' : currencyFormatterOptions}}</div>
          </div>
        </div>
      </div>
      <div class="col-md-12 life-detail-margin-box life-detail-box" id="box-loading" *ngIf="mobileLoaderActive && loader">
        <lpc-loader-mobile [loader]="loader"></lpc-loader-mobile>
      </div>
      <!-- FINE TABELLA PREMI -->

      <!-- TABELLA GARANZIE ATTIVE -->
      <div *ngIf="!loader && !!lifeContractWarranties && lifeContractWarranties.length > 0" class="col-md-12 life-detail-margin-box life-detail-box" id="consulting-detail" data-qa="active-guarantee-table">
        <div class="row life-detail-card-title-container">
          <div class="row life-detail-title">
            <div class="card-title">
              <span class="header-icon rgifont "></span>
              <span class="menu-title" translate>lpc_ActiveGuarantees</span>
            </div>
          </div>
        </div>
        <div class="tbld life-detail-margin-top">
          <div class="tbld_row life-detail-tbld_row_header">
            <div class="tbld_col tbld_col_title life-detail-table-width-34" translate>lpc_Guarantee</div>
            <div class="tbld_col tbld_col_title life-detail-table-width-33" translate>lpc_Benefit</div>
            <div class="tbld_col tbld_col_title life-detail-table-width-33" translate>lpc_Annual_SinglePremium</div>
            <div class="tbld_col tbld_col_title life-detail-table-width-33"></div>
          </div>
          <div *ngFor="let warranty of lifeContractWarranties" class="tbld_row">
            <div data-qa="garanzia-label" class="tbld_col tbld_col_value life-detail-table-border life-detail-table-width-34" translate>{{warranty.unitDescription}}</div>
            <div data-qa="garanzia-prestazione" class="tbld_col tbld_col_value life-detail-table-border life-detail-table-width-33">{{warranty.onDateBenefit| rgiCtryLNumFormatter: '' : currencyFormatterOptions}}</div>
            <div data-qa="garanzia-premio" class="tbld_col tbld_col_value life-detail-table-border life-detail-table-width-33">{{warranty.premium| rgiCtryLNumFormatter: '' : currencyFormatterOptions}}</div>
            <div data-qa="garanzia-freccia" class="tbld_col tbld_col_value life-detail-table-border life-detail-table-width-33">
              <button data-qa="open-warranty-button" class="btn dt-btn" (click)="openFundDetailSession(warranty)">
                <span class="rgifont rgi-chevron-right"></span>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-12 life-detail-margin-box life-detail-box" id="box-loading" *ngIf="mobileLoaderActive && loader">
        <lpc-loader-mobile [loader]="loader"></lpc-loader-mobile>
      </div>
      <!-- FINE TABELLA GARANZIE ATTIVE -->

      <!-- CONSULTAZIONE FONDI -->
      <div *ngIf="!!investments && !!investments.profiles && !!investments.profiles.length" class="col-md-12 life-detail-margin-box life-detail-box"
        data-qa="investment-funds-table" id="consulting-detail">
        <div class="row life-detail-card-title-container">
          <div class="row life-detail-title">
            <div class="card-title">
              <span class="header-icon rgifont "></span>
              <span class="menu-title" translate>lpc_InvestmentByFund</span>
            </div>
          </div>
        </div>
        <div class="tbld life-detail-margin-top">
          <div class="tbld_row life-detail-tbld_row_header">
            <div class="tbld_col tbld_col_title life-detail-table-dynamic-width" translate>lpc_category_fund</div>
            <div class="tbld_col tbld_col_title life-detail-table-dynamic-width" translate>lpc_equivalent_on_date</div>
            <div class="tbld_col tbld_col_title life-detail-table-dynamic-width" translate>lpc_data_value</div>
            <div class="tbld_col tbld_col_title life-detail-table-dynamic-width" translate>lpc_exchange_rate</div>
            <div class="tbld_col tbld_col_title life-detail-table-dynamic-width" translate>lpc_last_share_value</div>
            <div class="tbld_col tbld_col_title life-detail-table-dynamic-width" translate>lpc_quotes_number</div>
            <div class="tbld_col tbld_col_title life-detail-table-dynamic-width" translate>lpc_Percentage</div>
            <div class="tbld_col tbld_col_title life-detail-table-dynamic-width" style="width: 0%"></div>
          </div>
          <ng-container *ngFor="let profile of investments.profiles">
            <div class="tbld_row life-detail-investment-profile-row bolder">
              <div class="tbld_col tbld_col_value life-detail-table-border  life-detail-table-dynamic-width">{{profile.description}}</div>
              <div class="tbld_col tbld_col_value life-detail-table-border  life-detail-table-dynamic-width" [attr.data-qa]="profile.description + '-profileNetAmout'">
                {{getProfileGrossAmount(profile).toString() | rgiCtryLNumFormatter: '' : currencyFormatterOptions}}</div>
              <div class="tbld_col tbld_col_value life-detail-table-border  life-detail-table-dynamic-width"></div>
              <div class="tbld_col tbld_col_value life-detail-table-border  life-detail-table-dynamic-width"></div>
              <div class="tbld_col tbld_col_value life-detail-table-border  life-detail-table-dynamic-width"></div>
              <div class="tbld_col tbld_col_value life-detail-table-border  life-detail-table-dynamic-width"></div>
              <div class="tbld_col tbld_col_value life-detail-table-border  life-detail-table-dynamic-width"
                [attr.data-qa]="profile.description + '-profilePercentage'">
                {{profile.percent | rgiCtryLNumFormatter: '' : percentFormatterOptions}}</div>
              <div class="tbld_col tbld_col_value life-detail-table-border  life-detail-table-dynamic-width"></div>
            </div>
            <div *ngFor="let fund of profile.funds" class="tbld_row regular">
              <div class="tbld_col tbld_col_value life-detail-table-border  life-detail-table-dynamic-width">{{fund.description}}</div>
              <div [attr.data-qa]="fund.description + '-fundNetAmout'"
                   class="tbld_col tbld_col_value life-detail-table-border  life-detail-table-dynamic-width">{{getSymbol(investments.currency.symbol)}} {{ (getFundGrossAmount(fund) * fund.exchangeRate).toString() | rgiCtryLNumFormatter}}
                <br><span
                  *ngIf="investments.currency.id !== fund.currency.id">{{getSymbol(fund.currency.symbol)}} {{getFundGrossAmount(fund).toString() | rgiCtryLNumFormatter}}</span>
              </div>
              <div class="tbld_col tbld_col_value life-detail-table-border  life-detail-table-dynamic-width"
                [attr.data-qa]="fund.description + '-marketValueDate'">{{setDateVal(fund.marketValueDate)}}</div>
              <div *ngIf="investments.currency.id === fund.currency.id"
                class="tbld_col tbld_col_value life-detail-table-border  life-detail-table-dynamic-width">-
              </div>
              <div *ngIf="investments.currency.id !== fund.currency.id"
                class="tbld_col tbld_col_value life-detail-table-border  life-detail-table-dynamic-width">{{fund.exchangeRate| rgiCtryLNumFormatter}}</div>
              <div [attr.data-qa]="fund.description + '-marketValue'"
                class="tbld_col tbld_col_value life-detail-table-border  life-detail-table-dynamic-width">
                 <span *ngIf="fund.typeId && fund.typeId !== '4'">
                    {{getSymbol(fund.currency.symbol)}} {{fund.marketValue | rgiCtryLNumFormatter: '' : decimalNumberFormatterOptionsSix}}
                 </span>
              </div>
              <div class="tbld_col tbld_col_value life-detail-table-border  life-detail-table-dynamic-width"
                [attr.data-qa]="fund.description + '-netUnits'">
                <span *ngIf="!!fund.typeId && fund.typeId !== '4'">
                  {{fund.grossUnits | rgiCtryLNumFormatter: '' : quoteNumberFormatterOptions}}
                </span>
              </div>
              <div class="tbld_col tbld_col_value life-detail-table-border  life-detail-table-dynamic-width"
                [attr.data-qa]="fund.description + '-fundPercentage'">
                {{getTotalFund(fund, profile) | rgiCtryLNumFormatter: '' : percentFormatterOptions}}</div>
              <div class="tbld_col tbld_col_value life-detail-table-border  life-detail-table-dynamic-width" id="funds-action">
                <button class="btn dt-btn" data-qa="fund.description + '-fund-action'" (click)="openSession(fund)" >
                  <span class="rgifont rgi-chevron-right"></span>
                </button>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
      <div class="col-md-12 life-detail-margin-box life-detail-box" id="box-loading" *ngIf="mobileLoaderActive && loader">
        <lpc-loader-mobile [loader]="loader"></lpc-loader-mobile>
      </div>
      <!-- FINE CONSULTAIONE FONDI -->

      <!--INIZIO TABELLA MOVIMENTI-->
      <div *ngIf="!loader" class="col-md-12 life-detail-margin-box life-detail-box" data-qa="movements-table" id="consulting-detail">

        <div class="row life-detail-card-title-container">
          <div class="row life-detail-title">
            <div class="card-title">
              <span class="header-icon rgifont "></span>
              <span class="menu-title" translate>lpc_Movements</span>
            </div>
          </div>
        </div>

        <table rgi-rx-table [dataSource]="getValidMovements(movements)">
            <ng-container rgiRxColumnDef="Movements">
                <td rgi-rx-header-cell *rgiRxHeaderCellDef translate>lpc_Movements</td>
                <td rgi-rx-cell *rgiRxCellDef="let movement" [attr.data-qa]="'movement-description-'+ movement.description"> {{movement.description}} </td>
                <td rgi-rx-footer-cell *rgiRxFooterCellDef>Movements</td>
            </ng-container>
            <ng-container rgiRxColumnDef="Effective Date">
                <td rgi-rx-header-cell *rgiRxHeaderCellDef translate> lpc_EffectiveDate</td>
                <td rgi-rx-cell *rgiRxCellDef="let movement" [attr.data-qa]="movement.effectDate + '-movement-'+ movement.description"> {{movement.effectDate | dataValueFormatterPipe}} </td>
                <td rgi-rx-footer-cell *rgiRxFooterCellDef>Effective Date</td>
            </ng-container>
            <ng-container rgiRxColumnDef="Title Amount">
                <td rgi-rx-header-cell *rgiRxHeaderCellDef translate>lpc_mov_Amount</td>
                <td rgi-rx-cell *rgiRxCellDef="let movement" [attr.data-qa]="movement.netAmount + '-movement-'+ movement.description">{{movement?.netAmount?.toString(10) | rgiCtryLNumFormatter: '' :
                    currencyFormatterOptions}} </td>
                <td rgi-rx-footer-cell *rgiRxFooterCellDef>Title Amount</td>
            </ng-container>
            <ng-container rgiRxColumnDef="Appendix Number">
                <td rgi-rx-header-cell *rgiRxHeaderCellDef translate>lpc_AppendixNumber</td>
                <td rgi-rx-cell *rgiRxCellDef="let movement" [attr.data-qa]="movement.numApp + '-movementNumApp-'+ movement.description" style="text-align: center"> {{!!movement.numApp ? movement.numApp : ''}} </td>
                <td rgi-rx-footer-cell *rgiRxFooterCellDef>Appendix Number</td>
            </ng-container>
            <ng-container rgiRxColumnDef="Status Title">
                <td rgi-rx-header-cell *rgiRxHeaderCellDef translate>lpc_mov_status</td>
                <td rgi-rx-cell *rgiRxCellDef="let movement" style="text-align: center">
                    <lpc-status-handler [attr.data-qa]="movement.statusName + '-movement-'+ movement.description"
                    [statusCode]="movement.statusCodeId" [statusName]="movement.statusName"></lpc-status-handler>
                </td>
                <td rgi-rx-footer-cell *rgiRxFooterCellDef>Status Title</td>
            </ng-container>
            <ng-container rgiRxColumnDef=" ">
                <td rgi-rx-header-cell *rgiRxHeaderCellDef translate></td>
                <td *rgiRxCellDef="let movement" rgiRxGrid="6" style="text-align: center">
                    <button class="btn dt-btn" data-qa="historical-consultation-button"
                        (click)="openHistoricalConsultation(movement)" lpcTooltip="lpc_consultazione_storica"
                        placement="top" delay="200">
                        <span class="rgifont rgi-file-text"> </span>
                    </button>
                    <button class="btn dt-btn" data-qa="movement-detail-button"
                        (click)="openMovementDetailSession(movement)" lpcTooltip="lpc_dettaglio_movimento" placement="top"
                        delay="200">
                        <span class="rgifont rgi-chevron-right"></span>
                    </button>
                </td>
                <td rgi-rx-footer-cell *rgiRxFooterCellDef></td>
            </ng-container>

            <ng-container *ngIf="haveToShowAppendixNumber; else notShowAppendixNumber">
              <tr rgi-rx-header-row *rgiRxHeaderRowDef="lifeDetailMovementsColumns"></tr>
              <tr rgi-rx-row *rgiRxRowDef="let movement; columns: lifeDetailMovementsColumns;"></tr>
            </ng-container>
            <ng-template #notShowAppendixNumber>
              <tr rgi-rx-header-row *rgiRxHeaderRowDef="lifeDetailMovementsColumnsWithoutNumApp"></tr>
              <tr rgi-rx-row *rgiRxRowDef="let movement; columns: lifeDetailMovementsColumnsWithoutNumApp;"></tr>
            </ng-template>
        </table>
      </div>
      <div class="col-md-12 life-detail-margin-box life-detail-box" id="box-loading" *ngIf="mobileLoaderActive && loader">
        <lpc-loader-mobile [loader]="loader"></lpc-loader-mobile>
      </div>
      <!-- FINE TABELLA MOVIMENTI-->

      <!--INIZIO TABELLA PRENOTAZIONI-->
      <div *ngIf="!loader && !!bookings && !!bookings.length" class="col-md-12 life-detail-margin-box life-detail-box" data-qa="booking-table" id="consulting-detail">
        <div class="row life-detail-card-title-container">
          <div class="row life-detail-title">
            <div class="card-title">
              <span class="header-icon rgifont"></span>
              <span class="menu-title" translate>lpc_bookings</span>
            </div>
          </div>
        </div>
        <div class="tbld life-detail-margin-top">
          <div class="tbld_row life-detail-tbld_row_header">
            <div class="tbld_col tbld_col_title" translate>lpc_booking_type</div>
            <div class="tbld_col tbld_col_title" translate>lpc_booking_state</div>
            <div class="tbld_col tbld_col_title" translate>lpc_booking_effective_date</div>
            <div class="tbld_col tbld_col_title" ></div>
          </div>
          <div *ngFor="let booking of bookings; index as i" class="tbld_row">
            <div [attr.data-qa]="'booking-description-'+ i" class="tbld_col tbld_col_value life-detail-table-border" translate>{{booking.reservationType}}</div>
            <div [attr.data-qa]="'booking-state-' + i" class="tbld_col tbld_col_value life-detail-table-border">{{booking.status}}</div>
            <div [attr.data-qa]="'booking-date-' + i" class="tbld_col tbld_col_value life-detail-table-border">{{booking.date}}</div>
            <div class="tbld_col tbld_col_value life-detail-table-border center">
              <button *ngIf="booking.showCancellation" class="btn dt-btn" (click)="cancelReservation(booking)" lpcTooltip="lpc_cancel_reservation" placement="top" delay="200">
                <span class="rgifont rgi-trash"></span>
              </button>
              <button class="btn dt-btn" (click)="openReservationDetail(booking.reservationId)" lpcTooltip="lpc_Visualizza_dettaglio" placement="top" delay="200">
                <span class="rgifont rgi-chevron-right"></span>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-12 life-detail-margin-box life-detail-box" id="box-loading" *ngIf="mobileLoaderActive && loader">
        <lpc-loader-mobile [loader]="loader"></lpc-loader-mobile>
      </div>
      <!-- FINE TABELLA PRENOTAZIONI-->

    </div>
    <!-- FINE PRIMA COLONNA COL-8 -->

    <!-- SECONDA COLONNA COL-4 -->
    <div class="col-sm-12 col-lg-4">

      <!-- DATI DI POLIZZA -->
      <div *ngIf="!loader" class="col-md-12 life-detail-margin-box life-detail-box" id="consulting-detail" data-qa="policy-data-table">

        <div class="row life-detail-card-title-container">
          <div class="row life-detail-title">
            <div class="card-title">
              <span class="header-icon rgifont "></span>
              <span class="menu-title" translate>lpc_PolicyData</span>
            </div>
            <div class="hidden-xs hidden-sm">
              <span
                class="rgifont pull-right maximize-session-card  hidden-sm hidden-xs ng-hide rgi-arrows-alt card-header-single-icon"></span>
              <span class="rgifont rgi-times-circle pull-right close-session-card" [attr.data-qa]="'remove-session-card'" (click)="removeSession()"></span>
            </div>
          </div>
        </div>
        <div class="tbld life-detail-margin-top" data-qa="policy-data-table">
          <div class="tbld_row life-detail-tbld_row_header">
            <div class="tbld_col tbld_col_title" translate>lpc_PolicyData</div>
            <div class="tbld_col tbld_col_title"></div>
          </div>
          <div *ngIf="!!lifeContract.policyData?.issuePropDate" class="tbld_row">
            <div class="tbld_col tbld_col_title life-detail-table-border" translate>lpc_ProposalIssueDate</div>
            <div class="tbld_col tbld_col_value life-detail-table-border" data-qa="issuePropDate">{{lifeContract.policyData.issuePropDate}}
            </div>
          </div>
          <div *ngIf="!!lifeContract.policyData?.issuePolDate" class="tbld_row">
            <div class="tbld_col tbld_col_title life-detail-table-border" translate>lpc_PolicyIssueDate</div>
            <div class="tbld_col tbld_col_value life-detail-table-border" data-qa="issuePolDate">{{lifeContract.policyData.issuePolDate}}</div>
          </div>
          <div *ngIf="!!durationLabel() && !!durationValue()" class="tbld_row">
            <div class="tbld_col tbld_col_title life-detail-table-border" translate>{{durationLabel()}}</div>
            <div class="tbld_col tbld_col_value life-detail-table-border" data-qa="duration">{{durationValue()}}</div>
          </div>
          <div class="tbld_row">
            <div class="tbld_col tbld_col_title life-detail-table-border" translate>lpc_ExpirationDate</div>
            <div *ngIf="!!lifeContract.policyData.expDate"
              class="tbld_col tbld_col_value life-detail-table-border" data-qa="expDate">{{lifeContract.policyData.expDate}}</div>
            <div *ngIf="lifeContract.policyData.expDate === null"
              class="tbld_col tbld_col_value life-detail-table-border"> - </div>
          </div>
          <div *ngIf="!!lifeContract.policyData.nextInstallDate" class="tbld_row">
            <div class="tbld_col tbld_col_title life-detail-table-border" translate>lpc_NextInstalmentDate</div>
            <div class="tbld_col tbld_col_value life-detail-table-border" data-qa="nextInstallDate">{{lifeContract.policyData.nextInstallDate}}
            </div>
          </div>
          <!--<div class="tbld_row">
            <div class="tbld_col tbld_col_title" translate>Totale incassi</div>
            <div class="tbld_col tbld_col_value">12.358</div>
          </div>
          <div class="tbld_row">
            <div class="tbld_col tbld_col_title" translate>Totale arretrati</div>
            <div class="tbld_col tbld_col_value">1.985</div>
          </div>-->
        </div>
      </div>
      <div class="col-md-12 life-detail-margin-box life-detail-box" id="box-loading" *ngIf="mobileLoaderActive && loader">
        <lpc-loader-mobile [loader]="loader"></lpc-loader-mobile>
      </div>
      <!-- FINE DATI DI POLIZZA -->

      <!-- POLIZZE COLLEGATE -->
      <div *ngIf="!loader && lifeContract.relatedPoliciesData.relatedPolicies.length > 0" class="col-md-12 life-detail-margin-box life-detail-box" id="consulting-detail" data-qa="linked-policy-table">

        <div class="row life-detail-card-title-container">
          <div class="row life-detail-title">
            <div class="card-title">
              <span class="header-icon rgifont "></span>
              <span class="menu-title" translate>lpc_PolicyLinked</span>
              <span class="ng-hide"> </span> <span class="ng-hide"></span>
            </div>
          </div>
        </div>
        <div class="tbld life-detail-margin-top">
          <div class="tbld_row life-detail-tbld_row_header">
            <div class="tbld_col tbld_col_title" translate>lpc_PolicyLinked</div>
            <div class="tbld_col tbld_col_title" translate>lpc_Motivo_Legame</div>
          </div>
          <div *ngFor="let relatedPolicy of lifeContract.relatedPoliciesData.relatedPolicies" class="tbld_row">
            <div data-qa="policyNumber" class="tbld_col tbld_col_value life-detail-table-border">{{relatedPolicy.policyNumber}}</div>
            <div data-qa="bond" class="tbld_col tbld_col_value life-detail-table-border">{{relatedPolicy.bond}}</div>
          </div>
        </div>
      </div>
      <div class="col-md-12 life-detail-margin-box life-detail-box" id="box-loading" *ngIf="mobileLoaderActive && loader">
        <lpc-loader-mobile [loader]="loader"></lpc-loader-mobile>
      </div>
      <!-- FINE POLIZZE COLLEGATE -->

      <!-- SOGGETTI -->
      <div *ngIf="!loader && !!getHolders()?.length" class="col-md-12 life-detail-margin-box life-detail-box" id="consulting-detail" data-qa="subjects-table">

        <div class="row life-detail-card-title-container">
          <div class="row life-detail-title">
            <div class="card-title">
              <span class="header-icon rgifont "></span>
              <span class="menu-title" translate>lpc_subject_roles</span>
            </div>
          </div>
        </div>

        <div class="tbld life-detail-margin-top" data-qa="holder-section">
          <div class="tbld_row life-detail-tbld_row_header">
            <div class="tbld_col tbld_col_title" translate>lpc_holders</div>
            <div class="tbld_col tbld_col_title"></div>
          </div>
          <!-- <div *ngFor="let subject of getHolders(); let i = index" class="tbld_row {{i % 2 === 0 ? 'tbld_row_even' : 'tbld_row_odd'}}" >
            <div class="tbld_col tbld_col_title" translate>{{subject.roleDescription}}</div>
            <div class="tbld_col tbld_col_value">{{subject.denomination}}</div>
          </div> -->
          <div *ngFor="let subject of getHolders();" class="tbld_row life-detail-subject-box">
            <div class="tbld_col tbld_col_value life-detail-subject-margin" translate>{{subject.roleDescription}}</div>
            <div class="tbld_col tbld_col_value life-detail-subject-margin">
              <a class="life-detail-a-benef" (click)="openSubjDetail(subject.id)">{{subject.denomination}}</a>
              <span *ngIf="!!subject.percBenef && +subject.percBenef > 0">&nbsp;{{+subject.percBenef / 100 | rgiCtryLNumFormatter: '' : percentFormatterOptions}}</span>
            </div>
          </div>
        </div>
        <div class="tbld" data-qa="benefeiciary-table">
          <div *ngIf="getBeneficiaries(null).length > 0" class="tbld_row life-detail-tbld_row_header">
            <div class="tbld_col tbld_col_title" translate>lpc_life_detail_beneficiaries</div>
            <div class="tbld_col tbld_col_title"></div>
            <div class="tbld_col tbld_col_title"></div>
          </div>
          <div *ngFor="let benef of benediciaries; index as i" class="tbld_row life-detail-subject-box">
            <div class="tbld_col tbld_col_value life-detail-subject-margin" [attr.data-qa]="'benef-type'" >{{benef.typeBenef}}</div>
            <div *ngIf="!benef.id" class="tbld_col tbld_col_value life-detail-subject-margin" [attr.data-qa]="'benef-label'" >{{benef.label}}</div>
            <div *ngIf="benef.id" class="tbld_col tbld_col_value life-detail-subject-margin">
              <span *ngIf="!!benef.positionNumberDescr" [attr.data-qa]="'benef-positionNumberDescr'">{{benef.positionNumberDescr}}&#58;&nbsp;</span>
              <a class="life-detail-a-benef" (click)="openSubjDetail(benef.id)" [attr.data-qa]="'benef-denomination'">{{benef.denomination}}</a>
              <span *ngIf="!!benef.percentage && +benef.percentage > 0" [attr.data-qa]="'benef-percentage'">&nbsp;{{benef.percentage / 100 | rgiCtryLNumFormatter: '' : percentFormatterOptions}}</span>
              <span *ngFor="let lbLinkSubj of benef.relatedSubject; let i = index">
                <span *ngIf="checkMultipleRelatedSubject(benef.relatedSubject, i, 'first')" [attr.data-qa]="'benef-related-sub'"> (</span>
                <a class="life-detail-a-benef" (click)="openSubjDetail(lbLinkSubj.id)" [attr.data-qa]="'benef-related-sub-label'">{{lbLinkSubj.label}}</a>
                <span *ngIf="checkMultipleRelatedSubject(benef.relatedSubject, i, 'middle')">, </span>
                <span *ngIf="checkMultipleRelatedSubject(benef.relatedSubject, i, 'last')">)</span>
              </span>
            </div>
            <div class="tbld_col tbld_col_value life-detail-subject-margin" id="payments-benef">
              <span *ngIf="!!benef.expiryCommunication" [attr.data-qa]="'expiryCommunication-'+ benef.denomination.trim() +'-'+ benef.typeBenef" class="rgi-envelope" lpcTooltip="lpc_reg41Msg" placement="top" delay="200"></span>
              <span *ngIf="!!benef.severeDisability" [attr.data-qa]="'severeDisability-'+ benef.denomination.trim() +'-'+ benef.typeBenef" class="rgi-mani" lpcTooltip="lpc_severeDisabilityMsg" placement="top" delay="200"></span>
              <ng-container *ngIf="!!benef.payments">
                <span class="rgi-info payments" [lpcTooltip] [stringOBJ]="benef.payments" [customId]="'payment-box'" placement="top" delay="200"></span>
              </ng-container>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-12 life-detail-margin-box life-detail-box" id="box-loading" *ngIf="mobileLoaderActive && loader">
        <lpc-loader-mobile [loader]="loader"></lpc-loader-mobile>
      </div>
      <!-- FINE SOGGETTI -->


      <!-- CALUSOLE -->
      <div *ngIf="!loader && !!lifeContract.clausesType?.length" class="col-md-12 life-detail-margin-box life-detail-box" id="consulting-detail" data-qa="clauses-table">

        <div class="row life-detail-card-title-container">
          <div class="row life-detail-title">
            <div class="card-title">
              <span class="header-icon rgifont "></span>
              <span class="menu-title" translate>lpc_clauses</span>
            </div>
          </div>
        </div>

        <rgi-rx-expansion-panel color="default" *ngFor="let item of lifeContract.clausesType">
          <div rgi-rx-expansion-panel-header>
            <div class="row">
              <span>{{item.descClauseType}}</span>
            </div>
          </div>
          <ng-container rgiRxExpansionPanelContent >
            <div *ngFor="let clause of item.clause;" class="tbld_row life-detail-subject-box">
              <div class="tbld_col tbld_col_value life-detail-subject-margin">
                {{clause.description}}
              </div>
              <div class="tbld_col tbld_col_value life-detail-subject-margin">
                {{clause.text}}
              </div>
            </div>
          </ng-container>
        </rgi-rx-expansion-panel>


      </div>
      <div class="col-md-12 life-detail-margin-box life-detail-box" id="box-loading" *ngIf="mobileLoaderActive && loader">
        <lpc-loader-mobile [loader]="loader"></lpc-loader-mobile>
      </div>
      <!-- FINE CLAUSOLE -->




      <!-- CONSULTAZIONE FATTORI -->
      <div *ngIf="!loader && !!lifeContract.factorsForConsultation.policyFactors && !!lifeContract.factorsForConsultation.policyFactors.length || checkAssetsFactors"
            class="col-md-12 life-detail-margin-box life-detail-box" id="consulting-detail" data-qa="consulting-factors-table">

        <div class="row life-detail-card-title-container">
          <div class="row life-detail-title">
            <div class="card-title">
              <span class="header-icon rgifont "></span>
              <span class="menu-title" translate>lpc_Dati</span>
              <span class="ng-hide"> </span> <span class="ng-hide"></span>
            </div>
          </div>
        </div>
        <div class="tbld life-detail-margin-top" *ngIf="!!lifeContract.factorsForConsultation.policyFactors && !!lifeContract.factorsForConsultation.policyFactors.length">
          <div class="tbld_row life-detail-tbld_row_header" >
            <div class="tbld_col tbld_col_title" translate>lpc_PolicyData</div>
            <div class="tbld_col tbld_col_title"></div>
          </div>
          <div *ngFor="let factorPolicy of lifeContract.factorsForConsultation.policyFactors" class="tbld_row life-detail-subject-box">
            <div data-qa="factor-description" class="tbld_col tbld_col_value life-detail-subject-margin">{{factorPolicy.description}}</div>
            <div data-qa="factor-value" class="tbld_col tbld_col_value life-detail-subject-margin">{{factorPolicy.value}}</div>
          </div>
        </div>
        <ng-container *ngFor="let obj of lifeContract.factorsForConsultation.assetsFactors">

          <div class="tbld life-detail-margin-top" *ngIf="!!obj.assetFactors && !!obj.assetFactors.length">
            <div class="tbld_row life-detail-tbld_row_header">
              <div class="tbld_col tbld_col_title" translate>lpc_HolderData</div>
              <div class="tbld_col tbld_col_title"></div>
            </div>
            <div *ngFor="let asset of obj.assetFactors" class="tbld_row life-detail-subject-box">
              <div data-qa="asset-description" class="tbld_col tbld_col_value life-detail-subject-margin">{{asset.description}}</div>
              <div data-qa="asset-value" class="tbld_col tbld_col_value life-detail-subject-margin" translate>{{asset.value}}</div>
            </div>
          </div>

          <div class="tbld life-detail-margin-top" *ngIf="!!obj.risksFactors && showRisk">
            <div class="tbld_row life-detail-tbld_row_header">
              <div class="tbld_col tbld_col_title" translate>lpc_RiskData</div>
              <div class="tbld_col tbld_col_title"></div>
            </div>
            <ng-container *ngFor="let objRisks of obj.risksFactors">
              <div class="tbld_row bolder" *ngIf="!!objRisks.riskFactors.length ">
                <div data-qa="risk-parent-description" class="tbld_col tbld_col_value life-detail-subject-margin">{{objRisks.riskDescription}}</div>
                <div class="tbld_col tbld_col_value life-detail-subject-margin"></div>
              </div>

              <div *ngFor="let risk of objRisks.riskFactors" class="tbld_row life-detail-subject-box">
                <div data-qa="risk-description" class="tbld_col tbld_col_value life-detail-subject-margin">{{risk.description}}</div>
                <div data-qa="risk-value" class="tbld_col tbld_col_value life-detail-subject-margin">{{risk.value}}</div>
              </div>
            </ng-container>
          </div>
      </ng-container>
      </div>
      <div class="col-md-12 life-detail-margin-box life-detail-box" id="box-loading" *ngIf="mobileLoaderActive && loader">
        <lpc-loader-mobile [loader]="loader"></lpc-loader-mobile>
      </div>
      <!-- FINE CONSULTAZIONE FATTORI -->

      <!-- DATI DI PIP-->
      <lpc-life-pip-detail-mc *ngIf="!loader && showPIPData()" [pipDataConfig]="pipConfig" (pipDetailSessionEvent)="pipDetailSession($event)"></lpc-life-pip-detail-mc>
      <!-- FINE DATI DI PIP-->

      <!-- VALORIZZAZIONE -->
      <div *ngIf="!!investments && !loader" class="col-md-12 life-detail-margin-box life-detail-box" id="consulting-detail" data-qa="enhancement-table">
        <div class="row life-detail-card-title-container">
          <div class="row life-detail-title">
            <div class="card-title">
              <span class="header-icon rgifont "></span>
              <span class="menu-title" translate>lpc_Enhancement</span>
            </div>
            <div style="margin-top: 7px;">
                <button *ngIf="!lifeContract.generalData.policyReversed && lifeContractInvestimentWarranties && lifeContractInvestimentWarranties.length > 0" class="btn dt-btn pull-right" (click)="openTrendSession(storedPolicyNumber)"  data-qa="open-trend-button" >
                  <span class="rgifont rgi-chevron-right"></span>
                </button>
              </div>
          </div>
        </div>
        <div class="container-fluid anag-data">
          <div *ngIf="investments.effect" class="col-md-12">
            <div class="col-sm-6">
              <span class="tbld_col_title" translate>lpc_CalculationData</span><span class="tbld_col_title">: </span>
            </div>
            <div class="col-sm-6">
              <span [attr.data-qa]="'investment-effect-date'" class="tbld_col_value">{{setDateVal(investments.effect)}}</span>
            </div>
          </div>
          <div *ngIf="investments.grossAmount" class="col-md-12">
            <div class="col-sm-6">
              <span class="tbld_col_title" translate>lpc_ControvaloreAllaData</span><span class="tbld_col_title">: </span>
            </div>
            <div class="col-sm-6">
              <span [attr.data-qa]="'investment-gross-amount'"  class="tbld_col_value">{{getInvestmentAmount().toString() | rgiCtryLNumFormatter: '' : currencyFormatterOptions}}</span>
            </div>
          </div>
          <div *ngIf="investments.paidAmount" class="col-md-12">
            <div class="col-sm-6">
              <span class="tbld_col_title" translate>lpc_AccumulationOfPrizes</span><span class="tbld_col_title">: </span>
            </div>
            <div class="col-sm-6">
              <span [attr.data-qa]="'investment-paid-amount'"  class="tbld_col_value">{{investments.paidAmount | rgiCtryLNumFormatter: '' : currencyFormatterOptions}}</span>
            </div>
          </div>
          <div *ngIf="investments.totalGrossSurrender" class="col-md-12">
            <div class="col-sm-6">
              <span class="tbld_col_title" translate>lpc_TotalSurrender</span><span class="tbld_col_title">: </span>
            </div>
            <div class="col-sm-6">
              <span [attr.data-qa]="'investment-total-gross-surrender'" class="tbld_col_value">{{investments.totalGrossSurrender | rgiCtryLNumFormatter: '' : currencyFormatterOptions}}</span>
            </div>
          </div>
          <div *ngIf="investments.leftPaidAmount" class="col-md-12">
            <div class="col-sm-6">
              <span class="tbld_col_title" translate>lpc_AccumulationOfReducedPremiums</span><span class="tbld_col_title">: </span>
            </div>
            <div class="col-sm-6">
              <span [attr.data-qa]="'investment-left-paid-amount'" class="tbld_col_value">{{investments.leftPaidAmount  | rgiCtryLNumFormatter: '' : currencyFormatterOptions}}</span>
            </div>
          </div>
          <div *ngIf="investments.grossAmount && investments.leftPaidAmount" class="col-md-12">
            <div class="col-sm-6">
              <span class="tbld_col_title" translate>lpc_TotalBalance</span><span class="tbld_col_title">: </span>
            </div>
            <div class="col-sm-6">
              <span [attr.data-qa]="'investment-total-balance'" class="tbld_col_value">{{getTotalInvestments() | rgiCtryLNumFormatter: '' : currencyFormatterOptions}}</span>
            </div>
          </div>
        </div>
        <div class="container-fluid">
          <span translate>lpc_ongoing_operations</span>
        </div>
      </div>
      <div class="col-md-12 life-detail-margin-box life-detail-box" id="box-loading" *ngIf="mobileLoaderActive && loader">
        <lpc-loader-mobile [loader]="loader"></lpc-loader-mobile>
      </div>
      <!-- FINE VALORIZZAZIONE -->

      <!-- OPZIONI CONTRATTUALI ATTIVE -->
      <div *ngIf="isValid() && !!options && options.length > 0 && !loader" class="col-md-12 life-detail-margin-box life-detail-box" id="consulting-detail" data-qa="contractual-option-table">
          <div class="row life-detail-card-title-container">
              <div class="row life-detail-title">
                <div class="card-title">
                  <span class="header-icon rgifont "></span>
                  <span class="menu-title" translate>lpc_active_contractual_option</span>
                </div>
              </div>
            </div>
            <div class="tbld life-detail-margin-top">
              <ng-container *ngFor="let option of options">
                <div class="tbld_row life-detail-tbld_row_header">
                <div class="tbld_col tbld_col_title" data-qa="tool-name" translate>{{option.toolName}}</div>
                  <div class="tbld_col tbld_col_title">
                    <button class="btn dt-btn pull-right" [attr.data-qa]="'open-'+option.toolName.trim()+'-button'"  (click)="openOptionDetail(option)">
                      <span class="rgifont rgi-chevron-right"></span>
                    </button>
                  </div>
                </div>
            </ng-container>
            </div>
      </div>

      <div *ngIf="lifeContract.policyData.transformMotive === '4'" class="col-md-12 life-detail-margin-box life-detail-box" id="consulting-detail" data-qa="reinvested-settlements-table">
        <div class="row life-detail-card-title-container">
          <div class="row life-detail-title">
            <div class="card-title">
              <span class="header-icon rgifont "></span>
              <span class="menu-title" translate>lpc_reinvested_settlements</span>
            </div>
            <div style="margin-top: 7px;">
              <button (click)="openReinvestmentDataDetail()" data-qa="reinvestment-data-detail-button" class="btn dt-btn pull-right" >
                <span class="rgifont rgi-chevron-right"></span>
              </button>
            </div>
          </div>
        </div>
      </div>

      <!-- ELENCO PRESTITI -->
      <div *ngIf="!!lifeContract?.loanData?.length" class="col-md-12 life-detail-margin-box life-detail-box" id="consulting-detail">
        <div class="row life-detail-card-title-container">
          <div class="row life-detail-title">
            <div class="card-title">
              <span class="header-icon rgifont "></span>
              <span class="menu-title" translate>lpc_loans</span>
            </div>
            <div style="margin-top: 7px;">
              <button (click)="openLoanDataDetail()" data-qa="open-loan-detail-button" class="btn dt-btn pull-right" >
                <span class="rgifont rgi-chevron-right"></span>
              </button>
            </div>
          </div>
        </div>
      </div>
      <!-- FINE ELENCO PRESTITI -->

      <div *ngIf="showFiscalData()" class="col-md-12 life-detail-margin-box life-detail-box" id="consulting-detail" data-qa="fiscal-data-detail-table">

        <div class="row life-detail-card-title-container">
          <div class="row life-detail-title">
            <div class="card-title">
              <span class="header-icon rgifont "></span>
              <span class="menu-title" translate>lpc_fiscal_data</span>
            </div>
          </div>
        </div>

        <div class="tlbd">
          <div class="tlbd_row life-detail-subject-box" *ngIf="fiscalData.substituteTaxData && fiscalData.substituteTaxData.hasSubstituteTax">
            <div class="tbld_col tbld_col_title life-detail-table-width-5"><span translate>lpc_imposta_sostitutiva</span>
              <button data-qa="substituteTaxData-button" (click)="openFiscalDataDetail({'substituteTaxData': fiscalData.substituteTaxData})" class="btn dt-btn pull-right" >
                <span class="rgifont rgi-chevron-right"></span>
              </button>
            </div>
          </div>
          <div class="tlbd_row life-detail-subject-box" *ngIf="fiscalData.substituteTaxData && fiscalData.substituteTaxData.hasContractualNovation">
            <div class="tbld_col tbld_col_title life-detail-table-width-5"><span translate>lpc_novazione_contr</span>
              <button data-qa="contractualNovationData-button" (click)="openFiscalDataDetail({'contractualNovationData': fiscalData.substituteTaxData})" class="btn dt-btn pull-right" >
                <span class="rgifont rgi-chevron-right"></span>
              </button>
            </div>
          </div>
          <div class="tlbd_row life-detail-subject-box" *ngIf="fiscalData.stampDutyData">
            <div class="tbld_col tbld_col_title life-detail-table-width-5"><span translate>lpc_imposta_bollo</span>
              <button data-qa="stampDutyData-button" (click)="openFiscalDataDetail({'stampDutyData': fiscalData.stampDutyData})" class="btn dt-btn pull-right" >
                <span class="rgifont rgi-chevron-right"></span>
              </button>
            </div>
          </div>
          <div class="tlbd_row life-detail-subject-box" *ngIf="fiscalData.whiteListData">
            <div class="tbld_col tbld_col_title life-detail-table-width-5"><span translate>lpc_quote_white_list</span>
              <button data-qa="whiteListData-button" (click)="openFiscalDataDetail({'whiteListData': fiscalData.whiteListData})" class="btn dt-btn pull-right" >
                <span class="rgifont rgi-chevron-right"></span>
              </button>
            </div>
          </div>
        </div>

      </div>

      <div class="col-md-12 life-detail-margin-box life-detail-box" id="box-loading" *ngIf="isValid() && (mobileLoaderActive && loader)">
        <lpc-loader-mobile [loader]="loader"></lpc-loader-mobile>
      </div>
      <!-- FINE OPZIONI CONTRATTUALI ATTIVE -->

      <!-- Scelta Opzione a Scadenza -->
      <div *ngIf="!this.loader && expiryOption && expiryOption.risksExpiryOption && expiryOption.risksExpiryOption.length > 0" class="col-md-12 life-detail-margin-box life-detail-box" id="consulting-detail">

        <div class="row life-detail-card-title-container">
          <div class="row life-detail-title">
            <div class="card-title">
              <span class="header-icon rgifont "></span>
              <span class="menu-title" translate>lpc_expiry_option</span>
            </div>
            <div style="margin-top: 7px;">
              <button data-qa="expiry-option-detail-button" (click)="openExpiryOptionDetail()" class="btn dt-btn pull-right" >
                <span class="rgifont rgi-chevron-right"></span>
              </button>
            </div>
          </div>
        </div>

      </div>

      <div class="col-md-12 life-detail-margin-box life-detail-box" id="box-loading" *ngIf="mobileLoaderActive && loader">
        <lpc-loader-mobile [loader]="loader"></lpc-loader-mobile>
      </div>
       <!-- Fine Scelta Opzione a Scadenza -->

      <!-- START Piano di investimenti -->
      <div *ngIf="!loader && investmentPlan && investmentPlan.length > 0"
           class="col-md-12 life-detail-margin-box life-detail-box" id="consulting-detail">

        <div class="row life-detail-card-title-container">
          <div class="row life-detail-title">
            <div class="card-title">
              <span class="header-icon rgifont"></span>
              <span class="menu-title" translate>lpc_investment_plan</span>
            </div>
            <div style="margin-top: 7px;">
              <button data-qa="investment-plan-button" class="btn dt-btn pull-right" (click)="openInvestmentPlan()">
                <span class="rgifont rgi-chevron-right"></span>
              </button>
            </div>
          </div>
        </div>

      </div>

      <div class="col-md-12 life-detail-margin-box life-detail-box" id="box-loading" *ngIf="mobileLoaderActive && loader">
        <lpc-loader-mobile [loader]="loader"></lpc-loader-mobile>
      </div>
      <!-- END Piano di investimenti -->

    </div>
    <!-- FINE SECONDA COLONNA COL-4 -->
  </div>
</div>
