import { Component, ViewEncapsulation, Input, ViewChild, ElementRef, OnDestroy, AfterViewInit } from '@angular/core';
import { LccMessagesComponent } from './rgi-lcc-messages.component';

@Component({
  selector: 'rgi-lcc-messages-floating',
  styleUrls: ['rgi-lcc-messages.component.css'],
  templateUrl: 'rgi-lcc-messages-floating.component.html',
  encapsulation: ViewEncapsulation.None
})
export class LccMessagesFloatingComponent extends LccMessagesComponent implements AfterViewInit, OnDestroy {

  // se non specificato prende la finestra intera
  @Input() containerId: string | undefined; // => lo posiziona in basso a dx e gestisce lo scroll

  @ViewChild('errorsElement', { static: false })
  errorsElement: ElementRef<HTMLDivElement> | undefined;

  showFloatingMessages = false;
  enableWarningIcon = true;
  container: any;

  handleScroll() {
    if (this.isInView()) {
      this.showFloatingMessages = false;
      this.enableWarningIcon = false;
    } else {
      this.enableWarningIcon = true;
    }
  }
  ngAfterViewInit() {
    if (this.containerId != null) {
      this.container = document.getElementById(this.containerId);
      this.container.addEventListener('scroll', () => {this.handleScroll()});
    } else {
      window.addEventListener('scroll',  () => {this.handleScroll()});
    }
  }
  
  onWarningIconClick() {
    this.showFloatingMessages = true;
  }

  onCloseErrorsClick() {
    this.showFloatingMessages = false;
  }

  // verifico se il componente dei messaggi in basso è presente in view
  isInView(): boolean {

    const rect = this.errorsElement  &&  this.errorsElement.nativeElement.getBoundingClientRect();
    return (
        !!rect &&
        rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
        rect.right <= (window.innerWidth || document.documentElement.clientWidth)
    );
  }

  ngOnDestroy() {
    if (this.containerId != null) {
      this.container.nativeElement.removeEventListener('scroll', () => {this.handleScroll()});
    } else {
      window.removeEventListener('scroll', () => {this.handleScroll()});
    }
  }
}
