import {NgModule} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { LccMessagesComponent } from './rgi-lcc-messages.component';
import { LccMessagesFloatingComponent } from './rgi-lcc-messages-floating.component';

@NgModule({
    declarations: [LccMessagesComponent, LccMessagesFloatingComponent],
    imports: [BrowserModule],
    providers: [],
    exports: [LccMessagesComponent, LccMessagesFloatingComponent]
})

export class LccCommonComponentModule {}
