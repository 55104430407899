import {
  Component, Inject, OnDestroy, OnInit, Optional, ViewEncapsulation
} from '@angular/core';
import {
  LifeDetailComponent,
  Movement,
  JS_EVENT,
  PipDataServiceService,
  AnagService,
  OperationsListComponent,
  ProposalDetailService,
  LpcCurrencyCache,
  CurrencyCacheService,
  PV_TOKEN,
  CustomModalService,
  LpcLayeredRuleService,
  LifeContractMessage,
  TranslationWrapperService,
  CustomPropertyService
} from '@rgi/life-postsales-card';
import { RgiCtryLayerNumFormatterPipe } from '@rgi/rgi-country-layer';
import { JS_EVENT_EXT } from '../models/consts/life-postsales-card-ext-consts';
import { Subscription } from 'rxjs';
import {OtpService} from '../../services/otp-service.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { HttpClient } from '@angular/common/http';
import {OTPDocument} from '../../models/otp-document.model';
import { RgiRxTranslationService } from '@rgi/rx/i18n';
import { NotifierService } from '@rgi/portal-ng-core';
import { ModalService } from '@rgi/rx/ui';
import { RgiRxEventService } from '@rgi/rx';



@Component({
  selector: 'iiab-lpc-life-detail-ext',
  templateUrl: './life-detail-ext.component.html',
  styleUrls: ['./life-detail-ext.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [RgiCtryLayerNumFormatterPipe]
})
export class LifeDetailExtComponent extends LifeDetailComponent implements OnInit, OnDestroy {

  private $subscriptionsList: Subscription[] = [];
  otpMessages: LifeContractMessage[] = [];

  get errors(): LifeContractMessage[] {
    return this.lifeContract.messages.concat(this.otpMessages);
  }

  showOtpDocuments = true;

  constructor(
    protected modalService: NgbModal,
    protected httpClient: HttpClient,
    protected pipDataServiceService: PipDataServiceService,
    @Inject(PV_TOKEN.ENV) protected environment: any,
    @Inject(PV_TOKEN.CORE_AUTH_SERVICE) protected authService: any,
    @Inject(PV_TOKEN.CORE_INJECTOR) protected injector: any,
    protected translate: TranslationWrapperService,
    protected notifierService: NotifierService,
    @Inject(PV_TOKEN.PV_GRAPHIC_FIX) protected plcGraphicFix,
    protected rgiNumberFormatter: RgiCtryLayerNumFormatterPipe,
    protected anagService: AnagService,
    protected proposalDetailService: ProposalDetailService,
    protected customModalService: CustomModalService,
    @Inject(PV_TOKEN.OPERATION_LIST_COMPONENT) protected operationsListComponent: OperationsListComponent,
    @Inject(PV_TOKEN.SHOW_LOADER_MOBILE) protected showMobileLoader: any,
    @Optional() @Inject(LpcCurrencyCache) protected currencyService: CurrencyCacheService,
    protected layeredRuleService: LpcLayeredRuleService,
    protected customPropService: CustomPropertyService,
    protected rgiModalService: ModalService,
    protected eventService: RgiRxEventService,
    // EXT SERVICES
    protected otpService: OtpService,
    protected translateService: RgiRxTranslationService
    ) {
    super(modalService, httpClient, pipDataServiceService, environment, authService, injector, translate,
      notifierService, plcGraphicFix, rgiNumberFormatter, anagService, proposalDetailService, customModalService,
      operationsListComponent, showMobileLoader, currencyService, layeredRuleService, customPropService, rgiModalService, eventService);
  }

  ngOnInit(): void {
    this.$subscriptionsList.push(this.otpService.callOtpAbilitation().subscribe(() => this.showOtpDocuments = this.otpService.otpAbilitation));
    this.otpMessages = [];
    super.ngOnInit();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this.$subscriptionsList.forEach(subscription => subscription.unsubscribe());
  }

  public openOTPDocuments(movement: Movement) {
    if (!!movement) {
      this.eventPropagation.emit(JS_EVENT.LOADER.START);
      this.$subscriptionsList.push(
        this.otpService.getMovementDocuments(
          this.lifeContract.generalData.policyId,
          movement.idMov
        ).subscribe((response: OTPDocument[]) => {
          this.eventPropagation.emit(JS_EVENT.LOADER.STOP);
          if (!response) {
            this.throwOtpServiceError('_IIAB_._OTP_._OTP_DOCUMENTS_MISSING_');
            return;
          }
          const otpDocuments = response;
          this.eventPropagation.emit({ eventName: JS_EVENT_EXT.OPEN_OTP_DOCUMENTS, otpDocuments });
        }, _ => {
          this.throwOtpServiceError('_IIAB_._OTP_._OTP_SERVICE_KO');
          this.eventPropagation.emit(JS_EVENT.LOADER.STOP);
        })
      );
    }
  }


  private throwOtpServiceError(key: string) {
    this.otpMessages = [];
    this.translateService.translate(key).subscribe(
      (translation: string) => {
        this.otpMessages.push(
          {
            msg: translation,
            livello: '1'
          }
        );
      });
  }

  public orderAction(actionOperation: string): number {
    return super.orderAction(actionOperation);
  }

}
