import { Beneficiary } from '../modules/lpc-beneficiary/model/beneficiary';
import { Questionnaire } from '../modules/lpc-beneficiary/model/questionnaire';
import { KarmaProfile } from '../modules/lpc-karma-funds/model/karma-profile';
import { KarmaProfileDefinition } from '../modules/lpc-karma-funds/model/karma-profile-definition';
import { QuestionnaireLevel } from '../modules/lpc-questionnaire/model/questionnaire-level.enum';
import { ToolDefinition } from '../modules/lpc-tools/model/tool-definition';
import { FiscalData } from '../operation-components/pension-buyback/config/pension-buyback.model';
import { RoleType } from './enum/lpc-subjects.enum';
import { RestErrorType } from './enums/vita.enum';
import { Factor } from './factor.model';
import { FeesFactor } from './fees-factor.model';
import { LoanQuotationTableDefinitions, LoanTableDefinitions } from './loan.model';
import { OperationPropertyCode } from './operation-property-code.enum';

interface AbsPostsalesOperationsObject {
  definitions: {
    [key: string]: InputFieldDefinition | InputFieldDefinition[] |
      RoleDefinition[] | BeneficiaryDefinition[] | Definition[] | DefinitionPaymentTypes |
      FactorDefinition[] | FactorDefinition | QuotationDefinition[] | QuotationDefinition |
      ToolDefinition[] | KarmaProfile[] | KarmaProfileDefinition[] | FeesFactor |
      QuestionnaireDefinition[] | OperationProperty[] | BeneficiaryRole[] | LienTypesCreditor [] |
      PaymentTypeDefinition[] | BenefitDefinition | ConfirmOperationDefinition | FormFieldsDefinition[] |
      PolicyRoles[] | RelatedPolicies[] | LoanQuotationTableDefinitions[] | LoanTableDefinitions[] | DataTransferDefinition[] |
      ClauseDefinition | ClauseDefinition[] | FiscalData | FinancialData
  };
  finalizable: boolean;
  requireAuthorization?: boolean;
  errors: PostsalesError[];
}

export interface FinancialData {
  accruedBenefit: MonetaryValue;
  startingAnnuityAmount: MonetaryValue;
  taxableBefore2001: null;
  taxableBefore2006: null;
  taxableAfter2006: MonetaryValue | null;
  paymentTypes: PaymentTypeDefinition[];
}

export interface MonetaryValue {
  value: string;
  label: string;
}


export interface OperationProperty {
  booleanValue: string;
  code: OperationPropertyCode;
  stringValue: string;
}

export interface PostsalesOperationsResponse extends AbsPostsalesOperationsObject {
  data: PostsalesOperationsResponseData;
}

export type PVErrorSeverity = 'error' | 'auth' | 'warning' | 'info';
export type PVErrorType = 'persistent' | 'currentStep';

export const PV_ERROR_SEVERITY_VALUE = {
  ERROR: 'error' as PVErrorSeverity,
  AUTH:  'auth' as PVErrorSeverity,
  WARNING: 'warning' as PVErrorSeverity,
  INFO: 'info' as PVErrorSeverity
};

export interface PostsalesError {
  context: string;
  errorId: string;
  errorMessage: string;
  severity: PVErrorSeverity;
  isFe?: boolean;
  type: PVErrorType;
}

export interface BenefitDefinition {
  value: string;
  editable: boolean;
  required: boolean;
}

export interface ConfirmOperationDefinition {
  confirmEnable: boolean;
}

export interface OperationData {
  data: { [key: string]: any | any[] };
  operationDataType: string;
}

interface AbsPostsalesOperationsData {
  id: string;
  contractorId: string;
  managementNodeId: string;
  contractId: string;
  causeCode: string;
  subCauseCode: string;
  username: string;
  operationData: OperationData;
  step?: string;
  reload?: boolean;
  loginCodeNode?: string;
  loginNodeCode?: string;
  questionnaires?: any;
  productId?: string;
  managementNodeCode?: string;
  authorizationId?: string;
  authorizationIdGen?: string;
  lastMovementId?: string;
  printMode?: string;
  operationRoles?: Role[];
  ticketId?: string;
  ticketIdCrypt?: string;
}

export interface PostsalesOperationsResponseData extends AbsPostsalesOperationsData {
  dates: DataInputField[];
}

export interface PostsalesOperationsData extends AbsPostsalesOperationsData {
  dates: { [key: string]: Date };
  operationData: OperationData;
}

export interface PostsalesOperationObject extends AbsPostsalesOperationsObject {
  data: PostsalesOperationsData;
}

export interface Definition {
  id?: string;
  code: string;
  value?: string;
  label: string;
  visible?: boolean;
  editable?: boolean;
  inputNotes?: string;
  helpFile?: string;
}

export interface PaymentTypeDefinition extends Definition {
  paymentFields: PaymentFieldDefinition[];
}

export interface DefinitionPaymentTypes {
  definitionPaymentTypes: PaymentTypeDefinition[];
  editable: boolean;
}

export interface PaymentFieldDefinition extends Definition {
  type: string;
  defaultValue?: string;
  values?: string;
  required: boolean;
}

export interface FormFieldsDefinition extends Definition {
  mandatory?: boolean;
  readOnly: boolean;
  inputValidator?: string;
  translate: boolean;
  type: FormFieldsType;
  values: Values[];
}

export interface DataTransferDefinition {
  amountToBeTransferred: string;
  descOfPreviousPensionPlan: string;
  id: string;
  notes: string;
  previousContract: string;
  providerOfPreviousPensionPlan: string;
  selected: boolean;
}

export interface FiscalDataFolders {
  headerDataTransfer: FormFieldsDefinition[];
  fiscalFolderData20012006: FormFieldsDefinition[];
  fiscalFolderDataBefore2001: FormFieldsDefinition[];
  fiscalFolderDataPost2006: FormFieldsDefinition[];
}


export type FormFieldsType = 'PARTY' | 'ENUM' | 'STRING' | 'DOUBLE' | 'DATE' | 'INT';

export interface PolicyRoles {
  id: string;
  name: string;
  roleCode: string;
  roleLabel: string;
}

export interface QuotationDefinition {
  annualPremium: Instalment;
  firstInstalment: Instalment;
  nextInstalment: Instalment;
  recoveredCommission?: number;
  reimbursementAmount?: number;
  risks: Risk[];
  beneficiaries: BeneficiaryDefinition[];
  productTypeCode?: string;
}

export interface Instalment {
  description?: string;
  fees: number;
  frequencyInterests: number;
  gross: number;
  net: number;
  taxes: number;
  purePremium?: number;
  loadings?: number;
  extraPremium?: number;
  delayInterests?: number;
  reactivationInterests?: number;
  total?: number;
}

export interface OperationBeneficiaryData {
  beneficiary: Beneficiary;
  editable: boolean;
  idBenefSettlement: string;
  questionnaireData: Questionnaire;
}

export interface BeneficiaryDefinition extends Definition {
  admittedBeneficiaries: RoleDefinition[];
  idAssicurato: string;
  showIrrevocable: boolean;
  isPhysicalSubject: boolean;
  severeDisability: boolean | null;
  type?: number;
  showPositionNumber: boolean;
}

export interface RoleDefinition extends Definition {
  minCardinality: string;
  maxCardinality: string;
  showPercentage: boolean;
  position?: string;
  linkedRoles?: RoleDefinition[];
}

export interface InputFieldDefinition extends Definition {
  reloadOnChange?: boolean;
  required?: boolean;
  defaultValue?: any;
  minValue?: any;
  maxValue?: any;
  listValue?: any[];
  typeCode?: any;
}

export interface AssignmentType {
  codice: string;
  descrizione: string;
}

export interface AssignmentHolderType {
  codice: string;
  descrizione: string;
}

export interface Company {
  codice: string;
  descrizione: string;
}

export interface DataInputField {
  code: string;
  label?: string;
  value: any;
  userInput: boolean;
  persistent?: boolean;
}

export interface Role {
  id: string;
  role: RoleType;
  name: string;
  percentage: string;
  amount?: string;
  personType?: string;
  adult?: boolean;
  linkedSubjectsRoles?: Role[];
}

export interface TextValue {
  text: string;
}

export interface FactorDefinition extends Definition {
  required: boolean;
  defaultValue: string;
  format: string;
  formatter?: any;
  listValue: any[];
  typeCode: string;
  helpFile: string;
}

export interface QuestionnaireDefinition {
  id: number;
  code: string;
  typeCode: string;
  required: boolean;
  description: string;
  level: QuestionnaireLevel;
  subjectType?: string;
}

export interface Risk {
  annualPremium?: Instalment;
  code: string;
  value?: string;
  description?: string;
  extraPremium?: number;
  firstInstalment?: Instalment;
  formulas: Formula[];
  instalmentAmount?: any;
  recoveredCommission?: number;
  reimbursementAmount?: number;
  insuredAmount?: number;
  loadings?: number;
  nextInstalment?: Instalment;
  purePremium?: number;
  subRisks?: Risk[];
  type?: any;
  modifiable?: boolean;
  selected?: boolean;
  factors?: Factor[];
  purchaseCommissions?: Commission[];
  helpFile?: any;
}

export interface RiskRequest {
  codeRisk: string;
  factors: Factor[];
}

export interface Commission {
  code: string;
  description: string;
  value: number;
}

export interface Formula {
  code: string;
  description?: string;
  value: string;
  modifiable?: boolean;
}

export interface BeneficiaryContainer {
  benefType: string;
  benefLabel: string;
  benefName: string;
  benefPercentage: number;
  benefPositionNumberLabel?: string;
  visible?: boolean;
  linkedSubjectsRoles?: BeneficiaryContainer[];
}

export interface BeneficiaryRole {
  admittedType: number;
  availForRole?: number;
  availForType: number;
  code: RoleType;
  label: string;
  mandatory: boolean;
  percentage: boolean;
  maxCardinality?: number;
  minPercentage?: number;
}

export interface LienTypesCreditor {
  codice: string;
  descrizione: string;
}

export interface StringDefinitionField {
  value: string;
}

export interface Values {
  id: string;
  description: string;
}

export interface IdRoleVector {
  id: string;
  role: string;
}

export interface FormuleQuotationTable {
  [key: string]: QuotationTableValueType;
}

export interface QuotationTableValueType {
  value: string;
  type: string;
}


export interface RestErrorMessage {
  errorCode: string;
  errorDescription: string;
  errorType: RestErrorType;
}

export interface RelatedPolicies {
  id: string;
  number: string;
  product: string;
  insuranceForm: string;
  roles: string[];
}

export interface RiskFormulas {
  code: string;
  formulas: FormulasInstance[];
}

export interface FormulasInstance {
  code: string;
  value: number;
}

export interface Agreement {
  code: string;
  description: string;
}

export interface ClauseListDefinition {
  descrizione: string;
  visible: boolean;
  descrizioneEstesa: string;
  codice: string;
  tipoTesto: boolean;
  helpFile: string;
  objectId: any;
  selected: boolean;
  enabled: boolean;
  testo: string;
}

export class ClauseListWithInstAndRisk implements ClauseListDefinition {
  descrizione: string;
  visible: boolean;
  descrizioneEstesa: string;
  codice: string;
  tipoTesto: boolean;
  helpFile: string;
  objectId: any;
  selected: boolean;
  enabled: boolean;
  testo: string;
  istanceName: string;
  riskCode: string;

  constructor(clauselistDef: ClauseListDefinition, instName = null, codRisk = null) {
    this.descrizione = clauselistDef.descrizione;
    this.visible = clauselistDef.visible;
    this.descrizioneEstesa = clauselistDef.descrizioneEstesa;
    this.codice = clauselistDef.codice;
    this.tipoTesto = clauselistDef.tipoTesto;
    this.helpFile = clauselistDef.helpFile;
    this.objectId = clauselistDef.objectId;
    this.selected = clauselistDef.selected;
    this.enabled = clauselistDef.enabled;
    this.testo = clauselistDef.testo;
    this.istanceName = instName;
    this.riskCode = codRisk;
  }
}

export interface ClauseDefinition {
    istanceName?: string;
    riskCode?: string;
    clauseList: ClauseListDefinition[];
}

export interface ClausesData {
  code: string;
  text: string;
}

export interface ClauseVariationInfo {
  policyClauses: PolicyClauseInfo[];
  insuredClauses: InsuredClauseInfo[];
  riskClauses: RiskClauseInfo[];
}

export interface PolicyClauseInfo {
  clauses: ClausesData[];
}

export interface InsuredClauseInfo extends PolicyClauseInfo {
  nomeIstanza: string;
}

export interface RiskClauseInfo extends PolicyClauseInfo {
  nomeIstanza: string;
  codRisk: string;
}
