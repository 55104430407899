import { IiabTranslationsKeys } from './iiab-translations-keys';

export const IIAB_EN: IiabTranslationsKeys = {
  _IIAB_: {
    _MOTOR_: {
      _NICE_ACTIMIZE_KO_: 'Nice Actimize is currently not available. Please re-open the authorization request at a later time to retrigger the screening of the proposal parties.'
    },
    _OTP_: {
      _OTP_SERVICE_KO: 'The OTP service is not available. Please try again later.',
      _INITIATED_SIGNING_PROCESS_: 'Initiated signing process',
      _TITLE_: 'Sign documents',
      _DIGITAL_PRIVACY_CONSENT_NOT_ACCEPTED_NOT_SIGNED_: 'The digital sign privacy consent is not accepted. It will not be possible to digital sign documents.',
      _MOBILE_NOT_PRESENT_NOT_SIGNED_: 'The mobile number is not present. It will not be possible to digital sign documents.',
      _EMAIL_NOT_PRESENT_NOT_SIGNED_: 'The email address is not present. It will not be possible to digital sign documents.',
      _DOCUMENT_DATA_NOT_PRESENT_NOT_SIGNED_: 'Some document data is missing. It will not be possible to digital sign documents.',
      _GENERIC_NOT_SIGNED_ERROR_: 'It will not be possible to digital sign documents.',
      _OTP_DOCUMENTS_: 'Digital signature - Documents',
      _CONSENSUSES_AND_CONTACTS_: 'Consensuses and contacts',
      _OTP_DATA_MISSING_: 'Some mandatory data missing. It will not be possible to digital sign documents.',
      _OTP_DOCUMENTS_MISSING_: 'No digital documents available for this movement',
      _BTN_: {
        _SIGN_DOCS_: 'Sign documents',
      }
    },
    _LABEL_: {
      _DESCRIPTION_: 'Description',
      _VALUE_: 'Value',
      _RELEASE_DATE_: 'Release Date',
      _EXPIRATION_DATE_: 'Expiration Date'
    }
  },
  _LS_TITLE_TOTAL_SUMMARY_: 'Total summary'
};
