import { Component, Inject, Input, OnInit, Optional } from '@angular/core';
import { Instalment } from '../../../models/postsales-operations-response.model';
import { QuotationReactivation } from '../model/quotation.model';
import { LpcCurrencyCache, CurrencyCacheService } from '../../../services/currency-cache.service';
import { RgiCtryLayerNumFormatterPipe } from '@rgi/rgi-country-layer';

@Component({
  selector: 'lpc-reactivation-quotation',
  templateUrl: './lpc-reactivation-quotation.component.html',
  styleUrls: ['./lpc-reactivation-quotation.component.scss'],
  providers: [RgiCtryLayerNumFormatterPipe]
})
export class LpcReactivationQuotationComponent implements OnInit {
  // formatter Options
  public currencyFormatterOptions: Intl.NumberFormatOptions = {
    style: 'currency'
  };

  totalBenefit = 0;

  @Input() label = null;
  @Input() hide: boolean;
  public instalments: {label: string, instalment: Instalment}[];
  public quoteData: QuotationReactivation;

  @Input() set quotationData(quotationData: QuotationReactivation) {
    if (!!quotationData) {
      this.instalments = [];
      this.totalBenefit = 0;
      if (!!quotationData.reactivationPremium && this.installmentGotValue(quotationData.reactivationPremium)) {
        this.instalments.push({label: 'lpc_premium_reactivation', instalment: quotationData.reactivationPremium});
      }
      if (!!quotationData.nextPremium && this.installmentGotValue(quotationData.nextPremium)) {
        this.instalments.push({label: 'lpc_next_installment_premium', instalment: quotationData.nextPremium});
      }
      if (!!quotationData.annualPremium && this.installmentGotValue(quotationData.annualPremium)) {
        this.instalments.push({label: 'lpc_AnnualPremium', instalment: quotationData.annualPremium});
      }

      if (!!quotationData.risksQuotation && !!quotationData.risksQuotation.length) {
        quotationData.risksQuotation.forEach(risk => this.totalBenefit += risk.benefit);
      }
    }
    this.quoteData = quotationData;
  }

  constructor(@Optional() @Inject(LpcCurrencyCache) protected currencyService: CurrencyCacheService) {
    this.currencyFormatterOptions.currency = currencyService.currency;
  }

  ngOnInit() {
  }

  installmentGotValue(annualPremium: Instalment): boolean {
    return Object.values(annualPremium).filter(value => !!value && !!String(value)).some(gotVal => gotVal);
  }

}
