<form [formGroup]="unitsForm">
    <ng-container *ngFor="let instance of instancesArray" [formGroupName]="instance.name">
      <ng-container *ngFor="let sect of instance.sections" [formGroupName]="sect.code">
        <ng-container *ngFor="let unit of sect.units; let iUnit = index" [formGroupName]="unit.code">
          <div rgi-rx-accordion [multi]="true" #multiAccordion=rgiRxAccordion>
            <rgi-rx-expansion-panel [expanded]="unit.selection && !!unit?.subUnits?.length" (toggle)="accordionToggled(instance, sect, unit)">
              <div rgi-rx-expansion-panel-header [ngClass]="{'hide-expandability': !hasFactorsBy(instance.name, sect.code, unit.code)}">
                <!-- <ng-template let-ctx="multiAccordion"> -->
                  <input
                  type="checkbox"
                  class="rgi-ui-form-control"
                  [ngClass]="{ 'unit-locked' : !unit.enable}"
                  [formControlName]="'selection'"
                  [attr.data-qa]="unit.code"
                  [checked]="unit.selection"
                  (click)="unit.enable ? saveAll(unit, instance.name, sect.code, unit.code, null) : null">
                <!-- </ng-template> -->
                <div rgi-rx-expansion-panel-label class="col-xs-6">
                  <span>{{ unit.shortDescription }}</span>
                  <span class="hidden-sm">&nbsp;{{ printDescription(unit) }}</span>
                  <lic-help-icon [helpFile]="unit.helpFile" [title]="unit.shortDescription"></lic-help-icon>
                </div>
                <div class="col-xs-6 text-right unit-info row">
                  <div class="amount-resp col-xs-6">
                    <div *ngIf="isUnitLife(instance.name, sect.code, unit.code)">
                      <span class="lic-unit-label" *ngIf="isTCMorCPI(unit)" translate>lic_InsuredAmount</span>
                      <span class="lic-unit-label" *ngIf="!isTCMorCPI(unit)" translate>lic_InvestedPremium</span>
                      <span class="lic-unit-value">{{getPrestazione(unit.riskCode)}}</span>
                    </div>
                  </div>
                  <div class="amount-resp col-xs-6">
                    <span class="lic-unit-label" translate>lic_FirstInstalment</span>
                    <span class="lic-unit-value">{{getRataFirma(unit.riskCode)}}</span>
                  </div>
                </div>
              </div>
              <ng-container *ngTemplateOutlet="accordionCtrl; context: {accordion : multiAccordion}"></ng-container>


              <ng-template #accordionCtrl>
                <div class="unit-body clearfix row" [hidden]="!isUnitBodyOpen(instance.name, sect.code, unit.code) && !hasFactorsBy(instance.name, sect.code, unit.code)">
                  <lic-factors
                    [parentForm]="unitsForm.get(instance.name).get(sect.code).get(unit.code).get('factors')"
                    [fattori]="unit.instances[0].factors" (changeValEmitter)="changeFactor(unit.instances[0].factors)">
                  </lic-factors>
                </div>
              </ng-template>
            </rgi-rx-expansion-panel>
          </div>

          <div rgi-rx-accordion [multi]="true" #inner=rgiRxAccordion [hidden]="!isUnitBodyOpen(instance.name, sect.code, unit.code) && !unit.subUnits?.length">
            <ng-container formGroupName="subUnits">
              <ng-container *ngFor="let subUnit of unit.subUnits; let iSubUnit = index" [formGroupName]="subUnit.code">
                <rgi-rx-expansion-panel [expanded]="subUnit.selection" (toggle)="accordionToggled(instance, sect, unit, subUnit)">
                  <div rgi-rx-expansion-panel-header class="subUnit-header" [ngClass]="{'hide-expandability': !hasFactorsBy(instance.name, sect.code, unit.code, subUnit.code)}">
                    <!-- <ng-template let-ctx1="inner"> -->
                      <input
                        type="checkbox"
                        class="rgi-ui-form-control"
                        [ngClass]="{ 'unit-locked' : !subUnit.enable}"
                        [formControlName]="'selection'"
                        [attr.data-qa]="subUnit.code"
                        [checked]="subUnit.selection"
                        (click)="subUnit.enable ? saveAll(subUnit, instance.name, sect.code, unit.code, subUnit.code) : null">
                    <!-- </ng-template> -->
                    <div rgi-rx-expansion-panel-label class="col-xs-6">
                      <span>{{ subUnit.description }}</span>
                      <span class="hidden-sm">&nbsp;{{printDescription(subUnit)}}</span>
                      <lic-help-icon [helpFile]="subUnit.helpFile" [title]="subUnit.description"></lic-help-icon>
                    </div>
                    <div class="col-xs-6 text-right unit-info row">
                      <div class="amount-resp col-xs-6">
                        <div *ngIf="isUnitLife(instance.name, sect.code, unit.code, subUnit.code)">
                          <span class="lic-unit-label" *ngIf="isTCMorCPI(subUnit)" translate>lic_InsuredAmount</span>
                          <span class="lic-unit-label" *ngIf="!isTCMorCPI(subUnit)" translate>lic_InvestedPremium</span>
                          <span class="lic-unit-value">{{getPrestazione(subUnit.riskCode)}}</span>
                        </div>
                      </div>
                      <div class="amount-resp col-xs-6">
                        <span class="lic-unit-label" translate>lic_FirstInstalment</span>
                        <span class="lic-unit-value">{{getRataFirma(subUnit.riskCode)}}</span>
                      </div>
                    </div>
                  </div>
                  <ng-container *ngTemplateOutlet="innerCtrl; context: {accordion : inner}"></ng-container>

                  <ng-template #innerCtrl>
                    <div class="unit-body clearfix row" [hidden]="!isUnitBodyOpen(instance.name, sect.code, unit.code, subUnit.code) && !hasFactorsBy(instance.name, sect.code, unit.code, subUnit.code)">
                        <lic-factors
                        [parentForm]="unitsForm.get(instance.name).get(sect.code).get(unit.code).get('subUnits').get(subUnit.code).get('factors')"
                        [fattori]="subUnit.instances[0].factors"
                        (changeValEmitter)="changeFactor(subUnit.instances[0].factors)">
                      </lic-factors>
                    </div>
                  </ng-template>
                </rgi-rx-expansion-panel>

              </ng-container>
            </ng-container>
          </div> <!-- div closer for the inner accordion -->

        </ng-container>
      </ng-container>
    </ng-container>
</form>
