<div [formGroup]="formGroup">
    <div class="col-xs-12 col-sm-6 col-md-7 top-margin padding-top-life-15 padding-left-life-10">
      <pnc-checkbox formControlName="active" [attr.data-qa]="'disinvestment_checkbox_' + definition.description"></pnc-checkbox> <span> {{getFundDescription()}} </span>
    </div>
    <div class="col-xs-12 col-sm-6 col-md-5 padding-top-life-15">
      <div [ngClass]="{'col-xs-6 col-sm-4': showSliderInput, 'col-xs-6 col-sm-6': !showSliderInput}" class="col-xs-6 col-sm-4 switch-right">
        <label class="switch">
          <input style="position: fixed;" type="checkbox" formControlName="isPercent" class="form-control" (change)="onToggleClick()">
          <span class="slider" true="%" [attr.false]="currencySymbol" ></span>
        </label>
      </div>
      <div [ngClass]="{'col-xs-6 col-sm-4': showSliderInput, 'col-xs-6 col-sm-6': !showSliderInput}">
        <label class="switch-value">
          <input #percent formControlName="percent" [attr.data-qa]="'disinvestment_percentage_' + definition.description" [value]="formGroup.get('percent').value" *ngIf="isPercent" class="form-control" placeholder="%" (blur)="roundValueTrunc('percent')"/>
          <input #amount formControlName="amount" [attr.data-qa]="'disinvestment_amount_' + definition.description" [value]="formGroup.get('amount').value" *ngIf="!isPercent" class="form-control" placeholder="{{currencySymbol}}" (blur)="roundValue('amount')"/>
        </label>
      </div>
      <div *ngIf="showSliderInput" class="col-sm-4">
        <input class="slider-top" *ngIf="isPercent" formControlName="percent" type="range" min="0" step="0.01" max="{{ getMaxPercent() }}">
        <input class="slider-top" *ngIf="!isPercent" formControlName="amount" type="range" min="0" step="0.01" max="{{ getMaxDisinvestmentAmount() }}">
        <div class="col-md-6 col-xs-6">0</div>
        <div class="col-md-6 col-xs-6" style="text-align: right;">{{isPercent ? getMaxPercent() : ( getMaxDisinvestmentAmount()  | rgiCtryLNumFormatter : '': currencyFormatterOptions )}}</div>

      </div>
    </div>
  </div>
  <div class="col-sm-12">
    <hr>
  </div>
