import { AuthorizationsStatesService } from './../../services/authorizations-states.service';
import {Component, OnInit, Input, EventEmitter, Output, OnChanges, Optional, Inject} from '@angular/core';
import {Authorization} from '../../resources/models/authorization';
import {UntypedFormGroup, UntypedFormBuilder, Validators} from '@angular/forms';
import {AuthorizationsSearchService} from '../../services/authorizations-search.service';
import {AuthorizationRequest} from '../../resources/models/authorization-request';
import {Event} from '../../resources/models/event';
import {StateTypes} from '../../resources/constants/state-types';
import {RgiRxTranslationService} from '@rgi/rx/i18n';
import {RgiRxUserService, UserService} from "@rgi/rx/auth";
import {DIALOG_DATA, ModalService, OnModalClose} from "@rgi/rx/ui";
import { switchMap } from 'rxjs/operators';
import { EMPTY, of } from 'rxjs';

@Component({
    selector: 'ac-auth-request',
    templateUrl: './auth-request.component.html',
    host: {
        class: 'rgi-authorizations-style'
    }
})
export class AuthRequestComponent implements OnInit, OnChanges {

    @Input() authorizationDetail: Authorization;
    @Input() isSubcard: boolean;
    @Output() back = new EventEmitter<{changedAuthorizationStatus: Event, authorization: Authorization}>();

    username: string;
    authForm: UntypedFormGroup;
    submitted = false;
    actionGroups: Array<Event> = new Array<Event>();
    validationMessages: string[] = [];
    docsValidated: boolean;
    maxChars = 255;
    counterNote = '';

    nextStatus: Event;

    stateTypes = StateTypes;

    constructor(
        protected formBuilder: UntypedFormBuilder,
        protected authorizationsSearchService: AuthorizationsSearchService,
        protected translate: RgiRxTranslationService,
        protected userService: RgiRxUserService,
        protected modalService: ModalService
    ) {
    }

    ngOnInit() {
        let user: any;
        user = this.userService.getUser();
        this.username = user.username;
        this.actionGroups = this.authorizationDetail.acceptableEvents;

        this.authForm = this.formBuilder.group(
            {
                action: [undefined, Validators.required],
                note: [undefined, Validators.maxLength(20)]
            }
        );
    }

    ngOnChanges() {
        this.ngOnInit();
    }

    @Input()
    set documentsValidated(docsValidated: boolean) {
        this.docsValidated = docsValidated;
    }

    onSubmit(event) {
        this.submitted = true;
        this.validateForm();
        this.setFieldsDirty();
        let messageConfirm = '';
        this.translate.translate('_AUTH_._ARE_YOU_SURE_TO_APPLY_THIS_ACTION').subscribe(text => messageConfirm = text);
        let systemMessage = '';
        this.translate.translate('SYSTEM MESSAGE').subscribe(text => systemMessage = text);

        const data: any = {
            messageConfirm: messageConfirm,
            systemMessage: systemMessage
        }

        if (this.authForm.valid) {
            const {modal, component} = this.modalService.openComponent(AuthModalDialogConfirmComponent, data);
            modal.onClose.subscribe(event => {
               if ('ok' === event) {
                   this.changeAuthorizationStatus();
               }
            });
        }
    }

    protected validateForm() {
        const controls = this.authForm.controls;

        this.validationMessages.length = 0;

        if (controls.action.errors && controls.action.errors.required) {
            this.validationMessages.push('Action is mandatory');
        }

        if (controls.action.value) {
            if (controls.action.value.state.code === this.stateTypes.STATE_ACCEPTED
                && !this.docsValidated) {
                this.validationMessages.push('In order to accept the request, all documents must be validated');

                this.authForm.patchValue({
                    action: null
                });

                controls.action.setErrors({docsNotValidated: true});
            } else {
                controls.action.setErrors(null);
            }
        }
    }

    private setFieldsDirty() {
        Object.keys(this.authForm.controls).forEach(field => {
            this.authForm.get(field).markAsDirty();
        });
    }

    changeAuthorizationStatus() {
        this.validationMessages.length = 0;
        const controls = this.authForm.controls;
        const authRequest = new AuthorizationRequest();
        authRequest.authorizationId = this.authorizationDetail.id;
        authRequest.eventId = controls.action.value.id;
        if (controls.note.value) {
            authRequest.manualNotes = controls.note.value;
        }
        this.callUpdateAuthorizationStatus(authRequest).pipe(
            switchMap((data: Authorization) => {
                if (data.errorMessage) {
                    this.validationMessages.push(data.errorMessage);
                    return EMPTY;
                } else {
                    return of(data);
                }
            })
        ).subscribe(authorizationData => {
            const authorization = Object.assign({}, authorizationData, this.authorizationDetail);
            this.back.emit({changedAuthorizationStatus: this.nextStatus, authorization});
        });
    }


    protected callUpdateAuthorizationStatus(authRequest: AuthorizationRequest) {
        return this.authorizationsSearchService.putAuthorizationStatus(authRequest);
    }

    genericEntitiesTrackByFn(index, any: any) {
        return any.code;
    }

    populateNextAction() {
        if (this.authForm.controls.action.value) {
            const event = this.authForm.controls.action.value;
            this.nextStatus = new Event(event.id, event.description, new Date(), event.state, event.level);
        } else {
            this.nextStatus = null;
        }
    }
}

@Component({
    selector: 'ui-modal-dialog-confirm',
    template: `
        <rgi-rx-panel>
            <rgi-rx-panel-header [closeable]="true" (onClose)="modalClose.emit()"></rgi-rx-panel-header>
            <rgi-rx-panel-footer>
                <button class="rgi-ui-btn rgi-ui-btn-secondary" (click)="modalClose.emit()">close</button>
                <button class="rgi-ui-btn rgi-ui-btn-primary" (click)="modalClose.emit('ok')" rgi-rx-qa="modal-confirm-btn">ok</button>
            </rgi-rx-panel-footer>
            <p>{{ data.systemMessage }}</p>
            <p>{{ data.messageConfirm }}</p>
        </rgi-rx-panel> 
    `
})
export class AuthModalDialogConfirmComponent implements OnModalClose {
    modalClose = new EventEmitter<any>();

    data: any

    constructor(@Optional() @Inject(DIALOG_DATA) data: any) {
        this.data = data;
    }

}
