<div class="tbld life-detail-margin-top">
  <div class="tbld_row life-detail-tbld_row_header">
    <div class="tbld_col tbld_col_title" translate>lpc_formula</div>
    <div class="tbld_col tbld_col_title" *ngFor="let riskName of riskNames">{{riskName}}</div>
  </div>

  <ng-container *ngFor="let formula of getformulaNames(formulasGrouped) | async">
    <div class="tbld_row regular">
      <div [attr.data-qa]="'formula-name-'+formula" class="tbld_col life-detail-table-border">{{formula}}</div>
      <div *ngFor="let riskName of riskNames" class="tbld_col tbld_col_value life-detail-table-border">
        <span *ngIf="!!getFormulaByRiskNameAndFormula(formula, riskName); else empty" [attr.data-qa]="'formula-value-'+formula">{{getFormulaByRiskNameAndFormula(formula, riskName).value | rgiCtryLNumFormatter}} </span>
        <ng-template #empty>
          <span data-qa="formula-empty">-</span>
        </ng-template>
      </div>
    </div>

  </ng-container>
</div>
