import { NgModule } from '@angular/core';
import { LifeProposalCardComponent } from './life-proposal-card.component';
import { PortalNgCoreModule} from '@rgi/portal-ng-core';
import { CommonModule } from '@angular/common';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RgiRxI18nModule } from '@rgi/rx/i18n';
import { LIC_TRANSLATIONS } from '../../../lib/i18n/translations';
import { LicRequiredModule } from '../utils/lic-required/lic-required.module';
import { RgiRxDragDropModule, RgiRxFormElementsModule, RgiRxPanelModule, RgiRxTableModule } from '@rgi/rx/ui';
import { NodeSelectionModalComponent } from './node-selection-modal/node-selection-modal.component';


@NgModule({
    declarations: [LifeProposalCardComponent, NodeSelectionModalComponent],
    imports: [
        PortalNgCoreModule,
        CommonModule,
        NgSelectModule,
        FormsModule,
        ReactiveFormsModule,
        RgiRxI18nModule,
        RgiRxI18nModule.forRoot(LIC_TRANSLATIONS),
        LicRequiredModule,
        RgiRxDragDropModule,
        RgiRxFormElementsModule,
        RgiRxPanelModule,
        RgiRxTableModule
    ],
    exports: [LifeProposalCardComponent]
})
export class LifeProposalCardModule { }
