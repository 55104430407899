import { ChangeDetectorRef, Component, Inject, Optional } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslationWrapperService } from '../../i18n/translation-wrapper.service';
import { NotifierService } from '@rgi/portal-ng-core';
import { QuestionnaireCacheService } from '@rgi/questionnaires-manager';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { InvestmentAdapterConfig, OperationWithInvestment } from '../../interfaces/operation-with-financial-step';
import { PV_TOKEN } from '../../models/consts/lpc-consts';
import { KarmaProfileDefinition } from '../../modules/lpc-karma-funds/model/karma-profile-definition';
import { VolatilityErrorType, VolatilityResponse, VolatilityType } from '../../modules/lpc-karma-funds/model/karma-volatility';
import { KarmaFundService } from '../../modules/lpc-karma-funds/service/karma-fund.service';
import { LpcKarmaFundUtils } from '../../modules/lpc-karma-funds/utils/lpc-karma-fund-utils';
import { AbsOperationComponent } from '../../operation-components/abs-operation-component/abs-operation.component';
import { AnagService } from '../../services/anag.service';
import { AuthService } from '../../services/auth.service';
import { PlcQuestService } from '../../services/plc-quest.service';
import { PostsalesOperationsService } from '../../services/postsales-operations.service';
import { PlcDateUtils } from '../../utils/plc-date-utils';
import { AngularCommunicationService } from './../../services/angular-communication.service';
import { PostsalesOperationObject } from '../../models/postsales-operations-response.model';
import { KarmaProfile } from '../../modules/lpc-karma-funds/model/karma-profile';
import { KarmaFund } from '../../modules/lpc-karma-funds/model/karma-fund';

@Component({
  selector: 'lpc-operation-with-financial-step',
  templateUrl: './operation-with-financial-step.component.html',
  // styleUrls: ['./change-combination.component.scss']
})
export class OperationWithFinancialStepComponent extends AbsOperationComponent implements OperationWithInvestment {

   protected operationDataKey = null; // NECESSARIO VALORIZZARE

    // INVESTIMENTI E DISINVESTIMENTI
    public showDisinvestment = false;
    public showInvestment = false;
    public firstInvestmentStep = 'investmentProfiles';
    public firstDisinvestmentStep = 'disinvestmentProfiles';
    public investmentProfiles: KarmaProfileDefinition[] = [];
    public disinvestmentProfiles: KarmaProfileDefinition[] = [];
    public totalAmountToInvest: number; // totale da investire
    public volatility: number; // valore della volatilità

    constructor(
        @Inject(PV_TOKEN.POSTSALES_SERVICE) protected operations: PostsalesOperationsService,
        protected cd: ChangeDetectorRef,
        protected translate: TranslationWrapperService,
        @Inject(PV_TOKEN.CORE_INJECTOR) protected injector: any,
        @Optional() protected questCacheService: QuestionnaireCacheService,
        protected modalService: NgbModal,
        protected notifierService: NotifierService,
        protected plcQuestService: PlcQuestService,
        protected authService: AuthService,
        protected anag: AnagService,
        protected karmaService: KarmaFundService,
        protected angularCommunicationService: AngularCommunicationService
    ) {
        super(operations, cd, translate, injector, questCacheService, modalService, notifierService,
        plcQuestService, authService, anag);
    }

    ngOnInit() {
    }

    // NECESSARIO VALORIZZARE
    protected getFormGroup() {
        return new UntypedFormGroup({
            dates: new UntypedFormControl(),
            disinvestmentProfiles: new UntypedFormGroup({}),
            disinvestmentFunds: new UntypedFormGroup({}),
            investmentProfiles: new UntypedFormGroup({}),
            investmentFunds: new UntypedFormGroup({}),
        });
    }

    // ---------------------------------------- INVESTIMENTI ----------------------------------------

    // PROFILI SELEZIONATI
    public get selectedInvestmentProfiles(): KarmaProfileDefinition[] {
        return LpcKarmaFundUtils.getSelectedProfiles(this.formGroup, this.investmentProfiles);
    }

    // CAPIRE SE PROFILI/FONDI SONO BLOCCATI DA UNA PREVALORIZZAZIONE
    public get profileFixedPercentage(): boolean {
        return this.investmentProfiles && this.investmentProfiles.length > 0 &&
        (
            (!!this.getInvestmentProfileConfigurations.prevalIfLengthEqual1 && this.investmentProfiles.length === 1) ||
            (this.investmentProfiles.every(
                p => p.maxPercentAllocation === p.minPercentAllocation && p.maxPercentAllocation > 0
            )
        ));
    }
    public get fundFixedPercentage(): boolean {
        return this.selectedInvestmentProfiles && this.selectedInvestmentProfiles.length > 0 &&
            this.selectedInvestmentProfiles.every(
                p => p.funds.every(f => f.maxPercentAllocation === f.minPercentAllocation && f.maxPercentAllocation > 0)
            );
    }

    // FLAG PER SALTARE GLI STEP DI INVESTIMENTI e DISINVESTIMENTI
    public get skipDisinvestment(): boolean {
        return false;
    }
    public get skipInvestmentProfiles(): boolean {
        return this.profileFixedPercentage;
    }
    public get skipInvestmentFunds(): boolean {
        return this.fundFixedPercentage;
    }

    public get getInvestmentProfileConfigurations(): InvestmentAdapterConfig {
        return {
            profileFormGroupName: 'investmentProfiles',
            investmentMode: undefined,
            enableVolatility: false,
            prevalIfLengthEqual1: true,
            percentageSumEqual100: true,
            enableSingleElementSelection: false,
            showSliderInput: true,
            showPercentageInput: true,
            showAmountColumnOnViewOnly: true,
            enableOpenListDefault: true
        };
    }

    public get getInvestmentFundConfigurations(): InvestmentAdapterConfig {
        return {
            profileFormGroupName: 'investmentProfiles',
            fundFormGroupName: 'investmentFunds',
            investmentMode: undefined,
            enableVolatility: this.isWithVolatility(),
            prevalIfLengthEqual1: true,
            percentageSumEqual100: true,
            enableSingleElementSelection: false,
            showSliderInput: true,
            showPercentageInput: true,
            showAmountColumnOnViewOnly: true,
            enableOpenListDefault: true
        };
    }

    public haveToInitInvestmentStructure(step: string) {
        const nextStep = this.getNextStep(step);
        if (this.showDisinvestment && this.skipDisinvestment) {
            return nextStep === this.firstDisinvestmentStep;
        } else {
            return nextStep === this.firstInvestmentStep;
        }
    }

    public updateProfilesAndFunds(step, definitions) {
        // DISINVESTIMENTI - VISIBILITà STEP
        if (!!definitions.disinvestmentProfiles && definitions.disinvestmentProfiles.length > 0) {
            this.showDisinvestment = true;
        } else {
            this.showDisinvestment = false;
        }

        // INVESTIMENTI - VISIBILITà STEP & GESTIONE UPDATE
        if (definitions.investmentProfiles && definitions.investmentProfiles.length > 0) {
            this.showInvestment = true;
            if (this.haveToInitInvestmentStructure(step)) {
                this.investmentProfiles = definitions.investmentProfiles;
                LpcKarmaFundUtils.resetProfileAndFundForm(
                    this.investmentProfiles,
                    this.formGroup,
                    'investmentProfiles',
                    'investmentFunds'
                );
            }
            if (this.investmentProfiles && this.investmentProfiles.length > 0) {
                LpcKarmaFundUtils.handleInvestmentsOnUpdate(
                    this.investmentProfiles,
                    this.formGroup,
                    this.getInvestmentProfileConfigurations,
                    this.getInvestmentFundConfigurations
                );
            }
        } else {
            this.showInvestment = false;
        }
    }

    public isWithVolatility(): boolean {
        return false;
    }

    public canCalculateVolatility(step): boolean {
        return this.isWithVolatility() && LpcKarmaFundUtils.canCalculateVolatility(
            this.formGroup, !!this.volatility, this.getNextStep(step), this.skipDisinvestment, this.skipInvestmentProfiles
        );
    }

    public onCalculateVolatility() {
        this.angularCommunicationService.communicate('startLoader');
        this.calculateVolatility().subscribe(_ => {
            this.angularCommunicationService.communicate('stopLoader');
        });
    }

    public calculateVolatility(): Observable<VolatilityResponse> {
        return this.karmaService.calculateVolatility(
            this.getProfilesValueFromForm(this.formGroup, 'investmentProfiles', 'investmentFunds', true),
            this.getProfilesValueFromForm(this.formGroup, 'disinvestmentProfiles', 'disinvestmentFunds', true),
            PlcDateUtils.isoDateTimeToIsoDate(this.formGroup.get('dates').value._1OEFF),
            this.operations.session.productId,
            this.operations.getPolicyNumber(),
            this.totalAmountToInvest,
            this.session.contractor,
            this.operations.session.managementNodeCode,
            this.$session.operation,
            this.getInvestmentModeForRequest(),
            this.volatilityType(),
        ).pipe(
            tap(r => {
                this.volatility = r.volatility;
                this.extractVolatilityErrors(r);
            })
        );
    }

    /** indica il tipo di volatilità da calcolare */
    public volatilityType() {
        return VolatilityType.premiumDemand;
    }

    /** indica il tipo di investimento in corso */
    public getInvestmentModeForRequest(): number {
        return undefined;
    }

    public handleVolatilityEvent(event) {
        if (event.action === 'calculate') {
            this.onCalculateVolatility();
        } else if (event.action === 'reset') {
            this.volatility = 0;
        }
    }

    public getProfilesValueFromForm(fg: UntypedFormGroup, profileControlName: string, fundControlName: string, toHundred: boolean = false) {
        return LpcKarmaFundUtils.getProfilesValueFromForm(
          fg.getRawValue()[profileControlName],
          fg.getRawValue()[fundControlName],
          toHundred
        );
    }

    public extractVolatilityErrors(result: VolatilityResponse, step = 'investmentFunds') {
        if (!result.errors) { return; }
        const warnings = result.errors.filter(err => err.errorType === VolatilityErrorType.WARNING);
        const errors = result.errors.filter(err => err.errorType === VolatilityErrorType.BLOCKING);

        if (!!warnings.length) {
            this.setCustomFeErrorsVector(step, warnings.map(warning => warning.errorDescription), 'warning');
        }
        if (!!errors.length) {
            this.setCustomFeErrorsVector(step, errors.map(error => error.errorDescription), 'error');
        }
    }

    // ---------------------------------------- INVESTIMENTI ----------------------------------------

  public populateInvestmentForms(result: PostsalesOperationObject) {
    if (result.data.operationData.data && result.data.operationData.data.investments) {
      this.inizializeInvestmentForm(result.data.operationData.data.investments);
    } else if (result.data.operationData.data && result.data.operationData.data.investmentProfiles) {
      this.inizializeInvestmentForm(result.data.operationData.data.investmentProfiles);
    }
    if (result.definitions.investmentProfiles && (result.definitions.investmentProfiles as KarmaProfileDefinition[]).length > 0) {
      this.investmentProfiles = (result.definitions.investmentProfiles as KarmaProfileDefinition[]);
    }
  }

  private inizializeInvestmentForm(investments: KarmaProfile[]) {
    investments.forEach(p => {
      (this.formGroup.get('investmentProfiles') as UntypedFormGroup).addControl(p.id, new UntypedFormControl(p.percentage));
      (this.formGroup.get('investmentFunds') as UntypedFormGroup).addControl(
        p.id,
        new UntypedFormGroup(
          !!p.funds.length ? p.funds.map((f: KarmaFund) => {
            return { [f.id]: new UntypedFormControl(f.percentage) };
          }).reduce((group, fund) => {
            Object.assign(group, fund);
            return group;
          }) : {}
        )
      );
    });
  }
}
