/**
 * MORE READABILITY ADVICE
 * Please maintain an alphabetical order in each section
 */
import {AuthTranslationsKeys} from './auth-translations-keys';


export const AUTH_EU_ES: AuthTranslationsKeys = {
    _AUTH_: {
        _CARD_TITLE_: 'Baimenak',
        /* eslint-disable max-len */
        // _VIEW_FROM_{{_START_}}_TO_{{_END}}_OF_{{AUTHORIZATIONLISTLENGTH}}_ELEMENTS: "View from {{ start }} to {{ end}} of {{authorizationList.length}} elements",
        _ACTION_IS_MANDATORY: 'Ekintza derrigorrezkoa da',
        _ARE_YOU_SURE_TO_APPLY_THIS_ACTION: 'Ziur ekintza hori eskatu nahi duzula?',
        _AUTHORIZATION_DEROGATION_LEVEL: 'Baimenaren indargabetze-maila:',
        _AUTHORIZATION_LEVEL: 'Baimenaren maila:',
        _AUTHORIZATION_REQUEST_CODE: 'Baimena eskatzeko kodea',
        _BACK: 'Atzera',
        _BLOCK: 'Blokeatu',
        _BUY_POLICY: 'harpidetu_poliza',
        _BY_USER: 'erabiltzaileak egina',
        _CANCEL: 'Ezeztatu',
        _CANCEL_REQUEST: 'Ezeztatu eskaera',
        _CHARACTERS: 'Karaktereak',
        _CODE: 'Kodea',
        _COMMENT: 'Iruzkina:',
        _COMPETENCE_LEVEL: 'Eskumen-maila:',
        _COMPETENT_LEVEL: 'Maila eskudunekoa:',
        _COMPETENT_USER: 'Erabiltzaile eskuduna:',
        _CONFIRM: 'baieztatu',
        _CONFIRM_VARIATION: 'baieztatu_aldaketa',
        _CONTRACT_NUMBER: 'Kontratu-zenbakia',
        _CONTRACT_TYPE: 'Kontratu mota',
        _DATE: 'Data',
        _DEROGATION_LEVEL: 'Indargabetze-maila:',
        _DESCRIPTION: 'Deskribapena',
        _EFFECTIVE_DATE: 'Indarraldiaren data',
        _ELEMENTS: 'elementuak',
        _EMPTY: 'hutsik',
        _ERROR: 'Errorea',
        _ERR_AUTH_DEROGATION_LEVEL: 'ERR_AUTH_DEROGATION_LEVEL',
        _ERR_AUTH_EXTRA_CHECK: 'ERR_AUTH_EXTRA_CHECK',
        _ERR_AUTH_NOT_OPERABLE: 'ERR_AUTH_NOT_OPERABLE',
        _ERR_AUTH_NOT_SAVED: 'ERR_AUTH_NOT_SAVED',
        _EVENT: 'Gertaera',
        _FILL_IN_REQUEST_STATUS_OR_USER: 'Bete eskaeraren egoera edo erabiltzailea',
        _FIND: 'aurkitu',
        _HISTORY: 'Historia',
        _IN_ORDER_TO_ACCEPT_THE_REQUEST_ALL_DOCUMENTS_MUST_BE_VALIDATED: 'Eskaera onartzeko, dokumentu guztiak baliozkotu behar dira',
        _ISSUE: 'jaulkitzea',
        _ISSUE_DATE: 'jaulkipen-data',
        _ISSUE_POLICY: 'poliza_jaulkitzea',
        _ISSUE_PROPOSAL: 'eskaera_jaulkitzea',
        _ISSUE_USER: 'erabiltzailea jaulkitzea',
        _LAST_ACTION: 'Azken ekintza:',
        _LAST_ACTION_DATE: 'Azken ekintzaren data:',
        _LAST_EVENT: 'Azken gertaera',
        _LAST_EXECUTED_ACTION: 'Baztertutako azken ekintza',
        _MANAGEMENT_NODE: 'Kudeaketa-nodoa',
        _MODIFY_PROPOSAL: 'aldatu_proposamena',
        _MODIFY_VARIATION: 'aldatu_aldaketa',
        _NEXT_STATUS: 'Hurrengo egoera',
        _NODE: 'Nodoa',
        _NODE2: 'Nodoa:',
        _COMPANY: 'Konpainia:',
        _NODE_CODE: 'Nodoaren kodea',
        _NODE_IS_MANDATORY: 'Nodoa derrigorrezkoa da',
        _NOTES: 'Oharrak',
        _NOT_AVAILABLE: 'Ez dago eskuragarri',
        _NO_ELEMENT_PRESENT: 'Ez dago elementurik',
        _OF: 'noiztik',
        _OPERATION: 'Eragiketa',
        _OPERATION_WAS_ISSUED_ON: 'Eragiketaren jaulkipen-data',
        _OPERATOR: 'Operadorea',
        _OPERATOR_DEROGATION_LEVEL: 'Operadorearen indargabetze-maila:',
        _OPERATOR_LEVEL: 'Operadore-maila:',
        _ORIGIN_TYPE: 'Jatorri mota:',
        _PM_POLICY_NUMBER: 'Poliza nagusiaren zk.',
        _PM_PROPOSAL_NUMBER: 'Proposamen nagusiaren zk.',
        _POLICY_NUMBER: 'Poliza-zenbakia',
        _POLICY_NUMBER_PM: 'Poliza nagusiaren zenbakia',
        _POLICY_TYPE: 'Poliza mota',
        _POSSIBLE_ACTIONS: 'Ekintza posibleak',
        _POSSIBLE_DEROGATION_LEVEL: 'Indargabetze-maila posiblea',
        _POSTSELL_CODE: 'Saldu osteko kodea',
        _PRIORITY: 'Lehentasuna',
        _PRODUCT: 'Produktua',
        _PRODUCT_TYPE: 'Produktu mota',
        _PROPOSAL_NUMBER: 'Proposamen-zenbakia',
        _PROPOSAL_NUMBER_PM: 'Proposamen nagusiaren zenbakia',
        _REFERENCE: 'Erreferentzia',
        _REQUESTS_LIST: 'Eskaera-zerrenda',
        _REQUEST_DATE: 'Eskaera-data',
        _REQUEST_DATE_FROM: 'Eskaera-data, hasiera',
        _REQUEST_DATE_FROM_IS_NOT_A_VALID_DATE: 'Eskaera-dataren hasiera ez da baliozko data',
        _REQUEST_DATE_FROM_MUST_BE_GREATER_THEN_REQUEST_DATE_TO: 'Eskaera-datak eskaera-dataren amaiera baino handiago izan behar du',
        _REQUEST_DATE_TO: 'Eskaera-data, amaiera',
        _REQUEST_DATE_TO_IS_NOT_A_VALID_DATE: 'Eskaera-dataren amaiera ez da baliozko data',
        _REQUEST_DETAIL: 'Eskaeraren xehetasunak',
        _REQUEST_NODE: 'Eskaera-nodoa',
        _REQUEST_NUMBER: 'Eskaera-zenbakia',
        _REQUEST_OPERATION: 'Eskatu eragiketa',
        _REQUEST_STATUS: 'Eskaeraren egoera',
        _REQUEST_STATUS_VARIATION: 'Eskaeraren egoera aldatu da',
        _REQUEST_TYPE: 'Eskaera mota',
        _REQUEST_USER: 'Eskaeraren erabiltzailea:',
        _SALE_POINT_GROUP: 'Salmenta-puntuaren taldea',
        _SEARCH_UNDER_SUBNET: 'Bilatu azpisarean',
        _SELECT_NODE: 'Hautatu nodoa',
        _STATUS: 'Egoera:',
        _STATUS_AFTER_EVENT: 'Egoera, gertaeraren ondoren:',
        _STATUS_CHANGES_ARE_NOT_ADMITTED_FOR_THIS_AUTHORIZATION_REQUEST: 'Ez da egoera-aldaketarik onartzen baimen-eskaera honetan',
        _STATUS_VARIATION: 'Egoera aldatu da',
        _SUBSCRIBER: 'Hartzailea',
        _SUBSYSTEM: 'Azpisistema',
        _SUBSYSTEM_IS_MANDATORY: 'Azpisistema derrigorrezkoa da',
        _TO: 'noiz arte',
        _TYPE: 'Mota',
        _USER: 'Erabiltzailea',
        _USER_TYPE: 'Erabiltzaile mota',
        _VARIATION_SUMMARY: 'laburpen_aldakuntza',
        _VIEW_ELEMENT: 'Ikusi elementua',
        _VIEW_FROM: 'Ikusi inprimakia',
        _WARNINGS: 'Abisuak',
        _WORKFLOW_STATUS: 'Lan-fluxuaren egoera',
        _MODIFY_AUTHORIZATION_LABEL: 'Aldatu baimena',
        _CONFIRM_PROP_POL_AUTH: 'Baieztatu proposamena/poliza baimenaren aukeran',
        _OPERATION_CONFIRMED: 'Eragiketa baieztatu da'
    },
    _AUTHORIZATION_SEARCH: 'Baimena bilatzea',
    _AUTHORIZATION_SEARCH_DETAIL: 'Baimena bilatzearen inguruko xehetasunak',
};
