<div *ngIf="danni">
  <span class="header-text" translate>Life Policy</span>
</div>

<div class="row header-warranties">
  <div class="col-lg-10" >
    <p style="font-weight: bold" translate>lic_first_instalment</p>
  </div>
  <div class="col-lg-2 value-warranties">
    <span>{{policyPremium.instalmentGross | rgiCtryLNumFormatter : locale : optionCurrency}}</span>
  </div>
</div>
<div class="arrow-down"></div>
<div class="tbld tbld_contributions_taxes hidden-xs">

  <!-- INTESTAZIONE -->
  <div class="tbld_row tbld_row_contributions_taxes_header">
    <div class="tbld_col" translate>lic_cover</div>
    <div class="tbld_col" translate>lic_pure_premium_label</div>
    <div class="tbld_col" translate>lic_loadings_label</div>
    <div class="tbld_col" translate>lic_extra_premium_label</div>
    <div class="tbld_col" translate>lic_Net</div>
    <div class="tbld_col" translate>lic_int_fract_label</div>
    <div class="tbld_col" translate>lic_fees_label</div>
    <div class="tbld_col" translate>lic_taxes_label</div>
    <div class="tbld_col" translate>lic_gross_label</div>
  </div>
  <!-- GARANZIE -->
  <div *ngFor="let risk of riskPremium" class="tbld_row tbld_row_contributions_taxes">
    <div class="tbld_col tbld_row_warranties" [attr.data-qa]="'garanzia-rataFirma-vita-'+risk.riskCode">{{warrantiesDescr.get(risk.riskCode)}}</div>
    <div class="tbld_col" [attr.data-qa]="'premioPuro-rataFirma-vita-'+risk.riskCode">{{risk.instalmentPurePremium | rgiCtryLNumFormatter : locale : optionCurrency}}</div>
    <div class="tbld_col" [attr.data-qa]="'caricamento-rataFirma-vita-'+risk.riskCode">{{risk.instalmentLoadings | rgiCtryLNumFormatter : locale : optionCurrency}}</div>
    <div class="tbld_col" [attr.data-qa]="'sovrappremio-rataFirma-vita-'+risk.riskCode">{{sumVariables(risk.instalmentHealthExtrapremium,risk.instalmentOtherExtrapremium,risk.instalmentProfessionExtrapremium,risk.instalmentSportsExtrapremium) | rgiCtryLNumFormatter : locale : optionCurrency}}</div>
    <div class="tbld_col" [attr.data-qa]="'netto-rataFirma-vita-'+risk.riskCode">{{risk.instalmentNet | rgiCtryLNumFormatter : locale : optionCurrency}}</div>
    <div class="tbld_col" [attr.data-qa]="'intFraz-rataFirma-vita-'+risk.riskCode">{{risk.instalmentInterestForFractioning | rgiCtryLNumFormatter : locale : optionCurrency}}</div>
    <div class="tbld_col" [attr.data-qa]="'diritti-rataFirma-vita-'+risk.riskCode">{{risk.instalmentFees | rgiCtryLNumFormatter : locale : optionCurrency}}</div>
    <div class="tbld_col" [attr.data-qa]="'imposte-rataFirma-vita-'+risk.riskCode">{{risk.instalmentTaxes | rgiCtryLNumFormatter : locale : optionCurrency}}</div>
    <div class="tbld_col" [attr.data-qa]="'lordo-rataFirma-vita-'+risk.riskCode">{{risk.instalmentGross | rgiCtryLNumFormatter : locale : optionCurrency}}</div>
  </div>
  <div class="tbld_row tbld_row_contributions_taxes_total">
    <div class="tbld_col text-uppercase" translate data-qa="garanzia-rataFirma-vita-total">lic_total_label</div>
    <div class="tbld_col" data-qa="premioPuro-rataFirma-vita-total">{{policyPremium.instalmentPurePremium | rgiCtryLNumFormatter : locale : optionCurrency}}</div>
    <div class="tbld_col" data-qa="caricamento-rataFirma-vita-total">{{policyPremium.instalmentLoadings | rgiCtryLNumFormatter : locale : optionCurrency}}</div>
    <div class="tbld_col" data-qa="sovrappremio-rataFirma-vita-total">{{sumVariables(policyPremium.instalmentHealthExtrapremium,policyPremium.instalmentOtherExtrapremium,policyPremium.instalmentProfessionExtrapremium,policyPremium.instalmentSportsExtrapremium) | rgiCtryLNumFormatter : locale : optionCurrency}}</div>
    <div class="tbld_col" data-qa="netto-rataFirma-vita-total">{{policyPremium.instalmentNet | rgiCtryLNumFormatter : locale : optionCurrency}}</div>
    <div class="tbld_col" data-qa="intFraz-rataFirma-vita-total">{{policyPremium.instalmentInterestForFractioning | rgiCtryLNumFormatter : locale : optionCurrency}}</div>
    <div class="tbld_col" data-qa="diritti-rataFirma-vita-total">{{policyPremium.instalmentFees | rgiCtryLNumFormatter : locale : optionCurrency}}</div>
    <div class="tbld_col" data-qa="imposte-rataFirma-vita-total">{{policyPremium.instalmentTaxes | rgiCtryLNumFormatter : locale : optionCurrency}}</div>
    <div class="tbld_col" data-qa="lordo-rataFirma-vita-total">{{policyPremium.instalmentGross | rgiCtryLNumFormatter : locale : optionCurrency}}</div>
  </div>
</div>
<div *ngIf="ifNotSingleFrequency">
  <div class="row header-warranties">
    <div class="col-lg-10">
      <p style="font-weight: bold" translate>lic_next_instalment_premium</p>
    </div>
    <div class="col-lg-2 value-warranties">
      <span>{{policyPremium.successiveInstalmentGross | rgiCtryLNumFormatter : locale : optionCurrency}}</span>
    </div>
  </div>
  <div class="arrow-down"></div>
  <div class="tbld tbld_contributions_taxes hidden-xs">

    <!-- INTESTAZIONE -->
    <div class="tbld_row tbld_row_contributions_taxes_header">
      <div class="tbld_col" translate>lic_cover</div>
      <div class="tbld_col" translate>lic_pure_premium_label</div>
      <div class="tbld_col" translate>lic_loadings_label</div>
      <div class="tbld_col" translate>lic_extra_premium_label</div>
      <div class="tbld_col" translate>lic_Net</div>
      <div class="tbld_col" translate>lic_int_fract_label</div>
      <div class="tbld_col" translate>lic_fees_label</div>
      <div class="tbld_col" translate>lic_taxes_label</div>
      <div class="tbld_col" translate>lic_gross_label</div>
    </div>
    <!-- GARANZIE -->
    <div *ngFor="let risk of riskPremium" class="tbld_row tbld_row_contributions_taxes">
      <div class="tbld_col tbld_row_warranties" [attr.data-qa]="'garanzia-rataSucc-vita-'+risk.riskCode">{{warrantiesDescr.get(risk.riskCode)}}</div>
      <div class="tbld_col" [attr.data-qa]="'premioPuro-rataSucc-vita-'+risk.riskCode">{{risk.successiveInstalmentPurePremium | rgiCtryLNumFormatter : locale : optionCurrency}}</div>
      <div class="tbld_col" [attr.data-qa]="'caricamento-rataSucc-vita-'+risk.riskCode">{{risk.successiveInstalmentLoadings | rgiCtryLNumFormatter : locale : optionCurrency}}</div>
      <div class="tbld_col" [attr.data-qa]="'sovrappremio-rataSucc-vita-'+risk.riskCode">{{sumVariables(risk.successiveInstalmentHealthExtrapremium,risk.successiveInstalmentOtherExtrapremium,risk.successiveInstalmentProfessionExtrapremium,risk.successiveInstalmentSportsExtrapremium) | rgiCtryLNumFormatter : locale : optionCurrency}}</div>
      <div class="tbld_col" [attr.data-qa]="'netto-rataSucc-vita-'+risk.riskCode">{{risk.successiveInstalmentNet | rgiCtryLNumFormatter : locale : optionCurrency}}</div>
      <div class="tbld_col" [attr.data-qa]="'intFraz-rataSucc-vita-'+risk.riskCode">{{risk.successiveInstalmentInterestForFractioning | rgiCtryLNumFormatter : locale : optionCurrency}}</div>
      <div class="tbld_col" [attr.data-qa]="'diritti-rataSucc-vita-'+risk.riskCode">{{risk.successiveInstalmentFees | rgiCtryLNumFormatter : locale : optionCurrency}}</div>
      <div class="tbld_col" [attr.data-qa]="'imposte-rataSucc-vita-'+risk.riskCode">{{risk.successiveInstalmentTaxes | rgiCtryLNumFormatter : locale : optionCurrency}}</div>
      <div class="tbld_col" [attr.data-qa]="'lordo-rataSucc-vita-'+risk.riskCode">{{risk.successiveInstalmentGross | rgiCtryLNumFormatter : locale : optionCurrency}}</div>
    </div>
    <div class="tbld_row tbld_row_contributions_taxes_total">
      <div class="tbld_col text-uppercase" translate>lic_total_label</div>
      <div class="tbld_col" data-qa="premioPuro-rataSucc-vita-total">{{policyPremium.successiveInstalmentPurePremium | rgiCtryLNumFormatter : locale : optionCurrency}}</div>
      <div class="tbld_col" data-qa="caricamento-rataSucc-vita-total">{{policyPremium.successiveInstalmentLoadings | rgiCtryLNumFormatter : locale : optionCurrency}}</div>
      <div class="tbld_col" data-qa="sovrappremio-rataSucc-vita-total">{{sumVariables(policyPremium.successiveInstalmentHealthExtrapremium,policyPremium.successiveInstalmentOtherExtrapremium,policyPremium.successiveInstalmentProfessionExtrapremium,policyPremium.successiveInstalmentSportsExtrapremium) | rgiCtryLNumFormatter : locale : optionCurrency}}</div>
      <div class="tbld_col" data-qa="netto-rataSucc-vita-total">{{policyPremium.successiveInstalmentNet | rgiCtryLNumFormatter : locale : optionCurrency}}</div>
      <div class="tbld_col" data-qa="intFraz-rataSucc-vita-total">{{policyPremium.successiveInstalmentInterestForFractioning | rgiCtryLNumFormatter : locale : optionCurrency}}</div>
      <div class="tbld_col" data-qa="diritti-rataSucc-vita-total">{{policyPremium.successiveInstalmentFees | rgiCtryLNumFormatter : locale : optionCurrency}}</div>
      <div class="tbld_col" data-qa="imposte-rataSucc-vita-total">{{policyPremium.successiveInstalmentTaxes | rgiCtryLNumFormatter : locale : optionCurrency}}</div>
      <div class="tbld_col" data-qa="lordo-rataSucc-vita-total">{{policyPremium.successiveInstalmentGross | rgiCtryLNumFormatter : locale : optionCurrency}}</div>
    </div>
  </div>
</div>
<div *ngIf="ifNotSingleFrequency">
  <div class="row header-warranties">
    <div class="col-lg-10">
      <p style="font-weight: bold" translate>lic_annual_premium</p>
    </div>
    <div class="col-lg-2 value-warranties">
      <span>{{policyPremium.gross | rgiCtryLNumFormatter : locale : optionCurrency}}</span>
    </div>
  </div>
  <div class="arrow-down"></div>
  <div class="tbld tbld_contributions_taxes hidden-xs">

    <!-- INTESTAZIONE -->
    <div class="tbld_row tbld_row_contributions_taxes_header">
      <div class="tbld_col"  translate>lic_cover</div>
      <div class="tbld_col" translate>lic_pure_premium_label</div>
      <div class="tbld_col" translate>lic_loadings_label</div>
      <div class="tbld_col" translate>lic_extra_premium_label</div>
      <div class="tbld_col" translate>lic_Net</div>
      <div class="tbld_col" translate>lic_int_fract_label</div>
      <div class="tbld_col" translate>lic_fees_label</div>
      <div class="tbld_col" translate>lic_taxes_label</div>
      <div class="tbld_col" translate>lic_gross_label</div>
    </div>
    <!-- GARANZIE -->
    <div *ngFor="let risk of riskPremium" class="tbld_row tbld_row_contributions_taxes">
      <div class="tbld_col tbld_row_warranties" [attr.data-qa]="'garanzia-premioAnnuo-vita-'+risk.riskCode">{{warrantiesDescr.get(risk.riskCode)}}</div>
      <div class="tbld_col"[attr.data-qa]="'premioPuro-premioAnnuo-vita-'+risk.riskCode">{{risk.purePremium | rgiCtryLNumFormatter : locale : optionCurrency}}</div>
      <div class="tbld_col"[attr.data-qa]="'caricamento-premioAnnuo-vita-'+risk.riskCode">{{risk.policyLoadings | rgiCtryLNumFormatter : locale : optionCurrency}}</div>
      <div class="tbld_col"[attr.data-qa]="'sovrappremio-premioAnnuo-vita-'+risk.riskCode">{{sumVariables(risk.healthExtrapremium,risk.otherExtrapremium,risk.professionExtrapremium,risk.sportsExtrapremium) | rgiCtryLNumFormatter : locale : optionCurrency}}</div>
      <div class="tbld_col" [attr.data-qa]="'netto-premioAnnuo-vita-'+risk.riskCode">{{risk.net | rgiCtryLNumFormatter : locale : optionCurrency}}</div>
      <div class="tbld_col" [attr.data-qa]="'intFraz-premioAnnuo-vita-'+risk.riskCode">{{risk.interestsForFractioning | rgiCtryLNumFormatter : locale : optionCurrency}}</div>
      <div class="tbld_col" [attr.data-qa]="'diritti-premioAnnuo-vita-'+risk.riskCode">{{risk.fees | rgiCtryLNumFormatter : locale : optionCurrency}}</div>
      <div class="tbld_col" [attr.data-qa]="'imposte-premioAnnuo-vita-'+risk.riskCode">{{risk.taxes | rgiCtryLNumFormatter : locale : optionCurrency}}</div>
      <div class="tbld_col" [attr.data-qa]="'lordo-premioAnnuo-vita-'+risk.riskCode">{{risk.gross | rgiCtryLNumFormatter : locale : optionCurrency}}</div>
    </div>
    <div class="tbld_row tbld_row_contributions_taxes_total">
      <div class="tbld_col text-uppercase" translate>lic_total_label</div>
      <div class="tbld_col" data-qa="premioPuro-premioAnnuo-vita-total">{{policyPremium.purePremium | rgiCtryLNumFormatter : locale : optionCurrency}}</div>
      <div class="tbld_col" data-qa="caricamento-premioAnnuo-vita-totale">{{policyPremium.policyLoadings | rgiCtryLNumFormatter : locale : optionCurrency}}</div>
      <div class="tbld_col" data-qa="sovrappremio-premioAnnuo-vita-totale">{{sumVariables(policyPremium.healthExtrapremium,policyPremium.otherExtrapremium,policyPremium.professionExtrapremium,policyPremium.sportsExtrapremium) | rgiCtryLNumFormatter : locale : optionCurrency}}</div>
      <div class="tbld_col" data-qa="netto-premioAnnuo-vita-total">{{policyPremium.net | rgiCtryLNumFormatter : locale : optionCurrency}}</div>
      <div class="tbld_col" data-qa="intFraz-premioAnnuo-vita-total">{{policyPremium.interestsForFractioning | rgiCtryLNumFormatter : locale : optionCurrency}}</div>
      <div class="tbld_col" data-qa="diritti-premioAnnuo-vita-total">{{policyPremium.fees | rgiCtryLNumFormatter : locale : optionCurrency}}</div>
      <div class="tbld_col" data-qa="imposte-premioAnnuo-vita-total">{{policyPremium.taxes | rgiCtryLNumFormatter : locale : optionCurrency}}</div>
      <div class="tbld_col" data-qa="lordo-premioAnnuo-vita-total">{{policyPremium.gross | rgiCtryLNumFormatter : locale : optionCurrency}}</div>
    </div>
  </div>
</div>

<!-- ------------------------------------------- PARTE DANNI ------------------------------------------------------ -->

<div *ngIf="danni">
    <span class="header-text" translate>Damages Policy</span>
  </div>

<div *ngIf="danni">
  <div class="row header-warranties">
    <div class="col-lg-10">
      <p style="font-weight: bold" translate>lic_first_instalment</p>
    </div>
    <div class="col-lg-2 value-warranties">
      <span>{{damagesInstalment[0].dueAmount.premium.gross | rgiCtryLNumFormatter : locale : optionCurrency}}</span>
    </div>
  </div>
  <div class="arrow-down"></div>
  <div class="tbld tbld_contributions_taxes hidden-xs">

    <!-- INTESTAZIONE -->
    <div class="tbld_row tbld_row_contributions_taxes_header">
      <div class="tbld_col" translate>lic_cover</div>
      <div class="tbld_col" translate>lic_Net</div>
      <div class="tbld_col" translate>lic_accessories</div>
      <div class="tbld_col" translate>lic_int_fract_label</div>
      <div class="tbld_col" translate>lic_taxable</div>
      <div class="tbld_col" translate>lic_taxes_label</div>
      <div class="tbld_col" translate>lic_gross_label</div>
    </div>
    <!-- GARANZIE -->
    <div *ngFor="let risk of damagesInstalment[0].dueAmountsByRisk" class="tbld_row tbld_row_contributions_taxes">
      <div class="tbld_col tbld_row_warranties" [attr.data-qa]="'garanzia-rataFirma-danni-'+risk.object.code">{{warrantiesDescr.get(risk.object.code)}}</div>
      <div class="tbld_col" [attr.data-qa]="'netto-rataFirma-danni-'+risk.object.code">{{risk.premium.net | rgiCtryLNumFormatter : locale : optionCurrency}}</div>
      <div class="tbld_col" [attr.data-qa]="'accessori-rataFirma-danni-'+risk.object.code">{{risk.premium.accessories | rgiCtryLNumFormatter : locale : optionCurrency}}</div>
      <div class="tbld_col" [attr.data-qa]="'intFraz-rataFirma-danni-'+risk.object.code">{{risk.premium.fractioningInterestsAccessories | rgiCtryLNumFormatter : locale : optionCurrency}}</div>
      <div class="tbld_col" [attr.data-qa]="'imponibile-rataFirma-danni-'+risk.object.code">{{sumVariables(risk.premium.net,risk.premium.accessories,risk.premium.fractioningInterestsAccessories,risk.premium.fractioningInterestsNet) | rgiCtryLNumFormatter : locale : optionCurrency}}</div>
      <div class="tbld_col" [attr.data-qa]="'imposte-rataFirma-danni-'+risk.object.code">{{risk.premium.taxes | rgiCtryLNumFormatter : locale : optionCurrency}}</div>
      <div class="tbld_col" [attr.data-qa]="'lordo-rataFirma-danni-'+risk.object.code">{{risk.premium.gross | rgiCtryLNumFormatter : locale : optionCurrency}}</div>
    </div>
    <div class="tbld_row tbld_row_contributions_taxes_total">
      <div class="tbld_col text-uppercase" translate>lic_total_label</div>
      <div class="tbld_col" data-qa="netto-rataFirma-danni-total">{{damagesInstalment[0].dueAmount.premium.net | rgiCtryLNumFormatter : locale : optionCurrency}}</div>
      <div class="tbld_col" data-qa="accessori-rataFirma-danni-total">{{damagesInstalment[0].dueAmount.premium.accessories | rgiCtryLNumFormatter : locale : optionCurrency}}</div>
      <div class="tbld_col" data-qa="intFraz-rataFirma-danni-total">{{damagesInstalment[0].dueAmount.premium.fractioningInterestsAccessories | rgiCtryLNumFormatter : locale : optionCurrency}}</div>
      <div class="tbld_col" data-qa="imponibile-rataFirma-danni-total">{{sumVariables(damagesInstalment[0].dueAmount.premium.net,damagesInstalment[0].dueAmount.premium.accessories,damagesInstalment[0].dueAmount.premium.fractioningInterestsAccessories,damagesInstalment[0].dueAmount.premium.fractioningInterestsNet) | rgiCtryLNumFormatter : locale : optionCurrency}}</div>
      <div class="tbld_col" data-qa="imposte-rataFirma-danni-total">{{damagesInstalment[0].dueAmount.premium.taxes | rgiCtryLNumFormatter : locale : optionCurrency}}</div>
      <div class="tbld_col" data-qa="lordo-rataFirma-danni-total">{{damagesInstalment[0].dueAmount.premium.gross | rgiCtryLNumFormatter : locale : optionCurrency}}</div>
    </div>
  </div>
  <div *ngIf="ifNotSingleFrequency">
    <div class="row header-warranties">
      <div class="col-lg-10">
        <p style="font-weight: bold" translate>lic_next_instalment_premium</p>
      </div>
      <div class="col-lg-2 value-warranties">
        <span>{{damagesInstalment[1].dueAmount.premium.gross | rgiCtryLNumFormatter : locale : optionCurrency}}</span>
      </div>
    </div>
    <div class="arrow-down"></div>
    <div class="tbld tbld_contributions_taxes hidden-xs">

      <!-- INTESTAZIONE -->
      <div class="tbld_row tbld_row_contributions_taxes_header">
        <div class="tbld_col" translate>lic_cover</div>
        <div class="tbld_col" translate>lic_Net</div>
        <div class="tbld_col" translate>lic_accessories</div>
        <div class="tbld_col" translate>lic_int_fract_label</div>
        <div class="tbld_col" translate>lic_taxable</div>
        <div class="tbld_col" translate>lic_taxes_label</div>
        <div class="tbld_col" translate>lic_gross_label</div>
      </div>
      <!-- GARANZIE -->
      <div *ngFor="let risk of damagesInstalment[1].dueAmountsByRisk" class="tbld_row tbld_row_contributions_taxes">
        <div class="tbld_col tbld_row_warranties" [attr.data-qa]="'garanzia-rataSucc-danni-'+risk.object.code">{{warrantiesDescr.get(risk.object.code)}}</div>
        <div class="tbld_col" [attr.data-qa]="'netto-rataSucc-danni-'+risk.object.code">{{risk.premium.net | rgiCtryLNumFormatter : locale : optionCurrency}}</div>
        <div class="tbld_col" [attr.data-qa]="'accessori-rataSucc-danni-'+risk.object.code">{{risk.premium.accessories | rgiCtryLNumFormatter : locale : optionCurrency}}</div>
        <div class="tbld_col" [attr.data-qa]="'intFraz-rataSucc-danni-'+risk.object.code">{{risk.premium.fractioningInterestsAccessories | rgiCtryLNumFormatter : locale : optionCurrency}}</div>
        <div class="tbld_col" [attr.data-qa]="'imponibile-rataSucc-danni-'+risk.object.code">{{sumVariables(risk.premium.net,risk.premium.accessories,risk.premium.fractioningInterestsAccessories,risk.premium.fractioningInterestsNet) | rgiCtryLNumFormatter : locale : optionCurrency}}</div>
        <div class="tbld_col" [attr.data-qa]="'imposte-rataSucc-danni-'+risk.object.code">{{risk.premium.taxes | rgiCtryLNumFormatter : locale : optionCurrency}}</div>
        <div class="tbld_col" [attr.data-qa]="'lordo-rataSucc-danni-'+risk.object.code">{{risk.premium.gross | rgiCtryLNumFormatter : locale : optionCurrency}}</div>
      </div>
      <div class="tbld_row tbld_row_contributions_taxes_total">
        <div class="tbld_col text-uppercase" translate>lic_total_label</div>
        <div class="tbld_col" data-qa="netto-rataSucc-danni-total">{{damagesInstalment[1].dueAmount.premium.net | rgiCtryLNumFormatter : locale : optionCurrency}}</div>
        <div class="tbld_col" data-qa="accessori-rataSucc-danni-total">{{damagesInstalment[1].dueAmount.premium.accessories | rgiCtryLNumFormatter : locale : optionCurrency}}</div>
        <div class="tbld_col" data-qa="intFraz-rataSucc-danni-total">{{damagesInstalment[1].dueAmount.premium.fractioningInterestsAccessories | rgiCtryLNumFormatter : locale : optionCurrency}}</div>
        <div class="tbld_col" data-qa="imponibile-rataSucc-danni-total">{{sumVariables(damagesInstalment[1].dueAmount.premium.net,damagesInstalment[1].dueAmount.premium.accessories,damagesInstalment[1].dueAmount.premium.fractioningInterestsAccessories,damagesInstalment[1].dueAmount.premium.fractioningInterestsNet) | rgiCtryLNumFormatter : locale : optionCurrency}}</div>
        <div class="tbld_col" data-qa="imposte-rataSucc-danni-total">{{damagesInstalment[1].dueAmount.premium.taxes | rgiCtryLNumFormatter : locale : optionCurrency}}</div>
        <div class="tbld_col" data-qa="lordo-rataSucc-danni-total">{{damagesInstalment[1].dueAmount.premium.gross | rgiCtryLNumFormatter : locale : optionCurrency}}</div>
      </div>
    </div>
  </div>
  <div *ngIf="ifNotSingleFrequency">
    <div class="row header-warranties">
      <div class="col-lg-10">
        <p style="font-weight: bold" translate>lic_annual_premium</p>
      </div>
      <div class="col-lg-2 value-warranties">
        <span>{{grossDR.toString() | rgiCtryLNumFormatter : locale : optionCurrency}}</span>
      </div>
    </div>

    <div class="arrow-down"></div>
    <div class="tbld tbld_contributions_taxes hidden-xs">

      <!-- INTESTAZIONE -->
      <div class="tbld_row tbld_row_contributions_taxes_header">
        <div class="tbld_col" translate>lic_cover</div>
        <div class="tbld_col" translate>lic_Net</div>
        <div class="tbld_col" translate>lic_accessories</div>
        <div class="tbld_col" translate>lic_int_fract_label</div>
        <div class="tbld_col" translate>lic_taxable</div>
        <div class="tbld_col" translate>lic_taxes_label</div>
        <div class="tbld_col" translate>lic_gross_label</div>
      </div>
      <!-- GARANZIE -->
      <div *ngFor="let risk of damageRisk" class="tbld_row tbld_row_contributions_taxes">
        <div class="tbld_col tbld_row_warranties" [attr.data-qa]="'garanzia-premioAnnuo-danni-'+risk.riskCode">{{warrantiesDescr.get(risk.riskCode)}}</div>
        <div class="tbld_col" [attr.data-qa]="'netto-premioAnnuo-danni-'+risk.riskCode">{{risk.annualPremium.net | rgiCtryLNumFormatter : locale : optionCurrency}}</div>
        <div class="tbld_col" [attr.data-qa]="'accessori-premioAnnuo-danni-'+risk.riskCode">{{risk.annualPremium.accessories | rgiCtryLNumFormatter : locale : optionCurrency}}</div>
        <div class="tbld_col" [attr.data-qa]="'intFraz-premioAnnuo-danni-'+risk.riskCode">{{risk.annualPremium.fractioningInterestsAccessories | rgiCtryLNumFormatter : locale : optionCurrency}}</div>
        <div class="tbld_col" [attr.data-qa]="'imponibile-premioAnnuo-danni-'+risk.riskCode">{{sumVariables(risk.annualPremium.net,risk.annualPremium.accessories,risk.annualPremium.fractioningInterestsAccessories,risk.annualPremium.fractioningInterestsNet) | rgiCtryLNumFormatter : locale : optionCurrency}}</div>
        <div class="tbld_col" [attr.data-qa]="'imposte-premioAnnuo-danni-'+risk.riskCode">{{risk.annualPremium.taxes | rgiCtryLNumFormatter : locale : optionCurrency}}</div>
        <div class="tbld_col" [attr.data-qa]="'lordo-premioAnnuo-danni-'+risk.riskCode">{{risk.annualPremium.gross | rgiCtryLNumFormatter : locale : optionCurrency}}</div>
      </div>
      <div class="tbld_row tbld_row_contributions_taxes_total">
        <div class="tbld_col lic_total_label" translate>lic_total_label</div>
        <div class="tbld_col" data-qa="netto-premioAnnuo-danni-total">{{netDR.toString() | rgiCtryLNumFormatter : locale : optionCurrency}}</div>
        <div class="tbld_col" data-qa="accessori-premioAnnuo-danni-total">{{accessoriesDR.toString() | rgiCtryLNumFormatter : locale : optionCurrency}}</div>
        <div class="tbld_col" data-qa="intFraz-premioAnnuo-danni-total">{{fractioningInterestsAccessoriesDR.toString() | rgiCtryLNumFormatter : locale : optionCurrency}}</div>
        <div class="tbld_col" data-qa="imponibile-premioAnnuo-danni-total">{{imponibileDR.toString() | rgiCtryLNumFormatter : locale : optionCurrency}}</div>
        <div class="tbld_col" data-qa="imposte-premioAnnuo-danni-total">{{taxesDR.toString() | rgiCtryLNumFormatter : locale : optionCurrency}}</div>
        <div class="tbld_col" data-qa="lordo-premioAnnuo-danni-total">{{grossDR.toString() | rgiCtryLNumFormatter : locale : optionCurrency}}</div>
      </div>
    </div>
  </div>
</div>
