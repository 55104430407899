import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {LpcQuotationRecessComponent} from './lpc-quotation-recess/lpc-quotation-recess.component';
import {LpcQuotationReductionComponent} from './lpc-quotation-reduction/lpc-quotation-reduction.component';

import { RgiCountryLayerModule } from '@rgi/rgi-country-layer';
import { LpcReactivationReductionComponent } from './lpc-reactivation-reduction/lpc-reactivation-reduction.component';
import { LpcReactivationQuotationComponent } from './lpc-reactivation-quotation/lpc-reactivation-quotation.component';
import { RgiRxI18nModule } from '@rgi/rx/i18n';


@NgModule({
  declarations: [
    LpcQuotationRecessComponent,
    LpcQuotationReductionComponent,
    LpcReactivationReductionComponent,
    LpcReactivationQuotationComponent
  ],
  imports: [
    CommonModule,
    RgiCountryLayerModule,
    RgiRxI18nModule
  ],
  exports: [
    LpcQuotationRecessComponent,
    LpcQuotationReductionComponent,
    LpcReactivationReductionComponent,
    LpcReactivationQuotationComponent
  ]
})
export class LpcQuotationModule { }
