import { LicObjectUtils } from './../../utils/lic-object-utils';
import { Directive, ElementRef, HostListener, OnInit } from '@angular/core';
import { RgiCtryLayerNumFormatterPipe } from '@rgi/rgi-country-layer';
import { PolicyService } from '../../services/policy-service';
@Directive({
  selector: '[licContractualOptionNumberFormatter]'
})
export class ContractualOptionNumberFormatterDirective implements OnInit {

  private el: HTMLInputElement;
  private quoteNumberFormatterOptions: Intl.NumberFormatOptions = {
    style: 'decimal',
    minimumFractionDigits: 2
  };

  constructor(
    private elementRef: ElementRef,
    private formatterPipe: RgiCtryLayerNumFormatterPipe,
    private policyService: PolicyService
  ) {
    this.el = this.elementRef.nativeElement;
  }

  ngOnInit() {
    this.el.value = this.formatterPipe.transform(
      LicObjectUtils.cloneObject(this.el.value),
      this.policyService.currentLocale,
      this.quoteNumberFormatterOptions
    );
    console.log(this.el.value);
  }

  @HostListener('focus', ['$event.target.value'])
  onFocus(value) {
    this.el.value = this.parseLocaleNumber(value, this.policyService.currentLocale); // opossite of transform
  }

  @HostListener('blur', ['$event.target.value'])
  onBlur(value) {
    this.el.value = this.formatterPipe.transform(this.el.value, this.policyService.currentLocale, this.quoteNumberFormatterOptions);
  }


  /**
   * Converts a formatted numeric string based on a specific locale into a valid JavaScript number.
   * @param stringNumber - The formatted numeric string.
   * @param locale - The locale to use for number parsing.
   * @returns The string representing the converted JavaScript number.
   */
  parseLocaleNumber(stringNumber, locale) {
    /**
     * Gets the thousands separator from the specified locale.
     */
    const thousandSeparator = Intl.NumberFormat(locale).format(11111).replace(/\p{Number}/gu, '');

    /**
     * Gets the decimal separator from the specified locale.
     */
    const decimalSeparator = Intl.NumberFormat(locale).format(1.1).replace(/\p{Number}/gu, '');

    // Removes thousands separators and replaces the decimal separator with a period.
    const numericString = stringNumber
        .replace(new RegExp('\\' + thousandSeparator, 'g'), '')
        .replace(new RegExp('\\' + decimalSeparator), '.');

    /**
     * The converted JavaScript number as a string.
     */
    const parsedNumber = parseFloat(numericString);
    return isNaN(parsedNumber) ? null : parsedNumber.toString();
  }
}
