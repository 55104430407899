import { LicTranslationsKeys } from './lic-translations-keys';

/**
 * !! IMPORTANT !!
 * Add a new key on the bottom of the list and start declaring from the LpcTranslationsKeys
 *
 * @description
 * This is the list of all the translations keys used in the application for the life-issue-card library.
 */

/* eslint-disable max-len */
export const LIC_ES: LicTranslationsKeys = {
  lic_PolicyCorrectlyIssued: 'Póliza número {{num}} emitida correctamente',
  lic_ProposalCorrectlyIssued: 'Propuesta número {{num}} emitida correctamente',
  lic_LifeProposalCorrectlyIssued: 'Propuesta de vida número {{num}} emitida correctamente',
  lic_LifePolicyCorrectlyIssued: 'Póliza de vida número {{num}} emitida correctamente',
  lic_DamagesPolicyCorrectlyIssued: 'Póliza de daños número {{num}} emitida correctamente',
  lic_AuthorizatioProposalForwarded: 'La solicitud de autorización para la propuesta número {{num}} se ha enviado al usuario adecuado',
  lic_ProposalCorrectlySaved: 'Propuesta número {{num}} guardada correctamente',
  lic_PreventiveCorrectlySaved: 'Cotización número {{num}} guardada correctamente',
  lic_benMorteNoAssic: 'El sujeto seleccionado como beneficiario del fallecimiento ya está seleccionado como asegurado, seleccione otro sujeto',
  lic_benMorteNoRef: 'El sujeto seleccionado como beneficiario del fallecimiento ya está seleccionado como tercera persona de contacto',
  lic_benefPresente: 'El sujeto seleccionado como beneficiario del fallecimiento ya está seleccionado, seleccione otro sujeto',
  lic_refTerzoNoAssic: 'El sujeto seleccionado como tercera persona de contacto ya está seleccionado como asegurado, seleccione otro sujeto',
  lic_refTerzoNoContr: 'El asunto seleccionado como tercera persona de contacto ya está seleccionado como titular de la póliza, seleccione otro asunto',
  lic_refTerzoPresente: 'El sujeto seleccionado como tercera persona de contacto ya está seleccionado como beneficiario en caso de fallecimiento',
  lic_DelegateEqualToPolicyHolder: '¿Delegado igual al tomador?',
  lic_LegalGuardianEqualToPolicyHolder: '¿Tutor legal igual al tomador?',
  lic_ThirdPayerEqualToPolicyHolder: '¿Tercer pagador igual al tomador?',
  lic_DelegateEqualToPolicyHolderWarning: 'Sujeto para el rol delegado igual al tomador',
  lic_LegalGuardianEqualToPolicyHolderWarning: 'Sujeto para el puesto de tutor legal igual al tomador',
  lic_ThirdPayerEqualToPolicyHolderWarning: 'Sujeto para el rol tercer pagador igual al tomador',
  lic_MasterPolicyHolder: 'Titular principal de la póliza',
  lic_SubjectXalreadyselectedfortherole: 'El sujeto {{sujeto}} ya está seleccionado para el rol',
  lic_InsuredAmount: 'Importe asegurado',
  lic_InvestedPremium: 'Prima de inversión',
  lic_FirstInstalment: 'Primer plazo',
  lic_InsuredNotEqualHolder: 'El asegurado y el titular deben ser diferentes',
  lic_SaveEstimate: 'Guardar cotización',
  lic_LifeQuote: 'Cotización de vida',
  lic_PreventiveIssue: 'Emisión de la cotización',
  lic_ProposalIssue: 'Propuesta de emisión',
  lic_PolicyIssue: 'Emitir póliza',
  lic_appendixLabel: 'Anexo de beneficiarios',
  lic_summary_save_and_accept: 'guardar y aceptar',
  lic_summary_save_accept_pol_issue: 'guardar, aceptar y emitir póliza',
  lic_summary_save_accept_prop_issue: 'guardar, aceptar y emitir propuesta',
  lic_effectivebeneficiaryholder: 'titular beneficiario efectivo',
  lic_LifePolicy: 'Póliza de vida',
  lic_DamagesPolicy: 'Póliza de daños',
  lic_view: 'Ver',
  lic_premium_detail: 'Detalle de la prima',
  lic_guarantee_summary: 'Resumen de garantías',
  lic_commissions: 'Comisiones',
  lic_Parties: 'Partes',
  lic_Party: 'Parte',
  lic_Employer: 'Empleador',
  lic_SpecificPIPdata: 'Datos PIP específicos',
  lic_prev_continue: 'Próximo',
  lic_Transferdata: 'Transferir datos',
  lic_Selectatleastonecontribution: 'Seleccione al menos una aportación',
  lic_Globalpercentagemustbe100: 'El porcentaje global debe ser 100 %',
  lic_Selectcontributionsmandatoryfields: 'Seleccione los campos obligatorios de las aportaciones',
  lic_Contributiondata: 'Datos de la aportación',
  lic_Globalamountmustbemajorthan0: 'El importe global debe ser mayor que 0',
  lic_Everycontributionselectedmustbemajorthan0: 'Todas las contribuciones seleccionadas deben ser mayores que 0',
  lic_error_mandatory_fields: 'Los campos marcados en rojo son obligatorios o contienen valores incorrectos',
  lic_error_iban: 'El Iban no es correcto',
  lic_authorization_request: 'PETICIÓN DE AUTORIZACIÓN',
  lic_selected_profiles_: 'Perfiles seleccionados',
  lic_Disinvestmentsfunds: 'Fondos de desinversión',
  lic_reg41_comunication: 'Nombre al que enviar comunicaciones',
  lic_disinvestment_amount: 'Importe de desinversión',
  lic_occurrenceNumber: 'Incidencia',
  lic_TheoccurenceNumbermustbeavalue: 'El número de incidentes debe ser mayor que {{min}}',
  lic_Disinvestmentrange: 'Introduzca un importe para desinvertir superior a {{max}} e inferior o igual a {{min}}',
  lic_Incompleteinvestmentforthefunds: 'Inversión incompleta, complete la inversión para los fondos',
  lic_Incompleteinvestmentfortheprofiles: 'Inversión incompleta, complete la inversión para los perfiles',
  lic_Settlementstoreinvest: 'Liquidación a reinvertir',
  lic_Policynumber: 'Número de póliza',
  lic_Type: 'Tipo',
  lic_Beneficiary: 'Beneficiario',
  lic_State: 'Estado',
  lic_Net: 'Neto',
  lic_Nettoreinvest: 'Neto para reinvertir',
  lic_Reinvestmentmustbelargerthanquotation: 'La reinversión debe ser mayor a la cotización.',
  lic_Mustselectatleastonesettlementtoreinvest: 'Debe seleccionar al menos una liquidación para reinvertir.',
  lic_Nettoreinvestmustbeequalorlessthannet: 'El neto a reinvertir debe ser igual o menor que el neto.',
  lic_Nosettlementstoreinvestexist: 'No existen asentamientos para reinvertir.',
  lic_Missingvalueonnettoreinvest: 'Falta el valor en el neto a reinvertir.',
  lic_information: 'Información:',
  lic_Percentage: 'Porcentaje',
  lic_Durationin: 'Duración en',
  lic_years: 'años',
  lic_months: 'meses',
  lic_days: 'días',
  lic_Complementary: '(Complementario)',
  lic_PleaseprovideapaymentmethodtotheCouponBeneficiary: 'Facilite un método de pago al beneficiario del cupón',
  lic_Netavailable: 'Neto disponible',
  lic_Proposal_Saved: 'La propuesta se ha guardado con el número',
  lic_add_employer: 'Añadir un empleado',
  lic_AvailableGuarantees: 'Garantías disponibles',
  lic_error: 'Error',
  lic_error_msg: 'Error al emitir la póliza',
  lic_auth_error_msg: 'Error al procesar la solicitud',
  lic_common_risk: 'Riesgo común',
  lic_assignment_holder: 'Titular de la asignación',
  lic_Sustainability: 'Sostenibilidad',
  lic_ecoSustainabilityAmount: 'Importe de ecosostenibilidad',
  lic_ecoSustainabilityPerc: 'Ecosostenibilidad %',
  lic_envSustainabilityAmount: 'Importe de sostenibilidad ambiental',
  lic_envSustainabilityPerc: 'Sostenibilidad ambiental %',
  lic_paiAmount: 'Importe PAI',
  lic_paiPerc: 'PAI %',
  lic_socialSustainabilityAmount: 'Importe de sostenibilidad social',
  lic_socialSustainabilityPerc: 'Sostenibilidad social %',
  lic_benefSevereDisability: 'Beneficiario con discapacidad grave',
  lic_SecondInsured: 'Segundo asegurado',
  lic_Secondinsuredmustbeaphysicalperson: 'El segundo asegurado tiene que ser una persona física',
  lic_Selectasecondinsured: 'Seleccione un segundo asegurado',
  lic_Aderente: 'Adherente​',
  lic_NumeroProposta: 'Número de propuesta',
  lic_DescrizioneProdotto: 'Descripción de producto',
  lic_DescrizioneRischio: 'Descripción del riesgo',
  lic_CapitaleAssicurato: 'Importe asegurado',
  lic_SELEZIONA: '-- SELECCIONAR --',
  lic_PrNetto: '% neto de la prima',
  lic_ImpAcq: 'Impuesto de compra',
  lic_profileInvestmentIncomplete: 'Inversión incompleta, perfiles de inversión completo',
  lic_fundsInvestmentIncomplete: 'Inversión incompleta, fondos de inversión completos',
  lic_Clauses: 'Cláusulas',
  lic_quote_type: 'Tipo de cotización',
  lic_quote_type_anonymous: 'Anónimo',
  lic_Policyholder: 'Titular de la póliza',
  lic_quote_type_registered: 'Registrado',
  lic_text_required: '*Texto necesario',
  lic_text_required_error: 'Se necesita un texto de cláusula.',
  lic_close: 'cerrar',
  lic_save: 'guardar',
  lic_NEXT: 'SIGUIENTE',
  lic_policyData: 'Datos de la póliza',
  lic_pipData: 'Datos de PIP',
  lic_parties: 'Partes',
  lic_assets: 'Activos',
  lic_quotation: 'Cotización',
  lic_summary: 'Resumen',
  lic_issue: 'Emisión',
  lic_investments: 'Inversiones',
  lic_invest_value_range: 'Por favor ingrese un valor entre {{min}} y {{max}}',
  lic_disinvestment_range: 'Por favor ingrese un valor de desinversión entre {{min}} y {{max}}',
  lic_mandatory_threshold_perc: 'Fondo: {{value}} - Por favor ingrese el porcentaje de umbral',
  lic_mandatory_one_fund: 'Seleccione al menos un fondo',
  lic_invest_factor_min_validation: '{{factor}} debe ser menor que {{amount}}',
  lic_invest_factor_max_validation: '{{factor}} debe ser mayor que {{amount}}',
  lic_invest_factor_eq_validation: '{{factor}} debe ser igual a {{amount}}',
  lic_calculate_asset_volatility: 'Calcular Volatilidad de Activos',
  lic_calculate_sustainability_asset: 'Calcular Sostenibilidad de Activos',
  lic_occurance_number_range: 'El número de ocurrencia debe ser mayor que {{min}}',
  lic_policy_holder: 'Titular de la póliza',
  lic_guarantee: 'Garantía',
  lic_co_applicant_not_same_holder: 'El co-solicitante no debe ser el mismo que el titular de la póliza',
  lic_co_applicant_physical: 'El co-solicitante debe ser una persona física',
  lic_co_applicant_mandatory: 'El co-solicitante es obligatorio',
  lic_co_applicant: 'Co-solicitante',
  lic_insured_must_be_physical: 'El asegurado debe ser una persona física',
  lic_mandatory_product: 'Seleccione un producto',
  lic_mandatory_insured: 'Seleccione un asegurado',
  lic_mandatory_holder: 'Seleccione un titular de póliza',
  lic_contribution_frequency: 'La frecuencia de contribución {{frequency}} debe ser la misma',
  lic_mandatory_benef: 'Beneficiario {{cathegory}}: El beneficiario es obligatorio',
  lic_mandatory_appendix_benef: 'Beneficiario {{cathegory}}: El beneficiario del apéndice es obligatorio',
  lic_add_benef: 'Beneficiario {{cathegory}}: Agregar beneficiario del registro civil',
  lic_add_benef_perc: 'Beneficiario {{cathegory}} {{nominative}}: Agregar porcentaje',
  lic_benef_perc_range: 'Beneficiario {{cathegory}} {{nominative}}: El porcentaje debe estar entre 0.01 y 100',
  lic_benef_total_perc: 'Beneficiario {{cathegory}}: El porcentaje total debe ser del 100%',
  lic_guarantee_mandatory_data: 'Garantía {{unit}}: Complete todos los datos obligatorios',
  lic_quotation_not_done: 'Cotización no realizada: seleccione "Cotizar" para actualizar la cotización',
  lic_quotation_modified_value_quote: 'Los datos se han actualizado: seleccione "Cotizar" para actualizar la cotización',
  lic_quotation_benef_custom_msg: 'Beneficiario {{cathegory}} - {{msg}}',
  lic_product: 'Producto',
  lic_managementNode: 'Nodo de gestión',
  lic_branch: 'Sucursal',
  lic_agreement: 'Acuerdo',
  lic_effect_date: 'Fecha de efecto',
  lic_exp_date: 'Fecha de vencimiento',
  lic_payment_frequency: 'Frecuencia de pago',
  lic_means_of_payment: 'Medios de pago',
  lic_unit_type: 'Tipo de unidad',
  lic_risk_type: 'Tipo de riesgo',
  lic_substate: 'Subestado',
  lic_benefit: 'Beneficio',
  lic_decreasing_frequency: 'Frecuencia decreciente',
  lic_damages: 'Daños',
  lic_effective_holder: 'Titulares beneficiarios',
  lic_insured_role: 'Asegurado',
  lic_third_contact_person: 'Tercera persona de contacto',
  lic_quotation_benef_owner_perc_validation: '{{roleDescr}} {{name}} del beneficiario {{benefName}} no respeta el porcentaje mínimo aceptado {{minPerc}}',
  lic_subscriber: 'Suscriptor',
  lic_yes: 'sí',
  lic_no: 'no',
  lic_new_insured: 'Nuevo asegurado',
  lic_simple_select: 'Seleccionar',
  lic_first_instalment: 'Primer pago',
  lic_next_instalment_premium: 'Prima del próximo pago',
  lic_payment_type: 'Tipo de pago',
  lic_first_instalment_payment_type: 'Tipo de pago del primer pago',
  lic_annual_premium: 'Prima anual',
  lic_quote_button: 'Cotizar',
  lic_quote_save: 'Cotizar y guardar',
  lic_purchasing_commission: 'Comisión de compra',
  lic_beneficiaries_label: 'Beneficiarios',
  lic_general_data_label: 'Datos generales',
  lic_contractual_option_not_allowed: 'Opciones contractuales no permitidas',
  lic_investment_value_label: 'Valor de inversión',
  lic_investment_profile: 'Perfil de inversión',
  lic_investment_funds: 'Fondos de inversión',
  lic_calculate_finantial_parameters: 'Calcular parámetros financieros',
  lic_confirm_button: 'Confirmar',
  lic_cancel_button: 'Cancelar',
  lic_save_button: 'Guardar',
  lic_continue_button: 'Continuar',
  lic_next_button: 'Siguiente',
  lic_required: 'obligatorio',
  lic_node: 'Nodo',
  lic_company: 'Compañía',
  lic_product_type: 'Tipo de producto',
  lic_taxes_label: 'Impuestos',
  lic_fees_label: 'Tarifas',
  lic_frequency_interests: 'Frecuencia de intereses',
  lic_frequency: 'Frecuencia',
  lic_accessories: 'Accesorios',
  lic_taxable: 'Gravable',
  lic_quotation_detail_modal: 'Detalle de cotización',
  lic_guarantees_overview_modal: 'Resumen de garantías',
  lic_gross_label: 'Bruto',
  lic_int_fract_label: 'Int. Frac.',
  lic_next_instalment_label: 'Próximo pago',
  lic_pure_premium_label: 'Prima pura',
  lic_extra_premium_label: 'Prima extra',
  lic_loadings_label: 'Recargos',
  lic_total_label: 'Total',
  lic_beneficiary_perc_is_over: 'La suma de los porcentajes de los roles vinculados a {{benefCat}} {{benefRole}} supera el 100',
  lic_questionSTR_invalid: 'La respuesta a la pregunta: {{question}}, no es correcta',
  lic_purchase_commissions: 'Comisiones de compra',
  lic_sales_commissions: 'Comisiones de venta',
  lic_IBAN_formally_incorrect: 'El IBAN no es válido formalmente',
  lic_holder_physical_person_req: 'El titular de la póliza debe ser una persona física',
  lic_holder_legal_person_req: 'El titular de la póliza debe ser una persona jurídica',
  lic_available_covers: 'Coberturas disponibles',
  lic_covers_overview: 'Resumen de coberturas',
  lic_covers: 'Coberturas',
  lic_cover: 'Cobertura',
  lic_config_profile_warning: 'ADVERTENCIA: Los perfiles de configuración han cambiado.',
  lic_select: 'Seleccionar',
  lic_Irrevocable_beneficiary: 'Beneficiario irrevocable',
  lic_Base: '(Base)',
  lic_Accessory: '(Accesorio)',
  lic_investment_value: 'Valor de inversión',
  lic_volatility: 'Volatilidad',
  lic_name: 'Nombre',
  lic_to_be_invested: 'A invertir',
  lic_info_investment_completed: 'Información: inversión completada',
  lic_fund: 'Fondo',
  lic_max_percentage: 'porcentaje máximo aceptado',
  lic_min_percentage: 'porcentaje mínimo aceptado',
  lic_selected_funds: 'Fondos seleccionados',
  lic_allocation_exceeds: 'La asignación de inversión supera el monto disponible',
  lic_percentage_threshold: '% Umbral',
  lic_fund_distribution_identical: 'Distribución de fondos idéntica al pago inicial',
  lic_fund_not_available: 'sin fondo para la opción',
  lic_details: 'Detalles',
  lic_starting_fund: 'Fondo inicial',
  lic_fund_category: 'Perfil/ Fondo',
  lic_equivalent: 'Equivalente en la fecha',
  lic_policy_address: 'Dirección de la póliza',
  lic_policy_master_details: 'Detalle maestro de la póliza',
  lic_administrative_data: 'Datos administrativos',
  lic_ordinary_mail: 'Correo ordinario',
  lic_threshold: 'Umbral',
  lic_payment_method: 'Método de pago de los próximos pagos',
  lic_additional_payment: 'Pago adicional',
  lic_contractual_options: 'Opciones contractuales',
  lic_elements: 'Elementos',
  lic_position_number: 'Número de posición',
  lic_questionnaires_section: 'Cuestionarios',
  lic_fees: 'Cargos Fijos',
  lic__1TERM: 'Primer Plazo',
  lic__EFFOP: 'Fecha Efectiva',
  lic__OPZFR: 'Frecuencia',
  lic__OPZPM: 'Modo de Pago',
  lic_expirationDate: 'Fecha de Expiración',
  lic__OPBEN: 'Beneficiario',
  lic__MINTS: 'Umbral Mínimo',
  lic__MAXTS: 'Umbral Máximo',
  lic__NUMOC: 'Número de Ocurrencia',
  lic__AMTOP: 'Monto de Rendición',
  lic__MALMT: 'Monto Mínimo',
  lic_amount: 'Monto',
  lic_currency: 'Moneda',
  lic__CIBAN: 'Iban',
  lic_fixedFees: 'Tarifas Fijas',
  lic__PCOUP: 'Porcentaje de Descuento Periódico',
  lic_holder: 'Titular',
  lic_ACTIVE_STATE: 'ACTIVO',
  lic_MANDATORY_STATE: 'OBLIGATORIO',
  lic_DEACTIVATE_STATE: 'DESACTIVAR',
  lic_premium_label: 'Prima',
  lic_holders_title: 'Titulares',
  lic_beneficiary_qualification: ' {{benefCat}} {{benefRole}} ',
  lic_invalid_total_position_percentage: 'El porcentaje total para el grupo "{{groupType}}" en el Beneficiario {{cat}} debe ser del 100%',
  lic_beneficiary_position_mandatory: 'Beneficiario {{benefCat}} {{benefName}}, selecciona un \"Número de Posición\"',
  lic_role_is_mandatory: '{{role}} es obligatorio',
  lic_effective_holder_duplicated: 'Titular efectivo duplicado',
  lic_beneficiary_perc_req_range: '{{benefCat}} {{benefRole}} {{benefName}}: El porcentaje debe estar entre 0.01 y 100',
  lic_beneficiary_perc_req: ' {{benefCat}} {{benefRole}} {{benefName}}: Ingresa un porcentaje',
  lic_beneficiary_from_party_req: ' {{benefCat}} {{benefRole}}: Ingresa un beneficiario del repositorio de partes',
  lic_effective_holder_perc: 'El porcentaje del titular efectivo {{linkedName}}, del beneficiario {{benefName}}, no cumple con el porcentaje mínimo de {{linkedPerc}}%',
  lic_effective_holder_perc_req: '{{linkedName}} titular efectivo de {{benefName}}: Ingresa el porcentaje',
  lic_or_junction: 'o'
};
export default LIC_ES ;
