import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RgiRxFormElementsModule } from '@rgi/rx/ui';
import { RgiCurrencyModule } from '../rgi-currency/rgi-currency.module';
import { LpcDistributionToggleComponent } from './distribution-toggle/distribution-toggle.component';
import { RgiCountryLayerModule } from '@rgi/rgi-country-layer';
import { RgiRxI18nModule } from '@rgi/rx/i18n';

@NgModule({
    declarations: [
        LpcDistributionToggleComponent
    ],
    imports: [
        CommonModule,
        RgiCountryLayerModule,
        RgiRxI18nModule,
        RgiCurrencyModule,
        RgiRxFormElementsModule
    ],
    exports: [
        LpcDistributionToggleComponent
    ]
})
export class LpcDistributionToggleModule { }
