import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable, combineLatest, of } from 'rxjs';
import { PaymentsDashboardIntegrationService } from '../configurations/payments-dashboard-integration.service';
import { map, mergeMap } from 'rxjs/operators';
import { restEndPoint } from '../configurations/payments-dashboard.endpoint';
import { AddForfait } from '../models/forfait/add-forfait.model';
import { ModifyForfait } from '../models/forfait/modify-forfait.model';
import { DeleteForfait } from '../models/forfait/delete-forfait.model';
import { SingleResponseForfait } from '../models/forfait/single-response-forfait.model';
import { ForfaitState } from '../states/forfait.state';
import { OutputForfait } from '../models/forfait/output-forfait.model';

@Injectable({
  providedIn: "root",
})
export class ForfaitService {
  private baseApiUrlV2: string;

  constructor(
    private httpClient: HttpClient,
    @Inject("environment") environment: any,
    private integrationService: PaymentsDashboardIntegrationService
  ) {
    this.baseApiUrlV2 = environment.api.portal.host + environment.api.portal.path + "/v2";
  }

  initState$(st: Observable<ForfaitState>, dataFromRoute: OutputForfait[]): Observable<ForfaitState> {
    return st.pipe(
      map((state: ForfaitState) => {
        state.search = dataFromRoute;
        return state;
      })
    );
  }

  public async addForfait(addForfait: AddForfait): Promise<SingleResponseForfait> {
    return await this.httpClient
      .post<SingleResponseForfait>(this.baseApiUrlV2 + restEndPoint.addForfait, addForfait)
      .toPromise()
      .catch((e: SingleResponseForfait) => {
        return e
      });
  }

  modifyForfait(st: Observable<ForfaitState>, modifyForfait: ModifyForfait) {
    return st.pipe(
      mergeMap((state: ForfaitState) => {
        const response: Observable<SingleResponseForfait> = this.httpClient.put<SingleResponseForfait>(this.baseApiUrlV2 + restEndPoint.modifyForfait, modifyForfait);
        return combineLatest([response, of(state)]);
      }),
      mergeMap(([response, state]) => {
        if (!!response) {
          state.modify = response.response;
        }

        return of(state)
      }),
    );
  }

  deleteForfait(st: Observable<ForfaitState>, deleteForfait: DeleteForfait) {
    return st.pipe(
      mergeMap((state: ForfaitState) => {
        const response = this.httpClient.delete(this.baseApiUrlV2 + restEndPoint.deleteForfait, { body: deleteForfait });
        return combineLatest([response, of(state)]);
      }),
      mergeMap(([response, state]) => {
        return of(state)
      })
    );
  }
}