<div class="lic-life-proposal-card">
  <form [formGroup]="newProposalControl" (ngSubmit)="onSubmit()">
    <!-- select company -->
    <div class="row">
      <rgi-rx-form-field>
        <label rgiRxLabel>
          <span>{{'lic_company' | translate}}</span>
          <lic-required required="true"></lic-required>
        </label>
        <select rgiRxNativeSelect formControlName="company"
                (change)="cleanNode()"
                data-qa="new-life-proposal-company">
          <option value=""></option>
          <option *ngFor="let company of availableCompany" [value]="company.code">{{ company.description }}</option>
        </select>
      </rgi-rx-form-field>
    </div>
    <!-- select node -->
    <div class="example-row">
        <label class="label-core">
          <span>{{'lic_node' | translate}}</span>
          <lic-required required="true"></lic-required>
        </label>
        <select formControlName="node"
                (change)="selectChangeHandlerNode($event)"
                data-qa="new-life-proposal-node"
                class="drop-container">
          <option *ngFor="let node of descriptions" [value]="node.displayValue">{{node.description}}</option>
        </select>
  
        <div class="drop-item-delete">
            <button *ngIf="withBasket" type="button" class="drag-del-item" (click)="cleanNode()">
              <span class="glyphicon glyphicon-trash"></span>
            </button>
        </div>
  
      <!-- <rgi-rx-drop-container
        formControlName="node"
        label="{{'lic_node' | translate}}"
        select="NodeSelectionModal"
        [selectData]="descriptions"
        field="data.displayValue">
        <rgi-rx-drag-remove (onRemove)="cleanNode()"></rgi-rx-drag-remove>
      </rgi-rx-drop-container> -->
    </div>
  
    <!-- select product type -->
    <div class="example-row">
      <rgi-rx-form-field>
        <label rgiRxLabel>
          <span>{{'lic_product_type' | translate}}</span>
          <lic-required required="true"></lic-required>
        </label>
        <select rgiRxNativeSelect data-qa="new-life-proposal-productType" name="productType" formControlName="productType" class="drop-container" required>
          <option value=""></option>
          <option *ngFor="let product of products" [value]="product?.productType?.code">
              {{product?.productType?.description}}
          </option>
        </select>
      </rgi-rx-form-field>
    </div>
  
    <div class="example-row">
      <div class="btn-group btn-group-justified">
        <div class="btn-group">
          <button type="button" (click)="clean()" class="btn btn-warning btn-secondary" translate>lic_cancel_button</button>
        </div>
        <div class="btn-group">
          <button type="submit" data-qa='new-life-proposal-start' class="btn btn-warning" translate>lic_prev_continue</button>
        </div>
      </div>
    </div>
  </form>
</div>

