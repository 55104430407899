import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslationWrapperService } from '../../../../i18n/translation-wrapper.service';
import { Subscription, throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { PV_TOKEN } from '../../../../models/consts/lpc-consts';
import { Address } from '../../models/address';
import { AddContactService } from './add-contact.service';

@Component({
  selector: 'lpc-add-contact',
  templateUrl: './add-contact.component.html',
  styleUrls: ['./add-contact.component.scss']
})
export class AddContactComponent implements OnInit, OnDestroy {

  protected $subscription: Subscription[] = [];
  public adressConfig: any = {};
  public receivedLabels = false;
  public hasFormErrors = false;
  public countries = [];
  public provinces = [];
  public cities = [];
  public toponyms = [];
  public selectedCityCap: string[] = [];
  public message = [];
  public loader = false;

  public formGroup: UntypedFormGroup = new UntypedFormGroup({
    country: new UntypedFormControl(null, {
      validators: [Validators.required]
    }),
    adminLevel1: new UntypedFormControl(null),
    adminLevel2: new UntypedFormControl(null, Validators.required),
    adminLevel3: new UntypedFormControl(null, Validators.required),
    cap: new UntypedFormControl(null, Validators.required),
    toponimo: new UntypedFormControl(null),
    indirizzo: new UntypedFormControl(null, Validators.required),
    numeroCivico: new UntypedFormControl(null, Validators.required),
    frazione: new UntypedFormControl(null),
    presso: new UntypedFormControl(null)
  });

  constructor(
    protected activeModal: NgbActiveModal,
    @Inject(PV_TOKEN.CORE_INJECTOR) protected injector: any,
    protected translateService: TranslationWrapperService,
    protected modalService: NgbModal,
    protected addContactService: AddContactService) { }

  ngOnInit() {
    this.loader = true;
    this.$subscription.push(this.addContactService.getCountries().subscribe(countries => {
      this.countries = countries.countriesList;
      this.loader = false;
    }, (error) => {
      this.loader = false;
      this.message.push(this.translateService.getImmediate('lpc_system_error'));
    }));
    this.$subscription.push(
      this.formGroup.get('country').valueChanges.pipe(
          switchMap( countryCode => {
              this.loader = true;
              this.resetFormControls();

              if (countryCode !== 'IT') {
                  this.formGroup.get('adminLevel1').setValidators(Validators.required);
              } else {
                this.formGroup.get('adminLevel1').clearValidators();
                this.formGroup.get('adminLevel1').updateValueAndValidity();
                // SE L'INDIRIZZO E' ITALIANO CARICO LE PROVINCE
                this.provinces = this.injector.get('core.enumService').getEnumList('anag.Provincia');
              }
              return this.addContactService.getAddressConfiguration(countryCode);
          }),
          catchError(error => {
            this.loader = false;
            this.message.push(this.translateService.getImmediate('lpc_system_error'));
            return throwError(error);
          })
        ).subscribe( res => {
            if (!!res.addressConfig) {
              this.adressConfig = res.addressConfig;
              this.receivedLabels = true;
              this.addContactService.getToponyms().subscribe(resp => this.toponyms = resp.toponymsList);
              this.loader = false;
            }
        })
    );

  }

  // QUANDO CAMBIO NAZIONE RESETTO TUTTI GLI ALTRI PARAMETRI
  protected resetFormControls(): void {
    this.receivedLabels = false;
    this.formGroup.get('adminLevel1').setValue(null);
    this.formGroup.get('adminLevel2').setValue(null);
    this.formGroup.get('adminLevel3').setValue(null);
    this.formGroup.get('cap').setValue(null);
    this.formGroup.get('toponimo').setValue(null);
    this.formGroup.get('indirizzo').setValue(null);
    this.formGroup.get('numeroCivico').setValue(null);
    this.formGroup.get('frazione').setValue(null);
    this.formGroup.get('presso').setValue(null);
  }

  getCitiesByProvince(event) {
    this.loader = true;
    this.selectedCityCap = [];
    this.formGroup.get('cap').setValue(null);
    this.formGroup.get('adminLevel3').setValue(null);
    this.addContactService.getCitiesByProvince(event.codice).subscribe(cities => {
      this.cities = cities.citiesList;
      this.loader = false;
    }, (error) => {
      this.loader = false;
      this.message.push(this.translateService.getImmediate('lpc_system_error'));
    });
  }


  getCapByCity($event) {
    this.selectedCityCap = [];
    this.addContactService.getZipByCityAndProvince($event.name, $event.provinceInitial)
      .subscribe(provinceList => this.selectedCityCap = provinceList.zipList);
  }

  close($event?: MouseEvent) {
    if (!!this.activeModal) {
      this.activeModal.close(null);
    }
  }

  ngOnDestroy(): void {
    this.$subscription.forEach(sub => sub.unsubscribe());
  }

  onSubmit() {
    this.message = [];
    if (this.formGroup.invalid) {
        this.message.push(this.translateService.getImmediate('lpc_error_mandatory_fields'));
    } else {
      const countryCode = this.formGroup.get('country').value;
      let adminLevel2 = null;
      let provincia = null;
      let adminLevel2Short = null;
      if (!!countryCode && countryCode === 'IT') {
        adminLevel2Short = this.formGroup.get('adminLevel2').value;
        provincia = this.provinces.find(province => province.codice === adminLevel2Short);
        adminLevel2 = !!provincia ? provincia.descrizione : null;
      } else {
        adminLevel2 = this.formGroup.get('adminLevel2').value;
      }
      const policyAddress: Address = {
        placeAddress : this.formGroup.get('indirizzo').value,
        adminLevel1: this.formGroup.get('adminLevel1').value,
        adminLevel2,
        adminLevel2Short,
        adminLevel3 : this.formGroup.get('adminLevel3').value,
        city: this.formGroup.get('adminLevel3').value,
        cap : this.formGroup.get('cap').value,
        country : countryCode,
        number: this.formGroup.get('numeroCivico').value,
        toponym: this.formGroup.get('toponimo').value,
        fraction: this.formGroup.get('frazione').value,
        at: this.formGroup.get('presso').value,
      };
      this.activeModal.close(policyAddress);
    }
  }

  get getValidationMessages(): string[] {
    return this.message;
  }

}
