<div *ngIf="showTable" class="tbld life-detail-margin-top">
  <div class="tbld_row life-detail-tbld_row_header">
    <div data-qa="categoria-fondo" class="tbld_col tbld_col_title" translate>lpc_category_fund</div>
    <div data-qa="percentuale" class="tbld_col tbld_col_title" translate>lpc_life_detail_percentage</div>
  </div>
  <ng-container *ngFor="let profile of profiles; index as indexProfile">
    <div class="tbld_row life-detail-investment-profile-row bolder">
      <div [attr.data-qa]="'profile_description' + profile.id"
           class="tbld_col tbld_col_value life-detail-table-border">{{profile.description}}</div>
      <div class="tbld_col tbld_col_value life-detail-table-border" [attr.data-qa]="'-profilePercent' + profile.id">
        {{percentagesProfilesToShow[indexProfile] | rgiCtryLNumFormatter : '' : percentFormatterOptions }}</div>
    </div>
    <div *ngFor="let fund of profile.funds; index as indexFund" class="tbld_row regular">
      <div [attr.data-qa]="'fund_description' + profile.id + fund.id"
           class="tbld_col tbld_col_value life-detail-table-border"> {{fund.description}}</div>
      <div class="tbld_col tbld_col_value life-detail-table-border"
           [attr.data-qa]=" '-fundPercent' + profile.id + fund.id">
        {{percentagesFundsToShow[indexProfile][indexFund] | rgiCtryLNumFormatter : '' : percentFormatterOptions }}</div>
    </div>
  </ng-container>
  <div *ngIf="showTotal" class="tbld_row life-detail-tbld_row_header">
    <div data-qa="total" class="tbld_col tbld_col_title" translate>lpc_total</div>
    <div class="tbld_col tbld_col_title" translate>
      {{totalPercentSummary(profiles) | rgiCtryLNumFormatter : '' : percentFormatterOptions }}
    </div>
  </div>
</div>
