import { ChangeDetectorRef, Component, Inject, OnInit, Optional } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, ValidationErrors } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslationWrapperService } from '../../i18n/translation-wrapper.service';
import { NotifierService } from '@rgi/portal-ng-core';
import { QuestionnaireCacheService } from '@rgi/questionnaires-manager';
import { distinctUntilChanged } from 'rxjs/operators';
import { EMPTY_STR, ERROR_TYPE_CURRENT, PV_TOKEN, SEVERITY_ERROR_BLOCKING } from '../../models/consts/lpc-consts';
import { KarmaProfile } from '../../modules/lpc-karma-funds/model/karma-profile';
import { KarmaProfileDefinition } from '../../modules/lpc-karma-funds/model/karma-profile-definition';
import { LpcKarmaFundUtils } from '../../modules/lpc-karma-funds/utils/lpc-karma-fund-utils';
import { ToolDefinition, ToolStateStatus } from '../../modules/lpc-tools/model/tool-definition';
import { LpcToolUtils } from '../../modules/lpc-tools/utils/lpc-tool-utils';
import { AnagService } from '../../services/anag.service';
import { AuthService } from '../../services/auth.service';
import { PlcQuestService } from '../../services/plc-quest.service';
import { PostsalesOperationsService } from '../../services/postsales-operations.service';
import { PlcObjectUtils } from '../../utils/plc-object-utils';
import { AbsOperationComponent } from '../abs-operation-component/abs-operation.component';
import { FACTOR_TOOL_CODE, FactorToolCode, ProfileType, ToolCode, ToolCodeIdType } from './../../models/enums/vita.enum';
import { KarmaFund } from './../../modules/lpc-karma-funds/model/karma-fund';


@Component({
  selector: 'lpc-tool-manager',
  templateUrl: './tool-manager.component.html',
  styleUrls: ['./tool-manager.component.css']
})
export class ToolManagerComponent extends AbsOperationComponent implements OnInit {

  protected operationDataKey = 'tools';

  public activeTools: {[key in ToolCodeIdType]?: boolean} = {};
  private $toolDefinitions: ToolDefinition[] = [];

  public get toolDefinitions(): ToolDefinition[] {
    return this.$toolDefinitions;
  }

  public policyProfiles: KarmaProfile[] = [];


   constructor(
    @Inject(PV_TOKEN.POSTSALES_SERVICE) protected operations: PostsalesOperationsService,
    protected cd: ChangeDetectorRef,
    protected translate: TranslationWrapperService,
    @Inject(PV_TOKEN.CORE_INJECTOR) protected injector: any,
    @Optional() protected questCacheService: QuestionnaireCacheService,
    protected modalService: NgbModal,
    protected notifierService: NotifierService,
    protected plcQuestService: PlcQuestService,
    protected authService: AuthService,
    protected anag: AnagService,
    @Optional() @Inject(PV_TOKEN.TOOLS_HIDE_ENUMS) protected hideEnumWithOneValue: boolean
    ) {
      super(operations, cd, translate, injector, questCacheService, modalService, notifierService,
        plcQuestService, authService, anag);
  }

  ngOnInit() {
    this.initializeSession();
    this.$subscriptions.push(
      this.createDraft().subscribe(),

      this.formGroup.get('tools').valueChanges.pipe(
        distinctUntilChanged((prev, curr) => PlcObjectUtils.equal(prev, curr))
      ).subscribe(el => {
        this.$feErrors = [];
        this.getFeErrors(PlcObjectUtils.flattenFormErrors(this.formGroup, {}));
      })
    );
  }

  protected getFormGroup(): UntypedFormGroup {
    return new UntypedFormGroup({
      dates: new UntypedFormControl(),
      tools: new UntypedFormGroup({})
    });
  }

  getFeErrors(errors: ValidationErrors) {
    if (!!Object.keys(errors).length) {
      const msgs: string[] = [];
      if (errors.minMaxThreshold) {
        msgs.push(this.translate.getImmediate('lpc_please_enter_a_value_between', {min: errors.min, max: errors.max}));
      }
      if (errors.thresholdMissing) {
        msgs.push(this.translate.getImmediate('lpc_Please_enter_threshold_percentage_value', {value: errors.fund}));
      }
      if (errors.operator === 'LE') {
        msgs.push(this.translate.getImmediate(
            'lpc_factor_must_be_less_than', {factor: errors.factor.replace(/_/g, ' '), amount: errors.value} ));
      }
      if (errors.operator === 'GE') {
        msgs.push(this.translate.getImmediate(
            'lpc_factor_must_be_greater_than', {factor: errors.factor.replace(/_/g, ' '), amount: errors.value} ));
      }
      if (errors.operator === 'EQ') {
        msgs.push(this.translate.getImmediate(
            'lpc_factor_must_be_equal', {factor: errors.factor.replace(/_/g, ' '), amount: errors.value} ));
      }
      if (errors.iban) {
        msgs.push(this.translate.getImmediate('lpc_IBAN_error'));
      }
      if (errors.disinvestmentAmountMissing) {
        msgs.push(this.translate.getImmediate('lpc_please_enter_a_disinvestment_amount', {fund: errors.fund}));
      }
      if (errors.addAtLeastADisinvestment) {
        msgs.push(this.translate.getImmediate('lpc_please_select_at_least_a_disinvestment'));
      }
      if (errors.minMaxDisinvestmentsAmount) {
        msgs.push(this.translate.getImmediate('lpc_disinvestment_range', { min: errors.min, max: errors.max }));
      }
      if (!!errors.occurrenceNumber) { // a validation message set on the child component: lpc-tool.component
        msgs.push(errors.occurrenceNumber);
      }
      if (!!errors.investmentFunds) { // a validation message set on the child component: lpc-tool.component
        msgs.push(this.translate.getImmediate(errors.investmentFunds));
      }
      if (!!errors.investmentProfile) { // a validation message set on the child component: lpc-tool.component
        msgs.push(this.translate.getImmediate(errors.investmentProfile));
      }
      if (!!msgs.length) {
        msgs.forEach(msg => this.$feErrors.push(PlcObjectUtils.getGenericPostsalesError(this.stepper, this.activeStep, msg)));
      }
    }
  }

  public isToolOpen(operationCodeId: ToolCodeIdType) {
    return this.activeTools[operationCodeId];
  }

  public onToggleTool(toolId: ToolCodeIdType, isActive: boolean) {
    this.activeTools[toolId] = isActive;
    this.formGroup.get('tools').updateValueAndValidity();
  }

  public getTotalAmountOfTool(operationCodeId: ToolCodeIdType) {
    try {
      const factors: {[key in FactorToolCode]: number} = this.formGroup.getRawValue().tools[operationCodeId].factors;
      if (factors[FACTOR_TOOL_CODE.AMOUNT_CODE]) {
        return factors[FACTOR_TOOL_CODE.AMOUNT_CODE];
      } else if (factors[FACTOR_TOOL_CODE.SURRENDER_AMOUNT_CODE]) {
        return factors[FACTOR_TOOL_CODE.SURRENDER_AMOUNT_CODE];
      } else {
        return 0;
      }
    } catch (e) {
      return 0;
    }
  }

  public isToolWithFlag(operationId: ToolCodeIdType) {
    return [ToolCode.SCHEDULED_PREMIUM.toString()].includes(operationId);
  }

  public onFactorReload(event) {
    this.updateDraft('tools', true, null).subscribe(el => {
        this.handleResponse(el);
    });
  }

  public getInitialValue(operationCode: ToolCodeIdType): any {
    const toolDefinition: ToolDefinition = this.getToolDefinitionByOpCode(operationCode);
    return LpcToolUtils.getDefaultFormValueOf(toolDefinition, true, !!toolDefinition.usePolicyProfiles, this.hideEnumWithOneValue);
  }

  handleResponse(result) {
    const activeTools: any = {};
    this.policyProfiles = (PlcObjectUtils.asValidArray(result.definitions.policyProfiles) as KarmaProfile[]);
    this.$toolDefinitions = (result.definitions.tools as ToolDefinition[]).map(tool => {
      tool.disinvestmentProfiles = LpcKarmaFundUtils.filterProfileDefinitionBy(
        PlcObjectUtils.asValidArray(tool.disinvestmentProfiles),
        PlcObjectUtils.asValidArray(this.policyProfiles)
      );
      return tool;
    });
    this.$toolDefinitions.forEach(toolDefinition => {
      const initialValue = this.getInitialValue(toolDefinition.operationCodeId);
      (this.formGroup.get('tools') as UntypedFormGroup).addControl(toolDefinition.operationCodeId, new UntypedFormControl(initialValue));
      // eslint-disable-next-line max-len
      const selectionStateActiveOrMandatory = toolDefinition.selectionState === ToolStateStatus.ACTIVE || toolDefinition.selectionState === ToolStateStatus.MANDATORY;
      activeTools[toolDefinition.operationCodeId] = selectionStateActiveOrMandatory || toolDefinition.preChecked === true;
    });
    this.activeTools = PlcObjectUtils.merge({}, activeTools, this.activeTools);
  }

  onNext(step: string, isConfirmAndAccept = false) {
    this.$feErrors = [];
    this.validateUntouchedForm('tools');
    this.getFeErrors(PlcObjectUtils.flattenFormErrors(this.formGroup.get(step), {}));
    if (!this.$feErrors.find(el => el.context === step)) {
      if (this.isUpdateToCall(step) ) {
        this.updateDraft(step).subscribe(result => {
          this.handleResponse(result);
          // this.detectChanges();
          const bErrors = result.errors && result.errors.length > 0;
          const toBePublished = (isConfirmAndAccept && !bErrors) || this.stepper.isAtLastStep;
          if (!this.hasBlockingErrorsOnSteps(step)) {
            this.doPublishOrNext(toBePublished, isConfirmAndAccept);
          }
        });
      } else {
        this.setFeErrors(step);
      }
    }
  }

  public validateUntouchedForm(step: string) {
    this.$feErrors = [];
    const toolStatus = Object.values(this.activeTools);
    if (step === 'tools' && toolStatus.every(ts => ts !== undefined && !ts)) {
      const msg = this.translate.getImmediate('lpc_nessuna_variazione');
      this.$feErrors.push({
        context: 'tools',
        errorId: EMPTY_STR,
        errorMessage: msg,
        severity: SEVERITY_ERROR_BLOCKING,
        type: ERROR_TYPE_CURRENT
      });
    } else {
      this.$feErrors = [];
    }
  }

  protected getTransformedOperationData(): any {
    return this.toolDefinitions.map(toolDefinition => this.getTransformedToolDefinition(toolDefinition))
      .filter(tool => this.activeTools[tool.operationCodeId]);
  }

  private getTransformedToolDefinition(toolDefinition: ToolDefinition): any {
    const factors: any = toolDefinition.factors.map(factor => {
      if (!!this.getFactorValueByCode(toolDefinition.operationCodeId, factor.code)) {
        return {
          code: factor.code,
          value: this.getFactorValueByCode(toolDefinition.operationCodeId, factor.code)
        };
      }
    }).filter(factor => !!factor);

    let investmentProfiles: KarmaProfile[] = PlcObjectUtils.asValidArray(toolDefinition.investmentProfiles).map(profile => {
      return this.getTransformedProfile(profile, toolDefinition.operationCodeId, ProfileType.INVESTMENT);
    });

    if (
      [ToolCode.SCHEDULED_PREMIUM].includes(toolDefinition.operationCodeId) &&
      this.formGroup.getRawValue().tools[toolDefinition.operationCodeId].defaultFunds
    ) {
      investmentProfiles = this.policyProfiles;
    }

    const disinvestmentProfiles: KarmaProfile[] = PlcObjectUtils.asValidArray(toolDefinition.disinvestmentProfiles).map(profile => {
      return this.getTransformedProfile(profile, toolDefinition.operationCodeId, ProfileType.DISINVESTMENT);
    });

    return {
      operationCodeId: toolDefinition.operationCodeId,
      operationCode: toolDefinition.operationCode,
      toolName: toolDefinition.toolName,
      factors,
      profiles: investmentProfiles,
      disinvestment: disinvestmentProfiles,
      usePolicyProfiles: this.formGroup.getRawValue().tools[toolDefinition.operationCodeId].defaultFunds
    };
  }

  protected getEffectiveOperationData() {
    let effectiveOperationData = this.getTransformedOperationData();
    if (!!this._createOperationData && !!this._createOperationData.length) {
      effectiveOperationData = this.formGroup.get(this.operationDataKey).dirty ?
        effectiveOperationData : this._createOperationData;
    }
    return effectiveOperationData;
  }

  private getFactorValueByCode(operationCodeId: string, code: string): KarmaProfile {
    return this.formGroup.getRawValue().tools[operationCodeId].factors[code];
  }

  private getTransformedProfile(profile: KarmaProfileDefinition, operationCodeId: string, profileType: string): KarmaProfile {
    let totalAmount = 0;
    const profileObj: any = {
      id: profile.id,
      funds: PlcObjectUtils.asValidArray(profile.funds).map(fund => {
        const fundObj: KarmaFund = {
          id: fund.id,
          fundTypeId: fund.fundTypeId
        };
        if (LpcToolUtils.isToolProgressiveSwitch(operationCodeId) && profileType === ProfileType.DISINVESTMENT) {
          fundObj.amount = this.getFundValueById(operationCodeId, profileType, profile.id, fund.id);
          totalAmount += fundObj.amount;
        } else {
          fundObj.percentage = this.getPercentValueConverted(this.getFundValueById(operationCodeId, profileType, profile.id, fund.id));
        }
        return fundObj;
      }).filter(fund => !!fund.percentage || !!fund.amount),
      percentage: this.getPercentValueConverted(this.getProfileValueById(operationCodeId, profileType, profile.id))
    };
    if (!!totalAmount) {
      profileObj.amount = totalAmount;
    }
    return profileObj;
  }

  private getProfileValueById(operationCodeId: string, profileType: string, profileId: any): number {
    const value: number = this.formGroup.get('tools').get(operationCodeId).value[profileType][profileId].percent;
    return value ? value : 0;
  }

  private getFundValueById(operationCodeId: string, profileType: string, profileId: string, fundId: string): number {
    let value: number = this.formGroup.get('tools').get(operationCodeId).value[profileType][profileId].funds[fundId];
    if (typeof value === 'string') {
      value = parseFloat(value);
    }
    return value ? value : 0;
  }

  private getToolDefinitionByOpCode(operationCode: string): ToolDefinition {
    return this.$toolDefinitions.find(toolDefinition => toolDefinition.operationCodeId === operationCode);
  }

  getPercentValueConverted(value: number): number {
    if (value === 1) {return value * 100; }
    if (PlcObjectUtils.countDigit(value) === 0) {
      return value * 100;
    }
    return value;
  }

}
