
import {
  AfterViewInit, Component, EventEmitter,
  forwardRef, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges
} from '@angular/core';
import { AbstractControl, ControlValueAccessor, UntypedFormArray, UntypedFormControl, UntypedFormGroup, NG_VALIDATORS, NG_VALUE_ACCESSOR, Validator } from '@angular/forms';
import { EMPTY_STR } from '../../../models/consts/lpc-consts';
import { BeneficiaryRole, Definition, PaymentTypeDefinition, Role } from '../../../models/postsales-operations-response.model';
import { AnagSubject, PARTY_COMPLETE_KO } from '../../../models/subject.model';
import { AnagService } from '../../../services/anag.service';
import { Beneficiary } from '../model/beneficiary';
import {BeneficiaryCathegory, BeneficiaryType} from '../model/lpc-beneficiary-roles.enum';
import { RgiCtryLayerNumFormatterPipe } from '@rgi/rgi-country-layer';
import { Roles, RoleType } from '../../../models/enum/lpc-subjects.enum';
import { Subscription } from 'rxjs';


@Component({
  selector: 'lpc-beneficiary-control',
  templateUrl: './lpc-beneficiary-control.component.html',
  styleUrls: ['./lpc-beneficiary-control.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => LpcBeneficiaryControlComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => LpcBeneficiaryControlComponent),
      multi: true
    },
    RgiCtryLayerNumFormatterPipe
  ]
})
export class LpcBeneficiaryControlComponent implements ControlValueAccessor, OnChanges, OnInit, AfterViewInit, Validator, OnDestroy {

  // per raccogliere i valori dei beneficiari dal form utilizzare --> PlcObjectUtils.getBeneficiariesFromForm(definitions, formGroup)
  // da aggiungere nel metodo getTransformedOperationData delle operazioni che contengono i beneficiari
  // controlla la formattazione della percentuale (dopo aver aggiunto la direttiva)
  // guardare getTransformedOperationData di dynamic-operation e change-beneficiaries

  @Input() public value: Beneficiary;
  @Input() public items = []; // specifica se la percentuale deve essere mostrata in formato lista o numerico
  @Input() public beneficiaryRoles: BeneficiaryRole[];
  @Input() public showPercentage = true;
  @Input() public reg41CheckBoxVisibility = false;
  @Input() public benDgCheckBoxVisibility = false;
  @Input() public paymentTypes: PaymentTypeDefinition[] = [];
  @Input() public benefPositionNumberConfig: {active: boolean, benefPositionNumberList: Definition[]} = {
    active: false, benefPositionNumberList: []
  };

  @Output() public delete: EventEmitter<Beneficiary> = new EventEmitter<Beneficiary>();

  // RRDL-4705
  @Input() public isBenEditable = true;
  @Input() public isPaymentTypeEditable = true;

  personType: any;

  public beneficiaryRole: BeneficiaryRole;
  public roleCodeToAdd: RoleType = EMPTY_STR;
  private subscriptions: Subscription[] = [];
  partyCompleteError: {subjIdPlusrole: string, messages: string[]}[] = [];

  public get roles(): any {
    return this.formGroup.get('value').get('linkedSubjectsRoles').value;
  }

  get isBenefCedola(): boolean {
    return this.value.type === BeneficiaryCathegory.CEDOLE;
  }

  public formGroup: UntypedFormGroup = new UntypedFormGroup({
    value: new UntypedFormGroup({
      id: new UntypedFormControl(),
      role: new UntypedFormControl(),
      name:  new UntypedFormControl(),
      percentage: new UntypedFormControl(),
      personType:  new UntypedFormControl(),
      adult: new UntypedFormControl(),
      linkedSubjectsRoles: new UntypedFormArray([])
    }),
    type: new UntypedFormControl(),
    code: new UntypedFormControl(),
    idAssicurato: new UntypedFormControl(),
    irrevocable: new UntypedFormControl(),
    expiryCommunication: new UntypedFormControl(),
    severeDisability: new UntypedFormControl(),
    creditPayment: new UntypedFormControl(),
    positionNumberCode: new UntypedFormControl()
  });

  constructor(protected anagService: AnagService, protected decPipe: RgiCtryLayerNumFormatterPipe) {}

  ngOnInit(): void {
    // RDDL-4705 (The disable method delete the field from the request)
    /*if (!this.editable) {
      this.formGroup.get('value').get('percentage').disable();
    }*/
    this.formGroup.valueChanges.subscribe((value) => {
      this.writeValue(value);
      this.onChange(value);
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.value) {
      const value: Beneficiary = changes.value.currentValue;
      this.formGroup.setValue(value, {emitEvent: false});
    }
  }

  public deleteSubRole(index: string, event: Role) {
      const linkedRoles = this.formGroup.get('value').get('linkedSubjectsRoles') as UntypedFormArray;
      this.removeMessagesByRole(event.id, event.role);
      linkedRoles.removeAt(+index);
  }

  ngAfterViewInit(): void {
    this.personType = this.formGroup.get('value').get('personType').value;
    if (!!this.beneficiaryRoles) {
      this.beneficiaryRole = this.beneficiaryRoles
    .find(role => role.availForType.toString() === this.personType.toString());
    }
  }

  public trackFn(index: any, item: Role) {
    if ((item as Role).id) {
      return (item as Role).id;
    } else {
      return index;
    }
  }

  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: any): void {}

  public setDisabledState(isDisabled: boolean): void {
    if (isDisabled) {
      this.formGroup.disable({emitEvent: false});
    } else {
      this.formGroup.enable({emitEvent: false});
    }
  }

  public writeValue(obj: Beneficiary): void {
    if (!!obj) {
      this.value = obj;
      this.formGroup.patchValue(obj, {emitEvent: false});
      if (!(this.formGroup.get('value').get('linkedSubjectsRoles') as UntypedFormArray).length) {
        ((obj.value as Role).linkedSubjectsRoles as []).forEach(subject => {
          (this.formGroup.get('value').get('linkedSubjectsRoles') as UntypedFormArray).push(
            new UntypedFormControl(subject)
          );
        });
      }
    }
  }

  onChange(obj: Beneficiary) {}

  get getBenDgCheckBoxVisibility() {
    return this.benDgCheckBoxVisibility && this.personType === BeneficiaryType.PHISICAL;
  }

  public deleteBen(value: Beneficiary) {
    this.removeMessagesByRole((value.value as Role).id, (value.value as Role).role);
    this.delete.emit(value);
  }

  public getValueForDataQA(element: string): string {
    return element + '-benef-' + this.formGroup.get('type').value + this.formGroup.get('value').get('name').value;
  }

  public canAddEffectiveHolders(): boolean {
    if (!!this.beneficiaryRole) {
      const maxCardinality = this.beneficiaryRole.maxCardinality;
      const subBeneficiaries = this.roles;
      return (subBeneficiaries.length < maxCardinality);
    }
  }

  public openAnagSubjectModal(roleCode: RoleType) {
    this.roleCodeToAdd = roleCode;
    this.anagService.openSubjectModal(this);
  }

  public validate(control: AbstractControl): { [key: string]: boolean } | null {
    const errors: { [key: string]: boolean } = {};
    if (!!this.beneficiaryRoles &&  control.value.value.personType === '2' ) {
      const linkedSubjectsRoles = control.value.value.linkedSubjectsRoles as Role[];
      if (!linkedSubjectsRoles.length) {
        Object.assign(errors, {minLinkedSubjectRoles: true});
      } else {
        // validazione soggetti minorenni
        if (linkedSubjectsRoles.some(lsr => {
          return !lsr.adult && lsr.personType !== '2';
        })) {
          const linkedNames = {
            name: linkedSubjectsRoles.find(linked => !linked.adult && linked.personType !== '2').name,
            role: this.beneficiaryRole.label,
            benef: control.value.value.name
          };

          Object.assign(errors, {underAgeLinkedSubjectRoles: linkedNames});
        }

        // validazione soggetto giuridico
        if (linkedSubjectsRoles.some(lsr => lsr.personType === '2')) {
          const linkedNames = {
              name: linkedSubjectsRoles.find(linked => linked.personType === '2').name,
              role: this.beneficiaryRole.label,
              benef: control.value.value.name
            };
          Object.assign(errors, {giuridicalLinkedSubjectRoles: linkedNames});
        }

        // validazione sottoruoli duplicati
        const linkedDuplicate = linkedSubjectsRoles.find((subject, i) => {
          return linkedSubjectsRoles.find((x, ii) => x.id === subject.id && i !== ii );
        });
        if (linkedDuplicate) {
          const linkedNames = {
            name: linkedDuplicate.name,
            benef: control.value.value.name
          };
          Object.assign(errors, {duplicatesLinkedSubjectRoles: linkedNames});
        }

        // validazione percentuale globale
        const percentageAmount: number = linkedSubjectsRoles
            .map(subject => {
                return Number(subject.percentage);
            })
            .reduce((previous, current) => previous + current);
        if (!!this.beneficiaryRole && this.beneficiaryRole.percentage && !!percentageAmount && percentageAmount > 100.0) {
            Object.assign(errors, {linkedSubjectRolesPercentageAmount: true});
        }

        // RDDL-3676 -> se non ho la percentuale non ha senso controllare la percentuale minima
        if (!!this.beneficiaryRole && this.beneficiaryRole.percentage &&  linkedSubjectsRoles.some(lsr => {
          return lsr.percentage == null || lsr.percentage === '';
        })) {
          const linkedNames = {
            name: linkedSubjectsRoles.find(linked => linked.percentage == null || linked.percentage === '').name,
            benef: control.value.value.name
          };
          Object.assign(errors, {missingPercentageLinkedSubjectRoles: linkedNames});
        } else {
          if (this.beneficiaryRole && !!this.beneficiaryRole.percentage && !!this.beneficiaryRole.minPercentage && linkedSubjectsRoles
            .some(linked => +linked.percentage < this.beneficiaryRole.minPercentage)) {
              const decimalNumberFormatterOptions: Intl.NumberFormatOptions = {
                style: 'decimal',
                maximumFractionDigits: 2,
                minimumFractionDigits: 2
              };
              const linkedNames = linkedSubjectsRoles.filter(linked => +linked.percentage < this.beneficiaryRole.minPercentage).map(lsr => {
                return {
                  benef: control.value.value.name,
                  name: lsr.name,
                  minPerc: this.decPipe.transform(this.beneficiaryRole.minPercentage, '', decimalNumberFormatterOptions)
                };
              });
              Object.assign(errors, {
                errorMinPercentageLinkedSubjectRoles: linkedNames
              });
            }
        }


      }
      if (!!this.partyCompleteError.length) {
        Object.assign(errors, {partycomplete: this.partyCompleteError});
      }
    }
    return errors;
  }


  public receiveAnagSubjectFromModal(subject: AnagSubject) {
    const role: Role = AnagService.subjectToRole(subject, Roles.EFFECTIVEHOLDER_BENEF);

    this.subscriptions.push(
      this.anagService.checkPartyCompleted(
        Number(subject.objectId),
        Number(subject.idLatestPhotos),
        role.role,
        Number(this.anagService.managementNode)
      ).subscribe(res => {
        if (res.result === PARTY_COMPLETE_KO) {
          const roleDescr = this.beneficiaryRole.label;
          this.partyCompleteError.push({
            subjIdPlusrole: `${subject.objectId}-${role.role}`,
            messages: res.outcome.map(m => `${roleDescr} ${subject.nominative}: ${m}`)
          });
        } else {
          this.removeMessagesByRole(subject.objectId, role.role);
        }
        this.pushNewBenefToForm(role);
        this.formGroup.updateValueAndValidity();
      })
    );
  }

  private removeMessagesByRole(subjId: string, role: RoleType) {
    this.partyCompleteError = this.partyCompleteError.filter(m => m.subjIdPlusrole !== `${subjId}-${role}`);
  }

  private pushNewBenefToForm(role: Role) {
    (this.formGroup.get('value').get('linkedSubjectsRoles') as UntypedFormArray).push(new UntypedFormControl({
      id: role.id,
      role: Roles.EFFECTIVEHOLDER_BENEF,
      name: role.name,
      percentage: role.percentage,
      personType: role.personType,
      adult: role.adult,
      linkedSubjectsRoles: []
    }));
  }

  public onChangePayment(payment) {}

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }
}
