import { LpcTranslationsKeys } from './lpc-translations-keys';
/* eslint-disable max-len */

/**
 * !! IMPORTANT !!
 * Add a new key on the bottom of the list and start declaring from the LpcTranslationsKeys
 *
 * @description
 * This is the list of all the translations keys used in the application for the life-postsales-card library.
 */
export const LPC_IT: LpcTranslationsKeys = {
  lpc_Investment_profiles: 'Profili di investimento',
  lpc_payment_method: 'Modalità di pagamento',
  lpc_investment_funds: 'Fondi di investimento',
  lpc_fund_type: 'Tipo Fondo',
  lpc_fields_generic_error: 'I campi obbligatori sono incompleti o contengono valori errati',
  lpc_profile_type: 'Tipo Profilo',
  lpc_elements: ' elementi',
  lpc_fund_name: 'Nome Fondo',
  lpc_profile_name: 'Nome Profilo',
  lpc_occurenceNumber: 'Il valore delle Occorrenze deve essere maggiore o uguale a {{min}}',
  lpc_profiles: 'Profili',
  lpc_funds: 'Fondi',
  lpc_division_type: 'Tipo di ripartizione',
  lpc_division_description: 'Descrizione ripartizione',
  lpc_start_validity_date: 'Data inizio validità',
  lpc_fund_description: 'Descrizione Fondo',
  lpc_ISIN_code: 'Codice ISIN',
  lpc_Allocation: 'Ripartizione',
  lpc_profile_details: 'Caratteristiche del profilo',
  lpc_portfolio_fund_display: 'Esposizione del fondo nel portafoglio',
  lpc_payment_data: 'Dati di pagamento',
  lpc_Warning_you_are_deleting_the_contractor: 'Attenzione si sta effettuando l\'eliminazione del Contraente.  Si desidera procedere?',
  lpc_Investment_amount: 'Valore dell\'investimento:',
  lpc_mov_status: 'Stato Titolo',
  lpc_mov_Amount: 'Importo Titolo',
  lpc_ticket_status_label: 'In attesa valorizzazione quote',
  lpc_status_investment_label: 'Stato Investimento',
  lpc_order_status_label: 'Stato Ordine',
  lpc_selected_funds: 'Fondi selezionati',
  lpc_Premium: 'Premio',
  lpc_InvestmentByFund: 'Investimento per fondo',
  lpc_Loading: 'Caricamenti',
  lpc_NetPremium: 'Premio Netto',
  lpc_IntFract: 'Int. Fraz.',
  lpc_Fees: 'Diritti',
  lpc_Taxes: 'Imposte',
  lpc_GrossPremium: 'Premio Lordo',
  lpc_FirstInstallment: 'Rata alla Firma',
  lpc_AnnualPremium: 'Premio Annuale',
  lpc_NextInstallment: 'Rata Successiva',
  lpc_Guarantee: 'Garanzia',
  lpc_BenefitOnDate: 'Prestazione alla data',
  lpc_Benefit: 'Prestazione',
  lpc_AnnualSinglePremium: 'Premio Annuo/Unico',
  lpc_ActiveGuarantees: 'Garanzie Attive',
  lpc_Enhancement: 'Valorizzazione',
  lpc_CalculationData: 'Data di calcolo',
  lpc_ControvaloreAllaData: 'Controvalore alla data',
  lpc_AccumulationOfPrizes: 'Cumulo Premi',
  lpc_TotalSurrender: 'Importo da Riscattare',
  lpc_AccumulationOfReducedPremiums: 'Cumulo Premi Abbattuti',
  lpc_TotalBalance: 'Totale a saldo',
  lpc_EffectiveDate: 'Data effetto',
  lpc_Movement: 'Movimento',
  lpc_Movements: 'Movimenti',
  lpc_booking_number: 'Numero prenotazione',
  lpc_booking_policyData: 'Dati di Polizza',
  lpc_booking_assetData: 'Dati di Bene',
  lpc_PolicyLinked: 'Polizze Collegate',
  lpc_Motivo_Legame: 'Tipo Legame',
  lpc_ProposalIssueDate: 'Data di Emissione Proposta',
  lpc_PolicyIssueDate: 'Data di Emissione Polizza',
  lpc_ExpirationDate: 'Data scadenza',
  lpc_NextInstalmentDate: 'Data prossima quietanza',
  lpc_found_duplicate: 'Attenzione',
  lpc_duplicate_subject_message: 'Soggetto selezionato già presente tra i beneficiari',
  lpc_SelectSettlement: 'Selezione Liquidazione',
  lpc_You_must_select_the_settlement_to_perform_the_variation: 'è necessario selezionare la liquidazione su cui effettuare la variazione',
  lpc_beneficiaryPercentage: 'La percentuale del beneficiario',
  lpc_beneficiaryPercentage_greater: 'deve essere maggiore di',
  lpc_beneficiaryPercentage_lower: 'deve essere minore di',
  lpc_beneficiaryDuplicate: 'Soggetto duplicato',
  lpc_NoOperations: 'Nessuna azione disponibile',
  lpc_confirm: 'Conferma',
  lpc_description: 'Descrizione',
  lpc_issueDate: 'Data emissione',
  lpc_effectiveDate: 'Data effetto',
  lpc_MovementData: 'Annullo Appendice',
  lpc_Are_you_sure_you_want_to_confirm_this_claim: 'Sei sicuro di voler confermare questo sinistro?',
  lpc_Are_you_sure_you_want_to_cancel_this_claim: 'Sei sicuro di voler annullare questo sinistro?',
  lpc_warnings: 'Segnalazioni',
  lpc_warning: 'Segnalazione',
  lpc_warning_message: 'Se si decide di proseguire, l\'operazione sarà inviata in autorizzazione',
  lpc_ClaimCanceled: 'Sinistro annullato correttamente',
  lpc_ClaimConfirmed: 'Sinistro confermato correttamente',
  lpc_reserve: 'salva',
  lpc_questionnaire: 'Questionario',
  lpc_bonus_amount: 'Importo bonus',
  lpc_life_detail_beneficiary: 'Beneficiario',
  lpc_life_detail_percentage: 'Percentuale',
  lpc_life_detail_net_amount: 'Importo netto',
  lpc_life_detail_questionnaire: 'Questionario',
  lpc_life_detail_beneficiaries: 'Beneficiari',
  lpc_AppendixNumber: 'Numero Appendice',
  lpc_nessuna_variazione: 'Nessuna variazione',
  lpc_appendixLabel: 'Appendice Beneficiario',
  lpc_operation_rescission_reason: 'Motivo Rescissione',
  lpc_Lien_type_creditor: 'Tipo ente creditore',
  lpc_creditor: 'Ente creditore',
  lpc_Open_lien: 'Apertura pegno',
  lpc_assignment_holder_type: 'Tipo vincolatario',
  lpc_assignment_holder: 'Ente vincolatario',
  lpc_assignment_amount: 'Importo',
  lpc_assignment_policy_number: 'Numero di contratto',
  lpc_assignment_company: 'Compagnia',
  lpc_assignment_type: 'Tipo Vincolo',
  lpc_assignment_benefit: 'Prestazione di polizza',
  lpc_amount_less_of_benefit: 'L\'importo deve essere minore rispetto alla prestazione di polizza.',
  lpc_open_assignment: 'Apertura vincolo',
  lpc_sum_det_cancel_proposal: 'annulla proposta',
  lpc_sum_det_success_cancel: 'Proposta annullata correttamente',
  lpc_sum_det_success_cancel_prev: 'Preventivo annullato correttamente',
  lpc_sum_det_confirm_cancel: 'Vuoi confermare l\'annullamento della proposta?',
  lpc_sum_det_confirm_cancel_prev: 'Vuoi confermare l\'annullamento del preventivo?',
  lpc_sum_det_confirm_prop_issue: 'Vuoi confermare l\'emissione della proposta?',
  lpc_sum_det_confirm_pol_issue: 'Vuoi confermare l\'emissione della polizza?',
  lpc_health_questionnaire: 'Questionari di bene',
  lpc_quotation_show_details: 'Visualizza',
  lpc_quotation_premium_details: 'Dettaglio premio',
  lpc_quotation_warranties_details: 'Riepilogo garanzie',
  lpc_quotation_commissions_details: 'Provvigioni',
  lpc_Reference_Third_Party: 'Referente Terzo',
  lpc_DelegateEqualToPolicyHolder: 'Delegato uguale al contraente ?',
  lpc_policyHolder: 'Contraente',
  lpc_LegalGuardianEqualToPolicyHolder: 'Tutore legale uguale al contraente ?',
  lpc_ThirdPayerEqualToPolicyHolder: 'Terzo pagatore uguale al contraente ?',
  lpc_DelegateEqualToPolicyHolderWarning: 'Soggetto inserito per ruolo Delegato uguale al contraente',
  lpc_LegalGuardianEqualToPolicyHolderWarning: 'Soggetto inserito per ruolo Tutore legale uguale al contraente',
  lpc_ThirdPayerEqualToPolicyHolderWarning: 'Soggetto inserito per ruolo Terzo pagatore uguale al contraente',
  lpc_but_confirm_accept: 'conferma e accetta',
  lpc_Date_Start_Variation: 'Data Inizio Variazione',
  lpc_New_Frequent_Payment: 'Nuova frequenza pagamento',
  lpc_start_date_profit: 'Data inizio rendimento',
  lpc_end_date_profit: 'Data ultimo rendimento',
  lpc_last_rate_profit: 'Ultimo rendimento',
  lpc_last_profit: 'Ultimo profitto',
  lpc_Fund_Code: 'Codice Fondo',
  lpc_OperationSynstesis: 'Sintesi Operazione',
  lpc_PreviusPerceent: 'Percentuale Precedente',
  lpc_PreviusValue: 'Controvalore Precedente',
  lpc_NumberOfUnit: 'Numero di Quote Precedenti',
  lpc_PostPErcent: 'Percentuali successive',
  lpc_PostValue: 'Controvalore successivo',
  lpc_PostNumberOfUnit: 'Numero di Quote successive',
  lpc_Dati: 'Dati',
  lpc_PIP_Data: 'Dati di Pip',
  lpc_PIP_General: 'Dati Generali',
  lpc_PIP_Contribution: 'Contribuzioni PIP',
  lpc_HolderData: 'Dati Assicurato',
  lpc_RiskData: 'Dati di rischio',
  lpc_IBAN_error: 'Il campo iban non è corretto',
  lpc_BACK: 'Indietro',
  lpc_ACTIONS: 'Azioni',
  lpc_DETAIL: 'Dettaglio',
  lpc_CANCEL: 'Annulla Proposta',
  lpc_CONFIRM_PROPOSAL: 'Conferma Proposta',
  lpc_CONFIRM_POLICY: 'Conferma Polizza',
  lpc_not_linked_detail: 'Dettaglio {{name}}',
  lpc_quote_number: 'Numero preventivo',
  lpc_valid_quote: 'Preventivo valido',
  lpc_BUY_PREV: 'Acquista',
  lpc_EDIT_PREV: 'Modifica',
  lpc_EDIT: 'Modifica',
  lpc_CANCEL_PREV: 'Annulla',
  lpc_modify_quote: 'Modifica preventivo',
  lpc_modify_proposal: 'Modifica proposta',
  lpc_buy_quote: 'Acquista preventivo',
  lpc_Close_assignment: 'Chiusura vincolo',
  lpc_Close_lien: 'Chiusura pegno',
  lpc_disinvestment_funds: 'Fondi di disinvestimento',
  lpc_error_mandatory_fields: 'I campi marcati in rosso sono obbligatori o contengono valori non corretti',
  lpc_disinvestment_msg: 'Rimane {{amount}} da disinvestire, oppure {{percent}}',
  lpc_disinvestment_allocation_msg: 'L\'allocazione dell\'investimento supera l\'importo disponibile',
  lpc_maximum_percentage: 'Percentuale massima accettata',
  lpc_maximum_amount: 'Importo massimo accettato',
  lpc_minimum_percentage: 'Percentuale minima accettata',
  lpc_minimum_amount: 'Importo minimo accettato',
  lpc_investment_msg: 'Rimane {{amount}} da investire, oppure {{percent}}',
  lpc_Fields_marked_in_red_are_required: 'I campi marcati in rosso sono obbligatori',
  lpc_amount_format_validation: 'Il formato del importo è invalido, contiene il separatore decimale sbagliato usare \'.\'',
  lpc_select_at_least_one_beneficiary: 'Selezionare almeno un beneficiario',
  lpc_quote_due_date: 'Data scadenza preventivo',
  lpc_roles_variation: 'Ruoli',
  lpc_fiscal_data: 'Dati Fiscali',
  lpc_data_calc: 'Data calcolo',
  lpc_tipo_liquidazione: 'Tipo di liquidazione',
  lpc_valore_polizza: 'Valore polizza',
  lpc_imponibile_imp: 'Imponibile imposta',
  lpc_imposta_base: 'Imposta base',
  lpc_imposta_calcol: 'Imposta calcolata',
  lpc_imposta_da_add: 'Imposta da addebitare',
  lpc_imposta_add: 'Imposta addebitata',
  lpc_imposta_bollo: 'Imposta bollo',
  lpc_fiscal_data_detail: 'Dettaglio Dati Fiscali',
  lpc_quote_white_list: 'Quote white list',
  lpc_data_rif: 'Data riferimento',
  lpc_tipo_perc: 'Percentuale WL',
  lpc_CancellInstallment: 'Riepilogo richiesta annullo rata',
  lpc_DescOperazione: 'Descrizione operazione',
  lpc_RateAmount: 'Importo totale rata',
  lpc_OPEN_LATEST_VERSION: 'Apri ultima versione',
  lpc_TOTAL_INVESTED: 'Totale investito',
  lpc_REMAINING_AMOUNT: 'Residuo',
  lpc_descrizione_rischio: 'Descrizione Rischio',
  lpc_prestazione_ridotta_iniz: 'Prestazione ridotta iniziale',
  lpc_prestazione_rivalutata: 'Prestazione ridotta rivalutata',
  lpc_provvigioni_da_recuperare: 'Provvigioni da recuperare',
  lpc_reg41: 'Nominativo a cui inviare le comunicazioni',
  lpc_disinvestment_amount: 'Importo da disinvestire',
  lpc_occurrenceNumber: 'Numero Occorrenze',
  lpc_frequecy: 'Frequenza',
  lpc_the_occurenceNumber_must_be_a_value: 'Il valore delle Occorrenze deve essere maggiore o uguale a {{min}}',
  lpc_disinvestment_range: 'Inserire un importo da disinvestire maggiore di {{min}} e minore o uguale a {{max}}',
  lpc_state: 'Stato',
  lpc_substate: 'Sottostato',
  lpc_product_type: 'Tipo Prodotto',
  lpc_managementnode: 'Nodo di gestione',
  lpc_subagency: 'Subagenzia',
  lpc_branch_name: 'Filiale',
  lpc_agreement: 'Convenzione',
  lpc_payment_frequency: 'Frazionamento',
  lpc_mean_of_payment: 'Modalità di Pagamento',
  lpc_Subcause: 'Sottocausale',
  lpc_premium_payment_suspension_date: 'Data Sospensione Pagamento Premi',
  lpc_imposta_sostitutiva: 'Imposta sostitutiva',
  lpc_novazione_contr: 'Novazione contrattuale',
  lpc_acc_premium_initial_2011: 'Cum. premi 31/12/2011 iniziale',
  lpc_amount_intital_2011: 'Montante 31/12/2011 iniziale',
  lpc_acc_premium_2011: 'Cum. premi 31/12/2011',
  lpc_amount_2011: 'Montante 31/12/2011',
  lpc_acc_premium_initial_2014: 'Cum. premi 30/06/2014 iniziale',
  lpc_acc_premium_2014: 'Cum. premi 30/06/2014',
  lpc_amount_intital_2014: 'Montante 30/06/2014 iniziale',
  lpc_amount_2014: 'Montante 30/06/2014',
  lpc_acc_premium_initial_2022: 'Cum. premi 31/12/2022 iniziale',
  lpc_acc_premium_2022: 'Cum. premi 31/12/2022',
  lpc_amount_intital_2022: 'Montante 31/12/2022 iniziale',
  lpc_amount_2022: 'Montante 31/12/2022',
  lpc_acc_premium_intial_tax_change: 'Cum. premi cambio fiscalità iniziale',
  lpc_amount_initial_tax_change: 'Montante cambio fiscalità iniziale',
  lpc_acc_premium_tax_change: 'Cum. premi cambio fiscalità',
  lpc_amount_tax_change: 'Montante cambio fiscalità',
  lpc_regime_change_date: 'Data cambio fiscalità',
  lpc_please_enter_a_disinvestment_amount: 'Inserire un importo di disinvestimento per il fondo: {{fund}}',
  lpc_please_select_at_least_a_disinvestment: 'Selezionare almeno un fondo di disinvestimento per procedere correttamente con l\'investimento',
  lpc_reg41Msg: 'Beneficiario destinatario delle comunicazioni',
  lpc_cost: 'Caricamenti',
  lpc_paymentFrequencyInterest: 'Int. Fraz.',
  lpc_netpremium: 'Premio Netto',
  lpc_grosspremium: 'Premio Lordo',
  lpc_information: 'Informazioni',
  lpc_reinvest_data_detail: 'Dettaglio Reinvestimento',
  lpc_polizza: 'Polizza',
  lpc_data_effetto_mov: 'Data effetto',
  lpc_netto_liquidazione: 'Importo netto Liquidazione',
  lpc_netto_reinvestire: 'Importo netto da Reinvestire',
  lpc_stato_reinvest: 'Stato liquidazione reinvestimento',
  lpc_reinvested_settlements: 'Liquidazioni reinvestite',
  lpc_loans: 'Prestiti',
  lpc_elaboration_date: 'Data elaborazione',
  lpc_calculation_date: 'Data calcolo',
  lpc_annuity_data: 'Dati di rendita',
  lpc_extra_premium: 'Sovrappremio',
  lpc_loan_interest: 'Interessi di prestito',
  lpc_fractionation_interest: 'Interessi di frazionamento',
  lpc_default_interest: 'Interessi di mora',
  lpc_reactivation_interest: 'Interessi di riattivazione',
  lpc_installment_detail: 'Dettaglio rata',
  lpc_effectiveAnnuityDate: 'Data Decorrenza Rendita',
  lpc_firstInstallmentDate: 'Data Prima Rata',
  lpc_expiryAnnuityDate: 'Data Scadenza Rendita',
  lpc_amountFirstInstallment: 'Importo Prima Rata',
  lpc_initialAnnuityAmount: 'Importo Rendita Iniziale',
  lpc_periodicityInstallmentAnnuity: 'Periodicità Rata Rendita',
  lpc_typePaymentAnnuity: 'Tipo Pagamento Rendita',
  lpc_performanceType: 'Tipo Prestazione',
  lpc_annuityType: 'Tipologia Rendita',
  lpc_annuity_detail: 'Dettaglio Rendita',
  lpc_canceled_liquidation_detail: 'Dettaglio liquidazione annullata',
  lpc_Percentage: 'Percentuale',
  lpc_loan_data: 'Dati prestito',
  lpc_maxLoanAmount: 'Importo massimo erogabile',
  lpc_loanRepaymentProcedure: 'Modalità restituzione',
  lpc_loanAmount: 'Importo prestito',
  lpc_rateType: 'Tipo tasse interesse',
  lpc_maxLoanAmountKO: 'è 0 (errore nel calcolo del importo massimo erogabile)',
  lpc_grant_date: 'Data concessione',
  lpc_loan_amount: 'Importo prestito',
  lpc_return_way: 'Modo restituzione',
  lpc_interest_rate_type: 'Tipo tasso interesse',
  lpc_residual_amount: 'Importo residuo',
  lpc_refund_type: 'Tipo rimborso',
  lpc_refund_amount: 'Importo rimborso',
  lpc_select_lpc_refund_type: 'Seleziona un Tipo rimborso',
  lpc_leftInsuredAmount: 'Capitale Assicurato Residuo',
  lpc_insuredAmount: 'Capitale Assicurato',
  lpc_reactivatedCapital: 'Capitale Riattivato',
  lpc_premium_reactivation: 'Premio riattivazione',
  lpc_negative_amount: 'Importo residuo negativo',
  lpc_zero_amount: 'Importo residuo non presente, inserire un importo maggiore di 0',
  lpc_error_mandatory_selected_type_of_refund: 'Selezionare un tipo di rimborso',
  lpc_select_refund: 'Selezionare almeno un prestito',
  lpc_selected_profiles: 'Profili selezionati:',
  lpc_coupon_beneficiary: 'Beneficiario cedola',
  lpc_bookings: 'Prenotazioni',
  lpc_booking_type: 'Tipo prenotazione',
  lpc_booking_state: 'Stato',
  lpc_booking_effective_date: 'Data effetto',
  lpc_booking_extrapremiumHealth: 'Sovrappremio sanitario',
  lpc_booking_extrapremiumProf: 'Sovrappremio professionale',
  lpc_booking_extrapremiumSport: 'Sovrappremio sportivo',
  lpc_booking_extrapremiumOther: 'Sovrappremio altro',
  lpc_booking_effectiveDate: 'Data effetto',
  lpc_booking_detail_label: 'Dettaglio della prenotazione',
  lpc_Rischi: 'Rischi',
  lpc_booking_destinationNode: 'Nodo di destinazione',
  lpc_booking_cause: 'Causale: ',
  lpc_PolicyData: 'Dati di Polizza',
  lpc_booking_cancellationDate: 'Data di cancellazione',
  lpc_extra_premiums: 'Sovrappremi',
  lpc_error_reservation_cancel: 'Errore nella cancellazione della {{descr}} numero {{num}}',
  lpc_success_reservation_cancel: 'La cencellazione della {{descr}} numero {{num}} à andata a buon fine',
  lpc_incomplete_disinvestment_for_the_funds: 'Disinvestimento incompleto, prego procedere a completare il disinvestimento per i Fondi di disinvestimento',
  lpc_Sinistro_morte: 'Sinistro morte',
  lpc_Sinistro_invalidita: 'Sinistro invalidità',
  lpc_Sinistro_inabilità: 'Sinistro inabilità',
  lpc_Malattia_grave: 'Malattia grave',
  lpc_insured_form: 'Forma assicurativa',
  lpc_other_contract_list: 'Altre polizze',
  lpc_modified_personal_data_for_the_subject: 'Dati anagrafici modificati per il soggetto',
  lpc_subject_roles: 'Soggetti',
  lpc_no_variation_modpd: 'Nessuna variazione effettuata, modificare un soggetto',
  lpc_DeductiblePremium: 'Premio detraibile',
  lpc_firstterm: 'Data attivazione pagamento premi',
  lpc__1TERM_ATOOL: 'Data attivazione tool',
  lpc__1TERM_MTOOL: 'Data modifica tool',
  lpc__1TERM_DTOOL: 'Data disattivazione tool',
  lpc_management_fees: 'Spese di gestione',
  lpc_investment_not_found: 'Non sono presenti elementi da selezionare',
  lpc_No_policies: 'Non sono presenti polizze per il soggetto variato',
  lpc_variation_convention: 'Variazione convenzioni',
  lpc_andamento_polizza_alla_data: 'Andamento polizza alla data',
  lpc_appendice_dichiarativa: 'Appendice Dichiarativa',
  lpc_policy_number_dot: 'Polizza n. ',
  lpc_investment_sum_must_be100: 'La somma degli investimenti deve essere uguale a 100%',
  lpc_Operation_detail: 'Dettagli delle operazioni',
  lpc_Notwithstanding_any_ongoing_operations: 'Fatte salve eventuali operazioni in corso',
  lpc_percentage_max_zero: 'inserire una percentuale maggiore di zero',
  lpc_Mostra_tutti: 'Mostra tutti',
  lpc_Visualizza_dettaglio: 'Visualizza dettaglio',
  lpc_consultazione_storica: 'Consultazione storica',
  lpc_dettaglio_movimento: 'Dettaglio movimento',
  lpc_mensile: 'mensile',
  lpc_annuale: 'annuale',
  lpc_sixmonthly: 'six-monthly',
  lpc_trimestrale: 'trimestrale',
  lpc_Titolo_non_incassato: 'Titolo non incassato?',
  lpc_Rid: 'Rid?',
  lpc_In_attesa_valorizzazione_quote: 'In attesa valorizzazione quote',
  lpc_switch_di_perequazione: 'Switch di perequazione?',
  lpc_SELEZIONA: '-- SELEZIONA --',
  lpc_settimanale: 'lpc_settimanale',
  lpc_Valido: 'Valido',
  lpc_quotidiano: 'quotidiano',
  lpc_Assegno: 'Assegno',
  lpc_Bonifico: 'lpc_Bonifico',
  lpc_Sdd: 'Sdd',
  lpc_severeDisabilityMsg: 'Beneficiario affetto da disabilità grave',
  lpc_benDg: 'Beneficiario disabile',
  lpc_distribution: 'Modalità ripartizione',
  lpc_Distribution_amount_exceeds: 'L\'importo della ripartizione supera l\'importo della liquidazione selezionata',
  lpc_distribution_invalid_greater: '{{type}} non corretto, deve essere maggiore di {{min}}',
  lpc_distribution_invalid_greater_equal: '{{type}} non corretto: deve essere minore o uguale a {{max}}',
  lpc_perc: 'Percenutale',
  lpc_amount: 'Importo',
  lpc_Please_insert_a_distribution: 'Inserire una ripartizione',
  lpc_For_the_Beneficiary: 'Per il Beneficiario',
  lpc_global_distribution: 'L\'importo totale da liquidare deve essere minore o uguale a {{totalAmount}}',
  lpc_invalid_char: 'Il formato del campo {{type}} contiene caratteri errati',
  lpc_toDistribute: 'da ripartire',
  lpc_noClaims: 'Non è stata trovata nessuna denuncia',
  lpc_Data_sinistro: 'Data sinistro',
  lpc_Data_Pervenimento_Denuncia: 'Data Pervenimento Denuncia',
  lpc_Data_Denuncia_Sinistro: 'Data Denuncia Sinistro',
  lpc_Tipo_sinistro: 'Tipo sinistro',
  lpc_inserisci_denuncia: 'inserisci denuncia',
  lpc_Conferma_inserimento_denuncia: 'Conferma inserimento denuncia',
  lpc_Denuncia_inserita_correttamente: 'Denuncia inserita correttamente',
  lpc_Stato_denuncia: 'Stato denuncia',
  lpc_marital_status: 'Stato Civile',
  lpc_fiscal_code: 'Codice Fiscale',
  lpc_birthdate: 'Data di Nascita',
  lpc_address: 'Residenza',
  lpc_sex: 'Sesso',
  lpc_annulla_denuncia: 'annulla denuncia',
  lpc_modifica_denuncia: 'modifica denuncia',
  lpc_conferma_denuncia: 'conferma denuncia',
  lpc_new_beneficiary: 'Nuovo beneficiario',
  lpc_Seleziona: 'Seleziona',
  lpc_insert_phisical_sbj_for: 'Inserire un soggetto fisico per il ruolo {{role}}',
  lpc_insert_legal_sbj_for: 'Inserire un soggetto giuridico per il ruolo {{role}}',
  lpc_payment_incomplete: 'Dati di pagamento incompleti',
  lpc_add_mandatory_linkedroles: 'Inserire i ruoli obbligatori per il soggetto inserito',
  lpc_beneficiary_owner_recipient_must_be_physical: 'Il titolare effettivo del percipiente deve essere fisico',
  lpc_enter_the_beneficial_owners_of_the_recipient: 'Inserire i titolari effettivi del percipiente',
  lpc_global_percentage_must_be_a_maximum_100: 'la percentuale globale deve essere massimo 100',
  lpc_overall_percentage_must_equal100: 'la percentuale globale deve essere uguale a 100',
  lpc_Questionario_deve_essere_compilato: 'Questionario deve essere compilato',
  lpc_enter_mandatory_data: 'Inserire i dati obbligatori',
  lpc_enter_an_adult: 'Inserire un soggetto maggiorenne',
  lpc_enter_a_beneficial_owner: 'Inserire un titolare effettivo',
  lpc_enter_a_physical_beneficial_owner: 'Inserire un titolare effettivo di tipo fisico',
  lpc_global_percentage_of_beneficial_owners_must_not_exceed_100: 'La percentuale globale dei titolari effettivi non deve essere maggiore di 100',
  lpc_beneficial_owners_percentages_must_be_greater_than0: 'La percentuale del titolare effettivo deve essere maggiore di 0.0',
  lpc_effective_holder_perc: 'La percentuale del titolare effettivo {{linkedName}}, del beneficiario {{benefName}}, non rispetta la percentuale minima di {{linkedPerc}}%',
  lpc_effective_holder_duplicated: 'Titolare effettivo duplicato',
  lpc_enter_the_minimum_number_of_subjects: 'Inserire il numero minimo di soggetti',
  lpc_percentage_must_be_greater_than_0: 'La percentuale deve essere maggiore di 0.0',
  lpc_enter_free_text: 'Inserire testo libero',
  lpc_beneficiary_executor: 'Esecutore del Beneficiario',
  lpc_effective_recipient: 'Effettivo Percipiente',
  lpc_recipient_owner: 'Titolare effettivo del percipiente',
  lpc_beneficial_owner_of_beneficiary: 'Titolare effettivo del beneficiario',
  lpc_confirmed_claim_modify: 'Conferma modifica denuncia',
  lpc_claim_modified_correctly: 'Denuncia modificata correttamente',
  lpc_Assicurato: 'Assicurato',
  lpc_Assicurati: 'Assicurati',
  lpc_holderType: 'Tipo Aderente',
  lpc_ageRetirement: 'Età Pensionamento',
  lpc_accessTransfer: 'Adesione da Trasferimento',
  lpc_emptyContribution: 'Contribuz. Datore Lavoro',
  lpc_firstEmployment: 'Data Prima Occupazione',
  lpc_oldUnderWritt: 'Vecchio iscritto',
  lpc_underWitt1993: 'Iscritto ante 29/4/1993',
  lpc_maturato2001: 'Maturato al 31/12/2000',
  lpc_maturato2006: 'Maturato al 31/12/2006',
  lpc_riduzImponibiTFR: 'Riduz.Imponibi.TFR (fiscalità ante 2001)',
  lpc_aliquotaTFR: 'Aliquota TFR (fiscalità ante 2001)',
  lpc_PIP_Contribution_Type: 'Tipo contributo',
  lpc_YES: 'Si',
  lpc_PIP: 'PIP',
  lpc_PIP_Contribution_Percentage: 'Percentuale',
  lpc_PIP_Contribution_Amount: 'Importo',
  lpc_transfer_data: 'Dati Trasferimento',
  lpc_unit_questionnaire: 'Questionario di garanzie',
  lpc_amount_reimbured: 'Importo da rimborsare',
  lpc_Descr_not_handled: 'Descrizione non gestito per questo codice operazione',
  lpc_select_transfer: 'Selezione trasferimento',
  lpc_fiscalFolderDataPost2006: 'Fiscalità Post 2006',
  lpc_fiscalFolderData20012006: 'Fiscalità 2001 - 2006',
  lpc_fiscalFolderDataBefore2001: 'Fiscalità Ante 2001',
  lpc_filledOut: 'Compilato',
  lpc_blank: 'Non compilato',
  lpc_desc_previousPenFund: 'Descrizione forma pensionistica di provenienza',
  lpc_companyPreviousPenFund: 'Azienda Forma Pensionistica di provenienza',
  lpc_typePreviousPenFund: 'Descrizione Forma Pensionistica di provenienza',
  lpc_note: 'Note',
  lpc_Benef_irrevocabile: 'Beneficiario irrevocabile',
  lpc_PIP_Transfer: 'Trasferimenti da altro fondo',
  lpc_requestDate: 'Data richiesta',
  lpc_Type_Previous_Pension_Fund: 'Tipo forma pensionistica di provenienza',
  lpc_Previous_Pension_Fund: 'Forma pensionistica di provenienza',
  lpc_Import_Transfert: 'Importo trasferito',
  lpc_Note: 'Note',
  lpc_relief: 'Dettaglio affrancamento 2022',
  lpc_selected_settlement: 'Liquidazione selezionata',
  lpc_EDIT_ANONYMOUS_PREV: 'Modifica',
  lpc_invalid_quote: 'Preventivo Annullato',
  lpc_reasonForRequest: 'Motivo richiesta',
  lpc_advancePaymentAmount: 'Importo anticipazione',
  lpc_repaidAdvance: 'Anticipo rimborsato',
  lpc_PIP_AdvancedPayment: 'Anticipazioni',
  lpc_requestReason: 'Motivo richiesta',
  lpc_notRepaidAmount: 'Importo non reintegrato',
  lpc_fiscalDate: 'Data effetto',
  lpc_previousPension: 'Forma pensionistica di provenienza',
  lpc_fiscalCompetence: 'Fiscalità competenza',
  lpc_settlementStatus: 'Stato liquidazione',
  lpc_repaidAmount: 'Importo anticipazione',
  lpc_advancePaymentTransfer: 'Anticipi da trasferimenti',
  lpc_policy_factors: 'Dati di polizza',
  lpc_asset_factors: 'Dati di bene',
  lpc_Date_Variation: 'Date Variazione',
  lpc_penalties_amount: 'Importo Penali',
  lpc_tax_amount: 'Importo Tasse',
  lpc_warranties_detail: 'Dettaglio per rischio',
  lpc_fixedCharges: 'Diritti',
  lpc_catastrophe: 'Sovrappremio catastrofe',
  lpc_health: 'Sovrappremio Sanitario',
  lpc_other: 'Sovrappremio Altro',
  lpc_professional: 'Sovrappremio Professionale',
  lpc_sports: 'Sovrappremio sportivo',
  lpc_collectionFee: 'Caricamneto Incasso',
  lpc_lambdaFee: 'Caricamento Lambda',
  lpc_managementFee: 'Caricamento Gestione',
  lpc_purchaseFee: 'Caricamento Acquisto',
  lpc_continue_button: 'Continua',
  lpc_confirm_button: 'Conferma',
  lpc_questionSTR_invalid: 'la risposta alla domanda: {{question}}, risulta errata',
  lpc_questionNUM_invalid: 'L\'importo digitato alla domanda: {{question}}, non è corretto',
  lpc_Beneficiaryowner: 'Titolare effettivo del beneficiario',
  lpc_confirm_operation: 'Conferma operazione',
  lpc_financial_questionnaire: 'Questionario Finanziario',
  lpc_avc_questionnaire: 'Questionario AVC',
  lpc_confirm_variation: 'Conferma Variazione',
  lpc_Declarative_Appendix: 'Appendice Dichiarativa',
  lpc_investment_funds_step: 'Fondi di Investimento',
  lpc_disinvestment_profile_step: 'Profili di Disinvestimento',
  lpc_startDate: 'Data inizio',
  lpc_end_date: 'Data fine',
  lpc_six_monthly: 'Semestrale',
  lpc_quarterly: 'Trimestrale',
  lpc_weekly: 'Settimanale',
  lpc_monthly: 'Mensile',
  lpc_annual: 'Annuale',
  lpc_daily: 'Giornaliero',
  lpc_start_date_cannot_be_earlier_than_the_effective_date: 'La data di inizio non può essere precedente alla data effetto {{value}}',
  lpc_start_date_must_be_earlier_than_the_end_date: 'La data di inizio deve essere precedente alla data di fine',
  lpc_contract_value: 'Controvalore di Polizza',
  lpc_invested_amount: 'Premi pagati',
  lpc_funds_details_and_summary: 'Dettagli e sintesi fondo',
  lpc_separate_management_detail: 'Dettaglio Gestione Separata',
  lpc_denuncia_sinistro: 'Denuncia Sinistro',
  lpc_duration_in: 'Durata in',
  lpc_years: 'anni',
  lpc_months: 'mesi',
  lpc_days: 'giorni',
  lpc_policy_number: 'Numero Polizza',
  lpc_proposal_number: 'Numero Proposta',
  lpc_LifeBeneficiary: 'Beneficiario Vita',
  lpc_DeathBeneficiary: 'Beneficiario Morte',
  lpc_NO: 'No',
  lpc_claim_data: 'Dati sinistro',
  lpc_node: 'Nodo',
  lpc_sbj: 'Soggetto',
  lpc_empty: 'svuota',
  lpc_search_result: 'Risultati ricerca',
  lpc_show_elemets: 'Visualizza elementi',
  lpc_insured: 'Assicurato',
  lpc_notification_status: 'Stato Denuncia',
  lpc_nuova_denuncia: 'nuova denuncia',
  lpc_insured_policy: 'Polizze Assicurato',
  lpc_product: 'Prodotto',
  lpc_find: 'Trova',
  lpc_status: 'Status',
  lpc_fund_trend: 'Andamento fondo',
  lpc_payment_type: 'Tipo pagamento',
  lpc_category_fund: 'Profilo / Fondo',
  lpc_equivalent_on_date: 'Controvalore alla Data',
  lpc_quotes_number: 'Numero di Quote',
  lpc_damages_policy: 'Polizza Danni',
  lpc_life_policy: 'Polizza Vita',
  lpc_next_installment_premium: 'Premio Rata Successiva',
  lpc_no_result: 'Nessun risultato',
  lpc_data_value: 'Data Valore Quota',
  lpc_paidAmount: 'Importo pagato',
  lpc_total_gross_surrender: 'Totale riscattato',
  lpc_left_premiums_paid: 'Cumulo premi abbattuti',
  lpc_left_premiums_amount: 'Totale a saldo',
  lpc_portfolio_volatility_on_subscription_date: 'Volatilità portafoglio alla data di sottoscrizione',
  lpc_portfolio_volatility: 'Volatilità portafoglio al',
  lpc_andamento_controvalore: 'Andamento Controvalore di Polizza',
  lpc_policy_trend: 'Andamento Polizza',
  lpc_calculate: 'Calcola',
  lpc_frequency: 'Frequenza',
  lpc_date_variation_step: 'Date Variazione',
  lpc_quotation_step: 'Quotazione',
  lpc_confirm_variation_step: 'Conferma Variazione',
  lpc_financial_questionnaire_step: 'Questionario Finanziario',
  lpc_close_button: 'Chiudi',
  lpc_Operation_of_executed_correctly: 'Operazione {{operation}} eseguita correttamente',
  lpc_AuthorizatioPolicyForwarded: 'La richiesta di autorizzazione per la polizza {{policyNumber}} è stata inoltrata all\'utente competente',
  lpc_are_you_sure_you_want_to_cancel_this_operation: 'Sei sicuro di voler annullare questa operazione?',
  lpc_cancel_button: 'Annulla',
  lpc_cancel_reservation: 'Cancella prenotazione',
  lpc_system_error: 'Errore di sistema',
  lpc_generic_error_title: 'Errore',
  lpc_select_label: 'Seleziona',
  lpc_Warning_you_are_deleting_the_policyholder_Do_you_want_to_proceed: 'Attenzione si sta effettuando l\'eliminazione del Contraente. Si desidera procedere?',
  lpc_factor_must_be_equal: 'Il {{factor}} deve essere uguale a {{amount}}',
  lpc_factor_must_be_greater_than: 'Il {{factor}} deve essere maggiore di {{amount}}',
  lpc_factor_must_be_less_than: 'Il {{factor}} deve essere minore di {{amount}}',
  lpc_Please_enter_threshold_percentage_value: 'Fondo: {{value}} - Inserire soglia percentuale',
  lpc_please_enter_a_value_between: 'Inserire un valore compreso tra {{min}} e {{max}}',
  lpc_variation_step_label: 'Variazione',
  lpc_contractual_option: 'Opzioni Contrattuali',
  lpc_costs_step_label: 'Caricamenti',
  lpc_variation_detail_step_label: 'Dettaglio Variazione',
  lpc_ongoing_operations: 'Fatte salve eventuali operazioni in corso',
  lpc_exchange_rate: 'Tasso di cambio',
  lpc_last_share_value: 'Ultimo valore quota',
  lpc_movement_description: 'Descrizione movimento',
  lpc_settlement_amount: 'Importo liquidazione',
  lpc_Annual_SinglePremium: 'Premio Annuo/Unico',
  lpc_policy_address: 'Recapito di Polizza',
  lpc_holders: 'Intestatari',
  lpc_last_revaluation: 'Data ultima rivalutazione',
  lpc_revalueted_benef: 'Prestazione rivalutata',
  lpc_investment: 'Investimenti',
  lpc_position_number: 'Raggruppamento',
  lpc_invested_premium: 'Premio Investito',
  lpc_operation_details: 'Dettagli delle operazioni',
  lpc_contract: 'Contratto',
  lpc_dis_date: 'Data Disinvestimento',
  lpc_gross_amount: 'Importo lordo',
  lpc_net_amount: 'Importo netto',
  lpc_net: 'Netto',
  lpc_quote: 'Ricalcola',
  lpc_quote_and_save: 'Ricalcola e salva',
  lpc_info_investment_complete: 'Informazioni: Investimento completo',
  lpc_effective_holders: 'Titolari effettivi',
  lpc_effective_holder: 'Titolare effettivo',
  lpc_third_party_role: 'Terzo pagatore',
  lpc_lien_credit: 'Motivo Rescissione',
  lpc_end_validity_date: 'Data fine validità',
  lpc_start_date: 'Data inizio',
  lpc_unit_linked_value: 'Valore Unit Linked',
  lpc_allocation_profile: 'Ripartizione del profilo',
  lpc_search_filter: 'Filtro ricerca',
  lpc_expiry_option_detail: 'Scadenza opzione dettagli',
  lpc_annuity_payment_type: 'Tipo pagamento rendita',
  lpc_annuity_frequency: 'Frequenza rendita',
  lpc_percentage_reversibility: 'Percentuale reversibilità',
  lpc_reversionary: 'Reversibile',
  lpc_certain_revenue_years: 'Anni di entrate determinati',
  lpc_postponement_type: 'Tipo rinvio',
  lpc_postponement_years: 'Rinvio anni',
  lpc_clauses: 'Clausole',
  lpc_active_contractual_option: 'Opzioni contrattuali attive',
  lpc_expiry_option: 'Opzioni di scadenza',
  lpc_revaluation_history: 'Rivalutazione storia',
  lpc_monetary_fund: 'Fondo monetario',
  lpc_benefit_effect_date: 'Data decorrenza beneficio',
  lpc_initial_benefit: 'Prestazione iniziale',
  lpc_movement_update: 'Aggiornamento movimento',
  lpc_update_effect_date: 'Aggiorna data di effetto',
  lpc_net_rate: 'Tasso netto',
  lpc_macro_category: 'Macro categoria',
  lpc_quotation_frequency: 'Frequenza quotazioni',
  lpc_volatility: 'Volatilità',
  lpc_volatility_date: 'Data volatilità',
  lpc_start_quotation: 'Inizio quotazione',
  lpc_last_quotation: 'Ultima quotazione',
  lpc_valuation: 'Valutazione',
  lpc_percentage_total: 'Percentuale totale',
  lpc_euro_valuation_not_fund_currency: 'La valutazione è espressa in € e non nella valuta del fondo',
  lpc_disinvstements: 'Disinvestimenti',
  lpc_payment_amount: 'Importo pagamento',
  lpc_surrender_distribution: 'Distribuzione riscatto',
  lpc_formula: 'Formula',
  lpc_roles_connected_benef: 'Ruoli collegati al beneficiario',
  lpc_quotation_details: 'Dettaglio quotazione',
  lpc_Disinvestmentsfunds: 'Fondi da disinvestire',
  lpc_same_fund_distribution: 'Distribuzione fondi identica al versamento iniziale',
  lpc_not_same_fund_distribution: 'Distribuzione fondi modificata rispetto al versamento iniziale',
  lpc_guarantees_oveview: 'Riepilogo Garanzie',
  lpc_calculate_volatility: 'Calcola volatilità asset',
  lpc_benef_death_not_same: 'Il beneficiario morte non può essere il soggetto assicurato',
  lpc_global_percentage_equal_hundred: 'La percentuale globale deve essere uguale a 100',
  lpc_percentage_must_be_between: 'La percentuale deve essere compresa fra 0.01 e 100',
  lpc_effective_holder_natural_person_req: 'Il soggetto {{linkedName}} nel ruolo di titolare effettivo di un {{benefRole}} {{benefCat}} deve essere una persona fisica',
  lpc_benefPresente_default_msg: 'Il soggetto è già stato selezionato come beneficiario nella stessa categoria',
  lpc_effective_holder_duplicate: 'Il soggetto {{linkedName}} è gia stato selezionato nel ruolo di titolare effettivo di {{benefName}}',
  lpc_effective_holder_adult_req: 'Il soggetto {{linkedName}} nel ruolo di titolare effettivo di un {{benefRole}} {{benefCat}} deve essere un adulto',
  lpc_investment_plan: 'Piano investimento',
  lpc_contract_detail: 'Dettagli Contratto',
  lpc_variation_reason: 'Motivo Variazione',
  lpc_investment_plan_detail: 'Dettaglio piano investimento',
  lpc_revaluation_rate: 'Tasso di rivalutazione',
  lpc_benefit_commencement_date: 'Data decorrenza prestazione',
  lpc_update_effective_date: 'Data effetto aggiornamento',
  lpc_last_odds_value: 'Ultimo valore quote',
  lpc_total: 'Totale',
  lpc__EFFOP: 'Data effetto',
  lpc__OPZFR: 'Frazionamento',
  lpc__OPZPM: 'Modalità di pagamento',
  lpc__OPBEN: 'Beneficiario',
  lpc__MINTS: 'Percentuale soglia minima',
  lpc__MAXTS: 'Percentuale soglia massima',
  lpc__NUMOC: 'Numero occorrenze',
  lpc__AMTOP: 'Importo riscatto',
  lpc__MALMT: 'Importo minimo',
  lpc__CIBAN: 'IBAN',
  lpc__PCOUP: 'Percentuale cedola periodica',
  lpc_threshold_percentage: 'Percentuale soglia',
  lpc_pure: 'Premio Puro',
  lpc_starting_fund: 'Fondo di partenza',
  lpc_details: 'Dettagli',
  lpc_treshold: 'Soglia',
  lpc_inv_date: 'Data investimento',
  lpc_beneficiary_perc_req: ' {{benefRole}} {{benefCat}} {{benefName}}: Inserisci la percentuale',
  lpc_beneficiary_perc_req_range: ' {{benefRole}} {{benefCat}} {{benefName}}: La percentuale deve essere compresa tra 0.01 e 100',
  lpc_invalid_total_position_percent: 'La percentuale totale per il raggruppamento \"{{groupType}}\" sul Beneficiario {{cat}} deve essere il 100%',
  lpc_beneficiary_from_party_req: ' {{benefRole}} {{benefCat}}: Aggiungere un beneficiario da anagrafe',
  lpc_benefPresente: 'Il soggetto {{benefName}} è già stato selezionato come {{benefRole}} {{benefCat}}',
  lpc_benMorteNoAssic: '{{benefName}} selezionato come {{benefRole}} {{benefCat}} è già stato censito come assicurato.',
  lpc_life_beneficiary: 'Beneficiario Vita',
  lpc_death_beneficiary: 'Beneficiario Morte',
  lpc_beneficiary_position_mandatory: 'Per il Beneficiario {{benefCat}} {{benefName}} indicare un \"Raggruppamento\"',
  lpc_beneficiary_qualification: ' {{benefCat}} {{benefRole}} ',
  lpc_reversionary_subject: 'Soggetto reversionario',
  lpc_gross_detail: 'Dettaglio premio Lordo',
  lpc_type_of_covers: 'Tipi di copertura',
  lpc_clausesinsuredClauses: 'Clausole Assicurato',
  lpc_clausespolicyClauses: 'Clausole Polizza',
  lpc_clausesriskClauses: 'Claosule Rischio',
  lpc_max_capital_amount: 'Massimo importo capitale',
  lpc_fiscal_period_M1: 'M1 – Montante maturato al 31/12/2000	',
  lpc_fiscal_period_M2: 'M2 - Montante maturato da 1/1/2001 a 31/12/2006',
  lpc_fiscal_period_M3: 'M3 - Montante maturato da 1/1/2007',
  lpc_fiscal_period_capital_request_K1: 'Parte di M1 richiesta in capitale',
  lpc_fiscal_period_capital_request_K2: 'Parte di M2 richiesta in capitale',
  lpc_fiscal_period_capital_request_K3: 'Parte di M3 richiesta in capitale',
  lpc_max_amount_exceeded: 'L’importo richiesto in capitale supera il massimo possibile.',
  lpc_max_amount_error: 'L’importo in capitale richiesto deve essere tutto attribuito ai montanti M1 e M2.',
  lpc_performance_data: 'Dati di prestazione',
  lpc_role_is_mandatory: '{{role}} obbligatorio',
  lpc_issue_authorized_proposal: 'Emetti proposta/polizza autorizzata',
  lpc_confirm_prop_pol_auth: 'Conferma proposta/polizza in Autorizzazione',
  lpc_settlement: 'Liquidazione',
  lpc_annuity: 'Rendita',
  lpc_percentage_in_capital: '% in capitale',
  lpc_insured_amount: 'Capitale Assicurato',
  lpc_custom_field_mandatory_error: 'Il campo {{field}} è obbligatorio',
  lpc_print_docs: 'Stampa documenti',
  lpc_revisionary_err: 'Inserire soggetto reversionario',
  lpc_revisionary_perc_err: 'Inserire percentuale del soggetto reversionario',
  lpc_PIP_UndeductedPremiums: 'Premi non Dedotti',
  lpc_contributionYear: 'Anno Contribuzione',
  lpc_undeductedPremium : 'Premi non Dedotti',
  lpc_reversionario: "Reversionario",
  lpc_annualPremium: 'Premio Annuo',
  lpc_firstEnrollmentComplementaryPension: 'Data Prima Iscrizione alla Previdenza Complementare'
};
export default LPC_IT;
