<div [formGroup]="formGroup">
  <div class="form-group">
    <label for="select_code">
      <span>{{beneficiaryDefinition?.label}}</span>
      <lpc-required required="true | translate"></lpc-required>
    </label>
    <select id="select_code" formControlName="code" class="form-control" [attr.data-qa]="'benefType-' +beneficiaryDefinition?.code">
      <option [disabled]="true" [ngValue]="null" translate>lpc_SELEZIONA</option>
      <option [ngValue]="option.code" *ngFor="let option of beneficiaryTypeOptions">{{option.label}}</option>
    </select>
    <div *ngIf="beneficiaryListVisible">
      <div [formGroup]="formGroup">
        <div class="row" formArrayName="subjects" [ngSwitch]="admittedBeneficiary?.code">
          <ng-container *ngSwitchCase="'_ALTRO'">
            <div *ngFor="let ben of formGroupValue.subjects; let i = index; trackBy: trackFn">
              <label translate>lpc_appendixLabel</label>
              <lpc-required *ngIf="i < minCardinality" required="true | translate"></lpc-required>
              <lpc-beneficiary-control
                [beneficiaryRoles]="beneficiaryRoles"
                (delete)="deleteBen($event)"
                [formControlName]="i"
                [showPercentage]="getShowPercentage()"
                [reg41CheckBoxVisibility]="REG41CheckBoxVisibility"
                [benDgCheckBoxVisibility]="BENDGCheckBoxVisibility"
                [paymentTypes]="paymentTypes"
                [benefPositionNumberConfig]="{active: beneficiaryDefinition.showPositionNumber, benefPositionNumberList: benefPositionNumberList}">
              </lpc-beneficiary-control>
            </div>
            <div *ngIf="isSelectable() && !formGroup.disabled">
              <label translate>lpc_appendixLabel</label>
              <lpc-required *ngIf="formGroupValue.subjects.length < minCardinality" required="true | translate"></lpc-required>
              <input [value]="'lpc_Seleziona' | translate" (click)="openAnagSubjectModal()" class="col-lg-12 drop-container dashedSelect" [attr.data-qa]="'dashed-select-beneficiary-'+ beneficiaryDefinition.code" readonly>
            </div>
          </ng-container>
          <ng-container *ngSwitchCase="'_TSLIB'">
            <div *ngFor="let ben of formGroupValue.subjects; let i = index; trackBy: trackFn">
              <label translate>lpc_appendixLabel</label>
              <lpc-required *ngIf="i < minCardinality" required="true | translate"></lpc-required>
              <lpc-beneficiary-text-control [formControlName]="i">
              </lpc-beneficiary-text-control>
            </div>
            <div *ngIf="isSelectable() && !formGroup.disabled">
              <input [value]="'lpc_Seleziona' | translate" (click)="openAnagSubjectModal()" class="col-lg-12 drop-container dashedSelect" readonly>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="irrevocableBeneficiaryAllowed">
    <input type="checkbox" formControlName="irrevocable" class="check" [attr.data-qa]="'benef-irrev-'+ beneficiaryDefinition.code">
    <span style="font-size: small;" translate>lpc_Benef_irrevocabile</span>
  </div>
</div>

