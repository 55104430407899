
<div  class="display-col life-detail-margin-box life-detail-box display-table col-lg-12 overrride-fund-table-invest">
  <div class="tbld_row life-detail-tbld_row_header">
    <div class="tbld_col tbld_col_title first-column"></div>
    <div class="tbld_col tbld_col_title" translate>lic_fund_category</div>
    <div class="tbld_col tbld_col_title" translate>lic_Percentage</div>
    <div *ngIf="!hideValueColumn" class="tbld_col tbld_col_title" translate>lic_equivalent</div>
  </div>
  <ng-container *ngFor="let profile of profiles">
    <div class="tbld_row life-detail-investment-profile-row bolder">
      <div class="first-column tbld_col tbld_col_value life-detail-table-border">
          <span (click)="toggleProfile(profile.id)"  class="rgifont" [class.rgi-plus]="!profileToggle[profile.id]" [class.rgi-minus]="profileToggle[profile.id]"></span>
      </div>
      <div class="tbld_col tbld_col_value life-detail-table-border">{{profile.description}}</div>
      <div class="tbld_col tbld_col_value life-detail-table-border">{{ profile.percent | rgiCtryLNumFormatter: locale: optionPercents }}</div>
      <div *ngIf="!hideValueColumn" class="tbld_col tbld_col_value life-detail-table-border"></div>
    </div>
    <ng-container *ngIf="profileToggle[profile.id]">
        <div *ngFor="let fund of profile.funds" class="tbld_row regular">
          <div class="tbld_col tbld_col_value life-detail-table-border first-column"></div>
            <div class="tbld_col tbld_col_value life-detail-table-border">{{fund.description}}</div>
            <div class="tbld_col tbld_col_value life-detail-table-border">{{ fund.percent | rgiCtryLNumFormatter: locale: optionPercents }}</div>
            <div *ngIf="!hideValueColumn" class="tbld_col tbld_col_value life-detail-table-border">{{ fund.amount | rgiCtryLNumFormatter : locale: optionCurrency}}
            </div>
          </div>
    </ng-container>
  </ng-container>
</div>
