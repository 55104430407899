<div class="quotation-top-control central-cntr active">
  <div>
    <div class="pull-left">
      <div *ngIf="showFirstInstallment && !!quotationDefinition && !!quotationDefinition.firstInstalment && !!quotationDefinition.firstInstalment?.gross" class="box-premium pull-left hidden-xs">
        <span class="title-premium" translate>lpc_FirstInstallment<br></span>
        <div class="mrg-top">
            <ng-template [ngIf]="!!disableQuote" [ngIfElse]="no_value">
              <span class="currency-premium" id="premio-firma">
                {{(quotationDefinition.firstInstalment?.gross |rgiCtryLNumFormatter:  '' : currencyFormatterOptions)}}
              </span>
            </ng-template>
        </div>
        <div>
          <!--  <span class="currency-premium currency-premium2 bold" *ngIf="!premiumInstallment">--</span> -->
        </div>
      </div>
      <div *ngIf="!!quotationDefinition && !!quotationDefinition.nextInstalment && !!quotationDefinition.nextInstalment?.gross" class="box-premium pull-left hidden-xs">
        <span class="title-premium text-uppercase" translate>lpc_next_installment_premium<br></span>
        <div class="mrg-top">
            <ng-template [ngIf]="!!disableQuote" [ngIfElse]="no_value">
              <span class="currency-premium" id="premio-successiva">
                {{(quotationDefinition.nextInstalment?.gross |rgiCtryLNumFormatter:  '' : currencyFormatterOptions)}}
              </span>
            </ng-template>
        </div>
        <div></div>
      </div>

      <div class="sep pull-left">&nbsp;</div>
      <div *ngIf="!!quotationDefinition && !!quotationDefinition.annualPremium && !!quotationDefinition.annualPremium.gross" class="box-premium pull-left">
        <span class="title-premium" id="premioAnnuo" translate>lpc_AnnualPremium<br></span>
        <div class="mrg-top">
          <ng-template [ngIf]="!!disableQuote" [ngIfElse]="no_value">
            <span class="currency-premium" id="premio-annuo">
              {{(quotationDefinition.annualPremium.gross |rgiCtryLNumFormatter:  '' : currencyFormatterOptions)}}
            </span>
          </ng-template>

        </div>
        <div>
          <!-- <span class="currency-premium currency-premium2 bold" *ngIf="!annualPremiumValue">--</span> -->
        </div>
      </div>
    </div>

    <div class="pull-right btn-toolbar btn-quotation-box nmf_cont_button_menunit">
      <div class="btn-group">
        <button id="quote" [disabled]="disableQuote" (click)="recalculate()" class="btn btn-style btn-warning recalculate-btn" data-qa="recalculate">
          <span class="hidden-xs hidden-sm" translate>lpc_quote</span>
          <span class="controls-icon visible-xs visible-sm rgifont rgi-refresh"></span>
        </button>
      </div>
      <div class="btn-group">
        <button *ngIf="areFormulasVisible()"
            class="btn btn-default btn-quotation-detail nmf_button_headerecalculate" (click)="openFormule()">
          <span class="rgifont rgi-pencil"></span>
        </button>
      </div>

      <div ngbDropdown class="dropdown">
        <button [disabled]="disableDetails" class="btn btn-outline-primary dropdown-button text-uppercase" id="dropdownBasic2" ngbDropdownToggle translate>lpc_quotation_show_details</button>
        <div ngbDropdownMenu aria-labelledby="dropdownBasic2">
          <button ngbDropdownItem *ngIf="showOpenQuotationModalButton" (click)="openQuotation()" translate>lpc_quotation_premium_details</button>
          <button ngbDropdownItem *ngIf="showopenWarrantiesModalButton" (click)="openWarranties()" translate>lpc_quotation_warranties_details</button>
          <button ngbDropdownItem  *ngIf="enableProv" (click)="openProv()"translate>lpc_quotation_commissions_details</button>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="quotation-top-control central-cntr inactive">
  <div>
    <div class="pull-left">
      <div class="box-premium pull-left hidden-xs" *ngIf="!!quotationDefinition && !quotationDefinition.nextInstalment" >
        <span class="title-premium" translate>lpc_FirstInstallment<br></span>
        <div class="mrg-top" *ngIf="!!quotationDefinition && !!quotationDefinition.firstInstalment ">
          <span class="currency-premium" id="premio-firma"> {{quotationDefinition.firstInstalment?.gross |rgiCtryLNumFormatter:  '' : currencyFormatterOptions}}</span>
        </div>
        <div>
          <!--  <span class="currency-premium currency-premium2 bold" *ngIf="!premiumInstallment">--</span> -->
        </div>
      </div>
      <div *ngIf="!!quotationDefinition && !!quotationDefinition.nextInstalment && !!quotationDefinition.nextInstalment?.gross" class="box-premium pull-left hidden-xs">
        <span class="title-premium text-uppercase" translate>lpc_next_installment_premium<br></span>
        <div class="mrg-top">
          <span class="currency-premium" id="premio-successiva">{{(quotationDefinition.nextInstalment?.gross |rgiCtryLNumFormatter:  '' : currencyFormatterOptions)}} </span>
        </div>
        <div></div>
      </div>

      <div class="sep pull-left">&nbsp;</div>
      <div *ngIf="!!quotationDefinition && !!quotationDefinition.annualPremium && !!quotationDefinition.annualPremium.gross"  class="box-premium pull-left hidden-xs">
        <span class="title-premium" id="premioAnnuo" translate>lpc_AnnualPremium<br></span>
        <div class="mrg-top">
        <span class="currency-premium" id="premio-annuo"> {{quotationDefinition.annualPremium.gross |rgiCtryLNumFormatter:  '' : currencyFormatterOptions}}</span>
        </div>
        <div>
          <!-- <span class="currency-premium currency-premium2 bold" *ngIf="!annualPremiumValue">--</span> -->
        </div>
      </div>
    </div>

    <div class="pull-right btn-toolbar btn-quotation-box nmf_cont_button_menunit">
      <div class="btn-group">
        <button disabled class="btn btn-style btn-disabled recalculate-btn" data-qa="recalculate">
          <span class="controls-icon visible-xs visible-sm rgifont rgi-refresh"></span>
          <span class="hidden-xs hidden-sm" translate>lpc_quote</span>
        </button>
      </div>
      <div class="btn-group">
        <button disabled *ngIf="areFormulasVisible()"
            class="btn btn-default btn-quotation-detail nmf_button_headerecalculate" >
          <span class="rgifont rgi-pencil"></span>
        </button>
      </div>
      <div ngbDropdown class="dropdown">
        <button disabled class="btn btn-outline-primary dropdown-button text-uppercase" id="dropdownBasic2" ngbDropdownToggle translate>lpc_quotation_show_details</button>
      </div>
    </div>
  </div>
</div>


<ng-template #no_value>
  <span class="currency-premium" id="premio-firma">{{'--'}}</span>
</ng-template>
