import { NgModule } from '@angular/core';
import { LpcFactorAdapterComponent } from './lpc-factor-adapter.component';
import { FactorsComponent } from '../lpc-postsales-factor/factors.component';
import { LoaderDirective } from '../lpc-postsales-factor/factor-loader.directive';
import { FormatFactorDirective } from '../lpc-postsales-factor/format-factor.directive';
import { LpcSelectFactorComponent } from '../lpc-postsales-factor/lpc-select-factor/lpc-select-factor.component';
import { LpcRadioGroupFactorComponent } from '../lpc-postsales-factor/lpc-radio-group-factor/lpc-radio-group-factor.component';
import { LpcDatepickerFactorComponent } from '../lpc-postsales-factor/lpc-datepicker-factor/lpc-datepicker-factor.component';
import { LpcInputFactorComponent } from '../lpc-postsales-factor/lpc-input-factor/lpc-input-factor.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';

import { CommonModule } from '@angular/common';
import { NgSelectModule } from '@ng-select/ng-select';
import { PortalNgCoreModule } from '@rgi/portal-ng-core';
import { LpcHelpModule } from '../lpc-help/lpc-help.module';
import { RgiRxI18nModule } from '@rgi/rx/i18n';
import { LpcRequiredModule } from '../../utils/lpc-required/lpc-required.module';
import { RgiRxFormElementsModule } from '@rgi/rx/ui';
import { DataValueFormatterModule } from '../../utils/pipe/data-value-formatter/data-value-formatter.module';

@NgModule({
    declarations: [
        LpcFactorAdapterComponent,
        FactorsComponent,
        LoaderDirective,
        FormatFactorDirective,
        LpcSelectFactorComponent,
        LpcRadioGroupFactorComponent,
        LpcDatepickerFactorComponent,
        LpcInputFactorComponent
    ],
    imports: [
        ReactiveFormsModule,
        FormsModule,
        CommonModule,
        NgSelectModule,
        PortalNgCoreModule,
        LpcHelpModule,
        RgiRxI18nModule,
        LpcRequiredModule,
        RgiRxFormElementsModule,
        DataValueFormatterModule
    ],
    exports: [
        LpcFactorAdapterComponent,
        FactorsComponent
    ]
})
export class LpcFactorAdapterModule {
}
