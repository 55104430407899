import { ChangeDetectorRef, Component, Inject, Optional, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslationWrapperService } from '../../i18n/translation-wrapper.service';
import { NotifierService } from '@rgi/portal-ng-core';
import { QuestionnaireCacheService } from '@rgi/questionnaires-manager';
import { Observable } from 'rxjs';
import { distinctUntilChanged, finalize, map, switchMap } from 'rxjs/operators';
import { RequestFactor } from '../../models/factor.model';
import { InvestmentMode, OperationPropertyCode } from '../../models/operation-property-code.enum';
import { BeneficiaryDefinition,
  BeneficiaryRole,
  Definition, FactorDefinition, OperationProperty, PaymentTypeDefinition, PostsalesError, PostsalesOperationObject, Role } from '../../models/postsales-operations-response.model';
import { LpcBeneficiaryUtils } from '../../modules/lpc-beneficiary/util/lpc-beneficiary-utils';
import { LpcFactorAdapterComponent } from '../../modules/lpc-factor-adapter/lpc-factor-adapter.component';
import { KarmaProfileDefinition } from '../../modules/lpc-karma-funds/model/karma-profile-definition';
import { KarmaFundService } from '../../modules/lpc-karma-funds/service/karma-fund.service';
import { LpcKarmaFundUtils } from '../../modules/lpc-karma-funds/utils/lpc-karma-fund-utils';
import { AngularCommunicationService } from '../../services/angular-communication.service';
import { AuthService } from '../../services/auth.service';
import { PlcQuestService } from '../../services/plc-quest.service';
import { PostsalesOperationsService } from '../../services/postsales-operations.service';
import { PlcDateUtils } from '../../utils/plc-date-utils';
import { PlcObjectUtils } from '../../utils/plc-object-utils';
import { LpcClaimBeneficiariesComponent } from '../../modules/lpc-beneficiary/lpc-claim-beneficiaries/lpc-claim-beneficiaries.component';
import { AnagService } from '../../services/anag.service';
import { AbsOperationComponent } from '../abs-operation-component/abs-operation.component';
import { KarmaFundDefinition } from './../../modules/lpc-karma-funds/model/karma-fund-definition';
import { VolatilityErrorType, VolatilityResponse, VolatilityType } from '../../modules/lpc-karma-funds/model/karma-volatility';
import { EMPTY_STR, JS_EVENT, PV_TOKEN } from '../../models/consts/lpc-consts';
import { LpcCurrencyCache, CurrencyCacheService } from '../../services/currency-cache.service';
import { LpcBeneficiaryService } from '../../services/beneficiary.service';

@Component({
  selector: 'lpc-partial-surrender',
  templateUrl: './partial-surrender.component.html',
  styleUrls: ['./partial-surrender.component.scss']
})
export class PartialSurrenderComponent extends AbsOperationComponent {
  // formatter Options
  public currencyFormatterOptions: Intl.NumberFormatOptions = {
    style: 'currency'
  };
  public percentFormatterOptions: Intl.NumberFormatOptions = {
    style: 'percent',
    minimumIntegerDigits: 1,
    maximumFractionDigits: 2,
    minimumFractionDigits: 2
  };

  protected operationDataKey = 'partial-surrender';
  public listProductFactor: FactorDefinition[] = [];
  public requestFactor: RequestFactor[] = [];
  public volatility: number;
  public tableDefinitions: Definition[] = [];
  public insuredSettlements: any[] = [];
  public investmentMode: number;
  public beneficiariesDefinitions: BeneficiaryDefinition[] = [];
  protected anagSubj = null;
  public feErrorsBenef: PostsalesError[] = [];

  public get hasProductFactors(): boolean {
    return !!this.listProductFactor.length;
  }

  public get useFixedTemplate(): boolean {
    return this.profiles.every(profile => profile.funds.every(fund => fund.maxPercentAllocation === fund.minPercentAllocation));
  }

  public get feErrors(): PostsalesError[] {
    return LpcBeneficiaryUtils.getSettlementBeneficiariesErrors(this.formGroup, this.translate);
  }

  private $profiles: KarmaProfileDefinition[] = [];

  public get profiles(): KarmaProfileDefinition[] {
    return this.$profiles;
  }

  private $modifiable: boolean;

  public get modifiable(): boolean {
    return this.$modifiable;
  }

  private $investedProfiles: KarmaProfileDefinition[] = [];

  public get investedProfiles(): KarmaProfileDefinition[] {
    return this.$investedProfiles;
  }


  private $totalAmount: number;

  public get totalAmount(): number {
    return this.$totalAmount;
  }

  private $paymentTypes: PaymentTypeDefinition[] = [];

  public get paymentTypes(): PaymentTypeDefinition[] {
    return this.$paymentTypes;
  }

  private _beneficiaryData: any[];

  get idAssicurato(): string {
    if (!!this.beneficiariesDefinitions && this.beneficiariesDefinitions.length) {
      return this.beneficiariesDefinitions[0].idAssicurato;
    } else if (!!this._beneficiaryData && this._beneficiaryData.length) {
      return this._beneficiaryData[0].beneficiary && this._beneficiaryData[0].beneficiary.idAssicurato;
    } else {
      return EMPTY_STR;
    }
  }

  get beneficiaryType(): string {
    if (!!this.beneficiariesDefinitions && !!this.beneficiariesDefinitions.length) {
      return this.beneficiariesDefinitions[0].code;
    }
  }

  public get selectedProfiles(): KarmaProfileDefinition[] {
    const profiles: any = this.formGroup.getRawValue().disinvestmentProfiles ? this.formGroup.getRawValue().disinvestmentProfiles : {};
    const profileKeys = Object.keys(profiles).filter(key => !!profiles[key]);
    return this.$profiles.filter(pr => profileKeys.includes(pr.id));
  }

  @ViewChild('factorAdapter') factorAdapter: LpcFactorAdapterComponent;
  @ViewChild('beneficiaries') beneficiaries: LpcClaimBeneficiariesComponent;

  constructor(
    @Inject(PV_TOKEN.POSTSALES_SERVICE) protected operations: PostsalesOperationsService,
    protected cd: ChangeDetectorRef,
    protected translate: TranslationWrapperService,
    protected angularCommunicationService: AngularCommunicationService,
    @Inject(PV_TOKEN.CORE_INJECTOR) protected injector: any,
    @Optional() protected questCacheService: QuestionnaireCacheService,
    protected modalService: NgbModal,
    protected notifierService: NotifierService,
    protected plcQuestService: PlcQuestService,
    protected authService: AuthService,
    protected karmaService: KarmaFundService,
    protected anag: AnagService,
    @Optional() @Inject(LpcCurrencyCache) protected currencyService: CurrencyCacheService,
    protected beneficiaryService: LpcBeneficiaryService
  ) {
    super(operations, cd, translate, injector, questCacheService, modalService, notifierService,
      plcQuestService, authService, anag);
    this.currencyFormatterOptions.currency = currencyService.currency;
  }

  ngOnInit() {
    this.initializeSession();
    this.$subscriptions.push(
      this.createDraft().subscribe(response => {
        this.createDraftHandleResponse(response);
      }),
      this.formGroup.get('disinvestmentProfiles').valueChanges.pipe(
        distinctUntilChanged(),
        map(result => {
          this.updateFundsFormOnProfileChange(result);
          this.volatility = 0;
        }),
        finalize(() => {
          this.formGroup.get('disinvestmentFunds').updateValueAndValidity();
        })
      ).subscribe(),
      this.formGroup.get('disinvestmentFunds').valueChanges.pipe(distinctUntilChanged()).subscribe(result => {
        this.volatility = 0;
      })
    );
  }

  protected updateFundsFormOnProfileChange(result: any) {
    Object.keys(result).filter(p => !result[p]).forEach(pId => {
      (this.formGroup.get('disinvestmentFunds') as UntypedFormGroup).get(pId).reset();
      (this.formGroup.get('disinvestmentFunds') as UntypedFormGroup).get(pId).clearValidators();
    });
  }

  createDraftHandleResponse(createDraftResponse: PostsalesOperationObject) {
    LpcBeneficiaryUtils.setBeneficiaryRoles(createDraftResponse.definitions.BenefiacyRoles as BeneficiaryRole[]);
    this.beneficiariesDefinitions = createDraftResponse.definitions.beneficiaries &&
                                    (createDraftResponse.definitions.beneficiaries as BeneficiaryDefinition[]).reverse();
    this._beneficiaryData = createDraftResponse.data.operationData.data.beneficiaries;
    if (!!this._beneficiaryData) {
      this.formGroup.get('beneficiaries').setValue(
        (this._beneficiaryData as any[]).map(beneficiary => {
          return LpcBeneficiaryUtils.toClaimBeneficiary(beneficiary);
        }),
        { emitEvent: false }
      );
    }
    this.updateInvestmentProfiles(createDraftResponse.definitions);
  }

  public getTotalAmount(): number {
    const vrspa = this.listProductFactor.find(factor => factor.code === '_VRSPA');
    if (!!vrspa && !!vrspa.defaultValue) {
      this.$totalAmount = Number(vrspa.defaultValue);
      return this.$totalAmount;
    }
  }

  public getInvestmentMode(): number {
    const vmodi = this.listProductFactor.find(factor => factor.code === '_VMODI');
    if (!!vmodi && !!vmodi.defaultValue) {
      this.investmentMode = Number(vmodi.defaultValue);
      return this.investmentMode;
    }
  }

  public getInvestmentInsertMode() {
    const prop: OperationProperty = this.getOperationPropertyByCode(OperationPropertyCode.DISINVESTMENT_MODE);
    if (!!prop) {
      return prop.stringValue as InvestmentMode;
    }
    return undefined;
  }

  public getProfileAmount(id: string): number {
    const percentage = this.formGroup.get('disinvestmentProfiles').value[id];
    if (!!percentage) {
      const amount = !!this.getTotalAmount() ? this.getTotalAmount() :
      this.investedProfiles.find(profile => profile.id.toString() === id.toString()).counterValue;
      return Math.round(
        percentage * amount);
    }
    return 0;
  }

  public getProfilePercent(id: string): number {
    return this.formGroup.get('disinvestmentProfiles').value[id];
  }

  protected updateDraft(step: string, reload: boolean = false, opDataType?: string): Observable<any> {
    if (this.beneficiaryService.checkAllQuestionnaires(this.stepper.activeStep.id, s => this.setQuestionaryError(s))) {
      return this.beneficiaryService.persistQuestionnaires(this.stepper.activeStep.id)
        .pipe(switchMap(_ => super.updateDraft(step, reload, opDataType).pipe(switchMap(result => {
          this.insuredSettlements = result.data.operationData.data.insuredSettlements;
          this.beneficiariesDefinitions = result.definitions.beneficiaries &&
                                        (result.definitions.beneficiaries as BeneficiaryDefinition[]).reverse();
          this.tableDefinitions = result.definitions.settlement as Definition[];
          this.listProductFactor = result.definitions.productFactors as FactorDefinition[];
          this.$paymentTypes = result.definitions.paymentTypes as PaymentTypeDefinition[];
          const disinvestmentProfiles: any = result.definitions.disinvestmentProfiles;
          if (!!disinvestmentProfiles && !!disinvestmentProfiles.length) {
            /* const modifiable: any = result.definitions.modifiableFunds;*/
            this.$modifiable = false;
            this.removeOldControls(this.formGroup.get('factors') as UntypedFormGroup);
            if (step === 'factors' && !reload) {
              this.updateInvestmentProfiles(result.definitions);
              this.getTotalAmount();
              if (this.calculateVolatilityOn(step)) {
                this.onCalculateVolatility();
                }
            } else if (step === 'disinvestmentFunds') {
              // ASMC-11516 - ribalto le percentuali del form su $profiles per visualizzare la corretta tabella riassuntiva
              const profilesPercentageFromForm = this.formGroup.getRawValue().disinvestmentProfiles;
              const fundsPercentageFromForm = this.formGroup.getRawValue().disinvestmentFunds;
              this.$profiles.forEach(p => {
                p.percent = profilesPercentageFromForm[p.id];
                p.percentage = profilesPercentageFromForm[p.id];
                p.funds.forEach(f => {
                  f.percent = fundsPercentageFromForm[p.id] && fundsPercentageFromForm[p.id][f.id];
                  f.percentage = f.percent;
                });
              });
            }
          }
          this.detectChanges();
          return Promise.resolve(result);
        })
        )
      ));
    }
  }

  public calculateVolatilityOn(step): boolean {
    return this.useFixedTemplate && !this.volatility && this.isWithVolatility();
  }

  // Rimuove i vecchi controlli
  public removeOldControls(form: UntypedFormGroup) {
    const codes: string[] = [];
    this.listProductFactor.map((element => {
        codes.push(element.code);
    }));
    Object.keys(form.controls).forEach((key) => {
        if (!codes.includes(key)) {
            form.removeControl(key);
        }
    });
}

  public onCalculateVolatility() {
    this.angularCommunicationService.communicate(JS_EVENT.LOADER.START);
    this.calculateVolatility().subscribe(result => {
      this.volatility = result.volatility;
      this.extractVolatilityErrors(result, 'disinvestmentFunds');
      this.angularCommunicationService.communicate(JS_EVENT.LOADER.STOP);
    });
  }

  private calculateVolatility(): Observable<VolatilityResponse> {
    const totalAmount = Math.round(!!this.$totalAmount ? this.$totalAmount : this.getInvestmentTotalAmount());
    return this.karmaService.calculateVolatility(
      [],
      this.getProfilesValueFromForm(this.formGroup, 'disinvestmentProfiles', 'disinvestmentFunds', true),
      PlcDateUtils.isoDateTimeToIsoDate(this.formGroup.get('dates').value._1OEFF),
      this.operations.session.productId,
      this.operations.getPolicyNumber(),
      totalAmount,
      this.session.contractor,
      this.operations.session.managementNodeCode,
      this.$session.operation,
      this.getInvestmentMode(),
      VolatilityType.surrenderDemand
    );
  }

  public getInvestmentTotalAmount(): number {
    let sumInvestable = 0;
    let percentageInvestedProfile  = null;
    let foundedInvestedProfile  = null;
    const disinvProf = this.formGroup.get('disinvestmentProfiles').value;
    if (!!disinvProf) {
      Object.keys(this.formGroup.get('disinvestmentProfiles').value).forEach((key, value) => {
        foundedInvestedProfile = this.investedProfiles.find(investedProf => investedProf.id.toString() === key.toString());
        if (!!foundedInvestedProfile) {
          percentageInvestedProfile = disinvProf[key];
          if (!!percentageInvestedProfile) {
            sumInvestable += foundedInvestedProfile.counterValue * percentageInvestedProfile;
          }
        }
      });
    }

    return sumInvestable;
  }

  public getActivePanelIds(): string[] {
    const panels = [];
    this.selectedProfiles.forEach(el => {
      panels.push('panel' + el.id);
    });
    return panels;
  }

  public isWithVolatility(): boolean {
    if (this.hasOperationPropertyByCode(OperationPropertyCode.VOLATILITY_CALCULATION)) {
      return PlcObjectUtils.getBooleanString(
        this.getOperationPropertyByCode(OperationPropertyCode.VOLATILITY_CALCULATION).booleanValue
      );
    }
    return false;
  }

  public onAddedSubject($event: Role) {
    if ($event === null) {
      this.openModal('lpc_found_duplicate', 'lpc_duplicate_subject_message', true);
    }
  }

  public getInvestedFundsByProfileId(id: number): KarmaFundDefinition[] {
    const profileFound = this.investedProfiles.find(el => el.id.toString() === id.toString());
    if (!!profileFound) {
      return profileFound.funds;
    }
  }

  public onTriggerQuestPreval($event: any) {
    if (!!$event) {
      this.anagSubj = $event;
      this.$subscriptions.push(
        this.plcQuestService.prevalQuest(this.operationDataKey, EMPTY_STR, this).subscribe(result => {
          this.questFactorsArray = result;
          this.disabledQuestionArray = this.plcQuestService.disableQuest(this.operationDataKey, EMPTY_STR, this);
        })
      );
    }
  }

  protected updateInvestmentProfiles(definitions): void {
    const profileDefinitions = definitions.disinvestmentProfiles;
    if (!PlcObjectUtils.equal(profileDefinitions, this.$profiles)) {
      this.volatility = null;
      if (!!profileDefinitions) {
        this.$profiles = profileDefinitions;
        this.$investedProfiles = definitions.investedProfiles;
        const profilesFormValue: any = {};
        this.$profiles.forEach(profile => {
          const fundsFormValue: any = {};
          profilesFormValue[profile.id] = profile.percentage;
          profile.funds.forEach(fund => {
            fundsFormValue[fund.id] = fund.percentage;
          });
          if ((this.formGroup.get('disinvestmentFunds') as UntypedFormGroup).get(profile.id.toString())) {
            (this.formGroup.get('disinvestmentFunds') as UntypedFormGroup).get(profile.id.toString()).setValue(fundsFormValue, {emitEvent: false});
          } else {
            (this.formGroup.get('disinvestmentFunds') as UntypedFormGroup).addControl(profile.id.toString(), new UntypedFormControl(fundsFormValue));
          }
          // this.formGroup.get('disinvestmentFunds').get(profile.id.toString()).setValue(, {emitEvent: false});
          // this.cd.detectChanges();
        });
        this.formGroup.get('disinvestmentProfiles').setValue(profilesFormValue, {emitEvent: false});
      }
    }
  }

  public updateFactors(factors: RequestFactor[]) {
    this.requestFactor = factors;
    this.$subscriptions.push(this.onReload('factors').subscribe((result) => {
      this.listProductFactor = result.definitions.productFactors as FactorDefinition[];
    }));
  }

  protected getFormGroup(): UntypedFormGroup {
    return new UntypedFormGroup({
      dates: new UntypedFormControl(),
      factors: new UntypedFormGroup({}),
      disinvestmentProfiles: new UntypedFormControl(),
      disinvestmentFunds: new UntypedFormGroup({}),
      quotation: new UntypedFormGroup({}),
      beneficiaries: new UntypedFormControl([])
    });
  }

  protected getTransformedOperationData(): any {
    let disinvestments = [];
    if (!!this.formGroup.getRawValue().disinvestmentProfiles && !!this.formGroup.getRawValue().disinvestmentFunds &&
    !!this.profiles) {
      disinvestments = LpcKarmaFundUtils.getBackendStructureOfProfiles(
        this.profiles,
        this.formGroup.getRawValue().disinvestmentProfiles,
        this.formGroup.getRawValue().disinvestmentFunds
      );
    }
    return {
      /* amountNotSegragatedFunds: null,
      amountSegragatedFunds: null, */
      disinvestments,
      /* amountValue: 5000, */
      insuredSettlements: null,
      listProductFactor: this.getProductFactorList(),
      beneficiaries: this.formGroup.getRawValue().beneficiaries
        .map(claimBeneficiary => {
          return LpcBeneficiaryUtils.convertToBeneficiaryRequest(claimBeneficiary);
        })
    };
  }


  protected getProductFactorList() {
    if (!this.hasProductFactors) {
      return [];
    }
    if (!this.requestFactor.length) {
      return this.factorAdapter.getRequestFactor();
    }
    return this.requestFactor;
  }

  public confirmButtonVisibility() {
    return !this.hasBlockingErrorsOnSteps('quotation') && !!this.insuredSettlements && !!this.insuredSettlements.length;
  }

  // METODI GIà PRESENTI IN OperationWithFinancialStepComponent
  public getProfilesValueFromForm(fg: UntypedFormGroup, profileControlName, fundControlName, toHundred = false) {
    return LpcKarmaFundUtils.getProfilesValueFromForm(
      fg.getRawValue()[profileControlName],
      fg.getRawValue()[fundControlName],
      toHundred
    );
  }

  public extractVolatilityErrors(result: VolatilityResponse, step = 'investmentFunds') {
    if (!result.errors) { return; }
    const warnings = result.errors.filter(err => err.errorType === VolatilityErrorType.WARNING);
    const errors = result.errors.filter(err => err.errorType === VolatilityErrorType.BLOCKING);

    if (!!warnings.length) {
      this.setCustomFeErrorsVector(step, warnings.map(warning => warning.errorDescription), 'warning');
    }
    if (!!errors.length) {
      this.setCustomFeErrorsVector(step, errors.map(error => error.errorDescription), 'error');
    }
  }

}
