<div class="message" *ngIf="total">
  <div class="message-warning" *ngIf="lower">
    <span translate>lic_information</span><span>:</span>
    <span> {{ amount | rgiCtryLNumFormatter : locale : formatterOptionCurrency }} </span>
    <span>{{'lic_or_junction' | translate}}</span>
    <span> {{ percent | rgiCtryLNumFormatter: locale: formatterOptionPerc }} </span>
    <span>{{'lic_to_be_invested' | translate}}</span>
  </div>
  <!--<div class="message-ok" [hidden]="isOk()">
    <span translate>lic_info_investment_completed</span>
  </div>-->
  <div class="message-error" *ngIf="!lower">
    <span translate>lic_allocation_exceeds</span>
    <span> {{ amount | rgiCtryLNumFormatter : locale : formatterOptionCurrency }}</span>
  </div>
</div>

<div class="message" *ngIf="!total">
  <div class="message-error" *ngIf="type === 'max'" >
    <span>{{ error.description }}: </span>
    <span translate>lic_max_percentage</span>
    <span> {{ limit | rgiCtryLNumFormatter: locale: formatterOptionPerc }}</span>
  </div>
  <div class="message-error" *ngIf="type === 'min'" >
    <span>{{ error.description }}: </span>
    <span>{{'lic_min_percentage' | translate}}</span>
    <span> {{ limit | rgiCtryLNumFormatter: locale: formatterOptionPerc }}</span>
  </div>
</div>
