<div class="container-fluid" *ngIf="(stateList.getState$() | async) as detailList" class="portal-navigation-wrapper dhTable">

    <ng-container *ngIf="viewCond.fromSearchCond">
        <div class="accordion-color" style="margin-bottom: 15px;">
            <rgi-rx-expansion-panel [expanded]="false">
                <rgi-rx-expansion-panel-header>{{'_CLAIMS_._FILTER' | translate}}</rgi-rx-expansion-panel-header>
                <div class="container-fluid table-white">
                    <div class="row">
                        <div class="col-md-12  py-4">
                            <claims-dialogue-handler-search [buttonCondition]="false" [searchForm]="detailList.formSearch" [routeList]="activeRoute"></claims-dialogue-handler-search>
                        </div>
                    </div>
                </div>
            </rgi-rx-expansion-panel>
        </div>

        <rgi-rx-datatable [data]="responseDialogeList" [schema]="schemaTableSx" [pageOptions]="[5,10,20,50]"
        [expansionRow]="rowDetailSx" (onAction)="onActionClick($event)">

        <ng-template rgiRxDataTableCell="insuranceCodeOther" let-ctx>
            <div>
                <span>{{ctx.row.insuranceCodeOther?.code}}</span>
            </div>
        </ng-template>

        <ng-template rgiRxDataTableCell="functionCode" let-ctx>
            <div>
                <span>{{ctx.row.functionCode?.description}}</span>
            </div>
        </ng-template>

        <ng-template rgiRxDataTableCell="roleCompany" let-ctx>
            <div>
                <span>{{ctx.row.roleCompany?.description}}</span>
            </div>
        </ng-template>

        <ng-template rgiRxDataTableCell="lastRespMsgCode" let-ctx>
            <div>
                <span  [class]="checkClass(ctx)">
                    {{ctx.row.lastRespMsgCode}}</span>
            </div>
        </ng-template>

        <ng-template rgiRxDataTableCell="actions" let-ctx>
            <div>
                <button rgi-rx-button (click)="guideMeToSummary(ctx)" variant="icon"><span class="rgi-ui-icon-right-arrow"></span></button>
            </div>
        </ng-template>
    </rgi-rx-datatable>

    <ng-template #rowDetailSx let-ctx>
        <div class="row  border-round">
            <div class="col-md-3">
                <label rgiRxLabel class="label-core">{{'_CLAIMS_._INSURANCE_CODE_US' | translate}}:</label>
                <span style="margin-left: 5px">{{ctx.insuranceCodeUS?.description}}</span>
            </div>

            <div class="col-md-3">
                <label rgiRxLabel class="label-core">{{'_CLAIMS_._ACTOR_NOMINATIVE' | translate}}:</label>
                <span style="margin-left: 5px">{{ctx.nameParty}}</span>
            </div>

            <div class="col-md-3">
                <label rgiRxLabel class="label-core">{{'_CLAIMS_._OBJECT' | translate}}:</label>
                <span style="margin-left: 5px">{{ctx.dialogueSubjectCode?.description}}</span>
            </div>

            <div class="col-md-3">
                <label rgiRxLabel class="label-core">{{'_CLAIMS_._START_DATE' | translate}}:</label>
                <span style="margin-left: 5px">{{ctx.startDate | date:'dd/MM/yyyy'}}</span>
            </div>


            <div class="col-md-3">
                <label rgiRxLabel class="label-core">{{'_CLAIMS_._LST_MSG_DATE' | translate}}:</label>
                <span style="margin-left: 5px">{{ctx.lastMsgDate | date:'dd/MM/yyyy'}}</span>
            </div>

            <div class="col-md-3">
                <label rgiRxLabel class="label-core">{{'_CLAIMS_._END_DATE' | translate}}:</label>
                <span style="margin-left: 5px">{{(ctx.endDate)?(ctx.endDate | date:'dd/MM/yyyy'):(ctx.cancelDate | date:'dd/MM/yyyy') }}</span>
            </div>

            <div class="col-md-3">
                <label rgiRxLabel class="label-core">{{'_CLAIMS_._LST_MSG_INT_EXP' | translate}}:</label>
                <span style="margin-left: 5px">{{ctx.lastMsgInternalExpire | date:'dd/MM/yyyy'}}</span>
            </div>

            <div class="col-md-3">
                <label rgiRxLabel class="label-core">{{'_CLAIMS_._EXTERNAL_KEY' | translate}}:</label>
                <span style="margin-left: 5px">{{ctx.externalKey}}</span>
            </div>

            <div class="col-md-3">
                <label rgiRxLabel class="label-core">{{'_CLAIMS_._EXT_KEY_IDEF_DAM' | translate}}:</label>
                <span style="margin-left: 5px">{{ctx.externalIdentifyDamageParty}}</span>
            </div>
        
            <div class="col-md-3">
                <label rgiRxLabel class="label-core">{{'_CLAIMS_._TYPE_LICENSE_PLATE_NUMBER_US' | translate}}:</label>
                <span style="margin-left: 5px">{{ctx.typeInsPlate?.description}}</span>
            </div>

            <div class="col-md-3">
                <label rgiRxLabel class="label-core">{{'_CLAIMS_._TYPE_LICENSE_PLATE_NUMBER_CTP' | translate}}:</label>
                <span style="margin-left: 5px">{{ctx.typePlateCtnp?.description}}</span>
            </div>
        </div>
    </ng-template>

    <div class="btn-group btn-group-justified btn-group-justified-claim" style="margin: 25px 0px;">
        <div class="btn-group">
            <!-- *ngIf="btnCond.canOpenNew == true" -->
            <button  style="margin-top: 10px;" rgi-rx-button class="btn btn-warning pull-right text-uppercase"
                (click)="openPartialNewDialogue()" translate>{{'_CLAIMS_._BUTTONS_._NEW_DIALOGUE' | translate
                }}</button>
        </div>
    </div>

    </ng-container>

    <ng-container *ngIf="viewCond.doubleListCond">

        <div class="container-fluid">

            <rgi-rx-datatable [data]="detailList.listFromClaim[0]" [schema]="schemaTableSx" [pageOptions]="[5,10,20,50]"
            [expansionRow]="rowDetailSx" (onAction)="onActionClick($event)">

            <ng-template rgiRxDataTableCell="insuranceCodeOther" let-ctx>
                <div>
                    <span>{{ctx.row.insuranceCodeOther?.code}}</span>
                </div>
            </ng-template>

            <ng-template rgiRxDataTableCell="functionCode" let-ctx>
                <div>
                    <span>{{ctx.row.functionCode?.description}}</span>
                </div>
            </ng-template>

            <ng-template rgiRxDataTableCell="roleCompany" let-ctx>
                <div>
                    <span>{{ctx.row.roleCompany?.description}}</span>
                </div>
            </ng-template>

            <ng-template rgiRxDataTableCell="lastRespMsgCode" let-ctx>
                <div>
                    <span  [class]="checkClass(ctx)">
                        {{ctx.row.lastRespMsgCode}}</span>
                </div>
            </ng-template>

            <ng-template rgiRxDataTableCell="actions" let-ctx>
                <div>
                    <button rgi-rx-button (click)="guideMeToSummary(ctx, matrixIndex.SX_LIST)" variant="icon"><span class="rgi-ui-icon-right-arrow"></span></button>
                </div>
            </ng-template>
        </rgi-rx-datatable>

        <ng-template #rowDetailSx let-ctx>
            <div class="row  border-round">
                <div class="col-md-1">
                    <label rgiRxLabel class="label-core">{{'_CLAIMS_._INSURANCE_CODE_US' | translate}}:</label>
                    <span style="margin-left: 5px">{{ctx.insuranceCodeUS?.description}}</span>
                </div>

                <div class="col-md-1">
                    <label rgiRxLabel class="label-core">{{'_CLAIMS_._ACTOR_NOMINATIVE' | translate}}:</label>
                    <span style="margin-left: 5px">{{ctx.nameParty}}</span>
                </div>

                <div class="col-md-1">
                    <label rgiRxLabel class="label-core">{{'_CLAIMS_._OBJECT' | translate}}:</label>
                    <span style="margin-left: 5px">{{ctx.dialogueSubjectCode?.description}}</span>
                </div>

                <div class="col-md-1">
                    <label rgiRxLabel class="label-core">{{'_CLAIMS_._START_DATE' | translate}}:</label>
                    <span style="margin-left: 5px">{{ctx.startDate | date:'dd/MM/yyyy'}}</span>
                </div>


                <div class="col-md-1">
                    <label rgiRxLabel class="label-core">{{'_CLAIMS_._LST_MSG_DATE' | translate}}:</label>
                    <span style="margin-left: 5px">{{ctx.lastMsgDate | date:'dd/MM/yyyy'}}</span>
                </div>

                <div class="col-md-1">
                    <label rgiRxLabel class="label-core">{{'_CLAIMS_._END_DATE' | translate}}:</label>
                    <span style="margin-left: 5px">{{(ctx.endDate)?(ctx.endDate | date:'dd/MM/yyyy'):(ctx.cancelDate | date:'dd/MM/yyyy') }}</span>
                </div>

                <div class="col-md-1">
                    <label rgiRxLabel class="label-core">{{'_CLAIMS_._LST_MSG_INT_EXP' | translate}}:</label>
                    <span style="margin-left: 5px">{{ctx.lastMsgInternalExpire | date:'dd/MM/yyyy'}}</span>
                </div>

                <div class="col-md-1">
                    <label rgiRxLabel class="label-core">{{'_CLAIMS_._EXTERNAL_KEY' | translate}}:</label>
                    <span style="margin-left: 5px">{{ctx.externalKey}}</span>
                </div>

                <div class="col-md-1">
                    <label rgiRxLabel class="label-core">{{'_CLAIMS_._EXT_KEY_IDEF_DAM' | translate}}:</label>
                    <span style="margin-left: 5px">{{ctx.externalIdentifyDamageParty}}</span>
                </div>
            </div>
        </ng-template>

            <div class="btn-group btn-group-justified btn-group-justified-claim" style="margin: 25px 0px;">
                <div class="btn-group">
                    <!-- da modificare una volta che si avrà la parte di angularjs con il claimNumberPass proveniente da lì -->
                    <button *ngIf="btnCond.canOpenNew == true" style="margin-top: 10px;" rgi-rx-button class="btn btn-warning pull-right text-uppercase"
                        (click)="openCompleteNewDialogue()" translate>{{'_CLAIMS_._BUTTONS_._NEW_DIALOGUE' | translate
                        }}</button>
                </div>
            </div>

            <h4>{{'_CLAIMS_._SIMILAR_DIALOGUES' | translate}}</h4>
            <rgi-rx-datatable [data]="detailList.listFromClaim[1]" [schema]="schemaTableSx" [pageOptions]="[5,10,20,50]"
            [expansionRow]="rowDetailSx" (onAction)="onActionClick($event)">

            <ng-template rgiRxDataTableCell="insuranceCodeOther" let-ctx>
                <div>
                    <span>{{ctx.row.insuranceCodeOther?.code}}</span>
                </div>
            </ng-template>

            <ng-template rgiRxDataTableCell="functionCode" let-ctx>
                <div>
                    <span>{{ctx.row.functionCode?.description}}</span>
                </div>
            </ng-template>

            <ng-template rgiRxDataTableCell="roleCompany" let-ctx>
                <div>
                    <span>{{ctx.row.roleCompany?.description}}</span>
                </div>
            </ng-template>

            <ng-template rgiRxDataTableCell="lastRespMsgCode" let-ctx>
                <div>
                    <span  [class]="checkClass(ctx)">
                        {{ctx.row.lastRespMsgCode}}</span>
                </div>
            </ng-template>

            <ng-template rgiRxDataTableCell="actions" let-ctx>
                <div>
                    <button rgi-rx-button (click)="guideMeToSummary(ctx, matrixIndex.NOT_ASSOCIETED_LIST)" variant="icon"><span class="rgi-ui-icon-right-arrow"></span></button>
                </div>
            </ng-template>
        </rgi-rx-datatable>

        <ng-template #rowDetailSx let-ctx>
            <div class="row  border-round">
                <div class="col-md-1">
                    <label rgiRxLabel class="label-core">{{'_CLAIMS_._INSURANCE_CODE_US' | translate}}:</label>
                    <span style="margin-left: 5px">{{ctx.insuranceCodeUS?.description}}</span>
                </div>

                <div class="col-md-1">
                    <label rgiRxLabel class="label-core">{{'_CLAIMS_._ACTOR_NOMINATIVE' | translate}}:</label>
                    <span style="margin-left: 5px">{{ctx.nameParty}}</span>
                </div>

                <div class="col-md-1">
                    <label rgiRxLabel class="label-core">{{'_CLAIMS_._OBJECT' | translate}}:</label>
                    <span style="margin-left: 5px">{{ctx.dialogueSubjectCode?.description}}</span>
                </div>

                <div class="col-md-1">
                    <label rgiRxLabel class="label-core">{{'_CLAIMS_._START_DATE' | translate}}:</label>
                    <span style="margin-left: 5px">{{ctx.startDate | date:'dd/MM/yyyy'}}</span>
                </div>


                <div class="col-md-1">
                    <label rgiRxLabel class="label-core">{{'_CLAIMS_._LST_MSG_DATE' | translate}}:</label>
                    <span style="margin-left: 5px">{{ctx.lastMsgDate | date:'dd/MM/yyyy'}}</span>
                </div>

                <div class="col-md-1">
                    <label rgiRxLabel class="label-core">{{'_CLAIMS_._END_DATE' | translate}}:</label>
                    <span style="margin-left: 5px">{{(ctx.endDate)?(ctx.endDate | date:'dd/MM/yyyy'):(ctx.cancelDate | date:'dd/MM/yyyy') }}</span>
                </div>

                <div class="col-md-1">
                    <label rgiRxLabel class="label-core">{{'_CLAIMS_._LST_MSG_INT_EXP' | translate}}:</label>
                    <span style="margin-left: 5px">{{ctx.lastMsgInternalExpire | date:'dd/MM/yyyy'}}</span>
                </div>

                <div class="col-md-1">
                    <label rgiRxLabel class="label-core">{{'_CLAIMS_._EXTERNAL_KEY' | translate}}:</label>
                    <span style="margin-left: 5px">{{ctx.externalKey}}</span>
                </div>

                <div class="col-md-1">
                    <label rgiRxLabel class="label-core">{{'_CLAIMS_._EXT_KEY_IDEF_DAM' | translate}}:</label>
                    <span style="margin-left: 5px">{{ctx.externalIdentifyDamageParty}}</span>
                </div>
            </div>
        </ng-template>

        </div>

    </ng-container>




</div>
