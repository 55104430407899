<div *ngIf="danni">
    <span class="header-text" translate>Life Policy</span>
  </div>

   <div class="row premium-table">
        <div class="prem col-lg-4 tbld_col_summary tbld_annual_premium premium-table-section">
          <div class="tbld">
            <div class="tbld_row premium-title">
              <div class="tbld_col tbld_col_title">
                <b style="color: white; margin-left: 2%;"><span translate>lic_first_instalment</span></b>
              </div>
              <div class="tbld_col tbld_col_value" style="text-align: right">
                <span class="premio-firma-vita"  data-qa="premio-firma-vita">{{primaRata.gross | rgiCtryLNumFormatter : locale : optionCurrency}}</span>
              </div>
            </div>
            <div class="graphic arrow-down"></div>
            <div class="tbld_row premium_tab">
              <div class="tbld_col tbld_col_title tbld_col_title_premi border-dettaglio-quot " >
                <span translate>lic_taxes_label</span>
              </div>
              <div class="tbld_col tbld_col_value tbld_col_value_premi" translate>
                <span data-qa="premio-firma-vita-tasse" style="padding-right: 15%">{{primaRata.taxes | rgiCtryLNumFormatter : locale : optionCurrency}}</span>
              </div>
            </div>
            <div class="tbld_row premium_tab">
              <div class="tbld_col tbld_col_title tbld_col_title_premi border-dettaglio-quot" >
                <span translate>lic_fees_label</span>
              </div>
              <div class="tbld_col tbld_col_value tbld_col_value_premi">
                <span data-qa="premio-firma-vita-diritti" style="padding-right: 15%">{{primaRata.rights | rgiCtryLNumFormatter : locale : optionCurrency}}</span>
              </div>
            </div>
            <div class="tbld_row premium_tab">
              <div class="tbld_col tbld_col_title tbld_col_title_premi border-dettaglio-quot">
                <span translate>lic_frequency_interests</span>
              </div>
              <div class="tbld_col tbld_col_value tbld_col_value_premi">
                <span data-qa="premio-firma-vita-intfraz" style="padding-right: 15%">{{primaRata.interestsFractionation | rgiCtryLNumFormatter : locale : optionCurrency}}</span>
              </div>
            </div>
            <div class="tbld_row premium_tab">
              <div class="tbld_col tbld_col_title tbld_col_title_premi border-dettaglio-quot" >
                <span translate>lic_Net</span>
              </div>
              <div class="tbld_col tbld_col_value tbld_col_value_premi">
                <span data-qa="premio-firma-vita-netto" style="padding-right: 15%">{{primaRata.net | rgiCtryLNumFormatter : locale : optionCurrency}}</span>
              </div>
            </div>
          </div>
        </div>
        <!-- -------------------------------------------------------------------------------------------------------- -->
        <div *ngIf="ifNotSingleFrequency" class="prem col-lg-4 tbld_col_summary tbld_annual_premium premium-table-section">
          <div class="tbld">
            <div class="tbld_row premium-title">
              <div class="tbld_col tbld_col_title">
                <b style="color: white; margin-left: 2%;"><span translate>lic_next_instalment_premium</span></b>
              </div>
              <div class="tbld_col tbld_col_value" style="text-align: right">
                <span style="font-size: 15px; padding-right: 15%" data-qa="premio-ratasuccessiva-vita">{{policyPremium.successiveInstalmentGross | rgiCtryLNumFormatter : locale : optionCurrency}}</span>
              </div>
            </div>
            <div class="arrow-down"></div>
            <div class="tbld_row premium_tab">
              <div class="tbld_col tbld_col_title tbld_col_title_premi border-dettaglio-quot">
                <span translate>lic_taxes_label</span>
              </div>
              <div class="tbld_col tbld_col_value tbld_col_value_premi">
                <span data-qa="premio-ratasuccessiva-vita-tasse" style="padding-right: 15%">{{policyPremium.successiveInstalmentTaxes | rgiCtryLNumFormatter : locale : optionCurrency}}</span>
              </div>
            </div>
            <div class="tbld_row premium_tab">
              <div class="tbld_col tbld_col_title tbld_col_title_premi border-dettaglio-quot">
                <span translate>lic_fees_label</span>
              </div>
              <div class="tbld_col tbld_col_value tbld_col_value_premi">
                <span data-qa="premio-ratasuccessiva-vita-diritti" style="padding-right: 15%">{{policyPremium.successiveInstalmentFees | rgiCtryLNumFormatter : locale : optionCurrency}}</span>
              </div>
            </div>
            <div class="tbld_row premium_tab">
              <div class="tbld_col tbld_col_title tbld_col_title_premi border-dettaglio-quot">
                <span translate>lic_frequency_interests</span>
              </div>
              <div class="tbld_col tbld_col_value tbld_col_value_premi">
                <span data-qa="premio-ratasuccessiva-vita-intfraz" style="padding-right: 15%">{{policyPremium.successiveInstalmentInterestForFractioning | rgiCtryLNumFormatter : locale : optionCurrency}}</span>
              </div>
            </div>
            <div class="tbld_row premium_tab">
              <div class="tbld_col tbld_col_title tbld_col_title_premi border-dettaglio-quot">
                <span translate>lic_Net</span>
              </div>
              <div class="tbld_col tbld_col_value tbld_col_value_premi">
                <span data-qa="premio-ratasuccessiva-vita-netto" style="padding-right: 15%">{{policyPremium.successiveInstalmentNet | rgiCtryLNumFormatter : locale : optionCurrency}}</span>
              </div>
            </div>
          </div>
        </div>
        <!-- -------------------------------------------------------------------------------------------------------- -->
        <div *ngIf="ifNotSingleFrequency" class="prem col-lg-4 tbld_col_summary tbld_annual_premium premium-table-section">
          <div class="tbld">
            <div class="tbld_row premium-title">
              <div class="tbld_col tbld_col_title" >
                <b style="color: white; margin-left: 2%;"><span translate>lic_annual_premium</span></b>
              </div>
              <div class="tbld_col tbld_col_value" style="text-align: right">
                <span style="font-size: 15px; padding-right: 15%" data-qa="premio-annuo-vita">{{policyPremium.gross | rgiCtryLNumFormatter : locale : optionCurrency}}</span>
              </div>
            </div>
            <div class="arrow-down"></div>
            <div class="tbld_row premium_tab">
              <div class="tbld_col tbld_col_title tbld_col_title_premi border-dettaglio-quot">
                <span translate>lic_taxes_label</span>
              </div>
              <div class="tbld_col tbld_col_value tbld_col_value_premi">
                <span data-qa="premio-annuo-vita-tasse" style="padding-right: 15%">{{policyPremium.taxes | rgiCtryLNumFormatter : locale : optionCurrency}}</span>
              </div>
            </div>
            <div class="tbld_row premium_tab">
              <div class="tbld_col tbld_col_title tbld_col_title_premi border-dettaglio-quot">
                <span translate>lic_fees_label</span>
              </div>
              <div class="tbld_col tbld_col_value tbld_col_value_premi">
                <span data-qa="premio-annuo-vita-diritti" style="padding-right: 15%">{{policyPremium.fees | rgiCtryLNumFormatter : locale : optionCurrency}}</span>
              </div>
            </div>
            <div class="tbld_row premium_tab">
              <div class="tbld_col tbld_col_title tbld_col_title_premi border-dettaglio-quot" >
                <span translate>lic_frequency_interests</span>
              </div>
              <div class="tbld_col tbld_col_value tbld_col_value_premi">
                <span data-qa="premio-annuo-vita-intfraz" style="padding-right: 15%">{{policyPremium.interestsForFractioning | rgiCtryLNumFormatter : locale : optionCurrency}}</span>
              </div>
            </div>
            <div class="tbld_row premium_tab">
              <div class="tbld_col tbld_col_title tbld_col_title_premi border-dettaglio-quot">
                <span translate>lic_Net</span>
              </div>
              <div class="tbld_col tbld_col_value tbld_col_value_premi">
                <span data-qa="premio-annuo-vita-netto" style="padding-right: 15%">{{policyPremium.net | rgiCtryLNumFormatter : locale : optionCurrency}}</span>
              </div>
            </div>
          </div>
        </div>
      </div>



<!-- ------------------------------------------- PARTE DANNI ------------------------------------------------------ -->
<br>
<div *ngIf="danni">
    <span class="header-text" translate>lic_DamagesPolicy</span>
  </div>
      <div *ngIf="danni" class="row premium-table">
        <div class="prem col-lg-4 tbld_col_summary tbld_annual_premium premium-table-section">
          <div class="tbld">
            <div class="tbld_row premium-title">
              <div class="tbld_col tbld_col_title">
                <b style="color: white; margin-left: 2%;"><span translate>lic_first_instalment</span></b>
              </div>
              <div class="tbld_col tbld_col_value" style="text-align: right">
                <span style="font-size: 15px; padding-right: 15%" data-qa="premio-firma-danni">{{damagesInstalment[0].dueAmount.premium.gross | rgiCtryLNumFormatter : locale : optionCurrency}}</span>
              </div>
            </div>
            <div class="arrow-down"></div>
            <div class="tbld_row premium_tab">
              <div class="tbld_col tbld_col_title tbld_col_title_premi border-dettaglio-quot" >
                <span translate>lic_taxes_label</span>
              </div>
              <div class="tbld_col tbld_col_value tbld_col_value_premi">
                <span data-qa="premio-firma-danni-tasse" style="padding-right: 15%">{{damagesInstalment[0].dueAmount.premium.taxes | rgiCtryLNumFormatter : locale : optionCurrency}}</span>
              </div>
            </div>
            <div class="tbld_row premium_tab">
              <div class="tbld_col tbld_col_title tbld_col_title_premi border-dettaglio-quot" >
                <span translate>lic_taxable</span>
              </div>
              <div class="tbld_col tbld_col_value tbld_col_value_premi">
                <span data-qa="premio-firma-danni-imponibile" style="padding-right: 15%">{{sommaImponibile(
                  damagesInstalment[0].dueAmount.premium.net,
                  damagesInstalment[0].dueAmount.premium.accessories,
                  damagesInstalment[0].dueAmount.premium.fractioningInterestsAccessories,
                  damagesInstalment[0].dueAmount.premium.fractioningInterestsNet
                  ) | rgiCtryLNumFormatter : locale : optionCurrency}}
                </span>
              </div>
            </div>
            <div class="tbld_row premium_tab">
              <div class="tbld_col tbld_col_title tbld_col_title_premi border-dettaglio-quot">
                <span translate>lic_frequency_interests</span>
              </div>
              <div class="tbld_col tbld_col_value tbld_col_value_premi">
                <span data-qa="premio-firma-danni-intfraz" style="padding-right: 15%">{{sommaFractioningInterests(
                  damagesInstalment[0].dueAmount.premium.fractioningInterestsAccessories,
                  damagesInstalment[0].dueAmount.premium.fractioningInterestsNet
                  ) | rgiCtryLNumFormatter : locale : optionCurrency}}
                </span>
              </div>
            </div>
            <div class="tbld_row premium_tab">
              <div class="tbld_col tbld_col_title tbld_col_title_premi border-dettaglio-quot" >
                <span translate>lic_accessories</span>
              </div>
              <div class="tbld_col tbld_col_value tbld_col_value_premi">
                <span data-qa="premio-firma-danni-accessori" style="padding-right: 15%">{{damagesInstalment[0].dueAmount.premium.accessories | rgiCtryLNumFormatter : locale : optionCurrency}}</span>
              </div>
            </div>
            <div class="tbld_row premium_tab">
              <div class="tbld_col tbld_col_title tbld_col_title_premi border-dettaglio-quot " >
                <span translate>lic_Net</span>
              </div>
              <div class="tbld_col tbld_col_value tbld_col_value_premi" translate>
                <span data-qa="premio-firma-danni-netto" style="padding-right: 15%">{{damagesInstalment[0].dueAmount.premium.net | rgiCtryLNumFormatter : locale : optionCurrency}}</span>
              </div>
            </div>
          </div>
        </div>
        <!-- -------------------------------------------------------------------------------------------------------- -->
        <div *ngIf="ifNotSingleFrequency" class="prem col-lg-4 tbld_col_summary tbld_annual_premium premium-table-section">
          <div class="tbld">
            <div class="tbld_row premium-title">
              <div class="tbld_col tbld_col_title">
                <b style="color: white; margin-left: 2%;"><span translate>lic_next_instalment_premium</span></b>
              </div>
              <div class="tbld_col tbld_col_value" style="text-align: right">
                <span style="font-size: 15px; padding-right: 15%" data-qa="premio-ratasuccessiva-danni">{{damagesInstalment[1].dueAmount.premium.gross | rgiCtryLNumFormatter : locale : optionCurrency}}</span>
              </div>
            </div>
            <div class="arrow-down"></div>
            <div class="tbld_row premium_tab">
              <div class="tbld_col tbld_col_title tbld_col_title_premi border-dettaglio-quot" >
                <span translate>lic_taxes_label</span>
              </div>
              <div class="tbld_col tbld_col_value tbld_col_value_premi">
                <span data-qa="premio-ratasuccessiva-danni-tasse" style="padding-right: 15%">{{damagesInstalment[1].dueAmount.premium.taxes | rgiCtryLNumFormatter : locale : optionCurrency}}</span>
              </div>
            </div>
            <div class="tbld_row premium_tab">
              <div class="tbld_col tbld_col_title tbld_col_title_premi border-dettaglio-quot" >
                <span translate>lic_taxable</span>
              </div>
              <div class="tbld_col tbld_col_value tbld_col_value_premi">
                <span data-qa="premio-ratasuccessiva-danni-imponibile" style="padding-right: 15%">{{sommaImponibile(
                  damagesInstalment[1].dueAmount.premium.net,
                  damagesInstalment[1].dueAmount.premium.accessories,
                  damagesInstalment[1].dueAmount.premium.fractioningInterestsAccessories,
                  damagesInstalment[1].dueAmount.premium.fractioningInterestsNet
                  ) | rgiCtryLNumFormatter : locale : optionCurrency}}
                </span>
              </div>
            </div>
            <div class="tbld_row premium_tab">
              <div class="tbld_col tbld_col_title tbld_col_title_premi border-dettaglio-quot">
                <span translate>lic_frequency_interests</span>
              </div>
              <div class="tbld_col tbld_col_value tbld_col_value_premi">
                <span data-qa="premio-ratasuccessiva-danni-intfraz" style="padding-right: 15%">{{sommaFractioningInterests(damagesInstalment[1].dueAmount.premium.fractioningInterestsAccessories, damagesInstalment[1].dueAmount.premium.fractioningInterestsNet) | rgiCtryLNumFormatter : locale : optionCurrency}}</span>
              </div>
            </div>
            <div class="tbld_row premium_tab">
              <div class="tbld_col tbld_col_title tbld_col_title_premi border-dettaglio-quot" >
                <span translate>lic_accessories</span>
              </div>
              <div class="tbld_col tbld_col_value tbld_col_value_premi">
                <span data-qa="premio-ratasuccessiva-danni-accessori" style="padding-right: 15%">{{damagesInstalment[1].dueAmount.premium.accessories | rgiCtryLNumFormatter : locale : optionCurrency}}</span>
              </div>
            </div>
            <div class="tbld_row premium_tab">
              <div class="tbld_col tbld_col_title tbld_col_title_premi border-dettaglio-quot " >
                <span translate>lic_Net</span>
              </div>
              <div class="tbld_col tbld_col_value tbld_col_value_premi" translate>
                <span data-qa="premio-ratasuccessiva-danni-netto" style="padding-right: 15%">{{damagesInstalment[1].dueAmount.premium.net | rgiCtryLNumFormatter : locale : optionCurrency}}</span>
              </div>
            </div>
          </div>
        </div>
        <!-- -------------------------------------------------------------------------------------------------------- -->
        <div *ngIf="ifNotSingleFrequency" class="prem col-lg-4 tbld_col_summary tbld_annual_premium premium-table-section">
          <div class="tbld">
            <div class="tbld_row premium-title">
              <div class="tbld_col tbld_col_title" >
                <b style="color: white; margin-left: 2%;"><span translate>lic_annual_premium</span></b>
              </div>
              <div class="tbld_col tbld_col_value" style="text-align: right">
                <span style="font-size: 15px; padding-right: 15%" data-qa="premio-annuo-danni">{{annualPremium.gross | rgiCtryLNumFormatter : locale : optionCurrency}}</span>
              </div>
            </div>
            <div class="arrow-down"></div>
            <div class="tbld_row premium_tab">
              <div class="tbld_col tbld_col_title tbld_col_title_premi border-dettaglio-quot" >
                <span translate>lic_taxes_label</span>
              </div>
              <div class="tbld_col tbld_col_value tbld_col_value_premi">
                <span data-qa="premio-annuo-danni-tasse" style="padding-right: 15%">{{annualPremium.taxes | rgiCtryLNumFormatter : locale : optionCurrency}}</span>
              </div>
            </div>
            <div class="tbld_row premium_tab">
              <div class="tbld_col tbld_col_title tbld_col_title_premi border-dettaglio-quot" >
                <span translate>lic_taxable</span>
              </div>
              <div class="tbld_col tbld_col_value tbld_col_value_premi">
                <span data-qa="premio-annuo-danni-imponibile" style="padding-right: 15%">{{sommaImponibile(
                  annualPremium.net,
                  annualPremium.accessories,
                  annualPremium.fractioningInterestsAccessories,
                  annualPremium.fractioningInterestsNet
                  ) | rgiCtryLNumFormatter : locale : optionCurrency}}
                </span>
              </div>
            </div>
            <div class="tbld_row premium_tab">
              <div class="tbld_col tbld_col_title tbld_col_title_premi border-dettaglio-quot">
                <span translate>lic_frequency_interests</span>
              </div>
              <div class="tbld_col tbld_col_value tbld_col_value_premi">
                <span data-qa="premio-annuo-danni-intfraz" style="padding-right: 15%">{{sommaFractioningInterests(annualPremium.fractioningInterestsAccessories, annualPremium.fractioningInterestsNet) | rgiCtryLNumFormatter : locale : optionCurrency}}</span>
              </div>
            </div>
            <div class="tbld_row premium_tab">
              <div class="tbld_col tbld_col_title tbld_col_title_premi border-dettaglio-quot" >
                <span translate>lic_accessories</span>
              </div>
              <div class="tbld_col tbld_col_value tbld_col_value_premi">
                <span data-qa="premio-annuo-danni-accessori" style="padding-right: 15%">{{annualPremium.accessories | rgiCtryLNumFormatter : locale : optionCurrency}}</span>
              </div>
            </div>
            <div class="tbld_row premium_tab">
              <div class="tbld_col tbld_col_title tbld_col_title_premi border-dettaglio-quot " >
                <span translate>lic_Net</span>
              </div>
              <div class="tbld_col tbld_col_value tbld_col_value_premi" translate>
                <span data-qa="premio-annuo-danni-netto" style="padding-right: 15%">{{annualPremium.net | rgiCtryLNumFormatter : locale : optionCurrency}}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
