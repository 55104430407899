import { Component, EventEmitter, Inject, OnInit } from "@angular/core";
import { DIALOG_DATA, OnModalClose } from "@rgi/rx/ui";
import { FormControl, UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { OutputForfait } from "../../../models/forfait/output-forfait.model";
import { UtilityMethod } from "../../../constants/forfait.constants";
import { ModifyForfait } from "../../../models/forfait/modify-forfait.model";
import { AddForfait } from "../../../models/forfait/add-forfait.model";
import { SingleResponseForfait } from "../../../models/forfait/single-response-forfait.model";
import { PaymentsSearchboardStateManager } from "../../../state-managers/payments-searchboard.state.manager";
import { DialogueMsg } from "../../../models/dialogue-msg.model";
import { ForfaitService } from "../../../services/forfait.service";
import { RgiRxTranslationService } from "@rgi/rx/i18n";

@Component({
    selector: 'add-edit-forfait',
    templateUrl: './add-edit-forfait.component.html'
})
export class AddEditForfaitComponent implements OnModalClose, OnInit {
    modalClose = new EventEmitter<any>()

    protected errorMessage: string
    protected form: UntypedFormGroup = this.formBuilder.group({
        codiceMessaggio: [[], Validators.required],
        dataInizioValidita: ["", Validators.required],
        dataFineValidita: ["", Validators.required],
        importo: [0.00, Validators.required]
    });
    protected dialogueFunctions: DialogueMsg[]

    constructor(
        @Inject(DIALOG_DATA) public data: OutputForfait,
        public searchboardState: PaymentsSearchboardStateManager,
        private formBuilder: UntypedFormBuilder,
        private forfaitService: ForfaitService,
        public translateService: RgiRxTranslationService
    ) { }

    ngOnInit(): void {
        this.searchboardState
            .getState$()
            .subscribe(currentState => {
                const dialogueFunctionsFromState = currentState.searchWrap
                    .dialogueFunctionResponse
                    .find(dialogFunction => dialogFunction.functionCode == "CICOS")
                    .dialogueMsgData;

                if (!!dialogueFunctionsFromState) {
                    this.dialogueFunctions = dialogueFunctionsFromState;
                }

                if (!!this.data) {
                    this.form = this.formBuilder.group({
                        importo: new FormControl(this.data.importo, Validators.required)
                    });
                }
            });
    }

    onSubmit(): void {
        this.errorMessage = "";
        const rawValue = this.form.getRawValue();

        if (this.form.invalid) {
            return;
        }

        const isDateOk: boolean = this.controlloDate(rawValue);

        if(isDateOk) {
            const isImportoOk: boolean = this.controlloImporto(rawValue);

            if(isImportoOk) {
                this.confirm(rawValue)
            }
        }

        this.translateService.translate(this.errorMessage).subscribe(res => {
            this.errorMessage = res;
        })
    }

    private controlloImporto(rawValue: any): boolean {
        const regexImporto: RegExp = new RegExp('^[0-9]{1,}(\\.[0-9]{0,2})?$');
        let importoAsString: string = rawValue.importo;

        if (!regexImporto.test(importoAsString)) {
            this.errorMessage = '_CLAIMS_._INCORRECT_AMOUNT';
            return false;
        }

        return true;
    }

    private controlloDate(rawValue: any): boolean {
        rawValue.dataInizioValidita = UtilityMethod.transform(rawValue.dataInizioValidita);
        rawValue.dataFineValidita = UtilityMethod.transform(rawValue.dataFineValidita);

        if (rawValue.dataInizioValidita != null && rawValue.dataFineValidita != null) {
            const dataInizio: Date = new Date(rawValue.dataInizioValidita);
            const dataFine: Date = new Date(rawValue.dataFineValidita);

            if (dataFine <= dataInizio) {
                this.errorMessage = '_CLAIMS_._WRONG_DATES'
                return false
            }

            return true
        }

        this.errorMessage = '_CLAIMS_._NOT_CORRECT_ENTRY_DATA'
        return false
    }

    private async confirm(rawValue: any): Promise<void> {
        if (this.errorMessage == '') {
            if (!!this.data) {
                const modifyForfait: ModifyForfait = {
                    idForfait: this.data.idForfait,
                    importo: Number(rawValue.importo)
                }

                this.modalClose.emit(modifyForfait);
            } else {
                rawValue.dataInizioValidita = UtilityMethod.transform(rawValue.dataInizioValidita);
                rawValue.dataFineValidita = UtilityMethod.transform(rawValue.dataFineValidita);

                const addForfait: AddForfait = {
                    codiceFunzione: "CICOS",
                    codiceMessaggio: rawValue.codiceMessaggio,
                    dataInizioValidita: rawValue.dataInizioValidita,
                    dataFineValidita: rawValue.dataFineValidita,
                    importo: rawValue.importo
                }

                const singleResponseForfait: SingleResponseForfait = await this.forfaitService.addForfait(addForfait);

                if (singleResponseForfait == null) {
                    this.errorMessage = '_CLAIMS_._ERROR_DURING_SAVING'
                    return
                } else {
                    if (!!singleResponseForfait.requestOutcome
                        && !!singleResponseForfait.requestOutcome.code
                        && singleResponseForfait.requestOutcome.code == "KO"
                        && !!singleResponseForfait.requestOutcome.description
                    ) {
                        this.errorMessage = singleResponseForfait.requestOutcome.description
                        return
                    } else {
                        const outputForfait: OutputForfait = singleResponseForfait.response;

                        if (!!outputForfait && !!outputForfait.idForfait) {
                            this.modalClose.emit(outputForfait);
                        } else {
                            this.errorMessage = '_CLAIMS_._NOT_CORRECT_ENTRY_DATA'
                            return
                        }
                    }
                }
            }
        }
    }
}
